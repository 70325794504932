import React, { useState, useRef, useEffect } from 'react';
import {
  Send as SendIcon,
  AttachFile as AttachmentIcon
} from '@mui/icons-material';
import {
  Avatar,
  IconButton,
  TextField,
  InputAdornment,
  Typography
} from '@mui/material';
import { sendMessage } from 'features/reservation/services/serverApi.reservation';
import { toast } from 'react-toastify';

const Chat = ({
  messages,
  reservationId,
  socket,
  addNewMessage,
  chatTitle = "Reservation Chat"
}) => {
  const [newMessage, setNewMessage] = useState('');
  const [localMessages, setLocalMessages] = useState(messages || []);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
    setLocalMessages(messages || []);
  }, [messages]);

  useEffect(() => {
    if (!socket) return;

    const handleNewMessage = (data) => {
      console.log('Chat Component - New Message Received:', {
        data,
        reservationId,
        messageDetails: {
          bookingId: data.booking_id,
        }
      });
    };

    socket.on('NEW_RECIVED_MSG', handleNewMessage);
    socket.on('NEW_SENDED_MSG', handleNewMessage);

    return () => {
      socket.off('NEW_RECIVED_MSG', handleNewMessage);
      socket.off('NEW_SENDED_MSG', handleNewMessage);
    };
  }, [socket, reservationId, addNewMessage]);

  const handleSendMessage = async () => {
    if (!newMessage.trim() || isLoading) return;

    try {
      setIsLoading(true);
      const messageData = {
        message: newMessage,
        reservationId: reservationId
      };

      await sendMessage(messageData);

      const sentMessage = {
        id: `temp-${Date.now()}`,
        message: newMessage,
        sender: 'property',
        // property_id: reservationId,
        attachments: [],
        have_attachment: false,
        timestamp: new Date().toLocaleTimeString()
      };

      addNewMessage(sentMessage);
      setNewMessage('');
      scrollToBottom();

      toast.success('Message sent successfully');
    } catch (error) {
      console.error('Failed to send message:', error);
      const errorMessage = error.response?.data?.message || 'Failed to send message';
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent form submission
      handleSendMessage();
    }
  };

  const renderMessageBubble = (msg, index) => {
    const isGuest = msg.sender === 'guest';
    const isProperty = msg.sender === 'property';

    return (
      <div
        key={msg.id || index}
        className={`flex w-full mb-3 ${isGuest ? 'justify-start' : 'justify-end'
          }`}
      >
        {isGuest && (
          <Avatar
            className="mr-2 self-end mx-2"
            sx={{
              width: 32,
              height: 32,
              backgroundColor: '#00b4b4'
            }}
          >
            G
          </Avatar>
        )}

        <div
          className={`
            max-w-[70%] p-3 rounded-2xl 
            ${isGuest
              ? 'bg-gray-100 text-black'
              : 'bg-[#00b4b4] text-white'
            }
            relative
          `}
        >
          {msg.message}
          {msg.have_attachment && (
            <div className="text-xs italic mt-1">
              Attachment
            </div>
          )}
          <div
            className="text-xs absolute bottom-[-15px] right-0"
            style={{
              color: isGuest ? '#666' : 'rgba(255,255,255,0.7)',
              fontSize: '10px'
            }}
          >
          </div>
        </div>

        {isProperty && (
          <Avatar
            className="ml-2 self-end mx-2"
            sx={{
              width: 32,
              height: 32,
              backgroundColor: '#4a90e2'
            }}
          >
            P
          </Avatar>
        )}
      </div>
    );
  };

  return (
    <div className="flex flex-col h-[650px] bg-white shadow-md rounded-lg overflow-hidden mt-4">
      <div className="p-4 bg-[#00b4b4] text-white flex items-center">
        <div className="flex-grow">
          <Typography variant="h6" className="font-bold text-white">
            {chatTitle}
          </Typography>
        </div>
      </div>

      <div
        className="flex-grow overflow-y-auto p-4 space-y-2"
        style={{
          background: 'linear-gradient(to bottom, #f0f0f0 0%, #ffffff 100%)'
        }}
      >
        {localMessages.map(renderMessageBubble)}
        <div ref={messagesEndRef} />
      </div>

      <div className="p-4 bg-white border-t flex items-center space-x-2">
        <IconButton>
          <AttachmentIcon className="text-[#00b4b4]" />
        </IconButton>

        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleSendMessage}
                  disabled={!newMessage.trim() || isLoading}
                >
                  <SendIcon
                    className={
                      newMessage.trim() && !isLoading
                        ? 'text-[#00b4b4]'
                        : 'text-gray-400'
                    }
                  />
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              borderRadius: '20px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#00b4b4',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#00b4b4',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#00b4b4',
              },
            }
          }}
        />
      </div>
    </div>
  );
};

export default Chat;