import React, { useState, useEffect } from 'react';
import { Typography, Select, MenuItem } from '@mui/material';
import { User, Bot, Users } from 'lucide-react';
import { updateUserChatHistoryReply } from './services/serverApi.chatConfig';
import { toast } from 'react-toastify';

const AIReplyModeSelector = ({ initialSettings, onModeChange, conversationId }) => {
  const [mode, setMode] = useState('');

  useEffect(() => {
    if (initialSettings) {
      let initialMode;
      if (initialSettings.OpenAI === true) {
        initialMode = 'ai';
      } else if (initialSettings.response_backend === true) {
        initialMode = 'assistant-and-sojori';
      } else {
        initialMode = 'assistant-only';
      }
      setMode(initialMode);
    }
  }, [initialSettings]);

  const handleChange = async (event) => {
    const newMode = event.target.value;
    setMode(newMode);
    
    let openAI, response_backend;
    
    switch(newMode) {
      case 'assistant-only':
        openAI = false;
        response_backend = false;
        break;
      case 'ai':
        openAI = true;
        response_backend = true;
        break;
      case 'assistant-and-sojori':
        openAI = false;
        response_backend = true;
        break;
      default:
        openAI = initialSettings?.OpenAI ?? false;
        response_backend = initialSettings?.response_backend ?? false;
    }
    
    try {
      await updateUserChatHistoryReply(conversationId, { OpenAI: openAI, response_backend: response_backend });
      onModeChange({ mode: newMode, openAI, response_backend });
      toast.success('AI Reply Mode updated successfully');
    } catch (error) {
      console.error('Error updating reply mode:', error);
      toast.error('Failed to update AI Reply Mode. Please try again.');
    }
  };

  return (
    <div className="p-4">
      <Typography variant="body1" className="mb-2">AI Reply Mode</Typography>
      <Select
        value={mode}
        onChange={handleChange}
        fullWidth
        className="cursor-pointer text-black p-2 rounded-md"
        style={{ border: '1px solid #ccc' }}
      >
        <MenuItem value="assistant-only">
          <div className="flex gap-2 items-center">
            <User className="mr-2" size={20} />
            Assistant Only
          </div>
        </MenuItem>
        <MenuItem value="ai">
          <div className="flex gap-2 items-center">
            <Bot className="mr-2" size={20} />
            AI Response
          </div>
        </MenuItem>
        <MenuItem value="assistant-and-sojori">
          <div className="flex gap-2 items-center">
            <Users className="mr-2" size={20} />
            Assistant and Sojori
          </div>
        </MenuItem>
      </Select>
    </div>
  );
};

export default AIReplyModeSelector;