export const tabFieldMappings = {
    basicinfo: ['externalListingName', 'propertyType', 'roomType', 'bedroomsNumber', 'bathroomsNumber', 'bathroomType', 'description'],
    amenities: ['listingAmenitiesIds'],
    media: ['listingImages'],
    additionalinfo: ['houseRules', 'houseManual', 'checkInTimeStart', 'checkOutTime', 'specialInstruction', 'doorSecurityCode', 'minNights', 'maxNights'], 
    channelspecific: ['homeawayPropertyName', 'bookingcomPropertyName', 'bookingcomPropertyDescription', 'homeawayPropertyDescription'],
    address: ['address', 'city', 'cityId', 'country', 'lat', 'lng'],
    task: ['cleanNumber', 'frequency'],
    roomtype : ['roomTypes'],
    configuration: ['roomTypes'],
  


};

export const getTabsWithErrors = (errors, touched) => {
    return Object.entries(tabFieldMappings).reduce((acc, [tab, fields]) => {
        const hasError = fields.some(field => {
            const errorField = errors[field] || errors[field.toLowerCase()];
            const touchedField = touched[field] || touched[field.toLowerCase()];
            
            if (Array.isArray(errorField)) {
                return errorField.some((_, index) => touchedField?.[index]);
            }
            return errorField && touchedField;
        });
        if (hasError) {
            acc.push(tab);
        }
        return acc;
    }, []);
};

