import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Typography, Tooltip } from '@mui/material';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import { createTaskConfig, updateTaskConfig } from '../../services/serverApi.task';
import { toast } from 'react-toastify';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';

const validationSchema = Yup.object().shape({
    task: Yup.string().required('Task is required'),
    name: Yup.string().required('Name is required'),
    subs: Yup.array().of(
        Yup.object().shape({
            type: Yup.string().required('Type is required'),
            name: Yup.string().required('Name is required'),
        })
    ).min(1, 'At least one subtask is required'),
});

const updateValidationSchema = Yup.object().shape({
    task: Yup.string().required('Task is required'),
    name: Yup.string().required('Name is required'),
    subs: Yup.array().of(
        Yup.object().shape({
            type: Yup.string().required('Type is required'),
            name: Yup.string().required('Name is required'),
        })
    ),
});

const TaskConfigModal = ({ open, onClose, setTaskConfigs, taskConfig }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        setIsLoading(true);
        setErrorMessage('');
        try {
            let response;
            if (taskConfig) {
                const updateData = {
                    task: taskConfig.task,
                    name: values.name,
                    subs: values.subs
                };
                response = await updateTaskConfig(taskConfig._id, updateData);
            } else {
                response = await createTaskConfig(values);
            }

            if (response) {
                setTaskConfigs(prevConfigs => {
                    if (!prevConfigs) return [response.data];
                    if (taskConfig) {
                        return prevConfigs.map(c => c._id === taskConfig._id ? response.data : c);
                    } else {
                        return [...prevConfigs, response.data];
                    }
                });
                setSubmitting(false);
                onClose();
                toast.success(taskConfig ? 'Task configuration updated successfully' : 'Task configuration created successfully');
            } else {
                throw new Error('Unexpected response structure');
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage(error.message || 'An error occurred while processing your request.');
            setSubmitting(false);
            toast.error(taskConfig ? 'Error updating task configuration' : 'Error creating task configuration');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{taskConfig ? 'Update Task Configuration' : 'Create New Task Configuration'}</DialogTitle>
            <DialogContent>
                {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                <Formik
                    initialValues={{
                        task: taskConfig ? taskConfig.task : '',
                        name: taskConfig ? taskConfig.name : '',
                        subs: taskConfig ? taskConfig.subs : [{ type: '', name: '' }],
                    }}
                    validationSchema={taskConfig ? updateValidationSchema : validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form className="mt-2">
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                {taskConfig ? (
                                    <Typography>{taskConfig.task}</Typography>
                                ) : (
                                    <Field name="task">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Task"
                                                fullWidth
                                                error={touched.task && Boolean(errors.task)}
                                                helperText={touched.task && errors.task}
                                            />
                                        )}
                                    </Field>
                                )}
                                <Field name="name">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Name"
                                            fullWidth
                                            error={touched.name && Boolean(errors.name)}
                                            helperText={touched.name && errors.name}
                                        />
                                    )}
                                </Field>
                                <FieldArray name="subs">
                                    {({ push, remove }) => (
                                        <>
                                            {values.subs.map((sub, index) => (
                                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Subtask Type"
                                                        value={sub.type}
                                                        onChange={(e) => setFieldValue(`subs.${index}.type`, e.target.value)}
                                                        error={touched.subs?.[index]?.type && Boolean(errors.subs?.[index]?.type)}
                                                        helperText={touched.subs?.[index]?.type && errors.subs?.[index]?.type}
                                                        disabled={taskConfig && index < taskConfig.subs.length}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="Subtask Name"
                                                        value={sub.name}
                                                        onChange={(e) => setFieldValue(`subs.${index}.name`, e.target.value)}
                                                        error={touched.subs?.[index]?.name && Boolean(errors.subs?.[index]?.name)}
                                                        helperText={touched.subs?.[index]?.name && errors.subs?.[index]?.name}
                                                    />
                                                    <Button onClick={() => remove(index)} disabled={taskConfig && index < taskConfig.subs.length}>
                                                        <DeleteOutlineIcon className="!text-gray-500 !w-6 !h-6" />
                                                    </Button>
                                                </Box>
                                            ))}
                                            <Button onClick={() => push({ type: '', name: '' })} startIcon={<AddIcon />}>
                                                Add Subtask
                                            </Button>
                                        </>
                                    )}
                                </FieldArray>
                            </Box>
                            <DialogActions>
                                <Button onClick={onClose} className="!bg-red-500 !text-white">
                                    Cancel
                                </Button>
                                <Button type="submit" className="!bg-medium-aquamarine !text-white" disabled={isLoading}>
                                    {taskConfig ? 'Update' : 'Create'}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default TaskConfigModal;