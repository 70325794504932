import React from 'react';
import { FieldArray } from 'formik';
import { Box, IconButton, Fade, Grid } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageUpload from 'components/CustomUpload/UploadV2';
import defaultImage from 'assets/images/image_placeholder.jpg';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export const ImageUploadSection = ({ 
  values, 
  setFieldValue, 
  imageHeight = 300, // Default height
  containerHeight = 200, // Default container height for add button
  previewBoxPadding = '300px' // Default padding-top for aspect ratio
}) => {
  const handleDragEnd = (result, arrayHelpers) => {
    if (!result.destination) return;

    const items = Array.from(values.images);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatedItems = items.map((item, index) => ({
      ...item,
      sortOrder: index + 1,
    }));

    setFieldValue('images', updatedItems);
  };

  return (
    <FieldArray name="images">
      {(arrayHelpers) => (
        <Box sx={{ width: '100%' }}>
          <DragDropContext onDragEnd={(result) => handleDragEnd(result, arrayHelpers)}>
            <Droppable droppableId="images" direction="horizontal">
              {(provided) => (
                <Grid
                  container
                  spacing={2}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {values.images.map((image, index) => (
                    <Draggable
                      key={index}
                      draggableId={`image-${index}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Fade in={true}>
                            <Box
                              sx={{
                                position: 'relative',
                                borderRadius: '8px',
                                overflow: 'hidden',
                                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                                transition: 'transform 0.2s',
                                transform: snapshot.isDragging
                                  ? 'scale(1.05)'
                                  : 'none',
                                '&:hover': {
                                  transform: 'translateY(-4px)',
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  position: 'relative',
                                  paddingTop: previewBoxPadding,
                                  backgroundColor: '#f5f5f5',
                                }}
                              >
                                <img
                                  src={image.url || defaultImage}
                                  alt={`Image ${index + 1}`}
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: `${imageHeight}px`,
                                    objectFit: 'cover',
                                  }}
                                />
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: 'rgba(0,0,0,0.3)',
                                    opacity: 0,
                                    transition: 'opacity 0.2s',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '&:hover': {
                                      opacity: 1,
                                    },
                                  }}
                                >
                                  <IconButton
                                    onClick={() => arrayHelpers.remove(index)}
                                    sx={{
                                      color: 'white',
                                      '&:hover': {
                                        backgroundColor: 'rgba(255,255,255,0.2)',
                                      },
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              </Box>
                              <Box sx={{ p: 1 }} className="text-center">
                                <ImageUpload
                                  fieldName={`images.${index}.url`}
                                  setFieldValue={setFieldValue}
                                  folder="listing"
                                  accept="image/*"
                                  label="Change Image"
                                />
                              </Box>
                            </Box>
                          </Fade>
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Box
                      onClick={() =>
                        arrayHelpers.push({
                          url: '',
                          sortOrder: values.images.length + 1,
                        })
                      }
                      sx={{
                        height: '100%',
                        minHeight: `${containerHeight}px`,
                        border: '2px dashed #00b4b4',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        '&:hover': {
                          borderColor: '#00b4b4',
                          backgroundColor: 'rgba(0,180,180,0.1)',
                        },
                      }}
                    >
                      <IconButton color="primary">
                        <AddPhotoAlternateIcon
                          sx={{ fontSize: 40 }}
                          className="!text-medium-aquamarine"
                        />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      )}
    </FieldArray>
  );
};

export default ImageUploadSection;