import React from 'react';
import { Typography, List, ListItem, Grid, Paper, Chip, Box } from '@mui/material';
import { styled } from '@mui/system';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AssignmentIcon from '@mui/icons-material/Assignment';
import logo from 'assets/images/logo-ct.png';

const STATUS_COLORS = {
    PENDING: 'warning',
    IN_PROGRESS: 'info',
    COMPLETED: 'success',
    CANCELED: 'error',
    'CANCELED-BY-CUST': 'error'
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: theme.shape.borderRadius,
}));

const StyledChip = styled(Chip)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: '0.85rem',
    color: theme.palette.common.white,
}));

const IconText = ({ icon, text }) => (
    <Box display="flex" alignItems="center" mb={1}>
        {icon}
        <Typography variant="body2" ml={1}>{text}</Typography>
    </Box>
);

const TaskDetails = ({ task }) => {
    if (!task) return null;

    const formatDate = (date) => {
        if (!date) return '';
        const d = new Date(date);
        return d.toLocaleDateString();
    };

    return (
        <StyledPaper>
            <Grid container spacing={2}>
                <Grid item xs={12} className="!flex gap-1 items-center !pl-1 mb-4 justify-between">
                    <Typography variant="h6" className="!text-medium-aquamarine" gutterBottom>
                        {task.type || 'Unknown Type'} - {task.subType || 'Unknown SubType'}
                    </Typography>
                    <StyledChip
                        label={task.status || 'Unknown'}
                        color={STATUS_COLORS[task.status] || 'default'}
                        size="small"
                        className="!mb-2"
                    />
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <IconText
                            icon={<EventIcon color="action" />}
                            text={task.startDate ? formatDate(task.startDate) : 'N/A'}
                        />
                        <IconText
                            icon={<AccessTimeIcon color="action" />}
                            text={task.TS_SEL && task.TS_SEL.length > 0 ? `${task.TS_SEL[0].start}:00 - ${task.TS_SEL[0].end}:00` : 'N/A'}
                        />
                        <IconText
                            icon={<AttachMoneyIcon color="action" />}
                            text={`${task.price} (${task.paid ? 'Paid' : 'Unpaid'})`}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <IconText
                            icon={<PersonIcon color="action" />}
                            text={task.staff?.username || 'Unassigned'}
                        />
                        <IconText
                            icon={<AssignmentIcon color="action" />}
                            text={task.assignmentStatus || 'N/A'}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </StyledPaper>
    );
};

const ReservationTasksTab = ({ tasks }) => {
    const taskList = Object.values(tasks).flat();
    const listingInfo = taskList.length > 0 ? taskList[0].listing : null;

    return (
        <Box p={2}>
            {listingInfo && (
                <Paper elevation={0} sx={{ p: 2, mb: 3, backgroundColor: 'rgba(0, 0, 0, 0.03)' }}>
                    <Box display="flex" className="gap-2" alignItems="center">
                        <img src={logo} alt="Listing" style={{ width: 30, height: 40 }} />
                        <Box>
                            <Typography variant="subtitle1" fontWeight="medium">Listing name</Typography>
                            <Typography variant="body2">{listingInfo.name || 'N/A'}</Typography>
                        </Box>
                    </Box>
                </Paper>
            )}

            {taskList.length  ? (
                taskList.map(task => (
                    <TaskDetails key={task._id} task={task} />
                ))
            ) : (
                <Typography variant="body1" textAlign="center" color="text.secondary" py={4}>
                    No tasks available.
                </Typography>
            )}
        </Box>
    );
};

export default ReservationTasksTab;
