
export const cleanRoomTypesData = (roomTypes) => {
    return roomTypes.map(({ _id, rooms, ...roomType }) => ({
      ...roomType,
      _id: _id || undefined, 
      rooms: rooms.map(({
        _id,
        roomNumber,
        roomName,
        roomCode,
        address,
        enabled
      }) => ({
        _id: _id || undefined, 
        roomNumber,
        roomName,
        roomCode,
        address: address || '',
        enabled
      }))
    }));
  };

