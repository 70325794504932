import React, { useState, useEffect } from 'react';
import { getTaskConfigs, deleteTaskConfig } from './services/serverApi.task';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import TaskConfigModal from './components/TaskConfigModal/TaskConfigModal';
import { toast } from 'react-toastify';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { CircularProgress, Typography, Button } from '@mui/material';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';

function TaskConfigs() {
    const [isLoading, setIsLoading] = useState(true);
    const [taskConfigs, setTaskConfigs] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedTaskConfig, setSelectedTaskConfig] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchTaskConfigs();
    }, []);

    const fetchTaskConfigs = async () => {
        try {
            const response = await getTaskConfigs();
            if (Array.isArray(response)) {
                setTaskConfigs(response);
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            console.error('Error fetching task configs:', error);
            setError('Failed to fetch task configurations. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleOpenModal = (taskConfig = null) => {
        setSelectedTaskConfig(taskConfig);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedTaskConfig(null);
        setModalOpen(false);
    };

    const handleDeleteTaskConfig = async (taskConfigId) => {
        if (window.confirm('Are you sure you want to delete this task configuration?')) {
            try {
                await deleteTaskConfig(taskConfigId);
                setTaskConfigs(prevConfigs => prevConfigs.filter(config => config._id !== taskConfigId));
                toast.success('Task configuration deleted successfully');
            } catch (error) {
                console.error('Error deleting task configuration:', error);
                toast.error('Failed to delete task configuration');
            }
        }
    };

    const columns = [
        { header: "Task", body: (rowData) => <span>{rowData.task || 'N/A'}</span> },
        { header: "Name", body: (rowData) => <span>{rowData.name || 'N/A'}</span> },
        {
            header: "Subtasks",
            body: (rowData) => (
                <div>
                    {rowData.subs && rowData.subs.map((sub, index) => (
                        <div key={sub._id || index}>
                            <strong>{sub.type || 'N/A'}:</strong> {sub.name || 'N/A'}
                        </div>
                    ))}
                </div>
            )
        },
        {
            header: "Action",
            body: (rowData) => (
                <div className="flex gap-1">
                    <button className="px-2 py-1 bg-medium-aquamarine !rounded-md" onClick={() => handleOpenModal(rowData)}>
                        <EditOffIcon className="text-white" />
                    </button>
                    <button className="px-2 py-1 bg-[#df5454] !rounded-md" onClick={() => handleDeleteTaskConfig(rowData._id)}>
                        <DeleteSweepIcon className="text-white" />
                    </button>
                </div>
            ),
        },
    ];

    if (error) {
        return <div className="w-full h-64 flex justify-center items-center text-red-500">{error}</div>;
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="card p-4">
                <Typography variant="h4" component="h1" className="mb-4" gutterBottom>
                    Task Configuration Management
                </Typography>
                <div className="mb-4">
                    <Button onClick={() => handleOpenModal()} className="float-right !bg-medium-aquamarine text-white">Create New Task Config</Button>
                </div>
                <div>
                    <div className="w-full">
                        {isLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress style={{ color: '#00b4b4' }} />
                            </div>
                        ) : (
                            <GlobalTable
                                data={taskConfigs}
                                columns={columns}
                                hasPagination={false}
                            />
                        )}
                    </div>
                </div>
                <TaskConfigModal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    setTaskConfigs={setTaskConfigs}
                    taskConfig={selectedTaskConfig}
                />
            </div>
        </DashboardLayout>
    );
}

export default TaskConfigs;