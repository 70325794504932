import React from 'react';
import { Typography } from '@mui/material';
import RoomTypeItem from './RoomTypeItem';

const ListingItem = ({ listing, onCreateRatePlan, onUpdateRatePlan }) => {
    const firstRoomType = listing.roomTypes && listing.roomTypes.length > 0 ? listing.roomTypes[0] : null;

    return (
        <div className="mb-8 border p-4 rounded">
            {/* <h2 className="text-xl font-bold mb-2">{listing.name}</h2> */}
            {firstRoomType ? (
                <RoomTypeItem
                    key={firstRoomType._id}
                    listingId={listing._id}
                    roomType={firstRoomType}
                    onCreateRatePlan={() => onCreateRatePlan(listing._id)}
                    onUpdateRatePlan={onUpdateRatePlan}
                />
            ) : (
                <Typography className="text-gray-500 italic">No room types available</Typography>
            )}
        </div>
    );
};

export default ListingItem;