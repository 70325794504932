import { useState } from 'react';
import { cancelReservation, revertCancellation } from 'features/reservation/services/serverApi.reservation';
import { toast } from 'react-toastify';

const useReservationStatus = (initialReservation, onStatusChange) => {
  const [isLoading, setIsLoading] = useState(false);

  const updateReservationStatus = async (actionType) => {
    if (!initialReservation?._id) {
      toast.error(`Unable to ${actionType}: Reservation details are not available`);
      return;
    }

    setIsLoading(true);
    const action = actionType === 'cancel' ? cancelReservation : revertCancellation;
    
    const successMessage = actionType === 'cancel' ? 'Reservation cancelled successfully' : 'Cancellation reverted successfully';
    const errorMessage = actionType === 'cancel' ? 'Error cancelling reservation' : 'Error reverting cancellation';
    const newStatus = actionType === 'cancel' ? 'CancelledByAdmin' : 'Pending';

    try {
      const { _id, arrivalDate, departureDate, status, ...restData } = initialReservation;
        
      const data = {
        ...restData,
        arrivalDate: arrivalDate.split('T')[0],
        departureDate: departureDate.split('T')[0],
        status: actionType === 'cancel' ? 'CancelledByAdmin' : 'Pending'
      };

      const response = await action(_id, data);

      if (response.data.success) {
        onStatusChange(newStatus);
        toast.success(successMessage);
      } else {
        throw new Error(response.data.message || errorMessage);
      }
    } catch (error) {
      console.error(`Error ${actionType} reservation:`, error);
      const errorMsg = error.response?.data?.message || error.message || errorMessage;
      toast.error(errorMsg);
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, updateReservationStatus };
};

export default useReservationStatus;