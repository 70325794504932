import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { VirtualScroller } from 'primereact/virtualscroller';
import './MultiTableCalendar.css';
import UpdateCalendarDialog from '../../multiCalendarV2/UpdateCalendar';
import ModelEventDialog from '../../multiCalendarV2/ModelEventDialog';
import moment from 'moment';
import SidebarProperty from './SideBar/SidebarProperty'
import { debounce } from 'lodash';


const MultiTableCalendar = React.memo(({ properties: initialProperties, daysInView, selectedItems, isLoading, allDays, loadingDateRanges, onScroll, scrollerRef }) => {
    const [properties, setProperties] = useState(initialProperties);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [lazyLoading, setLazyLoading] = useState(true);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [selectedDay, setSelectedDay] = useState(null);
    const [lastTriggerScrollPosition, setLastTriggerScrollPosition] = useState(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    const saveScrollPosition = useCallback(() => {
        if (scrollerRef.current) {
            setScrollPosition(scrollerRef.current.scrollTop);
        }
    }, []);

    const restoreScrollPosition = useCallback(() => {
        if (scrollerRef.current) {
            scrollerRef.current.scrollTop = scrollPosition;
        }
    }, [scrollPosition]);

    const loadLazyTimeout = useRef(null);

    const totalColumns = allDays.length;
    const columnWidth = 92;
    const rowHeight = 76;

    const initialScrollPercentage = useMemo(() => {
        const today = moment();
        const startDate = moment(allDays[0]);
        const totalDays = moment(allDays[allDays.length - 1]).diff(startDate, 'days');
        const targetDate = today.clone().add(3, 'days');
        const daysSinceStart = targetDate.diff(startDate, 'days');
        return Math.min((daysSinceStart / totalDays) * 100, 100);
    }, [allDays]);

    const debouncedHandleScroll = useCallback(
        debounce((e) => {
            const { scrollLeft, scrollWidth, clientWidth } = e.target;
            const newScrollPercentage = (scrollLeft / (scrollWidth - clientWidth)) * 100;
            const currentScrolledColumns = Math.round(newScrollPercentage / (columnWidth / scrollWidth * 100));

            if (lastTriggerScrollPosition === null) {
                setLastTriggerScrollPosition(currentScrolledColumns);
                return;
            }

            const daysScrolled = currentScrolledColumns - lastTriggerScrollPosition;
            const absDaysScrolled = Math.abs(daysScrolled);

            if (absDaysScrolled >= 5) {
                const targetDate = moment(allDays[currentScrolledColumns]);
                onScroll(targetDate.toDate());
                setLastTriggerScrollPosition(currentScrolledColumns);
            }
        }, 200),
        [columnWidth, lastTriggerScrollPosition, allDays, onScroll]
    );

    useEffect(() => {
        setProperties(initialProperties);
        setLazyLoading(false);
    }, [initialProperties]);

    useEffect(() => {
        if (scrollerRef.current) {
            const scrollWidth = scrollerRef.current.scrollWidth;
            const clientWidth = scrollerRef.current.clientWidth;
            const initialScrollLeft = (initialScrollPercentage / 100) * (scrollWidth - clientWidth);
            scrollerRef.current.scrollLeft = initialScrollLeft;

            const initialScrolledColumns = Math.round((initialScrollLeft / (scrollWidth - clientWidth)) * 100 / (columnWidth / scrollWidth * 100));
            setLastTriggerScrollPosition(initialScrolledColumns);
        }
    }, [initialScrollPercentage, columnWidth]);

    useEffect(() => {
        setProperties(initialProperties);
    }, [initialProperties]);

    const formatDate = useCallback((date) => {
        return date.toLocaleDateString('en-US', { weekday: 'short', day: 'numeric' });
    }, []);

    const handleUpdateCalendar = useCallback((updatedData) => {
        setProperties(prevProperties => prevProperties.map(property => {
            if (property.id === updatedData.listingId) {
                const updatedCalendarData = property.calendarData.map(day => {
                    const matchingUpdatedDay = updatedData.postUpdateDocs.find(
                        updatedDay => moment(updatedDay.date).format('YYYY-MM-DD') === moment(day.date).format('YYYY-MM-DD')
                    );

                    if (matchingUpdatedDay) {
                        return {
                            ...day,
                            price: matchingUpdatedDay.price,
                            minimumStay: matchingUpdatedDay.minimumStay,
                            maximumStay: matchingUpdatedDay.maximumStay,
                            isAvailable: matchingUpdatedDay.isAvailable,
                            note: matchingUpdatedDay.note,
                            status: matchingUpdatedDay.status,
                        };
                    }
                    return day;
                });
                return { ...property, calendarData: updatedCalendarData };
            }
            return property;
        }));
    }, []);

    const handleReservationClick = useCallback((reservation) => {
        saveScrollPosition();
        setSelectedEvent(reservation);
    }, [saveScrollPosition]);

    const handleCloseDialog = useCallback(() => {
        setSelectedEvent(null);
        setTimeout(restoreScrollPosition, 0);
    }, [restoreScrollPosition]);

    const handleCloseUpdateCalendarDialog = useCallback(() => {
        setIsPopupOpen(false);
        setTimeout(restoreScrollPosition, 0);
    }, [restoreScrollPosition]);

    const isSameDay = useCallback((date1, date2) => {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }, []);

    const isDateBetween = useCallback((date, start, end) => {
        const checkDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const checkStart = new Date(start.getFullYear(), start.getMonth(), start.getDate());
        const checkEnd = new Date(end.getFullYear(), end.getMonth(), end.getDate());
        return checkDate >= checkStart && checkDate <= checkEnd;
    }, []);

    const handlePriceStayClick = useCallback((property, date, dayData) => {
        saveScrollPosition();
        const selectedDate = moment(date).format('YYYY-MM-DD');

        const allListingsDayData = properties.map(prop => ({
            property: prop,
            dayData: prop.calendarData.find(d => moment(d.date).format('YYYY-MM-DD') === selectedDate) || null
        }));

        setSelectedDay({
            property,
            date,
            dayData,
            allListingsDayData
        });
        setIsPopupOpen(true);
    }, [properties, saveScrollPosition]);

    const isDateLoading = useCallback((date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        return loadingDateRanges.some(range =>
            moment(formattedDate).isBetween(range.start, range.end, null, '[]')
        );
    }, [loadingDateRanges]);

    const renderDateCell = useCallback((property, date) => {
        const dayData = property.calendarData.find(d => isSameDay(new Date(d.date), date));
        const isLoading = isDateLoading(date);

        if (isLoading) {
            return (
                <div className="date-cell loading flex items-center justify-center">
                    <div className="w-full h-12 bg-gray-50 rounded-md">
                        <div className="animate-pulse">
                            <div className="flex flex-col gap-2 items-center">
                                <div className="h-1 bg-gray-200 rounded w-5/6 mt-3"></div>
                                <div className="h-1 bg-gray-200 rounded w-12"></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (!dayData) {
            return (
                <div className="date-cell loading flex items-center justify-center">
                    <div className="w-full h-12 bg-gray-50 animate-pulse"></div>
                </div>
            );
        }

        const hasReservation = dayData.reservations.length > 0;
        const reservation = hasReservation ? dayData.reservations[0] : null;

        const renderPriceStayInfo = () => (
            <div className="flex flex-col items-center justify-center !h-[50px] cursor-pointer" onClick={() => handlePriceStayClick(property, date, dayData)}>
                <div><span className="text-[11.5px] font-semibold italic text-gray-500">{dayData.price} DH</span></div>
                {selectedItems.includes('Minimum stay') && (
                    <div><span className="text-[9.5px] font-semibold italic text-gray-400">{dayData.minimumStay} Nights</span></div>
                )}
            </div>
        );

        return (
            <div className="date-cell">
                <div className="p-2">
                    {selectedItems.includes('Reservation') && hasReservation && isDateBetween(date, new Date(reservation.arrivalDate), new Date(reservation.departureDate)) ? (
                        renderReservation(reservation, date)
                    ) : (
                        renderPriceStayInfo()
                    )}
                </div>
            </div>
        );
    }, [handlePriceStayClick, isDateLoading, isSameDay, selectedItems]);

    const renderReservation = useCallback((reservation, date) => {
        const startDate = new Date(reservation.arrivalDate);
        const endDate = new Date(reservation.departureDate);
        endDate.setDate(endDate.getDate() - 1);
        const isFirstDay = isSameDay(date, startDate);
        const daysLeft = Math.max(
            Math.ceil((endDate - date) / (1000 * 60 * 60 * 24)),
            1
        );
        const calendarStartDate = daysInView[0];
        const now = new Date();

        if (isDateBetween(date, startDate, endDate)) {
            const width = `calc(${daysLeft * 100}% + ${(daysLeft - 1) * 12}px)`;
            return (
                <div
                    className="reservation-event !top-[27px]"
                    style={{
                        width: width,
                        maxWidth: `calc(100% * ${daysLeft} - 0px)`,
                        backgroundColor: '#66dac3',
                        zIndex: isFirstDay ? 1 : 0,
                    }}
                    onClick={() => handleReservationClick(reservation)}
                >
                    {(isFirstDay || (startDate < calendarStartDate && isSameDay(date, calendarStartDate))) && (
                        <span className='text-white font-semibold text-xs'>
                            {`${startDate < now ? reservation.guestName : reservation.guestName}`}
                        </span>
                    )}
                </div>
            );
        }
        return null;
    }, [handleReservationClick, isDateBetween, isSameDay, daysInView]);

    const onLazyLoad = useCallback((event) => {
        setLazyLoading(true);
        if (loadLazyTimeout.current) {
            clearTimeout(loadLazyTimeout.current);
        }

        loadLazyTimeout.current = setTimeout(() => {
            const { first, last } = event;
            const _properties = [...properties];
            for (let i = first; i < last; i++) {
                if (!_properties[i]) {
                    _properties[i] = initialProperties[i];
                }
            }
            setProperties(_properties);
            setLazyLoading(false);
        }, 250);
    }, [properties, initialProperties]);

    const itemTemplate = useCallback((property, options) => {

        if (!property) return null;

        return (
            <div className="flex bg-white" style={{ height: rowHeight, borderBottom: '1px solid #dee2e6' }}>
                {allDays.map((day, dayIndex) => (
                    <div key={dayIndex} style={{ width: columnWidth, minWidth: columnWidth }}>
                        {renderDateCell(property, day)}
                    </div>
                ))}
            </div>
        );
    }, [allDays, renderDateCell, rowHeight, columnWidth]);

    const headerTemplate = useCallback(() => (
        <div className="flex bg-gray-50" style={{ height: 30, position: 'sticky', top: 0, zIndex: 1 }}>
            {allDays.map((day, index) => (
                <div key={index} style={{ width: columnWidth, minWidth: columnWidth }} className="calendar-header-cell bg-gray-50">
                    <span className="text-xs font-semibold">{formatDate(day)}</span>
                </div>
            ))}
        </div>
    ), [allDays, formatDate, columnWidth]);



    return (
        <div className='w-ful h-full card'>
            {isLoading && (
                <div className="absolute">
                    {/* <CircularProgress color="inherit" className='text-sm' /> */}
                </div>
            )}

            <div className="flex bg-white">
                <SidebarProperty properties={properties} />
                <div style={{ flex: 1, overflow: 'hidden' }}>
                    <div
                        ref={scrollerRef}
                        style={{ height: '100%', overflowX: 'auto'}}    
                        onScroll={debouncedHandleScroll}
                    >
                        {headerTemplate()}
                        <VirtualScroller
                            items={properties}
                            itemSize={rowHeight}
                            itemTemplate={itemTemplate}
                            style={{ width: columnWidth * totalColumns, paddingBottom: '10px'}}
                            scrollHeight="100%"
                            onLazyLoad={onLazyLoad}
                            loading={lazyLoading}
                            delay={0}
                            lazy
                        />
                    </div>
                </div>
            </div>

            {isPopupOpen && (
                <UpdateCalendarDialog
                    isOpen={isPopupOpen}
                    onClose={handleCloseUpdateCalendarDialog}
                    selectedDay={selectedDay}
                    onUpdate={handleUpdateCalendar}
                    properties={properties}
                />
            )}
            {selectedEvent && (
                <ModelEventDialog
                    eventSelect={selectedEvent}
                    onClose={handleCloseDialog}
                />
            )}
        </div>
    );
});

export default MultiTableCalendar;