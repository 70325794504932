import React, { useState, useEffect } from 'react';
import { Reorder, AnimatePresence, motion } from 'framer-motion';
import {
    Box,
    Grid,
    Card,
    CardContent,
    Typography,
    TableContainer,
    Paper,
    CircularProgress,
    IconButton,
    Tooltip,
    Skeleton,
    Alert,
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Home, Building2} from 'lucide-react';
import { alpha } from '@mui/material/styles';
import { getListingMapping, updateListingMapping } from '../services/serverApi.listing';
import { ToastContainer, toast } from 'react-toastify';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';

function ListingsMap() {
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isDragging, setIsDragging] = useState(false);
    const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
console.log(listings);

    const fetchListings = async () => {
        try {
            const response = await getListingMapping(staging);
            const listings = response?.data?.listingsMapping?.map(listing => ({
                id: listing.listing._id,
                name: listing.listing.name,
                propertyUnit: listing.listing.propertyUnit,
            })) || [];
            setListings(listings);
            setLoading(false);
        } catch (error) {
            toast.error("Failed to fetch listings");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchListings();
    }, [staging]);

    const handleReorder = async (newOrder) => {
        setListings(newOrder);
        try {
            await updateListingMapping({
                listingsMapping: newOrder.map(item => item.id),
                staging: staging,
            });
            toast.success("Listing order updated successfully");
        } catch (error) {
            console.error("Error updating listing order:", error);
            toast.error("Failed to update listing order");
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    theme="colored"
                />
                <Box sx={{ width: '100%', padding: 3 }}>
                    <Card
                        elevation={3}
                        sx={{
                            padding: 3,
                            marginBottom: 3,
                            background: 'linear-gradient(to right bottom, #ffffff, #f8f9fa)',
                        }}
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            marginBottom={3}
                        >
                            <Typography
                                variant="h4"
                                fontWeight="bold"
                                sx={{
                                    background: 'linear-gradient(45deg, #2196F3, #21CBF3)',
                                    backgroundClip: 'text',
                                    WebkitBackgroundClip: 'text',
                                    color: 'transparent',
                                }}
                            >
                                Listings Mapping
                            </Typography>
                            {!loading && (
                                
                                  <span className='text-sm text-gray-400'> {listings.length} listings found</span> 
                            
                            )}
                        </Box>

                        {loading ? (
                            <Box sx={{ padding: 2 }}>
                                {[1, 2, 3].map((item) => (
                                    <Skeleton
                                        key={item}
                                        variant="rectangular"
                                        height={80}
                                        sx={{
                                            marginBottom: 2,
                                            borderRadius: 1,
                                        }}
                                    />
                                ))}
                            </Box>
                        ) : listings.length === 0 ? (
                            <Alert severity="info" sx={{ marginTop: 2 }}>
                                No listings found. Add some listings to get started.
                            </Alert>
                        ) : (
                            <TableContainer
                                component={Paper}
                                elevation={0}
                                sx={{
                                    bgcolor: 'transparent',
                                    transition: 'all 0.3s ease',
                                }}
                            >
                                <AnimatePresence>
                                    <Reorder.Group
                                        axis="y"
                                        values={listings}
                                        onReorder={handleReorder}
                                    >
                                        {listings.map((listing, index) => (
                                            <ListingItem
                                                key={listing.id}
                                                listing={listing}
                                                index={index}
                                                isDragging={isDragging}
                                                setIsDragging={setIsDragging}
                                            />
                                        ))}
                                    </Reorder.Group>
                                </AnimatePresence>
                            </TableContainer>
                        )}
                    </Card>
                </Box>
            </Grid>
        </DashboardLayout>
    );
}

const ListingItem = ({ listing, index, isDragging, setIsDragging }) => {
    const isTopListing = index <= 2;

    return (
        <Reorder.Item
            value={listing}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            onDragStart={() => setIsDragging(true)}
            onDragEnd={() => setIsDragging(false)}
        >
            <Card
                elevation={isDragging ? 4 : 1}
                sx={{
                    marginBottom: 2,
                    backgroundColor: isTopListing ? alpha('#2196F3', 0.08) : 'white',
                    transition: 'all 0.3s ease',
                    borderLeft: isTopListing ? '4px solid #2196F3' : 'none',
                    '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: 3,
                        backgroundColor: isTopListing ? alpha('#2196F3', 0.12) : alpha('#000', 0.02),
                    },
                }}
            >
                <CardContent
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '16px !important',
                    }}
                >
                    <Box display="flex" alignItems="center" gap={2}>
                        <Tooltip title="Drag to reorder">
                            <IconButton
                                size="small"
                                sx={{
                                    cursor: 'grab',
                                    '&:active': { cursor: 'grabbing' },
                                }}
                            >
                                <DragIndicatorIcon />
                            </IconButton>
                        </Tooltip>
                        <Typography
                            variant="body1"
                            sx={{
                                fontWeight: isTopListing ? 600 : 400,
                                color: '#1976D2',
                            }}
                        >
                            {listing.name}
                        </Typography>
                    </Box>
                    {listing.propertyUnit === 'Single' ? (
                        <Tooltip title="Apartment"><Home size={16} className='!text-gray-500'/></Tooltip> 
                    ) : (
                        <Tooltip title="Hotel"><Building2 size={16} className='!text-gray-500'/></Tooltip> 
                    )}
                </CardContent>
            </Card>
        </Reorder.Item>
    );
};

export default ListingsMap;