import React, { useState } from 'react';
import { Modal, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';

export const ImageGalleryModal = ({ images, initialIndex, onClose }) => {
    const [currentIndex, setCurrentIndex] = useState(initialIndex);

    const handleNext = () => {
        setCurrentIndex((prev) => (prev + 1) % images.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);
    };

    return (
        <Modal
            open={true}
            onClose={onClose}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiBackdrop-root': {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
                '& .MuiModal-backdrop': {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                },
                '& .MuiModal-root': {
                    outline: 'none',
                },
            }}
            disableAutoFocus
            disableEnforceFocus
            classes={{
                root: 'outline-none',
            }}
        >
            <div className="relative max-w-4xl w-full h-[80vh] flex items-center justify-center outline-none">
                <IconButton
                    onClick={handlePrevious}
                    sx={{
                        position: 'absolute',
                        left: '10px',
                        color: 'white',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' }
                    }}
                >
                    <ArrowBackIosIcon />
                </IconButton>

                <img
                    src={images[currentIndex].url}
                    alt={`Image ${currentIndex + 1}`}
                    className="max-h-full max-w-full object-contain outline-none"
                    style={{ WebkitTapHighlightColor: 'transparent' }}
                />

                <IconButton
                    onClick={handleNext}
                    sx={{
                        position: 'absolute',
                        right: '10px',
                        color: 'white',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        '&:hover': { backgroundColor: 'rgba(0,0,0,0.7)' }
                    }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>

                <div className="absolute bottom-4 left-0 right-0 flex justify-center gap-2">
                    {images.map((_, index) => (
                        <button
                            key={index}
                            className={`w-2 h-2 rounded-full outline-none ${
                                index === currentIndex ? 'bg-white' : 'bg-gray-500'
                            }`}
                            onClick={() => setCurrentIndex(index)}
                        />
                    ))}
                </div>
            </div>
        </Modal>
    );
};

export const ImageGalleryTrigger = ({ images, className = '' }) => {
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [initialImageIndex, setInitialImageIndex] = useState(0);

    const handleImageClick = (index) => {
        setInitialImageIndex(index);
        setGalleryOpen(true);
    };

    return (
        <>
            <div className={`flex gap-2 ${className}`}>
                {images.slice(0, 2).map((image, index) => (
                    <img
                        key={image._id || index}
                        src={image.url}
                        alt={`Image ${index + 1}`}
                        className="w-16 h-16 object-cover rounded cursor-pointer outline-none"
                        onClick={() => handleImageClick(index)}
                    />
                ))}
            </div>

            {galleryOpen && (
                <ImageGalleryModal
                    images={images}
                    initialIndex={initialImageIndex}
                    onClose={() => setGalleryOpen(false)}
                />
            )}
        </>
    );
};

export default ImageGalleryTrigger;