import { createSlice } from '@reduxjs/toolkit';

export const dateSlice = createSlice({
  name: 'YearDate',
  initialState: {
    YearDate: null,
    ListingId: null,
    slotDate: null,
    idListingBtn:null,
    Calendarfilter:['Reservation']
  },
  reducers: {
    setYearDate: (state, action) => {
      state.YearDate = action.payload;
    },
    setListingId: (state, action) => {
      state.ListingId = action.payload;
    },
    setSlotDate: (state, action) => {
      state.slotDate = action.payload;
    },
    setListingIdBtn:(state, action) => {
      state.idListingBtn = action.payload;
    },
    setCalendarfilter:(state, action) => {
      state.Calendarfilter = action.payload;
    },
   
  },
});

export const { setYearDate,setListingId,setSlotDate,setListingIdBtn,setCalendarfilter } = dateSlice.actions;

export default dateSlice.reducer;
