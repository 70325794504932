import React, { useState, useRef, useEffect } from 'react';
import { Box, MenuItem, TextField, Modal, Typography, Select, InputLabel, FormControl, FormControlLabel, Switch } from '@mui/material';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { defaultValues } from './helper/Options';
import { getLanguage } from 'features/listing/services/serverApi.listing';
import VerificationItems from './VerificationItems';



const Cleaning = ({ formik }) => {
  const [editingRows, setEditingRows] = useState({ TS_CLEAN: null, frequency: null, TS_CHECKIN: null, TS_CHECKOUT: null });
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [expandedTables, setExpandedTables] = useState({ TS_CLEAN: false, frequency: false, TS_CHECKIN: false, TS_CHECKOUT: false });
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    if (!formik.values.TS_CLEAN || formik.values.TS_CLEAN.length === 0) {
      formik.setFieldValue('TS_CLEAN', defaultValues.TS_CLEAN);
    }
    if (!formik.values.frequency || formik.values.frequency.length === 0) {
      formik.setFieldValue('frequency', defaultValues.frequency || []);
    }
    if (!formik.values.TS_CHECKIN || formik.values.TS_CHECKIN.length === 0) {
      formik.setFieldValue('TS_CHECKIN', defaultValues.TS_CHECKIN || []);
    }
    if (!formik.values.TS_CHECKOUT || formik.values.TS_CHECKOUT.length === 0) {
      formik.setFieldValue('TS_CHECKOUT', defaultValues.TS_CHECKOUT || []);
    }

    if (formik.values.clean === undefined) {
      formik.setFieldValue('clean', false);
    }
    if (formik.values.checkin === undefined) {
      formik.setFieldValue('checkin', false);
    }
    if (formik.values.checkout === undefined) {
      formik.setFieldValue('checkout', false);
    }
    if (formik.values.syndic === undefined) {
      formik.setFieldValue('syndic', false);
    }
    if (formik.values.assit === undefined) {
      formik.setFieldValue('assit', false);
    }
    if (formik.values.taskVerification === undefined) {
      formik.setFieldValue('taskVerification', false);
    }
  }, []);



  useEffect(() => {

    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [languagesResponse] = await Promise.all([
        getLanguage()
      ]);
      setLanguages(languagesResponse.data);
    } catch (error) {
      console.error('Error while fetching languages:', error);
    }
  };

  const getDisplayValues = (type) => {
    return (formik.values[type] && formik.values[type].length > 0)
      ? formik.values[type]
      : (defaultValues[type] || []);
  };

  const handleAddSlot = (type) => {
    let newSlot;
    if (type === 'frequency') {
      newSlot = { id: formik.values[type].length, startDay: '', endDay: '', numberOfCleaning: 0, isNew: true };
    } else {
      newSlot = { id: formik.values[type].length, start: '', end: '', type: 'Normal', price: 0, default: false, isNew: true };
    }
    const updatedSlots = [...formik.values[type], newSlot];
    formik.setFieldValue(type, updatedSlots);
    setEditingRows(prev => ({ ...prev, [type]: newSlot }));
    setExpandedTables(prev => ({ ...prev, [type]: true }));
  };

  const handleDefaultChange = (event, type, id) => {
    const newValue = event.target.value === "Yes";
    const updatedSlots = formik.values[type].map((slot, index) => ({
      ...slot,
      default: index === id ? newValue : false
    }));
    formik.setFieldValue(type, updatedSlots);
  };

  const handleSaveNewSlot = (rowData, type) => {
    let updatedSlot;
    if (type === 'frequency') {
      updatedSlot = { startDay: Number(rowData.startDay), endDay: Number(rowData.endDay), numberOfCleaning: Number(rowData.numberOfCleaning) };
    } else {
      updatedSlot = {
        start: Number(rowData.start),
        end: Number(rowData.end),
        type: rowData.type || 'Normal',
        price: Number(rowData.price) || 0,
        default: rowData.default || false
      };
    }
    const updatedSlots = formik.values[type].map((slot, index) =>
      index === rowData.id ? updatedSlot : slot
    );
    formik.setFieldValue(type, updatedSlots);
    setEditingRows(prev => ({ ...prev, [type]: null }));
  };

  const handleUpdateRow = (rowData, type) => {
    let updatedSlot;
    if (type === 'frequency') {
      updatedSlot = { ...rowData, startDay: Number(rowData.startDay), endDay: Number(rowData.endDay), numberOfCleaning: Number(rowData.numberOfCleaning) };
    } else {
      updatedSlot = {
        ...rowData,
        start: Number(rowData.start),
        end: Number(rowData.end),
        type: rowData.type || 'Normal',
        price: Number(rowData.price) || 0,
        default: rowData.default || false
      };
    }
    const updatedSlots = formik.values[type].map((slot, index) =>
      index === rowData.id ? updatedSlot : slot
    );
    formik.setFieldValue(type, updatedSlots);
    setEditingRows(prev => ({ ...prev, [type]: null }));
  };

  const handleDelete = (type) => {
    const updatedSlots = formik.values[type].filter((_, index) => index !== selectedSlot.id);
    formik.setFieldValue(type, updatedSlots);
    setSelectedSlot(null);
  };

  const handleStartEditing = (rowData, type) => {
    setEditingRows(prev => ({ ...prev, [type]: { ...rowData } }));
  };

  const handleCancelEditing = (type) => {
    if (editingRows[type]?.isNew) {
      const updatedSlots = formik.values[type].filter((slot, index) => index !== editingRows[type].id);
      formik.setFieldValue(type, updatedSlots);
    }
    setEditingRows(prev => ({ ...prev, [type]: null }));
  };

  const handleEditingInputChange = (e, type) => {
    const { name, value } = e.target;
    setEditingRows(prev => ({ ...prev, [type]: { ...prev[type], [name]: value } }));
    if (type === 'frequency') {
      formik.setFieldValue(`${type}.${editingRows[type].id}.${name}`, value);
      formik.validateField(`${type}.${editingRows[type].id}.${name}`);
    }
  };

  const toggleTable = (type) => {
    setExpandedTables(prev => ({ ...prev, [type]: !prev[type] }));
  };

  const columns = {
    TS_CLEAN: [
      {
        header: "Start Time",
        body: (rowData) => (
          editingRows.TS_CLEAN?.id === rowData.id ? (
            <TextField
              type="number"
              name="start"
              value={editingRows.TS_CLEAN.start}
              onChange={(e) => handleEditingInputChange(e, 'TS_CLEAN')}
              fullWidth
            />
          ) : (
            <span onDoubleClick={() => handleStartEditing(rowData, 'TS_CLEAN')}>{rowData.start}</span>
          )
        ),


      },

      {
        header: "End Time",
        body: (rowData) => (
          editingRows.TS_CLEAN?.id === rowData.id ? (
            <TextField
              type="number"
              name="end"
              value={editingRows.TS_CLEAN.end}
              onChange={(e) => handleEditingInputChange(e, 'TS_CLEAN')}
              fullWidth
            />
          ) : (
            <span onDoubleClick={() => handleStartEditing(rowData, 'TS_CLEAN')}>{rowData.end}</span>
          )
        ),
      },
      {
        header: "Type",
        body: (rowData) => (
          editingRows.TS_CLEAN?.id === rowData.id ? (
            <Select
              name="type"
              value={editingRows.TS_CLEAN.type || ''}
              onChange={(e) => handleEditingInputChange(e, 'TS_CLEAN')}
              fullWidth
            >
              <MenuItem value="Normal">Normal</MenuItem>
              <MenuItem value="Early">Early</MenuItem>
              <MenuItem value="Late">Late</MenuItem>
            </Select>
          ) : (
            <span onDoubleClick={() => handleStartEditing(rowData, 'TS_CLEAN')}>{rowData.type}</span>
          )
        ),
      },
      {
        header: "Price",
        body: (rowData) => (
          editingRows.TS_CLEAN?.id === rowData.id ? (
            <TextField
              type="number"
              name="price"
              value={editingRows.TS_CLEAN.price}
              onChange={(e) => handleEditingInputChange(e, 'TS_CLEAN')}
              fullWidth
            />
          ) : (
            <span onDoubleClick={() => handleStartEditing(rowData, 'TS_CLEAN')}>{rowData.price}</span>
          )
        ),
      },
      {
        header: "Default",
        body: (rowData) => (
          <Select
            value={rowData.default ? "Yes" : "No"}
            onChange={(e) => handleDefaultChange(e, 'TS_CLEAN', rowData.id)}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        ),
      },
    ],

    TS_CHECKIN: [
      {
        header: "Start Time",
        body: (rowData) => (
          editingRows.TS_CHECKIN?.id === rowData.id ? (
            <TextField
              type="number"
              name="start"
              value={editingRows.TS_CHECKIN.start}
              onChange={(e) => handleEditingInputChange(e, 'TS_CHECKIN')}
              fullWidth
            />
          ) : (
            <span onDoubleClick={() => handleStartEditing(rowData, 'TS_CHECKIN')}>{rowData.start}</span>
          )
        ),
      },
      {
        header: "End Time",
        body: (rowData) => (
          editingRows.TS_CHECKIN?.id === rowData.id ? (
            <TextField
              type="number"
              name="end"
              value={editingRows.TS_CHECKIN.end}
              onChange={(e) => handleEditingInputChange(e, 'TS_CHECKIN')}
              fullWidth
            />
          ) : (
            <span onDoubleClick={() => handleStartEditing(rowData, 'TS_CHECKIN')}>{rowData.end}</span>
          )
        ),
      },
      {
        header: "Type",
        body: (rowData) => (
          editingRows.TS_CHECKIN?.id === rowData.id ? (
            <Select
              name="type"
              value={editingRows.TS_CHECKIN.type || ''}
              onChange={(e) => handleEditingInputChange(e, 'TS_CHECKIN')}
              fullWidth
            >
              <MenuItem value="Normal">Normal</MenuItem>
              <MenuItem value="Early">Early</MenuItem>
              <MenuItem value="Late">Late</MenuItem>
            </Select>
          ) : (
            <span onDoubleClick={() => handleStartEditing(rowData, 'TS_CHECKIN')}>{rowData.type}</span>
          )
        ),
      },
      {
        header: "Price",
        body: (rowData) => (
          editingRows.TS_CHECKIN?.id === rowData.id ? (
            <TextField
              type="number"
              name="price"
              value={editingRows.TS_CHECKIN.price}
              onChange={(e) => handleEditingInputChange(e, 'TS_CHECKIN')}
              fullWidth
            />
          ) : (
            <span onDoubleClick={() => handleStartEditing(rowData, 'TS_CHECKIN')}>{rowData.price}</span>
          )
        ),
      },
      {
        header: "Default",
        body: (rowData) => (
          <Select
            value={rowData.default ? "Yes" : "No"}
            onChange={(e) => handleDefaultChange(e, 'TS_CHECKIN', rowData.id)}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        ),
      },
    ],
    TS_CHECKOUT: [
      {
        header: "Start Time",
        body: (rowData) => (
          editingRows.TS_CHECKOUT?.id === rowData.id ? (
            <TextField
              type="number"
              name="start"
              value={editingRows.TS_CHECKOUT.start}
              onChange={(e) => handleEditingInputChange(e, 'TS_CHECKOUT')}
              fullWidth
            />
          ) : (
            <span onDoubleClick={() => handleStartEditing(rowData, 'TS_CHECKOUT')}>{rowData.start}</span>
          )
        ),
      },
      {
        header: "End Time",
        body: (rowData) => (
          editingRows.TS_CHECKOUT?.id === rowData.id ? (
            <TextField
              type="number"
              name="end"
              value={editingRows.TS_CHECKOUT.end}
              onChange={(e) => handleEditingInputChange(e, 'TS_CHECKOUT')}
              fullWidth
            />
          ) : (
            <span onDoubleClick={() => handleStartEditing(rowData, 'TS_CHECKOUT')}>{rowData.end}</span>
          )
        ),
      },
      {
        header: "Type",
        body: (rowData) => (
          editingRows.TS_CHECKOUT?.id === rowData.id ? (
            <Select
              name="type"
              value={editingRows.TS_CHECKOUT.type || ''}
              onChange={(e) => handleEditingInputChange(e, 'TS_CHECKOUT')}
              fullWidth
            >
              <MenuItem value="Normal">Normal</MenuItem>
              <MenuItem value="Early">Early</MenuItem>
              <MenuItem value="Late">Late</MenuItem>
            </Select>
          ) : (
            <span onDoubleClick={() => handleStartEditing(rowData, 'TS_RECEPT')}>{rowData.type}</span>
          )
        ),
      },
      {
        header: "Price",
        body: (rowData) => (
          editingRows.TS_CHECKOUT?.id === rowData.id ? (
            <TextField
              type="number"
              name="price"
              value={editingRows.TS_CHECKOUT.price}
              onChange={(e) => handleEditingInputChange(e, 'TS_CHECKOUT')}
              fullWidth
            />
          ) : (
            <span onDoubleClick={() => handleStartEditing(rowData, 'TS_CHECKOUT')}>{rowData.price}</span>
          )
        ),
      },
      {
        header: "Default",
        body: (rowData) => (
          <Select
            value={rowData.default ? "Yes" : "No"}
            onChange={(e) => handleDefaultChange(e, 'TS_CHECKOUT', rowData.id)}
          >
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        ),
      },
    ],
    frequency: [
      {
        header: "Start Day",
        body: (rowData) => (
          editingRows.frequency?.id === rowData.id ? (
            <TextField
              type="number"
              name="startDay"
              value={editingRows.frequency.startDay}
              fullWidth
              onChange={(e) => handleEditingInputChange(e, 'frequency')}
              onBlur={() => formik.validateField(`frequency.${rowData.id}.startDay`)}
              error={Boolean(formik.errors.frequency?.[rowData.id]?.startDay)}
              helperText={formik.errors.frequency?.[rowData.id]?.startDay}
            />
          ) : (
            <span onDoubleClick={() => handleStartEditing(rowData, 'frequency')}>{rowData.startDay}</span>
          )
        ),
      },
      {
        header: "End Day",
        body: (rowData) => (
          editingRows.frequency?.id === rowData.id ? (
            <TextField
              type="number"
              name="endDay"
              value={editingRows.frequency.endDay}
              fullWidth
              onChange={(e) => handleEditingInputChange(e, 'frequency')}
              onBlur={() => formik.validateField(`frequency.${rowData.id}.endDay`)}
              error={Boolean(formik.errors.frequency?.[rowData.id]?.endDay)}
              helperText={formik.errors.frequency?.[rowData.id]?.endDay}
            />
          ) : (
            <span onDoubleClick={() => handleStartEditing(rowData, 'frequency')}>{rowData.endDay}</span>
          )
        ),
      },
      {
        header: "Number of Cleaning",
        body: (rowData) => (
          editingRows.frequency?.id === rowData.id ? (
            <TextField
              type="number"
              name="numberOfCleaning"
              value={editingRows.frequency.numberOfCleaning}
              onChange={(e) => handleEditingInputChange(e, 'frequency')}
              fullWidth
              error={Boolean(formik.errors.frequency?.[rowData.id]?.numberOfCleaning)}
              helperText={formik.errors.frequency?.[rowData.id]?.numberOfCleaning}
            />
          ) : (
            <span onDoubleClick={() => handleStartEditing(rowData, 'frequency')}>{rowData.numberOfCleaning}</span>
          )
        ),
      },
    ],
  };

  const actionColumn = {
    header: "Action",
    body: (rowData, type) => (
      <div className="flex gap-1">
        {editingRows[type]?.id === rowData.id ? (
          <>
            {rowData.isNew ? (
              <button type='button' className="px-2 py-1 bg-[#6ad1d1] rounded-sm" onClick={() => handleSaveNewSlot(editingRows[type], type)}>
                <span className="text-[13px] text-white">Save</span>
              </button>
            ) : (
              <button type='button' className="px-2 py-1 bg-[#6ad1d1] rounded-sm" onClick={() => handleUpdateRow(editingRows[type], type)}>
                <span className="text-[13px] text-white">Save</span>
              </button>
            )}
            <button type='button' className="px-2 py-1 bg-gray-400 rounded-sm" onClick={() => handleCancelEditing(type)}>
              <span className="text-[13px] text-white">Cancel</span>
            </button>
          </>
        ) : (
          <>
            <button type='button' className="px-2 py-1 bg-[#6ad1d1] rounded-sm" onClick={() => handleStartEditing(rowData, type)}>
              <span className="text-[13px] text-white">Update</span>
            </button>
            <button type='button' className="px-2 py-1 bg-[#df5454] rounded-sm" onClick={() => setSelectedSlot({ ...rowData, type })}>
              <span className="text-[13px] text-white">Delete</span>
            </button>
          </>
        )}
      </div>
    ),
  };

  const renderTable = (type, title) => {
    const displayValues = getDisplayValues(type);
    const slotsSummary = type === 'frequency'
      ? (displayValues || []).map(slot => `${slot.startDay}-${slot.endDay}:${slot.numberOfCleaning}`).join(', ')
      : (displayValues || []).map(slot => `${slot.start}/${slot.end}`).join(', ');

    const tableRef = useRef(null);
    const [tableHeight, setTableHeight] = useState('0px');

    useEffect(() => {
      if (expandedTables[type]) {
        setTableHeight(`${tableRef.current.scrollHeight}px`);
      } else {
        setTableHeight('0px');
      }
    }, [expandedTables[type], displayValues]);

    return (
      <div className="mb-8">
        <div className="flex items-center my-1 w-full">
          {expandedTables[type] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          <span className="text-sm font-semibold ml-2 cursor-pointer" onClick={() => toggleTable(type)}>
            {title}
          </span>
          <span className='text-sm text-gray-400 mx-1'>{slotsSummary}</span>
          <button type="button" className='px-2 py-[10px] mx-1 bg-slate-200 !rounded-md' onClick={() => handleAddSlot(type)}>
            <AddIcon className='!text-sm' />
          </button>
        </div>

        <div
          ref={tableRef}
          style={{ height: tableHeight }}
          className="transition-height duration-300 ease-in-out overflow-hidden"
        >
          {(displayValues && displayValues.length > 0) ? (
            <div className="w-full">
              <GlobalTable
                data={displayValues.map((slot, index) => ({ ...slot, id: index }))}
                columns={[...columns[type], { ...actionColumn, body: (rowData) => actionColumn.body(rowData, type) }]}

                hasPagination={false}
              />
            </div>
          ) : (
            <div className="w-full h-48 flex justify-center items-center">
              <span className="text-gray-500 text-[20px]">No {title.toLowerCase()} added yet</span>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full p-4 h-auto">
      <div className="flex justify-between mb-6">
        <span className="text-md font-semibold">Cleaning</span>
      </div>
      <div className="flex flex-col gap-4 mt-3 mb-4">
        <div className="flex gap-4">
          <FormControlLabel
            control={
              <Switch
                name="clean"
                checked={formik.values.clean}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#00b4b4',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 180, 180, 0.08)',
                    },
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#00b4b4',
                  },
                }}
              />
            }
            label="Clean"
          />
          <FormControlLabel
            control={
              <Switch
                name="checkin"
                checked={formik.values.checkin}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#00b4b4',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 180, 180, 0.08)',
                    },
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#00b4b4',
                  },
                }}
              />
            }
            label="Check-in"
          />
          <FormControlLabel
            control={
              <Switch
                name="checkout"
                checked={formik.values.checkout}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#00b4b4',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 180, 180, 0.08)',
                    },
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#00b4b4',
                  },
                }}
              />
            }
            label="Checkout"
          />
          <FormControlLabel
            control={
              <Switch
                name="syndic"
                checked={formik.values.syndic}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#00b4b4',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 180, 180, 0.08)',
                    },
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#00b4b4',
                  },
                }}
              />
            }
            label="Syndic"
          />
          <FormControlLabel
            control={
              <Switch
                name="assit"
                checked={formik.values.assit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#00b4b4',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 180, 180, 0.08)',
                    },
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#00b4b4',
                  },
                }}
              />
            }
            label="Assit"
          />
          <FormControlLabel
            control={
              <Switch
                name="taskVerification"
                checked={formik.values.taskVerification}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                sx={{
                  '& .MuiSwitch-switchBase.Mui-checked': {
                    color: '#00b4b4',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 180, 180, 0.08)',
                    },
                  },
                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                    backgroundColor: '#00b4b4',
                  },
                }}
              />
            }
            label="Task Verification"
          />
        </div>




        <div className="flex gap-2">
          <div className="w-full">
            <TextField
              label="Clean Price"
              type="number"
              name="cleanPrice"
              value={formik.values.cleanPrice}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              error={formik.touched.cleanPrice && Boolean(formik.errors.cleanPrice)}
            />
            {formik.touched.cleanPrice && formik.errors.cleanPrice && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.cleanPrice}</div>
            )}
          </div>
          <FormControl fullWidth error={formik.touched.RECEPT && Boolean(formik.errors.RECEPT)}>
            <InputLabel id="recept-label">RECEPT</InputLabel>
            <Select
              labelId="recept-label"
              name="RECEPT"
              value={formik.values.RECEPT}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="Auto">Auto</MenuItem>
              <MenuItem value="Manu">Manual</MenuItem>
            </Select>
            {formik.touched.RECEPT && formik.errors.RECEPT && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.RECEPT}</div>
            )}
          </FormControl>
        </div>
        <div className='w-1/2'>
          <FormControl fullWidth error={formik.touched.cleaningType && Boolean(formik.errors.cleaningType)}>
            <InputLabel id="cleaning-type-label">Cleaning Type</InputLabel>
            <Select
              labelId="cleaning-type-label"
              name="cleaningType"
              value={formik.values.cleaningType}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="days">Days</MenuItem>
              <MenuItem value="frequence">frequence</MenuItem>
            </Select>
            {formik.touched.cleaningType && formik.errors.cleaningType && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.cleaningType}</div>
            )}
          </FormControl>
        </div>
        <div className="flex gap-4 w-1/2">
          <div className='w-full'>
            <TextField
              label="Clean Number"
              type="number"
              name="cleanNumber"
              value={formik.values.cleanNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              error={formik.touched.cleanNumber && Boolean(formik.errors.cleanNumber)}
            />
            {formik.touched.cleanNumber && formik.errors.cleanNumber && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.cleanNumber}</div>
            )}
          </div>
          <div className="w-full">
            <TextField
              label="Min Days For Cleaning"
              type="number"
              name="minDaysForCleaning"
              value={formik.values.minDaysForCleaning}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              fullWidth
              error={formik.touched.minDaysForCleaning && Boolean(formik.errors.minDaysForCleaning)}
            />
            {formik.touched.minDaysForCleaning && formik.errors.minDaysForCleaning && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.minDaysForCleaning}</div>
            )}
          </div>
        </div>


      </div>





      {formik.errors.frequency && (
        <div className="z-10">
          <span className='text-red-500 text-sm'>Start day must be greater than the previous end day</span>
        </div>
      )}
      <VerificationItems formik={formik} languages={languages} />

      {renderTable('frequency', 'frequency')}
      {renderTable('TS_CLEAN', 'Cleaning')}
      {renderTable('TS_CHECKIN', 'Check-in')}
      {renderTable('TS_CHECKOUT', 'Checkout')}
      <Modal open={Boolean(selectedSlot)} onClose={() => setSelectedSlot(null)}>
        <Box className="absolute top-36 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[550px] rounded-sm shadow-[24] bg-[#f8fafb]">
          <Box className="w-full mb-11 p-4">
            <Typography className="!text-[14px] !text-center" id="modal-modal-description">
              Are you sure you want to delete this cleaning slot?
            </Typography>
          </Box>
          <Box className="flex justify-end p-3 bg-white border-t-1 border-gray-200">
            <Box className="flex gap-2">
              <button
                className="px-3 py-1 bg-[#d6fff7] text-[#70d4c0] !rounded-sm mr-2 !text-[16px]"
                onClick={() => setSelectedSlot(null)}
              >
                Cancel
              </button>
              <button
                className="px-3 py-1 bg-red-500 text-white !rounded-sm mr-2 !text-[16px]"
                onClick={() => handleDelete(selectedSlot.type)}
              >
                Confirm
              </button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Cleaning;