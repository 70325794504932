import React, { useState, useEffect } from 'react';
import MongoDBChartComponenet from '../components/MongoDB.Chart.component';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import { getToken } from '../services/serverApi.charts';

function MyCharts() {
  const [filter, setFilter] = useState(null);
  const [token, setToken] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    const startDate = event.target.elements.startDate.value;
    const endDate = event.target.elements.endDate.value;

    const newFilter = {
      date: {
        $gte: new Date(startDate),
        $lte: new Date(endDate),
      },
    };

    console.log('Setting filter:', newFilter);
    setFilter(newFilter);
  };

  const handleClearFilter = () => {
    console.log('Clearing filter');
    setFilter(null);
  };
  const fetchToken = () => {
    getToken()
      .then(({ data }) => data.token && setToken(data.token))
      .catch((err) => console.log('error when fetching token', err));
  };
  useEffect(() => {
    fetchToken();

    return () => {};
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <header className="App-header">
        <h1>My MongoDB Chart</h1>
        <form onSubmit={handleSubmit}>
          <input name="startDate" type="date" />
          <input name="endDate" type="date" />
          <button type="submit">Submit</button>
        </form>
        <button onClick={handleClearFilter}>Clear Filter</button>
        <MongoDBChartComponenet filter={filter} token={token} />
      </header>
    </DashboardLayout>
  );
}

export default MyCharts;
