import React, { useState, useEffect } from 'react';
import { getCountries, getCities } from 'features/reservation/services/serverApi.reservation';

const ReservationDetails = ({ formik, listingOptions }) => {
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const selectedListing = listingOptions.find(listing => listing.id === formik.values.sojoriId);
    const checkInTime = selectedListing?.checkInTime || '';
    const checkOutTime = selectedListing?.checkOutTime || '';

    useEffect(() => {
        const fetchData = async () => {
            try {
                const countriesData = await getCountries();
                setCountries(countriesData.data);

                const citiesData = await getCities();
                setCities(citiesData.data);

                const defaultCountry = countriesData.data.find(country => country.name === 'Morocco');
                const defaultCity = citiesData.data.find(city => city.name === 'Rabat');

                if (defaultCountry && !formik.values.guestCountry) {
                    formik.setFieldValue('guestCountry', defaultCountry.id);
                }
                if (defaultCity && !formik.values.guestCity) {
                    formik.setFieldValue('guestCity', defaultCity.id);
                }
            } catch (error) {
                console.error('Error fetching country and city data:', error);
            }
        };

        fetchData();
    }, []);

    const renderInputField = (label, name, type = 'text') => {
        const today = new Date().toISOString().split('T')[0];
        return (
        <div className="flex flex-col">
            <label className="font-bold text-[#676a6c] text-sm mb-2">{label}</label>
            <input
                type={type}
                name={name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[name]}
                min={type === 'date' ? today : undefined}
                className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded p-2"
                style={{
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    fontSize: 'small',
                }}
            />
            {formik.touched[name] && formik.errors[name] && (
                <div className="text-red-500 text-sm">{formik.errors[name]}</div>
            )}
        </div>
        );
    };

    const renderSelectField = (label, name, options, defaultValue) => (
        <div className="flex flex-col">
            <label className="font-bold text-[#676a6c] text-sm mb-2">{label}</label>
            <select
                name={name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values[name] || defaultValue || ''}
                className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded p-2"
                style={{
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    fontSize: 'small',
                }}
            >
                <option value="">Select {label}</option>
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            {formik.touched[name] && formik.errors[name] && (
                <div className="text-red-500 text-sm">{formik.errors[name]}</div>
            )}
        </div>
    );

    const renderTimeSelectField = (label, name) => {
        const currentHour = new Date().getHours();
        const today = new Date().toISOString().split('T')[0];
        const selectedDate = formik.values.arrivalDate || today;
        const isToday = selectedDate === today;
        const hours = Array.from({ length: 24 }, (_, i) => i);

        return (
            <div className="flex flex-col">
                <label className="font-bold text-[#676a6c] text-sm mb-2">{label}</label>
                <select
                    name={name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values[name]}
                    className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded p-2"
                    style={{
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        fontSize: 'small',
                    }}
                >
                    {hours.map(hour => (
                        <option key={hour} value={hour} disabled={isToday && hour < currentHour}>
                            {`${hour}:00`}
                        </option>
                    ))}
                </select>
                {formik.touched[name] && formik.errors[name] && (
                    <div className="text-red-500 text-sm">{formik.errors[name]}</div>
                )}
            </div>
        );
    };

    return (
        <>
            <div className="grid grid-cols-4 gap-4">
                {renderInputField('Check-in date (Listing time)', 'arrivalDate', 'date')}
                {renderInputField('Check-out date (Listing time)', 'departureDate', 'date')}
                {renderTimeSelectField('Check-in Time (Listing time)', 'checkInTime', checkInTime)}
                {renderTimeSelectField('Check-out Time (Listing time)', 'checkOutTime', checkOutTime)}
                {renderSelectField('Currency', 'currency', [
                    { value: 'MAD', label: 'MAD' },
                    { value: 'USD', label: 'USD' },
                    { value: 'EUR', label: 'EUR' },
                    { value: 'GBP', label: 'GBP' }
                ])}
                {renderSelectField('Status', 'status', [
                    { value: 'Pending', label: 'Pending' },
                    { value: 'Confirmed', label: 'Confirmed' },
                    { value: 'Completed', label: 'Completed' },
                    { value: 'CancelledByAdmin', label: 'Cancelled by Admin' },
                    { value: 'CancelledByCustomer', label: 'Cancelled by Customer' },
                    { value: 'CancelledByOta', label: 'Cancelled by OTA' },
                    { value: 'CancelledAfterFailedPayment', label: 'Cancelled After Failed Payment' },
                    { value: 'OtherCancellation', label: 'Other Cancellation' }
                ], 'Confirmed')}
                {renderSelectField('Payment Status', 'paymentStatus', [
                    { value: 'Paid', label: 'Paid' },
                    { value: 'UnPaid', label: 'UnPaid' }
                ], 'UnPaid')}
                {renderSelectField('Time Line', 'timeLine', [
                    { value: 'Coming-Today', label: 'Coming Today' },
                    { value: 'Coming-Tomorrow', label: 'Coming Tomorrow' },
                    { value: 'Coming-in2days', label: 'Coming in 2 days' },
                    { value: 'Coming-in3days', label: 'Coming in 3 days' },
                    { value: 'Normal', label: 'Normal' },
                    { value: 'Departure-today', label: 'Departure Today' },
                    { value: 'Departure-tomorrow', label: 'Departure Tomorrow' },
                    { value: 'Inside', label: 'Inside' },
                    { value: 'Leave', label: 'Leave' },
                    { value: 'One-Night', label: 'One Night' }
                ], 'Normal')}
                {renderSelectField('Guest Country', 'guestCountry', countries.map(country => ({ value: country.id, label: country.name })))}
                {renderSelectField('Guest City', 'guestCity', cities.map(city => ({ value: city.id, label: city.name })))}
            </div>
            <div className="grid grid-cols-3 gap-4 mt-4">
                {/* Commented out fields */}
                {/* {renderInputField('Children', 'children', 'number')}
                {renderInputField('Infants', 'infants', 'number')}
                {renderInputField('Pets', 'pets', 'number')} */}
            </div>
            {/* <div className="grid grid-cols-1 gap-4 mt-4">
                {renderInputField('Guest Note', 'guestNote', 'textarea')}
                {renderInputField('Host Note', 'hostNote', 'textarea')}
            </div> */}
        </>
    );
};

export default ReservationDetails;