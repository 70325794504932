import React from 'react';
import { Field } from 'formik';
import { TextField, Box } from '@mui/material';

export const PriceInformation = ({ touched, errors }) => (
  <Box sx={{ display: 'flex', gap: 2 }} className="!mb-4">
    <Field name="salePrice">
      {({ field }) => (
        <TextField
          {...field}
          label="Sale Price ($)"
          type="number"
          fullWidth
          error={touched.salePrice && Boolean(errors.salePrice)}
          helperText={touched.salePrice && errors.salePrice}
        />
      )}
    </Field>

    <Field name="pricePerSqm">
      {({ field }) => (
        <TextField
          {...field}
          label="Price per m² ($)"
          type="number"
          fullWidth
          error={touched.pricePerSqm && Boolean(errors.pricePerSqm)}
          helperText={touched.pricePerSqm && errors.pricePerSqm}
        />
      )}
    </Field>
  </Box>
);

export default PriceInformation;