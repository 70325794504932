import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    TextField,
    Box,
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { updateBedType } from '../services/serverApi.listing';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Bed type name is required')
});

const ModifyBedType = ({ open, onClose, setBedTypes, bedTypes, index, dataBedType }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        setIsLoading(true);
        const formData = {
            name: values.name
        };
        try {
            if (!dataBedType.hostawayId) {
                const response = await updateBedType(dataBedType._id, formData);
                const updatedBedType = response.data.bedTypes;
                const newBedTypes = bedTypes.map(bedType =>
                    bedType._id === updatedBedType._id ? updatedBedType : bedType
                );
                setBedTypes(newBedTypes);
                setSubmitting(false);
                onClose();
                toast.success('Bed type updated successfully');
            } else {
                setErrorMessage('Cannot update bed type with hostawayId');
            }
        } catch (error) {
            setErrorMessage(error.message);
            setSubmitting(false);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className='bg-medium-aquamarine text-white'>
                <Typography variant="h6" style={{ color: 'white' }} align="center">
                    Modify Bed Type
                </Typography>
            </DialogTitle>
            <DialogContent className='pt-4'>
                {errorMessage && (
                    <Box mb={2}>
                        <Typography variant="body2" color="error" align="center">
                            {errorMessage}
                        </Typography>
                    </Box>
                )}
                <Formik
                    initialValues={{
                        name: dataBedType?.name || ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <Box margin={1}>
                            <Field
                                as={TextField}
                                name='name'
                                label='Bed Type Name'
                                fullWidth
                                error={Boolean(errorMessage)}
                                helperText={<ErrorMessage name='name' />}
                            />
                        </Box>
                        <DialogActions>
                            <Button onClick={onClose} color='secondary'>
                                Cancel
                            </Button>
                            {dataBedType && !dataBedType.hostawayId && (
                                <button className='text-white py-1 px-3 bg-medium-aquamarine rounded-lg' type="submit" style={{ borderRadius: '5px' }}>
                                    {isLoading ? 'Updating...' : 'Update'}
                                </button>
                            )}
                        </DialogActions>
                    </Form>
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

export default ModifyBedType;
