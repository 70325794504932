export const catNames = ["", "WW", //- Who We Are
    "BO", //- Booking
    "OC", //- Online Check -in
    "CC", //- Check -in and Check - out
    "HR", //- Host Reception
    "CL", //- Cleaning
    "RS", //- Room Services
    "TI", //- Technical Issues
    "PI", //- Property Information
    "OT" //- Other
]