import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';


export function getProjectsAndUnits({ page, limit }) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/project/get-project-and-units?page=${page}&limit=${limit}&paged=true`);
}



export function getProjectById(projectId) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/project/get-project/${projectId}`);
}


export function getUnitById(unitId) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/units/get-unit/${unitId}`);
}



export function getProjects({ page, limit }) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/project?page=${page}&limit=${limit}&paged=true`);
}


export function createProject(data) {
    return axios.post(`${MICROSERVICE_BASE_URL.SRV_LISTING}/project/create-project`, data);
}


export function updateProject(projectId, data) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_LISTING}/project/update-project/${projectId}`, data);
}


export function deleteProject(projectId, deleteUnits = false) {
    return axios.delete(`${MICROSERVICE_BASE_URL.SRV_LISTING}/project/delete-project/${projectId}?deleteUnits=${deleteUnits}`);
}


export function getUnits({ page, limit }) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/units?page=${page}&limit=${limit}&paged=true`);
}


export function createUnit(data) {
    return axios.post(`${MICROSERVICE_BASE_URL.SRV_LISTING}/units/create-unit`, data);
}



export function updateUnit(unitId, data) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_LISTING}/units/update-unit/${unitId}`, data);
}



export function deleteUnit(unitId) {
    return axios.delete(`${MICROSERVICE_BASE_URL.SRV_LISTING}/units/delete-unit/${unitId}`);
}

export function getcities(page, limit, paged) {
    return axios.get(`${MICROSERVICE_BASE_URL.CITY}?page=${page || 0}&limit=${limit || 20}&paged=false`);
}


export function getUnitMapping() {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/units/get-unit-mapping`);
}


export function updateUnitMapping(unitId, data) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_LISTING}/units/update-unit-mapping/${unitId}`, data);
}