import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { getAllTasks } from './services/serverApi.task';
import './Task.css';
import FilterTask from './components/Calendar/FilterTask';
import moment from 'moment';
import { UserPlus } from 'lucide-react';
import { IconButton, Tooltip, Snackbar, Typography } from '@mui/material';
import AddTask from './components/Calendar/AddTask';
import { getStaff } from './services/serverApi.task';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { ReservationNumber } from './utils/ReservationNumber';

function DisplayTask() {
    const location = useLocation();
    const navigate = useNavigate();
    const [tasks, setTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);


    const [typeFilter, setTypeFilter] = useState([]);
    const [subTypeFilter, setSubTypeFilter] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);
    const [assignmentStatusFilter, setAssignmentStatusFilter] = useState([]);
    const [listingIdFilter, setListingIdFilter] = useState('');
    const [reservationNumberFilter, setReservationNumberFilter] = useState('');

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [staff, setStaff] = useState([]);
    const [snackbar, setSnackbar] = useState({ open: false, message: '' });

    const staging = JSON.parse(localStorage.getItem('isStaging')) || false;


    const filters = useMemo(() => ({
        type: typeFilter,
        subType: subTypeFilter,
        status: statusFilter,
        assignmentStatus: assignmentStatusFilter,
        listingId: listingIdFilter,
        reservationNumber: reservationNumberFilter
    }), [typeFilter, subTypeFilter, statusFilter, assignmentStatusFilter, listingIdFilter, reservationNumberFilter]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const reservationNumber = searchParams.get('reservationNumber');
        const listingId = searchParams.get('listingId');

        setReservationNumberFilter(reservationNumber || '');
        setListingIdFilter(listingId || '');
    }, [location]);

    const handleCopyReservationNumber = (reservationNumber) => {
        navigator.clipboard.writeText(reservationNumber)
            .then(() => {
                setSnackbar({ open: true, message: 'Reservation number copied to clipboard!' });
            })
            .catch((err) => {
                console.error('Failed to copy reservation number: ', err);
                setSnackbar({ open: true, message: 'Failed to copy reservation number' });
            });
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const handleEditTask = (task) => {
        setSelectedTask(task);
        setIsEditModalOpen(true);
    };

    const handleCloseEditModal = () => {
        setIsEditModalOpen(false);
        setSelectedTask(null);
    };

    const handleSaveTask = (response) => {
        const savedTask = response.task;
        toast.success('Task updated successfully.');
        setTasks(prevTasks => {
            const taskIndex = prevTasks.findIndex(task => task._id === savedTask._id);
            if (taskIndex !== -1) {
                return prevTasks.map(task =>
                    task._id === savedTask._id
                        ? { ...task, ...savedTask, listing: task.listing }
                        : task
                );
            } else {
                toast.success('New task added successfully!');
                const newTask = {
                    ...savedTask,
                    listing: savedTask.listing || { name: 'Unknown' }
                };
                return [newTask, ...prevTasks];
            }
        });

        handleCloseEditModal();
    };

    const fetchTasks = async () => {
        setIsLoading(true);
        try {
            const response = await getAllTasks({
                page,
                limit,
                ...filters,
                staging
            });
            if (response.data && Array.isArray(response.data)) {
                setTasks(response.data);
            } else {
                setTasks([]);
            }
        } catch (error) {
            console.error('Error fetching tasks:', error);
            setTasks([]);
            setError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchStaff = async () => {
        setIsLoading(true);
        try {
            const response = await getStaff();
            if (response.data.data && Array.isArray(response.data.data)) {
                setStaff(response.data.data);
            } else {
                setStaff([]);
            }
        } catch (error) {
            console.error('Error fetching staff:', error);
            setStaff([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchStaff();
    }, []);

    useEffect(() => {
        fetchTasks();
    }, [page, limit, filters, staging]);

    const handleFilterChange = (newFilters) => {
        setTypeFilter(newFilters.type || []);
        setSubTypeFilter(newFilters.subType || []);
        setStatusFilter(newFilters.status || []);
        setAssignmentStatusFilter(newFilters.assignmentStatus || []);
        setListingIdFilter(newFilters.listingId || '');
        setReservationNumberFilter(newFilters.reservationNumber || '');

        let searchParams = new URLSearchParams();

        if (newFilters.reservationNumber) {
            searchParams.set('reservationNumber', newFilters.reservationNumber);
        }
        if (newFilters.listingId) {
            searchParams.set('listingId', newFilters.listingId);
        }

        navigate(`/admin/Tasks?${searchParams.toString()}`, { replace: true });
        setPage(0);
    };

    const formatDate = (date, timeStart) => {
        return moment(date)
            .set({ hour: parseInt(timeStart), minute: 0 })
            .format('MM-DD | HH:mm');
    };

    const columns = [
        {
            field: 'taskCode',
            header: 'Task ID',
            body: (rowData) => (
                <Tooltip title={rowData.taskCode} arrow placement="top">
                    <span>{rowData.taskCode}</span>
                </Tooltip>
            )
        },
        {
            field: 'startDate',
            header: 'Task Date',
            body: (rowData) => formatDate(rowData?.startDate, rowData.TS_SEL[0]?.startDate)
        },
        {
            field: 'name',
            header: 'Task Name',
            body: (rowData) => (
                <Tooltip title={rowData.name} arrow placement="top">
                    <span>{rowData.name}</span>
                </Tooltip>
            )
        },
        {
            field: 'listing.name',
            header: 'Listing Name',
            body: (rowData) => (
                <Tooltip title={rowData.listing.name} arrow placement="top">
                    <span>{rowData.listing.name}</span>
                </Tooltip>
            )
        },
        {
            field: 'reservationNumber',
            header: 'Reservation N°',
            body: (rowData) => (
                <ReservationNumber
                    reservationNumber={rowData.reservationNumber}
                    onCopy={handleCopyReservationNumber}
                />
            )
        },
        { field: 'type', header: 'Type' },
        { field: 'subType', header: 'Sub Type' },
        { field: 'mode', header: 'Mode' },
        { field: 'status', header: 'Status' },
        { field: 'assignmentStatus', header: 'Assignment Status' },
        { field: 'price', header: 'Price' },
        {
            field: 'paymentMode',
            header: 'Payment Mode',
            body: (rowData) => rowData.paid ? rowData.paymentMode : '-'
        },
        {
            field: 'action',
            body: (rowData) => (
                <Tooltip title="Assign Task">
                    <IconButton
                        onClick={() => handleEditTask(rowData)}
                        size="small"
                        color="primary"
                        aria-label="assign task"
                    >
                        <UserPlus size={20} />
                    </IconButton>
                </Tooltip>
            )
        },
    ];

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ToastContainer />
            <div className="card py-4 overflow-hidden">
                <Typography variant="h4" component="h1" className="mb-2 px-4" gutterBottom>
                    Tasks
                </Typography>
                <FilterTask
                    onFilterChange={handleFilterChange}
                    onSaveTask={handleSaveTask}
                    staff={staff}
                    initialFilters={filters}
                />
                <div className="h-full overflow-auto p-2">
                    <div className="main-content">
                        <main>
                            <GlobalTable
                                data={tasks}
                                columns={columns}
                                page={page}
                                onPageChange={setPage}
                                isNextDisabled={tasks.length < limit}
                                hasPagination
                                limit={limit}
                                onLimitChange={setLimit}
                                rowsPerPageOptions={[10, 20, 50]}
                            />
                        </main>
                    </div>
                </div>
                {isEditModalOpen && (
                    <AddTask
                        open={isEditModalOpen}
                        onClose={handleCloseEditModal}
                        onSave={handleSaveTask}
                        existingTask={selectedTask}
                        staff={staff}
                        listing={selectedTask?.listing ? [selectedTask.listing] : []}
                    />
                )}
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackbar}
                    message={snackbar.message}
                />
            </div>
        </DashboardLayout>
    );
}

export default DisplayTask;