import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Clock, Building2 } from 'lucide-react';
import DynamicListingName from '../../utils/DynamicListingName';
import DetailsTask from './DetailsTask';


const TableTask = ({ staff, daysInView }) => {
    const [selectedTask, setSelectedTask] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString('en-US', {
            weekday: 'short',
            day: 'numeric',
            month: 'short'
        });
    };

    const statusConfig = {
        ASSIGNED: { bg: 'bg-blue-50', text: 'text-blue-700' },
        UNASSIGNED: { bg: 'bg-orange-50', text: 'text-orange-700' },
        ACCEPTED: { bg: 'bg-green-50', text: 'text-green-700' },
        REFUSED: { bg: 'bg-red-50', text: 'text-red-700' }
    };

    const renderTaskCard = (task) => (
        <div
            className={`
                p-1.5 rounded
                ${statusConfig[task.assignmentStatus]?.bg || 'bg-gray-50'}
                border border-gray-100
                hover:shadow-sm transition-all
                cursor-pointer
                ${task.emergency === 'Urgent' ? 'ring-1 ring-red-400' : ''}
            `}
        >
            <div className="flex items-center justify-between mb-0.5 p-1">
                <div className="flex items-center gap-1 truncate">
                    <Clock className="w-3 h-3 text-gray-500" />
                    <span className="text-xs font-medium text-gray-600">
                        {task.time}
                    </span>
                    <div className="text-xs text-gray-800 mb-0.5 font-bold">
                        {task.title}
                    </div>
                    <span className="text-xs font-medium">
                        {task.duration}h
                    </span>
                    <span className="text-xs font-bold">
                        {task.presence}
                    </span>
                </div>

            </div>



            <div className="flex items-center gap-1 p-1">
                <Building2 className="w-3 h-3 text-gray-400" />
                <DynamicListingName listingName={task.ListingName} />
            </div>
        </div>
    );

    const renderTaskCell = (rowData, day) => {
        const dayTasks = rowData.tasks.filter(task => {
            const taskDate = new Date(task.date);
            return taskDate.toDateString() === day.toDateString();
        });

        return (
            <div className="grid gap-1">
                {dayTasks.map((task, index) => (
                    <div
                        key={index}
                        onClick={() => {
                            setSelectedTask(task);
                            setIsModalOpen(true);
                        }}
                    >
                        {renderTaskCard(task)}
                    </div>
                ))}
            </div>
        );
    };

    const renderStaffCell = (rowData) => (
        <div className="py-1 px-2">
            <div className="text-sm font-medium text-gray-800">
                {rowData.username}
            </div>
            <div className="text-xs text-gray-500">
                {rowData.type}
            </div>
        </div>
    );

    const renderHeaderCell = (day) => (
        <div className="text-xs font-medium text-gray-600 p-1">
            {formatDate(day)}
        </div>
    );

    const getColumnWidth = () => {
        const totalWidth = window.innerWidth;
        const staffColumnWidth = 140;
        const remainingWidth = totalWidth - staffColumnWidth;
        const dayColumnWidth = Math.max(120, Math.floor(remainingWidth / 30));
        return {
            staffWidth: `${staffColumnWidth}px`,
            dayWidth: `${dayColumnWidth}px`
        };
    };

    const { staffWidth, dayWidth } = getColumnWidth();

    const columns = [
        {
            field: 'username',
            header: 'Staff',
            body: renderStaffCell,
            frozen: true,
            style: {
                width: staffWidth,
                minWidth: staffWidth,
                backgroundColor: '#f8fafc',
                borderRight: '2px solid #e2e8f0',
                padding: '4px'
            }
        },
        ...daysInView.map((day, index) => ({
            field: `day${index}`,
            header: renderHeaderCell(day),
            body: (rowData) => renderTaskCell(rowData, day),
            style: {
                width: dayWidth,
                minWidth: dayWidth,
                padding: '4px',
                borderRight: '1px solid #e2e8f0',
            }
        }))
    ];


    const tableStyles = `
        .task-table-compact .p-datatable-wrapper {
            border: 1px solid #e2e8f0;
        }

        .task-table-compact .p-datatable-thead > tr > th {
            background-color: #f8fafc !important;
            border-bottom: 2px solid #e2e8f0 !important;
            border-right: 1px solid #e2e8f0 !important;
        }

        .task-table-compact .p-datatable-tbody > tr {
            border-bottom: 1px solid #e2e8f0 !important;
        }

        .task-table-compact .p-datatable-tbody > tr > td {
            border-right: 1px solid #e2e8f0 !important;
        }

        .task-table-compact .p-datatable-tbody > tr:hover {
            background-color: #f8fafc !important;
        }

        .task-table-compact .p-datatable-tbody > tr > td:first-child {
            border-right: 2px solid #e2e8f0 !important;
        }

        .p-datatable-wrapper {
           max-height: calc(-100px + 100vh) !important;
        }
    `;

    return (
        <div className="border border-gray-200 rounded-sm">
            <style>{tableStyles}</style>
            <DataTable
                value={staff}
                className="task-table-compact"
                scrollable
                scrollHeight="calc(100vh - 180px)"
                showGridlines
                resizableColumns={false}
                columnResizeMode="none"
                style={{
                    '--grid-line-color': '#e2e8f0',
                    '--header-bg': '#f8fafc',
                    '--row-hover': '#f8fafc'
                }}
            >
                {columns.map((col, index) => (
                    <Column
                        key={index}
                        field={col.field}
                        header={col.header}
                        body={col.body}
                        frozen={col.frozen}
                        style={col.style}
                        className="border-right"
                    />
                ))}
            </DataTable>

            {selectedTask && (
                <DetailsTask
                    event={selectedTask}
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                />
            )}
        </div>
    );
};

export default TableTask;