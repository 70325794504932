import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getChannelManager } from '../../services/serverApi.listing';

const ChannelManager = ({ formik }) => {
    const [channelManagerOptions, setChannelManagerOptions] = useState([]);

    const fetchChannelManager = async () => {
        try {
            const response = await getChannelManager();
            const channels = response.data;
            setChannelManagerOptions([
                { value: '', label: 'empty' },
                ...channels.map(channel => ({ value: channel.name, label: channel.name }))
            ]);
        } catch (error) {
            console.error('Error fetching channel manager:', error);
        }
    };

    useEffect(() => {
        fetchChannelManager();
    }, []);

    const handleChannelManagerChange = (selectedOption) => {
        formik.setFieldValue('channelManager', selectedOption ? selectedOption.value : '');
    };

    const renderListingIds = () => {
        const listingIds = [
            {
                label: 'Checkin Listing ID',
                value: formik.values.checkinListingId,
                icon: 'pi pi-check-circle',
                color: 'text-green-600'
            },
            {
                label: 'Channex Listing ID',
                value: formik.values.channexListingId,
                icon: 'pi pi-link',
                color: 'text-blue-600'
            }
        ];

        return (
            <div className="mt-2">
                {listingIds.map((listing, index) => (
                    listing.value && (
                        <div 
                            key={index} 
                            className="flex items-center justify-between py-2 border-b last:border-b-0 border-gray-100"
                        >
                            <div className="flex items-center">
                                <i className={`${listing.icon} !mr-2 ${listing.color}`}></i>
                                <span className="text-sm font-medium text-gray-600">
                                    {listing.label}
                                </span>
                            </div>
                            <div className="bg-gray-100 px-2 py-1 rounded text-xs font-mono text-gray-800">
                                {listing.value}
                            </div>
                        </div>
                    )
                ))}
            </div>
        );
    };

    return (
        <div className="w-full p-4">
            <div className="mb-4">
                <span className="text-lg font-bold text-gray-800 flex items-center">
                    Channel Manager 
                    <i className="pi pi-check-square text-green-500 ml-2"></i>
                </span>
            </div>

            <div className="mb-4">
                <label 
                    htmlFor="channelManager" 
                    className="block text-sm text-gray-700 font-semibold mb-2"
                >
                    Select Channel Manager
                </label>
                <div className="flex items-center">
                    <Select
                        id="channelManager"
                        name="channelManager"
                        options={channelManagerOptions}
                        className="w-full"
                        value={channelManagerOptions.find(option => option.value === formik.values.channelManager)}
                        onChange={handleChannelManagerChange}
                        onBlur={formik.handleBlur}
                    />
                  
                </div>
                {formik.touched.channelManager && formik.errors.channelManager && (
                    <div className="text-red-500 text-xs mt-1">
                        {formik.errors.channelManager}
                    </div>
                )}
            </div>

            {(formik.values.checkinListingId || formik.values.channexListingId) && renderListingIds()}
        </div>
    );
};

export default ChannelManager;