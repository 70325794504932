import React from 'react';
import styled, { css } from 'styled-components';

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const getPositionStyles = (position) => {
  switch (position) {
    case 'top':
      return css`
        bottom: 125%;
        left: 50%;
        transform: translateX(-50%);
        &::after {
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-color: black transparent transparent transparent;
        }
      `;

    case 'right':
      return css`
        top: 50%;
        left: 125%;
        transform: translateY(-50%);
        &::after {
          top: 50%;
          left: 0%;
          transform: translateY(-50%);
          border-color: transparent transparent transparent black;
        }
      `;
    case 'bottom':
      return css`
        top: 125%;
        left: 50%;
        transform: translateX(-50%);
        &::after {
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-color: transparent transparent black transparent;
        }
      `;
    case 'bottom-right':
      return css`
        top: 125%;
        left: 75%;
        transform: translateX(-50%);
        &::after {
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-color: transparent transparent black transparent;
        }
      `;
    case 'bottom-left':
      return css`
        top: 125%;
        left: 25%;
        transform: translateX(-50%);
        &::after {
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-color: transparent transparent black transparent;
        }
      `;
    case 'left':
      return css`
        top: 50%;
        right: 125%;
        transform: translateY(-50%);
        &::after {
          top: 50%;
          right: 0%;
          transform: translateY(-50%);
          border-color: transparent black transparent transparent;
        }
      `;
    default:
      return css``;
  }
};

const TooltipText = styled.div`
  visibility: hidden;
  width: 12.5rem;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 11px;

  /* Arrow */
  &::after {
    content: '';
    position: absolute;
    border-width: 5px;
    border-style: solid;
  }

  ${(props) => getPositionStyles(props.position)}
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;

  &:hover ${TooltipText} {
    visibility: visible;
    opacity: 1;
  }
`;

const Tooltip = ({ text, iconClass, position = 'top' }) => (
  <TooltipContainer>
    <IconContainer>
      <i className={iconClass} style={{ fontSize: '20px' }}></i>
      <TooltipText position={position}>{text}</TooltipText>
    </IconContainer>
  </TooltipContainer>
);

export default Tooltip;
