import React, { useEffect, useState } from 'react';
import Tooltip from 'components/TooltipGlobal/Tooltip';
import Select from 'react-select';


export const instantBookableOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
];

const BasicInfo = ({ formik, propertyTypes, currencies }) => {
    const currencyOptions = currencies.map(currency => ({
        label: `${currency.currencyCode} (${currency.currencySymbol})`,
        value: currency.currencyCode
    }));

    const handleChange = (e) => {
        const { name, value } = e.target;
        formik.setFieldValue(name, value);
    };

    const handleSelectChange = (selectedOption, name) => {
        if (name === 'propertyType') {
            formik.setFieldValue('propertyType', selectedOption ? selectedOption.value : '');
            formik.setFieldValue('propertyTypeId', selectedOption ? selectedOption._id : '');
            formik.setFieldValue('manageRoomType', selectedOption ? selectedOption.manageRoomType : '');
        } else {
            const value = selectedOption ? 
                (name === 'sendTochannex' ? selectedOption.value : selectedOption.value) 
                : '';
            formik.setFieldValue(name, value);
        }
    };

    const renderInputField = (name, label, placeholder, isRequired = false, isSelect = false, options = []) => (
        <div className="mb-1">
            <label htmlFor={name} className="block text-[13px] text-gray-700 font-semibold mb-2">
                {label} {isRequired && <span className="text-red-500">*</span>}
            </label>
            <Tooltip text="This is a tooltip text." iconClass="pi pi-question-circle text-[#337ab7] !text-[15px] !m-1" position="top" />
            {isSelect ? (
                <Select
                    id={name}
                    name={name}
                    options={options}
                    className={`w-full !text-sm ${formik.touched[name] && formik.errors[name] ? '!border-red-500' : ''}`}
                    value={options.find(option => option.value === formik.values[name])}
                    onChange={(selectedOption) => handleSelectChange(selectedOption, name)}
                    onBlur={formik.handleBlur}
                />
            ) : (
                <input
                    id={name}
                    name={name}
                    type="text"
                    className={`w-full !text-sm px-2 py-2 border border-gray-300 focus:outline-none focus:ring-1 focus:ring-green-300 ${formik.touched[name] && formik.errors[name] ? '!border-red-500' : ''}`}
                    placeholder={placeholder}
                    value={formik.values[name]}
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                />
            )}
            {formik.touched[name] && formik.errors[name] && (
                <div className="text-red-500 text-sm mt-1">{formik.errors[name]}</div>
            )}
        </div>
    );

    return (
        <div className="w-full p-4">
            <div className="flex justify-between">
                <span className="text-md font-semibold">Basic info</span>
                <span className="text-[15px] text-[#65c0ae] font-medium">Learn how to Export to Airbnb PRO</span>
            </div>
            <div className="grid grid-cols-2 gap-4">
                <div className="bg-white">
                    {renderInputField('name', 'External Listing Name', 'External Listing Name', true)}
                    {renderInputField('currencyCode', 'Currency', 'Select currency', true, true, currencyOptions)}
                    {renderInputField('active', 'Active', 'Select Active', false, true, instantBookableOptions)}
                    {renderInputField('displayInHomeScreen', 'Display In Home Screen', 'Select Display In Home Screen', false, true, instantBookableOptions)}
                    <div className="mb-1">
                        <label htmlFor="description" className="block text-[13px] text-gray-700 font-semibold mb-2">
                            Description
                        </label>
                        <textarea
                            id="description"
                            name="description"
                            className="w-full !text-sm px-2 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            rows="4"
                            placeholder="Description"
                            value={formik.values.description}
                            onChange={handleChange}
                            onBlur={formik.handleBlur}
                        ></textarea>
                    </div>
                </div>
                <div className="bg-white">
                    {renderInputField('propertyType', 'Property Type', 'Select Property type', true, true, propertyTypes)}
                    {renderInputField('sendTochannex', 'Send To Channex', 'Select Send To Channex', false, true, instantBookableOptions)}
                    {renderInputField('wifiUsername', 'Wifi Username', 'wifi username', true)}
                    {renderInputField('wifiPassword', 'Wifi Password', 'wifi password', true)}
                    {renderInputField('onlineCheckIn', 'Online CheckIn', 'Select Online CheckIn', false, true, instantBookableOptions)}
                </div>
            </div>
        </div>
    );
};

export default BasicInfo;