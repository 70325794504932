import React, { useRef, useEffect } from 'react';
import { ChevronLeft, ChevronRight, CalendarSearch } from 'lucide-react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const SidebarProperty = ({
  onPreviousPeriod,
  onNextPeriod,
  onTodayClick,
  onDateRangeChange,
  formattedDateRange,
  dateRange,
  showDatePicker,
  setShowDatePicker
}) => {
  const datePickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (datePickerRef.current && !datePickerRef.current.contains(event.target)) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [setShowDatePicker]);

  const handleDateRangeClick = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleRangeChange = (ranges) => {
    onDateRangeChange(ranges.selection);
  };

  return (
    <div className="w-full">
      <div className="flex justify-center items-center gap-10 p-2 relative">
        <div className="flex justify-center items-center gap-3 !z-50">
          <ChevronLeft
            onClick={onPreviousPeriod}
            size={25}
            className="cursor-pointer hover:bg-gray-100 rounded-full p-1"
          />
          <div
            className="flex items-center gap-2 cursor-pointer hover:bg-gray-100 p-2 rounded-md"
            onClick={handleDateRangeClick}
          >
            <span className="text-sm font-bold">{formattedDateRange}</span>
            <CalendarSearch className="text-teal-500" size={16} />
          </div>
          <div
            onClick={onTodayClick}
            className="px-2 py-1 bg-white border border-teal-500 rounded-md  cursor-pointer hover:bg-teal-600 hover:text-white"
          >
            <span className='text-xs text-teal-500'>Now</span>
          </div>
          <ChevronRight
            onClick={onNextPeriod}
            size={25}
            className="cursor-pointer text-teal-500 hover:bg-gray-100 rounded-full p-1"
          />
        </div>
        {showDatePicker && (
          <div
            ref={datePickerRef}
            className="absolute top-14 left-0 !z-50 mt-2 shadow-lg bg-white rounded-lg"
          >
            <DateRange
              onChange={handleRangeChange}
              moveRangeOnFirstSelection={false}
              ranges={[dateRange]}
              direction="horizontal"
              preventSnapRefocus={true}
              calendarFocus="backwards"
              months={2}
              className="rounded-lg overflow-hidden"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SidebarProperty;