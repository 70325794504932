import React from 'react';
import { IconButton } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import GlobalTable from 'components/GlobalTable/GlobalTable';

const TravellersTable = ({ data, isEditMode, onEdit, onDelete }) => {
    const columns = [
        { field: 'whatsappNumber', header: 'WhatsApp Number' },
        { field: 'language', header: 'Language' },
        { field: 'role', header: 'Role' },
        {
            field: 'actions',
            header: 'Actions',
            body: (rowData, { rowIndex }) => (
                isEditMode && (
                    <>
                        <IconButton color="primary" onClick={() => onEdit(rowData, rowIndex)} size="small">
                            <EditIcon />
                        </IconButton>
                        <IconButton color="secondary" onClick={() => onDelete(rowIndex)} size="small">
                            <DeleteIcon />
                        </IconButton>
                    </>
                )
            ),
        },
    ];

    return (
        <GlobalTable
            data={data}
            columns={columns}
            hasPagination={false}
        />
    );
};

export default TravellersTable;