import React from 'react';
import { FormControl, Select, MenuItem, Chip, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const paymentStatusOptions = [
  'Paid',
  'UnPaid'
];

const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    padding: '9px 32px 8px 9px !important',
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
});

const PaymentStatusFilter = ({ paymentStatus, setPaymentStatus }) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPaymentStatus(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <FormControl sx={{ m: 1, width: '100%' }}>
      <StyledSelect
        labelId="payment-status-filter-label"
        id="payment-status-filter"
        multiple
        value={paymentStatus}
        onChange={handleChange}
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em className="!p-[1.5px]">Payment Status</em>;
          }
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} size="small" />
              ))}
            </Box>
          );
        }}
      >
        {paymentStatusOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default PaymentStatusFilter;
