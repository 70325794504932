import React, { useState } from 'react';
import SyncIcon from '@mui/icons-material/Sync';

export const SyncButton = ({ label, onClick }) => {
    const [isRotating, setIsRotating] = useState(false);

    const handleClick = async () => {
        setIsRotating(true);
        try {
            await onClick();
        } finally {
            setIsRotating(false);
        }
    };

    return (
        <div
            className="border rounded p-5 flex justify-center text-black w-[10rem] h-[3rem] items-center cursor-pointer hover:text-white hover:shadow-md transition duration-300 hover:animate-pulse bg-teal-300 hover:bg-medium-aquamarine relative"
            onClick={handleClick}
        >
            <div className={`absolute top-1 right-1 transition-transform duration-1000 ${isRotating ? 'animate-spin' : ''}`}>
                <SyncIcon />
            </div>
            <h6 className="font-bold m-0" style={{ fontSize: '16px' }}>{label}</h6>
        </div>
    );
};