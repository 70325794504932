import React, { useState, useEffect, useMemo } from 'react';
import {
    Box,
    Checkbox,
    FormControlLabel,
    Button,
    Typography,
    Paper,
    Grid,
    TextField,
    CircularProgress,
    Switch,
    Divider
} from '@mui/material';
import { createUpdateWebhook, getWebhookConfigs } from '../services/serverApi.minut';
import { toast, ToastContainer } from 'react-toastify';
import { allEvents } from './EventsList'; 

function WebhookEventSelector() {
    const [checkedEvents, setCheckedEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [webhookId, setWebhookId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        fetchWebhookConfigs();
    }, []);

    const fetchWebhookConfigs = async () => {
        try {
            const response = await getWebhookConfigs();
            if (response.message === "No data found") {
                setCheckedEvents([]);
                setWebhookId(null);
            } else if (response.data && response.data.length > 0) {
                const events = response.data[0].events;
                if (events.includes('*')) {
                    setCheckedEvents(allEvents);
                    setSelectAll(true);
                } else {
                    setCheckedEvents(events);
                    setSelectAll(events.length === allEvents.length);
                }
                setWebhookId(response.data[0].hook_id);
            }
        } catch (err) {
            setError('Failed to fetch webhook configurations');
        } finally {
            setLoading(false);
        }
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedEvents(prev => {
            const newCheckedEvents = checked 
                ? [...prev, name] 
                : prev.filter(e => e !== name);
            setSelectAll(newCheckedEvents.length === allEvents.length);
            return newCheckedEvents;
        });
    };

    const handleSelectAllChange = (event) => {
        const { checked } = event.target;
        setSelectAll(checked);
        setCheckedEvents(checked ? allEvents : []);
    };

    const handleSave = async () => {
        try {
            const data = {
                hook_id: webhookId,
                events: selectAll ? ['*'] : checkedEvents
            };
            await createUpdateWebhook(data);
            toast.success("Webhook events updated successfully");
        } catch (err) {
            toast.error('Failed to update webhook events');
        }
    };

    const filteredEvents = useMemo(() =>
        allEvents.filter(event => event.toLowerCase().includes(searchTerm.toLowerCase())),
        [searchTerm]
    );

    if (loading) return <CircularProgress />;

    return (
        <Paper elevation={3} sx={{ p: 4, margin: 'auto' }}>
            <ToastContainer position="top-right" autoClose={3000} />
            <div className="flex items-center justify-between mb-4">
                <Typography variant="h5" gutterBottom>Webhook Event Selector</Typography>
                <Button
                    variant="contained"
                    onClick={handleSave}
                    disabled={checkedEvents.length === 0 && !selectAll}
                    className="!bg-medium-aquamarine text-white"
                >
                    Save
                </Button>
            </div>

            <TextField
                fullWidth
                variant="outlined"
                label="Search events"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ mb: 4 }}
            />

            <FormControlLabel
                control={
                    <Switch
                        checked={selectAll}
                        onChange={handleSelectAllChange}
                        name="selectAll"
                    />
                }
                label={<span className="text-xl">Select All</span>}
                sx={{ mb: 2 }}
            />

            <Divider sx={{ mb: 2 }} />

            <Box sx={{ overflowY: 'auto', mb: 2 }}>
                <Grid container spacing={2}>
                    {filteredEvents.map((event) => (
                        <Grid item xs={12} sm={6} md={4} key={event}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectAll || checkedEvents.includes(event)}
                                        onChange={handleCheckboxChange}
                                        name={event}
                                    />
                                }
                                label={<span className="text-sm font-bold">{event}</span>}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Paper>
    );
}

export default WebhookEventSelector;