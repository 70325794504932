import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Checkbox, CircularProgress, Grid, 
  Paper, FormControlLabel, Chip
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { getStandardSojori } from '../../services/serverApi.listing';
import Tooltip from 'components/TooltipGlobal/Tooltip';

function StandardSojori({ formik }) {
  const [standardSojori, setStandardSojori] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchStandardSojori();
  }, []);

  const fetchStandardSojori = async () => {
    setIsLoading(true);
    try {
      const response = await getStandardSojori();
      setStandardSojori(response.data.data);
    } catch (error) {
      console.error('Error fetching Standard Sojori:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = (event, standardId) => {
    const { checked } = event.target;
    const currentStandardSojori = formik.values.listingStandardSojoriIds || [];

    if (checked) {
      formik.setFieldValue('listingStandardSojoriIds', [...currentStandardSojori, standardId]);
    } else {
      formik.setFieldValue(
        'listingStandardSojoriIds',
        currentStandardSojori.filter(id => id !== standardId)
      );
    }
  };

  return (
    <Box sx={{ p: 3, bgcolor: '#f5f5f5', borderRadius: 2 }}>
      <Box className="flex justify-between">
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h6" fontWeight="bold">
            Standard Sojori
          </Typography>
          <Tooltip
            text="This is displayed on your website and exported to Tripadvisor, 9flats, and Innclusive."
            iconClass="pi pi-question-circle text-[#337ab7] !text-[15px] !m-1"
            position="top"
          >
            <InfoIcon fontSize="small" color="primary" sx={{ ml: 1, cursor: 'pointer' }} />
          </Tooltip>
        </Box>
        {formik.touched.listingStandardSojoriIds && formik.errors.listingStandardSojoriIds && (
          <span className="text-red-500 text-sm mt-2 float-right">
            {formik.errors.listingStandardSojoriIds}
          </span>
        )}
      </Box>

      {isLoading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress style={{ color: '#66cdaa' }} />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {standardSojori.length > 0 ? (
            standardSojori.map((standard) => (
              <Grid item xs={12} sm={6} md={4} key={standard._id}>
                <Paper 
                  elevation={1}
                  sx={{ 
                    p: 2,
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    '&:hover': { boxShadow: 3 }
                  }}
                >
                  <Box component="img" src={standard.iconUrl} alt={standard.name} sx={{ width: 28, height: 28, mr: 2 }} />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.listingStandardSojoriIds?.includes(standard._id) || false}
                        onChange={(event) => handleCheckboxChange(event, standard._id)}
                        sx={{ 
                          '& .MuiSvgIcon-root': { fontSize: 23 },
                          color: '#6fd1bd',
                          '&.Mui-checked': {
                            color: '#6fd1bd',
                          },
                        }}
                      />
                    }
                    label={
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        {standard.name}
                      </Typography>
                    }
                  />
                </Paper>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography variant="body1" textAlign="center" color="text.secondary">
                No Standard Sojori found.
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  );
}

export default StandardSojori;