import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { getLanguage } from '../../services/serverApi.listing';
import {
    Autocomplete,
    TextField,
    Button,
    IconButton,
    Typography,
    Paper,
    Grid,
    Divider,
    Rating,
    Box,
    Tabs,
    Tab
} from '@mui/material';
import {
    Plus,
    Trash2,
    Car,
    MapPin,
    SquareParking,
    BookOpen,
    FileText,
    Accessibility,
    Calendar,
    FileQuestion,
    Star
} from 'lucide-react';
import ImageUpload from 'components/CustomUpload/UploadV2';
import { useSelector } from 'react-redux';

const MoreInfo = ({ formik, listingId }) => {
    const upload = useSelector((state) => state.uploadData);
    const [languages, setLanguages] = useState([]);
    const [defaultLanguage, setDefaultLanguage] = useState(null);
    const [activeTab, setActiveTab] = useState(0);

    const isCreating = !listingId;

    const fetchLanguages = async () => {
        try {
            const response = await getLanguage();
            setLanguages(response.data);
            const englishLanguage = response.data.find(lang => lang.name.toLowerCase() === 'english');
            if (englishLanguage) {
                setDefaultLanguage(englishLanguage);
            }
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    };

    useEffect(() => {
        fetchLanguages();
    }, []);

    useEffect(() => {
        if (isCreating && defaultLanguage) {
          const shouldInitialize = categories.some(category => {
            const currentValue = formik.values[category.name];
            return !currentValue || 
                   !currentValue.name || 
                   !currentValue.descriptions ||
                   Object.keys(currentValue.name).length === 0;
          });
      
          if (shouldInitialize) {
            categories.forEach(category => {
              if (!formik.values[category.name]?.name || Object.keys(formik.values[category.name]?.name || {}).length === 0) {
                formik.setFieldValue(`${category.name}.name`, {
                  [defaultLanguage._id]: ''
                });
              }
      
              if (!formik.values[category.name]?.descriptions || formik.values[category.name]?.descriptions.length === 0) {
                formik.setFieldValue(`${category.name}.descriptions`, [
                  { [defaultLanguage._id]: '' }
                ]);
              }
      
              if (!formik.values[category.name]?.iconUrl) {
                formik.setFieldValue(`${category.name}.iconUrl`, '');
              }
            });
      
            if (!formik.values.review) {
              formik.setFieldValue('review', '');
            }
            if (!formik.values.rating) {
              formik.setFieldValue('rating', 0);
            }
          }
        }
      }, [defaultLanguage, isCreating]);

    const categories = [
        { name: 'transport', icon: <Car size={20} /> },
        { name: 'center', icon: <MapPin size={20} /> },
        { name: 'parking', icon: <SquareParking size={20} /> },
        { name: 'policy', icon: <FileText size={20} /> },
        { name: 'accessibility', icon: <Accessibility size={20} /> },
        { name: 'houseRule', icon: <BookOpen size={20} /> },
        { name: 'note', icon: <FileQuestion size={20} /> },
        { name: 'cancellation', icon: <Calendar size={20} /> },
    ];

    const handleRemoveName = (category, languageId) => {
        const currentNames = { ...formik.values[category]?.name };
        delete currentNames[languageId];
        formik.setFieldValue(`${category}.name`, currentNames);
    };

    const handleNameChange = (category, languageId, value) => {
        const currentNames = { ...formik.values[category]?.name };
        currentNames[languageId] = value;
        formik.setFieldValue(`${category}.name`, currentNames);
    };

    const handleAddDescription = (category) => {
        const currentCategory = formik.values[category] || {};
        const currentDescriptions = currentCategory.descriptions || [];
        formik.setFieldValue(category, {
            ...currentCategory,
            descriptions: [...currentDescriptions, {}],
        });
    };

    const handleRemoveDescription = (category, index, languageId) => {
        const currentDescriptions = formik.values[category]?.descriptions || [];
        const updatedDescriptions = currentDescriptions.map((desc, i) => {
            if (i === index) {
                const { [languageId]: _, ...rest } = desc;
                return rest;
            }
            return desc;
        });
        formik.setFieldValue(`${category}.descriptions`, updatedDescriptions.filter(desc => Object.keys(desc).length > 0));
    };

    const handleDescriptionChange = (category, index, languageId, value) => {
        const currentDescriptions = formik.values[category]?.descriptions || [];
        const updatedDescriptions = [...currentDescriptions];
        updatedDescriptions[index] = { ...updatedDescriptions[index], [languageId]: value };
        formik.setFieldValue(`${category}.descriptions`, updatedDescriptions);
    };

    const getAvailableLanguages = (category) => {
        const usedLanguageIds = Object.keys(formik.values[category]?.name || {});
        return languages.filter(lang => !usedLanguageIds.includes(lang._id));
    };

    const getAvailableLanguagesForDescription = (category, index) => {
        const usedLanguageIds = Object.keys(formik.values[category]?.descriptions[index] || {});
        return languages.filter(lang => !usedLanguageIds.includes(lang._id));
    };

    const renderTabContent = (category) => (
        <Box sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>Names</Typography>
            {Object.entries(formik.values[category]?.name || {}).map(([languageId, name]) => (
                <Grid container spacing={2} alignItems="center" key={languageId} sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            fullWidth
                            value={languages.find(lang => lang._id === languageId)?.name || ''}
                            disabled
                            label="Language"
                            variant="outlined"
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <TextField
                            fullWidth
                            value={name}
                            onChange={(e) => handleNameChange(category, languageId, e.target.value)}
                            label="Name"
                            variant="outlined"
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <IconButton onClick={() => handleRemoveName(category, languageId)} color="error">
                            <Trash2 size={20} />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}

            <Box mt={2}>
                <Autocomplete
                    options={getAvailableLanguages(category)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => <TextField {...params} label="Add Language" variant="outlined" size="small" />}
                    onChange={(_, newValue) => {
                        if (newValue) {
                            handleNameChange(category, newValue._id, '');
                        }
                    }}
                    sx={{ width: '25%' }}
                />
            </Box>

            <Divider sx={{ my: 3 }} />

            <Typography variant="h6" gutterBottom>Descriptions</Typography>
            {formik.values[category]?.descriptions.map((description, index) => (
                <Box key={index} mb={3}>
                    <Typography variant="subtitle1" gutterBottom>Description {index + 1}</Typography>
                    {Object.entries(description).map(([languageId, text]) => (
                        <Grid container spacing={2} alignItems="center" key={languageId} sx={{ mt: 1 }}>
                            <Grid item xs={12} sm={3}>
                                <Autocomplete
                                    fullWidth
                                    options={languages}
                                    getOptionLabel={(option) => option.name}
                                    value={languages.find(lang => lang._id === languageId) || null}
                                    onChange={(_, newValue) => {
                                        if (newValue) {
                                            handleDescriptionChange(category, index, newValue._id, text);
                                            const updatedDescription = { ...description };
                                            delete updatedDescription[languageId];
                                            updatedDescription[newValue._id] = text;
                                            const updatedDescriptions = [...formik.values[category].descriptions];
                                            updatedDescriptions[index] = updatedDescription;
                                            formik.setFieldValue(`${category}.descriptions`, updatedDescriptions);
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Language" variant="outlined" size="small" />}
                                />
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    fullWidth
                                    value={text}
                                    onChange={(e) => handleDescriptionChange(category, index, languageId, e.target.value)}
                                    label="Description"
                                    variant="outlined"
                                    size="small"
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                            <Grid item xs={12} sm={1}>
                                <IconButton onClick={() => handleRemoveDescription(category, index, languageId)} color="error">
                                    <Trash2 size={20} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                    <Box mt={2}>
                        <Autocomplete
                            options={getAvailableLanguagesForDescription(category, index)}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => <TextField {...params} label="Add Language" variant="outlined" size="small" />}
                            onChange={(_, newValue) => {
                                if (newValue) {
                                    handleDescriptionChange(category, index, newValue._id, '');
                                }
                            }}
                            sx={{ width: '25%' }}
                        />
                    </Box>
                    <Divider sx={{ my: 3 }} />
                </Box>
                
            ))}
            
            <Button
                startIcon={<Plus size={20} className='text-gray-600' />}
                onClick={() => handleAddDescription(category)}
                variant="outlined"
                size="small"
            >
                <span className='text-gray-600'>Add Description</span>
            </Button>

            <Divider sx={{ my: 3 }} />

            <Typography variant="h6" gutterBottom>Icon</Typography>
            <Box mb={2}>
                <ImageUpload
                    fieldName={`${category}.iconUrl`}
                    setFieldValue={formik.setFieldValue}
                    folder="other"
                />
                {formik.values[category]?.iconUrl && (
                    <img
                        src={formik.values[category].iconUrl}
                        alt={`${category} icon`}
                        style={{ width: '150px', height: '150px', objectFit: 'contain', marginTop: '10px' }}
                    />
                )}
                {formik.touched[category]?.iconUrl && formik.errors[category]?.iconUrl && (
                    <Typography color="error" variant="body2">
                        {formik.errors[category].iconUrl}
                    </Typography>
                )}
            </Box>
        </Box>
    );

    return (
        <Box sx={{ width: '100%', p: 4 }}>
            <Paper elevation={3} sx={{ p: 3, mb: 4 }} className="relative">
                <Typography variant="h5" gutterBottom>
                    Overall Review and Rating
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            type="number"
                            label="Review"
                            variant="outlined"
                            value={formik.values.review}
                            onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                formik.setFieldValue('review', isNaN(value) ? '' : value);
                            }}
                            inputProps={{ step: 0.1 }}
                            error={formik.touched.review && Boolean(formik.errors.review)}
                            helperText={formik.touched.review && formik.errors.review}
                        />
                    </Grid>
                    <Box className="absolute right-5 top-12">
                        <Grid item xs={12} sm={6}>
                            <span className='text-sm text-gray-400 flex'>Rating</span>
                            <Rating
                                name="rating"
                                value={formik.values.rating}
                                onChange={(_, newValue) => {
                                    formik.setFieldValue('rating', newValue);
                                }}
                                icon={<Star size={24} fill="currentColor" />}
                                emptyIcon={<Star size={24} />}
                            />
                            {formik.touched.rating && formik.errors.rating && (
                                <Typography color="error" variant="caption">
                                    {formik.errors.rating}
                                </Typography>
                            )}
                        </Grid>
                    </Box>
                </Grid>
            </Paper>
            <Paper elevation={3}>
                <Tabs
                    value={activeTab}
                    onChange={(_, newValue) => setActiveTab(newValue)}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {categories.map((category, index) => (
                        <Tab
                            key={category.name}
                            label={
                                <Box sx={{ display: 'flex', alignItems: 'center' }} >
                                    {category.icon}
                                    <Box sx={{ ml: 1, textTransform: 'capitalize' }} >{category.name}</Box>
                                </Box>
                            }
                        />
                    ))}
                </Tabs>
                {categories.map((category, index) => (
                    <Box
                        key={category.name}
                        role="tabpanel"
                        hidden={activeTab !== index}
                    >
                        {activeTab === index && renderTabContent(category.name)}
                    </Box>
                ))}
            </Paper>
        </Box>
    );
}

export default MoreInfo;