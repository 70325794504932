import React,{useState} from 'react';
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import TodayIcon from '@mui/icons-material/Today';
import ClearIcon from '@mui/icons-material/Clear';
const styles = {
  iconBackNext: {
    fontSize: "16px",
    color: "#00b4b4",
    cursor: "pointer",
    width: '29px',
  },
  buttonToday: {
    padding: 20,
    outline: "none",
    border: "none",
    background: "none",
    width: "28px",
    height: "36px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: "16px",
    color: "#00b4b4",
    fontSize: "16px",
    fontWeight: '700',
  },
  viewButton: {
    background: 'rgb(42 201 201)',
    color: 'white',
    padding: '12px',
    marginRight: '2px',
  },
  activeViewButton: {
    background: 'rgb(30 150 150)',
    color: 'white',
    padding: '12px',
    marginRight: '2px',
    fontWeight: 'bold',
  },
}
function CustomCalendar({ label, onView, onNavigate, views, onSelectedItemsChange,view}) {
  const options = ['Reservation', 'Inquiries', 'Tasks (assigned to me)','Tasks (supervised by me)','All tasks','Minimum stay','Notes'];
  const [selectedItems, setSelectedItems] = useState(['Reservation']);

  const handleSelectChange = (event) => {
    const { value } = event.target;
    onSelectedItemsChange(value);
    setSelectedItems(value); // Update parent's selected items
  };
  
const handleDelete = (itemToDelete) => {
    const newSelectedItems = selectedItems.filter((item) => item !== itemToDelete);
    setSelectedItems(newSelectedItems);
    onSelectedItemsChange(newSelectedItems); // Update parent's selected items
  };

  const formattedDate = new Date(label).toLocaleString('en-US', { month: 'long', year: 'numeric' });
  return (
    <div className="toolbarNav">
      <div className="CalendarMonthlyFilter">
        <div className="CalendarMonthlyFilter-left">
          <div className="CalendarNavigator">
            <div className="CalendarNavigator-buttons">
                    {/* <Button className="buttonTolbar" onClick={() => onNavigate('PREV')} startIcon={<ChevronLeftIcon />} />
                    <Button className="buttonTolbar" startIcon={<TodayIcon />} />
                    <Button className="buttonTolbar" onClick={() => onNavigate('NEXT')} endIcon={<ChevronRightIcon />} /> */}
                    <div className='btnsNest' >
              <ArrowBackIosIcon onClick={() => onNavigate('PREV')} style={styles.iconBackNext} />
              <button onClick={()=>onNavigate('TODAY')} style={styles.buttonToday}>Today</button>
              <ArrowForwardIosIcon onClick={() => onNavigate('NEXT')} style={styles.iconBackNext} />
              </div>
                    
             </div>
            <div className='formaDate'>{formattedDate}</div>
            <div className="CalendarNavigator-dropdown">
              {/* <Select className="CalendarNavigator-dropdown_element" defaultValue={24}>
                {[...Array(48)].map((_, index) => (
                  <MenuItem key={index} value={index}>May 2022</MenuItem>
                ))}
              </Select> */}
            </div>
          </div>
        </div>
        <div>
          {/* <Grid container alignItems="center">
            <Grid item></Grid>
          </Grid> */}
        </div>
      </div>
      <div className="CalendarMonthlyFilter-right">
        <div className="CalendarMonthlyFilter-things">
          <div style={{ display: 'flex' }}>
            {selectedItems?.length > 0 && (
              <div style={{ display: 'flex' }} >
                {selectedItems.map((item) => (
                  <div key={item} style={{ display: 'flex', alignItems: 'center', marginTop: '10px', fontSize: '10px' }}>
                    <span>{item}</span>
                    <Button onClick={() => handleDelete(item)}>
                      <ClearIcon />
                    </Button>
                  </div>
                ))}
              </div>
            )}
            <FormControl sx={{ width: 200, padding: '7px' }}>
              <InputLabel id="multi-select-label">Add</InputLabel>
              <Select
                labelId="multi-select-label"
                id="multi-select"
                multiple
                value={selectedItems}
                onChange={handleSelectChange}
                renderValue={(selected) => (
                  <div>
                    {selected?.map((item) => (
                      <span key={item}>{item} /</span>
                    ))}
                  </div>
                )}
              >
                {options?.map((option) => (
                  <MenuItem key={option} value={option}>
                    <span>{option}</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="CalendarMonthlyFilter-switcher">
          <Button
            variant="container"
            style={view === 'month' ? styles.activeViewButton : styles.viewButton}
            onClick={()=>onView(views[1])}
          >
            Calendar
          </Button>
          <Button
            variant="container"
            style={view === 'year' ? styles.activeViewButton : styles.viewButton}
            onClick={()=>onView(views[0])}
          >
            Year
          </Button>
          <Button
            variant="container"
            style={{ background: 'white', color: 'gray', padding: '14px',border: '1px solid #e2e2e2'}}
            startIcon={<TodayIcon />}
            onClick={()=>onNavigate('TODAY')}
          >
          
          </Button>

        </div>
      </div>
    </div>
  );
}

export default CustomCalendar;
