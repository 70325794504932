import React from 'react';

const TotalRevenueTable = ({ columns, totalRevenue, totals }) => {
  return (
    <table className="p-datatable-table">
      <tbody className="p-datatable-tbody">
        <tr className="p-datatable-row font-bold">
          {columns.map((col, index) => {
            let value = '';
            switch (col.field) {
              case 'name':
                value = 'Total';
                break;
              case 'guestName':
                value = 'Total';
                break;
              case 'totalRevenue':
                value = totalRevenue || '__';
                break;
              case 'fees':
                value = totals?.totalFees || '__';
                break;
              case 'nightsBooked':
                value = totals?.totalNightsBooked || '__';
                break;
              case 'ownerStayNights':
                value = totals?.totalOwnerStayNights || '__';
                break;
              case 'totalCheckIns':
                value = totals?.totalCheckIns || '__';
                break;
              case 'nightsAvailable':
                value = totals?.totalNightsAvailable || '__';
                break;
              case 'occupancyRate':
                value = totals?.averageOccupancyRate ? `${totals.averageOccupancyRate.toFixed(2)}%` : '__';
                break;
              case 'revenuePerNightBooked':
                value = totals?.revenuePerNightBooked || '__';
                break;
              case 'revenuePerNightAvailable':
                value = totals?.revenuePerNightAvailable || '__';
                break;
              case 'averageNightlyRate':
                value = totals?.averageNightlyRate || '__';
                break;
              case 'averageRevenuePerStay':
                value = totals?.averageRevenuePerStay || '__';
                break;
              case 'cancelations':
                value = totals?.totalCancelations || '__';
                break;
              case 'cancelationPercentage':
                value = totals?.cancelationPercentage ? `${totals.cancelationPercentage.toFixed(2)}%` : '__';
                break;
              default:
                value = '';
            }
            return (
              <td key={index} className="p-datatable-cell">
                {value}
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};

export default TotalRevenueTable;
