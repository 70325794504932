import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    TextField,
    Box,
    Switch,
    FormControlLabel,
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { updatePropertyType } from '../services/serverApi.listing';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Property type name is required'),
    manageRoomType: Yup.boolean().required('Manage room type is required')
});

const ModifyPropertyType = ({ open, onClose, setPropertyTypes, propertyTypes, index, dataPropertyType }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        setIsLoading(true);
        const formData = {
            name: values.name,
            manageRoomType: values.manageRoomType
        };
        try {
            const response = await updatePropertyType(dataPropertyType._id, formData);
            const updatedPropertyType = response.data.propertyTypes;
            const newPropertyTypes = propertyTypes.map(propertyType =>
                propertyType._id === updatedPropertyType._id ? updatedPropertyType : propertyType
            );
            setPropertyTypes(newPropertyTypes);
            setSubmitting(false);
            onClose();
            toast.success('Property type updated successfully');
        } catch (error) {
            setErrorMessage(error.message);
            setSubmitting(false);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className='bg-medium-aquamarine text-white'>
                <Typography variant="h6" style={{ color: 'white' }} align="center">
                    Modify Property Type
                </Typography>
            </DialogTitle>
            <DialogContent className='pt-4'>
                {errorMessage && (
                    <Box mb={2}>
                        <Typography variant="body2" color="error" align="center">
                            {errorMessage}
                        </Typography>
                    </Box>
                )}
                <Formik
                    initialValues={{
                        name: dataPropertyType?.name || '',
                        manageRoomType: dataPropertyType?.manageRoomType || false
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <Box margin={1}>
                                <Field
                                    as={TextField}
                                    name='name'
                                    label='Property Type Name'
                                    fullWidth
                                    error={Boolean(errorMessage)}
                                    helperText={<ErrorMessage name='name' />}
                                />
                            </Box>
                            <Box margin={1}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={values.manageRoomType}
                                            onChange={(event) => setFieldValue('manageRoomType', event.target.checked)}
                                            name="manageRoomType"
                                            color="primary"
                                        />
                                    }
                                    label="Manage Room Type"
                                />
                                <ErrorMessage name='manageRoomType' component={Typography} color="error" />
                            </Box>
                            <DialogActions>
                                <Button onClick={onClose} color='secondary'>
                                    Cancel
                                </Button>
                                <button className='text-white py-1 px-3 bg-medium-aquamarine rounded-lg' type="submit" style={{ borderRadius: '5px' }}>
                                    {isLoading ? 'Updating...' : 'Update'}
                                </button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

export default ModifyPropertyType;