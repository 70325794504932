import React from 'react';

const ButtonTab = ({ name, icon, onClick, isActive, hasError }) => {
    const handleClick = () => {
        const formName = name.replace(/\s+/g, '').toLowerCase();
        onClick(formName);
    };

    const getButtonStyle = () => {
        if (!hasError && isActive) {
            return "bg-[#00b4b4] text-white hover:bg-[#009999]";
        }
        if (hasError) {
            return "bg-red-100 text-red-600 hover:bg-red-200";
        }
        return "bg-[#6fd1bd] text-white hover:bg-[#00b4b4]";
    };

    return (
        <button
            type="button"
            className={`
                py-1 px-1 w-full !rounded-md !text-[14px] shadow-sm cursor-pointer
                transition-colors duration-200
                ${getButtonStyle()}
            `}
            onClick={handleClick}
        >
            <span className="w-[11rem] float-left flex items-center justify-start">
                {icon} <span className="ml-1">{name}</span>
            </span>
        </button>
    );
};

export default ButtonTab;