import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';

export function getcities(page, limit, paged) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/city?page=${page || 0}&limit=${limit || 20}&paged=false`);
}

export function getBookedNights(startDate, endDate, city, listingId) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations/booked-nights?startDate=${startDate}&endDate=${endDate}&city=${city}&listingId=${listingId}`);
}

export function getRentalRevenue(startDate, endDate, city, listingId) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations/rental-revenue?startDate=${startDate}&endDate=${endDate}&city=${city}&listingId=${listingId}`);
}

export function getReservationStats(startDate, endDate, city, listingId) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations/reservation-stats?startDate=${startDate}&endDate=${endDate}&city=${city}&listingId=${listingId}`);
}

export function getChannelStats(startDate, endDate, city, listingId) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations/channel-stats?startDate=${startDate}&endDate=${endDate}&city=${city}&listingId=${listingId}`);
}

export function getoccupancyRate(startDate, endDate, city, listingId) {
    return axios.get((`${MICROSERVICE_BASE_URL.SRV_CALENDAR}/calendar/occupancy-rate?startDate=${startDate}&endDate=${endDate}&city=${city}&listingId=${listingId}`));
}

export function getAverageDailyRate(startDate, endDate, city, listingId) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_CALENDAR}/calendar/average-daily-rate?startDate=${startDate}&endDate=${endDate}&city=${city}&listingId=${listingId}`);
}

export function getAverageRevenuePerStay(startDate, endDate, city, listingId) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_CALENDAR}/calendar/average-revenue-per-stay?startDate=${startDate}&endDate=${endDate}&city=${city}&listingId=${listingId}`);
}

export function getListings(staging = false) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/listings?staging=${staging}`);
}

export function getCheckinBychannel(startDate, endDate, city, listingId) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations/checkins-by-channel?startDate=${startDate}&endDate=${endDate}&city=${city}&listingId=${listingId}`);
}

export function getRevenueBychannel(startDate, endDate, city, listingId) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations/revenue-per-channel?startDate=${startDate}&endDate=${endDate}&city=${city}&listingId=${listingId}`);
}

export function getBookedNightsByChannel(startDate, endDate, city, listingId) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations/booked-nights-per-channel?startDate=${startDate}&endDate=${endDate}&city=${city}&listingId=${listingId}`);
}

export function getListingsStats(staging = false) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/listings/stats?staging=${staging}`);
}

export function getResStats(staging = false) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations/stats?staging=${staging}`);
}

export function getListingsPagination(page, limit) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/listings/?page=${page}&limit=${limit}`);
}

export function getRevenuePerLandR(startDate, endDate, type, itemIds) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations/revenue-per-l-and-r?startDate=${startDate}&endDate=${endDate}&type=${type}&itemIds=${itemIds}`);

}

export const getReservationPagination = (page, limit) => {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations?page=${page}&limit=${limit}`);
}