import React, { useState, useEffect } from 'react';
import { updateMinutEventHandler, getMinutEventHandler, getWebhookConfigs } from '../services/serverApi.minut';
import { allEvents } from './EventsList';
import { Box, Typography, Checkbox, FormControlLabel, Button, CircularProgress, Divider, TextField, Autocomplete, Paper, Chip } from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import { X } from 'lucide-react';


const EventSelector = () => {
    const [eventSelections, setEventSelections] = useState({
        client: [],
        addToReservation: [],
        admin: []
    });
    const [webhookEvents, setWebhookEvents] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchCurrentEvents();
        fetchWebhookConfigs();
    }, []);

    const fetchCurrentEvents = async () => {
        try {
            const response = await getMinutEventHandler();
            if (response.data && response.data.message !== "no data found") {
                const { client, addToReservation, admin } = response.data;
                setEventSelections({
                    client: Array.isArray(client) ? client : [],
                    addToReservation: Array.isArray(addToReservation) ? addToReservation : [],
                    admin: Array.isArray(admin) ? admin : []
                });
            }
        } catch (error) {
            console.error('Failed to fetch current events:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchWebhookConfigs = async () => {
        try {
            const response = await getWebhookConfigs();
            if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                const firstConfig = response.data[0];
                if (firstConfig.events && Array.isArray(firstConfig.events)) {
                    setWebhookEvents(firstConfig.events);
                } else {
                    setWebhookEvents([]);
                }
            } else {
                setWebhookEvents([]);
            }
        } catch (error) {
            console.error('Failed to fetch webhook configs:', error);
            setWebhookEvents([]);
        }
    };

    const handleSelectAll = (category) => {
        setEventSelections(prev => ({
            ...prev,
            [category]: prev[category].length === allEvents.length ? [] : [...allEvents]
        }));
    };

    const handleSave = async () => {
        try {
            const dataToSend = {
                client: eventSelections.client,
                addToReservation: eventSelections.addToReservation,
                admin: eventSelections.admin
            };
            await updateMinutEventHandler(dataToSend);
            toast.success('Events updated successfully');
        } catch (error) {
            console.error('Failed to update events:', error);
            toast.error('Failed to update events. Please try again.');
        }
    };

    const renderEventChip = (event) => {
        const isIncluded = webhookEvents.includes(event);
        return (
            <Chip
                label={`${event} ${isIncluded ? '' : '(excluded)'}`}
                style={{
                    color: isIncluded ? '#00b4b4' : 'inherit',
                    backgroundColor: isIncluded ? 'white' : 'inherit',
                    borderColor: isIncluded ? '#00b4b4' : 'inherit',
                }}
                variant={isIncluded ? 'outlined' : 'outlined'}
            />
        );
    };
    
    if (loading) return <CircularProgress />;

    return (
        <Paper elevation={3} sx={{ p: 4, margin: 'auto' }}>
            <ToastContainer />
            <div className="flex items-center justify-between mb-4">
                <Typography variant="h4" gutterBottom>Event Selector</Typography>
                <Button variant="contained" onClick={handleSave} sx={{ mt: 2 }} className="!bg-medium-aquamarine text-white">
                    Save
                </Button>
            </div>

            {Object.entries(eventSelections).map(([category, selectedEvents]) => (
                <Box key={category} sx={{ marginBottom: 4 }}>
                    <div className="flex items-center gap-4">
                        <Typography variant="h5">{category} :</Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedEvents.length === allEvents.length}
                                    onChange={() => handleSelectAll(category)}
                                />
                            }
                            label="Select All"
                        />
                    </div>
                    <Divider sx={{ my: 2 }} />
                    <Autocomplete
                        multiple
                        options={allEvents}
                        value={selectedEvents}
                        onChange={(event, newValue) => {
                            setEventSelections(prev => ({ ...prev, [category]: newValue || [] }));
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={`Select ${category} events`}
                                placeholder="Select events"
                            />
                        )}
                        renderOption={(props, option) => {
                            const isIncluded = webhookEvents.includes(option);
                            return (
                                <li {...props} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    {renderEventChip(option)}
                                </li>
                            );
                        }}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip
                                    key={index}
                                    {...getTagProps({ index })}
                                    label={option}
                                    onDelete={() => {
                                        const newValue = selectedEvents.filter(event => event !== option);
                                        setEventSelections(prev => ({ ...prev, [category]: newValue }));
                                    }}
                                    deleteIcon={<X size={20} className="border !border-gray-300 rounded-full bg-white" />}
                                    style={{
                                        backgroundColor: webhookEvents.includes(option) ? '#e8f5e9' : '#ffebee',
                                        color: 'inherit',
                                    }}
                                />
                            ))
                        }
                    />
                </Box>
            ))}
        </Paper>
    );
};

export default EventSelector;