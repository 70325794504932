import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Modal,
    TextField,
} from '@mui/material';
import { UpdateNameListing } from '../services/serverApi.listing';

const ModifyListings = ({ open, handleClose, selectedListing, onListingUpdated }) => {
    const [listingName, setListingName] = useState('');

    useEffect(() => {
        if (selectedListing) {
            setListingName(selectedListing.name || '');
        }
    }, [selectedListing]);

    const handleUpdateListing = async () => {
        try {
            if (selectedListing && selectedListing._id) {
                const updateData = {
                    name: listingName
                };
                
                const response = await UpdateNameListing(selectedListing._id, updateData);
                
                if (response.success) {
                    const updatedListing = {
                        ...selectedListing,
                        name: listingName
                    };
                    
                    onListingUpdated(updatedListing);
                    handleClose();
                } else {
                    console.error('Failed to update listing:', response.message);
                }
            }
        } catch (error) {
            console.error('Error updating listing:', error);
        }
    };
    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box className="absolute top-12 left-1/2 -translate-x-1/2  w-[550px] rounded-sm shadow-[24] bg-[#f8fafb]">
                <Box className="w-full mb-6 p-4">
                    <Typography className="!text-[18px] !font-bold !mb-4" id="modal-modal-title">
                        Modify listing name 
                    </Typography>
                    <TextField
                        fullWidth
                        label="Listing Name"
                        variant="outlined"
                        value={listingName}
                        onChange={(e) => setListingName(e.target.value)}
                        className="!mb-4"
                    />
                </Box>
                <Box className="flex justify-end p-3 bg-white border-t-1 border-gray-200">
                    <Box className="flex gap-2">
                        <button
                            className="px-3 py-1 bg-[#d6fff7] text-[#70d4c0] !rounded-sm mr-2 !text-[16px]"
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="px-3 py-1 bg-[#00b4b4] text-white !rounded-sm mr-2 !text-[16px]"
                            onClick={handleUpdateListing}
                        >
                            Update
                        </button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default ModifyListings;