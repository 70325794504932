import React from 'react';
import { Dialog, IconButton, DialogContent, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Chip } from '@mui/material';
import { Calendar, User, UserCheck, Clock, X } from 'lucide-react';
import notData from 'assets/images/illustrations/3caaeccfea86540f56c0bfecd3d6f412.svg';

const ReservationPopup = ({ isOpen, onClose, reservations }) => {
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
        >
            <Box sx={{
                p: 2.5,
                bgcolor: '#f8fafc',
                borderBottom: '1px solid #e2e8f0',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <Typography variant="h6" sx={{ fontWeight: 600, color: '#1e293b' }}>
                    Reservation Details
                </Typography>
                <IconButton onClick={onClose} size="small">
                    <X size={20} />
                </IconButton>

            </Box>

            <DialogContent>
                {reservations?.length > 0 ? (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Reservation #</TableCell>
                                    <TableCell>Guest Name</TableCell>
                                    <TableCell>Guests</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Dates</TableCell>
                                    <TableCell>Timeline</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reservations.map((reservation) => (
                                    <TableRow key={reservation._id}>
                                        <TableCell>
                                            <Box display="flex" alignItems="center">
                                                <Calendar size={16} />
                                                <Typography variant="body2" ml={1}>
                                                    {reservation.reservationNumber}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display="flex" alignItems="center">
                                                <User size={16} />
                                                <Typography variant="body2" ml={1}>
                                                    {reservation.guestFirstName} {reservation.guestLastName}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display="flex" alignItems="center">
                                                <UserCheck size={16} />
                                                <Typography variant="body2" ml={1}>
                                                    {reservation.numberOfGuests}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Chip
                                                label={reservation.status}
                                                color={reservation.status === 'Confirmed' ? 'success' : 'warning'}
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Box display="flex" flexDirection="column">
                                                <Typography variant="body2">
                                                    {new Date(reservation.arrivalDate).toLocaleDateString()} - {new Date(reservation.departureDate).toLocaleDateString()}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display="flex" alignItems="center">
                                                <Clock size={16} />
                                                <Typography variant="body2" ml={1}>
                                                    {reservation.timeLine}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Box mt={1}>
                        <div className="flex justify-center items-center flex-col">
                            <img
                                src={notData}
                                alt="No data found"
                                style={{ width: '120px', height: '120px' }}
                            />
                            <Typography variant="h6" style={{ color: 'gray' }}>
                                No data found
                            </Typography>
                        </div>
                    </Box>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default ReservationPopup;