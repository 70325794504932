import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ListingDetails from "features/listing/pages/details.page";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

// Material Dashboard 2 React example components
import Sidenav from "components/Sidenav";
import Configurator from "components/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import { SignOutButton } from "@clerk/clerk-react";
import Chip from "@mui/material/Chip";
import {
  Zap
} from 'lucide-react';


// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";

export default function Admin() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const staging = JSON.parse(localStorage.getItem('isStaging')) || false;

  // // Open sidenav when mouse enter on mini sidenav
  // const handleOnMouseEnter = () => {
  //   if (miniSidenav && !onMouseEnter) {
  //     setMiniSidenav(dispatch, false);
  //     setOnMouseEnter(true);
  //   }
  // };

  // // Close sidenav when mouse leave mini sidenav
  // const handleOnMouseLeave = () => {
  //   if (onMouseEnter) {
  //     setMiniSidenav(dispatch, true);
  //     setOnMouseEnter(false);
  //   }
  // };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);


  const getRoutes = (allRoutes) =>

    allRoutes.map((route) => {


      if (route.type === 'dropdown' && route.subRoutes) {
        return getRoutes(route.subRoutes);
      }


      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }


      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );
  // const configsMode = (
  //   <MDBox
  //     display="flex"
  //     justifyContent="center"
  //     alignItems="center"
  //     position="fixed"
  //     right="1px"
  //     top="40%"
  //     zIndex={99}
  //     color="dark"
  //   >
    
  //         <div className="p-3 opacity-15">
  //           <Chip variant="filled" label="Test Mode" color="error" size="medium" />
  //         </div> 
  //   </MDBox>
  // );


  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName={
              <div className="relative">
                <div className={`flex ${staging ? "" : "gap-[5.7rem]"}`}>
                  {staging ? <div className="p-3">
                    <Chip variant="outlined" label="Test Mode" color="warning" size="small" />
                  </div> : <div></div>}
                  <SignOutButton>
                    <ExitToAppIcon />
                  </SignOutButton>
                </div>
              </div>
            }
            routes={routes}
          />
          <Configurator />
          {configsButton}
          {/* {staging ? configsMode : ""} */}
        </>
      )}
      <Routes>
        {getRoutes(routes)}
        {/* <Route path="admin/Listing/Listing_Details" element={<ListingDetails/>} /> */}
        <Route path="*" element={<Navigate to="admin/overview" />} />
      </Routes>
    </ThemeProvider>
  );
}
