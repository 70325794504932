import React, { useEffect } from 'react';
import { useUser, useAuth } from "@clerk/clerk-react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { CircularProgress, Box } from '@mui/material';

function AuthRoute() {
  const { isLoaded, user } = useUser();
  const { isSignedIn, signOut } = useAuth();

  console.log({isSignedIn, user });
  const navigate = useNavigate();

  useEffect(() => {
    const autoLogout = async () => {
      if (isLoaded && isSignedIn) {
        const isAdmin = user?.publicMetadata?.role === 'admin'; 
        if (!isAdmin) {
          await signOut();
          navigate('/login', { state: { isUnauthorized: true } });
        }
      }
    };

    autoLogout();
  }, [isLoaded, isSignedIn, user, signOut, navigate]);

  if (!isLoaded) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isSignedIn) {
    return <Navigate to="/login" state={{ isUnauthorized: true }} />;
  }

  const isAdmin = user?.publicMetadata?.role === 'admin';

  if (!isAdmin) {
    return null; 
  }

  return <Outlet />;
}

export default AuthRoute;
