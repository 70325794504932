import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';

export function getCalnedarById(listingId, start, end) {
  return axios.get(`${MICROSERVICE_BASE_URL.CALENDAR}/${listingId}/calendar?startDate=${start || ''}&endDate=${end || ''}`);

}

export function updateCalnedar(data) {
  return axios.put(`${MICROSERVICE_BASE_URL.CALENDAR}/update-calendar`, data);
}

export function geListingIds(staging = false) {
  return axios.get(`${MICROSERVICE_BASE_URL.LISTING}?staging=${staging}`);
}
// -------------------------multi--------------------
export function getMultiCalendById(listingId, start, end) {
  return axios.get(`${MICROSERVICE_BASE_URL.CALENDAR}/${listingId}/calendar?startDate=${start || ''}&endDate=${end || ''}`);
}

export function getMultiListing(page, limit, filters, staging = false) {
  const tagsString = filters?.tags ? filters.tags.join(',') : '';
  return axios.get(`${MICROSERVICE_BASE_URL.LISTING}/?page=${page || 0}&limit=${limit || 20}&paged=true&country=${filters?.country || ''}&city=${filters?.city || ''}&tags=${tagsString}&name=${filters?.match || ''}&staging=${staging}`);
}

// --------------------filters enpoints -------------

export function getcountries(page, limit, paged) {
  return axios.get(`${MICROSERVICE_BASE_URL.COUNTRY}?page=${page || 0}&limit=${limit || 10}&paged=false`);
}

export function getcities(page, limit, paged) {
  return axios.get(`${MICROSERVICE_BASE_URL.CITY}?page=${page || 0}&limit=${limit || 20}&paged=false`);
}
export function getTags() {
  return axios.get(`${MICROSERVICE_BASE_URL.TAG}?paged=false`);
}


// -------------------------Inventory For Listings--------------------
export function getInventoryForListings(listingIds, startDate, endDate) {
  const listingIdsParams = listingIds.map(id => `listingIds[]=${id}`).join('&');
  
  return axios.get(
    `${MICROSERVICE_BASE_URL.SRV_CALENDAR}/inventory/get-inventory?${listingIdsParams}&startDate=${startDate || ''}&endDate=${endDate || ''}`
  );
}

export function updateInventoryForListings(data) {
  return axios.put(`${MICROSERVICE_BASE_URL.SRV_CALENDAR}/inventory/update-inventory`, data);
}
