import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import {
  Tabs,
  Tab,
  Box,
  Button,
  Typography,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { createUnit, updateUnit, getProjects, getUnitById } from '../../services/serverApi.ProjectUnits';
import { toast, ToastContainer } from 'react-toastify';
import BasicInformation from '../unitModalInfo/BasicInformation';
import LocationInformation from '../unitModalInfo/LocationInformation';
import PropertyFeatures from '../unitModalInfo/PropertyFeatures';
import PriceInformation from '../unitModalInfo/PriceInformation';
import ImageUploadSection from '../imageUpload/ImageUploadSection';
import FinancialMetrics from '../unitModalInfo/FinancialMetrics'; 
import validationSchema from '../unitModalInfo/validationSchema';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';

const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#00b4b4',
  },
});

const StyledTab = styled(Tab)({
  '&.Mui-selected': {
    color: '#00b4b4',
  },
  '&:hover': {
    color: '#00b4b4',
    opacity: 0.8,
  },
});

const TabPanel = ({ children, value, index }) => (
  <div hidden={value !== index} className="py-4">
    {value === index && children}
  </div>
);

const UnitFormPage = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [unit, setUnit] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { unitId } = useParams();

  useEffect(() => {
    fetchProjects();
    if (unitId) {
      fetchUnit();
    }
  }, [unitId]);

  const fetchUnit = async () => {
    try {
      setIsLoading(true);
      const response = await getUnitById(unitId);
      setUnit(response.data.unit);
    } catch (error) {
      console.error('Error fetching unit:', error);
      toast.error('Failed to fetch unit details');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await getProjects({ page: 0, limit: 100 });
      if (response?.data?.projects) {
        setProjects(response.data.projects);
      }
    } catch (error) {
      console.error('Error fetching projects:', error);
      // setErrorMessage('Failed to load projects');
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);
    setErrorMessage('');

    try {
      const submissionData = { ...values };
      submissionData.projectId = submissionData.projectId || null;

      const response = unitId
        ? await updateUnit(unitId, submissionData)
        : await createUnit(submissionData);

      if (response.data.success) {
        toast.success(unitId ? 'Unit updated successfully' : 'Unit created successfully');
        navigate('/admin/units');
      }
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessages = error.response.data.errors.map(err => err.message).join(', ');
        // setErrorMessage(errorMessages);
        toast.error(errorMessages);
      } else {
        toast.error(error.message || 'Error processing unit');
      }
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  const initialValues = {
    unitName: unit?.unitName || '',
    propertyType: unit?.propertyType || 'Villa',
    condition: unit?.condition || 'Neuf',
    totalArea: unit?.totalArea || 0,
    livingArea: unit?.livingArea || 0,
    city: unit?.city || '',
    district: unit?.district || '',
    address: unit?.address || '',
    projectId: unit?.projectId || '',
    gps: unit?.gps || { latitude: 0, longitude: 0 },
    numberOfRooms: unit?.numberOfRooms || 0,
    numberOfBedrooms: unit?.numberOfBedrooms || 0,
    numberOfBathrooms: unit?.numberOfBathrooms || 0,
    salePrice: unit?.salePrice || 0,
    pricePerSqm: unit?.pricePerSqm || 0,
    gatedCommunity: unit?.gatedCommunity || false,
    security: unit?.security || false,
    swimmingPool: unit?.swimmingPool || 'Aucune',
    numberOfFloors: unit?.numberOfFloors || 0,
    floor: unit?.floor || 0,
    elevator: unit?.elevator || false,
    balcony: unit?.balcony || false,
    balconyArea: unit?.balconyArea || 0,
    landArea: unit?.landArea || 0,
    garage: unit?.garage || false,
    parkingSpaces: unit?.parkingSpaces || 0,
    garden: unit?.garden || 0,
    images: unit?.images || [],
    status: unit?.status || 'Available',
    description: unit?.description || '',
    prixDeLocationJournalier: unit?.prixDeLocationJournalier || { min: 0, max: 0 },
    tauxOccupation: unit?.tauxOccupation || { min: 0, max: 0 },
    ota: unit?.ota || { min: 0, max: 0 },
    fraisDeGestion: unit?.fraisDeGestion || { min: 0, max: 0 },
    depensesOperationnelles: unit?.depensesOperationnelles || { min: 0, max: 0 },
    displayAsUnit: unit?.displayAsUnit || false,
    displayAsProject: unit?.displayAsProject || false,


  };

  if (isLoading && unitId) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <Box className="flex justify-center items-center h-64">
          <CircularProgress />
        </Box>
      </DashboardLayout>
    );
  }

  const TAB_LABELS = [
    "Basic Information",
    "Location",
    "Features",
    "Price",
    "Financial Metrics" ,
    "Images"

  ];

  const TOTAL_TABS = TAB_LABELS.length;


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />
      <div className="card p-4">
        <div className="flex justify-between items-center mb-4">
          <Typography variant="h4" component="h1">
            {unitId ? 'Update Unit' : 'Create New Unit'}
          </Typography>
          <Button
            type="submit"
            form="unitForm"
            variant="contained"
            className="!bg-medium-aquamarine !text-white"
            disabled={isLoading}
          >
            {unitId ? 'Update Unit' : 'Create Unit'}
          </Button>
        </div>

        {errorMessage && (
          <Typography color="error" className="mb-4">
            {errorMessage}
          </Typography>
        )}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form id="unitForm">
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <StyledTabs
                  value={currentTab}
                  onChange={(_, newValue) => setCurrentTab(newValue)}
                  className="mb-4"
                >
                  <StyledTab label="Basic Information" />
                  <StyledTab label="Location" />
                  <StyledTab label="Features" />
                  <StyledTab label="Price" />
                  <StyledTab label="Financial Metrics" />
                  <StyledTab label="Images" />
                </StyledTabs>
              </Box>

              <TabPanel value={currentTab} index={0}>
                <BasicInformation
                  touched={touched}
                  errors={errors}
                  projects={projects}
                  isLoading={isLoading}
                />
              </TabPanel>

              <TabPanel value={currentTab} index={1}>
                <LocationInformation
                  touched={touched}
                  errors={errors}
                />
              </TabPanel>

              <TabPanel value={currentTab} index={2}>
                <PropertyFeatures
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              </TabPanel>

              <TabPanel value={currentTab} index={3}>
                <PriceInformation
                  touched={touched}
                  errors={errors}
                />
              </TabPanel>

              <TabPanel value={currentTab} index={4}>
                <FinancialMetrics
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              </TabPanel>

              <TabPanel value={currentTab} index={5}>
                <ImageUploadSection
                  values={values}
                  touched={touched}
                  errors={errors}
                  setFieldValue={setFieldValue}
                />
              </TabPanel>
              
              <Box className="flex justify-between mt-4">
                <Button
                  onClick={() => navigate('/admin/units')}
                  className="!text-gray-500"
                >
                  Cancel
                </Button>
                <div className="flex gap-2">
                  {currentTab > 0 && (
                    <Button
                      onClick={() => setCurrentTab(prev => prev - 1)}
                      className="!bg-gray-400 !text-white"
                    >
                      Previous
                    </Button>
                  )}
                  {currentTab < TOTAL_TABS - 1 && (
                    <Button
                      onClick={() => setCurrentTab(prev => prev + 1)}
                      className="!bg-medium-aquamarine !text-white"
                    >
                      Next
                    </Button>
                  )}
                </div>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </DashboardLayout>
  );
};

export default UnitFormPage;