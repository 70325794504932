import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import PropTypes from 'prop-types';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

const homeIconSvgString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 100">
  <defs>
    <filter id="shadow">
      <feDropShadow dx="0" dy="3" stdDeviation="2" flood-color="#000000" flood-opacity="0.3"/>
    </filter>
  </defs>
  <path d="M40 5 C20 5 5 25 5 50 C5 75 40 95 40 95 C40 95 75 75 75 50 C75 25 60 5 40 5 Z" fill="#00b4b4" filter="url(#shadow)"/>
  <circle cx="40" cy="42" r="25" fill="white"/>
  <path d="M40 25 L40 60 M28 37 L52 37 M28 47 L52 47" stroke="#00b4b4" stroke-width="5" stroke-linecap="round"/>
</svg>`;

export default function ListingMap({ data }) {
  const mapRef = useRef(null);
  const markerRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && data && data.lat && data.lng) {
      mapRef.current.setView([data.lat, data.lng], 10);

      if (markerRef.current) {
        markerRef.current.setLatLng([data.lat, data.lng]);
      }
    }
  }, [data]);

  useEffect(() => {
    if (data && data.lat && data.lng) {
      const map = L.map('mapid').setView([data.lat, data.lng], 10);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);

      mapRef.current = map;

 
      const homeIcon = new L.DivIcon({
        html: `<div style="display: flex; justify-content: center; align-items: center; width: 38px; height: 38px;">${homeIconSvgString}</div>`,
        className: '',
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -38]
      });


      const marker = L.marker([data.lat, data.lng], { icon: homeIcon }).addTo(map);
      marker.bindPopup('A pretty CSS3 popup. <br /> Easily customizable.');
      markerRef.current = marker;
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };
  }, [data]);

  return (
    <>
      {data && data.lat && data.lng ? (
        <div id="mapid" style={{ height: '398px' }} />
      ) : (
        <div>No location data available</div>
      )}
    </>
  );
}

ListingMap.propTypes = {
  data: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number
  })
};