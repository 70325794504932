import React, { useEffect, useState } from 'react';
import MDBox from 'components/MDBox';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import {
  getListings,
  getCountries,
  getCities,
  UpdateListing,
  deleteListing,
} from '../services/serverApi.listing';
import {
  Grid,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import FilterListing from '../components/FilterListing';
import LoadingListing from '../components/loading/LoadingListing';
import Pagination from 'components/GlobalTable/Pagination';
import notData from 'assets/images/illustrations/3caaeccfea86540f56c0bfecd3d6f412.svg';
import ModifyListings from '../components/ModifyListings';
import { toast, ToastContainer } from 'react-toastify';
import ListingItem from '../components/ListingItem';
import HeaderListing from '../components/HeaderListing';

function Listing() {
  const [filters, setFilters] = useState({
    name: '',
    country: '',
    city: '',
    sortingBy: '',
  });
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [listingItems, setListingItems] = useState([]);
  const [rowsPerPageOptions] = useState([10, 20, 50]);
  const [loading, setLoading] = useState(true);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedListing, setSelectedListing] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteListingId, setDeleteListingId] = useState(null);
  const [selectedListingName, setSelectedListingName] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedListingId, setSelectedListingId] = useState(null);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
  const [isDeleteButtonEnabled, setIsDeleteButtonEnabled] = useState(false);
  const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
  

  const navigate = useNavigate();

  const handleEditClick = (listingId) => {
    navigate(`/admin/Listing/edit/${listingId}`);
  };

  const fetchListings = async () => {
    setLoading(true);
    try {
      const result = await getListings({ page, limit, ...filters, staging });
      setListingItems(result.data.data || []);
    } catch (error) {
      setListingItems([]);
    } finally {
      setLoading(false);
    }
  };

  const handleTaskClick = (event, listingId) => {
    setAnchorEl(event.currentTarget);
    setSelectedListingId(listingId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTaskOption = () => {
    navigate(`/admin/Tasks?listingId=${selectedListingId}`);
    handleMenuClose();
  };

  const handleMasterOption = () => {
    navigate(`/admin/Master/${selectedListingId}`);
    handleMenuClose();
  };

  const handleCalendarClick = (listingId) => {
    navigate(`/admin/calendar/Inventory/${listingId}`);
  };

  const handleListingUpdated = (updatedListing) => {
    setListingItems((prevItems) =>
      prevItems.map((item) =>
        item._id === updatedListing._id ? updatedListing : item,
      ),
    );
  };

  const fetchCities = async () => {
    try {
      const response = await getCities();
      setCities(response.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await getCountries();
      setCountries(response.data);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  useEffect(() => {
    fetchListings();
  }, [filters, page, limit, staging]);

  useEffect(() => {
    fetchCities();
    fetchCountries();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (listing) => {
    setSelectedListing(listing);
    setOpen(true);
  };

  const handleDeleteClick = (listingId) => {
    const listingToDelete = listingItems.find(
      (listing) => listing._id === listingId,
    );
    setDeleteListingId(listingId);
    setSelectedListingName(listingToDelete ? listingToDelete.name : '');
    setDeleteDialogOpen(true);
    setDeleteConfirmationText('');
    setIsDeleteButtonEnabled(false);
  };

  const handleDeleteConfirm = async () => {
    if (deleteConfirmationText !== selectedListingName) {
      return;
    }

    try {
      await deleteListing(deleteListingId);
      setListingItems((prevItems) =>
        prevItems.filter((item) => item._id !== deleteListingId),
      );
      toast.success('Listing deleted successfully');
      handleDeleteClose();
    } catch (error) {
      console.error('Error deleting listing:', error);
    }
  };

  const handleDeleteClose = () => {
    setDeleteDialogOpen(false);
    setDeleteListingId(null);
    setSelectedListingName('');
    setDeleteConfirmationText('');
    setIsDeleteButtonEnabled(false);
  };

  return (
    <DashboardLayout>
      <ToastContainer />
      <DashboardNavbar />
      <MDBox py={3}>
        <HeaderListing />

        <FilterListing
          limit={limit}
          setLimit={setLimit}
          filters={filters}
          setFilters={setFilters}
          countries={countries}
          cities={cities}
          fetchListings={fetchListings}
        />

        {loading ? (
          <div className="w-[100%] flex justify-center">
            <LoadingListing />
          </div>
        ) : listingItems.length === 0 ? (
          <div className="flex justify-center items-center flex-col">
            <img
              src={notData}
              alt="No data found"
              style={{ width: '200px', height: '200px' }}
            />
            <Typography variant="h6" style={{ color: 'gray' }}>
              No data found
            </Typography>
          </div>
        ) : (
          <Grid container spacing={3}>
            {listingItems.map((listing, index) => (
              <ListingItem
                key={index}
                listing={listing}
                handleOpen={handleOpen}
                handleEditClick={handleEditClick}
                handleTaskClick={handleTaskClick}
                handleCalendarClick={handleCalendarClick}
                handleDeleteClick={handleDeleteClick}
                anchorEl={anchorEl}
                handleMenuClose={handleMenuClose}
                handleTaskOption={handleTaskOption}
                handleMasterOption={handleMasterOption}
                deleteDialogOpen={deleteDialogOpen}
                handleDeleteClose={handleDeleteClose}
                selectedListingName={selectedListingName}
                deleteConfirmationText={deleteConfirmationText}
                setDeleteConfirmationText={setDeleteConfirmationText}
                handleDeleteConfirm={handleDeleteConfirm}
                isDeleteButtonEnabled={isDeleteButtonEnabled}
                selectedListingId={selectedListingId}
                setIsDeleteButtonEnabled={setIsDeleteButtonEnabled}

              />
            ))}
          </Grid>
        )}
      </MDBox>

      <Pagination
        page={page}
        onPageChange={setPage}
        isNextDisabled={loading || listingItems.length < limit}
        limit={limit}
        onLimitChange={setLimit}
        hasPagination={true}
        rowsPerPageOptions={rowsPerPageOptions}
      />

      <ModifyListings
        open={open}
        handleClose={handleClose}
        selectedListing={selectedListing}
        onListingUpdated={handleListingUpdated}
      />
    </DashboardLayout>
  );
}

export default Listing;