import React, { useState, useEffect, useCallback } from 'react';
import {
    Typography, Button, Tabs, Tab, Box, CircularProgress,
    Card, CardContent, CardMedia, Divider, IconButton, Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { getWhatsappConfig, getLanguages, updateWhatsappConfig } from '../services/serverApi.adminConfig';
import WhatsAppConfigModal from './WhatsAppConfigModal';
import defaultAvatar from 'assets/images/placeholder.jpg';
import {
    Edit,
} from 'lucide-react';

const StyledTab = styled(Tab)(({ theme }) => ({
    minWidth: 120,
    '&.Mui-selected': {
        color: "#00bfa5",
        fontWeight: 'bold',
    },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
}));

const FeatureItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
    transition: 'background-color 0.3s',
    '&:hover': {
        backgroundColor: theme.palette.grey[200],
    },
}));

function WhatsApp() {
    const [isLoading, setIsLoading] = useState(true);
    const [whatsappConfig, setWhatsappConfig] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [error, setError] = useState(null);
    const [languages, setLanguages] = useState([]);
    const [whatsappConfigId, setWhatsappConfigId] = useState(null);
    const [featureOrder, setFeatureOrder] = useState([]);
    const [activeTab, setActiveTab] = useState(0);


    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await getWhatsappConfig();
            const { whatsappConfig } = response.data;
            setWhatsappConfig(whatsappConfig);
            setWhatsappConfigId(whatsappConfig._id);
            setFeatureOrder(whatsappConfig.features);
        } catch (error) {
            console.error('Error fetching WhatsApp config:', error);
            setError('Failed to fetch WhatsApp configuration. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    }, []);

    const fetchLanguages = useCallback(async () => {
        try {
            const response = await getLanguages();
            setLanguages(response);
        } catch (error) {
            console.error('Error fetching languages:', error);
            toast.error('Failed to fetch languages');
        }
    }, []);

    useEffect(() => {
        fetchData();
        fetchLanguages();
    }, [fetchData, fetchLanguages]);

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);

    const handleOnDragEnd = useCallback(async (result) => {
        if (!result.destination) return;

        const items = Array.from(featureOrder);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setFeatureOrder(items);

        try {
            const updatedConfig = { ...whatsappConfig, features: items };
            await updateWhatsappConfig(whatsappConfigId, updatedConfig);
            setWhatsappConfig(updatedConfig);
            toast.success('Order updated successfully!');
        } catch (error) {
            console.error('Error updating order:', error);
            toast.error('Failed to update order.');
        }
    }, [featureOrder, whatsappConfig, whatsappConfigId]);

    const handleUpdateConfig = useCallback((updatedConfig) => {
        setWhatsappConfig(updatedConfig);
        setFeatureOrder(updatedConfig.features);
    }, []);

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box p={4} className="card relative">
            <Typography variant="h4" component="h1" gutterBottom>
                WhatsApp Configuration
            </Typography>
            {whatsappConfig ? (
                <Box className="flex gap-3">
                    <WhatsAppTabs
                        whatsappConfig={whatsappConfig}
                        featureOrder={featureOrder}
                        languages={languages}
                        handleOnDragEnd={handleOnDragEnd}
                        activeTab={activeTab}
                        handleTabChange={handleTabChange}
                    />
                    <Box mt={2} className="absolute top-28 right-10">
                        <button
                            className='!text-white !bg-[#00bfa5] p-2 !rounded-md'
                            onClick={handleOpenModal}
                        >
                            <Edit />
                        </button>
                    </Box>
                </Box>
            ) : (
                <Typography>No WhatsApp configuration found.</Typography>
            )}
            {whatsappConfig && (
                <WhatsAppConfigModal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    setWhatsappConfig={handleUpdateConfig}
                    config={whatsappConfig}
                    configId={whatsappConfigId}
                    featureOrder={featureOrder}
                    languages={languages}
                    activeTab={activeTab}
                />
            )}
        </Box>
    );
}

function WhatsAppTabs({ whatsappConfig, featureOrder, languages, handleOnDragEnd, activeTab, handleTabChange }) {
    return (
        <Box sx={{ width: '100%' }}>
            <Tabs
                value={activeTab}
                onChange={handleTabChange}
                aria-label="WhatsApp configuration tabs"
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="secondary"
            >
                <StyledTab label="WhatsApp Info" id="whatsapp-tab-0" aria-controls="whatsapp-tabpanel-0" />
                <StyledTab label="Blog Info" id="whatsapp-tab-1" aria-controls="whatsapp-tabpanel-1" />
                <StyledTab label="WhatsApp Media" id="whatsapp-tab-2" aria-controls="whatsapp-tabpanel-2" />
                <StyledTab label="Image Info" id="whatsapp-tab-3" aria-controls="whatsapp-tabpanel-3" />
                <StyledTab label="Features" id="whatsapp-tab-4" aria-controls="whatsapp-tabpanel-4" />
            </Tabs>
            <div className="p-4">
                <TabPanel value={activeTab} index={0}>
                    <MultiLingualContent title="Title" content={whatsappConfig.title} languages={languages} />
                    <Divider sx={{ my: 2 }} />
                    <MultiLingualContent title="Description" content={whatsappConfig.description} languages={languages} />
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <MultiLingualContent title="Blog Title" content={whatsappConfig.blogTitle || {}} languages={languages} />
                    <Divider sx={{ my: 2 }} />
                    <MultiLingualContent title="Blog Description" content={whatsappConfig.blogDescription || {}} languages={languages} />
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                        <CardMedia
                            component={whatsappConfig.vdUrl?.includes('/MS/videos/') ? 'video' : 'img'}
                            src={whatsappConfig.vdUrl || defaultAvatar}
                            controls={whatsappConfig.vdUrl?.includes('/MS/videos/')}
                            alt="WhatsApp Media"
                            sx={{ maxWidth: 400, height: 500, margin: 'auto', objectFit: 'contain' }}
                        />
                </TabPanel>
                <TabPanel value={activeTab} index={3}>
                        <CardMedia
                            component="img"
                            image={whatsappConfig.imageUrl || defaultAvatar}
                            alt="WhatsApp Config"
                            sx={{ maxWidth: 400, margin: 'auto' }}
                        />
                        <CardContent>
                            <MultiLingualContent title="Image Title" content={whatsappConfig.imageTitle || {}} languages={languages} />
                            <Divider sx={{ my: 2 }} />
                            <MultiLingualContent title="Image Description" content={whatsappConfig.imageDescription || {}} languages={languages} />
                        </CardContent>
                </TabPanel>
                <TabPanel value={activeTab} index={4}>
                    <FeaturesList featureOrder={featureOrder} handleOnDragEnd={handleOnDragEnd} languages={languages} />
                </TabPanel>
            </div>
        </Box>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`whatsapp-tabpanel-${index}`}
            aria-labelledby={`whatsapp-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

function MultiLingualContent({ title, content, languages }) {
    return (
        <Box>
            <Typography variant="h6" gutterBottom>{title}</Typography>
            {Object.entries(content).map(([langId, text]) => (
                <Tooltip key={langId} title={text} arrow>
                    <Typography variant="body2" gutterBottom>
                        <strong>{languages.find(lang => lang._id === langId)?.name || 'Unknown'}:</strong> {text}
                    </Typography>
                </Tooltip>
            ))}
        </Box>
    );
}

function FeaturesList({ featureOrder, handleOnDragEnd, languages }) {
    return (
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="features">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                        {featureOrder.map((feature, index) => (
                            <Draggable key={feature._id} draggableId={feature._id} index={index}>
                                {(provided) => (
                                    <Box {...provided.dragHandleProps}>
                                        <FeatureItem
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            <IconButton {...provided.dragHandleProps}>
                                                <DragIndicatorIcon />
                                            </IconButton>
                                            <Box flexGrow={1}>
                                                <Typography variant="subtitle1">Feature {index + 1}</Typography>
                                                <MultiLingualContent content={feature.txt} languages={languages} />
                                            </Box>
                                            <Box>
                                                <img src={feature.iconUrl || defaultAvatar} alt="icon" style={{ width: 32, height: 32, objectFit: 'contain' }} />
                                            </Box>
                                        </FeatureItem>
                                    </Box>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}

export default WhatsApp;