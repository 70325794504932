import React from 'react';
import ReservationTable from './ReservationTable';
import { Button } from '@mui/material';

const RentalActivity = () => {
  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h5 className="text-2xl font-bold">Financial Reporting</h5>
        <div className="flex gap-2">
          <Button className="px-2 !bg-[#dcf6f6] !rounded-sm flex items-center gap-1">
            <span className="ladda-label flex items-center text-[13px] text-[#00b4b4] p-[3px] capitalize">Download Report</span>
            <i className="pi pi-angle-down text-[#00b4b4] text-sm"></i>
          </Button>
          <Button className="px-2 !bg-[#dcf6f6] !rounded-sm flex items-center gap-1">
            <i className="pi pi-cog text-[#00b4b4] text-sm"></i>
            <span className="ladda-label flex items-center text-[13px] text-[#00b4b4] p-[3px] capitalize">Settings</span>
          </Button>
        </div>
      </div>
      <ReservationTable />
    </div>
  );
};

export default RentalActivity;