import React from 'react';
import BasicInfo from './BasicInfo';
import Address from './Address';
import Media from './Media';
import Amenities from './Amenities';
import PriceAndFees from './PriceAndFees';
import AdditionalInfo from './AdditionalInfo';
import ChannelSpecific from './ChannelSpecific';
import ContactPerson from './ContactPerson';
import Attachment from './Attachment';
import CustomFields from './CustomFields';
import FinancialSettings from './FinancialSettings';
import GuestPortal from './GuestPortal';
import BedTypes from './BedTypes';
import LicenseInfo from './LicenseInfo';
import PaymentAccounts from './PaymentAccounts';
import ChannelManager from './ChannelManager';
import Cleaning from './Cleaning';
import StandardSojori from './StandardSojori';
import MoreInfo from './MoreInfo';
import AccessInfo from './AccessInfo';
import DetailAmenities from './DetailAmenities';
import Message from './Message';
import AccessDetails from './AccessDetails';
import MinutListing from './MinutListing';
import RoomType from './RoomType';
import Rooms from './Rooms';
import RoomConfig from './RoomConfig';








const FormContainer = ({tab, formik, listingId, types, propertyTypes, bedTypes, currencies}) => {
   
    
    return (
        <div className="w-full h-full bg-white mt-3 rounded-md">
            {tab && tab === 'media' && <Media formik={formik} />}
            {tab && tab === 'basicinfo' && <BasicInfo formik={formik} propertyTypes={propertyTypes} currencies={currencies}/>}
            {tab && tab === 'address' && <Address formik={formik}/>}
            {tab && tab === 'amenities' && <Amenities formik={formik}/>}
            {tab && tab === 'standardSojori' && <StandardSojori formik={formik}/>}
            {/* {tab && tab === 'price' && <PriceAndFees formik={formik}/>} */}
            {tab && tab === 'additionalinfo' && <AdditionalInfo formik={formik}/>}
            {/* {tab && tab === 'bedtypes' && <BedTypes formik={formik}/>} */}
            {tab && tab === 'channelmanager' && <ChannelManager formik={formik}/>}
            {tab && tab === 'task' && <Cleaning formik={formik}/>}
            {tab && tab === 'moreinfo' && <MoreInfo formik={formik} listingId={listingId}/>}
            {tab && tab === 'roomtype' && <RoomType formik={formik} types={types} bedTypes={bedTypes}/>}
            {tab && tab === 'rooms' && <Rooms formik={formik} types={types}  />}  
            {tab && tab === 'configuration' && <RoomConfig formik={formik} types={types}  bedTypes={bedTypes}/>} 
            {tab && tab === 'accessinfo' && <AccessInfo formik={formik} />}
            {tab && tab === 'message' && <Message formik={formik} />}
            {tab && tab === 'appartmentinformation' && <DetailAmenities formik={formik} />}
            {tab && tab === 'accessdetails' && <AccessDetails formik={formik} />}
            {tab && tab === 'minutListing' && <MinutListing formik={formik} />}
            
            
            
        </div>
    );
};



export default FormContainer;

