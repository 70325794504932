import React, { useState, useEffect, useCallback } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography,
  TextField, Select, MenuItem, FormControl, InputLabel,
  Button, Chip, Box, CircularProgress, Grid, IconButton
} from '@mui/material';
import {
  ChevronDown, Plus, Trash2, Bed, Bath, Users, HashIcon,
  DollarSign, Star, MapPin, ToggleLeft, Image
} from 'lucide-react';
import { getAmenities } from '../../services/serverApi.listing';
import AmenityPopup from './modals/AmenityPopup';
import BedTypePopup from './modals/BedTypePopup';
import RoomTypeImages from './helper/RoomTypeImages';
import RoomTypeSelect from './helper/RoomTypeSelect';

const RoomType = ({ formik, types, bedTypes }) => {
  const [expandedPanel, setExpandedPanel] = useState(null);
  const [amenities, setAmenities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAmenityPopupOpen, setIsAmenityPopupOpen] = useState(false);
  const [currentRoomTypeIndex, setCurrentRoomTypeIndex] = useState(null);
  const [isBedTypePopupOpen, setIsBedTypePopupOpen] = useState(false);

  const generateRooms = useCallback((roomType) => {
    const { startCode, roomNumber, roomTypeName } = roomType;
    console.log("startCode==>", startCode)
    return Array.from({ length: roomNumber }, (_, i) => ({
      roomNumber: startCode + i,
      roomName: `${roomTypeName}`,
      roomCode: '0000',
      address: "",
      enabled: true
    }));
  }, []);

  useEffect(() => {
    const updatedRoomTypes = formik.values.roomTypes.map(roomType => {
      if (!roomType.rooms || roomType.rooms.length !== roomType.roomNumber) {
        return { ...roomType, rooms: generateRooms(roomType) };
      }
      return roomType;
    });

    if (JSON.stringify(updatedRoomTypes) !== JSON.stringify(formik.values.roomTypes)) {
      formik.setFieldValue('roomTypes', updatedRoomTypes);
    }
  }, [formik.values.roomTypes, generateRooms]);

  useEffect(() => {
    fetchAmenities();
  }, []);

  const fetchAmenities = async () => {
    setIsLoading(true);
    try {
      const response = await getAmenities();
      setAmenities(response.data.data);
    } catch (error) {
      console.error('Error fetching amenities:', error);
    } finally {
      setIsLoading(false);
    }
  };


  const openBedTypePopup = (index) => {
    setCurrentRoomTypeIndex(index);
    setIsBedTypePopupOpen(true);
  };

  const closeBedTypePopup = () => setIsBedTypePopupOpen(false);

  const handleAddBedType = (bedTypeData) => {
    const newRoomTypes = [...formik.values.roomTypes];
    const currentBedTypes = newRoomTypes[currentRoomTypeIndex].bedTypes || [];
    const existingIndex = currentBedTypes.findIndex(bt => bt.bedTypeId === bedTypeData.bedTypeId);

    if (existingIndex !== -1) {
      currentBedTypes[existingIndex].quantity += bedTypeData.quantity;
    } else {
      currentBedTypes.push(bedTypeData);
    }

    newRoomTypes[currentRoomTypeIndex].bedTypes = currentBedTypes;
    formik.setFieldValue('roomTypes', newRoomTypes);
  };

  const handleRemoveBedType = (bedTypeId, index) => {
    const newRoomTypes = [...formik.values.roomTypes];
    newRoomTypes[index].bedTypes = newRoomTypes[index].bedTypes.filter(bt => bt.bedTypeId !== bedTypeId);
    formik.setFieldValue('roomTypes', newRoomTypes);
  };


  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  const handleAddAmenity = (amenities) => {
    if (currentRoomTypeIndex !== null) {
      const newRoomTypes = [...formik.values.roomTypes];
      newRoomTypes[currentRoomTypeIndex].amenitiesIds = amenities.map(a => a._id);
      formik.setFieldValue('roomTypes', newRoomTypes);
    }
    closeAmenityPopup();
  };

  const handleRemoveAmenity = (amenityId, index) => {
    const currentAmenities = formik.values.roomTypes[index].amenitiesIds;
    const newRoomTypes = [...formik.values.roomTypes];
    newRoomTypes[index].amenitiesIds = currentAmenities.filter(id => id !== amenityId);
    formik.setFieldValue('roomTypes', newRoomTypes);
  };

  const openAmenityPopup = (index) => {
    setCurrentRoomTypeIndex(index);
    setIsAmenityPopupOpen(true);
  };

  const closeAmenityPopup = () => setIsAmenityPopupOpen(false);

  const handleImageUpload = (roomTypeIndex, fieldName, newImages) => {
    const newRoomTypes = [...formik.values.roomTypes];
    newRoomTypes[roomTypeIndex].roomTypeImages = newImages;
    formik.setFieldValue('roomTypes', newRoomTypes);
  };

  const handleRemoveImage = (roomTypeIndex, imageIndex) => {
    const newRoomTypes = [...formik.values.roomTypes];
    newRoomTypes[roomTypeIndex].roomTypeImages.splice(imageIndex, 1);

    newRoomTypes[roomTypeIndex].roomTypeImages.forEach((img, idx) => {
      img.sortOrder = idx + 1;
    });
    formik.setFieldValue('roomTypes', newRoomTypes);
  };

  const handleRoomTypeChange = (index, field, value) => {
    const newRoomTypes = [...formik.values.roomTypes];
    const numberFields = ['startCode', 'roomNumber', 'bathroomsNumber', 'bedsNumber', 'personCapacity', 'bedroomsNumber', 'ranking', 'basePrice', 'surface'];
    newRoomTypes[index][field] = numberFields.includes(field) ? Number(value) || 0 : value;
    formik.setFieldValue('roomTypes', newRoomTypes);
  };

  const renderTextField = (label, field, icon, index, roomType) => (
    <TextField
      label={label}
      value={roomType[field]}
      error={
        formik.touched.roomTypes?.[index]?.[field] &&
        Boolean(formik.errors.roomTypes?.[index]?.[field])
      }
      helperText={
        formik.touched.roomTypes?.[index]?.[field] &&
        formik.errors.roomTypes?.[index]?.[field]
      }
      onChange={(e) => handleRoomTypeChange(index, field, e.target.value)}
      fullWidth
      type='text'
      InputProps={{
        startAdornment: React.cloneElement(icon, { size: 20, className: 'mr-2 text-gray-500' }),
      }}
      inputProps={{ min: 0 }}
    />
  );

  return (
    <div className="w-full p-4 h-auto">
      <Typography variant="h6" className="mb-4"> Room Types</Typography>
      {formik.values.roomTypes?.map((roomType, index) => (
        <Accordion
          key={index}
          expanded={expandedPanel === `panel${index}`}
          onChange={handleAccordionChange(`panel${index}`)}
          className="mb-4 shadow-md rounded-lg overflow-hidden"
        >
          <AccordionSummary
            expandIcon={<ChevronDown className="text-white" />}
            className="!bg-teal-600 text-white"
          >
            <Typography>{roomType.roomTypeName}</Typography>
          </AccordionSummary>
          <AccordionDetails className="bg-white my-3">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <RoomTypeSelect
                  roomType={roomType}
                  types={types}
                  index={index}
                  handleRoomTypeChange={handleRoomTypeChange}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {renderTextField("Number of rooms", "roomNumber", <HashIcon />, index, roomType)}
              </Grid>
              <Grid item xs={12} sm={6}>
                {renderTextField("Name", "roomTypeName", <HashIcon />, index, roomType)}
              </Grid>
              <Grid item xs={12} sm={6}>
                {renderTextField("Base Price", "basePrice", <span>DH</span>, index, roomType)}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Complement address</InputLabel>
                  <Select
                    value={roomType.useAddress ? 'Yes' : 'No'}
                    onChange={(e) => handleRoomTypeChange(index, 'useAddress', e.target.value === 'Yes')}
                    label="Use Address"
                    startAdornment={<MapPin size={20} className="mr-2 text-gray-500" />}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                {renderTextField("Number of Bedrooms", "bedroomsNumber", <Bed />, index, roomType)}
              </Grid>
              <Grid item xs={12} sm={6}>
                {renderTextField("Person Capacity", "personCapacity", <Users />, index, roomType)}
              </Grid>
              <Grid item xs={12} sm={6}>
                {renderTextField("Number of Beds", "bedsNumber", <Bed />, index, roomType)}
              </Grid>
              <Grid item xs={12} sm={6}>
                {renderTextField("Start room Code", "startCode", <HashIcon />, index, roomType)}
              </Grid>
              <Grid item xs={12} sm={6}>
                {renderTextField("Number of Bathrooms", "bathroomsNumber", <Bath />, index, roomType)}
              </Grid>
              <Grid item xs={12} sm={6}>
                {renderTextField("Ranking", "ranking", <Star />, index, roomType)}
              </Grid>
              <Grid item xs={12} sm={6}>
                {renderTextField("Surface", "surface", <HashIcon />, index, roomType)}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Active</InputLabel>
                  <Select
                    value={roomType.active ? 'Yes' : 'No'}
                    onChange={(e) => handleRoomTypeChange(index, 'active', e.target.value === 'Yes')}
                    label="Active"
                    startAdornment={<ToggleLeft size={20} className="mr-2 text-gray-500" />}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box className="flex items-center gap-2 mb-2">
                  <Typography variant="subtitle1">Select amenities</Typography>
                  <IconButton
                    onClick={() => openAmenityPopup(index)}
                    className="!text-teal-600 border !border-teal-600 rounded-full p-1"
                  >
                    <Plus size={20} />
                  </IconButton>
                </Box>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
                    {roomType.amenitiesIds.map((amenityId) => {
                      const amenity = amenities.find(a => a._id === amenityId);
                      return (
                        <Chip
                          key={amenityId}
                          label={amenity?.name}
                          onDelete={() => handleRemoveAmenity(amenityId, index)}
                          avatar={<img src={amenity?.iconUrl} alt={amenity?.name} style={{ width: 24, height: 24 }} />}
                          className="!bg-teal-100 !text-teal-800"
                        />
                      );
                    })}
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <Box className="flex items-center gap-2 mb-2">
                  <Typography variant="subtitle1">Select bed types</Typography>
                  <IconButton
                    onClick={() => openBedTypePopup(index)}
                    className="!text-teal-600 border !border-teal-600 rounded-full p-1"
                  >
                    <Plus size={20} />
                  </IconButton>
                </Box>
                <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
                  {roomType.bedTypes?.map((bedType) => {
                    const bedTypeInfo = bedTypes.find(bt => bt._id === bedType.bedTypeId);
                    return (
                      <Chip
                        key={bedType.bedTypeId}
                        label={`${bedTypeInfo?.name} (${bedType.quantity})`}
                        onDelete={() => handleRemoveBedType(bedType.bedTypeId, index)}
                        className="!bg-blue-100 !text-blue-800"
                      />
                    );
                  })}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <RoomTypeImages
                  roomType={roomType}
                  index={index}
                  handleImageUpload={handleImageUpload}
                  handleRemoveImage={handleRemoveImage}
                  formik={formik}
                />
              </Grid>
            </Grid>
            <Button
              startIcon={<Trash2 />}
              onClick={() => {
                const newRoomTypes = [...formik.values.roomTypes];
                newRoomTypes.splice(index, 1);
                formik.setFieldValue('roomTypes', newRoomTypes);
              }}
              className="mt-4 !text-red-500 border !border-red-500 hover:bg-red-50"
              variant="outlined"
            >
              Remove Room Type
            </Button>
          </AccordionDetails>
        </Accordion>
      ))}
      <Button
        startIcon={<Plus />}
        variant="contained"
        onClick={() => {
          formik.setFieldValue('roomTypes', [
            ...formik.values.roomTypes,
            {
              roomTypeName: "Standard Room",
              roomTypeConfigId: "",
              basePrice: '',
              ratePlanIds: [],
              amenitiesIds: [],
              roomTypeImages: [],
              bedTypes: [],
              useAddress: true,
              active: true,
              personCapacity: '',
              bedroomsNumber: '',
              bedsNumber: '',
              bathroomsNumber: '',
              roomNumber: '',
              startCode: '',
              ranking: '',
              surface: '',
            }
          ]);
        }}
        className="!bg-teal-600 !hover:bg-teal-700 text-white"
      >
        Add Room Type
      </Button>
      <AmenityPopup
        isOpen={isAmenityPopupOpen}
        onClose={closeAmenityPopup}
        amenities={amenities}
        selectedAmenities={currentRoomTypeIndex !== null ? formik.values.roomTypes[currentRoomTypeIndex]?.amenitiesIds : []}
        onAddAmenity={(amenity) => {
          if (currentRoomTypeIndex !== null) {
            handleAddAmenity(amenity, currentRoomTypeIndex);
          }
          closeAmenityPopup();
        }}
      />
      <BedTypePopup
        isOpen={isBedTypePopupOpen}
        onClose={closeBedTypePopup}
        bedTypes={bedTypes}
        selectedBedTypes={currentRoomTypeIndex !== null ? formik.values.roomTypes[currentRoomTypeIndex]?.bedTypes : []}
        onAddBedType={handleAddBedType}
      />
    </div>
  );
};

export default RoomType;