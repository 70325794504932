import React, { useState } from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Paper } from '@mui/material';
import ConversationList from './ConversationList';
import Chat from './Chat';
import ChatReservationDetails from './ChatReservationDetails';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';

function ChatContainer() {
  const [activeConversation, setActiveConversation] = useState(null);

  const handleSelectConversation = (conversation) => {
    setActiveConversation(conversation);
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <PanelGroup direction="horizontal" style={{ height: 'calc(100vh - 64px)' }}>
        <Panel defaultSize={25} minSize={20}>
          <Paper className="h-full">
            <ConversationList onSelectConversation={handleSelectConversation} />
          </Paper>
        </Panel>
        <PanelResizeHandle className="w-2 bg-gray-200 hover:bg-gray-300 transition-colors" />
        <Panel defaultSize={50}>
          <Paper className="h-full ">
            <Chat messages={activeConversation} />
          </Paper>
        </Panel>
        <PanelResizeHandle className="w-2 bg-gray-200 hover:bg-gray-300 transition-colors" />
        <Panel defaultSize={25} minSize={20}>
          <Paper className="h-full">
            <ChatReservationDetails activeConversation={activeConversation} />
          </Paper>
        </Panel>
      </PanelGroup>
    </DashboardLayout>
  );
}

export default ChatContainer;