import React, { useState, useEffect } from 'react';
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import GlobalTable from '../../../components/GlobalTable/GlobalTable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getOpenAiConfig,
  removeOpenAiConfig,
} from '../services/serverApi.adminConfig';
import AddOpenAiInitConfigDialog from './AddOpenAIConfig';
import ModifyOpenAiConfigDialog from './ModOpeniaConfigTop.component';
import AddIcon from '@mui/icons-material/Add';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import {
  Paper,
  Grid,
  Button,
  IconButton,
  CircularProgress,
  Switch,
  TextField,
  Box,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import { Link } from 'react-router-dom';
import { updateOpenAiConfig } from '../services/serverApi.adminConfig';
import OpenAILogo from '../../../helpers/OpenAILogo';
function OpenAiConfig() {
  const [openAiConfigItem, setOpenAiConfigItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [search, setSearch] = useState('');
  const [rowsPerPageOptions] = useState([20, 50, 100]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogM, setOpenDialogM] = useState(false);
  const [dataOpenAi, setDataOpenAi] = useState(null);
  const [openAiIndex, setOpenAiIndex] = useState(null);

  const openAiConfigItems = async () => {
    try {
      const data = await getOpenAiConfig(page, limit, search);
      setOpenAiConfigItem(data?.data?.openAiConfigs);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    openAiConfigItems();
  }, [page, limit]);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleCloseDialogM = () => {
    setOpenDialogM(false);
  };

  const handleOpenDialogM = (data, index) => {
    setOpenDialogM(true);
    setDataOpenAi(data);
    setOpenAiIndex(index);
  };

  const addAiConfig = (newItem) => {
    setOpenAiConfigItem([...openAiConfigItem, newItem]);
    handleCloseDialog();
  };
  const handleDelete = async (openAiId, index) => {
    const confirmed = window.confirm(
      'Are you sure you want to delete this item?',
    );
    if (confirmed) {
      removeOpenAiConfig(openAiId).then((response) => {
        if (response.status === 200) {
          const updatedData = openAiConfigItem.filter((item, i) => i !== index);
          setOpenAiConfigItem(updatedData);
          toast.success('Deleted Successfully');
        } else {
          console.error('Error deleting Event');
        }
      });
    }
  };
  const columns = [
    {
      field: 'type',
      header: 'Type',
      headerClassName: 'header-test',
      body: (rowData) => (
        <span className="block mx-auto text-center  text-medium-aquamarine font-bold cursor-pointer">
          {rowData?.type}
        </span>
      ),
    },
    {
      field: 'description_openai',
      header: 'Description',
      headerClassName: 'header-test',
      body: (rowData) => (
        <span className="block mx-auto text-center  text-medium-aquamarine font-bold cursor-pointer">
          {rowData?.description_openai}
        </span>
      ),
    },
    {
      field: 'enable',
      header: 'Enalbe',
      headerClassName: 'header-test',
      body: (rowData, { rowIndex }) => (
        <span className="block mx-auto text-center text-medium-aquamarine font-bold cursor-pointer">
          <Switch
            onChange={(e) => editSwitch('enable', e.target.checked, rowIndex)}
            value="checked"
            checked={rowData?.enable}
          />
        </span>
      ),
    },
    {
      field: 'configuration',
      header: 'Configuration',
      headerClassName: 'header-test',
      body: (rowData) => (
        <span className="block mx-auto text-center text-medium-aquamarine font-bold ">
          {/* {rowData?.configuration?.length > 0 ? (
          <Link  style={{cursor:'pointer',width:'87px',display:'inline-block'}} state={{ data: rowData._id }} to={`/admin/setting/openAi_Config`}>
            {rowData?.configuration?.length}
          </Link>
        ) : (
          '-'
        )} */}
          <Link
            style={{
              cursor: 'pointer',
              width: '87px',
              display: 'inline-block',
            }}
            state={{ data: rowData._id }}
            to={`/admin/setting/openAi_Config`}
          >
            {(rowData?.configuration || []).length}
          </Link>
        </span>
      ),
    },
    {
      field: 'null',
      header: 'Action',
      headerClassName: 'header-test',
      body: (rowData, { rowIndex }) => (
          <div className="flex  gap-2 justify-center items-center">
            <button className="px-2 py-1 bg-medium-aquamarine !rounded-md" onClick={() => handleOpenDialogM(rowData, rowIndex)}>
              <EditOffIcon className="text-white" />
            </button>
            <button className="px-2 py-1 bg-[#df5454] !rounded-md" onClick={() => handleDelete(rowData._id, rowIndex)}>
              <DeleteSweepIcon className="text-white" />
            </button>
          </div>
      ),
    },
  ];
  const handleInputChange = (event) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);
  };
  useEffect(() => {
    const intervalId = setTimeout(() => {
      openAiConfigItems();
    }, 1000);

    return () => {
      clearTimeout(intervalId);
    };
  }, [search]);

  const editSwitch = (key, value, index) => {
    let data = openAiConfigItem;
    data[index][key] = value;
    setOpenAiConfigItem([...data]);
    const { _id, ...item } = data[index];
    console.log(_id, item);
    updateOpenAiConfig(_id, item)
      .then(({ data }) => {
        console.log(data);
        toast.success('enble has been updated');
      })
      .catch((error) => {
        console.log(error.message, {
          type: 'error',
        });
      });
  };
  return (
    <div className="card p-4">
      <Grid>
        <Grid item xs={12}>
          <Box className="flex justify-center items-center gap-1 p-4">
            <OpenAILogo size={40} />
            <span
              style={{
                fontSize: '38px',
                fontWeight: 'bold',
              }}
            >
              OpenAI
            </span>
            <span className='text-gray-700 font-medium'>Config</span>
          </Box>
          <ToastContainer position="top-right" autoClose={3000} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
            }}
            className="mb-3"
          >
            <TextField
              variant="outlined"
              placeholder="Search..."
              autoComplete="false"
              value={search}
              onChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ width: 230 }}
            />
            <Button
              startIcon={<AddIcon />}
              sx={{
                float: 'right',
                color: 'white !important',
                margin: '10px',
                '&:hover': {
                  background: '#06bf9d',
                },
              }}
              className="!bg-medium-aquamarine"
              variant="contained"
              onClick={handleOpenDialog}
            >
              Add OpenAI Config
            </Button>
          </div>
        </Grid>
        {loading ? (
          <Grid item cl={12} style={{ margin: 'auto' }}>
            <CircularProgress size={30} />
          </Grid>
        ) : (
          <GlobalTable
            data={openAiConfigItem}
            columns={columns}
            page={page}
            onPageChange={setPage}
            isNextDisabled={loading || openAiConfigItem.length < limit}
            hasPagination={true}
            limit={limit}
            onLimitChange={setLimit}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        )}
      </Grid>

      <AddOpenAiInitConfigDialog
        open={openDialog}
        onClose={handleCloseDialog}
        addAiConfig={addAiConfig}
      />
      <ModifyOpenAiConfigDialog
        open={openDialogM}
        onClose={handleCloseDialogM}
        openAiConfigItem={openAiConfigItem}
        setOpenAiConfigItem={setOpenAiConfigItem}
        dataOpenAi={dataOpenAi}
        openAiIndex={openAiIndex}
      />
    </div>
  );
}

export default OpenAiConfig;
