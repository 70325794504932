import React, { useState, useEffect, useRef } from 'react';
import { Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel, Button, TextField, Box } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { searchListings, getListingsTa, getTaskConfigs } from '../../services/serverApi.task';
import AddTask from './AddTask';
import AddTaskButton from './AddTaskButton';
import { useLocation, useNavigate } from 'react-router-dom';

const FilterTask = ({ onFilterChange, onSaveTask, staff, initialFilters = {} }) => {
    const [filters, setFilters] = useState({
        type: [],
        subType: [],
        status: [],
        assignmentStatus: [],
        listingId: initialFilters.listingId || '',
        reservationNumber: initialFilters.reservationNumber || ''
    });
    console.log("initialFilters", initialFilters);
    const filterRef = useRef(null);
    const [showFilter, setShowFilter] = useState(false);
    const [filterHeight, setFilterHeight] = useState('0px');
    const [listingOptions, setListingOptions] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [openAddTaskModal, setOpenAddTaskModal] = useState(false);
    const [isAddTask, setIsAddTask] = useState(false);
    const [listings, setListings] = useState([]);
    const [taskTypes, setTaskTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const staging = JSON.parse(localStorage.getItem('isStaging')) || false;

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const reservationNumber = searchParams.get('reservationNumber');
        const listingId = searchParams.get('listingId');

        setFilters(prevFilters => ({
            ...prevFilters,
            reservationNumber: reservationNumber || '',
            listingId: listingId || ''
        }));
    }, [location]);

    useEffect(() => {
        if (showFilter) {
            setFilterHeight(`${filterRef.current.scrollHeight}px`);
        } else {
            setFilterHeight('0px');
        }
    }, [showFilter]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            fetchListings();
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, staging]);

    const fetchListingOptions = async () => {
        try {
            const listings = await getListingsTa(staging);
            setListings(listings);

            if (filters.listingId) {
                const selectedListing = listings.find(listing => listing._id === filters.listingId);
                if (selectedListing) {
                    setListingOptions([{
                        label: selectedListing.name,
                        value: selectedListing._id
                    }]);
                }
            }
        } catch (error) {
            console.error('Error fetching listing options:', error);
        }
    };

    const fetchTaskTypes = async () => {
        setIsLoading(true);
        try {
            const response = await getTaskConfigs();
            if (response && Array.isArray(response)) {
                setTaskTypes(response);
            } else {
                setTaskTypes([]);
            }
        } catch (error) {
            console.error('Error fetching task config:', error);
            setTaskTypes([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchTaskTypes();
        fetchListingOptions();
    }, [staging]);

    const fetchListings = async () => {
        try {
            const response = await searchListings({ name: searchTerm, limit: 20, staging });
            if (response.data) {
                setListingOptions(response.data.map(listing => ({
                    label: listing.name,
                    value: listing._id
                })));
            }
        } catch (error) {
            console.error('Error fetching listings:', error);
            setListingOptions([]);
        }
    };

    const handleFilterChange = (field, value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [field]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let searchParams = new URLSearchParams();

        if (filters.reservationNumber) {
            searchParams.set('reservationNumber', filters.reservationNumber);
        }
        if (filters.listingId) {
            searchParams.set('listingId', filters.listingId);
        }

        navigate(`/admin/Tasks?${searchParams.toString()}`, { replace: true });
        onFilterChange(filters);
        toggleFilter();
    };

    const handleClearFilters = () => {
        const clearedFilters = {
            type: [],
            subType: [],
            status: [],
            assignmentStatus: [],
            listingId: '',
            reservationNumber: ''
        };
        setFilters(clearedFilters);
        onFilterChange(clearedFilters);
        navigate('/admin/Tasks', { replace: true });
        toggleFilter();
    };;

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };



    const handleOpenAddTaskModal = () => {
        setOpenAddTaskModal(true);
        setIsAddTask(true);
    };

    const handleCloseAddTaskModal = () => {
        setOpenAddTaskModal(false);
    };

    const renderMultiSelect = (field, label, options) => {
        const handleChange = (event) => {
            const {
                target: { value },
            } = event;
            setFilters(prevFilters => ({
                ...prevFilters,
                [field]: typeof value === 'string' ? value.split(',') : value,
            }));
        };

        let selectOptions = [];
        if (field === 'type') {
            selectOptions = options.map(option => option.task);
        } else if (field === 'subType') {
            selectOptions = options.flatMap(type =>
                type.subs.map(sub => sub.type)
            );
        } else {
            selectOptions = options;
        }

        return (
            <FormControl fullWidth size="small">
                <InputLabel>{label}</InputLabel>
                <Select
                    multiple
                    value={filters[field]}
                    onChange={handleChange}
                    renderValue={(selected) => selected.join(', ')}
                    label={label}
                >
                    {selectOptions.map((option) => (
                        <MenuItem key={option} value={option}>
                            <Checkbox checked={filters[field].indexOf(option) > -1} />
                            <ListItemText primary={option} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    return (
        <>
            <div className='flex justify-between items-center px-4 py-2'>
                <div onClick={toggleFilter} style={{ cursor: 'pointer' }} className={`flex items-center p-2 rounded-md shadow-sm bg-[#66dac3]${showFilter ? 'bg-opacity-50 text-white' : 'text-gray-700'}`}>
                    <FilterListIcon fontSize="small" />
                    <span className='ml-2 text-sm font-medium'>Filters</span>
                </div>
                <div>
                    <AddTaskButton onClick={handleOpenAddTaskModal} />
                </div>
            </div>
            <div ref={filterRef}
                style={{ height: filterHeight, transition: 'height 0.3s' }}
                className="bg-white overflow-hidden rounded-md shadow-md absolute z-10 w-1/2 left-12"
            >
                <div className="p-4">
                    <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4">
                        {renderMultiSelect('type', 'Task Types', taskTypes)}
                        {renderMultiSelect('subType', 'Task Sub-Types', taskTypes)}
                        {renderMultiSelect('status', 'Task Status', ['PENDING', 'IN_PROGRESS', 'COMPLETED', 'CANCELED', 'CANCELED-BY-CUST'])}
                        {renderMultiSelect('assignmentStatus', 'Assignment Status', ['ASSIGNED', 'UNASSIGNED', 'ACCEPTED', 'REFUSED'])}
                        <FormControl fullWidth size="small">
                            <InputLabel>Select a Listing</InputLabel>
                            <Select
                                value={filters.listingId}
                                onChange={(e) => handleFilterChange('listingId', e.target.value)}
                                label="Select a Listing"
                                onOpen={() => setSearchTerm('')}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 300,
                                        },
                                    },
                                }}
                            >
                                <Box className="bg-gray-50">
                                    <TextField
                                        size="small"
                                        placeholder="Search listings..."
                                        className='w-full p-1 !rounded-md focus:outline-none'
                                        onChange={(e) => handleFilterChange('listingId', e.target.value)}
                                        InputProps={{
                                            onChange: handleSearchChange,
                                            value: searchTerm,
                                        }}
                                    />
                                </Box>
                                {listingOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth size="small">
                            <TextField
                                label="Reservation Number"
                                value={filters.reservationNumber}
                                onChange={(e) => handleFilterChange('reservationNumber', e.target.value)}
                                size="small"
                            />
                        </FormControl>

                        <div className="flex items-center justify-end col-span-2 space-x-2 gap-2">
                            <Button
                                onClick={toggleFilter}
                                variant="outlined"
                                className='border-1 !border-red-100'
                                size="small"
                            >
                                <span className='text-red-400'>Cancel</span>
                            </Button>
                            <Button
                                onClick={handleClearFilters}
                                variant="outlined"
                                style={{ borderColor: '#66dac3', color: '#66dac3' }}
                                size="small"
                            >
                                Clear Filters
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                style={{ backgroundColor: '#66dac3', color: 'white' }}
                                size="small"
                            >
                                Apply Filters
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
            {openAddTaskModal && (
                <AddTask
                    open={openAddTaskModal}
                    onClose={handleCloseAddTaskModal}
                    onSave={onSaveTask}
                    listing={listings}
                    staff={staff}
                    isAddTask={isAddTask}
                    listingId={filters.listingId}
                />
            )}
        </>
    );
};

export default FilterTask;