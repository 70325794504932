import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';


export function getChatInbox(page,limit) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/chat-inbox?page=${page}&limit=${limit}&paged=true`);
  }


export function getReservationDetails(reservationNumber) {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations/by-reservation-number/${reservationNumber}`);
}

export const getTasks = (startDate, endDate, listingIds) => {
  const params = new URLSearchParams({
    startDate,
    endDate,
    listingIds: listingIds.join(',')
  });

  return axios.get(`${MICROSERVICE_BASE_URL.SRV_TASK}/tasks/get-tasks`, { params });
};