import React, { useState, useEffect } from 'react';

const ListingDetails = ({ formik, listingOptions, handleListingChange }) => {
    const [selectedListing, setSelectedListing] = useState(null);
    
    useEffect(() => {
        if (formik.values.sojoriId) {
            const listing = listingOptions.find(l => l.id === formik.values.sojoriId);
            setSelectedListing(listing);
            
            console.log('Selected Listing Property Unit:', listing?.propertyUnit);
            console.log('Selected Listing Details:', listing);
            
            if (listing?.propertyUnit === 'Single' && listing.roomTypes?.[0]) {
                const roomTypeId = listing.roomTypes[0]._id;
                formik.setFieldValue('roomTypeId', roomTypeId);
                console.log('Single Property - Selected Room Type ID:', roomTypeId);
            }
        }
    }, [formik.values.sojoriId, listingOptions]);

    useEffect(() => {
        if (formik.values.roomTypeId && selectedListing?.propertyUnit === 'Multi') {
            console.log('Multi Property - Selected Room Type ID:', formik.values.roomTypeId);
        }
    }, [formik.values.roomTypeId, selectedListing]);

    const handleListingSelection = (e) => {
        handleListingChange(e);
        formik.setFieldValue('roomTypeId', '');
    };

    return (
        <div className="w-1/2">
            <div className="flex flex-col gap-4 my-4">
                <div className="col-span-2">
                    <div className="flex flex-col">
                        <label className="font-bold text-[#676a6c] text-sm mb-2">Listing</label>
                        <select
                            name="sojoriId"
                            onChange={handleListingSelection}
                            onBlur={formik.handleBlur}
                            value={formik.values.sojoriId}
                            className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded p-2"
                            style={{
                                backgroundColor: 'white',
                                border: '1px solid #ccc',
                                fontSize: 'small',
                            }}
                        >
                            <option value="">Select Listing...</option>
                            {listingOptions.map((listing) => (
                                <option key={listing.id} value={listing.id}>
                                    {listing.name}
                                </option>
                            ))}
                        </select>
                        {formik.touched.sojoriId && formik.errors.sojoriId && (
                            <div className="text-red-500 text-sm">{formik.errors.sojoriId}</div>
                        )}
                    </div>

                    {selectedListing?.propertyUnit === 'Multi' && (
                        <div className="flex flex-col mt-4">
                            <label className="font-bold text-[#676a6c] text-sm mb-2">Room Type</label>
                            <select
                                name="roomTypeId"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.roomTypeId || ''}
                                className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded p-2"
                                style={{
                                    backgroundColor: 'white',
                                    border: '1px solid #ccc',
                                    fontSize: 'small',
                                }}
                            >
                                <option value="">Select Room Type...</option>
                                {selectedListing?.roomTypes?.map((roomType) => (
                                    <option key={roomType._id} value={roomType._id}>
                                        {roomType.roomTypeName}
                                    </option>
                                ))}
                            </select>
                            {formik.touched.roomTypeId && formik.errors.roomTypeId && (
                                <div className="text-red-500 text-sm">{formik.errors.roomTypeId}</div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ListingDetails;