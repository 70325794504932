import React, { useState } from 'react';
import { TextField, Button, Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItem, IconButton, Switch } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ImageUpload from 'components/CustomUpload/UploadV2';
import { saveAs } from 'file-saver';
import PdfViewerModal from '../PdfViewerModal';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadingIcon from '@mui/icons-material/Downloading';

const AccessDetails = ({ formik }) => {
  const { values, setFieldValue } = formik;
  const accessDetails = values.accessDetails || {};
  const [expandedPanel, setExpandedPanel] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState('');

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  const handleAccessDetailsChange = (accessType, field, value) => {
    setFieldValue(`accessDetails.${accessType}.${field}`, value);
  };

  const handleNestedChange = (accessType, parentField, childField, value) => {
    setFieldValue(`accessDetails.${accessType}.${parentField}.${childField}`, value);
  };

  const addInstruction = (accessType) => {
    const currentInstructions = values.accessDetails[accessType].instructions || [];
    setFieldValue(`accessDetails.${accessType}.instructions`, [...currentInstructions, '']);
  };

  const removeInstruction = (accessType, index) => {
    const newInstructions = values.accessDetails[accessType].instructions.filter((_, i) => i !== index);
    setFieldValue(`accessDetails.${accessType}.instructions`, newInstructions);
  };

  const handleInstructionChange = (accessType, index, value) => {
    const newInstructions = [...values.accessDetails[accessType].instructions];
    newInstructions[index] = value;
    setFieldValue(`accessDetails.${accessType}.instructions`, newInstructions);
  };

  const downloadPDF = (url) => {
    saveAs(url, 'access_plan.pdf');
  };

  const openPdfModal = (url) => {
    setCurrentPdfUrl(url);
    setModalOpen(true);
  };

  const closePdfModal = () => {
    setModalOpen(false);
  };

  const renderGeneralAccessDetails = () => {
    const generalDetails = accessDetails.general || {};

    return (
      <Accordion
        expanded={expandedPanel === 'general'}
        onChange={handleAccordionChange('general')}
        className="mb-4"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="!text-white" />}
          className="!bg-[#6fd1bd] !text-white"
        >
          <Typography>General Access Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex flex-col gap-4 mt-4">
            <TextField
              label="Name"
              value={generalDetails.name || ''}
              onChange={(e) => handleAccessDetailsChange('general', 'name', e.target.value)}
              fullWidth
            />
            <TextField
              label="Type"
              value={generalDetails.type || ''}
              onChange={(e) => handleAccessDetailsChange('general', 'type', e.target.value)}
              fullWidth
            />
            <div>
              <Typography variant="subtitle1" className="mb-2">Instructions</Typography>
              <List>
                {generalDetails.instructions?.map((instruction, index) => (
                  <ListItem key={index} className="p-0 mb-2">
                    <TextField
                      value={instruction}
                      onChange={(e) => handleInstructionChange('general', index, e.target.value)}
                      fullWidth
                      multiline
                    />
                    <IconButton onClick={() => removeInstruction('general', index)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
              <Button
                startIcon={<AddCircleIcon />}
                onClick={() => addInstruction('general')}
                variant="outlined"
                className="mt-2 !border !border-medium-aquamarine !text-medium-aquamarine"
              >
                Add Instruction
              </Button>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderAccessTypeDetails = (accessType) => {
    const accessTypeDetails = accessDetails[accessType] || {};
    return (
      <Accordion
        expanded={expandedPanel === accessType}
        onChange={handleAccordionChange(accessType)}
        className="mb-4"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="!text-white" />}
          className="!bg-[#6fd1bd] !text-white"
        >
          <Typography>{accessType.charAt(0).toUpperCase() + accessType.slice(1)} Access Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="flex flex-col gap-4 mt-4">
            <TextField
              label="Name"
              value={accessTypeDetails.name || ''}
              onChange={(e) => handleAccessDetailsChange(accessType, 'name', e.target.value)}
              fullWidth
            />
            <TextField
              label="Type"
              value={accessTypeDetails.type || ''}
              onChange={(e) => handleAccessDetailsChange(accessType, 'type', e.target.value)}
              fullWidth
            />
            <TextField
              label="GPS Access Name"
              value={accessTypeDetails.gps_access?.name || ''}
              onChange={(e) => handleNestedChange(accessType, 'gps_access', 'name', e.target.value)}
              fullWidth
            />
            <div className="flex items-center gap-2">
              <label className="text-gray-500">Use URL for GPS Access</label>
              <Switch
                checked={accessTypeDetails.gps_access?.useUrl || false}
                onChange={(e) => handleNestedChange(accessType, 'gps_access', 'useUrl', e.target.checked)}
                color="primary"
              />
            </div>
            <TextField
              label="GPS Access URL"
              value={accessTypeDetails.gps_access?.url || ''}
              onChange={(e) => handleNestedChange(accessType, 'gps_access', 'url', e.target.value)}
              fullWidth
            />
            <TextField
              label="GPS Latitude"
              value={accessTypeDetails.gps_access?.gpsPosition?.lat || ''}
              onChange={(e) => handleNestedChange(accessType, 'gps_access', 'gpsPosition.lat', e.target.value)}
              fullWidth
            />
            <TextField
              label="GPS Longitude"
              value={accessTypeDetails.gps_access?.gpsPosition?.lng || ''}
              onChange={(e) => handleNestedChange(accessType, 'gps_access', 'gpsPosition.lng', e.target.value)}
              fullWidth
            />

            <div className="flex items-center gap-2">
              <label className="text-gray-500">Use GPS Access</label>
              <Switch
                checked={accessTypeDetails.use_gps_access || false}
                onChange={(e) => handleAccessDetailsChange(accessType, 'use_gps_access', e.target.checked)}
                color="primary"
              />
            </div>


            <TextField
              label="Access Plan Name"
              value={accessTypeDetails.access_plan?.name || ''}
              onChange={(e) => handleNestedChange(accessType, 'access_plan', 'name', e.target.value)}
              fullWidth
            />
            <div className="flex items-center gap-2">
              <label className="text-gray-500">Use URL for Access Plan</label>
              <Switch
                checked={accessTypeDetails.access_plan?.useUrl || false}
                onChange={(e) => handleNestedChange(accessType, 'access_plan', 'useUrl', e.target.checked)}
                color="primary"
              />
            </div>
            <TextField
              label="Access Plan URL"
              value={accessTypeDetails.access_plan?.url || ''}
              onChange={(e) => handleNestedChange(accessType, 'access_plan', 'url', e.target.value)}
              fullWidth
            />

            <div><label className="mb-2 text-gray-500">Access Plan:</label></div>
            <div className="my-2 flex flex-col gap-4 items-center">
              {accessTypeDetails.access_plan?.url ? (
                <div className="w-full">
                  {accessTypeDetails.access_plan.url.endsWith('.pdf') ? (
                    <div className="flex gap-4 items-center justify-center mt-4">
                      <Button
                        variant="contained"
                        className="!bg-medium-aquamarine !text-white"
                        onClick={() => openPdfModal(accessTypeDetails.access_plan.url)}
                      >
                        <RemoveRedEyeIcon />
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => downloadPDF(accessTypeDetails.access_plan.url)}
                        className="!text-gray-500 !bg-white !border !border-gray-500"
                      >
                        <DownloadingIcon />
                      </Button>
                      <Button
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        onClick={() => handleNestedChange(accessType, 'access_plan', 'url', '')}
                        className="!text-red-500 !bg-white !border !border-red-500"
                      >
                      </Button>
                    </div>
                  ) : (
                    <div className="flex flex-col gap-4 items-center justify-center">
                      <img
                        src={accessTypeDetails.access_plan.url}
                        alt="Access Image"
                        style={{ width: '300px', height: '150px', objectFit: 'cover' }}
                      />
                      <div className="flex gap-4 items-center justify-center mt-4">
                        <Button
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={() => handleNestedChange(accessType, 'access_plan', 'url', '')}
                          className="!text-red-500 !border !border-red-500"
                        >
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-col gap-4 items-center justify-center">
                  <p className="text-gray-500">No file uploaded</p>
                </div>
              )}
              <ImageUpload
                fieldName={`accessDetails.${accessType}.access_plan.url`}
                setFieldValue={setFieldValue}
                folder="listing"
                accept=".pdf,image/*"
                label="Upload Access Plan"
              />
            </div>
            <div className="flex items-center gap-2">
              <label className="text-gray-500">Use Access Plan</label>
              <Switch
                checked={accessTypeDetails.use_access_plan || false}
                onChange={(e) => handleAccessDetailsChange(accessType, 'use_access_plan', e.target.checked)}
                color="primary"
              />
            </div>
            <TextField
              label="Access Code Name"
              value={accessTypeDetails.access_code?.name || ''}
              onChange={(e) => handleNestedChange(accessType, 'access_code', 'name', e.target.value)}
              fullWidth
            />
            <TextField
              label="Access Code"
              value={accessTypeDetails.access_code?.code || ''}
              onChange={(e) => handleNestedChange(accessType, 'access_code', 'code', e.target.value)}
              fullWidth
            />
            <div className="flex items-center gap-2">
              <label className="text-gray-500">Use Access Code</label>
              <Switch
                checked={accessTypeDetails.use_access_code || false}
                onChange={(e) => handleAccessDetailsChange(accessType, 'use_access_code', e.target.checked)}
                color="primary"
              />
            </div>
            <div>
              <Typography variant="subtitle1" className="mb-2">Instructions</Typography>
              <List>
                {accessTypeDetails.instructions?.map((instruction, index) => (
                  <ListItem key={index} className="p-0 mb-2">
                    <TextField
                      value={instruction}
                      onChange={(e) => handleInstructionChange(accessType, index, e.target.value)}
                      fullWidth
                      multiline
                    />
                    <IconButton onClick={() => removeInstruction(accessType, index)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
              <Button
                startIcon={<AddCircleIcon />}
                onClick={() => addInstruction(accessType)}
                variant="outlined"
                className="mt-2 !border !border-medium-aquamarine !text-medium-aquamarine"
              >
                Add Instruction
              </Button>
            </div>
            <div className="flex items-center gap-2">
              <label className="text-gray-500">Use Instructions</label>
              <Switch
                checked={accessTypeDetails.use_instructions || false}
                onChange={(e) => handleAccessDetailsChange(accessType, 'use_instructions', e.target.checked)}
                color="primary"
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  };

  return (
    <div className="w-full p-4 h-auto">
      <Typography variant="h6" className="mb-4">Access Details</Typography>
      {renderGeneralAccessDetails()}
      {renderAccessTypeDetails('parking')}
      {renderAccessTypeDetails('building')}
      {renderAccessTypeDetails('apartment')}
      <PdfViewerModal
        open={modalOpen}
        onClose={closePdfModal}
        pdfUrl={currentPdfUrl}
      />
    </div>
  );
}

export default AccessDetails;