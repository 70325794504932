import React from 'react';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import ChannexCall from '../components/ChannexCall';

function Channex() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="Task">
        <div className="main-content">
          <main className='card'>
            <ChannexCall />
          </main>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default Channex;