import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { getMinutHomes } from 'features/listing/services/serverApi.listing';

const MinutListing = ({ formik }) => {
  const { values, errors, touched, setFieldValue, handleBlur } = formik;
  const [minutHouses, setMinutHouses] = useState([]);

  useEffect(() => {
    fetchMinutHouses();
  }, []);

  const fetchMinutHouses = async () => {
    try {
      const response = await getMinutHomes();
      setMinutHouses(response.data.listings || []);
    } catch (error) {
      console.error('Error fetching Minut homes:', error);
    }
  };

  const handleMinutHouseChange = (event) => {
    setFieldValue('listingMinutId', event.target.value);
  };

  return (
    <div className="w-full p-4 h-auto">
      <FormControl
        fullWidth
        className="mb-4"
        error={touched.listingMinutId && Boolean(errors.listingMinutId)}
      >
        <InputLabel id="minut-house-label">Select Minut House</InputLabel>
        <Select
          labelId="minut-house-label"
          id="minut-house-select"
          value={values.listingMinutId || ''}
          onChange={handleMinutHouseChange}
          onBlur={handleBlur}
          name="listingMinutId"
          label="Select Minut House"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {minutHouses.map((house) => (
            <MenuItem key={house.home_id} value={house.home_id}>
              {house.name}
            </MenuItem>
          ))}
        </Select>
        {touched.listingMinutId && errors.listingMinutId && (
          <FormHelperText>{errors.listingMinutId}</FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default MinutListing;
