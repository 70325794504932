import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';

export function getRatePlan(cityId, staging = false) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/rate-plan?cityId=${cityId}&staging=${staging}`);
}


export function getCities() {
    return axios.get(`${MICROSERVICE_BASE_URL.CITY}?page=0&limit=10&search_text&paged=false`);
}

export function getCountries() {
    return axios.get(`${MICROSERVICE_BASE_URL.COUNTRY}?page=0&limit=10&search_text&paged=false`);
}


export function updateRatePlan(ratePlanId, data) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_LISTING}/rate-plan/update/${ratePlanId}`, data);
}


export function createRatePlan(data) {
    return axios.post(`${MICROSERVICE_BASE_URL.SRV_LISTING}/rate-plan/create`, data);
}



export function getListingsWithRatePlans(params = {}) {
    const { page = 0, limit = 0, name = '', city = '', country = '', sortingBy = '', staging = false } = params;
    const queryParams = new URLSearchParams({
        page,
        limit,
        name,
        city,
        country,
        sortingBy,
        staging
    }).toString();

    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/rate-plan/listings-with-rate-plans?${queryParams}`);
}



// export function deleteRatePlan(ratePlanId) {
//     return axios.delete(`${MICROSERVICE_BASE_URL.SRV_LISTING}/rate-plan/delete/${ratePlanId}`);
// }


