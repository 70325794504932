import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createChannel, updateChannel, deleteChannel } from '../services/serverApi.adminConfig';
import { toast } from 'react-toastify';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from './ConfirmationDialog';


const ChannelDialog = ({ showDialog, onClose, channel, onChannelChange }) => {
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [channelToDelete, setChannelToDelete] = useState(null);

    const initialValues = {
        name: channel ? channel.name : '',
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            let response;
            if (channel) {
                response = await updateChannel(channel._id, { name: values.name });
                const newChannel = response.channelManager;
                onChannelChange(newChannel, false);
                toast.success('Channel updated successfully');
            } else {
                response = await createChannel({ name: values.name });
                const newChannel = response.channelManager; 
                onChannelChange(newChannel, false);
                toast.success('Channel created successfully');
            }
            onClose();
        } catch (error) {
            toast.error(error.message);
            console.error(error);
        }
        setSubmitting(false);
    };

    const handleDeleteChannel = (channel) => {
        setChannelToDelete(channel);
        setShowConfirmation(true);
    }

    const confirmDeleteChannel = async () => {
        try {
            await deleteChannel(channelToDelete._id);
            onChannelChange(channelToDelete, true);
            setShowConfirmation(false);
            toast.success('Channel deleted successfully!');
            onClose();
        } catch (error) {
            console.error('Error deleting channel:', error.message);
            toast.error('Error deleting channel');
        }
    }

    return (
        <>
            <Dialog open={showDialog} onClose={onClose} maxWidth="sm" fullWidth>
                <DialogTitle className='bg-medium-aquamarine text-white'>
                    {channel && channel._id ? 'Update Channel' : 'Create Channel'}
                </DialogTitle>
                <DialogContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                >
                    {({ isSubmitting }) => (
                        <Form>
                            <div className="px-4 pt-4">
                                <Field
                                    name="name"
                                    className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Name"
                                />
                                <ErrorMessage name="name" component="div" className="text-red-500 text-xs italic" />
                            </div>
                            <DialogActions>
                                <div className="flex justify-between w-full px-4">
                                    <div>
                                        {channel && channel._id && (
                                            <Button onClick={() => handleDeleteChannel(channel)} color="secondary" startIcon={<DeleteIcon />} className="!bg-red-500 text-white">
                                            </Button>
                                        )}
                                    </div>
                                    <div>
                                        <Button onClick={onClose} color="secondary">
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            className="!bg-medium-aquamarine text-white"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting ? 'Loading...' : channel && channel._id ? 'Update' : 'Create'}
                                        </Button>
                                    </div>
                                </div>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
                </DialogContent>
            </Dialog>
            <ConfirmationDialog
                open={showConfirmation}
                onClose={() => setShowConfirmation(false)}
                onConfirm={confirmDeleteChannel}
            />
        </>
    );
};

export default ChannelDialog;