import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import { Grid, Button, Typography } from "@mui/material";
 import CalendarTabs from "./CalendarTabs";
import SelectSearchBar from "./SearchBar";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';



const styles = {
  button: {
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '16px',
    padding: '6px 12px',
    background: '#2f9f9f',
    borderColor: '#2f9f9f',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'rgb(42 201 201)',
    },
  },
  lightButton: {
    textTransform: 'none',
    fontSize: '14px',
    lineHeight: '16px',
    padding: '6px 12px',
    background: '#e2fafa',
    borderColor: '#e2fafa',
    color: '#2f9f9f',
    '&:hover': {
      backgroundColor: '#b5e4e4',
    },
  },
};

function CalendarBarListing() {
  const listingId = useSelector((state) => state.yearDateDta?.ListingId);
  const btnIdLsting = useSelector((state) => state.yearDateDta?.idListingBtn);
  return (

        <Grid container item sx={{marginBottom: 2,background:"#fff",padding:'15px'}}>
          <Grid item xs={12} sm={1.1} sx={{
              display: "flex", 
              alignItems: "center",
            }}
          >
            <Typography sx={{
              margin: 0,
              marginLeft: "15px",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "24px",
              lineHeight: "28px",
              color: "#091e42",
            }}> Calendar</Typography>
          </Grid>
          <Grid item xs={12} sm={5.5} sx={{
              display: "flex", 
              alignItems: "center",
            }}
          >
          <CalendarTabs numn={1} />
          </Grid>
          <Grid item itemxs={12} sm={5.4} sx={{
              display: "flex", 
              alignItems: "center",
              padding: "0 20px"
            }}
          >
            <Grid container item sx={{
              justifyContent:'end'
            }}>
              <Grid item xs={12} sm={5}>
                  <SelectSearchBar /> 
              </Grid>
              <Grid item xs={12} sm={'auto'} sx={{padding: "0 5px"}}>
              <Link to={`/admin/listing/${btnIdLsting || listingId?.slice(0,6)}`} style={{ textDecoration: 'none' }}>
                <Button variant="contained" sx={styles.lightButton}>
                    Listing
                </Button>
                </Link>
              </Grid>
              <Grid item xs={12} sm={'auto'} sx={{padding: "0 5px"}}>
              <Link to={`/admin/Reservation/create`} style={{ textDecoration: 'none' }}>

                <Button variant="contained" style={{ background: 'rgb(42 201 201)', color: 'white', padding: '11px',marginRight:'2px' }}> 
                    Direct booking
                </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
       
  
  );
}




export default CalendarBarListing;

