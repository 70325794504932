  import moment from 'moment';
  import { cache } from './cache';
  
  export const isRangeCached = (start, end) => {
    return cache.fetchedRanges.some(range => 
      moment(start).isSameOrAfter(range.start) && 
      moment(end).isSameOrBefore(range.end)
    );
  };
  
  export const mergeRanges = (ranges) => {
    if (ranges.length <= 1) return ranges;
    
    ranges.sort((a, b) => moment(a.start).diff(moment(b.start)));
    
    const mergedRanges = [ranges[0]];
    
    for (const range of ranges.slice(1)) {
      const lastRange = mergedRanges[mergedRanges.length - 1];
      if (moment(range.start).isSameOrBefore(moment(lastRange.end))) {
        lastRange.end = moment.max(moment(lastRange.end), moment(range.end)).toDate();
      } else {
        mergedRanges.push(range);
      }
    }
    
    return mergedRanges;
  };
  