import React, { useState } from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel, Button, Grid } from '@mui/material';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';

const TicketFilter = ({ cities, onFilterApply, initialFilters = {} }) => {
  const [filters, setFilters] = useState({
    status: initialFilters.status || '',
    acceptance: initialFilters.acceptance || '',
    cityId: initialFilters.cityId || '',
    code: initialFilters.code || '',
  });

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleApplyFilters = () => {
    onFilterApply(filters);
  };

  const handleResetFilters = () => {
    const resetFilters = {
      status: '',
      acceptance: '',
      cityId: '',
      code: '',
    };
    setFilters(resetFilters);
    onFilterApply(resetFilters);
  };

  return (
    <div className="w-full flex flex-col gap-4 mb-4 sm:flex-row sm:flex-wrap sm:items-center">

      <div className="w-full sm:w-auto">
        <TextField
          label="Ticket Code"
          name="code"
          value={filters.code}
          onChange={handleFilterChange}
          variant="outlined"
          className="w-full sm:w-52"
        />
      </div>

      <div className="w-full sm:w-auto">
        <FormControl className="w-full sm:w-52" variant="outlined">
          <InputLabel>Status</InputLabel>
          <Select
            name="status"
            value={filters.status}
            label="Status"
            onChange={handleFilterChange}
          >
            <MenuItem value="">All Statuses</MenuItem>
            <MenuItem value="Created">Created</MenuItem>
            <MenuItem value="InProgress">In Progress</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="w-full sm:w-auto">
        <FormControl className="w-full sm:w-52" variant="outlined">
          <InputLabel>Acceptance</InputLabel>
          <Select
            name="acceptance"
            value={filters.acceptance}
            label="Acceptance"
            onChange={handleFilterChange}
          >
            <MenuItem value="">All Acceptances</MenuItem>
            <MenuItem value="Accepted">Accepted</MenuItem>
            <MenuItem value="Rejected">Rejected</MenuItem>
            <MenuItem value="Not processed">Not Processed</MenuItem>
          </Select>
        </FormControl>
      </div>

      <div className="w-full sm:w-auto">
        <FormControl className="w-full sm:w-52" variant="outlined">
          <InputLabel>City</InputLabel>
          <Select
            name="cityId"
            value={filters.cityId}
            label="City"
            onChange={handleFilterChange}
          >
            <MenuItem value="">All Cities</MenuItem>
            {cities.map((city) => (
              <MenuItem key={city._id} value={city._id}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="w-full sm:w-auto">
        <div className="flex gap-2 w-full">
          {/* <Button
            variant="contained"
            color="primary"
            onClick={handleApplyFilters}
            className="w-full !bg-medium-aquamarine"
          >
            Apply Filters
          </Button> */}
          <Button
            onClick={handleApplyFilters}
            className="!p-button-text !bg-medium-aquamarine !p-1 !shadow-md !rounded !justify-center"
          >
            <SavedSearchIcon className="!text-white !h-7 !w-7" />
          </Button>
          <Button onClick={handleResetFilters} className="!p-button-text !bg-red-400 !p-1 !shadow-md !rounded !justify-center">
            <RotateLeftIcon className="!text-white !h-7 !w-7" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TicketFilter;