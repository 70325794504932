import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import { createUnit, updateUnit, getProjects } from '../services/serverApi.ProjectUnits';
import { toast } from 'react-toastify';
import BasicInformation from './unitModalInfo/BasicInformation';
import LocationInformation from './unitModalInfo/LocationInformation';
import PropertyFeatures from './unitModalInfo/PropertyFeatures';
import PriceInformation from './unitModalInfo/PriceInformation';
import ImageUploadSection from './imageUpload/ImageUploadSection';
import validationSchema from './unitModalInfo/validationSchema';


const PROPERTY_TYPES = ['Villa', 'Appartement', 'Studio'];
const CONDITIONS = ['Neuf', 'Bon état', 'À rénover'];
const SWIMMING_POOL_TYPES = ['Aucune', 'Privée', 'Commune'];

const UnitModal = ({ open, onClose, setUnits, unit }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [projects, setProjects] = useState([]);


    useEffect(() => {
        fetchProjects();
    }, []);

    const fetchProjects = async () => {
        setIsLoading(true);
        try {
            const response = await getProjects({ page: 0, limit: 100 });

            if (response?.data?.projects && Array.isArray(response.data.projects)) {
                setProjects(response.data.projects);
            } else {
                console.error('Invalid projects data format:', response);
                throw new Error('Invalid response format');
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
            setErrorMessage('Failed to load projects. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        setIsLoading(true);
        setErrorMessage('');

        try {
            let response;
            if (unit) {
                response = await updateUnit(unit._id, values);
            } else {
                response = await createUnit(values);
            }

            const responseData = response.data;

            if (responseData && responseData.success) {
                const normalizedUnit = {
                    ...responseData.unit,
                    id: responseData.unit.id || responseData.unit._id,
                    _id: responseData.unit._id || responseData.unit.id,
                    images: responseData.unit.images.map(img => ({
                        ...img,
                        _id: img._id || img.id,
                        url: img.url,
                        sortOrder: img.sortOrder
                    }))
                };

                setUnits(prevUnits => {
                    if (!prevUnits) {
                        return [normalizedUnit];
                    }

                    if (unit) {
                        return prevUnits.map(u =>
                            (u.id === normalizedUnit.id || u._id === normalizedUnit._id)
                                ? normalizedUnit
                                : u
                        );
                    } else {
                        return [normalizedUnit, ...prevUnits];
                    }
                });

                toast.success(responseData.message || (unit ? 'Unit updated successfully' : 'Unit created successfully'));
                onClose();
            } else {
                throw new Error(responseData?.message || 'Failed to process unit');
            }
        } catch (error) {
            console.error('Error:', error);
            // setErrorMessage(error.message || 'An error occurred while processing your request.');
            toast.error(error.message || (unit ? 'Error updating unit' : 'Error creating unit'));
        } finally {
            setIsLoading(false);
            setSubmitting(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{unit ? 'Update Unit' : 'Create New Unit'}</DialogTitle>
            <DialogContent>
                {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                <Formik
                    initialValues={{
                        unitName: unit?.unitName || '',
                        propertyType: unit?.propertyType || PROPERTY_TYPES[0],
                        condition: unit?.condition || CONDITIONS[0],
                        totalArea: unit?.totalArea || 0,
                        livingArea: unit?.livingArea || 0,
                        city: unit?.city || '',
                        district: unit?.district || '',
                        address: unit?.address || '',
                        projectId: unit?.projectId || '',
                        gps: unit?.gps || { latitude: 0, longitude: 0 },
                        numberOfRooms: unit?.numberOfRooms || 0,
                        numberOfBedrooms: unit?.numberOfBedrooms || 0,
                        numberOfBathrooms: unit?.numberOfBathrooms || 0,
                        salePrice: unit?.salePrice || 0,
                        pricePerSqm: unit?.pricePerSqm || 0,
                        gatedCommunity: unit?.gatedCommunity || false,
                        security: unit?.security || false,
                        swimmingPool: unit?.swimmingPool || SWIMMING_POOL_TYPES[0],
                        numberOfFloors: unit?.numberOfFloors || 0,
                        floor: unit?.floor || 0,
                        elevator: unit?.elevator || false,
                        balcony: unit?.balcony || false,
                        balconyArea: unit?.balconyArea || 0,
                        landArea: unit?.landArea || 0,
                        garage: unit?.garage || false,
                        parkingSpaces: unit?.parkingSpaces || 0,
                        garden: unit?.garden || 0,
                        images: unit?.images || []
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}

                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form className="mt-2">
                            <BasicInformation touched={touched} errors={errors} projects={projects} isLoading={isLoading} />
                            <LocationInformation touched={touched} errors={errors} />
                            <PropertyFeatures values={values} touched={touched} errors={errors} setFieldValue={setFieldValue} />
                            <PriceInformation touched={touched} errors={errors} />
                            <ImageUploadSection values={values} touched={touched} errors={errors} setFieldValue={setFieldValue} />

                            <DialogActions>
                                <Button onClick={onClose} className="!text-gray-500">Cancel</Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="!bg-medium-aquamarine !text-white"
                                    disabled={isLoading}
                                >
                                    {unit ? 'Update Unit' : 'Create Unit'}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default UnitModal;
