import styled from 'styled-components';

const StyledCard = styled.div`


  .p-column-title {
    color: #6b778c;
    margin-right: 4px;
    font-size: 13px;
  }

  .p-sortable-column-icon {
    color: white;
  }

  .dialog-footer {
    display: flex;
    justify-content: space-between;
  }

  .input-field {
    width: 100%;
  }

  .p-button-label {
    font-weight: 200;
    color: white;
  }

  .p-dropdown-label {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .p-inputwrapper-focus {
    border-color: #66cdaa;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem white;
    border-color: #06b6d4;
  }
  

`;

export default StyledCard;
