import React from 'react';
import { Tooltip } from '@mui/material';

const DynamicListingName = ({ listingName }) => {
  if (!listingName) {
    return (
      <div className="w-32 flex items-center overflow-hidden justify-start">
        <span className="text-xs font-light text-gray-400">
          No listing name
        </span>
      </div>
    );
  }

  const isShort = listingName.length <= 20;

  return (
    <Tooltip title={listingName} placement="right" >
      <div className={`w-32 flex items-center overflow-hidden ${isShort ? 'justify-start' : ''}`}>
        <span className={`text-xs font-light ${isShort ? '' : 'line-clamp-2'}`}>
          {listingName}
        </span>
      </div>
    </Tooltip>
  );
};

export default DynamicListingName;