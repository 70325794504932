import React, { useState } from 'react';
import {
    Grid, TextField, FormControl, Select, MenuItem, InputLabel,
    IconButton, InputAdornment, Button, Typography
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import useTheme from '@mui/material/styles/useTheme';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const styles = {
    filterGrid: {
        padding: 0.5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 1.5
    },
    optionStyle: {
        padding: 1.5,
        borderRadius: 0,
        '&:hover': { backgroundColor: 'lightblue' }
    },
    clearButton: {
        padding: "0px 40px",
        textTransform: "none",
        color: '#ffff',
        fontSize: "1rem",
        background: '#00b4b4',
        '&:hover': { backgroundColor: '#66cdaa' }
    }
};

const FilterListing = ({ limit, setLimit, filters, setFilters, countries, cities, fetchListings }) => {
    const theme = useTheme();
    const [unit, setUnit] = useState('All');

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'limit') {
            setLimit(value);
        } else {
            setFilters(prevState => ({ ...prevState, [name]: value }));
        }
    };

    const handleClear = () => {
        setFilters({
            name: '',
            country: '',
            city: '',
            sortingBy: '',
        });
        setLimit(10);
        setUnit('All');
    };

    return (
        <FormControl fullWidth>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={2} md={3} sm={6} sx={styles.filterGrid}>
                    <TextField
                        variant="outlined"
                        placeholder="Search"
                        name="name"
                        value={filters.name}
                        onChange={handleChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ fontSize: "25px !important", marginRight: -2 }} />
                                </InputAdornment>
                            ),
                            endAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        sx={{
                                            backgroundColor: '#1A73E8', borderRadius: "5px !important",
                                            background: 'rgb(0, 180, 180)', marginRight: "-20px",
                                            ':hover': { backgroundColor: '#5ba2ff' },
                                        }}
                                    >
                                        <SearchIcon style={{ fill: "white" }} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} lg={2} md={3} sm={6} sx={styles.filterGrid}>
                    <FormControl fullWidth>
                        <InputLabel id="filter-country-label">Country</InputLabel>
                        <Select
                            labelId="filter-country-label"
                            id="filter-country"
                            name="country"
                            value={filters.country}
                            onChange={handleChange}
                            MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8 } } }}
                        >
                            {countries.map(country => (
                                <MenuItem key={country._id} value={country.name} sx={styles.optionStyle}>{country.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={2} md={3} sm={6} sx={styles.filterGrid}>
                    <FormControl fullWidth>
                        <InputLabel id="filter-city-label">City</InputLabel>
                        <Select
                            labelId="filter-city-label"
                            id="filter-city"
                            name="city"
                            value={filters.city}
                            onChange={handleChange}
                            MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8 } } }}
                        >
                            {cities.map(city => (
                                <MenuItem key={city._id} value={city.name} sx={styles.optionStyle}>{city.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={1} md={3} sm={6} sx={styles.filterGrid}>
                    <FormControl fullWidth>
                        <Select
                            displayEmpty
                            value={unit}
                            onChange={(e) => setUnit(e.target.value)}
                        >
                            <MenuItem sx={styles.optionStyle} value={"All"}>All</MenuItem>
                            <MenuItem sx={styles.optionStyle} value={"Single units"}>Single units</MenuItem>
                            <MenuItem sx={styles.optionStyle} value={"Multi units"}>Multi units</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={2} md={3} sm={6} sx={styles.filterGrid}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-multiple-chip-label">Sort by</InputLabel>
                        <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            label="Sort by"
                            value={filters.sortingBy}
                            onChange={handleChange}
                            name="sortingBy"
                        >
                            <MenuItem value="ListingNameAsc" sx={styles.optionStyle}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={10} zeroMinWidth>
                                        <Typography
                                            dangerouslySetInnerHTML={{ __html: 'Listing name<br>' }}
                                            style={{ overflowWrap: 'break-word', fontSize: 13 }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                backgroundColor: "green",
                                                padding: "0px 3px",
                                                borderRadius: 2,
                                                overflowWrap: 'break-word',
                                                fontSize: 10,
                                                color: "white"
                                            }}
                                        >asc</Typography>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem value="ListingNameDecs" sx={styles.optionStyle}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={10} zeroMinWidth>
                                        <Typography
                                            dangerouslySetInnerHTML={{ __html: 'Listing name<br>' }}
                                            style={{ overflowWrap: 'break-word', fontSize: 13 }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                backgroundColor: "blue",
                                                padding: "0px 3px",
                                                borderRadius: 2,
                                                overflowWrap: 'break-word',
                                                fontSize: 10,
                                                color: "white"
                                            }}
                                        >desc</Typography>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem value="ListingNicknameAsc" sx={styles.optionStyle}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={10} zeroMinWidth>
                                        <Typography
                                            dangerouslySetInnerHTML={{ __html: 'Listing nickname<br>' }}
                                            style={{ overflowWrap: 'break-word', fontSize: 13 }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                backgroundColor: "green",
                                                padding: "0px 3px",
                                                borderRadius: 2,
                                                overflowWrap: 'break-word',
                                                fontSize: 10,
                                                color: "white"
                                            }}
                                        >asc</Typography>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem value="ListingNicknameDecs" sx={styles.optionStyle}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={10} zeroMinWidth>
                                        <Typography
                                            dangerouslySetInnerHTML={{ __html: 'Listing nickname<br>' }}
                                            style={{ overflowWrap: 'break-word', fontSize: 13 }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                backgroundColor: "blue",
                                                padding: "0px 3px",
                                                borderRadius: 2,
                                                overflowWrap: 'break-word',
                                                fontSize: 10,
                                                color: "white"
                                            }}
                                        >desc</Typography>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem value="ListingOrderAsc" sx={styles.optionStyle}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={10} zeroMinWidth>
                                        <Typography
                                            dangerouslySetInnerHTML={{ __html: 'Listing order<br>' }}
                                            style={{ overflowWrap: 'break-word', fontSize: 13 }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                backgroundColor: "green",
                                                padding: "0px 3px",
                                                borderRadius: 2,
                                                overflowWrap: 'break-word',
                                                fontSize: 10,
                                                color: "white"
                                            }}
                                        >asc</Typography>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                            <MenuItem value="ListingOrderDecs" sx={styles.optionStyle}>
                                <Grid container wrap="nowrap">
                                    <Grid item xs={10} zeroMinWidth>
                                        <Typography
                                            dangerouslySetInnerHTML={{ __html: 'Listing order<br>' }}
                                            style={{ overflowWrap: 'break-word', fontSize: 13 }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography
                                            style={{
                                                backgroundColor: "blue",
                                                padding: "0px 3px",
                                                borderRadius: 2,
                                                overflowWrap: 'break-word',
                                                fontSize: 10,
                                                color: "white"
                                            }}
                                        >desc</Typography>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={1} md={3} sm={6} sx={styles.filterGrid}>
                    <FormControl fullWidth>
                        <InputLabel id="filter-limit-label">Limit</InputLabel>
                        <Select
                            labelId="filter-limit-label"
                            displayEmpty
                            name="limit"
                            value={limit}
                            onChange={handleChange}
                            MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8 } } }}
                        >
                            <MenuItem value={10} sx={styles.optionStyle}>10</MenuItem>
                            <MenuItem value={20} sx={styles.optionStyle}>20</MenuItem>
                            <MenuItem value={50} sx={styles.optionStyle}>50</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={2} md={3} sm={6} sx={styles.filterGrid}>
                    <Button variant="contained" sx={styles.clearButton} onClick={handleClear}>
                        <DeleteOutlineIcon className="!text-[22px]" sx={{ marginRight: theme.spacing(1) }} />
                        <span className="!font-small">Clear</span>
                    </Button>
                </Grid>
            </Grid>
        </FormControl>
    );
};

export default FilterListing;
