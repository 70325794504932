import React, { useState, useEffect } from 'react';
import { getProjects, deleteProject, getProjectById } from '../services/serverApi.ProjectUnits';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import ProjectModal from '../components/ProjectModal';
import { ToastContainer, toast } from 'react-toastify';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { CircularProgress, Typography, Button } from '@mui/material';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import ImageGalleryTrigger from 'components/ImageGalleryModal/ImageGalleryModal';
import { useParams } from 'react-router-dom';
import DeleteConfirmationModal from '../components/DeleteConfirmationModal';

function Projects() {
    const { projectId } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [projects, setProjects] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [projectToDelete, setProjectToDelete] = useState(null);


    useEffect(() => {
        fetchProjects();
    }, [page, limit, projectId]);

    const fetchProjects = async () => {
        try {
            let response;
            if (projectId) {
                response = await getProjectById(projectId);
                setProjects([response.data.project]);
            } else {
                response = await getProjects({ page, limit });
                setProjects(response.data.projects);
            }
        } catch (error) {
            console.error('Error fetching projects:', error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleOpenModal = (project = null) => {
        setSelectedProject(project);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedProject(null);
        setModalOpen(false);
    };

    const handleDeleteProject = (project) => {
        setProjectToDelete(project);
        setDeleteModalOpen(true);
    };

    const handleConfirmDelete = async (deleteUnits) => {
        try {
            await deleteProject(projectToDelete.id, deleteUnits);
            setProjects(prevProjects => prevProjects.filter(project => project.id !== projectToDelete.id));
            toast.success('Project deleted successfully');
        } catch (error) {
            console.error('Error deleting project:', error);
            toast.error('Failed to delete project');
        } finally {
            setDeleteModalOpen(false);
            setProjectToDelete(null);
        }
    };


    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (newLimit) => {
        setLimit(newLimit);
        setPage(0);
    };

    const columns = [
        {
            header: "Images",
            body: (rowData) => (
                <ImageGalleryTrigger
                    images={rowData.images}
                />
            )

        },
        { header: "Project Name", body: (rowData) => <span>{rowData.projectName}</span> },
        { header: "Unit Type", body: (rowData) => <span>{rowData.unitType}</span> },
        { header: "Project Type", body: (rowData) => <span>{rowData.projectType}</span> },
        { header: "Status", body: (rowData) => <span>{rowData.status}</span> },
        {
            header: "Location",
            body: (rowData) => (
                <div>
                    <div><strong>City:</strong> {rowData.city}</div>
                    <div><strong>District:</strong> {rowData.district}</div>
                    <div><strong>Address:</strong> {rowData.address}</div>
                </div>
            )
        },
        {
            header: "Units",
            body: (rowData) => (
                <div>
                    <div><strong>Total:</strong> {rowData.totalUnits}</div>
                    <div><strong>Sold:</strong> {rowData.soldUnits}</div>
                    <div><strong>Remaining:</strong> {rowData.remainingUnits}</div>
                </div>
            )
        },
        {
            header: "Starting Price",
            body: (rowData) => <span>{rowData.startingPrice.toLocaleString()}</span>
        },
        {
            header: "Action",
            body: (rowData) => (
                <div className="flex gap-1">
                    <button
                        className="px-2 py-1 bg-medium-aquamarine !rounded-md"
                        onClick={() => handleOpenModal(rowData)}
                    >
                        <EditOffIcon className="text-white" />
                    </button>
                    <button
                        className="px-2 py-1 bg-[#df5454] !rounded-md"
                        onClick={() => handleDeleteProject(rowData)}
                    >
                        <DeleteSweepIcon className="text-white" />
                    </button>
                </div>
            ),
        },
    ];

    if (error) {
        return <div className="w-full h-64 flex justify-center items-center text-red-500">{error}</div>;
    }

    return (
        <DashboardLayout>
            <ToastContainer />
            <DashboardNavbar />
            <div className="card p-4">
                <Typography variant="h4" component="h1" className="mb-4" gutterBottom>
                    Projects Management
                </Typography>
                <div className="mb-4">
                    <Button
                        onClick={() => handleOpenModal()}
                        className="float-right !bg-medium-aquamarine text-white"
                    >
                        Create New Project
                    </Button>
                </div>
                <div>
                    <div className="w-full">
                        {isLoading ? (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <CircularProgress style={{ color: '#00b4b4' }} />
                            </div>
                        ) : (
                            <GlobalTable
                                data={projects}
                                columns={columns}
                                hasPagination={true}
                                page={page}
                                onPageChange={handlePageChange}
                                limit={limit}
                                onLimitChange={handleLimitChange}
                                isNextDisabled={page >= Math.ceil(total / limit) - 1}
                                rowsPerPageOptions={[10, 20, 50]}
                            />
                        )}
                    </div>
                </div>
                <ProjectModal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    setProjects={setProjects}
                    project={selectedProject}
                />
                <DeleteConfirmationModal
                    open={deleteModalOpen}
                    onClose={() => {
                        setDeleteModalOpen(false);
                        setProjectToDelete(null);
                    }}
                    onConfirm={handleConfirmDelete}
                    projectName={projectToDelete?.projectName}
                />
            </div>
        </DashboardLayout>
    );
}

export default Projects;