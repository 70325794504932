import React, { useMemo,useEffect,useState } from "react";
import { DateLocalizer, Navigate } from "react-big-calendar";
import Calendar from "react-calendar";
import { Grid } from "@mui/material";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import MDBox from "components/MDBox";
import {useDispatch} from 'react-redux';
import {setYearDate} from '../../../redux/slices/DateSlice';
import { useSelector } from 'react-redux';
import { getCalnedarById } from '../services/serverApi.calendar';

export default function YearView({
  date,
  localizer,
  onView,
  onNavigate,
}) {
  const dispatch = useDispatch();
  const listingId = useSelector((state) => state.yearDateDta?.ListingId);
  const slotSelectDate = useSelector((state) => state.yearDateDta?.slotDate);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [calenListingId, setCalenListingId] = useState();
  
  useEffect(() => {
    if (listingId) {
      setCalenListingId(listingId)
    }
  }, [listingId]);


  const currRange = useMemo(() => YearView.range(date, { localizer }), [
    date,
    localizer,
  ]);
  const getCalendarEvents = async () => {
  const givenYear = moment().format('YYYY'); 
  const startDate = moment(givenYear).startOf('year').format('YYYY-MM-DD'); 
  const endDate = moment(givenYear).endOf('year').format('YYYY-MM-DD');
  if(calenListingId){
    try {
      const result = await getCalnedarById(calenListingId,startDate, endDate);
      const eventsData = result.data.data;
      const seenReservationIds = new Set();
      const transformedEvents = eventsData.flatMap(event =>
        event.reservations
          .filter(reservation => {
            if (seenReservationIds.has(reservation.reservationId)) {
              return false;
            } else {
              seenReservationIds.add(reservation.reservationId);
              return true;
            }
          })
          .map(reservation => ({
            ...event,
            ...reservation,
          }))
      );

      setEvents(transformedEvents);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }
  }
  useEffect(()=>{
    getCalendarEvents();
  },[calenListingId])
  const renderLink = (day) => {
    onView('month')
    dispatch(setYearDate(moment(day).format('YYYY-MM-DD')));
    // const year = moment(new Date()).format('yyyy')
  };
  return (
    <MDBox py={3}>
      <Grid container style={{ padding: '0 91px', background: 'white' }}>
        {currRange.map((month, index) => (
          <Grid item lg={3} key={index} style={{ height: '312px'}}>
            <Calendar
              activeStartDate={month}
              locale="en-US"
              showNeighboringMonth={false}
              tileClassName={({ date, view }) => {
                if (
                  view === "month" &&
                  events?.find((event) =>
                    moment(event.arrivalDate).isSameOrBefore(moment(date), "day") &&
                    moment(event.departureDate).isSameOrAfter(moment(date), "day")
                  )
                )
                  return "event-day";
                return null;
              }}
              onClickDay={()=>renderLink(month)}
          
        
            />
          </Grid>
        ))}
      </Grid>
    </MDBox>
  );
}

YearView.range = (date, { localizer }) => {
  const start = localizer.startOf(date, "year");
  const end = localizer.endOf(date, "year");

  const range = [];
  let current = start;

  while (localizer.lte(current, end, "year")) {
    range.push(current);
    current = localizer.add(current, 1, "month");
  }

  return range;
};

YearView.navigate = (date, action, { localizer }) => {
  if (action instanceof Date) return action;

  switch (action) {
    case Navigate.NEXT:
      return localizer.add(date, 1, "year");
    case Navigate.PREVIOUS:
      return localizer.add(date, -1, "year");
    default:
      return date;
  }
};

YearView.title = (date, { localizer }) => {
  return localizer.format(date, "YYYY");
};
