import React from 'react';

export const CalendarHeader = ({
    goToNextPeriod,
    goToPreviousPeriod,
    setViewType,
    viewType,
    goToToday,
    formattedDateRange,
    scrollBackward,
    scrollForward,
    setCurrentDate,
    changeMonthView
}) => {



    const handleNavigateMonth = (direction) => {
        changeMonthView(direction);
    };



    const handleNavigateDay = (direction) => {
        if (direction === 'next') {
            setCurrentDate(prevDate => {
                const newDate = new Date(prevDate);
                newDate.setDate(newDate.getDate() + 1);
                return newDate;
            });
        } else {
            setCurrentDate(prevDate => {
                const newDate = new Date(prevDate);
                newDate.setDate(newDate.getDate() - 1);
                return newDate;
            });
        }
    };


    return (
        <div>
            <div className="grid grid-cols-3 gap-3 p-4 bg-gray-100 border-b border-gray-300">
                <div className="flex">
                    {/* <button className="px-3 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs !rounded-l-md">Full Screen</button> */}
                    {/* <button onClick={scrollBackward} className="px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center"><i className="pi pi-angle-left"></i><span>Scroll</span></button>
                <button onClick={scrollForward} disabled={!canScrollForward} className={`px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center ${!canScrollForward ? 'opacity-50 cursor-not-allowed' : ''}`}><i className="pi pi-angle-right"></i><span>Scroll</span></button> */}
                    <button onClick={() => handleNavigateDay('previous')} className="px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center"><i className="pi pi-angle-left"></i><span>Dy</span></button>
                    <button onClick={scrollBackward} className="px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center"><i className="pi pi-angle-left"></i><span>Wk</span></button>
                    <button onClick={() => handleNavigateMonth('previous')} className="px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center"><i className="pi pi-angle-left"></i><span>Mo</span></button>
                    <button onClick={goToToday} className="px-3 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs">Now</button>
                    <button onClick={() => handleNavigateMonth('next')} className="px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center"><i className="pi pi-angle-right"></i><span>Mo</span></button>
                    <button onClick={scrollForward} className="px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center"><i className="pi pi-angle-right"></i><span>Wk</span></button>
                    <button onClick={() => handleNavigateDay('next')} className="px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center !rounded-r-md"><i className="pi pi-angle-right"></i><span>Dy</span></button>
                </div>
                <div className='text-center'>
                    <span className="!text-sm font-semibold">{formattedDateRange}</span>
                </div>
                <div className="flex">
                    {/* <button onClick={goToToday} className="px-3 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs !rounded-l-md">Today</button> */}
                    <button onClick={goToPreviousPeriod} className="px-3 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs"><i className="pi pi-angle-left"></i></button>
                    <button onClick={goToNextPeriod} className="px-3 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs"><i className="pi pi-angle-right"></i></button>
                    <button onClick={() => setViewType('10 Day')} className={`px-3 !py-[2px] border border-gray-300 hover:bg-gray-200 !text-xs ${viewType === '10 Day' ? 'bg-gray-600 text-white' : 'bg-white'}`}>10 Day</button>
                    <button onClick={() => setViewType('14 Day')} className={`px-3 !py-[2px] border border-gray-300 hover:bg-gray-200 !text-xs ${viewType === '14 Day' ? 'bg-gray-600 text-white' : 'bg-white'}`}>14 Day</button>
                    <button onClick={() => setViewType('20 Day')} className={`px-3 !py-[2px] border border-gray-600 hover:bg-gray-200 !text-xs ${viewType === '20 Day' ? 'bg-gray-600 text-white' : 'bg-white'}`}>20 Day</button>
                    <button onClick={() => setViewType('30 Day')} className={`px-3 !py-[2px] border border-gray-600 hover:bg-gray-200 !text-xs ${viewType === '30 Day' ? 'bg-gray-600 text-white' : 'bg-white'}`}>30 Day</button>
                    <button onClick={() => setViewType('Month')} disabled={viewType} className={`px-3 !py-[2px] border border-gray-600 hover:bg-gray-200 !text-xs !rounded-r-md cursor-no-drop ${viewType === 'Month' ? 'bg-gray-600 text-white' : 'bg-gray-50 text-gray-600'}`}>Month</button>
                </div>
            </div>
        </div>
    );
};