import { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';

export const useCalendar = (initialDate = new Date()) => {
    const [currentDate, setCurrentDate] = useState(initialDate);
    const [viewType, setViewType] = useState('30 Day');
    const [daysInView, setDaysInView] = useState(30);

    const allDays = useMemo(() => {
        const today = moment();
        const startDate = today.clone().subtract(3, 'months').startOf('day');
        const endDate = today.clone().add(9, 'months').endOf('day');
        const days = [];
        let currentDay = startDate.clone();
    
        while (currentDay.isSameOrBefore(endDate)) {
            days.push(currentDay.toDate());
            currentDay.add(1, 'day');
        }
        return days;
    }, []);
    

    useEffect(() => {
        switch (viewType) {
            case '10 Day':
                setDaysInView(10);
                break;
            case '14 Day':
                setDaysInView(14);
                break;
            case '20 Day':
                setDaysInView(20);
                break;
            case '30 Day':
                setDaysInView(30);
                break;
            case 'Month':
                setDaysInView(getDaysInMonth(currentDate));
                break;
            default:
                setDaysInView(30);
        }
    }, [viewType, currentDate]);

    const getDaysInMonth = (date) => {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    };

    const getDaysInView = useCallback(() => {
        const days = [];
        const startDate = new Date(currentDate);

        for (let i = 0; i < daysInView; i++) {
            const day = new Date(startDate);
            day.setDate(startDate.getDate() + i);
            days.push(day);
        }
        return days;
    }, [currentDate, daysInView]);

    const goToNextPeriod = useCallback(() => {
        setCurrentDate((date) => moment(date).add(30, 'days').toDate());
    }, [setCurrentDate]);
    
    const goToPreviousPeriod = useCallback(() => {
        setCurrentDate((date) => moment(date).subtract(30, 'days').toDate());
    }, [setCurrentDate]);
    

    const goToToday = useCallback(() => {
        setCurrentDate(new Date());
    }, []);

    return {
        currentDate,
        setCurrentDate,
        viewType,
        setViewType,
        goToNextPeriod,
        goToPreviousPeriod,
        goToToday,
        daysInView: getDaysInView(),
        allDays
    };
};