const handleQuickFilter = (quickFilterValue, setDateType, setStartDate, setEndDate, setPage) => {
    let newDateType = '';
    let newStartDate = null;
    let newEndDate = null;
  
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const nextWeek = new Date(today);
    nextWeek.setDate(today.getDate() + 7);
    const lastWeek = new Date(today);
    lastWeek.setDate(today.getDate() - 7);
  
    switch (quickFilterValue) {
      case 'arrival':
        newDateType = 'arrival';
        newStartDate = today;
        newEndDate = today;
        break;
      case 'departure':
        newDateType = 'departure';
        newStartDate = today;
        newEndDate = today;
        break;
      case 'tomorrow_arrival':
        newDateType = 'arrival';
        newStartDate = tomorrow;
        newEndDate = tomorrow;
        break;
      case 'tomorrow_departure':
        newDateType = 'departure';
        newStartDate = tomorrow;
        newEndDate = tomorrow;
        break;
      case 'next_week_arrival':
        newDateType = 'arrival';
        newStartDate = today;
        newEndDate = nextWeek;
        break;
      case 'next_week_departure':
        newDateType = 'departure';
        newStartDate = today;
        newEndDate = nextWeek;
        break;
      case 'last_week_departure':
        newDateType = 'departure';
        newStartDate = lastWeek;
        newEndDate = today;
        break;
      default:
        break;
    }
    setDateType(newDateType);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setPage(0);
  };
  
  export default handleQuickFilter;
  