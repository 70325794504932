import React from 'react';
import Tooltip from 'components/TooltipGlobal/Tooltip';

const RenderInputField = ({ formik, name, label, placeholder, isRequired = false, options = [] }) => {
    return (
        <div className="mb-1">
            <label htmlFor={name} className="block text-[13px] text-gray-700 font-semibold mb-2">
                {label} {isRequired && <span className='text-red-500'>*</span>}
            </label>
            <Tooltip text="This information is displayed on your website and exported to Tripadvisor, 9flats, and Innclusive." iconClass="pi pi-question-circle text-[#337ab7] !text-[15px] !m-1" position="top" />
            {options.length > 0 ? (
                <select
                    id={name}
                    name={name}
                    className={`w-full !text-sm px-2 py-2 border border-gray-300 focus:outline-none focus:ring-1 focus:ring-green-300 ${formik.touched[name] && formik.errors[name] ? '!border-red-500' : ''}`}
                    value={formik.values[name]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                >
                    {options.map((option) => (
                        <option key={option.value} value={option.value} className="text-gray-800">
                            {option.label}
                        </option>
                    ))}
                </select>
            ) : (
                <input
                    id={name}
                    name={name}
                    type="text"
                    className={`w-full !text-sm px-2 py-2 border border-gray-300 focus:outline-none focus:ring-1 focus:ring-green-300 ${formik.touched[name] && formik.errors[name] ? '!border-red-500' : ''}`}
                    placeholder={placeholder}
                    value={formik.values[name]}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
            )}
            {formik.touched[name] && formik.errors[name] && (
                <div className="text-red-500 text-sm mt-1">{formik.errors[name]}</div>
            )}
        </div>
    );
};

export default RenderInputField;
