import React, { useState, useRef, useEffect } from 'react';

const FilterCalendar = () => {
    const [property, setProperty] = useState('Show All');
    const [date, setDate] = useState('');
    const [showTasks, setShowTasks] = useState(true);
    const [showNotes, setShowNotes] = useState(true);
    const [showStaff, setShowStaff] = useState(false);
    const [showInactiveBookings, setShowInactiveBookings] = useState(false);
    const [showFilter, setShowFilter] = useState(false);

    const filterRef = useRef(null);
    const [filterHeight, setFilterHeight] = useState('0px');

    useEffect(() => {
        if (showFilter) {
            setFilterHeight(`${filterRef.current.scrollHeight}px`);
        } else {
            setFilterHeight('0px');
        }
    }, [showFilter]);

    const handlePropertyChange = (e) => setProperty(e.target.value);
    const handleDateChange = (e) => setDate(e.target.value);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log({ property, date, showTasks, showNotes, showStaff, showInactiveBookings });
    };

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    return (
        <>
            <div className='bg-[#f3f4f6] flex justify-between'>
                <div onClick={toggleFilter} style={{ cursor: 'pointer' }} className='w-[5rem]  my-1 flex'>
                    <span className='flex items-center text-[16px] p-1 text-gray-500'><i className={`pi ${showFilter ? "pi-filter-fill text-gray-500 text-sm" : "pi-filter text-gray-500 text-sm"}`}></i>Filters</span>
                </div>
                <div>
                <span><i className={`pi ${showFilter ? "pi-caret-down text-gray-400 text-xs" : "pi-caret-right text-gray-400 text-xs"}`}></i></span>
                </div>
            </div>
            <div
                ref={filterRef}
                style={{ height: filterHeight }}
                className="transition-height bg-white overflow-hidden rounded-md shadow"
            >
                <div className="p-4">
                    <form onSubmit={handleSubmit}>
                        <div className='flex gap-28'>
                            <div>
                                <div>
                                    <span className="text-gray-600 text-xs">Sort Properties Within Each Region By:</span>
                                    <div className="flex items-center space-x-4">
                                        <label className="inline-flex items-center">
                                            <input type="radio" className="form-radio" name="sort" value="name" />
                                            <span className="px-1 text-xs">Name</span>
                                        </label>
                                        <label className="inline-flex items-center">
                                            <input type="radio" className="form-radio" name="sort" value="property" defaultChecked />
                                            <span className="px-1 text-xs">Property Sort Order</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="flex space-x-4 gap-1">
                                    <label className="inline-flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={showTasks}
                                            onChange={() => setShowTasks(!showTasks)}
                                            className="form-checkbox h-3 w-3 text-blue-600 transition duration-150 ease-in-out"
                                        />
                                        <span className="p-1 text-xs text-gray-700">Show Tasks</span>
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={showNotes}
                                            onChange={() => setShowNotes(!showNotes)}
                                            className="form-checkbox h-3 w-3 text-blue-600 transition duration-150 ease-in-out"
                                        />
                                        <span className="p-1 text-xs text-gray-700">Show Notes</span>
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={showStaff}
                                            onChange={() => setShowStaff(!showStaff)}
                                            className="form-checkbox h-3 w-3 text-blue-600 transition duration-150 ease-in-out"
                                        />
                                        <span className="p-1 text-xs text-gray-700">Show Staff</span>
                                    </label>
                                    <label className="inline-flex items-center">
                                        <input
                                            type="checkbox"
                                            checked={showInactiveBookings}
                                            onChange={() => setShowInactiveBookings(!showInactiveBookings)}
                                            className="form-checkbox h-3 w-3 text-blue-600 transition duration-150 ease-in-out"
                                        />
                                        <span className="p-1 text-xs text-gray-700">Show Inactive Bookings</span>
                                    </label>
                                </div>
                            </div>
                            <div className='flex gap-32  p-2 rounded-md border-1'>
                                <div className="flex items-center space-x-2 gap-1">
                                    <div className="flex items-center space-x-4">
                                        <label className="text-gray-600 text-xs">Property:</label>
                                        <select
                                            value={property}
                                            onChange={handlePropertyChange}
                                            className="border border-gray-300 rounded px-4 py-1 mx-1 !text-sm"
                                        >
                                            <option className='text-xs' value="Show All">Show All</option>
                                        </select>
                                    </div>
                                    <div className='border border-gray-300 rounded  mx-1 !text-xs'>
                                        <label className="text-gray-600 text-xs p-1">Prop name</label>
                                    </div>
                                    <div>
                                        <label htmlFor="date" className="text-xs font-medium text-gray-700">Date:</label>
                                        <input
                                            type="date"
                                            id="date"
                                            value={date}
                                            onChange={handleDateChange}
                                            className="border border-gray-300 rounded px-4 py-1 mx-1 !text-sm "
                                        />
                                    </div>
                                </div>
                                <div className='flex items-center gap-1'>
                                    <button
                                        type="submit"
                                        className="bg-white hover:bg-gray-100 text-gray-400 font-medium py-2 px-4 !rounded-sm !text-xs transition duration-150 ease-in-out shadow-sm border-1"
                                    >
                                        Map
                                    </button>
                                    <button
                                        type="submit"
                                        className="bg-white hover:bg-gray-100 text-gray-400 font-medium py-2 px-4 !rounded-sm !text-xs transition duration-150 ease-in-out shadow-sm border-1"
                                    >
                                        Clear
                                    </button> <button
                                        type="submit"
                                        className="bg-[#66dac3] text-white font-medium py-2 px-4 !rounded-sm !text-xs transition duration-150 ease-in-out shadow-sm"
                                    >
                                        Set
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default FilterCalendar;
