import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography, Paper, Grid } from '@mui/material';
import { styled } from '@mui/system';
import { DollarSign } from 'lucide-react';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

const PriceInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.dark,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.dark,
    },
  },
}));



function PriceAndFees({ formik }) {
  const [price, setPrice] = useState(formik.values.price || 0);
  const currency = 'MAD';

  useEffect(() => {
    formik.setFieldValue('currencyCode', currency);
  }, []);

  const handlePriceChange = (event) => {
    const newPrice = parseFloat(event.target.value) || 0;
    setPrice(newPrice);
    formik.setFieldValue('price', newPrice);
  };



  return (
    <Box sx={{ width: '100%', p: 4 }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
        Price
      </Typography>
      
      <StyledPaper elevation={3}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6}>
            <PriceInput
              fullWidth
              label="Base Price"
              type="number"
              value={price}
              onChange={handlePriceChange}
              InputProps={{
                endAdornment: <span className='font-bold text-green-500'>{currency}</span>,
              }}
            />
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>
  );
}

export default PriceAndFees;