import { configureStore } from "@reduxjs/toolkit";
import uploadImage from "./slices/UploadSlice"
import dateReducer from "./slices/DateSlice"
import formData from "./slices/FormSlice"




const reducer = {
    uploadData: uploadImage,
    yearDateDta: dateReducer,
    formData: formData

    
  }
  
  const store = configureStore({
    reducer: reducer,
    devTools: true,
  })
  
  export default store;