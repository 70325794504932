import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  TextField,
  Box,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { updateAmenity, createAmenity } from '../services/serverApi.listing.js';
import { toast } from 'react-toastify';
import ImageUpload from 'components/CustomUpload/UploadV2';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Amenity name is required'),
  cityIds: Yup.array().of(Yup.string()).required('City is required'),
  enabled: Yup.boolean().required('Enabled flag is required'),
  topAmentity: Yup.boolean().required('Top Amenity flag is required'),
  usedForFilter: Yup.boolean().required('Used for Filter flag is required'),
  iconUrl: Yup.string().required('Image is required'),
});


const ModifyAmenity = ({ open, onClose, setAmenities, amenities, index, dataAmenity, cities, isCreating }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);
    const formData = {
      name: values.name,
      cityIds: values.cityIds,
      enabled: values.enabled,
      topAmentity: values.topAmentity,
      usedForFilter: values.usedForFilter,
      iconUrl: values.iconUrl,
    };
    try {
      let response;
      if (isCreating) {
        response = await createAmenity(formData);
      } else {
        response = await updateAmenity(dataAmenity._id, formData);
      }

      if (response.data && response.data.data) {
        if (isCreating) {
          setAmenities(prevAmenities => [...prevAmenities, response.data.data]);
        } else {
          setAmenities(prevAmenities =>
            prevAmenities.map(a => a._id === dataAmenity._id ? response.data.data : a)
          );
        }
        setSubmitting(false);
        onClose();
        toast.success(isCreating ? 'Amenity created successfully' : 'Modification réussie');
      } else {
        throw new Error('Unexpected response structure');
      }
    } catch (error) {
      setErrorMessage(error.message);
      setSubmitting(false);
      toast.error(isCreating ? 'Error creating amenity' : 'Error updating amenity');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className='bg-medium-aquamarine text-white'>
        <Typography variant="h6" style={{ color: 'white' }} align="center">
          {isCreating ? 'Create Amenity' : 'Modify Amenity'}
        </Typography>
      </DialogTitle>
      <DialogContent className='pt-4'>
        {errorMessage && (
          <Box mb={2}>
            <Typography variant="body2" color="error" align="center">
              {errorMessage}
            </Typography>
          </Box>
        )}
        <Formik
          initialValues={{
            name: dataAmenity?.name || '',
            cityIds: dataAmenity?.cityIds || [],
            enabled: dataAmenity?.enabled || false,
            topAmentity: dataAmenity?.topAmentity || false,
            usedForFilter: dataAmenity?.usedForFilter || false,
            iconUrl: dataAmenity?.iconUrl || '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <Box mb={2}>
                <TextField
                  fullWidth
                  id="outlined-number"
                  name="name"
                  label="Name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={values.name}
                  onChange={(event) => setFieldValue('name', event.target.value)}
                />
                <ErrorMessage name="name" component={Typography} variant="body2" color="error" />
              </Box>
              <Box mb={2}>
                <FormControl fullWidth>
                  <InputLabel id="cityIds-label">Cities</InputLabel>
                  <Select
                    label="Cities"
                    id="cityIds"
                    name="cityIds"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiple
                    value={values.cityIds}
                    onChange={(event) => setFieldValue('cityIds', event.target.value)}
                    renderValue={(selected) => (
                      <Box display="flex" flexWrap="wrap" gap={1}>
                        {selected.map((value) => (
                          <Chip key={value} label={cities.find((city) => city._id === value)?.name || value} />
                        ))}
                      </Box>
                    )}
                  >
                    {cities.map((city) => (
                      <MenuItem key={city._id} value={city._id}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <ErrorMessage name="cityIds" component={Typography} variant="body2" color="error" />
                </FormControl>
              </Box>
              <Box mb={2} display="flex" alignItems="center">
                <Typography className='text-[2px]' >
                  {values.enabled ? <VisibilityIcon style={{ color: "black" }} /> : <VisibilityOffIcon style={{ "color": "red" }} />}
                  <span className='text-[14px] p-1'>{values.enabled ? "Enabled" : "Disabled"}</span>
                </Typography>
                <Field as={Switch} type="checkbox" id="enabled" name="enabled" />
              </Box>
              <Box mb={2} display="flex" alignItems="center">
                <Typography className='text-[2px]' >
                  {values.topAmentity ? <StarIcon style={{ color: "gold" }} /> : <StarBorderIcon style={{ "color": "gray" }} />}
                  <span className='text-[14px] p-1'>{values.topAmentity ? "Top Amenity" : "Regular Amenity"}</span>
                </Typography>
                <Field as={Switch} type="checkbox" id="topAmentity" name="topAmentity" />
              </Box>
              <Box mb={2} display="flex" alignItems="center">
                <Typography className='text-[2px]' >
                  {values.usedForFilter ? <FilterListIcon style={{ color: "blue" }} /> : <FilterListOffIcon style={{ "color": "gray" }} />}
                  <span className='text-[14px] p-1'>{values.usedForFilter ? "Used for Filter" : "Not Used for Filter"}</span>
                </Typography>
                <Field as={Switch} type="checkbox" id="usedForFilter" name="usedForFilter" />
              </Box>
              <Box mb={2}>
                <Typography variant="body1">Icon</Typography>
                <Field name="iconUrl">
                  {({ field, form }) => (
                    <ImageUpload
                      fieldName="iconUrl"
                      setFieldValue={form.setFieldValue}
                      folder="other"
                    />
                  )}
                </Field>
                {values.iconUrl && (
                  <img
                    src={values.iconUrl}
                    style={{ width: '150px', height: '150px', objectFit: 'contain' }}
                  />
                )}
                <ErrorMessage name="iconUrl" component={Typography} variant="body2" color="error" />
              </Box>
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                <button className='text-white py-1 px-3 bg-medium-aquamarine !rounded-md' type="submit" disabled={isSubmitting}>
                  {isLoading ? (isCreating ? 'Creating...' : 'Updating...') : (isCreating ? 'Create' : 'Update')}
                </button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyAmenity;