import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { createRoomTypes, updateRoomTypes } from '../services/serverApi.listing.js';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Box, Typography } from '@mui/material';
import ImageUpload from 'components/CustomUpload/UploadV2';

const validationSchema = Yup.object().shape({
    type: Yup.string().required('Room type is required'),
    iconUrl: Yup.string().required('Icon is required'),
});

function ModifyRoomType({ open, onClose, index, dataRoomType, isCreating, onCreateRoomType, onUpdateRoomType }) {
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        setIsLoading(true);
        try {
            const formData = {
                type: values.type,
                iconUrl: values.iconUrl,
            };
    
            let response;
            if (isCreating) {
                response = await createRoomTypes(formData);
                console.log("response", response);
                
                if (response.data && response.data.RoomTypeConfig) {
                    onCreateRoomType(response.data.RoomTypeConfig);
                } else {
                    toast.error('Unexpected response format from server');
                }
                toast.success('Room Type created successfully');
            } else {
                response = await updateRoomTypes(dataRoomType._id, formData);
                if (response.data && response.data.roomTypeConfig) {
                    onUpdateRoomType(response.data.roomTypeConfig, index);
                } else {
                    console.error('Unexpected response format:', response);
                    toast.error('Unexpected response format from server');
                }
                toast.success('Room Type updated successfully');
            }
            setSubmitting(false);
            onClose();
        } catch (error) {
            console.error('Error saving room type:', error);
            toast.error('Error saving room type');
            setSubmitting(false);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className='bg-medium-aquamarine text-white'>
                <Typography variant="h6" style={{ color: 'white' }} align="center">
                    {isCreating ? 'Create Room Type' : 'Modify Room Type'}
                </Typography>
            </DialogTitle>
            <DialogContent className='pt-4'>
                <Formik
                    initialValues={{
                        type: dataRoomType?.type || '',
                        iconUrl: dataRoomType?.iconUrl || '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue, isSubmitting }) => (
                        <Form>
                            <Box mb={2}>
                                <TextField
                                    fullWidth
                                    id="type"
                                    name="type"
                                    label="Room Type"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="outlined"
                                    value={values.type}
                                    onChange={(event) => setFieldValue('type', event.target.value)}
                                />
                                <ErrorMessage name="type" component={Typography} variant="body2" color="error" />
                            </Box>
                            <Box mb={2}>
                                <Typography variant="body1">Icon</Typography>
                                <Field name="iconUrl">
                                    {({ field, form }) => (
                                        <ImageUpload
                                            fieldName="iconUrl"
                                            setFieldValue={form.setFieldValue}
                                            folder="other"
                                        />
                                    )}
                                </Field>
                                {values.iconUrl && (
                                    <img
                                        src={values.iconUrl}
                                        alt="Room Type Icon"
                                        style={{ width: '150px', height: '150px', objectFit: 'contain' }}
                                    />
                                )}
                                <ErrorMessage name="iconUrl" component={Typography} variant="body2" color="error" />
                            </Box>
                            <DialogActions>
                                <Button onClick={onClose} color="secondary">
                                    Cancel
                                </Button>
                                <button 
                                    className='text-white py-1 px-3 bg-medium-aquamarine !rounded-md' 
                                    type="submit" 
                                    disabled={isSubmitting}
                                >
                                    {isLoading ? (isCreating ? 'Creating...' : 'Updating...') : (isCreating ? 'Create' : 'Update')}
                                </button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

export default ModifyRoomType;