import React, { useState, useEffect } from 'react';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import FilterSection from './FilterSection';
import ViewToggle from './ViewToggle';
import { getRevenuePerLandR, getListingsPagination, getReservationPagination } from 'features/financial/services/serverApi.financialConfig';
import { Alert } from '@mui/material';
import moment from 'moment';
import { CircularProgress, Box } from '@mui/material';
import ColumnVisibility from './ColumnVisibility';

const ReservationTable = () => {
  const [filters, setFilters] = useState({
    from: moment().startOf('month').format('YYYY-MM-DD'),
    to: moment().endOf('month').format('YYYY-MM-DD'),
    dateRange: '',
    statuses: '2 reservation statuses',
    checkin: true
  });
  const [view, setView] = useState('listing');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [revenueData, setRevenueData] = useState(null);
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const savedVisibility = localStorage.getItem('columnVisibility');
    if (savedVisibility) {
      return JSON.parse(savedVisibility);
    }
    return {
      '_id': false,
      'name': true,
      'totalRevenue': true,
      'fees': true,
      'nightsBooked': true,
      'ownerStayNights': false,
      'totalCheckIns': true,
      'nightsAvailable': true,
      'occupancyRate': true,
      'revenuePerNightBooked': false,
      'revenuePerNightAvailable': false,
      'averageNightlyRate': false,
      'averageRevenuePerStay': false,
      'guestName': true,
      'listingName': true,
      'cancelations': false,
      'cancelationPercentage': false
    };
  });

  useEffect(() => {
    fetchData();
  }, [view, filters, page, limit]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setError(null);

      if (view === 'listing') {
        const listingsResponse = await getListingsPagination(page, limit);
        const listingsData = listingsResponse.data.data;

        const itemIds = listingsData.map(listing => listing._id).join(',');
        const revenueResponse = await getRevenuePerLandR(
          filters.from,
          filters.to,
          view,
          itemIds
        );

        if (revenueResponse.data.success) {
          const revenueData = revenueResponse.data.byItems;

          const combinedData = listingsData.map(listing => {
            const revenueItem = revenueData.find(item => item.itemId === listing._id) || {};
            return {
              ...listing,
              totalRevenue: revenueItem.totalRevenue || '_',
              fees: revenueItem.fees || '_',
              nightsBooked: revenueItem.nightsBooked || '_',
              ownerStayNights: revenueItem.ownerStayNights || '_',
              totalCheckIns: revenueItem.totalCheckIns || '_',
              nightsAvailable: revenueItem.nightsAvailable || '_',
              occupancyRate: revenueItem.occupancyRate || '_',
              revenuePerNightBooked: revenueItem.revenuePerNightBooked || '_',
              revenuePerNightAvailable: revenueItem.revenuePerNightAvailable || '_',
              averageNightlyRate: revenueItem.averageNightlyRate || '_',
              averageRevenuePerStay: revenueItem.averageRevenuePerStay || '_',
              cancelations: revenueItem?.cancelations || '_',
              cancelationPercentage: revenueItem?.cancelationPercentage || '_'
            };
          });

          setData(combinedData);
          setTotal(revenueResponse.data.totals.totalRevenue);
          setRevenueData(revenueResponse.data);
          setIsNextDisabled(listingsData.length < limit);
        } else {
          setError('Failed to fetch revenue data. Please try again.');
        }
      } else {
        const reservationsResponse = await getReservationPagination(page, limit);
        const reservationsData = reservationsResponse.data.data;

        const itemIds = reservationsData.map(reservation => reservation._id).join(',');
        const revenueResponse = await getRevenuePerLandR(
          filters.from,
          filters.to,
          view,
          itemIds
        );

        if (revenueResponse.data.success) {
          const revenueData = revenueResponse.data.byItems;

          const combinedData = reservationsData.map(reservation => {
            const revenueItem = revenueData.find(item => item.itemId === reservation._id) || {};
            return {
              ...reservation,
              totalRevenue: revenueItem.totalRevenue || "_",
              fees: revenueItem.fees || "_",
              listingName: reservation.listing?.name
            };
          });

          setData(combinedData);
          setTotal(revenueResponse.data.totals?.totalRevenue);
          setIsNextDisabled(reservationsData.length < limit);
        } else {
          setError('Failed to fetch revenue data. Please try again.');
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('An error occurred while fetching data. Please try again later.');
    }
    finally {
      setIsLoading(false);
    }
  };

  const columns = view === 'listing'
    ? [
      columnVisibility['_id'] && { field: '_id', header: 'Listing ID' },
      columnVisibility['name'] && { field: 'name', header: 'Listing Name' },
      columnVisibility['totalRevenue'] && { field: 'totalRevenue', header: 'Total Revenue' },
      columnVisibility['nightsBooked'] && { field: 'nightsBooked', header: 'Nights Booked' },
      columnVisibility['ownerStayNights'] && { field: 'ownerStayNights', header: 'Owner Stay Nights' },
      columnVisibility['totalCheckIns'] && { field: 'totalCheckIns', header: 'Total Check-ins' },
      columnVisibility['nightsAvailable'] && { field: 'nightsAvailable', header: 'Nights Available' },
      columnVisibility['occupancyRate'] && { field: 'occupancyRate', header: 'Occupancy Rate' },
      columnVisibility['revenuePerNightBooked'] && { field: 'revenuePerNightBooked', header: 'Revenue Per Night Booked' },
      columnVisibility['revenuePerNightAvailable'] && { field: 'revenuePerNightAvailable', header: 'Revenue Per Night Available' },
      columnVisibility['averageNightlyRate'] && { field: 'averageNightlyRate', header: 'Average Nightly Rate' },
      columnVisibility['averageRevenuePerStay'] && { field: 'averageRevenuePerStay', header: 'Average Revenue Per Stay' },
      columnVisibility['cancelations'] && { field: 'cancelations', header: 'Cancelations' },
      columnVisibility['cancelationPercentage'] && { field: 'cancelationPercentage', header: 'Cancelation Percentage' },
      columnVisibility['fees'] && { field: 'fees', header: 'Fees' }

    ].filter(Boolean)
    : [
      columnVisibility['_id'] && { field: '_id', header: 'Reservation ID' },
      columnVisibility['guestName'] && { field: 'guestName', header: 'Guest Name' },
      columnVisibility['listingName'] && { field: 'listingName', header: 'Listing Default Order' },
      columnVisibility['totalRevenue'] && { field: 'totalRevenue', header: 'Total Revenue' },
      columnVisibility['fees'] && { field: 'fees', header: 'Fees' }
    ].filter(Boolean);

    useEffect(() => {
      localStorage.setItem('columnVisibility', JSON.stringify(columnVisibility));
    }, [columnVisibility]);
  
    const handleColumnVisibilityChange = (column) => {
      setColumnVisibility(prev => {
        const newVisibility = {
          ...prev,
          [column]: !prev[column]
        };
        localStorage.setItem('columnVisibility', JSON.stringify(newVisibility));
        return newVisibility;
      });
    };
    
  const handleFilterChange = (key, value) => {
    if (key === 'reset') {
      resetFilters();
    } else {
      setFilters(prevFilters => ({ ...prevFilters, [key]: value }));
    }
  };

  const resetFilters = () => {
    setFilters({
      from: moment().startOf('year').format('YYYY-MM-DD'),
      to: moment().endOf('month').format('YYYY-MM-DD'),
      dateRange: '',
      checkin: true
    });
    setPage(0);
    setLimit(10);
    setData([]);
    setTotal(0);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };
  const toggleFilters = () => {
    setShowFilters(prev => !prev);
  };

  const handleViewChange = (newView) => {
    if (newView !== view) {
      resetFilters();
      setView(newView);
    }
  };


  return (
    <>
      <div className="flex justify-between">
      <ViewToggle view={view} setView={setView} toggleFilters={toggleFilters} onViewChange={handleViewChange} />
      </div>
      {showFilters && (
        <>
          <FilterSection filters={filters} handleFilterChange={handleFilterChange} />
          <ColumnVisibility 
            view={view}
            columnVisibility={columnVisibility}
            handleColumnVisibilityChange={handleColumnVisibilityChange}
          />
        </>
      )}
      {error && <Alert severity="error" className="mb-4">{error}</Alert>}
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress sx={{ color: '#00b4b4' }} />
        </Box>
      ) : (
        !error && (
          <>
            <GlobalTable
              data={data}
              columns={columns}
              hasPagination={true}
              page={page}
              onPageChange={handlePageChange}
              isNextDisabled={isNextDisabled}
              limit={limit}
              onLimitChange={handleLimitChange}
              rowsPerPageOptions={[5, 10, 20, 50]}
              totalRevenue={total}
              totals={view === 'listing' && revenueData ? revenueData.totals : undefined}
            />
          </>
        )
      )}
    </>
  );
};

export default ReservationTable;
