import React, { useState } from 'react';
import {
    TextField,
    Grid,
    Typography,
    Box,
    IconButton,
    Chip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import {
    Bed,
    Bath,
    Users,
    HashIcon,
    Plus
} from 'lucide-react';
import BedTypePopup from './modals/BedTypePopup';

const RoomTypeSelect = ({ roomType, types, index, formik }) => {
    const handleChange = (e) => {
        const selectedTypeId = e.target.value;
        const selectedType = types.find(type => type._id === selectedTypeId);

        formik.setFieldValue(`roomTypes.${index}.roomTypeConfigId`, selectedTypeId);
        formik.setFieldValue(`roomTypes.${index}.roomTypeName`, selectedType?.type);
        formik.setFieldValue(`roomTypes.${index}.rooms.${index}.roomName`, selectedType?.type);
    };

    return (
        <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
                name={`roomTypes[${index}].roomTypeConfigId`}
                value={roomType.roomTypeConfigId || ''}
                onChange={handleChange}
                label="Type"
                error={
                    formik.touched.roomTypes?.[index]?.roomTypeConfigId &&
                    Boolean(formik.errors.roomTypes?.[index]?.roomTypeConfigId)
                }
                renderValue={(selected) => {
                    const selectedTypeObj = types.find(type => type._id === selected);
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <img
                                src={selectedTypeObj?.iconUrl}
                                alt={selectedTypeObj?.type}
                                style={{ width: 24, height: 24, marginRight: 8 }}
                            />
                            {selectedTypeObj?.type}
                        </Box>
                    );
                }}
            >
                {types.map((type) => (
                    <MenuItem key={type._id} value={type._id}>
                        <ListItemIcon>
                            <img src={type.iconUrl} alt={type.type} style={{ width: 24, height: 24 }} />
                        </ListItemIcon>
                        <ListItemText primary={type.type} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const RoomConfig = ({ formik, types, bedTypes }) => {
    const [isBedTypePopupOpen, setIsBedTypePopupOpen] = useState(false);

    const openBedTypePopup = () => {
        setIsBedTypePopupOpen(true);
    };

    const closeBedTypePopup = () => setIsBedTypePopupOpen(false);

    const handleAddBedType = (bedTypeData) => {
        const newRoomTypes = [...formik.values.roomTypes];
        const currentBedTypes = newRoomTypes[0].bedTypes || [];
        const existingIndex = currentBedTypes.findIndex(bt => bt.bedTypeId === bedTypeData.bedTypeId);

        if (existingIndex !== -1) {
            currentBedTypes[existingIndex].quantity += bedTypeData.quantity;
        } else {
            currentBedTypes.push(bedTypeData);
        }

        newRoomTypes[0] = {
            ...newRoomTypes[0],
            bedTypes: currentBedTypes
        };

        formik.setFieldValue('roomTypes', newRoomTypes);
    };

    const handleRemoveBedType = (bedTypeId) => {
        const newRoomTypes = [...formik.values.roomTypes];
        newRoomTypes[0] = {
            ...newRoomTypes[0],
            bedTypes: newRoomTypes[0].bedTypes.filter(bt => bt.bedTypeId !== bedTypeId)
        };
        formik.setFieldValue('roomTypes', newRoomTypes);
    };

    const handleRoomTypeChange = (index, values) => {
        const newRoomTypes = [...formik.values.roomTypes];
        newRoomTypes[index] = {
            ...newRoomTypes[index],
            ...values
        };
        formik.setFieldValue('roomTypes', newRoomTypes);
    };

    const renderTextField = (label, field, icon, value, type = 'text') => (
        <TextField
            label={label}
            error={formik.touched?.roomTypes?.[0] && Boolean(formik.errors?.roomTypes?.[0]?.[field])}
            value={field === 'roomCode'
                ? formik.values?.roomTypes?.[0]?.rooms?.[0]?.roomCode || ''
                : value || ''}
            onChange={(e) => {
                const newRoomTypes = [...(formik.values?.roomTypes || [{
                    rooms: [{}],
                    bedTypes: []
                }])];

                if (field === 'roomCode') {
                    newRoomTypes[0] = {
                        ...newRoomTypes[0],
                        rooms: [{
                            ...newRoomTypes[0]?.rooms?.[0],
                            roomCode: e.target.value
                        }]
                    };
                } else if (field === 'roomNumber') {
                    const newValue = Number(e.target.value) || 0;
                    newRoomTypes[0] = {
                        ...newRoomTypes[0],
                        roomNumber: newValue,
                        rooms: [{
                            ...newRoomTypes[0]?.rooms?.[0],
                            roomNumber: Number(newValue)
                        }]
                    };

                }else if (field === 'roomTypeName') {
                    newRoomTypes[0] = {
                        ...newRoomTypes[0],
                        roomTypeName: e.target.value,
                        rooms: [{
                            ...newRoomTypes[0]?.rooms?.[0],
                            roomName: e.target.value  
                        }]
                    };

                } else {
                    const newValue = type === 'number' ? Number(e.target.value) || 0 : e.target.value;
                    newRoomTypes[0] = {
                        ...newRoomTypes[0],
                        [field]: newValue
                    };
                }

                formik.setFieldValue('roomTypes', newRoomTypes);

            }}
            fullWidth
            type={type}
            InputProps={{
                startAdornment: React.cloneElement(icon, { size: 20, className: 'mr-2 text-gray-500' }),
                inputProps: field === 'roomCode' ? {} : { min: 0 }
            }}
        />
    );

    return (
        <div className="w-full p-4 h-auto">
            <Typography variant="h6" className="mb-4">Configuration</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <RoomTypeSelect
                        roomType={formik.values.roomTypes[0]}
                        types={types}
                        index={0}
                        handleRoomTypeChange={handleRoomTypeChange}
                        formik={formik}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    {renderTextField(
                        "Number of rooms",
                        "roomNumber",
                        <HashIcon />,
                        formik.values.roomTypes[0]?.roomNumber,
                        "number"
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {renderTextField("Name", "roomTypeName", <HashIcon />, formik.values.roomTypes[0]?.roomTypeName)}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {renderTextField(
                        "Number of Beds",
                        "bedsNumber",
                        <Bed />,
                        formik.values.roomTypes[0]?.bedsNumber,
                        "number"
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {renderTextField(
                        "Key Code",
                        "roomCode",
                        <HashIcon />,
                        formik.values.roomTypes[0]?.rooms[0]?.roomCode
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {renderTextField(
                        "Number of Bedrooms",
                        "bedroomsNumber",
                        <Bed />,
                        formik.values.roomTypes[0]?.bedroomsNumber,
                        "number"
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {renderTextField(
                        "Base Price",
                        "basePrice",
                        <span>DH</span>,
                        formik.values.roomTypes[0]?.basePrice,
                        "number"
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {renderTextField(
                        "Number of Bathrooms",
                        "bathroomsNumber",
                        <Bath />,
                        formik.values.roomTypes[0]?.bathroomsNumber,
                        "number"
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {renderTextField(
                        "Person Capacity",
                        "personCapacity",
                        <Users />,
                        formik.values.roomTypes[0]?.personCapacity,
                        "number"
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {renderTextField(
                        "Surface",
                        "surface",
                        <HashIcon />,
                        formik.values.roomTypes[0]?.surface,
                        "number"
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Box className="flex items-center gap-2 mb-2">
                        <Typography variant="subtitle1">Select bed types</Typography>
                        <IconButton
                            onClick={openBedTypePopup}
                            className="!text-teal-600 border !border-teal-600 rounded-full p-1"
                        >
                            <Plus size={20} />
                        </IconButton>
                    </Box>
                    <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
                        {formik.values.roomTypes[0]?.bedTypes?.map((bedType) => {
                            const bedTypeInfo = bedTypes.find(bt => bt._id === bedType.bedTypeId);
                            return (
                                <Chip
                                    key={bedType.bedTypeId}
                                    label={`${bedTypeInfo?.name} (${bedType.quantity})`}
                                    onDelete={() => handleRemoveBedType(bedType.bedTypeId)}
                                    className="!bg-blue-100 !text-blue-800"
                                />
                            );
                        })}
                    </Box>
                </Grid>
            </Grid>
            <BedTypePopup
                isOpen={isBedTypePopupOpen}
                onClose={closeBedTypePopup}
                bedTypes={bedTypes}
                selectedBedTypes={formik.values.roomTypes[0]?.bedTypes || []}
                onAddBedType={handleAddBedType}
            />
        </div>
    );
};

export default RoomConfig;