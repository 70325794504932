import React from 'react';
import { Tooltip } from 'primereact/tooltip';

const ReservationColumns = ({
  handleDetailsClick,
  handleGuestClick,
  handleCalendarClick,
  handleTaskClick,
  openSnackbar,
  handleListingClick,
}) => {
  return [
    {
      field: null,
      uniqueId: 'calendar',
      headerLabel: 'Calendar',
      header: 'Calendar',
      body: (rowData) => (
        <span id={`calendarTooltip-${rowData.id}`}>
          <i
            className="pi pi-calendar text-medium-aquamarine font-semibold cursor-pointer size-4"
            style={{ fontWeight: 'bold' }}
            onClick={() =>
              handleCalendarClick(
                rowData.sojoriId,
                rowData.checkInDate,
                rowData.checkOutDate,
              )
            }
          ></i>
        </span>
      ),
      headerStyle: { width: '2.5rem' },
      header: () => (
        <>
          <span id="calendarHeaderTooltip">Calendar</span>
          <Tooltip target="#calendarHeaderTooltip" content="Calendar" />
        </>
      ),
    },
    {
      field: null,
      uniqueId: 'details',
      headerLabel: 'Details',
      header: 'Details',
      body: (rowData) => (
        <span id={`detailsTooltip-${rowData.id}`}>
          <i
            className="pi pi-align-justify text-medium-aquamarine font-semibold cursor-pointer size-4"
            style={{ fontWeight: 'bold' }}
            onClick={() => handleDetailsClick(rowData.id)}
          ></i>
        </span>
      ),
      headerStyle: { width: '2.5rem' },
      header: () => (
        <>
          <span id="detailsHeaderTooltip">Details</span>
          <Tooltip target="#detailsHeaderTooltip" content="Details" />
        </>
      ),
    },
    {
      field: null,
      uniqueId: 'contact',
      headerLabel: 'Contact/Tasks',
      header: 'Contact',
      body: (rowData) => (
        <span id={`contactTooltip-${rowData.id}`}>
          <i
            className="pi pi-inbox text-medium-aquamarine font-bold cursor-pointer size-4"
            style={{ fontWeight: 'bold' }}
            onClick={() => handleTaskClick(rowData.reservationNumber)}
          ></i>
        </span>
      ),
      headerStyle: { width: '2.5rem' },
      header: () => (
        <>
          <span id="contactHeaderTooltip">Contact</span>
          <Tooltip target="#contactHeaderTooltip" content="Contact/Tasks" />
        </>
      ),
    },
    {
      field: 'reservationNumber',
      header: 'Reservation',
      body: (rowData) => (
        <>
          <span
            id={`reservationTooltip-${rowData.id}`}
            className="cursor-pointer"
            onClick={() => {
              const reservationNumber = rowData.reservationNumber;
              navigator.clipboard.writeText(reservationNumber);
              openSnackbar(
                'Reservation' +
                  ' ' +
                  reservationNumber +
                  ' ' +
                  'copied to clipboard',
              );
            }}
          >
            {rowData.reservationNumber}
          </span>
          <Tooltip
            target={`#reservationTooltip-${rowData.id}`}
            content="Click to copy reservation number"
          />
        </>
      ),
    },
    {
      field: 'createdAt',
      header: 'Reservation Date',
    },
    {
      field: 'guestName',
      header: 'Guest Name',
      body: (rowData) => (
        <>
          <span
            id={`guestNameTooltip-${rowData.id}`}
            className="text-medium-aquamarine font-bold cursor-pointer"
            onClick={() => handleDetailsClick(rowData.id)}
          >
            {rowData.guestName}
          </span>
          <Tooltip
            target={`#guestNameTooltip-${rowData.id}`}
            content={rowData.guestName}
          />
        </>
      ),
    },
    {
      field: 'checkInDate',
      header: 'Check-in',
    },
    {
      field: 'checkOutDate',
      header: 'Check-out',
    },
    {
      field: 'totalPrice',
      header: 'Total Price',
      body: (rowData) => (
        <>
          <span className="">{rowData.totalPrice} </span>
          <span className="font-bold">{rowData.currency}</span>
        </>
      ),
    },
    {
      field: 'Online checkin',
      header: 'Online checkin',
      body: (rowData) => {
        const x = rowData.x;
        const y = rowData.y;

        const color = x < y ? 'text-red-500' : 'text-green-500';

        return (
          <span
            className={`${color} font-bold cursor-pointer`}
            onClick={() => handleGuestClick(rowData.id)}
          >
            {`${x}/${y}`}
          </span>
        );
      },
    },
    {
      field: 'status',
      header: 'Status',
      body: (rowData) => {
        const redStatuses = [
          'CancelledByAdmin',
          'CancelledByCustomer',
          'CancelledByOta',
          'CancelledAfterFailedPayment',
          'OtherCancellation',
          'RefusedBySoj',
        ];
        const isRedStatus = redStatuses.includes(rowData.status);

        return (
          <>
            <span
              id={`statusTooltip-${rowData.id}`}
              className={`cursor-pointer ${
                isRedStatus ? '!text-red-500' : '!text-[#00b4b4]'
              }`}
            >
              {rowData.status}
            </span>
            <Tooltip
              target={`#statusTooltip-${rowData.id}`}
              content={rowData.status}
              position="left"
            />
          </>
        );
      },
    },
    {
      field: 'paymentStatus',
      header: 'Payment Status',
      body: (rowData) => {
        const paymentStatus = rowData.paymentStatus || '';
        const isPaid = paymentStatus.toLowerCase() === 'paid';
        const paymentRedirectToSuccess = rowData.paymentRedirectToSuccess;

        return (
          <>
            <span
              id={`paymentStatusTooltip-${rowData.id}`}
              className={`cursor-pointer ${
                isPaid ? '!text-medium-aquamarine' : '!text-red-500'
              }`}
            >
              {paymentStatus}
              {isPaid && (
                <span
                  className={paymentRedirectToSuccess ? '' : '!text-red-500'}
                >
                  {paymentRedirectToSuccess ? ' +' : ' -'}
                </span>
              )}
            </span>
            <Tooltip
              target={`#paymentStatusTooltip-${rowData.id}`}
              content={`${paymentStatus}${
                isPaid
                  ? paymentRedirectToSuccess
                    ? ' (Redirect to Success)'
                    : ' (No Redirect to Success)'
                  : ''
              }`}
              position="left"
            />
          </>
        );
      },
    },
    {
      field: 'timeLine',
      header: 'timeLine',
    },
    {
      field: 'phone',
      header: 'phone',
    },
    {
      field: 'roomTypeName',
      header: 'Room Type',
      headerLabel: 'Room Type',
      uniqueId: 'roomType',
      body: (rowData) => (
        <>
          <span
            id={`roomTypeTooltip-${rowData.id}`}
            className="cursor-pointer text-medium-aquamarine"
          >
            {rowData?.roomTypeName}
          </span>
          <Tooltip
            target={`#roomTypeTooltip-${rowData.id}`}
            content={rowData.roomTypeName}
            position="left"
          />
        </>
      ),
      headerStyle: { minWidth: '150px' },
    },
    {
      field: 'listing',
      header: 'Listing',
      body: (rowData) => (
        <>
          <span
            id={`listingTooltip-${rowData.id}`}
            className="cursor-pointer font-bold"
            onClick={() => {
              if (rowData.listingData) {
                handleListingClick(rowData.listingData);
              }
            }}
          >
            {rowData?.listing}
          </span>
          <Tooltip
            target={`#listingTooltip-${rowData.id}`}
            content={rowData.listing}
            position="left"
          />
        </>
      ),
    },
  ];
};

export default ReservationColumns;
