import React, { useState, useEffect } from 'react';
import { IconButton, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import GuestForm from './GuestForm';
import { updateReservation, getReservationsById } from 'features/reservation/services/serverApi.reservation';
import { toast } from 'react-toastify';


const Guest = ({ name, initialData, reservationId, index, onUpdate, onDelete, reservationDetails }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [guestData, setGuestData] = useState(initialData);
  // const [updateKey, setUpdateKey] = useState(0);

  useEffect(() => {
    setGuestData(initialData);
  }, [initialData]);

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this guest?')) {
      onDelete(index);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

 
  const handleUpdate = async (values) => {
    setIsUpdating(true);
    try {
      console.log('Received new values:', values);

      const formattedDateOfBirth = `${values.year}-${values.month}-${values.day}`;

      const updatedMember = {
        document_type: values.documentType.toLowerCase(),
        document_number: values.documentNumber,
        first_name: values.name,
        last_name: values.surname,
        nationality: values.nationality.toLowerCase(),
        country_of_residence: values.documentCountry.toLowerCase(),
        date_of_birth: formattedDateOfBirth,
        gender: values.gender.toLowerCase(),
        document_front_download: values.document_front_download,
        document_back_download: values.document_back_download,
        status: 'COMPLETE',
        phone: values.phone,
        email: values.email,
        registration: 'Manual'
      };

      console.log('Updating member with data:', updatedMember);

      const currentReservation = await getReservationsById(reservationId);

      const updatedMembers = currentReservation.reservation.guestRegistration?.members.map((member, i) =>
        i === index ? { ...member, ...updatedMember } : member
      ) || [];

      const updateData = {
        guestRegistration: {
          ...currentReservation.reservation.guestRegistration,
          members: updatedMembers
        },
        ...reservationDetails,
        atSojori: reservationDetails.atSojori,
        sojoriId: reservationDetails.sojoriId,
        arrivalDate: formatDate(reservationDetails.arrivalDate),
        departureDate: formatDate(reservationDetails.departureDate),
        numberOfGuests: reservationDetails.numberOfGuests
      };

      console.log('Sending updated reservation data:', updateData);

      const response = await updateReservation(reservationId, updateData);
      if (response.success) {
        setGuestData(values);
        onUpdate(index, updatedMember);
        console.log('Member updated successfully');
        toast.success('Member updated successfully');
      } else {
        console.error('Update failed:', response);
        toast.error('Failed to update member' + response.message);
      }
    } catch (error) {
      console.error('Error updating member:', error);
      toast.error('Failed to update member' + error.message);
    } finally {
      setIsUpdating(false);
    }
  };


  return (
    <Box className="bg-white p-3">
      <Box className="flex items-center justify-start cursor-pointer" onClick={toggleOpen}>
        <div>{name}</div>
        <div className="flex items-center gap-2">
          <IconButton>
            {isOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
          <IconButton onClick={handleDelete}><DeleteIcon color="error" /></IconButton>
        </div>
      </Box>
      {isOpen && (
        <Box className="py-3 px-1">
          <GuestForm
            // key={updateKey}
            initialData={guestData}
            onSubmit={handleUpdate}
            isUpdating={isUpdating}
            showUpdateButton={true}
          />
        </Box>
      )}
    </Box>
  );
};

export default Guest;
