import React, { useState, useEffect } from 'react';
import { getcities, removeCity, updateCity, getLanguages } from '../services/serverApi.adminConfig';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import AddCityDialog from './AddCity.component';
import ModifyCityDialog from './ModifyCity.component';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { Typography, CircularProgress, Button, Switch, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';


function CitiesTable() {
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openModifyDialog, setOpenModifyDialog] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCityIndex, setSelectedCityIndex] = useState(null);
  const [error, setError] = useState(null);
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    fetchCities();
    fetchLanguages();
  }, []);

  const fetchLanguages = async () => {
    try {
      const response = await getLanguages();
      setLanguages(response);
    } catch (error) {
      console.error('Error fetching languages:', error);
      toast.error('Failed to fetch languages');
    }
  };

  const fetchCities = async () => {
    setIsLoading(true);
    try {
      const response = await getcities();
      setCities(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching cities:', error);
      setError('Failed to fetch cities. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenAddDialog = () => setOpenAddDialog(true);
  const handleCloseAddDialog = () => setOpenAddDialog(false);

  const handleOpenModifyDialog = (city, index) => {
    setSelectedCity(city);
    setSelectedCityIndex(index);
    setOpenModifyDialog(true);
  };
  
  const handleCloseModifyDialog = () => {
    setSelectedCity(null);
    setSelectedCityIndex(null);
    setOpenModifyDialog(false);
  };

  const handleDeleteCity = async (cityId) => {
    if (window.confirm('Are you sure you want to delete this city?')) {
      try {
        await removeCity(cityId);
        setCities(cities.filter(city => city._id !== cityId));
        toast.success('City deleted successfully');
      } catch (error) {
        console.error('Error deleting city:', error);
        toast.error('Failed to delete city');
      }
    }
  };

  const addCity = (newCity) => {
    setCities([...cities, newCity]);
    handleCloseAddDialog();
  };

  const handleUpdateCity = (updatedCity) => {
    const updatedCities = [...cities];
    updatedCities[selectedCityIndex] = updatedCity;
    setCities(updatedCities);
  };

  const editSwitch = (key, value, cityId) => {
    const updatedCities = cities.map(city => {
      if (city._id === cityId) {
        return { ...city, [key]: value };
      }
      return city;
    });
  
    setCities(updatedCities);
  
    const cityToUpdate = updatedCities.find(city => city._id === cityId);
    if (cityToUpdate) {
      const { _id, ...itemToUpdate } = cityToUpdate;
      updateCity(_id, itemToUpdate)
        .then(({ data }) => {
          console.log(data);
          toast.success(`${key} has been updated`);
        })
        .catch((error) => {
          console.error(error.message);
          toast.error(`Failed to update ${key}`);
        });
    }
  };

  const renderMultiLanguageContent = (content) => {
    if (typeof content !== 'object') {
      return <span>{content}</span>;
    }
    return (
      <div>
        {Object.entries(content).map(([langId, text]) => {
          const language = languages.find(lang => lang._id === langId);
          return (
            <Tooltip key={langId} title={text} arrow placement="top">
              <div>
                <strong>{language ? language.name : 'Unknown'}:</strong>{' '}
                {text.length > 100 ? `${text.substring(0, 100)}...` : text}
              </div>
            </Tooltip>
          );
        })}
      </div>
    );
  };

  const columns = [
    { header: "Name", body: (rowData) => <span>{rowData.name}</span> },
    { 
      header: "Description", 
      body: (rowData) => renderMultiLanguageContent(rowData.description)
    },
    {
      header: "GPS Position",
      body: (rowData) => (
        <div className="flex items-center">
          <LocationOnIcon className="mr-2 text-medium-aquamarine" />
          {rowData.gpsPosition ? (
            <Tooltip title="Click to copy coordinates" arrow>
              <button 
                className="text-left hover:bg-gray-100 p-1 rounded"
                onClick={() => {
                  const coords = `${rowData.gpsPosition.lat}, ${rowData.gpsPosition.lng}`;
                  navigator.clipboard.writeText(coords);
                  toast.info('Coordinates copied to clipboard');
                }}
              >
                <div>Lat: {rowData.gpsPosition.lat}</div>
                <div>Lng: {rowData.gpsPosition.lng}</div>
              </button>
            </Tooltip>
          ) : (
            <span className="text-gray-400">No coordinates</span>
          )}
        </div>
      )
    },
    { 
      header: "Image", 
      body: (rowData) => <img src={rowData.imageUrl} alt="City" style={{ width: '100px', height: 'auto' }} />
    },
    {
      header: "Screen",
      body: (rowData) => (
        <Switch 
          checked={rowData.toDisplayedInMainScreen}
          onChange={(e) => editSwitch('toDisplayedInMainScreen', e.target.checked, rowData._id)}
        />
      )
    },
    {
      header: "Action",
      body: (rowData, { rowIndex }) => (
        <div className="flex gap-1">
          <button className="px-2 py-1 bg-medium-aquamarine !rounded-md" onClick={() => handleOpenModifyDialog(rowData, rowIndex)}>
            <EditOffIcon className="text-white" />
          </button>
          <button className="px-2 py-1 bg-[#df5454] !rounded-md" onClick={() => handleDeleteCity(rowData._id)}>
            <DeleteSweepIcon className="text-white" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="card p-4">
      <Typography variant="h4" component="h1" className="mb-4" gutterBottom>
        Cities Management
      </Typography>
      <div className="mb-4">
        <Button
          startIcon={<AddIcon />}
          onClick={handleOpenAddDialog}
          className="float-right !bg-medium-aquamarine text-white"
        >
          Add City
        </Button>
      </div>
      <div>
        <div className="w-full">
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress style={{ color: '#00b4b4' }} />
            </div>
          ) : (
            <GlobalTable data={cities} columns={columns} hasPagination={false} />
          )}
        </div>
      </div>
      <AddCityDialog open={openAddDialog} onClose={handleCloseAddDialog} addCity={addCity} />
      <ModifyCityDialog
        open={openModifyDialog}
        onClose={handleCloseModifyDialog}
        onUpdateCity={handleUpdateCity}
        dataCity={selectedCity}
      />
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
}

export default CitiesTable;