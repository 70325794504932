import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormControlLabel, Button, CircularProgress } from '@mui/material';
import { createCron, getCrons } from '../services/serverApi.adminConfig';
import { CustomSwitch } from '../custom/CustomSwitch';
import { toast, ToastContainer } from 'react-toastify';

const initialCronState = {
  amenitiesCron: false,
  retrieveAccessToken: false,
  bedTypesCron: false,
  propertyTypesCron: false,
  customFieldCron: false,
  listingsCron: false,
  rerservationsCron: false,
  calendarCron: false,
  tasksCron: false,
};

const cronValidationSchema = Yup.object(
  Object.keys(initialCronState).reduce((acc, key) => {
    acc[key] = Yup.boolean().required();
    return acc;
  }, {})
);

const Cron = () => {
  const [cronSettings, setCronSettings] = useState(initialCronState);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchCronSettings();
  }, []);

  const filterCronSettings = (settings) => {
    const { _id, createdAt, updatedAt, __v, ...relevantSettings } = settings;
    return relevantSettings;
  };

  const fetchCronSettings = async () => {
    try {
      const response = await getCrons();
      if (response && response.cronSetting) {
        const filteredSettings = filterCronSettings(response.cronSetting);
        setCronSettings(filteredSettings);
      }
    } catch (error) {
      console.error('Error fetching cron settings:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const response = await createCron(values);
      if (response && response.message) {
        toast.success(response.message);
      }
      console.log('Response:', response);
    } catch (error) {
      console.error('Error:', error.message);
      toast.error(error.message);
    }
  };

  const formik = useFormik({
    initialValues: cronSettings,
    enableReinitialize: true,
    validationSchema: cronValidationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="card py-4">
      <ToastContainer position="top-right" autoClose={3000} />
      {isLoading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress style={{ color: '#00b4b4' }} />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit} className="flex flex-col items-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {Object.keys(cronSettings).map((key) => (
              <CronSwitch key={key} name={key} formik={formik} />
            ))}
          </div>
          <Button type="submit" variant="contained" className="mt-4 !bg-medium-aquamarine text-white">
            Save
          </Button>
        </form>
      )}
    </div>
  );
};

const CronSwitch = ({ name, formik }) => (
  <FormControlLabel
    control={<CustomSwitch checked={formik.values[name]} onChange={formik.handleChange} name={name} />}
    label={<span className="text-gray-400 text-sm sm:text-base">{name}</span>}
    className="p-2 rounded bg-white border"
  />
);

export default Cron;
