import React, { useState, useEffect } from 'react';
import { Typography, Button, Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import Guest from './components/guest/Guest';
import RegisterGuestModal from './components/guest/RegisterGuestModal';
import { getReservationsById, updateReservation } from "../services/serverApi.reservation";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";


function CheckinDetails() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [reservation, setReservation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
  useEffect(() => {
    fetchReservation();
  }, [id, staging]);

  const fetchReservation = async () => {
    try {
      const response = await getReservationsById(id, staging);
      const fetchedReservation = response.reservation;

      if (fetchedReservation.guestRegistration && fetchedReservation.guestRegistration.members) {
        fetchedReservation.guestRegistration.members = fetchedReservation.guestRegistration.members.map(member => {
          if (!member.document_front_download && member.document_front_scan) {
            member.document_front_download = member.document_front_scan;
            member.document_back_download = member.document_back_scan;
          }
          return member;
        });
      }

      setReservation(fetchedReservation);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const handleModalOpen = () => setIsModalOpen(true);
  const handleModalClose = () => setIsModalOpen(false);

  const handleGuestRegistration = async (values) => {
    const formattedDate = `${values.year}-${values.month}-${values.day}T00:00:00.000Z`;
    const newMember = {
      document_type: values.documentType.toLowerCase(),
      document_number: values.documentNumber,
      first_name: values.name,
      last_name: values.surname,
      nationality: values.nationality.toLowerCase(),
      country_of_residence: values.documentCountry.toLowerCase(),
      date_of_birth: formattedDate,
      gender: values.gender.toLowerCase(),
      document_front_download: values.document_front_download,
      document_back_download: values.document_back_download,
      phone: values.phone,
      email: values.email,
      registration: "Manual",
    };

    const currentGuestRegistration = reservation.guestRegistration || {};
    const currentMembers = currentGuestRegistration.members || [];
    const updatedMembers = [...currentMembers, newMember];
    const nbre_guest_to_register = currentGuestRegistration.nbre_guest_to_register !== undefined ? currentGuestRegistration.nbre_guest_to_register : 0;
    const nbre_guest_registered = updatedMembers.length;

    const updatedReservation = {
      ...reservation,
      atSojori: reservation.atSojori,
      sojoriId: reservation.sojoriId,
      arrivalDate: formatDate(reservation.arrivalDate),
      departureDate: formatDate(reservation.departureDate),
      guestRegistration: {
        nbre_guest_to_register: nbre_guest_to_register,
        nbre_guest_registered: nbre_guest_registered,
        registration_status: determineRegistrationStatus(nbre_guest_registered, nbre_guest_to_register),
        members: updatedMembers
      }
    };

    try {
      const response = await updateReservation(id, updatedReservation);
      if (response.success) {
        setReservation(updatedReservation);
        console.log('New member added successfully');
        toast.success('New member added successfully');
      } else {
        console.error('Update failed:', response);
        toast.error('Failed to add new member' + response.message);
      }
    } catch (error) {
      console.error('Error updating reservation:', error);
      toast.error('Failed to add new member' + error.message);
    }

    handleModalClose();
  };

  const determineRegistrationStatus = (registered, toRegister) => {
    if (registered === toRegister) {
      return 'COMPLETE';
    }
    return 'PENDING';
  };

  const handleMemberDelete = async (index) => {
    try {
      const updatedMembers = reservation.guestRegistration.members.filter((_, i) => i !== index);
      const nbre_guest_to_register = reservation.guestRegistration.nbre_guest_to_register || 0;
      const nbre_guest_registered = updatedMembers.length;

      const updatedReservation = {
        ...reservation,
        atSojori: reservation.atSojori,
        sojoriId: reservation.sojoriId,
        arrivalDate: formatDate(reservation.arrivalDate),
        departureDate: formatDate(reservation.departureDate),
        guestRegistration: {
          ...reservation.guestRegistration,
          nbre_guest_registered: nbre_guest_registered,
          members: updatedMembers,
          registration_status: determineRegistrationStatus(nbre_guest_registered, nbre_guest_to_register)
        }
      };

      const response = await updateReservation(id, updatedReservation);
      if (response.success) {
        setReservation(updatedReservation);
        console.log('Member deleted successfully');
        toast.success('Member deleted successfully');
      } else {
        console.error('Delete failed:', response);
        toast.error('Failed to delete member' + response.message);
      }
    } catch (error) {
      console.error('Error deleting member:', error);
      toast.error('Failed to delete member' + error.message);
    }
  };

  const handleMemberUpdate = (index, updatedMember) => {
    setReservation(prevReservation => {
      const updatedMembers = prevReservation.guestRegistration.members.map((member, i) =>
        i === index ? { ...member, ...updatedMember } : member
      );

      const registrationStatus = determineRegistrationStatus();

      return {
        ...prevReservation,
        guestRegistration: {
          ...prevReservation.guestRegistration,
          members: updatedMembers,
          registration_status: registrationStatus
        }
      };
    });
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!reservation) return <p>No reservation data available.</p>;

  return (
    <DashboardLayout>
      <ToastContainer />
      <Box sx={{ width: '100%', typography: 'body1', bgcolor: 'background.paper', mb: 2 }}>
          <div id="fixed_control_bar" className="w-full">
            <div className="flex justify-between items-center p-4">
              <h5>
                <div className="flex items-center text-[#00b4b4] cursor-pointer" onClick={() => navigate(-1)}>
                  <span className="ml-2 text-[16px]"><i className="pi pi-angle-left !text-[16px] font-bold"></i>Back to Reservations</span>
                </div>
              </h5>
            </div>
          </div>
        </Box>
      <div className="p-6 card">
        <Typography variant="h6" className="mb-4 p-2 bg-[#ECECF8]">
          Check-in online: {reservation.arrivalDate ? reservation.arrivalDate.split('T')[0] : 'N/A'}
        </Typography>

        {reservation.guestRegistration && reservation.guestRegistration.members &&
          reservation.guestRegistration.members.length > 0 ? (
          reservation.guestRegistration.members.map((guest, index) => (
            <Guest
              key={index}
              name={`${guest.first_name} ${guest.last_name} (${guest.registration})`}
              initialData={formatGuestData(guest)}
              reservationId={id}
              index={index}
              onUpdate={handleMemberUpdate}
              onDelete={handleMemberDelete}
              reservationDetails={{
                atSojori: reservation.atSojori,
                sojoriId: reservation.sojoriId,
                arrivalDate: reservation.arrivalDate,
                departureDate: reservation.departureDate,
                numberOfGuests: reservation.numberOfGuests
              }}
            />
          ))
        ) : (
          <p className="mx-4">No guest information available.</p>
        )}
        <div className="p-3 text-center">
          <Button
            variant="contained"
            className="!w-40 text-white !bg-medium-aquamarine"
            onClick={handleModalOpen}
          >
            REGISTER GUEST
          </Button>
        </div>

        <RegisterGuestModal
          open={isModalOpen}
          handleClose={handleModalClose}
          handleFormSubmit={handleGuestRegistration}
        />
      </div>
    </DashboardLayout>
  );
}

function formatGuestData(guest) {
  let day = '', month = '', year = '';
  if (guest.date_of_birth) {
    const birthDate = new Date(guest.date_of_birth);
    if (!isNaN(birthDate.getTime())) {
      day = birthDate.getDate().toString().padStart(2, '0');
      month = (birthDate.getMonth() + 1).toString().padStart(2, '0');
      year = birthDate.getFullYear().toString();
    }
  }

  return {
    name: guest.first_name || '',
    surname: guest.last_name || '',
    day: day,
    month: month,
    year: year,
    gender: guest.gender || '',
    nationality: guest.nationality || '',
    documentType: guest.document_type || '',
    documentNumber: guest.document_number || '',
    documentCountry: guest.country_of_residence || '',
    email: guest.email || '',
    phone: guest.phone || '',
    document_front_download: guest.document_front_download || '',
    document_back_download: guest.document_back_download || ''
  };
}

export default CheckinDetails;
