import * as Icons from '@mui/icons-material';

export const eventConfig = {
  alarm_heard: { icon: 'VolumeUp', color: 'error' },
  avg_sound_high: { icon: 'VolumeUp', color: 'warning' },
  battery_empty: { icon: 'BatteryAlert', color: 'error' },
  battery_low: { icon: 'BatteryChargingFull', color: 'warning' },
  device_offline: { icon: 'SignalWifiOff', color: 'error' },
  device_online: { icon: 'SignalWifi4Bar', color: 'success' },
  device_power_off: { icon: 'PowerOff', color: 'error' },
  disturbance_dismissed: { icon: 'CheckCircle', color: 'success' },
  disturbance_dispatched_automatically: { icon: 'Autorenew', color: 'info' },
  disturbance_dispatched_manually: { icon: 'PersonAdd', color: 'warning' },
  disturbance_ended: { icon: 'Stop', color: 'success' },
  disturbance_first_notice: { icon: 'Notifications', color: 'info' },
  disturbance_second_notice: { icon: 'NotificationsPaused', color: 'warning' },
  disturbance_snoozed: { icon: 'Snooze', color: 'info' },
  disturbance_third_notice: { icon: 'NotificationsActive', color: 'warning' },
  glassbreak: { icon: 'BrokenImage', color: 'error' },
  humidity_dropped_normal: { icon: 'Cloud', color: 'success' },
  humidity_high: { icon: 'CloudOff', color: 'error' },
  humidity_low: { icon: 'CloudQueue', color: 'warning' },
  humidity_risen_normal: { icon: 'Cloud', color: 'success' },
  pir_motion: { icon: 'Visibility', color: 'warning' },
  response_service_dispatch_arrived: { icon: 'LocalTaxi', color: 'success' },
  response_service_dispatch_cancelled_by_minut_while_in_progress: { icon: 'Cancel', color: 'secondary' },
  response_service_dispatch_cancelled_by_minut_while_requested: { icon: 'Cancel', color: 'secondary' },
  response_service_dispatch_cancelled_by_provider: { icon: 'Cancel', color: 'secondary' },
  response_service_dispatch_cancelled_by_user: { icon: 'Cancel', color: 'secondary' },
  risk_of_mould: { icon: 'Warning', color: 'warning' },
  short_button_press: { icon: 'TouchApp', color: 'info' },
  smoking_detection_smoking_detected: { icon: 'SmokeFree', color: 'error' },
  sound_level_dropped_normal: { icon: 'VolumeMute', color: 'success' },
  tamper: { icon: 'Warning', color: 'warning' },
  tamper_mounted: { icon: 'Settings', color: 'warning' },
  tamper_removed: { icon: 'Settings', color: 'warning' },
  temperature_dropped_normal: { icon: 'AcUnit', color: 'success' },
  temperature_high: { icon: 'Thermostat', color: 'error' },
  temperature_low: { icon: 'Thermostat', color: 'warning' },
  temperature_risen_normal: { icon: 'AcUnit', color: 'success' },
};

export const getEventIcon = (type) => {
  const IconComponent = Icons[eventConfig[type]?.icon || 'Notifications'];
  return <IconComponent />;
};

export const getEventColor = (type) => {
  return eventConfig[type]?.color || 'info';
};