import * as Yup from 'yup';

const validationSchema = Yup.object({
  // Basic Info
  // externalListingName: Yup.string().required('External Listing Name is required'),
  personCapacity: Yup.number(),
  propertyType: Yup.string().required('Property Type is required'),
  // roomType: Yup.string().required('Room Type is required'),
  // bedroomsNumber: Yup.number(),
  // bedsNumber: Yup.number(),
  // bathroomsNumber: Yup.number(),
  // bathroomType: Yup.string().required('Bathroom Type is required'),
  guestBathroomsNumber: Yup.number(),
  tags: Yup.array(),
  description: Yup.string(),

  // Media
  listingImages: Yup.array()
    .of(Yup.object().required('image is required'))
    .min(1, 'At least one image is required')
    .required('images are required'),

  // Amenities
  // listingAmenities: Yup.array().of(
  //     Yup.object({
  //         amenityId: Yup.string().required('Amenity ID is required'),
  //         amenityName: Yup.string().required('Amenity name is required')
  //     })
  // ).min(1, 'At least one amenity is required').required('Amenities are required'),

  listingAmenitiesIds: Yup.array()
    .of(Yup.string().required('Amenity ID is required'))
    .min(1, 'At least one amenity is required')
    .required('Amenities are required'),

  // room type
  roomTypes: Yup.array()
    .of(
      Yup.object({
        roomTypeConfigId: Yup.string().required('Room Type is required'),
        roomTypeName: Yup.string(),
        basePrice: Yup.number().required('price is required'),
        ratePlanIds: Yup.array(),
        amenitiesIds: Yup.array(),
        roomTypeImages: Yup.array()
          // .max(10, 'Maximum of 10 images allowed per room type')
          .of(
            Yup.object({
              url: Yup.string().required('Image URL is required'),
              sortOrder: Yup.number().required('Sort order is required'),
            }),
          ),
        useAddress: Yup.boolean(),
        active: Yup.boolean(),
        personCapacity: Yup.number(),
        bedroomsNumber: Yup.number(),
        bedsNumber: Yup.number(),
        bathroomsNumber: Yup.number(),
        roomNumber: Yup.number().required('room number is required'),
        startCode: Yup.number().required('start Code is required'),
        ranking: Yup.number(),
        rooms: Yup.array().of(
          Yup.object({
            roomNumber: Yup.number(),
            roomTypeName: Yup.string(),
            roomCode: Yup.string(),
            address: Yup.string(),
            enabled: Yup.boolean(),
          }),
        ),
      }),
    )
    .required('roomTypes is required'),

  // BedTypes
  // listingBedTypes: Yup.array().of(
  //     Yup.object({
  //         bedroomNumber: Yup.string(),
  //         bedTypeId: Yup.number().required(),
  //         quantity: Yup.number().required('quantity is required'),
  //     })
  // ).min(1, 'At least one bed type is required').required(),

  // Additional Info
  checkOutTime: Yup.string().required('Check-in time is required'),
  checkInTimeStart: Yup.string().required('Check-out time is required'),
  checkInTimeEnd: Yup.string(),
  minNights: Yup.number().required('Minimum nights is required'),
  maxNights: Yup.number().required('Maximum nights is required'),

  // Channel specific
  // homeawayPropertyName: Yup.string().required('Property Name is required'),
  // bookingcomPropertyName: Yup.string().required('Property Name is required'),
  // bookingcomPropertyDescription: Yup.string().required('Property Description is required'),
  // homeawayPropertyDescription: Yup.string().required('Property Description is required'),

  // Contact Person
  // contactName: Yup.string(),
  // contactSurName: Yup.string(),
  // contactPhone1: Yup.string(),
  // contactEmail: Yup.string(),
  // contactLanguage: Yup.string().required('Contact language is required'),
  taxId: Yup.string(),

  // Adress
  address: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  cityId: Yup.string().required(),
  country: Yup.string().required('country is required'),
  lat: Yup.number().required('Lat is required'),
  lng: Yup.number().required('lng is required'),

  // License Info
  // propertyLicenseNumber: Yup.string(),
  // propertyLicenseIssueDate: Yup.date(),
  // propertyLicenseType: Yup.string(),
  // propertyLicenseExpirationDate: Yup.date(),
  // registeredAtTradeRegister: Yup.boolean(),
  // vatRegistered: Yup.boolean(),
  // declareRevenues: Yup.boolean(),

  // Custom Fields
  // hidden: Yup.string()
  //     .oneOf(['yes', 'no'], 'Hidden must be either "yes" or "no"'),

  // isChanged: Yup.string(),

  //  Price
  // price: Yup.number().required('Price is required'),
  // currencyCode: Yup.string().required('Currency is required'),

  // Cleaning
  cleanPrice: Yup.number().required('Price is required'),
  cleanNumber: Yup.number()
    .positive('Clean number must be a positive number')
    .required('Clean number is required'),

  RECEPT: Yup.string().required('Recept is required'),

  // TS_CLEAN validation
  //   TS_CLEAN: Yup.array().of(
  //     Yup.object({
  //       start: Yup.number().required('Start time is required'),
  //       end: Yup.number().required('End time is required'),
  //       type: Yup.string().required('Type is required'),
  //       price: Yup.number().min(0, 'Price must be non-negative').required('Price is required'),
  //       default: Yup.boolean().required('Default flag is required')
  //     }).test('start-less-than-end', 'Start time must be less than end time', function(value) {
  //       return value.start < value.end;
  //     })
  //   ).required('TS_CLEAN is required'),

  // TS_RECEPT validation
  //   TS_RECEPT: Yup.array().of(
  //     Yup.object({
  //       start: Yup.number().required('Start time is required'),
  //       end: Yup.number().required('End time is required'),
  //       type: Yup.string().required('Type is required'),
  //       price: Yup.number().min(0, 'Price must be non-negative').required('Price is required'),
  //       default: Yup.boolean().required('Default flag is required')
  //     }).test('start-less-than-end', 'Start time must be less than end time', function(value) {
  //       return value.start < value.end;
  //     })
  //   ).required('TS_RECEPT is required'),

  // Frequency validation
  frequency: Yup.array()
    .of(
      Yup.object({
        startDay: Yup.number().required('Start day is required'),
        endDay: Yup.number().required('End day is required'),
        numberOfCleaning: Yup.number()
          .min(0, 'Number of cleanings must be non-negative')
          .required('Number of cleanings is required'),
      }).test(
        'start-less-than-end',
        'Start day must be less than end day',
        function (value) {
          return value.startDay < value.endDay;
        },
      ),
    )
    .test(
      'ordered-ranges',
      'Frequency ranges must be in order and not overlapping',
      function (value) {
        if (!value) return true;
        for (let i = 1; i < value.length; i++) {
          if (value[i].startDay <= value[i - 1].endDay) {
            return this.createError({
              path: `frequency[${i}].startDay`,
              message: `Start day must be greater than the previous end day`,
            });
          }
        }
        return true;
      },
    )
    .required('Frequency is required'),

  // Add validation for other form sections...
});

export default validationSchema;
