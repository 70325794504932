import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  Typography
} from '@mui/material';

function DeleteConfirmationModal({ open, onClose, onConfirm, projectName }) {
  const [deleteUnits, setDeleteUnits] = useState(false);

  const handleConfirm = () => {
    onConfirm(deleteUnits);
    setDeleteUnits(false);
  };

  const handleClose = () => {
    setDeleteUnits(false);
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      className="!rounded-lg"
    >
      <DialogTitle className="!bg-gray-50 !text-xl !font-bold !text-gray-800 !border-b !border-gray-200 !py-4 !px-6">
        Confirm Delete
      </DialogTitle>
      <DialogContent className="!p-6">
        <Typography className="!text-gray-700 !mb-4 !font-medium">
          Are you sure you want to delete {projectName || 'this project'}?
        </Typography>
        <FormControlLabel
          className="!text-gray-600"
          control={
            <Checkbox
              checked={deleteUnits}
              onChange={(e) => setDeleteUnits(e.target.checked)}
              className="!text-medium-aquamarine"
            />
          }
          label="Also delete all associated units"
        />
      </DialogContent>
      <DialogActions className="!bg-gray-50 !p-4 !border-t !border-gray-200">
        <Button 
          onClick={handleClose} 
          className="!bg-gray-200 !text-gray-700 !px-6 !py-2 !rounded-md !font-medium !hover:bg-gray-300"
        >
          Cancel
        </Button>
        <Button 
          onClick={handleConfirm} 
          className="!bg-red-500 !text-white !px-6 !py-2 !rounded-md !font-medium !hover:bg-red-600 !ml-3"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteConfirmationModal;