import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import 'assets/css/global.css';
import store from "./redux/store"
import { Provider } from "react-redux";
import { ClerkProvider } from "@clerk/clerk-react";
import { MaterialUIControllerProvider } from "context";

// const clerkPubKey = "pk_test_cHJlcGFyZWQtZ29waGVyLTIwLmNsZXJrLmFjY291bnRzLmRldiQ";
const clerkPubKey = "pk_live_Y2xlcmsuc29qb3JpLmNvbSQ";

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <ClerkProvider publishableKey={clerkPubKey}>
    <Provider store={store}>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <App />
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </Provider>
  </ClerkProvider>
);