import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InfoIcon from '@mui/icons-material/Info';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ImageIcon from '@mui/icons-material/Image';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DescriptionIcon from '@mui/icons-material/Description';
import LanguageIcon from '@mui/icons-material/Language';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import HotelIcon from '@mui/icons-material/Hotel';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SyncIcon from '@mui/icons-material/Sync';
import HandshakeIcon from '@mui/icons-material/Handshake';
import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import DoorbellIcon from '@mui/icons-material/Doorbell';

export const tabOrder = [
  'basicinfo',
  'address',
  'media',
  'amenities',
  'standardSojori',
  // 'price',
  'additionalinfo',
  // 'bedtypes',
  'channelmanager',
  'task',
  'accessinfo',
  'accessdetails',
  'appartmentinformation',
  'message',
  'minutListing',
  'roomtype',
  'rooms',
  'configuration',
  'moreinfo',
];

export const tabIcons = {
  basicinfo: <InfoIcon className="!text-[18px] !mr-1" />,
  address: <LocationOnIcon className="!text-[18px] !mr-1" />,
  media: <ImageIcon className="!text-[18px] !mr-1" />,
  amenities: <LocalOfferIcon className="!text-[18px] !mr-1" />,
  standardSojori: <HandshakeIcon className="!text-[18px] !mr-1" />,
  // price: <AttachMoneyIcon className="!text-[18px] !mr-1" />,
  additionalinfo: <DescriptionIcon className="!text-[18px] !mr-1" />,
  attachment: <AttachFileIcon className="!text-[18px] !mr-1" />,
  financialsettings: <AccountBalanceIcon className="!text-[18px] !mr-1" />,
  // bedtypes: <HotelIcon className="!text-[18px] !mr-1" />,
  licenseinfo: <AssignmentIcon className="!text-[18px] !mr-1" />,
  channelmanager: <SyncIcon className="!text-[18px] !mr-1" />,
  task: <CalendarMonthIcon className="!text-[18px] !mr-1" />,
  accessinfo: <SwitchAccessShortcutAddIcon className="!text-[18px] !mr-1" />,
  accessdetails: <SwitchAccessShortcutIcon className="!text-[18px] !mr-1" />,
  appartmentinformation: <PrivacyTipIcon className="!text-[18px] !mr-1" />,
  message: <DescriptionIcon className="!text-[18px] !mr-1" />,
  minutListing: <HandshakeIcon className="!text-[18px] !mr-1" />,
  roomtype: <DoorbellIcon className="!text-[18px] !mr-1" />,
  rooms: <WindowOutlinedIcon className="!text-[18px] !mr-1" />,
  configuration: <WindowOutlinedIcon className="!text-[18px] !mr-1" />,
  moreinfo: <InfoIcon className="!text-[18px] !mr-1" />,
};

