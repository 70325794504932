import React, { useState } from 'react';
import {
    Box,
    IconButton,
    Chip,
    Popover,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
    Paper,
} from '@mui/material';
import { ExpandMore, Close } from '@mui/icons-material';

const FilterItems = ({ selectedItems, options, onItemsChange }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleToggle = (value) => {
        const currentIndex = selectedItems.indexOf(value);
        const newSelectedItems = [...selectedItems];
        if (currentIndex === -1) {
            newSelectedItems.push(value);
        } else {
            newSelectedItems.splice(currentIndex, 1);
        }
        onItemsChange(newSelectedItems);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'items-popover' : undefined;

    return (
        <Box >
            <Box display="flex" alignItems="center">
                <Chip
                    label={`${selectedItems.join(', ')}`}
                    onClick={handleClick}
                    onDelete={() => onItemsChange([])}
                    deleteIcon={<Close />}
                    variant="outlined"
                    sx={{  maxWidth: 160, overflow: 'hidden',borderRadius: '5px',height: 41}}
                    
                />
                <IconButton size="small" onClick={handleClick}>
                    <ExpandMore />
                </IconButton>
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    sx: {
                        backgroundColor: 'white',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    }
                }}
            >
                <Paper elevation={0}>
                    <List sx={{ width: 200}} className='!p-1'>
                        {options.map((option) => (
                            <ListItem key={option} dense button onClick={() => handleToggle(option)}>
                               
                                    <Checkbox
                                        edge="start"
                                        checked={selectedItems.indexOf(option) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        className='!text-teal-600'
                                    />
                                
                                <ListItemText primary={option} />
                            </ListItem>
                        ))}
                    </List>
                </Paper>
            </Popover>
        </Box>
    );
};

export default FilterItems;