import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Switch,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    TextField,
    Box,
    Typography,
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createCurrency, updateCurrency } from '../services/serverApi.adminConfig';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
    currencyName: Yup.string().required('Currency name is required'),
    currencyCode: Yup.string().required('Currency code is required'),
    currencySymbol: Yup.string().required('Currency symbol is required'),
    languageId: Yup.array().of(Yup.string()).required('At least one language is required'),
    useInTranslate: Yup.boolean().required('Use in translate flag is required'),
    min: Yup.number().required('Minimum value is required').min(0, 'Minimum value cannot be negative'),
    max: Yup.number().required('Maximum value is required').min(Yup.ref('min'), 'Maximum value cannot be less than minimum value'),
});

const CurrencyModal = ({ open, onClose, setCurrencies, currency, languages }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        setIsLoading(true);
        setErrorMessage('');
        const formData = {
            currencyName: values.currencyName,
            currencyCode: values.currencyCode,
            currencySymbol: values.currencySymbol,
            languageId: values.languageId,
            useInTranslate: values.useInTranslate,
            defaultCurrency: values.defaultCurrency,
            min: values.min,
            max: values.max,
        };
        try {
            let response;
            if (currency) {
                response = await updateCurrency(currency._id, formData);
            } else {
                response = await createCurrency(formData);
            }

            if (response && response.currency) {
                setCurrencies(prevCurrencies => {
                    if (!prevCurrencies) return [response.currency];
                    if (currency) {
                        return prevCurrencies.map(c => c._id === currency._id ? response.currency : c);
                    } else {
                        return [...prevCurrencies, response.currency];
                    }
                });
                setSubmitting(false);
                onClose();
                toast.success(currency ? 'Currency updated successfully' : 'Currency created successfully');
            } else {
                throw new Error('Unexpected response structure');
            }
        } catch (error) {
            console.error('Error:', error);
            if (error.response && error.response.data && error.response.data.message) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage(error.message || 'An error occurred while processing your request.');
            }
            setSubmitting(false);
            toast.error(currency ? 'Error updating currency' : 'Error creating currency');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{currency ? 'Update Currency' : 'Create New Currency'}</DialogTitle>
            <DialogContent>
                {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                <Formik
                    initialValues={{
                        currencyName: currency ? currency.currencyName : '',
                        currencyCode: currency ? currency.currencyCode : '',
                        currencySymbol: currency ? currency.currencySymbol : '',
                        languageId: currency ? currency.languageId : [],
                        useInTranslate: currency ? currency.useInTranslate : false,
                        defaultCurrency: currency ? currency.defaultCurrency : false,
                        min: currency ? currency.min : '',
                        max: currency ? currency.max : '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ isSubmitting, setFieldValue, values, errors }) => (
                        <Form className="mt-2">
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Field name="currencyName">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Currency Name"
                                            fullWidth
                                            error={Boolean(errors.currencyName)}
                                            helperText={errors.currencyName}
                                        />
                                    )}
                                </Field>
                                <Field name="currencyCode">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Currency Code"
                                            fullWidth
                                            error={Boolean(errors.currencyCode)}
                                            helperText={errors.currencyCode}
                                        />
                                    )}
                                </Field>
                                <Field name="currencySymbol">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Currency Symbol"
                                            fullWidth
                                            error={Boolean(errors.currencySymbol)}
                                            helperText={errors.currencySymbol}
                                        />
                                    )}
                                </Field>
                                <Field name="min">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Min Value"
                                            type="number"
                                            fullWidth
                                            error={Boolean(errors.min)}
                                            helperText={errors.min}
                                        />
                                    )}
                                </Field>
                                <Field name="max">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Max Value"
                                            type="number"
                                            fullWidth
                                            error={Boolean(errors.max)}
                                            helperText={errors.max}
                                        />
                                    )}
                                </Field>
                                <FormControl fullWidth>
                                    <InputLabel id="languageId-label">Languages</InputLabel>
                                    <Select
                                        labelId="languageId-label"
                                        multiple
                                        value={values.languageId}
                                        onChange={(e) => setFieldValue('languageId', e.target.value)}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={languages.find(lang => lang._id === value)?.name || value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        {languages.map((language) => (
                                            <MenuItem key={language._id} value={language._id}>
                                                {language.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <ErrorMessage name="languageId" component="div" className="error-message" />
                                </FormControl>
                                <div className="flex gap-4 items-center justify-center">
                                <Field name="useInTranslate">
                                    {({ field }) => (
                                        <div>
                                            <Switch
                                                {...field}
                                                checked={field.value}
                                                onChange={(event) => setFieldValue('useInTranslate', event.target.checked)}
                                            />
                                            Use in Translate
                                        </div>
                                    )}
                                </Field>
                                <div className="w-px h-6 bg-gray-400"></div>

                                <Field name="defaultCurrency">
                                    {({ field }) => (
                                        <div>
                                            <Switch
                                                {...field}
                                                checked={field.value}
                                                onChange={(event) => setFieldValue('defaultCurrency', event.target.checked)}
                                            />
                                            Default Currency
                                        </div>
                                    )}
                                </Field>
                                </div>
                            </Box>
                            <DialogActions>
                                <Button onClick={onClose} color="primary">
                                    Cancel
                                </Button>
                                <Button type="submit" color="primary" disabled={isSubmitting || isLoading}>
                                    {currency ? 'Update' : 'Create'}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default CurrencyModal;
