import React, { useState } from 'react';
import GlobalTable from 'components/GlobalTable/GlobalTable';
// import FilterSection from './FilterSection';
import ViewToggle from './ViewToggle';
import expensesSvg from 'assets/images/expenses.svg';



const ReservationTable = () => {
  // const [filters, setFilters] = useState({
  //   from: '2024-07-01',
  //   to: '2024-07-19',
  //   days: true,
  // });
  const [view, setView] = useState('reservation');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  // const [showFilters, setShowFilters] = useState(true); 

  const columns = [
    { field: 'listing', header: 'Listing default order' },
    { field: 'nightsAvailable', header: 'Nights available' },
    { field: 'nightsBooked', header: 'Nights booked' },
    { field: 'ownerStayNights', header: 'Owner-stay nights' },
    { field: 'occupancyRate', header: 'Occupancy rate' },
    { field: 'totalCheckins', header: 'Total check-ins' },
  ];
  
  const data = [
    // Your data here
  ];

  // const handleFilterChange = (key, value) => {
  //   if (key === 'reset') {
  //     setFilters({
  //       from: '2024-07-01',
  //       to: '2024-07-19',
  //       days: true,
  //     });
  //   } else {
  //     setFilters(prevFilters => ({ ...prevFilters, [key]: value }));
  //   }
  // };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    setIsNextDisabled(newPage * limit >= data.length);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setPage(0);
  };

  // const toggleFilters = () => {
  //   setShowFilters(prev => !prev);
  // };

  return (
    <>
      <ViewToggle view={view} setView={setView}  />
      {data.length === 0 ? (
        <div className="flex items-center justify-center flex-col !m-12">
        <img src={expensesSvg}alt="CHERGES" />
        <div className="text-center py-4">No expenses or extras found</div>
        </div>
      ) : (
        <GlobalTable
          data={data}
          columns={columns}
          hasPagination={true}
          page={page}
          onPageChange={handlePageChange}
          isNextDisabled={isNextDisabled}
          limit={limit}
          onLimitChange={handleLimitChange}
          rowsPerPageOptions={[5, 10, 20, 50]}
        />
      )}
    </>
  );
};

export default ReservationTable;
