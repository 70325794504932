import React, { useState, useEffect } from 'react';
import { getUnits, deleteUnit } from '../services/serverApi.ProjectUnits';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import UnitModal from '../components/UnitModal';
import { toast, ToastContainer } from 'react-toastify';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { CircularProgress, Typography, Button, Tooltip } from '@mui/material';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import { useNavigate } from 'react-router-dom';
import ImageGalleryTrigger from 'components/ImageGalleryModal/ImageGalleryModal';

function Units() {
    const [isLoading, setIsLoading] = useState(true);
    const [units, setUnits] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();


    useEffect(() => {
        fetchUnits();
    }, [page, limit]);

    const handleOpenCreateEdit = (unit = null) => {
        if (unit) {
            navigate(`/admin/units/edit/${unit._id}`);
        } else {
            navigate('/admin/units/create');
        }
    };

    const fetchUnits = async () => {
        try {
            const response = await getUnits({ page, limit });
            if (response.data && response.data.units && Array.isArray(response.data.units)) {
                const normalizedUnits = response.data.units.map(unit => ({
                    ...unit,
                    id: unit.id || unit._id,
                    _id: unit._id || unit.id
                }));
                setUnits(normalizedUnits);
                setTotal(response.data.total);
            } else {
                throw new Error('Invalid response format');
            }
        } catch (error) {
            console.error('Error fetching units:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleOpenModal = (unit = null) => {
        setSelectedUnit(unit);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedUnit(null);
        setModalOpen(false);
    };

    const handleDeleteUnit = async (unitId) => {
        if (window.confirm('Are you sure you want to delete this unit?')) {
            try {
                await deleteUnit(unitId);

                setUnits(prevUnits => prevUnits.filter(unit =>
                    unit.id !== unitId && unit._id !== unitId
                ));

                toast.success('Unit deleted successfully');

            } catch (error) {
                console.error('Error deleting unit:', error);
                toast.error(error.response?.data?.message || 'Failed to delete unit');
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (newLimit) => {
        setLimit(newLimit);
        setPage(0);
    };

    const columns = [
        {
            header: "Images",
            body: (rowData) => (
                <ImageGalleryTrigger images={rowData.images} />

            )
        },

        { header: "Unit Name", body: (rowData) => <span>{rowData.unitName}</span> },
        { header: "Property Type", body: (rowData) => <span>{rowData.propertyType}</span> },
        { header: "City", body: (rowData) => <span>{rowData.city}</span> },
        { header: "District", body: (rowData) => <span>{rowData.district}</span> },
        {
            header: "Area Details",
            body: (rowData) => (
                <div>
                    <div>Total: {rowData.totalArea}m²</div>
                    <div>Living: {rowData.livingArea}m²</div>
                    {rowData.balcony && <div>Balcony: {rowData.balconyArea}m²</div>}
                </div>
            )
        },
        {
            header: "Price",
            body: (rowData) => (
                <div>
                    <div>Sale: {rowData.salePrice.toLocaleString()}</div>
                    <div>Per m²: {rowData.pricePerSqm.toLocaleString()}</div>
                </div>
            )
        },
        {
            header: "Features",
            body: (rowData) => (
                <div className="space-y-1">
                    <div>Rooms: {rowData.numberOfRooms}</div>
                    <div>Bedrooms: {rowData.numberOfBedrooms}</div>
                    <div>Bathrooms: {rowData.numberOfBathrooms}</div>
                </div>
            )
        },
        {
            header: "Unit Visibility",
            body: (rowData) => (
                <div className="flex flex-col items-start">
                    {rowData.projectId ? (
                        <>
                            <Tooltip
                                title={
                                    rowData.displayAsUnit
                                        ? "This unit is currently visible and included in the project listing"
                                        : "This unit is currently hidden from the project listing"
                                }
                                placement="top"
                            >
                                <div className="cursor-pointer">
                                    {rowData.displayAsUnit ? (
                                        <span className="text-green-500 font-bold text-[0.68rem]">
                                            Appears in the project ✓
                                        </span>
                                    ) : (
                                        <span className="text-red-500 font-bold text-[0.68rem]">
                                            Appears in the project ✗
                                        </span>
                                    )}
                                </div>
                            </Tooltip>
                            <Tooltip
                                title={
                                    rowData.displayAsProject
                                        ? "This unit can be listed and viewed independently of its project"
                                        : "This unit cannot be listed as a standalone property"
                                }
                                placement="bottom"
                            >
                                <div className="cursor-pointer">
                                    {rowData.displayAsProject ? (
                                        <span className="text-green-500 font-bold ml-2 text-[0.68rem]">
                                            Appears independent ✓
                                        </span>
                                    ) : (
                                        <span className="text-red-500 font-bold ml-2 text-[0.68rem]">
                                            Appears independent ✗
                                        </span>
                                    )}
                                </div>
                            </Tooltip>
                        </>
                    ) : (
                        <Tooltip title="This is a standalone unit not associated with any project">
                            <span className="text-blue-500 font-bold text-[0.68rem] cursor-pointer">
                                unit independent
                            </span>
                        </Tooltip>
                    )}
                </div>
            )
        },

        {
            header: "Action",
            body: (rowData) => (
                <div className="flex gap-1">
                    <button
                        className="px-2 py-1 bg-medium-aquamarine !rounded-md"
                        onClick={() => handleOpenCreateEdit(rowData)}
                    >
                        <EditOffIcon className="text-white" />
                    </button>
                    <button
                        className="px-2 py-1 bg-[#df5454] !rounded-md"
                        onClick={() => handleDeleteUnit(rowData.id || rowData._id)}
                    >
                        <DeleteSweepIcon className="text-white" />
                    </button>
                </div>
            ),
        }
    ];

    if (error) {
        return <div className="w-full h-64 flex justify-center items-center text-red-500">{error}</div>;
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ToastContainer />
            <div className="card p-4">
                <Typography variant="h4" component="h1" className="mb-4" gutterBottom>
                    Units Management
                </Typography>
                <div className="mb-4">
                    <Button
                        onClick={() => handleOpenCreateEdit()}
                        className="float-right !bg-medium-aquamarine text-white"
                    >
                        Create New Unit
                    </Button>
                </div>
                <div>
                    {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress style={{ color: '#00b4b4' }} />
                        </div>
                    ) : (
                        <GlobalTable
                            data={units}
                            columns={columns}
                            hasPagination={true}
                            page={page}
                            onPageChange={handlePageChange}
                            limit={limit}
                            onLimitChange={handleLimitChange}
                            isNextDisabled={page >= Math.ceil(total / limit) - 1}
                            rowsPerPageOptions={[10, 20, 50]}
                        />
                    )}
                </div>
                <UnitModal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    setUnits={setUnits}
                    unit={selectedUnit}
                />
            </div>
        </DashboardLayout>
    );
}

export default Units;