import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getStandardSojori, updateStandardSojori, deleteStandardSojori } from '../services/serverApi.listing.js';
import { getcities } from '../../setting/services/serverApi.adminConfig.js';
import ModifyStandardSojori from './ModifyStandardSojori.jsx';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import GlobalTable from 'components/GlobalTable/GlobalTable';


function StandardSojori() {
    const [standardSojoriList, setStandardSojoriList] = useState([]);
    const [cities, setCities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openModifyDialog, setOpenModifyDialog] = useState(false);
    const [selectedStandardSojori, setSelectedStandardSojori] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);

    const fetchStandardSojori = async () => {
        setIsLoading(true);
        try {
            const response = await getStandardSojori();
            if (response.data && Array.isArray(response.data.data)) {
                setStandardSojoriList(response.data.data);
            } else {
                setStandardSojoriList([]);
            }
        } catch (error) {
            console.error('Error fetching standard sojori:', error);
            setStandardSojoriList([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCities = async () => {
        try {
            const response = await getcities();
            setCities(response.data);
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    useEffect(() => {
        fetchStandardSojori();
        fetchCities();
    }, []);

    const getCityNames = (cityIds) => {
        if (!cityIds) {
            return [''];
        }
        return cityIds.map((cityId) => {
            const city = cities.find((c) => c._id === cityId);
            return city ? city.name : cityId;
        });
    };

    const handleOpenModifyDialog = (index, standardSojori) => {
        setSelectedStandardSojori(standardSojori);
        setSelectedIndex(index);
        setIsCreating(false);
        setOpenModifyDialog(true);
    };

    const handleCloseModifyDialog = () => {
        setOpenModifyDialog(false);
        setSelectedStandardSojori(null);
        setSelectedIndex(null);
        setIsCreating(false);
    };

    const handleToggleEnabled = async (standardSojori) => {
        try {
            const updatedStandardSojori = { ...standardSojori, enabled: !standardSojori.enabled };
            const response = await updateStandardSojori(standardSojori._id, updatedStandardSojori);

            if (response.data && response.data.data) {
                setStandardSojoriList((prevList) =>
                    prevList.map((item) =>
                        item._id === standardSojori._id ? response.data.data : item,
                    ),
                );
                toast.success('Standard Sojori updated successfully');
            } else {
                throw new Error('Unexpected response structure');
            }
        } catch (error) {
            console.error('Error updating standard sojori:', error);
            toast.error('Error updating standard sojori');
        }
    };

    const handleDelete = async (standardSojoriId) => {
        if (window.confirm('Are you sure you want to delete this Standard Sojori?')) {
            try {
                await deleteStandardSojori(standardSojoriId);
                setStandardSojoriList((prevList) => prevList.filter((item) => item._id !== standardSojoriId));
                toast.success('Standard Sojori deleted successfully');
            } catch (error) {
                console.error('Error deleting standard sojori:', error);
                toast.error('Error deleting standard sojori');
            }
        }
    };

    const columns = [
        {
            field: 'iconUrl',
            header: 'Icon',
            body: (rowData) => (
                <img 
                    src={rowData.iconUrl} 
                    alt={rowData.name} 
                    style={{ width: '70px', height: '70px', objectFit: 'contain' }} 
                />
            ),
            headerStyle: { width: '100px' }
        },
        {
            field: 'name',
            header: 'Name',
            headerStyle: { width: '200px' }
        },
        {
            field: 'cityIds',
            header: 'Cities',
            body: (rowData) => getCityNames(rowData.cityIds).join(', '),
            headerStyle: { width: '200px' }
        },
        {
            field: 'enabled',
            header: 'Enabled',
            body: (rowData) => (
                <Switch 
                    checked={rowData.enabled} 
                    onChange={() => handleToggleEnabled(rowData)} 
                    color="primary" 
                />
            ),
            headerStyle: { width: '100px' }
        },
        {
            field: 'actions',
            header: 'Action',
            body: (rowData) => (
                <>
                    <IconButton 
                        className="!p-[7px] !rounded-lg bg-white shadow-sm"
                        onClick={() => handleOpenModifyDialog(standardSojoriList.indexOf(rowData), rowData)}
                    >
                        <ModeEditOutlineOutlinedIcon style={{ color: '#66cdaa' }} />
                    </IconButton>
                    <IconButton 
                        className="!p-[7px] !rounded-lg bg-white shadow-sm mx-2"
                        onClick={() => handleDelete(rowData._id)}
                    >
                        <DeleteIcon style={{ color: '#ff6b6b' }} />
                    </IconButton>
                </>
            ),
            headerStyle: { width: '100px' }
        }
    ];

    return (
        <>
            <Button 
                onClick={() => {
                    setIsCreating(true);
                    setSelectedStandardSojori({});
                    setOpenModifyDialog(true);
                }}
                className='mb-3 !bg-[#00b4b4]'
            >
                <span className='!text-white'>Create Standard Sojori</span>
            </Button>
            <ToastContainer position="top-right" autoClose={3000} />
            <GlobalTable
                data={standardSojoriList}
                columns={columns}
                page={page}
                onPageChange={setPage}
                isNextDisabled={false}
                hasPagination={false}
                limit={limit}
                onLimitChange={setLimit}
                rowsPerPageOptions={[5, 10, 20, 50]}
            />
            {(selectedStandardSojori || isCreating) && (
                <ModifyStandardSojori
                    open={openModifyDialog}
                    onClose={handleCloseModifyDialog}
                    setStandardSojoriList={setStandardSojoriList}
                    cities={cities}
                    standardSojoriList={standardSojoriList}
                    index={selectedIndex}
                    dataStandardSojori={selectedStandardSojori}
                    isCreating={isCreating}
                />
            )}
        </>
    );
}

export default StandardSojori;