import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { TextField, Select, MenuItem, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import GlobalTable from 'components/GlobalTable/GlobalTable';

const Rooms = ({ formik, types }) => {
    const [editingRoom, setEditingRoom] = useState(null);

  

    const flattenedRooms = useMemo(() => {
        return formik.values.roomTypes.flatMap((roomType, roomTypeIndex) => {
            const type = types.find(t => t._id === roomType.roomTypeConfigId);
            const rooms = roomType.rooms || [];
            return rooms.map((room, roomIndex) => ({
                ...room,
                roomTypeName: type ? type.type : '',
                basePrice: roomType.basePrice,
                personCapacity: roomType.personCapacity,
                roomTypeIndex,
                roomIndex
            }));
        });
    }, [formik.values.roomTypes, types]);

    const handleEdit = useCallback((room) => {
        setEditingRoom(room);
    }, []);

    const handleSave = useCallback(() => {
        formik.setFieldValue('roomTypes', formik.values.roomTypes.map((roomType, typeIndex) => 
            typeIndex === editingRoom.roomTypeIndex
                ? {
                    ...roomType,
                    rooms: roomType.rooms.map((room, roomIndex) => 
                        roomIndex === editingRoom.roomIndex ? editingRoom : room
                    )
                }
                : roomType
        ));
        setEditingRoom(null);
    }, [formik, editingRoom]);

    const handleCancel = useCallback(() => {
        setEditingRoom(null);
    }, []);

    const handleDelete = useCallback((room) => {
        formik.setFieldValue('roomTypes', formik.values.roomTypes.map((roomType, index) => 
            index === room.roomTypeIndex
                ? { ...roomType, rooms: roomType.rooms.filter((_, i) => i !== room.roomIndex) }
                : roomType
        ));
    }, [formik]);

    const handleInputChange = useCallback((e, field) => {
        const value = field === 'enabled' ? e.target.value === 'true' : e.target.value;
        setEditingRoom(prev => ({ ...prev, [field]: value }));
    }, []);

    const columns = useMemo(() => [
        { field: 'roomNumber', header: 'Code' },
        { 
            field: 'roomName', 
            header: 'Name',
            body: (rowData) => 
                editingRoom && editingRoom.roomNumber === rowData.roomNumber ? (
                    <TextField 
                        value={editingRoom.roomName} 
                        onChange={(e) => handleInputChange(e, 'roomName')} 
                        size="small"
                        fullWidth
                    />
                ) : rowData.roomName
        },
        { field: 'roomTypeName', header: 'Type' },
        { 
            field: 'roomCode', 
            header: 'Key Code',
            body: (rowData) => 
                editingRoom && editingRoom.roomNumber === rowData.roomNumber ? (
                    <TextField 
                        value={editingRoom.roomCode} 
                        onChange={(e) => handleInputChange(e, 'roomCode')} 
                        size="small"
                        fullWidth
                    />
                ) : rowData.roomCode
        },
        { 
            field: 'address', 
            header: 'Complement address',
            body: (rowData) => 
                editingRoom && editingRoom.roomNumber === rowData.roomNumber ? (
                    <TextField 
                        value={editingRoom.address} 
                        onChange={(e) => handleInputChange(e, 'address')} 
                        size="small"
                        fullWidth
                    />
                ) : rowData.address
        },
        { 
            field: 'enabled', 
            header: 'Enabled', 
            body: (rowData) => 
                editingRoom && editingRoom.roomNumber === rowData.roomNumber ? (
                    <Select
                        value={editingRoom.enabled.toString()}
                        onChange={(e) => handleInputChange(e, 'enabled')}
                        size="small"
                        fullWidth
                    >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                    </Select>
                ) : (rowData.enabled ? 'Yes' : 'No')
        },
        {
            field: 'actions',
            header: 'Actions',
            body: (rowData) => 
                editingRoom && editingRoom.roomNumber === rowData.roomNumber ? (
                    <div>
                        <IconButton className='!text-blue-400' onClick={handleSave} size="small">
                            <SaveIcon />
                        </IconButton>
                        <IconButton className='!text-red-500' onClick={handleCancel} size="small">
                            <CancelIcon />
                        </IconButton>
                    </div>
                ) : (
                    <div>
                        <IconButton className='!text-teal-500' onClick={() => handleEdit(rowData)} size="small">
                            <EditIcon />
                        </IconButton>
                        <IconButton className='!text-red-500' onClick={() => handleDelete(rowData)} size="small">
                            <DeleteIcon />
                        </IconButton>
                    </div>
                )
        }
    ], [editingRoom, handleEdit, handleSave, handleCancel, handleDelete, handleInputChange]);

    return (
        <div>
            <GlobalTable
                data={flattenedRooms}
                columns={columns}
                hasPagination={false}
            />
        </div>
    );
};

export default React.memo(Rooms);