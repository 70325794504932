import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Box, Typography, Button, CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { uploadImageResetAction, uploadImageToAPI } from '../../../../redux/slices/UploadSlice';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { toast } from 'react-toastify';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import WarningIcon from '@mui/icons-material/Warning';

const MediaContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 8,
  height: 185,
  width: 340,
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: 'white',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}));

const Media = ({ formik }) => {
  const dispatch = useDispatch();
  const upload = useSelector((state) => state.uploadData);
  const { loading, iconUrl, newUpload } = upload;
  const fileInput = useRef(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [uploadedImages, setUploadedImages] = useState({});


  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) return;

    const uploadIndex = editingIndex !== null ? editingIndex : 0;
    setLoadingIndex(uploadIndex);
    dispatch(uploadImageToAPI({ file, folder: 'listing' }));

    const reader = new FileReader();
    reader.onloadend = () => {
      const updatedImages = [...formik.values.listingImages];

      if (editingIndex !== null) {
        const currentSortOrder = updatedImages[editingIndex].sortOrder;
        updatedImages[editingIndex] = {
          ...updatedImages[editingIndex],
          url: reader.result,
          sortOrder: currentSortOrder,
        };
      } else {
        updatedImages.forEach(img => {
          img.sortOrder = img.sortOrder + 1;
        });

        updatedImages.unshift({
          url: reader.result,
          sortOrder: 1,
        });
      }

      formik.setFieldValue('listingImages', updatedImages);
    };
    reader.readAsDataURL(file);
  }, [formik, editingIndex, dispatch]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*,video/mp4',
    maxSize: 52428800,
  });

  const moveImage = useCallback((dragIndex, hoverIndex) => {
    const updatedImages = [...formik.values.listingImages];
    const draggedImage = updatedImages[dragIndex];
    const targetImage = updatedImages[hoverIndex];

    const tempSortOrder = draggedImage.sortOrder;
    draggedImage.sortOrder = targetImage.sortOrder;
    targetImage.sortOrder = tempSortOrder;

    updatedImages.sort((a, b) => a.sortOrder - b.sortOrder);

    formik.setFieldValue('listingImages', updatedImages);
  }, [formik]);

  const handleClick = (index) => {
    setEditingIndex(index);
    fileInput.current.click();
  };

  const handleRemoveFile = (index) => {
    const updatedImages = [...formik.values.listingImages];
    updatedImages[index] = {
      ...updatedImages[index],
      url: '',
    };
    formik.setFieldValue('listingImages', updatedImages);
    setUploadedImages(prev => ({ ...prev, [index]: false }));
  };

  const handleRemove = (index) => {
    const newImages = formik.values.listingImages
      .filter((_, i) => i !== index)
      .map((img, i) => ({ ...img, sortOrder: i + 1 }));
    formik.setFieldValue('listingImages', newImages);
    setUploadedImages(prev => {
      const newUploaded = { ...prev };
      delete newUploaded[index];
      return newUploaded;
    });
  };

  useEffect(() => {
    dispatch(uploadImageResetAction());
  }, [dispatch]);

  useEffect(() => {
    if (iconUrl?.url && loadingIndex !== null) { 
      const updatedImages = [...formik.values.listingImages];
      const currentSortOrder = updatedImages[loadingIndex].sortOrder;
  
      updatedImages[loadingIndex] = {
        ...updatedImages[loadingIndex],
        url: iconUrl.url,  
        sortOrder: currentSortOrder,
      };
    
      formik.setFieldValue('listingImages', updatedImages);
      setUploadedImages(prev => ({ ...prev, [loadingIndex]: true }));
      setLoadingIndex(null);
      setEditingIndex(null);
      dispatch(uploadImageResetAction());
      toast.success('Image uploaded');

    }
  
  }, [iconUrl?.url, loadingIndex, formik, dispatch]);

  const isBase64Image = (url) => {
    return url.startsWith('data:image');
  };

  const DraggableImage = ({ index, image }) => {
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
      accept: 'image',
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        }
      },
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        moveImage(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: 'image',
      item: () => ({ id: image.id, index, sortOrder: image.sortOrder }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const opacity = isDragging ? 0.4 : 1;
    drag(drop(ref));

    const needsUpload = isBase64Image(image.url);

    const handleRetryUpload = () => {
      fetch(image.url)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], "image.jpg", { type: blob.type });
          setLoadingIndex(index);
          dispatch(uploadImageToAPI({ file, folder: 'listing' }));
        })
        .catch(() => {
          toast.error('Failed to retry upload');
        });
    };

    return (
      <Grid item xs={12} sm={6} md={4} lg={3} ref={ref} style={{ opacity }} data-handler-id={handlerId}>
        <Box className="w-80 bg-[#fbfbfb] hover:shadow-lg relative" sx={{ height: '100%' }}>
          <Box className='flex flex-col gap-2 p-4'>
            <button onClick={() => handleRemove(index)} className='absolute top-0 left-0 z-30'>
              <HighlightOffIcon className='text-gray-700 !text-[30px] p-1' />
            </button>
            <Box position="relative">
              <img className="w-full h-52 rounded-sm object-cover" src={image.url} alt="uploaded file" />
              {needsUpload && !(loading && loadingIndex === index) && (
                <Box
                  className="absolute inset-0 bg-black/50 flex items-center justify-center"
                  sx={{ borderRadius: '4px' }}
                >
                  <Button
                    onClick={handleRetryUpload}
                    variant="contained"
                    className="!bg-teal-500 !hover:bg-teal-600 !text-white"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload to Server
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" className="absolute bottom-2 left-0 right-0">
            <Button
              onClick={() => handleClick(index)}
              className='!backdrop-blur-sm !bg-blue-500/60 !shadow-md'
              sx={{ borderRadius: '12px', minWidth: 'auto', margin: '5px 0 0 1px', color: 'white !important' }}
            >
              <EditIcon />
            </Button>
            <Box mx={0.5}>
              <Button
                onClick={() => handleRemoveFile(index)}
                className='!backdrop-blur-sm !bg-red-500/60 !shadow-md'
                sx={{ borderRadius: '12px', minWidth: 'auto', margin: '5px 0 0 1px', color: 'white !important' }}
              >
                <CloseIcon />
              </Button>
            </Box>
            <Box mx={0.5}>
              <Button
                className={`!bg-${needsUpload ? 'yellow' : 'green'}-500`}
                sx={{ borderRadius: '12px', minWidth: 'auto', margin: '5px 0 0 1px', color: 'white !important' }}
                disabled={true}
              >
                {loading && loadingIndex === index ? (
                  <CircularProgress size={20} className='text-teal-700' />
                ) : needsUpload ? (
                  <WarningIcon className='text-yellow-500' />
                ) : (
                  <CheckIcon className='text-white' />
                )}
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
    );

  };

  return (
    <Box className="w-full flex justify-start relative">
      {formik.touched.listingImages && formik.errors.listingImages && (
        <div className='absolute right-8 top-5'>
          <span className="text-red-500 text-sm">{formik.errors.listingImages}</span>
        </div>
      )}
      <Box className="mt-3 w-full">
        <Box className="m-4">
          <Typography variant="h5" gutterBottom>
            Media
          </Typography>
          <Typography variant="body2" className="pb-4 !text-[13px]">
            Minimum size requirement for photos: 1024 x 683 pixels
          </Typography>
          <Typography variant="body2" className="!text-[14px] pb-2">
            Videos appear only in the booking engine. Accepted format is mp4 up to 50Mb.
          </Typography>
          <DndProvider backend={HTML5Backend}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <MediaContainer {...getRootProps()}>
                <input {...getInputProps()} ref={fileInput} style={{ display: 'none' }} />
                {isDragActive ? (
                  <Typography className='!text-[17px]'>Drag the media and drop it here...</Typography>
                ) : (
                  <Typography className='!text-[17px]'>Drag the media and drop it here</Typography>
                )}
              </MediaContainer>
            </Grid>
            <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
              {formik.values.listingImages
                .slice()
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((image, index) => (
                  <DraggableImage key={image.id || index} index={index} image={image} />
                ))}
            </Box>
          </DndProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default Media;