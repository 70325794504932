import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Checkbox, FormControlLabel, IconButton, Box, Typography, Select, MenuItem, Switch, CircularProgress } from '@mui/material';
import { X } from 'lucide-react';
import { updateCalnedar } from '../../services/serverApi.calendar';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

const BulkUpdateDialog = ({ isOpen, onClose, onUpdate, selectedDay }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [initialValues, setInitialValues] = useState({
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(30, 'days').format('YYYY-MM-DD'),
        days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        updateFields: [],
        minimumStay: '',
        maximumStay: '',
        isAvailable: true,
        price: '',
        listingId: '',
        status: 'available',
    });

    console.log('selectedDay:', selectedDay);

    useEffect(() => {
        if (selectedDay && selectedDay.allListingsDayData && selectedDay.allListingsDayData.length > 0) {
            const availableListings = selectedDay.allListingsDayData;
            if (availableListings.length > 0) {
                setInitialValues({
                    ...initialValues,
                    listingId: selectedDay.property.id,
                    startDate: moment(selectedDay.date).format('YYYY-MM-DD'),
                    endDate: moment(selectedDay.date).format('YYYY-MM-DD'),
                    minimumStay: selectedDay.dayData?.minimumStay?.toString() || '',
                    maximumStay: selectedDay.dayData?.maximumStay?.toString() || '',
                    price: selectedDay.dayData?.price?.toString() || '',
                    isAvailable: selectedDay.dayData?.isAvailable ?? true,
                    status: selectedDay.dayData?.status || 'available',
                });
            }
        }
    }, [selectedDay]);

    const validationSchema = Yup.object().shape({
        listingId: Yup.string().required('Listing is required'),
        price: Yup.number().positive('Price must be positive').required('Price is required'),
        startDate: Yup.date().required('Start date is required'),
        endDate: Yup.date().min(Yup.ref('startDate'), 'End date must be after start date').required('End date is required'),
        days: Yup.array().min(1, 'Select at least one day'),
        minimumStay: Yup.number()
            .required('Min Nights is required'),
        maximumStay: Yup.number()
            .required('Max Nights is required'),
        status: Yup.string().required('Status is required'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        setIsSubmitting(true);
        try {
            const payload = { ...values };
            delete payload.updateFields;
            const response = await updateCalnedar(payload);
            console.log('Server response:', response);
            toast.success('Calendar updated successfully');

            onUpdate({
                listingId: payload.listingId,
                postUpdateDocs: response.data.postUpdateDocs
            });

            onClose();
        } catch (error) {
            console.error('Error updating calendar:', error);
            toast.error('Failed to update calendar');
        } finally {
            setSubmitting(false);
            setIsSubmitting(false);
        }
    };

    const dayMapping = {
        'Sun': 'Sunday',
        'Mon': 'Monday',
        'Tue': 'Tuesday',
        'Wed': 'Wednesday',
        'Thu': 'Thursday',
        'Fri': 'Friday',
        'Sat': 'Saturday'
    };

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const updateFields = ['Min Nights', 'Max Nights', 'isAvailable'];

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle style={{ backgroundColor: '#2196f3', color: 'white' }}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6" style={{ color: 'white' }}>Bulk Update</Typography>
                    <IconButton onClick={onClose} size="small" style={{ color: 'white' }}>
                        <X />
                    </IconButton>
                </Box>
            </DialogTitle>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
            >
                {({ values, errors, touched, handleChange, setFieldValue, isValid, dirty }) => (
                    <Form>
                        <DialogContent>
                            <Box className="flex gap-4">
                                <Box>
                                    <Box my={2}>
                                        <Field
                                            as={Select}
                                            name="listingId"
                                            fullWidth
                                            displayEmpty
                                            onChange={(e) => {
                                                const newListingId = e.target.value;
                                                setFieldValue('listingId', newListingId);

                                                const selectedListing = selectedDay.allListingsDayData.find(item => item.property.id === newListingId);
                                                if (selectedListing) {
                                                    setFieldValue('minimumStay', selectedListing.dayData?.minimumStay?.toString() || '');
                                                    setFieldValue('maximumStay', selectedListing.dayData?.maximumStay?.toString() || '');
                                                    setFieldValue('price', selectedListing.dayData?.price?.toString() || '');
                                                    setFieldValue('isAvailable', selectedListing.dayData?.isAvailable ?? true);
                                                    setFieldValue('status', selectedListing.dayData?.status || 'available');
                                                }
                                            }}
                                        >
                                            {selectedDay.allListingsDayData.map(item => (
                                                <MenuItem key={item.property.id} value={item.property.id}>
                                                    {item.property.name}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                        <ErrorMessage name="listingId" component="span" className="!text-red-500 text-sm" />
                                    </Box>

                                    <Box my={2}>
                                        <Field
                                            as={TextField}
                                            name="price"
                                            type="number"
                                            placeholder="listing price"
                                            fullWidth
                                        />
                                        <ErrorMessage name="price" component="span" className="!text-red-500 text-sm" />
                                    </Box>

                                    <Box display="flex" justifyContent="space-between" my={2}>
                                        <Field
                                            as={TextField}
                                            name="startDate"
                                            label="From"
                                            type="date"
                                            InputLabelProps={{ shrink: true }}
                                            style={{ width: '48%' }}
                                        />
                                        <Field
                                            as={TextField}
                                            name="endDate"
                                            label="To"
                                            type="date"
                                            InputLabelProps={{ shrink: true }}
                                            style={{ width: '48%' }}
                                        />
                                    </Box>
                                    <ErrorMessage name="startDate" component="span" className="!text-red-500 text-sm" />
                                    <ErrorMessage name="endDate" component="span" className="!text-red-500 text-sm" />
                                </Box>
                                <Box className="p-2">
                                    <span className='text-md'>Select Days :</span>
                                    <Box display="flex" flexWrap="wrap">
                                        {days.map(day => (
                                            <FormControlLabel
                                                key={day}
                                                control={
                                                    <Field
                                                        as={Checkbox}
                                                        name="days"
                                                        value={dayMapping[day]}
                                                        checked={values.days.includes(dayMapping[day])}
                                                        onChange={(e) => {
                                                            const fullDayName = dayMapping[day];
                                                            if (e.target.checked) {
                                                                setFieldValue('days', [...values.days, fullDayName]);
                                                            } else {
                                                                setFieldValue('days', values.days.filter(d => d !== fullDayName));
                                                            }
                                                        }}
                                                    />
                                                }
                                                label={day}
                                            />
                                        ))}
                                    </Box>
                                    <ErrorMessage name="days" component="span" className="!text-red-500 text-sm" />
                                </Box>
                            </Box>
                            <Typography variant="subtitle1">Select for Update</Typography>
                            <Box display="flex" flexWrap="wrap">
                                {updateFields.map(field => (
                                    <FormControlLabel
                                        key={field}
                                        control={
                                            <Field
                                                as={Checkbox}
                                                name="updateFields"
                                                value={field}
                                                checked={values.updateFields.includes(field)}
                                            />
                                        }
                                        label={field}
                                    />
                                ))}
                            </Box>

                            {values.updateFields.includes('Min Nights') && (
                                <Box my={2}>
                                    <Field
                                        as={TextField}
                                        name="minimumStay"
                                        label="Min Nights"
                                        fullWidth
                                    />
                                    <ErrorMessage name="minimumStay" component="span" className="!text-red-500 text-sm" />
                                </Box>
                            )}

                            {values.updateFields.includes('Max Nights') && (
                                <Box my={2}>
                                    <Field
                                        as={TextField}
                                        name="maximumStay"
                                        label="Max Nights"
                                        fullWidth
                                    />
                                    <ErrorMessage name="maximumStay" component="span" className="!text-red-500 text-sm" />
                                </Box>
                            )}

                            {values.updateFields.includes('isAvailable') && (
                                <Box my={2}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={values.isAvailable}
                                                onChange={(event) => {
                                                    setFieldValue('isAvailable', event.target.checked);
                                                    if (values.status !== 'reserved') {
                                                        setFieldValue('status', event.target.checked ? 'available' : 'occupied');
                                                    }
                                                }}
                                                name="isAvailable"
                                                color="primary"
                                            />
                                        }
                                        label={values.isAvailable ? "Available" : "Not Available"}
                                    />
                                    <Typography variant="subtitle1" style={{ marginTop: '16px' }}>Status:</Typography>
                                    <Field
                                        as={Select}
                                        name="status"
                                        fullWidth
                                        value={values.status}
                                        onChange={(event) => {
                                            const newStatus = event.target.value;
                                            setFieldValue('status', newStatus);
                                            if (newStatus === 'reserved') {
                                                // Handle reserved status
                                            } else {
                                                setFieldValue('isAvailable', newStatus === 'available');
                                            }
                                        }}
                                        disabled={values.status === 'reserved'}
                                    >
                                        <MenuItem value="available">Available</MenuItem>
                                        <MenuItem value="reserved">Reserved</MenuItem>
                                        <MenuItem value="occupied">Occupied</MenuItem>
                                    </Field>
                                    <ErrorMessage name="status" component="span" className="!text-red-500 text-sm" />
                                </Box>
                            )}

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onClose} disabled={isSubmitting}>
                                <span className="!text-red-500">CLOSE</span>
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={isSubmitting || !isValid}
                            >
                                {isSubmitting ? (
                                    <CircularProgress size={24} color="inherit" />
                                ) : (
                                    <span className='text-white'>UPDATE</span>
                                )}
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

export default BulkUpdateDialog;