import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import { TextField, Box, MenuItem } from '@mui/material';
import axios from 'axios';
import { getcities } from '../../services/serverApi.ProjectUnits';

export const LocationInformation = ({ touched, errors }) => {
    const [cities, setCities] = useState([]);

    useEffect(() => {
        

        fetchCities();
    }, []);

    const fetchCities = async () => {
        try {
            const data = await getcities();
            setCities(data.data);
          } catch (error) {
            console.error("Error fetching data:", error);
          } 
      
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} className="!mb-4">
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Field name="city">
                    {({ field }) => (
                        <TextField
                            {...field}
                            select
                            label="City"
                            fullWidth
                            error={touched.city && Boolean(errors.city)}
                            helperText={touched.city && errors.city}
                        >
                            {cities.map(city => (
                                <MenuItem key={city._id} value={city.name}>
                                    {city.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}

                </Field>

                <Field name="district">
                    {({ field }) => (
                        <TextField
                            {...field}
                            label="District"
                            fullWidth
                            error={touched.district && Boolean(errors.district)}
                            helperText={touched.district && errors.district}
                        />
                    )}
                </Field>
            </Box>

            <Field name="address">
                {({ field }) => (
                    <TextField
                        {...field}
                        label="Address"
                        fullWidth
                        multiline
                        rows={2}
                        error={touched.address && Boolean(errors.address)}
                        helperText={touched.address && errors.address}
                    />
                )}
            </Field>

            <Box sx={{ display: 'flex', gap: 2 }}>
                <Field name="gps.latitude">
                    {({ field }) => (
                        <TextField
                            {...field}
                            label="Latitude"
                            type="number"
                            fullWidth
                            error={touched.gps?.latitude && Boolean(errors.gps?.latitude)}
                            helperText={touched.gps?.latitude && errors.gps?.latitude}
                        />
                    )}
                </Field>

                <Field name="gps.longitude">
                    {({ field }) => (
                        <TextField
                            {...field}
                            label="Longitude"
                            type="number"
                            fullWidth
                            error={touched.gps?.longitude && Boolean(errors.gps?.longitude)}
                            helperText={touched.gps?.longitude && errors.gps?.longitude}
                        />
                    )}
                </Field>
            </Box>
        </Box>
    );
};

export default LocationInformation;