import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';

const ConfirmationModal = ({ open, onClose, onConfirm, title, message }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
      className="!flex !items-center !justify-center"
    >
      <Box className="!bg-white !rounded-lg !shadow-xl !p-6 !w-full !max-w-md !mx-4">
        <Typography
          id="confirmation-modal-title"
          variant="h6"
          component="h2"
          className="!mb-4 !text-[#676a6c] !font-bold"
        >
          {title}
        </Typography>
        <Typography
          id="confirmation-modal-description"
          className="!mt-2 !mb-6 !text-base !text-[#676a6c]"
        >
          {message}
        </Typography>
        <Box className="!flex !justify-end !space-x-4">
          <Button
            onClick={onClose}
            variant="outlined"
            className="!px-4 !py-2 bg-[#f0f0f0] !text-[#676a6c] !border-[#676a6c]"
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            variant="contained"
            className="!px-4 !py-2 !bg-medium-aquamarine !text-white"
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;