import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  MenuItem,
  Paper,
  Alert,
  Snackbar,
  InputAdornment,
  TextField,
  Skeleton,
  Fade,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import {
  Search,
  Business,
  ArrowForward,
  Refresh,
} from '@mui/icons-material';
import { styled, keyframes } from '@mui/material/styles';
import { getListings, getChannexMapping } from '../services/serverApi.listing';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
  },
}));

const AnimatedBox = styled(Box)`
  animation: ${fadeIn} 0.3s ease-out;
`;

const LoadingSkeleton = () => (
  <Box sx={{ mt: 2, p: 2 }}>
    <Skeleton variant="rectangular" height={60} sx={{ borderRadius: 2, mb: 2 }} />
    <Skeleton variant="rectangular" height={100} sx={{ borderRadius: 2, mb: 2 }} />
    <Skeleton variant="rectangular" height={80} sx={{ borderRadius: 2 }} />
  </Box>
);

const MappingRow = ({
  content1,
  content2,
  variant = "body1",
  iconSize = "medium",
  sx = {}
}) => (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    p: 1.5,
    borderRadius: 1,
    position: 'relative',
    transition: 'all 0.2s ease',
    '&:hover': {
      bgcolor: 'action.hover',
      transform: 'translateX(4px)',
    },
    ...sx
  }}>
    <Box sx={{ flex: 1, width: "40%" }}>
      <Typography
        variant={variant}
        sx={{
          color: 'text.primary',
          display: 'flex',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          gap: 1,
        }}
      >
        {content1}
      </Typography>
    </Box>

    <ArrowForward
      sx={{
        fontSize: 'medium',
        color: 'action.active',
        transition: 'transform 0.2s ease',
        '&:hover': {
          transform: 'translateX(4px)'
        }
      }}

    />
    <Box sx={{ flex: 1, width: "40%" }}>
      <Typography
        variant={variant}
        sx={{
          flex: 1,
          color: 'text.primary',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          pl: 2
        }}
      >
        {content2}
      </Typography>
    </Box>
  </Box>
);

const MappingHeader = () => (
  <Box sx={{
    display: 'flex',
    alignItems: 'center',
    p: 3,
    borderBottom: '2px solid',
    borderColor: 'divider',
    borderRadius: '16px 16px 0 0',
  }}>
    <Typography
      variant="h6"
      sx={{
        flex: 1,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#00b4b4'
      }}
    >
      Sojori
    </Typography>
    <ArrowForward  sx={{
      color: '#00b4b4',
      transform: 'scale(1.2)',
      mx: 2,
      fontSize: 'medium'
    }}  />
    <Typography
      variant="h6"
      sx={{
        flex: 1,
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#00b4b4'
      }}
    >
      Channex
    </Typography>
  </Box>
);

const RoomTypeSection = ({ roomType, expanded }) => (
  <AnimatedBox
    sx={{
      mb: 2,
      // pl: 2,
      borderLeft: 3,
      borderColor: '#00b4b4',
      transition: 'all 0.3s ease'
    }}
  >
    <Box
      sx={{
        transition: 'all 0.2s ease',
        '&:hover': {
          transform: 'translateX(4px)'
        }
      }}
    >
      <MappingRow
        content1={roomType.myRoomType.roomTypeName}
        content2={roomType.channexRoomType.attributes.title}
        variant='span'
        sx={{
          borderRadius: 1,
          p: 1.5,
          fontSize: '1rem',
        }}
      />
    </Box>

    <Fade in={expanded}>
      <Box sx={{
        // pl: 2,
        borderLeft: 1,
        borderColor: 'divider',
      }}>
        {roomType.ratePlans.map((ratePlan, rateIndex) => (
          <MappingRow
            key={rateIndex}
            content1={ratePlan.attributes.title}
            content2={ratePlan.attributes.title}
            variant="body2"
            iconSize="small"
            sx={{
              py: 0.5,
              opacity: 0.9,
              '&:hover': {
                opacity: 1
              }
            }}
          />
        ))}
      </Box>
    </Fade>
  </AnimatedBox>
);

const ChannexMappingPage = () => {
  const [listings, setListings] = useState([]);
  const [selectedListingId, setSelectedListingId] = useState('');
  const [channexMapping, setChannexMapping] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [expandedRooms, setExpandedRooms] = useState({});
  const [limit] = useState(50);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const fetchListings = async () => {
    setIsFetching(true);
    try {
      const response = await getListings({ limit });
      setListings(response.data.data);
    } catch (error) {
      console.error('Error fetching listings:', error);
      setError(error.message);
      setSnackbarOpen(true);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchChannexMapping = async () => {
    setIsLoading(true);
    try {
      if (selectedListingId) {
        const response = await getChannexMapping(selectedListingId);
        setChannexMapping(response.data.data);
        const initialExpandedState = {};
        response.data.data.roomTypes?.forEach((_, index) => {
          initialExpandedState[index] = true;
        });
        setExpandedRooms(initialExpandedState);
      }
    } catch (error) {
      console.error('Error fetching channex mapping:', error);
      setError(error.message);
      setSnackbarOpen(true);
      setChannexMapping(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchListings();
  }, [limit]);

  useEffect(() => {
    if (selectedListingId) {
      fetchChannexMapping();
    }
  }, [selectedListingId]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ p: 3, maxWidth: '1200px', mx: 'auto' }}>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            {error}
          </Alert>
        </Snackbar>
        <StyledPaper
          elevation={2}
          sx={{
            p: 3,
            mb: 3,
          }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2
          }}>
            <Typography
              variant="h5"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Search /> Search Property
            </Typography>
            {isFetching ? (
              <CircularProgress size={24} />
            ) : (
              <Tooltip title="Refresh listings">
                <IconButton onClick={fetchListings}>
                  <Refresh />
                </IconButton>
              </Tooltip>
            )}
          </Box>

          <TextField
            select
            fullWidth
            value={selectedListingId}
            onChange={(e) => setSelectedListingId(e.target.value)}
            placeholder="Select a listing"
            variant="outlined"
            disabled={isFetching}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Business />
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value="">Select a listing</MenuItem>
            {listings.map((listing) => (
              <MenuItem key={listing._id} value={listing._id}>
                {listing.name}
              </MenuItem>
            ))}
          </TextField>
        </StyledPaper>

        {isLoading ? (
          <StyledPaper elevation={2}>
            <LoadingSkeleton />
          </StyledPaper>
        ) : channexMapping && (
          <StyledPaper
            elevation={2}
            sx={{
              overflow: 'hidden',
            }}
          >
            <MappingHeader />

            <Box sx={{ p: 2 }}>
              <AnimatedBox>
                <MappingRow
                  content1={`${channexMapping.myListing.name} (${channexMapping.myListing._id.slice(0, 6)})`}
                  content2={`${channexMapping.channexListing.attributes.title} (${channexMapping.channexListing.attributes.id.slice(0, 6)})`}
                  variant="h6"
                  sx={{
                    color: 'primary.contrastText',
                    p: 1,
                  }}
                />
              </AnimatedBox>

              {channexMapping.roomTypes?.map((roomType, index) => (
                <RoomTypeSection
                  key={index}
                  roomType={roomType}
                  expanded={expandedRooms[index]}
                />
              ))}
            </Box>
          </StyledPaper>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default ChannexMappingPage;