import React, { useEffect, useState } from 'react';
import moment from 'moment';
export const CalendarHeader = ({
    setViewType,
    viewType,
    goToToday,
    formattedDateRange,
    scrollToDate
}) => {

    const [currentScrollDate, setCurrentScrollDate] = useState(moment());

    useEffect(() => {
        const [startDate] = formattedDateRange.split(' – ');
        setCurrentScrollDate(moment(startDate, 'ddd D MMM'));
    }, [formattedDateRange]);

    const handleScroll = (unit, direction) => {
        let targetDate = currentScrollDate.clone();

        switch (unit) {
            case 'day':
                targetDate = targetDate.add(direction === 'next' ? 1 : -1, 'days');
                break;
            case 'week':
                targetDate = targetDate.add(direction === 'next' ? 1 : -1, 'weeks');
                break;
            case 'month':
                targetDate = targetDate.add(direction === 'next' ? 1 : -1, 'months');
                break;
            case 'now':
                targetDate = moment();
                break;
            default:
                return;
        }

        setCurrentScrollDate(targetDate);
        scrollToDate(targetDate.toDate());
    };
    const handleGoToToday = () => {
        handleScroll('now');
        goToToday();
    };
    return (
        <div className="grid grid-cols-3 gap-3 p-4 bg-gray-100 border-b border-gray-300">
            <div className="flex">
                {/* <button onClick={() => handleScroll('day', 'prev')} className="px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center"><i className="pi pi-angle-left"></i><span>Dy</span></button> */}
                <button onClick={() => handleScroll('week', 'prev')} className="px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center"><i className="pi pi-angle-left"></i><span>Wk</span></button>
                <button onClick={() => handleScroll('month', 'prev')} className="px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center"><i className="pi pi-angle-left"></i><span>Mo</span></button>
                <button onClick={handleGoToToday} className="px-3 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs">Now</button>
                <button onClick={() => handleScroll('month', 'next')} className="px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center"><i className="pi pi-angle-right"></i><span>Mo</span></button>
                <button onClick={() => handleScroll('week', 'next')} className="px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center"><i className="pi pi-angle-right"></i><span>Wk</span></button>
                {/* <button onClick={() => handleScroll('day', 'next')} className="px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center !rounded-r-md"><i className="pi pi-angle-right"></i><span>Dy</span></button> */}
            </div>
            <div className='text-center'>
                <span className="!text-sm font-semibold">{formattedDateRange}</span>
            </div>
            <div className="flex justify-end">
                {/* <button onClick={goToToday} className="px-3 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs !rounded-l-md">Today</button>  */}
                <button onClick={() => handleScroll('month', 'prev')} className="px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center"><i className="pi pi-angle-left"></i><span>Scroll</span></button>
                <button onClick={() => handleScroll('month', 'next')} className="px-2 !py-[2px] border border-gray-300 bg-white hover:bg-gray-200 !text-xs flex items-center" > <i className="pi pi-angle-right"></i><span>Scroll</span> </button>
                <button onClick={() => setViewType('30 Day')} className={`px-3 !py-[2px] border border-gray-600 hover:bg-gray-200 !text-xs ${viewType === '30 Day' ? 'bg-gray-600 text-white' : 'bg-white'}`}>Month</button>
                {/* <button onClick={() => setViewType('Month')} className={`px-3 !py-[2px] border border-gray-600 hover:bg-gray-200 !text-xs !rounded-r-md ${viewType === 'Month' ? 'bg-gray-600 text-white' : 'bg-white'}`}>Month</button> */}
            </div>
        </div>
    );
};