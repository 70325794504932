import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { AssignTaskToStaff } from '../../services/serverApi.task';
import { toast } from 'react-toastify';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid gray',
    boxShadow: 24,
    p: 4,
};

const AssignTask = ({ open, handleClose, staff = [], task, onTaskUpdated }) => {
    const [staffId, setStaffId] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (task && task.staffId) {
            setStaffId(task.staffId);
        } else {
            setStaffId('');
        }
    }, [task]);

    const handleChange = (event) => {
        setStaffId(event.target.value);
    };

    const handleSave = async () => {
        if (!task || !task.id) {
          setError('Invalid task ID.');
          return;
        }
    
        setIsLoading(true);
        setError(null);
        try {
          const response = await AssignTaskToStaff(task.id, staffId);
          if (response.status === 200) {
            toast.success(task.staffId ? 'Task staff updated successfully' : 'Task assigned successfully');
            
            if (onTaskUpdated) {
              onTaskUpdated({
                ...task,
                staffId: staffId
              });
            }
            
            handleClose();
          } else {
            throw new Error('Failed to assign/update task');
          }
        } catch (err) {
          console.error('Error in handleSave:', err);
          toast.error('Failed to assign/update task. Please try again.');
          setError('Failed to assign/update task. Please try again.');
        } finally {
          setIsLoading(false);
        }
      };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {task && task.staffId ? 'Update Task Assignment' : 'Assign Task'}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {task ? `Task: ${task.title} at ${task.time}` : 'No task selected'}
                </Typography>
                {task && task.staffId && (
                    <span className='text-sm text-gray-500'>
                        Currently assigned to: {staff.find(s => s._id === task.staffId)?.username || 'Unknown'}
                    </span>
                )}
                <FormControl fullWidth sx={{ mt: 2 }}>
                    <InputLabel id="staff-select-label">Staff</InputLabel>
                    <Select
                        labelId="staff-select-label"
                        id="staff-select"
                        value={staffId}
                        label="Staff"
                        onChange={handleChange}
                    >
                        {staff.map((member) => (
                            <MenuItem key={member.id} value={member._id}>
                                {member.username}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {error && (
                    <Typography color="error" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}
                <Button 
                    className='!bg-[#66dac3]'
                    onClick={handleSave}
                    disabled={isLoading || !staffId || (task && task.staffId === staffId)}
                    sx={{ mt: 2 }}
                >
                    {isLoading ? 'Saving...' : <span className='text-white'>{task && task.staffId ? 'Update' : 'Save'}</span>}
                </Button>
            </Box>
        </Modal>
    );
};

export default AssignTask;