import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';

export const getTickets = async (params = {}) => {
  try {
    const {
      page = 0,
      limit = 20,
      status,
      acceptance,
      code,
      cityId,
      paged = true
    } = params;

    const queryParams = new URLSearchParams();
    queryParams.append('page', page);
    queryParams.append('limit', limit);
    queryParams.append('paged', paged);

    if (status) queryParams.append('status', status);
    if (acceptance) queryParams.append('acceptance', acceptance);
    if (cityId) queryParams.append('cityId', cityId);
    if (code) queryParams.append('code', code);

    const response = await axios.get(
      `${MICROSERVICE_BASE_URL.SRV_TASK}/ticket/get-tickets?${queryParams.toString()}`
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching tickets:', error);
    throw error;
  }
}

// export const deleteTicket = async (ticketId) => {
//   try {
//     await axios.delete(`${MICROSERVICE_BASE_URL.SRV_TASK}/ticket/${ticketId}`);
//   } catch (error) {
//     console.error('Error deleting ticket:', error);
//     throw error;
//   }
// }

export function getCities(page, limit, paged) {
  return axios.get(`${MICROSERVICE_BASE_URL.CITY}?page=${page || 0}&limit=${limit || 1}&paged=false`);
}


export const createTicket = async (data) => {
  try {
      const response = await axios.post(`${MICROSERVICE_BASE_URL.SRV_TASK}/ticket/create-ticket`, data);
      return response.data;
  } catch (error) {
      throw error;
  }
};

export const updateTicket = async (id, data) => {
  try {
      const response = await axios.put(`${MICROSERVICE_BASE_URL.SRV_TASK}/ticket/update-ticket/${id}`, data);
      return response.data;
  } catch (error) {
      throw error;
  }
};

export const getPropertyTypes = () => {
  return axios.get(`${MICROSERVICE_BASE_URL.PROPERTYTYPES}`);
}