import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Calendar } from './Calendar/Calendar';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import { getMultiListing } from '../../services/serverApi.calendar';
import moment from 'moment';
import Pagination from 'components/GlobalTable/Pagination';
import FilterCalendar from './Calendar/FilterCalendar';
import 'react-toastify/dist/ReactToastify.css';
import { debounce } from 'lodash';
import { useParams, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { updateInventoryForListings } from '../../services/serverApi.calendar';
import { Snackbar, Alert } from '@mui/material';

function RoomTypeCalendar() {
  const { listingId } = useParams();
  console.log('listingId : ', listingId);
  const [pendingChanges, setPendingChanges] = useState([]);
  const [isInventoryUpdated, setIsInventoryUpdated] = useState(false);
  const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
  const [selectedItems, setSelectedItems] = useState([
    'Base Price',
    'Available Room',
  ]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const checkInDate = queryParams.get('checkIn')
    ? moment(queryParams.get('checkIn'))
    : null;
  const checkOutDate = queryParams.get('checkOut')
    ? moment(queryParams.get('checkOut'))
    : null;
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const [state, setState] = useState({
    listings: [],
    isLoading: true,
    error: null,
    filters: {
      city: '',
      country: '',
      match: '',
    },
    page: 0,
    limit: 20,
    totalCount: 0,
    isNextDisabled: false,
  });

  useEffect(() => {
    setState({
      listings: [],
      isLoading: true,
      error: null,
      filters: {
        city: '',
        country: '',
        match: '',
      },
      page: 0,
      limit: 20,
      totalCount: 0,
      isNextDisabled: false,
    });
  }, [staging]);

  const prevFiltersRef = useRef(state.filters);

  const fetchListings = useCallback(async () => {
    setState((prev) => ({ ...prev, isLoading: true }));
    try {
      let filters = { ...state.filters };
      if (listingId) {
        filters.listing_id = listingId;
      }

      const response = await getMultiListing(
        state.page,
        state.limit,
        filters,
        staging,
      );

      if (response?.data?.data) {
        let formattedListings = response.data.data.map((listing) => ({
          id: listing._id,
          name: listing.name,
        }));

        if (listingId) {
          formattedListings = formattedListings.filter(
            (listing) => listing.id === listingId,
          );
        }

        setState((prev) => ({
          ...prev,
          listings: formattedListings,
          totalCount: listingId
            ? formattedListings.length
            : response.data.total || formattedListings.length,
          isNextDisabled: listingId ? true : !response.data.hasNextPage,
          error: null,
          isLoading: false,
        }));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      setState((prev) => ({
        ...prev,
        listings: [],
        error: err.message,
        isLoading: false,
      }));
    }
  }, [state.page, state.limit, state.filters, listingId, staging]);

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const showNotification = (message, severity = 'success') => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  useEffect(() => {
    setPendingChanges([]);
  }, [staging]);

  const areValuesEqual = (existingChange, newChange) => {
    console.log('existingChange : ', existingChange);
    console.log('newChange : ', newChange);

    switch (newChange.type) {
      case 'manualPrice':
        return existingChange.price === newChange.price;
      case 'availability':
        return existingChange.availableRoom === newChange.availableRoom;
      case 'stopSell':
        return existingChange.stopSell === newChange.stopSell;
      case 'min_stay_arrival':
        return existingChange.min_stay_arrival === newChange.min_stay_arrival;
      case 'max_stay':
        return existingChange.max_stay === newChange.max_stay;
      case 'closed_to_arrival':
        return existingChange.closed_to_arrival === newChange.closed_to_arrival;
      case 'closed_to_departure':
        return (
          existingChange.closed_to_departure === newChange.closed_to_departure
        );
      default:
        return true;
    }
  };

  const handleInventoryUpdate = (newChanges) => {
    console.log('newChanges', newChanges);

    setPendingChanges((prev) => {
      const updatedChanges = [...prev];
      if (Array.isArray(newChanges)) {
        newChanges.forEach((newChange) => {
          const existingChangeIndex = updatedChanges.findIndex(
            (change) =>
              change.roomTypeId === newChange.roomTypeId &&
              change.date_from === newChange.date_from &&
              change.type === newChange.type,
          );

          if (existingChangeIndex !== -1) {
            if (
              !areValuesEqual(updatedChanges[existingChangeIndex], newChange)
            ) {
              updatedChanges[existingChangeIndex] = newChange;
            }
          } else {
            updatedChanges.push(newChange);
          }
        });
      } else {
        return newChanges;
      }

      return updatedChanges;
    });
  };

  const handleSaveAllChanges = async () => {
    try {
      if (pendingChanges.length === 0) {
        showNotification('No changes to save', 'info');
        return;
      }
      await updateInventoryForListings(pendingChanges);
      setPendingChanges([]);
      setIsInventoryUpdated(true);
      showNotification('All changes saved successfully');
    } catch (error) {
      showNotification('Failed to save changes', 'error');
    }
  };

  const debouncedFetchListings = useCallback(debounce(fetchListings, 300), [
    fetchListings,
  ]);

  useEffect(() => {
    const filtersChanged =
      JSON.stringify(state.filters) !== JSON.stringify(prevFiltersRef.current);
    if (
      filtersChanged ||
      state.page !== prevFiltersRef.current.page ||
      state.limit !== prevFiltersRef.current.limit
    ) {
      debouncedFetchListings();
      prevFiltersRef.current = {
        ...state.filters,
        page: state.page,
        limit: state.limit,
      };
    }
    return () => debouncedFetchListings.cancel();
  }, [state.filters, state.page, state.limit, debouncedFetchListings]);

  const handleFilterChange = useCallback(
    (newFilters) => {
      if (!listingId) {
        setState((prev) => ({
          ...prev,
          filters: { ...prev.filters, ...newFilters },
          page: 0,
        }));
      }
    },
    [listingId],
  );

  const handleSearchQueryChange = useCallback(
    (query) => {
      if (!listingId) {
        setState((prev) => ({
          ...prev,
          filters: { ...prev.filters, match: query },
          page: 0,
        }));
      }
    },
    [listingId],
  );

  const handleSelectedItemsChange = useCallback((items) => {
    setSelectedItems(items);
  }, []);

  const handlePageChange = useCallback(
    (newPage) => {
      if (!listingId) {
        setState((prev) => ({
          ...prev,
          page: newPage,
        }));
      }
    },
    [listingId],
  );

  const handleLimitChange = useCallback(
    (newLimit) => {
      if (!listingId) {
        setState((prev) => ({
          ...prev,
          limit: newLimit,
        }));
      }
    },
    [listingId],
  );

  const rowsPerPageOptions = [10, 20, 50, 100];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer position="top-right" autoClose={1000} />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          elevation={6}
          variant="filled"
          style={{
            backgroundColor: (() => {
              switch (snackbar.severity) {
                case 'success':
                  return '#22c55e';
                case 'error':
                  return '#ef4444';
                case 'warning':
                  return '#f59e0b';
                case 'info':
                  return '#f6b237';
                default:
                  return '#22c55e';
              }
            })(),
            color: 'white',
            opacity: 0.9,
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <div className="card !bg-slate-50">
        <main>
          <FilterCalendar
            onFilterChange={handleFilterChange}
            onSearchQueryChange={handleSearchQueryChange}
            onSelectedItemsChange={handleSelectedItemsChange}
            listingsCount={state.listings.length}
            currentFilters={state.filters}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
            disabled={!!listingId}
            onSaveChanges={handleSaveAllChanges}
            hasPendingChanges={pendingChanges.length > 0}
            setPendingChanges={setPendingChanges}
          />
          <ToastContainer />
          <Calendar
            listings={state.listings}
            selectedItems={selectedItems}
            listingId={listingId}
            checkInDate={checkInDate}
            checkOutDate={checkOutDate}
            isLoading={state.isLoading}
            error={state.error}
            onInventoryUpdate={handleInventoryUpdate}
            isInventoryUpdated={isInventoryUpdated}
            setIsInventoryUpdated={setIsInventoryUpdated}
            pendingChanges={pendingChanges}
            setPendingChanges={setPendingChanges}
            showNotification={showNotification}
          />
          {!listingId && (
            <Pagination
              page={state.page}
              onPageChange={handlePageChange}
              isNextDisabled={state.isNextDisabled}
              limit={state.limit}
              onLimitChange={handleLimitChange}
              rowsPerPageOptions={rowsPerPageOptions}
            />
          )}
        </main>
      </div>
    </DashboardLayout>
  );
}

export default RoomTypeCalendar;
