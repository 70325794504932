import React, { useState, useEffect } from 'react';
import { TextField, Switch, Typography, IconButton } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

const weekdays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const WeekdayWeekendRule = ({ ratePlan, onChange }) => {
    const [adjustments, setAdjustments] = useState(ratePlan['adjustments-week_days'] || {});
    const [isActive, setIsActive] = useState(ratePlan['adjustments-week_days-active'] || false);

    useEffect(() => {
        setAdjustments(ratePlan['adjustments-week_days'] || {});
        setIsActive(ratePlan['adjustments-week_days-active'] || false);
    }, [ratePlan]);

    const getWeekdayAdjustment = (day) => {
        return adjustments[day] / 100 || 1;
    };

    const handleSwitchChange = (e) => {
        const newIsActive = e.target.checked;
        setIsActive(newIsActive);
        onChange({
            'adjustments-week_days-active': newIsActive
        });
    };

    const handleAdjustmentChange = (day, value) => {
        const newAdjustments = { ...adjustments, [day]: Math.round(value * 100) };
        setAdjustments(newAdjustments);
        onChange({
            'adjustments-week_days': newAdjustments
        });
    };

    return (
        <div className="mb-4">
            <div className="flex items-center mb-2">
                <div className="flex items-center">
                    <Typography className="!text-base mr-2">WEEKDAY/WEEKEND RULE</Typography>
                    <IconButton size="small"><InfoOutlined /></IconButton>
                </div>
                <Switch
                    checked={isActive}
                    onChange={handleSwitchChange}
                    sx={{
                        '& .MuiSwitch-switchBase.Mui-checked': { color: '#00b4b4' },
                        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#00b4b4' }
                    }}
                />
            </div>

            <div className="flex flex-wrap gap-4 mt-2">
                {weekdays.map((day) => (
                    <div key={day} className="text-center">
                        <Typography className="my-1 !font-bold !text-sm">{day.charAt(0).toUpperCase() + day.slice(1)}</Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            value={getWeekdayAdjustment(day)}
                            onChange={(e) => handleAdjustmentChange(day, parseFloat(e.target.value))}
                            className="w-20 "
                            type="number"
                            inputProps={{ min: 0, step: 0.01 }}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WeekdayWeekendRule;