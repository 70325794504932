import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip, Box,IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ChatIcon from '@mui/icons-material/Chat';
import BookingIcon from '@mui/icons-material/BookOnline';
import { Link } from 'react-router-dom';


import QuestionMarkIcon from '@mui/icons-material/QuestionMark';


const ModelEventDialog = ({ eventSelect, onClose }) => {
  const [isEditingNote, setIsEditingNote] = useState(false);

  const formik = useFormik({
    initialValues: {
      note: '',
    },
    onSubmit: (values) => {
      setIsEditingNote(false);
      onClose();

    },
  });

  const toggleNoteEditing = () => {
    setIsEditingNote(!isEditingNote);
  };
  return (
    <Dialog PaperProps={{ style: { width: '400px', minHeight: '500px' } }} open={true} onClose={onClose}>
      <DialogTitle sx={{ padding: '30px', textAlign: 'center' }}>
    
      <Link to={`/reservation/${eventSelect?.id}`} style={{ textDecoration: 'none' }}>
  <Box sx={{ float: 'left', cursor: 'pointer', padding: '6px' }} onClick={onClose}>
    <BookingIcon fontSize='12px'/>
  </Box>
</Link>

<Link to="/home" style={{ textDecoration: 'none' }}>
  <Box sx={{ float: 'left', cursor: 'pointer', padding: '6px' }} onClick={onClose}>
    <ChatIcon fontSize='12px' />
  </Box>
</Link>

<Link to="/home" style={{ textDecoration: 'none' }}>
  <Box sx={{ float: 'left', cursor: 'pointer', padding: '6px' }} onClick={onClose}>
    <EditIcon fontSize='12px' />
  </Box>
</Link>

  <Box sx={{ float: 'left', cursor: 'pointer', padding: '6px' }} onClick={onClose}>
    <CloseIcon />
  </Box>

      </DialogTitle>
      <DialogContent>
        <div className="ReservationsPopupModalSlide-head_right">
        <div className="ReservationsMeta" style={{ marginTop: '3px', fontSize: '10px', fontStyle: 'italic' }}>
            {eventSelect?.guestCountry} {eventSelect?.guestCity}

          </div>
          <div className="Reservationstitle" style={{ fontWeight: '700', fontSize: '20px', color: '#2f4858' }}>
            {eventSelect?.guestName}
          </div>
          <div className="ReservationsMeta" style={{ marginTop: '3px', fontSize: '12px', fontStyle: 'italic' }}>
            {eventSelect?.nights} nights
          </div>
        </div>
        <div className="ReservationsPopupModalSlide-section">
          <div className="ReservationsPopupModalSlide-section-title">
            <label className="FieldLabel" htmlFor="guests">ID</label>
          </div>
          <div className="ReservationsPopupModalSlide-reservation-id">{eventSelect?.id}</div>
        </div>
        <div className="ReservationsPopupModalSlide-section">
          <div className="row d-flex">
            <div className="col-lg-6">
              <div className="Reservationstitle">
                <label className="FieldLabel">Guests</label>
              </div>
              <div> {eventSelect?.numberOfGuests}</div>
            </div>
            <div className="col-lg-6">
              <div className="Reservationstitle">
                <label className="FieldLabel">Status</label>
              </div>
              <div>{eventSelect?.status}</div>
            </div>
          </div>
        </div>
        <div className="ReservationsPopupModalSlide-section">
          <div className="col-lg-12">
            <div className="Reservationstitle">
              <label className="FieldLabel">Total price</label>
            </div>
            <div>{eventSelect?.totalPrice} {eventSelect?.currency}</div>
          </div>
        </div>
        <div className="ReservationsPopupModalSlide-section">
          <div className="col-lg-12">
            <div className="Reservationstitle">
              <label className="FieldLabel">Checkin & Checkout information</label>
            </div>
            <div>{eventSelect?.arrivalDate} &gt; {eventSelect?.departureDate}</div>
          </div>
        </div>
        <div className="ReservationsPopupModalSlide-section">
          <div className="col-lg-12">
            <div className="Reservationstitle">
              <label className="FieldLabel">Contact info</label>
            </div>
            <div><span style={{color:'black',fontSize:'larger',fontWeight:'bolder'}}>@</span> {eventSelect?.guestEmail}</div>
          </div>
        </div>
        <div className="ReservationsPopupModalSlide-section">
          <div className="col-lg-12">
            <div>0,00 MAD of {eventSelect?.totalPrice} {eventSelect?.currency}</div>
            <div>{eventSelect?.paymentStatus}</div>
          </div>
        </div>
        <div className="ReservationsPopupModalSlide-section">
          <div className="col-lg-12">
            <div className="Reservationstitle">
              <label className="FieldLabel">Host note <Tooltip title="This note is internal and will not be displayed to guests" placement='top-start'>
                    <QuestionMarkIcon style={{fontSize:'13px',background:'blue',color:'white',borderRadius:'20px'}} />
                </Tooltip>
          <span className='addNote' onClick={toggleNoteEditing} style={{ cursor: 'pointer' }}>{isEditingNote ? 'Cancel' : 'Add note'}</span></label>
            </div>
            {isEditingNote && (
              <form onSubmit={formik.handleSubmit}>
                <textarea
                  className='form-control'
                  type="text"
                  id="note"
                  name="note"
                  value={formik.values.note}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <DialogActions>
                  <Button type="submit" color="primary" disabled={formik.isSubmitting || !formik.dirty}>
                    {formik.isSubmitting ? 'Saving...' : 'Save'}
                  </Button>
                </DialogActions>
              </form>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ModelEventDialog;
