import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useCalendar } from '../hooks/useCalendar';
import TableCalendar from './TableCalendar';
import './Calendar.css';
import { getInventoryForListings } from '../../../services/serverApi.calendar';
import { toast } from 'react-toastify';

export const Calendar = ({
  listings,
  selectedItems,
  checkInDate,
  checkOutDate,
  onInventoryUpdate,
  isInventoryUpdated,
  setIsInventoryUpdated,
  pendingChanges,
  setPendingChanges,
  showNotification,
}) => {
  const [listingsData, setListingsData] = useState([]);
  const [inventoryData, setInventoryData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
  const [dateRange, setDateRange] = useState([
    {
      startDate: checkInDate ? new Date(checkInDate) : new Date(),
      endDate: checkOutDate
        ? new Date(checkOutDate)
        : new Date(new Date().getTime() + 9 * 24 * 60 * 60 * 1000),
      key: 'selection',
    },
  ]);
  console.log('listingsData', listingsData);

  const {
    currentDate,
    setCurrentDate,
    goToNextPeriod,
    goToPreviousPeriod,
    daysInView,
  } = useCalendar(dateRange[0].startDate);

  const startDate = moment(dateRange[0].startDate).format('YYYY-MM-DD');
  const endDate = moment(dateRange[0].endDate).format('YYYY-MM-DD');
  const formattedDateRange = `${moment(startDate).format('ddd D MMM')}`;

  const processInventoryData = (data) => {
    return data.reduce((acc, listing) => {
      acc[listing.listingId] = listing.roomTypes.reduce((roomAcc, room) => {
        roomAcc[room.roomTypeId] = {
          name: room.name,
          availability: room.availableRoomsByDay.reduce((dayAcc, day) => {
            dayAcc[moment(day.date).format('YYYY-MM-DD')] = {
              available: day.availableRoom,
              basePrice: day.basePrice,
              calculatedPrice: day.calculatedPrice,
              applyManual: day.applyManual,
              manualPrice: day.manualPrice,
              availabled: day.available,
              stopSell: day.stopSell,
              reservations: day.reservations,
              channexAvailableRoom: day.channexAvailableRoom,
              min_stay_arrival: day.min_stay_arrival,
              max_stay: day.max_stay,
              closed_to_arrival: day.closed_to_arrival,
              closed_to_departure: day.closed_to_departure,
              max_stay: day.max_stay,
              closed_to_arrival: day.closed_to_arrival,
              closed_to_departure: day.closed_to_departure,
            };
            return dayAcc;
          }, {}),
        };
        return roomAcc;
      }, {});
      return acc;
    }, {});
  };

  const processListingsData = (listings, inventoryData) => {
    return listings.map((listing) => {
      const listingInventory = inventoryData[listing.id] || {};
      const roomTypes = Object.entries(listingInventory)
        .map(([roomTypeId, roomData]) => ({
          id: roomTypeId,
          name: roomData.name,
          inventory: roomData.availability || {},
          ranking: roomData.roomTypeData?.ranking || roomData.ranking || 0,
        }))
        .sort((a, b) => {
          const rankingA = Number(a.ranking) || 0;
          const rankingB = Number(b.ranking) || 0;

          if (rankingA === rankingB) {
            return a.name.localeCompare(b.name);
          }

          return rankingB - rankingA;
        });

      const totalAvailableRoomsByDay = {};
      for (const roomType of roomTypes) {
        for (const [date, availability] of Object.entries(roomType.inventory)) {
          if (!totalAvailableRoomsByDay[date]) {
            totalAvailableRoomsByDay[date] = 0;
          }
          totalAvailableRoomsByDay[date] += availability.available || 0;
        }
      }

      return {
        id: listing.id,
        name: listing.name,
        roomTypes: roomTypes,
        totalAvailableRoomsByDay: Object.entries(totalAvailableRoomsByDay)
          .map(([date, available]) => ({
            date,
            available,
          }))
          .sort((a, b) => new Date(a.date) - new Date(b.date)),
      };
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      if (!listings || listings.length === 0) return setListingsData([]);

      try {
        setIsLoading(true);
        const listingIds = listings.map((listing) => listing.id);
        const response = await getInventoryForListings(
          listingIds,
          moment(dateRange[0].startDate).format('YYYY-MM-DD'),
          moment(dateRange[0].endDate).format('YYYY-MM-DD'),
        );
        if (response?.data?.data) {
          const processedInventory = processInventoryData(response.data.data);
          setInventoryData(processedInventory);

          const processedListings = processListingsData(
            listings,
            processedInventory,
          );
          setListingsData(processedListings);
        }
      } catch (error) {
        console.error('Failed to fetch inventory:', error);
        toast.error('Failed to fetch inventory data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [listings, dateRange, staging, isInventoryUpdated]);

  const handleUpdateInventory = async (updates) => {
    try {
      toast.success('Inventory updated successfully');
      const listingIds = listings.map((listing) => listing.id);
      const response = await getInventoryForListings(
        listingIds,
        startDate,
        endDate,
      );
      if (response?.data?.data) {
        const processedInventory = processInventoryData(response.data.data);
        setInventoryData(processedInventory);
        const processedListings = processListingsData(
          listings,
          processedInventory,
        );
        setListingsData(processedListings);
      }
    } catch (error) {
      console.error('Failed to update inventory:', error);
      toast.error('Failed to update inventory');
    }
  };

  const handleDateRangeChange = (startDate, endDate) => {
    setDateRange([
      {
        startDate,
        endDate,
        key: 'selection',
      },
    ]);
    setCurrentDate(startDate);
  };

  return (
    <div>
      <TableCalendar
        listings={listingsData}
        daysInView={daysInView}
        onUpdateInventory={handleUpdateInventory}
        formattedDateRange={formattedDateRange}
        goToPreviousPeriod={goToPreviousPeriod}
        goToNextPeriod={goToNextPeriod}
        onDateRangeChange={handleDateRangeChange}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        dateRange={dateRange}
        isLoading={isLoading}
        selectedItems={selectedItems}
        setIsInventoryUpdated={setIsInventoryUpdated}
        onInventoryUpdate={onInventoryUpdate}
        pendingChanges={pendingChanges}
        setPendingChanges={setPendingChanges}
        showNotification={showNotification}
      />
    </div>
  );
};

export default Calendar;
