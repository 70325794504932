import React, { useState, useEffect } from 'react';
import { 
  Modal, Box, Typography, Button, IconButton, Paper, TextField,
  Divider, List, ListItem, ListItemText, ListItemSecondaryAction
} from '@mui/material';
import { X as CloseIcon, Plus, Minus, Check, BedSingle } from 'lucide-react';

const BedTypePopup = ({ isOpen, onClose, bedTypes = [], selectedBedTypes, onAddBedType }) => {
  const [quantities, setQuantities] = useState({});
  const [availableBedTypes, setAvailableBedTypes] = useState([]);

  useEffect(() => {
    if (bedTypes && selectedBedTypes) {
      const selected = new Set(selectedBedTypes.map(bt => bt.bedTypeId));
      setAvailableBedTypes(bedTypes.filter(bt => !selected.has(bt._id)));
    }
  }, [bedTypes, selectedBedTypes]);

  const handleQuantityChange = (bedTypeId, change) => {
    setQuantities(prev => ({
      ...prev,
      [bedTypeId]: Math.max(0, (prev[bedTypeId] || 0) + change)
    }));
  };

  const handleAddBedType = (bedType) => {
    const quantity = quantities[bedType._id] || 0;
    if (quantity > 0) {
      onAddBedType({ bedTypeId: bedType._id, quantity: parseInt(quantity, 10) });
      setQuantities(prev => ({ ...prev, [bedType._id]: 0 }));
      setAvailableBedTypes(prev => prev.filter(bt => bt._id !== bedType._id));
    }
  };

  if (!bedTypes || bedTypes.length === 0) {
    return (
      <Modal open={isOpen} onClose={onClose}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          p: 4,
        }}>
          <Typography variant="h6">No Bed Types Available</Typography>
          <Typography>There are currently no bed types to select from.</Typography>
          <Button onClick={onClose} sx={{ mt: 2 }}>Close</Button>
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="bed-type-modal-title"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 2,
        p: 4,
        maxHeight: '80vh',
        overflow: 'auto'
      }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="bed-type-modal-title" variant="h6" component="h2" gutterBottom>
          Select Bed Types
        </Typography>
        <Divider sx={{ my: 2 }} />
        <List>
          {availableBedTypes.map((bedType) => (
            <ListItem key={bedType._id} disablePadding>
              <Paper
                elevation={0}
                sx={{
                  p: 2,
                  my: 1,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  bgcolor: '#eaf4fd',
                }}
              >
                <ListItemText primary={bedType.name} />
                <ListItemSecondaryAction>
                  <Box display="flex" alignItems="center" className="p-2">
                    <IconButton
                      size="small"
                      onClick={() => handleQuantityChange(bedType._id, -1)}
                      disabled={!quantities[bedType._id]}
                    >
                      <Minus size={20} />
                    </IconButton>
                    <TextField
                      value={quantities[bedType._id] || 0}
                      onChange={(e) => handleQuantityChange(bedType._id, parseInt(e.target.value, 10) - (quantities[bedType._id] || 0))}
                      inputProps={{ min: 0, style: { textAlign: 'center' } }}
                      sx={{ width: 50, mx: 1 }}
                    />
                    <IconButton
                      size="small"
                      onClick={() => handleQuantityChange(bedType._id, 1)}
                    >
                      <Plus size={20} />
                    </IconButton>
                    <Button
                      variant="contained"
                      className={`${quantities[bedType._id] ? "!bg-[#649ed0] !text-white " : "!bg-gray-300 !text-white"} `}
                      onClick={() => handleAddBedType(bedType)}
                      disabled={!quantities[bedType._id]}
                      sx={{ ml: 2 }}
                    >
                     {quantities[bedType._id] ? <Check size={20} /> : <BedSingle size={20}/>} 
                    </Button>
                  </Box>
                </ListItemSecondaryAction>
              </Paper>
            </ListItem>
          ))}
        </List>
        {availableBedTypes.length === 0 && (
          <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>
            All bed types have been selected.
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default BedTypePopup;