import React from 'react';
import { Plus } from 'lucide-react';

const AddTaskButton = ({ onClick }) => {
  return (
    <button 
      className="flex items-center px-3 py-2 bg-blue-500 hover:bg-blue-600 text-white !rounded-md transition-colors duration-200 shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50"
      onClick={onClick}
    >
      <Plus size={18} className="mr-2" />
      <span className="text-sm font-medium">Add Task</span>
    </button>
  );
};

export default AddTaskButton;