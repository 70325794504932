import React, { useState, useCallback, useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, Chip, CircularProgress } from '@mui/material';
import { addAmenityMapping } from '../services/serverApi.listing';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';

const AddAmenityMapping = ({ open, onClose, getAmenitiesMap, amenities, amenitiesMapping }) => {
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const uniqueAmenities = useMemo(() => {
        const mappedIds = amenitiesMapping.map(mapping => mapping.amenities._id);
        return amenities.filter(amenity => !mappedIds.includes(amenity._id));
    }, [amenities, amenitiesMapping]);

    const handleAdd = useCallback(async () => {
        setIsLoading(true);
        try {
            const newAmenitiesMapping = selectedAmenities;
            const existingAmenityIds = amenitiesMapping.map(mapping => mapping.amenities._id);
            const combinedAmenityIds = [...existingAmenityIds, ...newAmenitiesMapping];

            const response = await addAmenityMapping(combinedAmenityIds);

            if (response.data) {
                getAmenitiesMap();
                toast.success('Modification réussie');
                setSelectedAmenities([]);
                onClose();
            } else {
                console.error('Unexpected API response message:', response.data?.message);
                toast.error('Ajout échoué');
            }
        } catch (error) {
            console.error('Error adding amenity mapping:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            } else if (error.request) {
                console.error('Request data:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
            toast.error('Ajout échoué');
        } finally {
            setIsLoading(false);
        }
    }, [amenitiesMapping, selectedAmenities, getAmenitiesMap, onClose]);

    const handleChange = (event) => {
        const value = event.target.value;
        setSelectedAmenities(typeof value === 'string' ? value.split(',') : value);
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className='bg-medium-aquamarine text-white'>Add New Amenities</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="amenityIds-label">Amenities</InputLabel>
                    <Select
                        labelId="amenityIds-label"
                        id="amenityIds"
                        name="amenityIds"
                        multiple
                        value={selectedAmenities}
                        onChange={handleChange}
                        renderValue={(selected) => (
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={uniqueAmenities.find((amenity) => amenity._id === value)?.name || value} />
                                ))}
                            </div>
                        )}
                    >
                        {uniqueAmenities.map((amenity) => (
                            <MenuItem key={amenity._id} value={amenity._id}>
                                {amenity.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}><span className='text-red-600'>Cancel</span></Button>
                <button className='text-white bg-medium-aquamarine hover:bg-[#59bd9b] shadow-md px-4 py-[2px] rounded-md ' type="submit" onClick={handleAdd}  disabled={isLoading}>
                    {isLoading ? <CircularProgress color='white' size={20} /> :  <AddIcon />}
                </button>
            </DialogActions>
        </Dialog>
    );
};

export default AddAmenityMapping;
