import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    basicInfo: {},
    address: {},
    media: {},
    amenities: {},
    priceAndFees: {},
    additionalInfo: {},
    channelSpecific: {},
    contactPerson: {},
    attachment: {},
    customFields: {},
    financialSettings: {},
    guestPortal: {},
    bedTypes: {},
    licenseInfo: {},
    paymentAccounts: {},
};


const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        createBasicInfo: (state, action) => {
            state.basicInfo = action.payload;
        },
        createAddress: (state, action) => {
            state.address = action.payload;
        },
        createMedia: (state, action) => {
            state.media = action.payload;
        },
        createAmenities: (state, action) => {
            state.amenities = action.payload;
        },
        createPriceAndFees: (state, action) => {
            state.priceAndFees = action.payload;
        },
        createAdditionalInfo: (state, action) => {
            state.additionalInfo = action.payload;
        },
        createChannelSpecific: (state, action) => {
            state.channelSpecific = action.payload;
        },
        createContactPerson: (state, action) => {
            state.contactPerson = action.payload;
        },
        createAttachment: (state, action) => {
            state.attachment = action.payload;
        },
        createCustomFields: (state, action) => {
            state.customFields = action.payload;
        },
        createFinancialSettings: (state, action) => {
            state.financialSettings = action.payload;
        },
        createGuestPortal: (state, action) => {
            state.guestPortal = action.payload;
        },
        createBedTypes: (state, action) => {
            state.bedTypes = action.payload;
        },
        createLicenseInfo: (state, action) => {
            state.licenseInfo = action.payload;
        },
        createPaymentAccounts: (state, action) => {
            state.paymentAccounts = action.payload;
        },
    },
});


export const {
    createBasicInfo,
    createAddress,
    createMedia,
    createAmenities,
    createPriceAndFees,
    createAdditionalInfo,
    createChannelSpecific,
    createContactPerson,
    createAttachment,
    createCustomFields,
    createFinancialSettings,
    createGuestPortal,
    createBedTypes,
    createLicenseInfo,
    createPaymentAccounts,
} = formSlice.actions;


export default formSlice.reducer;