import React, { useState, useEffect } from 'react';
import { Modal, Box } from '@mui/material';
import { Home, Building2, ChevronRight, Check } from 'lucide-react';

export const PropertyTypeSelector = ({ formik, listingId }) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (!listingId) {
            setIsOpen(true);
        }
    }, [listingId]);

    const handleSelect = (type) => {
        formik.setFieldValue('propertyUnit', type);
    };

    const handleContinue = () => {
        setIsOpen(false);
    };

    return (
        <Modal open={isOpen} aria-labelledby="property-type-modal-title">
            <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[500px] bg-white rounded-lg shadow-lg overflow-hidden p-2">
                <div className="bg-white p-6 flex flex-col items-center">
                    <div className="bg-teal-100 rounded-full p-3 my-2">
                        <Home className="text-teal-500 w-6 h-6" />
                    </div>
                    <h2 className="text-xl !font-light text-gray-800">Select Property Type</h2>
                </div>
                <div className="p-6">
                    <button
                        onClick={() => handleSelect('Multi')}
                        className={`w-full border  mb-2 rounded-lg p-4 flex items-center justify-between transition-colors ${formik.values.propertyUnit === 'Multi' ? 'bg-teal-50 !border-teal-500' : 'border-gray-200 hover:bg-gray-50'
                            }`}
                    >
                        <div className="flex gap-2 items-center">
                            <Building2 className={`mr-3 w-8 h-8 p-2 rounded-full ${formik.values.propertyUnit === 'Multi' ? 'text-white bg-teal-500' : 'text-gray-500 bg-gray-200'}`} />
                            <div className="text-left">
                                <p className="font-semibold text-gray-800">Hotel</p>
                                <p className="text-sm text-gray-600">Multi-unit property</p>
                            </div>
                        </div>
                        {formik.values.propertyUnit === 'Multi' ? (
                            <Check className="text-white w-6 h-6 p-1 bg-teal-400 rounded-full" />
                        ) : (
                            <div className="w-6 h-6 p-1 bg-white border-1 border-gray-400 rounded-full" />
                        )}
                    </button>
                    <button
                        onClick={() => handleSelect('Single')}
                        className={`w-full border rounded-lg p-4 flex items-center justify-between transition-colors ${formik.values.propertyUnit === 'Single' ? 'bg-teal-50 !border-teal-500' : 'border-gray-200 hover:bg-gray-50'
                            }`}
                    >
                        <div className="flex gap-2 items-center">
                            <div>
                                <Home className={`mr-3 w-8 h-8 p-2 rounded-full ${formik.values.propertyUnit === 'Single' ? 'text-white bg-teal-500' : 'text-gray-500 bg-gray-200'}`} />
                            </div>
                            <div className="text-left">
                                <p className="font-semibold text-gray-800">Apartment</p>
                                <p className="text-sm text-gray-600">Single unit property</p>
                            </div>
                        </div>
                        {formik.values.propertyUnit === 'Single' ? (
                            <Check className="text-white w-6 h-6 p-1 bg-teal-400 rounded-full" />
                        ) : (
                            <div className="w-6 h-6 p-1 bg-white border-1 border-gray-400 rounded-full" />
                        )}
                    </button>

                </div>
                <button
                    onClick={handleContinue}
                    className="w-full bg-teal-500 text-white py-4 flex items-center justify-center hover:bg-teal-600 transition-colors mt-2"
                >
                    <span className="mr-2">Continue</span>
                    <ChevronRight className="w-5 h-5" />
                </button>
            </Box>
        </Modal>
    );
};

export default PropertyTypeSelector;