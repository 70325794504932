import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updateCountry } from '../services/serverApi.adminConfig';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  currency: Yup.string().required('Currency is required'),
  countryCode: Yup.string().required('Country Code is required'),
});

const ModifyCountryManDialog = ({ open, onClose, onUpdateCountry, dataCountry }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setIsLoading(true);
    setErrorMessage('');
    const dataToSend = {
      country: {
        name: values.name,
        currency: values.currency,
        countryCode: values.countryCode,
      }
    };
    try {
      const response = await updateCountry(dataCountry?._id, dataToSend);
      onUpdateCountry(response.data.country);
      toast.success("Modification réussie");
      resetForm();
      onClose();
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred while updating the country');
      toast.error(error.message || 'An error occurred while updating the country');
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Dialog PaperProps={{ style: { width: 500 } }} open={open} onClose={onClose}>
      <DialogTitle sx={{ padding: '30px', textAlign: 'center' }} className="!bg-medium-aquamarine !text-white mb-4"
      >Modify Country</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            name: dataCountry?.name || '',
            currency: dataCountry?.currency || '',
            countryCode: dataCountry?.countryCode || '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Name</label>
                <Field type="text" name="name" className="form-control" />
                <ErrorMessage name="name" component="div" className="text-danger" />
              </div>
              
              <div className="mb-3">
                <label htmlFor="currency" className="form-label">Currency</label>
                <Field type="text" name="currency" className="form-control" />
                <ErrorMessage name="currency" component="div" className="text-danger" />
              </div>

              <div className="mb-3">
                <label htmlFor="countryCode" className="form-label">Country Code</label>
                <Field type="text" name="countryCode" className="form-control" />
                <ErrorMessage name="countryCode" component="div" className="text-danger" />
              </div>


              
              {errorMessage && <div className="text-danger mb-3">{errorMessage}</div>}

              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" className="!bg-medium-aquamarine text-white" disabled={isSubmitting || isLoading}>
                  {isLoading ? 'Updating...' : 'Update'}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ModifyCountryManDialog;