import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { getDateRange } from '../dateUtils';

const FilterSection = ({ filters, handleFilterChange }) => {
    const handleFilterOptionChange = (option) => {
        const dateRange = getDateRange(option);
        handleFilterChange('dateRange', option);
        handleFilterChange('from', dateRange.start);
        handleFilterChange('to', dateRange.end);
    };

    return (
        <div className="mt-2">
            <div className="flex gap-2 items-center flex-wrap my-4">
                <LocalizationProvider dateAdapter={AdapterDayjs} className="">
                    <DatePicker
                        className="w-60"
                        label="From"
                        value={dayjs(filters.from)}
                        onChange={(newValue) => handleFilterChange('from', newValue ? newValue.format('YYYY-MM-DD') : '')}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                className="bg-white !rounded-sm !bg-[#dcf6f6] text-xs px-1"
                            />
                        )}
                    />
                    <DatePicker
                        className="w-60"
                        label="To"
                        value={dayjs(filters.to)}
                        onChange={(newValue) => handleFilterChange('to', newValue ? newValue.format('YYYY-MM-DD') : '')}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                className="bg-white !rounded-sm !bg-[#dcf6f6] text-xs px-1"
                            />
                        )}
                    />
                </LocalizationProvider>

                <FormControl size="small" className="w-60">
                    <InputLabel>Date Range</InputLabel>
                    <Select
                        value={filters.dateRange || ''}
                        onChange={(e) => handleFilterOptionChange(e.target.value)}
                        label="Date Range"
                    >
                        <MenuItem value="Last month">Last month</MenuItem>
                        <MenuItem value="Current month">Current month</MenuItem>
                        <MenuItem value="Last Year">Last Year</MenuItem>
                        <MenuItem value="Current Year">Current Year</MenuItem>
                        <MenuItem value="Last 12 months">Last 12 months</MenuItem>
                        <MenuItem value="Last 3 months">Last 3 months</MenuItem>
                        <MenuItem value="Last 30 days">Last 30 days</MenuItem>
                        <MenuItem value="Last 7 days">Last 7 days</MenuItem>
                        <MenuItem value="Today">Today</MenuItem>
                        <MenuItem value="YTD">YTD</MenuItem>
                        <MenuItem value="MTD">MTD</MenuItem>
                        <MenuItem value="QTD">QTD</MenuItem>
                        <MenuItem value="WTD">WTD</MenuItem>
                    </Select>
                </FormControl>

                <FormControl size="small" className="w-60">
                    <InputLabel>Reservation Status</InputLabel>
                    <Select
                        value={filters.statuses}
                        onChange={(e) => handleFilterChange('statuses', e.target.value)}
                        label="Reservation Status"
                    >
                        <MenuItem value="1">Status 1</MenuItem>
                        <MenuItem value="2">Status 2</MenuItem>
                        <MenuItem value="3">Status 3</MenuItem>
                        <MenuItem value="4">Status 4</MenuItem>
                    </Select>
                </FormControl>

                <FormControl size="small" className="w-60">
                    <InputLabel>Check-in</InputLabel>
                    <Select
                        value={filters.checkin}
                        onChange={(e) => handleFilterChange('checkin', e.target.value)}
                        label="Check-in"
                    >
                        <MenuItem value="true">Checked-in</MenuItem>
                        <MenuItem value="false">Not Checked-in</MenuItem>
                    </Select>
                </FormControl>

                <span className="cursor-pointer text-base font-medium text-red-500" onClick={() => handleFilterChange('reset', null)}>Reset filters</span>
            </div>
        </div>
    );
};

export default FilterSection;
