import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { createTicket, updateTicket, getCities, getPropertyTypes } from '../services/serviceApi.ticketsConfig';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    whatsApp: Yup.string().required('WhatsApp contact is required'),
    // cityId: Yup.string().required('City is required'),
    // city: Yup.string().required('City name is required'),
    // propertyType: Yup.string().required('Property type name is required'),
    location: Yup.string().required('Location is required'),
    // propertyTypeId: Yup.string().required('Property type is required'),
    propertyCondition: Yup.string().required('Property condition is required'),
    compensationModel: Yup.string().required('Compensation model is required'),
    expectedMonthlyRevenue: Yup.number()
        .typeError('Must be a number')
        .required('Expected monthly revenue is required')
        .min(0, 'Must be a positive number'),
});

const PROPERTY_CONDITIONS = [
    'Under Construction', 'New', 'Renovated', 'To Be Renovated', 'Other'
];

const COMPENSATION_MODELS = ['Fixed', 'Percentage'];

const STATUS_OPTIONS = ['Created', 'Open', 'In Progress', 'Closed'];

const ACCEPTANCE_OPTIONS = ['Accepted', 'Rejected', 'Not processed'];

const TicketsModal = ({ open, onClose, setTickets, ticket }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [cities, setCities] = useState([]);
    const [propertyTypes, setPropertyTypes] = useState([]);

    useEffect(() => {
        fetchCities();
        fetchPropertyTypes();
    }, []);

    const fetchPropertyTypes = async () => {
        setIsLoading(true);
        try {
            const response = await getPropertyTypes();
            setPropertyTypes(response.data.data);
        } catch (error) {
            console.error('Error fetching property types:', error);
            setErrorMessage('Failed to fetch property types. Please try again later.');
            toast.error('Failed to load property types');
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCities = async () => {
        try {
            const data = await getCities();
            setCities(data.data);
        } catch (error) {
            console.error("Error fetching cities:", error);
            toast.error('Failed to load cities');
        }
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        setIsLoading(true);
        setErrorMessage('');
        try {
            let response;
            if (ticket) {
                response = await updateTicket(ticket._id, {
                    ...values,
                    _id: ticket._id
                });
            } else {
                response = await createTicket(values);
            }

            if (response.success) {
                const updatedTicketData = response.ticket || response.data;

                setTickets(prevTickets => {
                    if (ticket) {
                        return prevTickets.map(t =>
                            t._id === ticket._id ? {
                                ...updatedTicketData,
                                _id: ticket._id
                            } : t
                        );
                    } else {
                        return [{
                            ...updatedTicketData,
                            _id: updatedTicketData._id || updatedTicketData.id
                        }, ...prevTickets];
                    }
                });

                toast.success(ticket ? 'Ticket updated successfully' : 'Ticket created successfully');
                onClose();
            } else {
                throw new Error(response.message || 'Operation failed');
            }
        } catch (error) {
            console.error('Error in handleSubmit:', error);
            setErrorMessage(error.message || 'An error occurred while processing your request.');
            toast.error(ticket ? 'Error updating ticket' : 'Error creating ticket');
        } finally {
            setIsLoading(false);
            setSubmitting(false);
        }
    };
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{ticket ? 'Update Ticket' : 'Create New Ticket'}</DialogTitle>
            <DialogContent>
                {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                <Formik
                    initialValues={{
                        firstName: ticket?.firstName || '',
                        lastName: ticket?.lastName || '',
                        email: ticket?.email || '',
                        whatsApp: ticket?.whatsApp || '',
                        cityId: ticket?.cityId || '',
                        city: ticket?.city || '',
                        propertyType: ticket?.propertyType || '',
                        location: ticket?.location || '',
                        propertyTypeId: ticket?.propertyTypeId || '',
                        propertyCondition: ticket?.propertyCondition || '',
                        compensationModel: ticket?.compensationModel || '',
                        expectedMonthlyRevenue: ticket?.expectedMonthlyRevenue || '',
                        status: ticket?.status || 'Created',
                        acceptance: ticket?.acceptance || 'Not processed',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, errors, touched, handleChange }) => (
                        <Form className="mt-2">
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <Field name="firstName">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="First Name"
                                                fullWidth
                                                error={touched.firstName && Boolean(errors.firstName)}
                                                helperText={touched.firstName && errors.firstName}
                                            />
                                        )}
                                    </Field>
                                    <Field name="lastName">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Last Name"
                                                fullWidth
                                                error={touched.lastName && Boolean(errors.lastName)}
                                                helperText={touched.lastName && errors.lastName}
                                            />
                                        )}
                                    </Field>
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <Field name="email">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Email"
                                                fullWidth
                                                error={touched.email && Boolean(errors.email)}
                                                helperText={touched.email && errors.email}
                                            />
                                        )}
                                    </Field>
                                    <Field name="whatsApp">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="WhatsApp"
                                                fullWidth
                                                error={touched.whatsApp && Boolean(errors.whatsApp)}
                                                helperText={touched.whatsApp && errors.whatsApp}
                                            />
                                        )}
                                    </Field>
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <FormControl fullWidth error={touched.status && Boolean(errors.status)}>
                                        <InputLabel>Status</InputLabel>
                                        <Select
                                            name="status"
                                            value={values.status}
                                            onChange={handleChange}
                                            label="Status"
                                        >
                                            {STATUS_OPTIONS.map((status) => (
                                                <MenuItem key={status} value={status}>
                                                    {status}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth error={touched.acceptance && Boolean(errors.acceptance)}>
                                        <InputLabel>Acceptance</InputLabel>
                                        <Select
                                            name="acceptance"
                                            value={values.acceptance}
                                            onChange={handleChange}
                                            label="Acceptance"
                                        >
                                            {ACCEPTANCE_OPTIONS.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <FormControl fullWidth error={touched.cityId && Boolean(errors.cityId)}>
                                        <InputLabel>City</InputLabel>
                                        <Select
                                            name="cityId"
                                            value={values.cityId}
                                            onChange={handleChange}
                                            label="City"
                                        >
                                            {cities.map((city) => (
                                                <MenuItem key={city._id} value={city._id}>
                                                    {city.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Field name="location">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Location"
                                                fullWidth
                                                error={touched.location && Boolean(errors.location)}
                                                helperText={touched.location && errors.location}
                                            />
                                        )}
                                    </Field>
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <FormControl fullWidth error={touched.propertyTypeId && Boolean(errors.propertyTypeId)}>
                                        <InputLabel>Property Type</InputLabel>
                                        <Select
                                            name="propertyTypeId"
                                            value={values.propertyTypeId}
                                            onChange={handleChange}
                                            label="Property Type"
                                        >
                                            {propertyTypes.map((type) => (
                                                <MenuItem key={type._id} value={type._id}>
                                                    {type.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth error={touched.propertyCondition && Boolean(errors.propertyCondition)}>
                                        <InputLabel>Property Condition</InputLabel>
                                        <Select
                                            name="propertyCondition"
                                            value={values.propertyCondition}
                                            onChange={handleChange}
                                            label="Property Condition"
                                        >
                                            {PROPERTY_CONDITIONS.map((condition) => (
                                                <MenuItem key={condition} value={condition}>
                                                    {condition}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <Field name="city">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="City Name"
                                                fullWidth
                                                error={touched.city && Boolean(errors.city)}
                                                helperText={touched.city && errors.city}
                                            />
                                        )}
                                    </Field>
                                    <Field name="propertyType">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Property Type Name"
                                                fullWidth
                                                error={touched.propertyType && Boolean(errors.propertyType)}
                                                helperText={touched.propertyType && errors.propertyType}
                                            />
                                        )}
                                    </Field>

                                </Box>

                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <FormControl fullWidth error={touched.compensationModel && Boolean(errors.compensationModel)}>
                                        <InputLabel>Compensation Model</InputLabel>
                                        <Select
                                            name="compensationModel"
                                            value={values.compensationModel}
                                            onChange={handleChange}
                                            label="Compensation Model"
                                        >
                                            {COMPENSATION_MODELS.map((model) => (
                                                <MenuItem key={model} value={model}>
                                                    {model}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Field name="expectedMonthlyRevenue">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Expected Monthly Revenue (MAD)"
                                                type="number"
                                                fullWidth
                                                error={touched.expectedMonthlyRevenue && Boolean(errors.expectedMonthlyRevenue)}
                                                helperText={touched.expectedMonthlyRevenue && errors.expectedMonthlyRevenue}
                                            />
                                        )}
                                    </Field>
                                </Box>
                            </Box>

                            <DialogActions>
                                <Button onClick={onClose} className="!text-gray-500">
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    className="!bg-medium-aquamarine !text-white"
                                    disabled={isLoading}
                                >
                                    {ticket ? 'Update' : 'Create'}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default TicketsModal;