import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from '@mui/material';
import { Search, FilterList } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { getcities, getcountries } from '../../../services/serverApi.calendar';
import { Eraser, CloudUpload, RotateCw } from 'lucide-react';
import FilterItems from './FilterItems';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ffffff',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  filterToggle: {
    color: '#00b4b4',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  filterContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gap: theme.spacing(2),
    width: '60%',
  },
  formControl: {
    width: '100%',
  },
  clearButton: {
    marginLeft: theme.spacing(2),
    width: '50%',
  },
}));

const FilterCalendar = ({
  onFilterChange,
  onSearchQueryChange,
  onSelectedItemsChange,
  listingsCount,
  selectedItems,
  setSelectedItems,
  onSaveChanges,
  hasPendingChanges,
  setPendingChanges,
}) => {
  const classes = useStyles();
  const [showFilter, setShowFilter] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const options = [
    'Base Price',
    'Available Room',
    'Channex Available',
    'Manual Price',
    'Calculated Price',
    'Reservations',
    'stopSell',
    'min_stay_arrival',
    'max_stay',
    'closed_to_arrival',
    'closed_to_departure',
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [countriesData, citiesData] = await Promise.all([
        getcountries(),
        getcities(),
      ]);
      setCountries(countriesData.data);
      setCities(citiesData.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleFilterChange = () => {
    onFilterChange({
      country: selectedCountry,
      city: selectedCity,
    });
  };

  const handleResetChanges = () => {
    setPendingChanges([]);
  };

  const handleSelectedItemsChange = (newItems) => {
    setSelectedItems(newItems);
    onSelectedItemsChange(newItems);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    onSearchQueryChange(query);
  };

  const handleCountryChange = (e) => {
    const countryId = e.target.value;
    setSelectedCountry(countryId);
    setSelectedCity('');
    handleFilterChange();
  };

  const handleCityChange = (e) => {
    const cityId = e.target.value;
    setSelectedCity(cityId);
    handleFilterChange();
  };

  const handleClearFilter = () => {
    setSelectedCountry('');
    setSelectedCity('');
    setSearchQuery('');
    setSelectedItems(['Base Price', 'Available Room']);
    onFilterChange({
      country: '',
      city: '',
    });
    onSearchQueryChange('');
  };

  useEffect(() => {
    handleFilterChange();
  }, [selectedCountry, selectedCity]);

  const handleSaveChanges = async () => {
    if (!hasPendingChanges) {
      toast.info('No changes to save');
      return;
    }
    await onSaveChanges();
  };

  return (
    <Box className={classes.root}>
      <Box className="flex justify-between">
        <div className="flex gap-1 !w-[40%]">
          <div className="flex items-center p-2 rounded-md shadow-sm bg-white text-gray-700 border-1 border-gray-200">
            <FilterList fontSize="small" />
            <span className="ml-2 text-sm font-medium">Filters</span>
          </div>
          <Box className="flex gap-2 !w-full">
            <FormControl className={classes.formControl}>
              <InputLabel id="country-select-label">Country</InputLabel>
              <Select
                labelId="country-select-label"
                id="country-select"
                value={selectedCountry}
                label="Country"
                onChange={handleCountryChange}
              >
                <MenuItem value="">All countries</MenuItem>
                {countries.map((country) => (
                  <MenuItem key={country.id} value={country.name}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="city-select-label">City</InputLabel>
              <Select
                labelId="city-select-label"
                id="city-select"
                value={selectedCity}
                label="City"
                onChange={handleCityChange}
              >
                <MenuItem value="">All cities</MenuItem>
                {cities.map((city) => (
                  <MenuItem key={city.id} value={city.name}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FilterItems
              selectedItems={selectedItems}
              options={options}
              onItemsChange={handleSelectedItemsChange}
            />
            <div
              onClick={handleClearFilter}
              style={{ cursor: 'pointer' }}
              className="flex items-center gap-1 p-2 rounded-md shadow-sm bg-gray-800 text-white"
            >
              <Eraser size={14} />
              <span className="ml-2 text-sm">Clear</span>
            </div>
          </Box>
        </div>
        <Box className="flex items-center gap-2">
          {hasPendingChanges && (
            <>
              <div
                onClick={handleSaveChanges}
                style={{
                  cursor: hasPendingChanges ? 'pointer' : 'not-allowed',
                  opacity: hasPendingChanges ? 1 : 0.6,
                }}
                className="flex items-center gap-1 p-2 rounded-md shadow-sm bg-green-500 text-white"
              >
                <CloudUpload size={14} />
                <span className="ml-2 text-sm">Save Changes</span>
              </div>
              <div
                onClick={handleResetChanges}
                style={{ cursor: 'pointer' }}
                className="flex items-center gap-1 p-2 rounded-md shadow-sm bg-red-500 text-white"
              >
                <RotateCw size={14} />
                <span className="ml-2 text-sm">Reset Changes</span>
              </div>
            </>
          )}

          {/* <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>
                        Show ({listingsCount})
                    </Typography> */}
          <TextField
            placeholder="Search by listing"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FilterCalendar;
