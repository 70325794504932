import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MDBox from "components/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import Analytics from "../components/Analytics";
import RentalActivity from "../components/rentalActivity/RentalActivity";
import Occupancy from "../components/occupancy/Occupancy";
import Exprenses from "../components/expensesAndExtras/Exprenses";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

function Financial() {
    const [value, setValue] = useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3} >
                <Box sx={{ width: '100%', typography: 'body1' }} className="card">
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="tabs" sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: '#00b4b4',
                                },
                                '& .MuiTab-root': {
                                    color: 'black',
                                    fontWeight: 600,
                                },
                                '& .Mui-selected': {
                                    color: '#00b4b4 !important',
                                },
                            }}>
                                <Tab label="Analytics" value="1" sx={{ fontSize: '0.8rem', textTransform: 'capitalize' }} />
                                <Tab label="Rental Activity" value="2" sx={{ fontSize: '0.8rem', textTransform: 'capitalize' }} />
                                {/* <Tab label="Occupancy Report" value="3" sx={{ fontSize: '0.8rem' , textTransform: 'capitalize' }}/> */}
                                <Tab label="Expenses & Extras" value="4" sx={{ fontSize: '0.8rem', textTransform: 'capitalize' }} />
                            </TabList>
                        </Box>
                        <TabPanel value="1"><Analytics /></TabPanel>
                        <TabPanel value="2"><RentalActivity /></TabPanel>
                        {/* <TabPanel value="3"><Occupancy /></TabPanel> */}
                        <TabPanel value="4"><Exprenses /></TabPanel>

                    </TabContext>
                </Box>
            </MDBox>
        </DashboardLayout>
    );
}

export default Financial;

