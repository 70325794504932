import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Switch, IconButton, FormControlLabel } from '@mui/material';
import { InfoOutlined, AddCircleOutline, RemoveCircleOutline, Save } from '@mui/icons-material';
import NotificationSnackbar from '../EventComponents/NotificationSnackbar';

const RatePlanEvents = ({ ratePlan, onUpdateEvent, isCreateMode = false }) => {
  const [events, setEvents] = useState(ratePlan['adjustments-event']?.events || []);
  const [isActive, setIsActive] = useState(ratePlan?.Active ?? true);
  const [isDirty, setIsDirty] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    setEvents(ratePlan['adjustments-event']?.events || []);
    setIsActive(ratePlan?.Active ?? true);
    setIsDirty(false);
  }, [ratePlan]);

  useEffect(() => {
    if (isCreateMode) {
      onUpdateEvent({ active: isActive, events });
    }
  }, [isCreateMode, events, isActive, onUpdateEvent]);

  const handleAddEvent = () => {
    const newEvent = {
      active: true,
      mon: true, tue: true, wed: true, thu: true, fri: true, sat: true, sun: true,
      event_name: "",
      start_date: new Date().toISOString().split('T')[0],
      end_date: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
      rate_percentage: 100
    };
    setEvents([...events, newEvent]);
    setIsDirty(true);
    setSnackbarOpen(true);
    setSnackbarMessage('Please enter a name for all events before saving.');
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleRemoveEvent = (index) => {
    setEvents(events.filter((_, i) => i !== index));
    setIsDirty(true);
  };

  const handleEventChange = (index, field, value) => {
    const newEvents = [...events];
    newEvents[index] = { ...newEvents[index], [field]: value };
    setEvents(newEvents);
    setIsDirty(true);
  };

  const handleActiveChange = (e) => {
    setIsActive(e.target.checked);
    setIsDirty(true);
  };

  const handleSave = () => {
    onUpdateEvent({ active: isActive, events });
    setIsDirty(false);
  };

  return (
    <div className="mt-4 border p-4 rounded">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Typography className="mr-2">EVENT RULE</Typography>
            <IconButton size="small"><InfoOutlined /></IconButton>
          </div>
          {!isCreateMode && (
            <Switch
              checked={isActive}
              onChange={handleActiveChange}
              sx={{
                '& .MuiSwitch-switchBase.Mui-checked': { color: '#00b4b4' },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#00b4b4' }
              }}
            />
          )}
        </div>
        {!isCreateMode && (
          <Button
            startIcon={<Save />}
            onClick={handleSave}
            disabled={!isDirty}
            variant="contained"
            className="!bg-medium-aquamarine !hover:bg-dark-aquamarine !text-white"
          >
            Save Changes
          </Button>
        )}
      </div>

      {events.map((event, index) => (
        <EventItem
          key={index}
          event={event}
          onEventChange={(field, value) => handleEventChange(index, field, value)}
          onRemoveEvent={() => handleRemoveEvent(index)}
        />
      ))}

      <div className="mt-4">
        <Button
          startIcon={<AddCircleOutline />}
          onClick={handleAddEvent}
          className="!text-xs !text-gray-500 !p-2 !bg-gray-200 !capitalize"
        >
          Add Event
        </Button>
      </div>
      <NotificationSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </div>
  );
};

const EventItem = ({ event, onEventChange, onRemoveEvent }) => {
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? '' : date.toISOString().split('T')[0];
  };

  return (
    <div className="mt-4 border p-4 rounded">
      <div className="flex flex-wrap items-center gap-2">

        <TextField
          label="Start"
          type="date"
          value={formatDate(event.start_date)}
          onChange={(e) => onEventChange('start_date', e.target.value)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="End"
          type="date"
          value={formatDate(event.end_date)}
          onChange={(e) => onEventChange('end_date', e.target.value)}
          size="small"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Scale"
          value={event.rate_percentage ? event.rate_percentage / 100 : ''}
          onChange={(e) => onEventChange('rate_percentage', Math.round(parseFloat(e.target.value) * 100))}
          type="number"
          inputProps={{ min: 0, step: 0.01 }}
          size="small"
        />
        <WeekdaySelector event={event} onEventChange={onEventChange} />
        <TextField
          label="Title"
          value={event.event_name || ''}
          onChange={(e) => onEventChange('event_name', e.target.value)}
          size="small"
        />
        <IconButton onClick={onRemoveEvent} color="error">
          <RemoveCircleOutline />
        </IconButton>
        <FormControlLabel
          control={
            <Switch
              checked={event.active}
              onChange={(e) => onEventChange('active', e.target.checked)}
              sx={{
                '& .MuiSwitch-switchBase.Mui-checked': { color: '#00b4b4' },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#00b4b4' }
              }}
            />
          }
        />
      </div>
    </div>
  );
};

const WeekdaySelector = ({ event, onEventChange }) => {
  const weekdays = [
    { short: 'Su', full: 'sun' },
    { short: 'M', full: 'mon' },
    { short: 'Tu', full: 'tue' },
    { short: 'W', full: 'wed' },
    { short: 'Th', full: 'thu' },
    { short: 'F', full: 'fri' },
    { short: 'Sa', full: 'sat' }
  ];

  const handleDayChange = (day) => {
    onEventChange(day, !event[day]);
  };

  return (
    <div className="flex gap-1">
      {weekdays.map(({ short, full }) => (
        <button
          key={full}
          onClick={() => handleDayChange(full)}
          className={`
            !w-8 
            !h-8 
            !rounded-full 
            !flex 
            items-center 
            !justify-center 
            !text-sm 
            !font-medium 
            !transition-colors 
            !duration-200 
            ${event[full] 
              ? '!bg-green-200 !text-green-800' 
              : '!bg-gray-200 !text-gray-600'
            }
          `}
        >
          {short}
        </button>
      ))}
    </div>
  );
};

export default RatePlanEvents;