import React from 'react';
import { SignIn } from "@clerk/clerk-react";

function SignInPage() {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md space-y-8">
        <div className="mt-8">
          <SignIn
            forceRedirectUrl="/"  
            appearance={{
              elements: {
                socialButtons: {
                  display: 'none'
                },
                formButtonPrimary: {
                  backgroundColor: '#4A5568',
                  '&:hover': { backgroundColor: '#2D3748' },
                },
                formFieldInput: {
                  borderColor: '#E2E8F0',
                },
                footer: {
                  display: 'none'
                },
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default SignInPage;
