import React, { useState ,useEffect} from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Switch, Box, TextField } from '@mui/material';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarToday from '@mui/icons-material/CalendarMonth';
import moment from "moment";
import { updateCalnedar } from '../services/serverApi.calendar';
import {toast} from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import {useDispatch} from 'react-redux';
import {setSlotDate} from '../../../redux/slices/DateSlice';


const ModelSlotDialog = ({ dataSlot, onClose ,dataSlotSelect,setSlotData}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(dataSlot?.date);
  const [endDate, setEndDate] = useState(dataSlot?.date);
  const [checkedDays, setCheckedDays] = useState([]);
  const [dateschecke, setDateschecke] = useState([]);
  const [clonedays, setClonedays] = useState([]);
  const days_of_week = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

  const dispatch = useDispatch();
  
useEffect(() => {
  const start = moment(startDate);
  const end = moment(endDate);
  const dates = [];

  for (let m = moment(start); m.isSameOrBefore(end); m.add(1, 'days')) {
    dates.push(m.format('YYYY-MM-DD'));
  }
  setDateschecke(dates)
  dispatch(setSlotDate(dates));
}, [startDate, endDate, dispatch]);
useEffect(() => {
  if (dateschecke.length >= 7) {
    setCheckedDays(days_of_week.slice()); 
  } else if (dateschecke.length >= 2) {
    const daysInDateschecke = dateschecke.map(date => moment(date).format('dddd'));
    const checked = days_of_week.filter(day => daysInDateschecke.includes(day));
    setCheckedDays(checked);
    setClonedays(days_of_week.filter(day => !checked.includes(day)));
  } else {
    setCheckedDays([]); 
    setClonedays([]);
  }
}, [dateschecke]);
const handleClose = () => {
  dispatch(setSlotDate([]));
  onClose(); 
};
  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true); 
    const dataForm ={
      listingId: values?.listingId,
      startDate: moment(values?.startDate || dataSlot?.date ).format('YYYY-MM-DD') ,
      endDate: moment(values?.endDate || dataSlot?.date ).format('YYYY-MM-DD') ,
      price: values?.price,
      minimumStay: values?.minimumStay,
      maximumStay: values?.maximumStay,
      isAvailable: values?.isAvailable,
      note: values?.note || '',
      days:checkedDays?.length > 0 ? checkedDays : [moment(endDate).format('dddd')]
    }
   updateCalnedar(dataForm)
    .then((data) => {
       const updatedArr = dataSlotSelect.map(obj => {
        const matchingUpdate = data?.data?.postUpdateDocs?.find(item => moment(item?.date ).format('YYYY-MM-DD')=== moment(obj?.date ).format('YYYY-MM-DD'));
        if (matchingUpdate) {
          return { ...obj, ...matchingUpdate };
        }
        return obj;
      });
      setSlotData(updatedArr);
      setSubmitting(false);
      onClose();
      toast.success("updated Successfully");
      handleClose()
      
      })
     .catch(error => {
       })
      .finally(() => {
       setIsLoading(false); 
     });
 };
  const isCurrentMonth = () => {
    if (dataSlot?.date) {
      const currentDate = moment();
      const dataSlotDate = moment(dataSlot.date);
  
      if (currentDate.isAfter(dataSlotDate, 'day')) {
          return false;
      } else {
          return true; 
      }
  }
  };
  const handleCheckboxChange = (day) => {
   
  
    if (checkedDays.includes(day)) {
      if(checkedDays?.length > 1){
      setCheckedDays(checkedDays.filter(d => d !== day));
      }
    } else {
      setCheckedDays([...checkedDays, day]);
    }
   
   
  };
const CustomInput = React.forwardRef(({ value, onClick ,disabled}, ref) => (
  <button  type='button' className="custom-input"  style={{color: disabled ? '#666666' : 'black',}}
   onClick={onClick}
    ref={ref}
     disabled={disabled} >  
    {value}
    <CalendarToday className="calendar-icon" />
  </button>
)); 
  return (
    <Dialog PaperProps={{ style: { width: '400px', minHeight: '500px' } }} open={true} onClose={handleClose}>
     
      <DialogTitle sx={{ padding: '30px', textAlign: 'center' }}>
        Manage dates
      <Box sx={{float:'right',cursor:'pointer' }} onClick={handleClose} ><CloseIcon /></Box>
        </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            listingId: dataSlot?.sojoriId || '',
            startDate: '',
            endDate: '',
            price: dataSlot?.price || '',
            minimumStay: dataSlot?.minimumStay || '',
            maximumStay: dataSlot?.maximumStay || '',
            isAvailable: dataSlot?.isAvailable  || false,
            note: dataSlot?.note || ''
          }}
          // validationSchema={Yup.object({
          //   note: Yup.string()
          //     .max(500, 'Must be 500 characters or less')
          //     .required('Required'),
          // })}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting,setFieldValue,dirty }) => (
            <Form encType="multipart/form-data">
              <div className="mb-3" style={{ borderBottom: '1px solid #e7eaf3', padding: '17px 0' }}>
              <div className="date-picker-container">
      <div className="date-picker">
        <label>Start date</label>
        <DatePicker
          selected={startDate}
           minDate={new Date()}
           maxDate={startDate}
          onChange={(date) => {
            setStartDate(date)
            setFieldValue('startDate', date);
          }}
          dateFormat="yyyy-MM-dd"
           disabled={!isCurrentMonth()}
          customInput={<CustomInput disabled={!isCurrentMonth()} />}
        />
      </div>
      <div className="date-picker">
        <label>End date</label>
        <DatePicker
          selected={endDate}
          onChange={(date) => {
            setEndDate(date)
             setFieldValue('endDate', date);
          }}
          dateFormat="yyyy-MM-dd"
           minDate={dataSlot?.date}
          disabled={!isCurrentMonth()}
          customInput={<CustomInput disabled={!isCurrentMonth()} />}
        /> 
        <div>
        {dateschecke?.length > 1 ? `${dateschecke.length - 1} Nights` : ''}
          </div>
      </div>
    </div>
     {/* ---------------------- start days of the week ------------------ */}
              <div style={{ marginLeft:'55px', marginTop:'35px'}}> 
                      <div className='Grid row'>
                      {checkedDays?.length > 0 && days_of_week.map(day => (
                      
                          <div className='col-6 GridCell' key={day}>
                        <label >
                          <input
                            type="checkbox"
                            value={day}
                            disabled={clonedays.includes(day)}
                            checked={clonedays.includes(day) || checkedDays.includes(day)}
                            onChange={() => clonedays.includes(day) ? null : handleCheckboxChange(day)}
                          />
                          <span style={{marginLeft:'3px'}}> {day}</span>

                        </label>
                        </div>
                      ))}
                </div>
                    </div>
              </div>
            
              <div className="mb-3 text-center" style={{ borderBottom: '1px solid #e7eaf3', padding: '17px 0' }}>
                <Accordion defaultExpanded style={{ boxShadow: 'none', textAlign: 'start' }}>
                  <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
                    <span className='slotsAccord'>Pricing</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="mb-3 ">
                      <label className="UiFieldLabel">Nightly base rate before mark-up</label>
                      <Field className='form-control' type="text"  name="price" disabled={!isCurrentMonth()} />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="mb-3 text-center" style={{ borderBottom: '1px solid #e7eaf3', padding: '17px 0' }}>
                <Accordion defaultExpanded style={{ boxShadow: 'none', textAlign: 'start' }}>
                  <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
                    <span className='slotsAccord'>Availability</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="mb-3 ">
                      <label className="UiFieldLabel">Minimum stay</label>
                      <Field className='form-control' type="text" name="minimumStay" disabled={!isCurrentMonth()} />
                    </div>
                    <div className="mb-5 ">
                      <label className="UiFieldLabel">Maximum stay</label>
                      <Field className='form-control' type="text" name="maximumStay" disabled={!isCurrentMonth()} />
                    </div>
                    <div className="mb-3 ">
                      <label className="UiFieldLabel">Availability</label>
                      <div>
                        <Field as={Switch} type="checkbox"style={{...(dataSlot?.isAvailable === false && { color: 'gray' })}} name="isAvailable" className="form-check-input" disabled={!isCurrentMonth()} />
                        <span> Availability</span>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className="mb-3 text-center" style={{ borderBottom: '1px solid #e7eaf3', padding: '17px 0' }}>
                <Accordion style={{ boxShadow: 'none' }}>
                  <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
                    <span className='slotsAccord'>Calendar notes</span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="mb-3">
                      < Field  as="textarea"
                  id="note"  placeholder="Save calendar notes for the selected dates" className='form-control' name='note' />
                    </div>
                  </AccordionDetails>
                </Accordion>
                <ErrorMessage name="note" component="div" className="text-danger" />

              </div>
               <DialogActions>
                 <Button type="submit" color="primary" disabled={isSubmitting || !dirty} >
                  {isLoading ? 'Saving...' : 'Save'}
               </Button>
             </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent> 
    </Dialog>
  );
};

export default ModelSlotDialog;



  