import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getRoomTypes, deleteRoomTypes } from '../services/serverApi.listing.js';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import ModifyRoomType from './ModifyRoomType';
import { Modal, Box, Typography } from '@mui/material';

function RoomTypeConfig() {
    const [roomTypes, setRoomTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [openModifyDialog, setOpenModifyDialog] = useState(false);
    const [selectedRoomType, setSelectedRoomType] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [roomTypeToDelete, setRoomTypeToDelete] = useState(null);

    const fetchRoomTypes = async () => {
        setIsLoading(true);
        try {
            const response = await getRoomTypes();
            if (response.data && Array.isArray(response.data.data)) {
                setRoomTypes(response.data.data);
            } else {
                setRoomTypes([]);
            }
        } catch (error) {
            console.error('Error fetching room types:', error);
            setRoomTypes([]);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchRoomTypes();
    }, []);

    const handleOpenModifyDialog = (index, roomType) => {
        setSelectedRoomType(roomType);
        setSelectedIndex(index);
        setIsCreating(false);
        setOpenModifyDialog(true);
    };

    const handleCloseModifyDialog = () => {
        setOpenModifyDialog(false);
        setSelectedRoomType(null);
        setSelectedIndex(null);
        setIsCreating(false);
    };

    const handleOpenDeleteModal = (roomType) => {
        setRoomTypeToDelete(roomType);
        setOpenDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
        setRoomTypeToDelete(null);
    };

    const handleConfirmDelete = async () => {
        if (roomTypeToDelete) {
            try {
                await deleteRoomTypes(roomTypeToDelete._id);
                setRoomTypes((prevList) => prevList.filter((item) => item._id !== roomTypeToDelete._id));
                toast.success('Room Type deleted successfully');
            } catch (error) {
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error('Error deleting room type');
                }
            } finally {
                handleCloseDeleteModal();
            }
        }
    };

    const handleCreateRoomType = (newRoomType) => {
        setRoomTypes(prevRoomTypes => [...prevRoomTypes, newRoomType]);
    };

    const handleUpdateRoomType = (updatedRoomType, index) => {
        setRoomTypes(prevRoomTypes => {
            const newRoomTypes = [...prevRoomTypes];
            newRoomTypes[index] = updatedRoomType;
            return newRoomTypes;
        });
    };

    const columns = [
        {
            field: 'iconUrl',
            header: 'Icon',
            body: (rowData) => (
                rowData && rowData.iconUrl ? (
                    <img 
                        src={rowData.iconUrl} 
                        alt={rowData.type} 
                        style={{ width: '70px', height: '70px', objectFit: 'contain' }} 
                    />
                ) : null
            ),
            headerStyle: { width: '100px' }
        },
        {
            field: 'type',
            header: 'Type',
            headerStyle: { width: '200px' }
        },
        {
            field: 'actions',
            header: 'Action',
            body: (rowData) => (
                <>
                    <IconButton 
                        className="!p-[7px] !rounded-lg bg-white shadow-sm"
                        onClick={() => handleOpenModifyDialog(roomTypes.indexOf(rowData), rowData)}
                    >
                        <ModeEditOutlineOutlinedIcon style={{ color: '#66cdaa' }} />
                    </IconButton>
                    <IconButton 
                        className="!p-[7px] !rounded-lg bg-white shadow-sm mx-2"
                        onClick={() => handleOpenDeleteModal(rowData)}
                    >
                        <DeleteIcon style={{ color: '#ff6b6b' }} />
                    </IconButton>
                </>
            ),
            headerStyle: { width: '100px' }
        }
    ];

    return (
        <>
            <Button 
                onClick={() => {
                    setIsCreating(true);
                    setSelectedRoomType({});
                    setOpenModifyDialog(true);
                }}
                className='mb-3 !bg-[#00b4b4]'
            >
                <span className='!text-white'>Create Room Type</span>
            </Button>
            <ToastContainer position="top-right" autoClose={3000} />
            <GlobalTable
                data={roomTypes}
                columns={columns}
                page={page}
                onPageChange={setPage}
                isNextDisabled={false}
                hasPagination={false}
                limit={limit}
                onLimitChange={setLimit}
                rowsPerPageOptions={[5, 10, 20, 50]}
            />
            {(selectedRoomType || isCreating) && (
                <ModifyRoomType
                    open={openModifyDialog}
                    onClose={handleCloseModifyDialog}
                    setRoomTypes={setRoomTypes}
                    roomTypes={roomTypes}
                    index={selectedIndex}
                    dataRoomType={selectedRoomType}
                    isCreating={isCreating}
                    onCreateRoomType={handleCreateRoomType}
                    onUpdateRoomType={handleUpdateRoomType}
                />
            )}
            <Modal
                open={openDeleteModal}
                onClose={handleCloseDeleteModal}
            >
                <Box className="absolute top-36 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[550px] rounded-sm shadow-[24] bg-[#f8fafb]">
                    <Box className="w-full mb-11 p-4">
                        <Typography className="!text-[14px] !text-center" id="modal-modal-description">
                            Are you sure you want to delete this Room Type?
                        </Typography>
                    </Box>
                    <Box className="flex justify-end p-3 bg-white border-t-1 border-gray-200">
                        <Box className="flex gap-2">
                            <button
                                className="px-3 py-1 bg-red-500 text-white !rounded-sm mr-2 !text-[16px]"
                                onClick={handleCloseDeleteModal}
                            >
                                Cancel
                            </button>
                            <button
                                className="px-3 py-1 bg-[#d6fff7] text-[#70d4c0] !rounded-sm mr-2 !text-[16px]"
                                onClick={handleConfirmDelete}
                            >
                                Confirm
                            </button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

export default RoomTypeConfig;