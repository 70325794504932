import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

function MessageEditModal({ open, onClose, onSubmit, message }) {
  const [editedContent, setEditedContent] = useState('');

  useEffect(() => {
    if (message) {
      setEditedContent(message.content);
    }
  }, [message]);

  const handleSubmit = () => {
    onSubmit(editedContent);
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          minWidth: '600px', 
          width: '80%',
          maxWidth: '900px', 
        },
      }}
    >
      <DialogTitle>Edit Message</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          multiline
          rows={4}
          value={editedContent}
          onChange={(e) => setEditedContent(e.target.value)}
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MessageEditModal;