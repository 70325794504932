import styled from 'styled-components';

const TableStyle = styled.div`
  .table-container {
    overflow-x: auto;
    width: 100%;
  }
  table {
    table-layout: fixed;
  }
    
  
  tr:nth-child(even),
  tr:nth-child(odd) {
    background-color: white;
    color: black;
  }

  tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  td,
  th {
    font-size: 14px;
    border: none;
    white-space: nowrap; 
    text-align: start;
    padding: 8px; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  td {
    height: 60px;
  }

  th {
    background: white;
    border-bottom: 1px solid #dfe1e6;
    min-width: 80px;
    max-width: 100px;
  }

  .p-sortable-column .p-sortable-column-icon {
    color: #808080; 
  }

  .p-sortable-column:hover .p-sortable-column-icon {
    color: #606060; 
  }

  .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #404040;
  }
`;

export default TableStyle;