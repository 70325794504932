import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Switch } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { updateOpenAiConfig } from '../services/serverApi.adminConfig';
import { toast } from 'react-toastify';

const validationSchema = Yup.object({
  type: Yup.string().required('Type is Required'),
  description_openai: Yup.string().required('Description is Required'),
  enable: Yup.boolean().required('Enable is Required'),
});

const ModOpeniaConfigTop = ({ open, onClose, openAiConfigItem, setOpenAiConfigItem, dataOpenAi, openAiIndex }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      type: '',
      description_openai: '',
      enable: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setIsLoading(true);
      const dataNew = dataOpenAi;
      dataNew.type = values?.type;
      dataNew.description_openai = values?.description_openai;
      dataNew.enable = values?.enable;
      updateOpenAiConfig(dataOpenAi._id, dataNew)
        .then((data) => {
          let newConfig = [...openAiConfigItem];
          newConfig[openAiIndex] = data.data.openAiConfig;
          setOpenAiConfigItem(newConfig);
          resetForm();
          onClose();
          toast.success("Modified Successfully");
        })
        .catch((error) => {
          setErrorMessage(error.message); // Assuming error.message contains error text
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {
    if (dataOpenAi) {
      formik.setValues({
        type: dataOpenAi.type || '',
        description_openai: dataOpenAi.description_openai || '',
        enable: dataOpenAi.enable || false,
      });
    }
  }, [dataOpenAi]);

  return (
    <Dialog PaperProps={{ style: { width: 500 } }} open={open} onClose={onClose}>
      <DialogTitle sx={{ padding: '30px', textAlign: 'center' }}>Modify OpenAi Config</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3 text-center">
            <label htmlFor="type" className="form-label">
              Type
            </label>
            <input
              className="form-control"
              type="text"
              id="type"
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.type && formik.errors.type ? (
              <div className="text-danger">{formik.errors.type}</div>
            ) : null}
          </div>
          <div className="mb-3 text-center">
            <label htmlFor="description_openai" className="form-label">
              Description
            </label>
            <textarea
              className="form-control"
              id="description_openai"
              name="description_openai"
              value={formik.values.description_openai}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.description_openai && formik.errors.description_openai ? (
              <div className="text-danger">{formik.errors.description_openai}</div>
            ) : null}
          </div>
          <div className="mb-3 text-center">
            <label htmlFor="enable" className="form-label">
              Enable
            </label>
            <Switch
              id="enable"
              checked={formik.values.enable}
              onChange={(event) => {
                formik.setFieldValue('enable', event.target.checked);
              }}
            />
            {formik.touched.enable && formik.errors.enable ? (
              <div className="text-danger">{formik.errors.enable}</div>
            ) : null}
          </div>

          <DialogActions>
            <Button onClick={onClose} color="secondary">
              Cancel
            </Button>
            <Button type="submit" color="primary" disabled={formik.isSubmitting}>
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ModOpeniaConfigTop;
