import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';
import RatePlanEvents from './RatePlanEvents';
import NotificationSnackbar from '../EventComponents/NotificationSnackbar';

const CreateRatePlanDialog = ({ open, onClose, ratePlan, onRatePlanChange, onCreateRatePlan }) => {
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [titleError, setTitleError] = useState('');

    useEffect(() => {
        if (open) {
            setNotificationMessage('Please add new events and save changes before clicking Create.');
            setNotificationOpen(true);
            validateTitle(ratePlan.name);
        }
    }, [open, ratePlan.name]);

    const validateTitle = (title) => {
        if (!title || title.trim() === '') {
            setTitleError('Rate plan name is required');
            return false;
        }
        if (title.length > 100) {
            setTitleError('Rate plan name must be less than 100 characters');
            return false;
        }
        const validCharacters = title.split('').every(char => 
            (char >= 'a' && char <= 'z') ||
            (char >= 'A' && char <= 'Z') ||
            (char >= '0' && char <= '9') ||
            char === ' ' ||
            char === '-' ||
            char === '_'
        );
        
        if (!validCharacters) {
            setTitleError('Rate plan name can only contain letters, numbers, spaces, hyphens, and underscores');
            return false;
        }
        setTitleError('');
        return true;
    };

    const handleTitleChange = (e) => {
        const newTitle = e.target.value;
        const filteredTitle = newTitle.replace(/[^a-zA-Z0-9\s\-_]/g, '');
        handleChange('name', filteredTitle);
        validateTitle(filteredTitle);
    };

    const handleChange = (field, value) => {
        onRatePlanChange({ ...ratePlan, [field]: value });
        setHasUnsavedChanges(true);
    };

    const handleClose = () => {
        if (hasUnsavedChanges) {
            if (window.confirm("You have unsaved changes. Are you sure you want to close?")) {
                resetForm();
                onClose();
            }
        } else {
            resetForm();
            onClose();
        }
    };

    const resetForm = () => {
        setHasUnsavedChanges(false);
        setTitleError('');
        setNotificationOpen(false);
    };

    const handleCreateRatePlan = () => {
        if (!validateTitle(ratePlan.name)) {
            setNotificationMessage('Please fix the rate plan name issues before creating');
            setNotificationOpen(true);
            return;
        }

        if (!ratePlan['adjustments-event']?.events?.length) {
            setNotificationMessage('Please add at least one event before creating the rate plan');
            setNotificationOpen(true);
            return;
        }

        onCreateRatePlan();
        resetForm();
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: '70rem',
                        maxWidth: '100%',
                    },
                }}
            >
                <DialogTitle className="border-b pb-3">Create Rate Plan</DialogTitle>
                <DialogContent className="mt-4">
                    <div className="mb-6">
                        <TextField
                            label="Rate Plan Name"
                            value={ratePlan.name || ''}
                            onChange={handleTitleChange}
                            fullWidth
                            margin="normal"
                            error={!!titleError}
                            helperText={titleError}
                            inputProps={{
                                maxLength: 100
                            }}
                            className="!mb-4"
                        />
                    </div>
                    <RatePlanEvents
                        ratePlan={ratePlan}
                        onUpdateEvent={(updatedEvent) => handleChange('adjustments-event', updatedEvent)}
                        isCreateMode={true}
                    />
                </DialogContent>
                <DialogActions className="border-t p-4">
                    <Button 
                        onClick={handleClose} 
                        className="!text-gray-600 !mr-2"
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleCreateRatePlan}
                        disabled={!hasUnsavedChanges || !!titleError}
                        className={`!px-6 !py-2 ${!hasUnsavedChanges || !!titleError 
                            ? '!bg-gray-300 !text-gray-500' 
                            : '!bg-medium-aquamarine !text-white !hover:bg-dark-aquamarine'}`}
                    >
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <NotificationSnackbar
                open={notificationOpen}
                onClose={() => setNotificationOpen(false)}
                message={notificationMessage}
                severity={titleError ? "error" : "info"}
            />
        </>
    );
};

export default CreateRatePlanDialog;