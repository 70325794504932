import { useState, useCallback, useEffect } from 'react';

export const useCalendar = (initialDate = new Date()) => {
    const [currentDate, setCurrentDate] = useState(initialDate);
    const [viewType, setViewType] = useState('range');
    const [daysInView, setDaysInView] = useState(10);
    const [scrollOffset, setScrollOffset] = useState(0);


    const getDaysBetween = (start, end) => {
        const oneDay = 24 * 60 * 60 * 1000; 
        return Math.ceil((end - start) / oneDay);
    };
    
    const getDaysInView = useCallback((dateRange) => {
        if (!dateRange || dateRange.length === 0 || !dateRange[0].startDate || !dateRange[0].endDate) {
            const defaultDays = [];
            const defaultStart = new Date(currentDate);
            for (let i = 0; i < 10; i++) {
                const day = new Date(defaultStart);
                day.setDate(defaultStart.getDate() + i);
                defaultDays.push(day);
            }
            return defaultDays;
        }

        const days = [];
        const startDate = new Date(dateRange[0].startDate);
        const endDate = new Date(dateRange[0].endDate);
        

        const totalDays = getDaysBetween(startDate, endDate) + 1;
        
        for (let i = 0; i < totalDays; i++) {
            const day = new Date(startDate);
            day.setDate(startDate.getDate() + i);
            days.push(day);
        }
        return days;
    }, [currentDate]);

    const goToNextPeriod = useCallback((dateRange) => {
        setCurrentDate(date => {
            const newDate = new Date(date);
            if (viewType === 'range' && dateRange && dateRange.length > 0) {
                const totalDays = getDaysBetween(
                    dateRange[0].startDate,
                    dateRange[0].endDate
                );
                newDate.setDate(date.getDate() + totalDays);
            } else {
                newDate.setDate(date.getDate() + daysInView);
            }
            return newDate;
        });
    }, [daysInView, viewType]);


    const goToPreviousPeriod = useCallback((dateRange) => {
        setCurrentDate(date => {
            const newDate = new Date(date);
            if (viewType === 'range' && dateRange && dateRange.length > 0) {
                const totalDays = getDaysBetween(
                    dateRange[0].startDate,
                    dateRange[0].endDate
                );
                newDate.setDate(date.getDate() - totalDays);
            } else {
                newDate.setDate(date.getDate() - daysInView);
            }
            return newDate;
        });
    }, [daysInView, viewType]);


    const goToToday = useCallback(() => {
        setCurrentDate(new Date());
        setScrollOffset(0);
    }, []);

 
    const changeMonthView = useCallback((direction) => {
        setCurrentDate(date => {
            const newDate = new Date(date);
            newDate.setMonth(date.getMonth() + (direction === 'next' ? 1 : -1));
            return newDate;
        });
        setViewType('Month');
    }, []);

    const scrollBackward = useCallback(() => {
        setScrollOffset(prev => prev + 7);
    }, []);

    const scrollForward = useCallback(() => {
        setScrollOffset(prev => prev - 7);
    }, []);


    useEffect(() => {
        switch (viewType) {
            case 'range':
                break;
            case '10 Day':
                setDaysInView(10);
                break;
            case '14 Day':
                setDaysInView(14);
                break;
            case '20 Day':
                setDaysInView(20);
                break;
            case '30 Day':
                setDaysInView(30);
                break;
            case 'Month':
                break;
            default:
                setDaysInView(10);
        }
    }, [viewType]);

    const getFirstDayOfMonth = useCallback(() => {
        const date = new Date(currentDate);
        date.setDate(1);
        return date;
    }, [currentDate]);

    const getLastDayOfMonth = useCallback(() => {
        const date = new Date(currentDate);
        date.setMonth(date.getMonth() + 1);
        date.setDate(0);
        return date;
    }, [currentDate]);


    const isToday = useCallback((date) => {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    }, []);


    const formatDate = useCallback((date) => {
        return date.toISOString().split('T')[0];
    }, []);

    return {
        currentDate,
        setCurrentDate,
        viewType,
        setViewType,
        goToNextPeriod,
        goToPreviousPeriod,
        goToToday,
        daysInView: getDaysInView(),
        scrollBackward,
        scrollForward,
        scrollOffset,
        changeMonthView,
        getDaysInView,
        getFirstDayOfMonth,
        getLastDayOfMonth,
        isToday,
        formatDate
    };
};

export default useCalendar;