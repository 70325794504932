import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import './TableCalendar.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useCalendar } from '../hooks/useCalendar';
import moment from 'moment';
import UpdateInventory from './UpdateInventory';
import SidebarProperty from './SideBar/SidebarProperty';
import ReservationPopup from './ReservationPopup';
import { CircleSlash2 } from 'lucide-react';

const TableCalendar = ({
  listings,
  formattedDateRange,
  onDateRangeChange,
  selectedItems,
  dateRange: initialDateRange,
  setIsInventoryUpdated,
  onInventoryUpdate,
  pendingChanges,
  showNotification,
}) => {
  const [collapsedProperties, setCollapsedProperties] = useState({});
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [dateRange, setDateRange] = useState(initialDateRange);
  const datePickerRef = useRef(null);
  const { getDaysInView } = useCalendar(dateRange[0].startDate);
  const daysInView = getDaysInView(dateRange);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [showReservationsPopup, setShowReservationsPopup] = useState(false);
  const [selectedReservations, setSelectedReservations] = useState(null);
  const [selectedRestriction, setSelectedRestriction] = useState('manualPrice');

  console.log('pendingChanges', pendingChanges);

  const getPendingChangeStyle = (dateStr, field, value, roomType) => {
    const pending = pendingChanges?.find(
      (change) =>
        change.type === field &&
        change.roomTypeId === roomType?.id &&
        isDateInRange(dateStr, change),
    );

    if (pending) {
      if (field === 'stopSell' && pending.stopSell) {
        return {
          backgroundColor: '#fee2e2',
          position: 'relative',
          cursor: 'help',
          transition: 'all 0.2s ease',
        };
      }
      return {
        backgroundColor: '#fff7e6',
        position: 'relative',
        cursor: 'help',
        transition: 'all 0.2s ease',
      };
    }
    return {};
  };

  const getPendingTooltipContent = (dateStr, field, currentValue, roomType) => {
    const pending = pendingChanges?.find(
      (change) =>
        change.type === field &&
        change.roomTypeId === roomType?.id &&
        isDateInRange(dateStr, change),
    );

    if (pending) {
      const dateRange = `${moment(pending.date_from).format(
        'MMM DD',
      )} - ${moment(pending.date_to).format('MMM DD')}`;
      const daysText =
        pending.days?.length > 0
          ? `on ${pending.days.map((d) => d.toUpperCase()).join(', ')}`
          : '';

      switch (field) {
        case 'manualPrice':
          return `Price change: ${currentValue} → ${pending.price}\n${dateRange} ${daysText}`;
        case 'availability':
          return `Availability change: ${currentValue} → ${pending.availableRoom}\n${dateRange} ${daysText}`;
        case 'stopSell':
          return `Stop sell change: ${currentValue ? 'Yes' : 'No'} → ${
            pending.stopSell ? 'Yes' : 'No'
          }\n${dateRange} ${daysText}`;
        case 'min_stay_arrival':
          return `min stay_arrival change: ${currentValue} → ${pending.min_stay_arrival}\n${dateRange} ${daysText}`;
        case 'max_stay':
          return `max stay change: ${currentValue} → ${pending.max_stay}\n${dateRange} ${daysText}`;
        case 'closed_to_arrival':
          return `closed to arrival change: ${currentValue ? 'Yes' : 'No'} → ${
            pending.closed_to_arrival ? 'Yes' : 'No'
          }\n${dateRange} ${daysText}`;
        case 'closed_to_departure':
          return `closed to departure change: ${
            currentValue ? 'Yes' : 'No'
          } → ${
            pending.closed_to_departure ? 'Yes' : 'No'
          }\n${dateRange} ${daysText}`;
        default:
          return '';
      }
    }
    return '';
  };

  const handleReservationClick = (reservations) => {
    setSelectedReservations(reservations);
    setShowReservationsPopup(true);
  };

  const getDaysDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return Math.ceil((end - start) / (1000 * 60 * 60 * 24)) + 1;
  };

  const handleTodayClick = () => {
    const today = new Date();
    const newRange = {
      startDate: today,
      endDate: new Date(today.getTime() + 9 * 24 * 60 * 60 * 1000),
      key: 'selection',
    };
    setDateRange([newRange]);
    onDateRangeChange(newRange.startDate, newRange.endDate);
    setShowDatePicker(false);
  };

  const handlePreviousPeriod = () => {
    const currentStart = new Date(dateRange[0].startDate);
    const currentEnd = new Date(dateRange[0].endDate);
    const rangeDays = getDaysDifference(currentStart, currentEnd);

    const newEndDate = new Date(currentStart);
    newEndDate.setDate(newEndDate.getDate() - 1);

    const newStartDate = new Date(newEndDate);
    newStartDate.setDate(newStartDate.getDate() - (rangeDays - 1));

    const newRange = {
      startDate: newStartDate,
      endDate: newEndDate,
      key: 'selection',
    };

    setDateRange([newRange]);
    onDateRangeChange(newRange.startDate, newRange.endDate);
  };

  const handleNextPeriod = () => {
    const currentStart = new Date(dateRange[0].startDate);
    const currentEnd = new Date(dateRange[0].endDate);
    const rangeDays = getDaysDifference(currentStart, currentEnd);

    const newStartDate = new Date(currentEnd);
    newStartDate.setDate(newStartDate.getDate() + 1);

    const newEndDate = new Date(newStartDate);
    newEndDate.setDate(newEndDate.getDate() + (rangeDays - 1));

    const newRange = {
      startDate: newStartDate,
      endDate: newEndDate,
      key: 'selection',
    };

    setDateRange([newRange]);
    onDateRangeChange(newRange.startDate, newRange.endDate);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    setDateRange(initialDateRange);
  }, [initialDateRange]);

  const toggleProperty = (propertyId) => {
    setCollapsedProperties((prev) => ({
      ...prev,
      [propertyId]: !prev[propertyId],
    }));
  };

  const handleRangeChange = (ranges) => {
    onDateRangeChange(ranges.selection.startDate, ranges.selection.endDate);
  };

  const isDateInRange = (dateStr, pendingChange) => {
    if (!pendingChange) return false;
    const date = moment(dateStr);
    const startDate = moment(pendingChange.date_from);
    const endDate = moment(pendingChange.date_to);

    if (date.isBetween(startDate, endDate, 'day', '[]')) {
      if (pendingChange.days && pendingChange.days.length > 0) {
        const dayOfWeek = date.format('dd').toLowerCase();
        return pendingChange.days.includes(dayOfWeek);
      }
      return true;
    }
    return false;
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const dayName = d.toLocaleDateString('en-US', { weekday: 'short' });
    const day = d.getDate();
    const month = d.toLocaleDateString('en-US', { month: 'short' });

    return (
      <div className="flex flex-col items-center w-full !text-[12px]">
        <div className="font-light">{dayName}</div>
        <div className="font-bold">{day}</div>
        <div className="font-light">{month}</div>
      </div>
    );
  };

  const columns = [
    {
      field: 'property',
      header: (
        <SidebarProperty
          onPreviousPeriod={handlePreviousPeriod}
          onNextPeriod={handleNextPeriod}
          onTodayClick={handleTodayClick}
          onDateRangeChange={(range) => handleRangeChange({ selection: range })}
          formattedDateRange={formattedDateRange}
          dateRange={dateRange[0]}
          showDatePicker={showDatePicker}
          setShowDatePicker={setShowDatePicker}
        />
      ),
      frozen: true,
      style: { width: '260px', minWidth: '260px', backgroundColor: '#f8fafc' },
    },

    ...daysInView.map((day, index) => ({
      field: `day${index}`,
      header: formatDate(day),
      style: { width: '80px', padding: '2px', minWidth: '80px' },
    })),
  ];

  const data = listings.flatMap((property) => {
    const propertyRow = {
      id: property.id,
      property: (
        <div className="property-cell">
          <div
            className="cursor-pointer flex items-center gap-2 hover:bg-gray-50 w-full"
            onClick={() => toggleProperty(property.id)}
          >
            {!collapsedProperties[property.id] ? (
              <ArrowRightIcon
                className="text-gray-600"
                style={{ fontSize: 20 }}
              />
            ) : (
              <ArrowDropDownIcon
                className="text-gray-600"
                style={{ fontSize: 20 }}
              />
            )}
            <span className="property-name !text-teal-600 font-medium">
              {property.name}
            </span>
          </div>
        </div>
      ),
      isProperty: true,
      propertyId: property.id,
      ...daysInView.reduce((acc, day, index) => {
        const dateStr = moment(day).format('YYYY-MM-DD');
        const availabilityForDay = property.totalAvailableRoomsByDay?.find(
          (item) => item.date === dateStr,
        );

        acc[`day${index}`] = (
          <div className="flex flex-col items-center">
            <div
              className={`px-2 rounded-md text-center font-semibold !text-[13px] ${
                availabilityForDay?.available === 0
                  ? 'bg-red-100 text-red-700'
                  : 'bg-green-100 text-green-700'
              }`}
            >
              {availabilityForDay?.available}
            </div>
          </div>
        );
        return acc;
      }, {}),
    };

    const roomTypeRows = collapsedProperties[property.id]
      ? property.roomTypes.map((roomType) => ({
          id: `${property.id}_${roomType.id}`,
          property: (
            <div className="!px-8 border-l-2 border-gray-200 flex items-center gap-1 relative !z-50">
              <span className="room-type-name text-sm text-gray-700">
                {roomType.name}
              </span>
              <div className="flex flex-col justify-center items-center gap-2 absolute -right-0">
                {selectedItems.includes('Available Room') && (
                  <span className="text-[9px] font-semibold !p-[3.5px] border-b-1 bg-black text-white">
                    AVL
                  </span>
                )}
                {selectedItems.includes('Channex Available') && (
                  <span className="text-[9px] font-semibold !p-[3.5px] border-b-1 bg-purple-300 text-white">
                    AVL
                  </span>
                )}
                {selectedItems.includes('Base Price') && (
                  <span className="text-[9px] font-light text-black">Base</span>
                )}
                {selectedItems.includes('Manual Price') && (
                  <span className="text-[9px] !p-[3.5px] font-light text-black">
                    MNL
                  </span>
                )}
                {selectedItems.includes('Calculated Price') && (
                  <span className="text-[9px] !p-[3.5px] font-light text-black">
                    CLC
                  </span>
                )}
                {selectedItems.includes('Reservations') && (
                  <span className="text-[9px] !p-[3.5px] font-light text-black">
                    RSV
                  </span>
                )}
                {selectedItems.includes('stopSell') && (
                  <span className="text-[9px] !p-[3.5px] font-light text-black">
                    STPS
                  </span>
                )}
                {selectedItems.includes('min_stay_arrival') && (
                  <span className="text-[9px] !p-[3.5px] font-light text-black">
                    MINSTY
                  </span>
                )}
                {selectedItems.includes('max_stay') && (
                  <span className="text-[9px] !p-[3.5px] font-light text-black">
                    MXSTY
                  </span>
                )}
                {selectedItems.includes('closed_to_arrival') && (
                  <span className="text-[9px] !p-[3.5px] font-light text-black">
                    CTA
                  </span>
                )}
                {selectedItems.includes('closed_to_departure') && (
                  <span className="text-[9px] !p-[3.5px] font-light text-black">
                    CTD
                  </span>
                )}
              </div>
            </div>
          ),
          isRoomType: true,
          propertyId: property.id,
          roomType: roomType,
          ...daysInView.reduce((acc, day, index) => {
            const dateStr = moment(day).format('YYYY-MM-DD');
            const dayInventory = roomType.inventory?.[dateStr] || {};
            const available = dayInventory.available || 0;
            const basePrice = dayInventory.basePrice || 0;
            const calculatedPrice = dayInventory.calculatedPrice;
            const applyManual = dayInventory.applyManual;
            const manualPrice = dayInventory.manualPrice;
            const stopSell = dayInventory.stopSell;
            const reservations = dayInventory.reservations;
            const channexAvailableRoom = dayInventory.channexAvailableRoom || 0;
            const availabled = dayInventory.availabled;
            const min_stay_arrival = dayInventory.min_stay_arrival;
            const max_stay = dayInventory.max_stay;
            const closed_to_arrival = dayInventory.closed_to_arrival;
            const closed_to_departure = dayInventory.closed_to_departure;

            acc[`day${index}`] = (
              <div className="flex flex-col gap-1 items-center">
                {selectedItems.includes('Available Room') && (
                  <div
                    onClick={() =>
                      handleAvailabilityClick(day, property, roomType)
                    }
                    style={getPendingChangeStyle(
                      dateStr,
                      'availability',
                      available,
                      roomType,
                    )}
                    title={getPendingTooltipContent(
                      dateStr,
                      'availability',
                      available,
                      roomType,
                    )}
                    className={`text-yellow-700 font-semibold !text-[13px] w-full text-center border-b-1 pt-1 cursor-pointer bg-green-100`}
                  >
                    {(() => {
                      const pending = pendingChanges?.find(
                        (change) =>
                          change.type === 'availability' &&
                          change.roomTypeId === roomType.id &&
                          isDateInRange(dateStr, change),
                      );

                      return (
                        <div className="w-full">
                          <span>
                            {pending ? pending.availableRoom : available}
                          </span>
                        </div>
                      );
                    })()}
                  </div>
                )}

                {selectedItems.includes('Channex Available') &&
                  (channexAvailableRoom ? (
                    <div className="w-full text-center border-b-1 bg-purple-100">
                      <span className="text-purple-700 font-semibold !text-[13px] ">
                        {channexAvailableRoom}
                      </span>
                    </div>
                  ) : (
                    <div className="w-full flex justify-center items-center border-b-1 !py-1.5">
                      <span className="text-gray-300">
                        <CircleSlash2 size={12} />
                      </span>
                    </div>
                  ))}

                {selectedItems.includes('Base Price') && (
                  <div
                    onClick={() => handleBasePrice(day, property, roomType)}
                    className="flex items-center justify-center gap-1 w-full hover:bg-gray-200 cursor-pointer"
                  >
                    <span
                      className={
                        applyManual
                          ? 'text-gray-400 !text-[13px] font-light line-through'
                          : 'text-gray-600 !text-[13px] font-light'
                      }
                    >
                      {basePrice}
                    </span>
                  </div>
                )}

                {selectedItems.includes('Manual Price') && (
                  <div
                    onClick={() => handleBasePrice(day, property, roomType)}
                    style={getPendingChangeStyle(
                      dateStr,
                      'manualPrice',
                      manualPrice,
                      roomType,
                    )}
                    title={getPendingTooltipContent(
                      dateStr,
                      'manualPrice',
                      manualPrice,
                      roomType,
                    )}
                    className="flex items-center gap-1 w-full text-center"
                  >
                    {(() => {
                      const pending = pendingChanges?.find(
                        (change) =>
                          change.type === 'manualPrice' &&
                          change.roomTypeId === roomType.id &&
                          isDateInRange(dateStr, change),
                      );

                      return (
                        <div className="w-full text-center">
                          <span className="text-gray-600 !text-[13px] font-light">
                            {pending ? pending.price : manualPrice}
                          </span>
                        </div>
                      );
                    })()}
                  </div>
                )}

                {selectedItems.includes('Calculated Price') && (
                  <div className="flex items-center gap-1">
                    <span className="text-gray-600 !text-[13px] font-light">
                      {calculatedPrice}
                    </span>
                  </div>
                )}
                {selectedItems.includes('Reservations') && (
                  <div
                    onClick={() =>
                      handleReservationClick(dayInventory.reservations)
                    }
                    className="flex items-center justify-center gap-1  font-semibold !text-[13px] w-full text-center border-b-1 pt-1 bg-blue-50 cursor-pointer"
                  >
                    <span className="text-sky-600 !text-[13px] font-semibold">
                      {reservations?.length}
                    </span>
                  </div>
                )}

                {selectedItems.includes('stopSell') && (
                  <div
                    onClick={() => handleStopSellClick(day, property, roomType)}
                    style={getPendingChangeStyle(
                      dateStr,
                      'stopSell',
                      stopSell,
                      roomType,
                    )}
                    title={getPendingTooltipContent(
                      dateStr,
                      'stopSell',
                      stopSell,
                      roomType,
                    )}
                    className={`flex items-center justify-center gap-1 w-full text-center border-b-1 pt-1 cursor-pointer`}
                  >
                    {(() => {
                      const pending = pendingChanges?.find(
                        (change) =>
                          change.type === 'stopSell' &&
                          change.roomTypeId === roomType.id &&
                          isDateInRange(dateStr, change),
                      );

                      const isStopSell = pending ? pending.stopSell : stopSell;

                      return (
                        <div className="w-full">
                          <span
                            className={`!text-[13px] font-semibold ${
                              isStopSell ? 'text-red-600' : 'text-gray-400'
                            }`}
                          >
                            {isStopSell ? 'Yes' : 'No'}
                          </span>
                        </div>
                      );
                    })()}
                  </div>
                )}
                {selectedItems.includes('min_stay_arrival') && (
                  <div
                    onClick={() =>
                      handleMinStayClick(
                        day,
                        property,
                        roomType,
                        'min_stay_arrival',
                      )
                    }
                    style={getPendingChangeStyle(
                      dateStr,
                      'min_stay_arrival',
                      min_stay_arrival,
                      roomType,
                    )}
                    title={getPendingTooltipContent(
                      dateStr,
                      'min_stay_arrival',
                      min_stay_arrival,
                      roomType,
                    )}
                    className={`flex items-center gap-1 w-full text-center`}
                  >
                    {(() => {
                      const pending = pendingChanges?.find(
                        (change) =>
                          change.type === 'min_stay_arrival' &&
                          change.roomTypeId === roomType.id &&
                          isDateInRange(dateStr, change),
                      );

                      return (
                        <div className="w-full">
                          <span className="text-gray-600 !text-[13px] font-light">
                            {pending
                              ? pending.min_stay_arrival
                              : min_stay_arrival}
                          </span>
                        </div>
                      );
                    })()}
                  </div>
                )}
                {selectedItems.includes('max_stay') && (
                  <div
                    onClick={() =>
                      handleMinStayClick(day, property, roomType, 'max_stay')
                    }
                    style={getPendingChangeStyle(
                      dateStr,
                      'max_stay',
                      max_stay,
                      roomType,
                    )}
                    title={getPendingTooltipContent(
                      dateStr,
                      'max_stay',
                      max_stay,
                      roomType,
                    )}
                    className={`flex items-center gap-1 w-full text-center`}
                  >
                    {(() => {
                      const pending = pendingChanges?.find(
                        (change) =>
                          change.type === 'max_stay' &&
                          change.roomTypeId === roomType.id &&
                          isDateInRange(dateStr, change),
                      );

                      return (
                        <div className="w-full">
                          <span className="text-gray-600 !text-[13px] font-light">
                            {pending ? pending.max_stay : max_stay}
                          </span>
                        </div>
                      );
                    })()}
                  </div>
                )}
                {selectedItems.includes('closed_to_arrival') && (
                  <div
                    onClick={() =>
                      handleMinStayClick(
                        day,
                        property,
                        roomType,
                        'closed_to_arrival',
                      )
                    }
                    style={getPendingChangeStyle(
                      dateStr,
                      'closed_to_arrival',
                      closed_to_arrival,
                      roomType,
                    )}
                    title={getPendingTooltipContent(
                      dateStr,
                      'closed_to_arrival',
                      closed_to_arrival,
                      roomType,
                    )}
                    className={`flex items-center justify-center gap-1 w-full text-center border-b-1 pt-1 cursor-pointer`}
                  >
                    {(() => {
                      const pending = pendingChanges?.find(
                        (change) =>
                          change.type === 'closed_to_arrival' &&
                          change.roomTypeId === roomType.id &&
                          isDateInRange(dateStr, change),
                      );

                      const isclosed_to_arrival = pending
                        ? pending.closed_to_arrival
                        : closed_to_arrival;

                      return (
                        <div className="w-full">
                          <span
                            className={`!text-[13px] font-semibold ${
                              isclosed_to_arrival
                                ? 'text-red-600'
                                : 'text-gray-400'
                            }`}
                          >
                            {isclosed_to_arrival ? 'Yes' : 'No'}
                          </span>
                        </div>
                      );
                    })()}
                  </div>
                )}
                {selectedItems.includes('closed_to_departure') && (
                  <div
                    onClick={() =>
                      handleMinStayClick(
                        day,
                        property,
                        roomType,
                        'closed_to_departure',
                      )
                    }
                    style={getPendingChangeStyle(
                      dateStr,
                      'closed_to_departure',
                      closed_to_departure,
                      roomType,
                    )}
                    title={getPendingTooltipContent(
                      dateStr,
                      'closed_to_departure',
                      closed_to_departure,
                      roomType,
                    )}
                    className={`flex items-center justify-center gap-1 w-full text-center border-b-1 pt-1 cursor-pointer`}
                  >
                    {(() => {
                      const pending = pendingChanges?.find(
                        (change) =>
                          change.type === 'closed_to_departure' &&
                          change.roomTypeId === roomType.id &&
                          isDateInRange(dateStr, change),
                      );

                      const isclosed_to_departure = pending
                        ? pending.closed_to_departure
                        : closed_to_departure;

                      return (
                        <div className="w-full">
                          <span
                            className={`!text-[13px] font-semibold ${
                              isclosed_to_departure
                                ? 'text-red-600'
                                : 'text-gray-400'
                            }`}
                          >
                            {isclosed_to_departure ? 'Yes' : 'No'}
                          </span>
                        </div>
                      );
                    })()}
                  </div>
                )}
              </div>
            );
            return acc;
          }, {}),
        }))
      : [];

    return [propertyRow, ...roomTypeRows];
  });

  const getRowClassName = (rowData) => {
    if (rowData.isProperty) return 'property-row';
    if (rowData.isRoomType) return 'room-type-row';
    return '';
  };

  const handleBasePrice = (day, property, roomType) => {
    const dateStr = moment(day).format('YYYY-MM-DD');
    const dayInventory = roomType.inventory?.[dateStr] || {};

    const DateInventory = {
      date: dateStr,
      basePrice: dayInventory.basePrice,
      manualPrice: dayInventory.manualPrice,
      calculatedPrice: dayInventory.calculatedPrice,
      available: dayInventory.available,
      stopSell: dayInventory.stopSell,
      applyManual: dayInventory.applyManual,
      reservations: dayInventory.reservations,
      availabled: dayInventory.availabled,
      min_stay_arrival: dayInventory.min_stay_arrival,
      max_stay: dayInventory.max_stay,
      closed_to_arrival: dayInventory.closed_to_arrival,
      closed_to_departure: dayInventory.closed_to_departure,
    };

    setSelectedDay({ day, property, roomType, DateInventory });
    setIsModalOpen(true);
    setSelectedRestriction('manualPrice');
  };

  const handleStopSellClick = (day, property, roomType) => {
    const dateStr = moment(day).format('YYYY-MM-DD');
    const dayInventory = roomType.inventory?.[dateStr] || {};

    const DateInventory = {
      date: dateStr,
      basePrice: dayInventory.basePrice,
      manualPrice: dayInventory.manualPrice,
      calculatedPrice: dayInventory.calculatedPrice,
      available: dayInventory.available,
      stopSell: dayInventory.stopSell,
      applyManual: dayInventory.applyManual,
      reservations: dayInventory.reservations,
      availabled: dayInventory.availabled,
      min_stay_arrival: dayInventory.min_stay_arrival,
      max_stay: dayInventory.max_stay,
      closed_to_arrival: dayInventory.closed_to_arrival,
      closed_to_departure: dayInventory.closed_to_departure,
    };

    setSelectedDay({ day, property, roomType, DateInventory });
    setIsModalOpen(true);
    setSelectedRestriction('stopSell');
  };

  const handleAvailabilityClick = (day, property, roomType) => {
    const dateStr = moment(day).format('YYYY-MM-DD');
    const dayInventory = roomType.inventory?.[dateStr] || {};

    const DateInventory = {
      date: dateStr,
      basePrice: dayInventory.basePrice,
      manualPrice: dayInventory.manualPrice,
      calculatedPrice: dayInventory.calculatedPrice,
      available: dayInventory.available,
      stopSell: dayInventory.stopSell,
      applyManual: dayInventory.applyManual,
      reservations: dayInventory.reservations,
      availabled: dayInventory.availabled,
      min_stay_arrival: dayInventory.min_stay_arrival,
      max_stay: dayInventory.max_stay,
      closed_to_arrival: dayInventory.closed_to_arrival,
      closed_to_departure: dayInventory.closed_to_departure,
    };

    setSelectedDay({ day, property, roomType, DateInventory });
    setIsModalOpen(true);
    setSelectedRestriction('availability');
  };
  const handleMinStayClick = (day, property, roomType, type) => {
    const dateStr = moment(day).format('YYYY-MM-DD');
    const dayInventory = roomType.inventory?.[dateStr] || {};

    const DateInventory = {
      date: dateStr,
      basePrice: dayInventory.basePrice,
      manualPrice: dayInventory.manualPrice,
      calculatedPrice: dayInventory.calculatedPrice,
      available: dayInventory.available,
      stopSell: dayInventory.stopSell,
      applyManual: dayInventory.applyManual,
      reservations: dayInventory.reservations,
      availabled: dayInventory.availabled,
      min_stay_arrival: dayInventory.min_stay_arrival,
      max_stay: dayInventory.max_stay,
      closed_to_arrival: dayInventory.closed_to_arrival,
      closed_to_departure: dayInventory.closed_to_departure,
    };

    setSelectedDay({ day, property, roomType, DateInventory });
    setIsModalOpen(true);
    setSelectedRestriction(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const tableStyles = `
    .task-table-compact .p-datatable-wrapper {
        border: 1px solid #e2e8f0;
        height: calc(100vh - 235px);
        overflow: hidden;
    }

    .task-table-compact .p-datatable-thead > tr > th {
        background-color: #f8fafc !important;
        border-bottom: 2px solid #e2e8f0 !important;
        border-right: 1px solid #e2e8f0 !important;
    }

    .task-table-compact .p-datatable-tbody > tr {
        border-bottom: 1px solid #e2e8f0 !important;
    }

    .task-table-compact .p-datatable-tbody > tr > td {
        border-right: 1px solid #e2e8f0 !important;
    }

    .task-table-compact .p-datatable-tbody > tr:hover {
        background-color: #f8fafc !important;
    }

    .task-table-compact .p-datatable-tbody > tr > td:first-child {
        border-right: 3px solid #e2e8f0 !important;
    }

    .p-datatable-wrapper {
       max-height: calc(-100px + 100vh) !important;
    }
`;
  return (
    <div>
      <style>{tableStyles}</style>
      <DataTable
        value={data}
        className="task-table-compact"
        scrollable
        scrollHeight="calc(100vh - 200px)"
        resizableColumns={false}
        columnResizeMode="none"
        showGridlines
        rowClassName={getRowClassName}
        // style={{ width: `${250 + (daysInView.length * 80)}px` }}
      >
        {columns.map((col, index) => (
          <Column
            key={index}
            field={col.field}
            header={col.header}
            frozen={col.frozen}
            style={col.style}
            body={(rowData) => rowData[col.field]}
          />
        ))}
      </DataTable>
      <UpdateInventory
        open={isModalOpen}
        onClose={handleCloseModal}
        roomType={selectedDay?.roomType}
        DateInventory={selectedDay?.DateInventory}
        ratePlan="lux Room - Best Available Rate"
        dateRange={{
          start: moment(selectedDay?.day).format('YYYY-MM-DD'),
          end: moment(selectedDay?.day).format('YYYY-MM-DD'),
        }}
        setIsInventoryUpdated={setIsInventoryUpdated}
        selectedRestriction={selectedRestriction}
        onInventoryUpdate={onInventoryUpdate}
        pendingChanges={pendingChanges}
        showNotification={showNotification}
      />
      <ReservationPopup
        isOpen={showReservationsPopup}
        onClose={() => setShowReservationsPopup(false)}
        reservations={selectedReservations}
      />
    </div>
  );
};

export default TableCalendar;
