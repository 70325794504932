import React, { useState, useCallback, useRef, useEffect } from 'react';
import { MultiCalendar } from './multiCalendarV2/MultiCalendar';
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { getMultiListing } from '../../services/serverApi.calendar';
import MDBox from "components/MDBox";
import Pagination from 'components/GlobalTable/Pagination';
import FilterCalendar from '../multiCalendarV2/FilterCalendar';
import 'react-toastify/dist/ReactToastify.css';
import { debounce } from 'lodash';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';

function MonthlyCalendarPage() {
  const { listingId } = useParams();
  
  const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const checkInDate = queryParams.get('checkIn') ? moment(queryParams.get('checkIn')) : null;
  const checkOutDate = queryParams.get('checkOut') ? moment(queryParams.get('checkOut')) : null;

  const [state, setState] = useState({
    listings: [],
    isLoading: true,
    error: null,
    selectedItems: ['Reservation'],
    filters: {
      city: '',
      country: '',
      tags: [],
      units: 'All units',
      match: '',
    },
    page: 0,
    limit: 25,
    totalCount: 0,
    isNextDisabled: false,
  });

  useEffect(() => {
    setState({
      listings: [],
      isLoading: true,
      error: null,
      selectedItems: ['Reservation'],
      filters: {
        city: '',
        country: '',
        tags: [],
        units: 'All units',
        match: '',
      },
      page: 0,
      limit: 25,
      totalCount: 0,
      isNextDisabled: false,
    })
  }, [staging]);

  const prevFiltersRef = useRef(state.filters);

  const fetchListings = useCallback(async () => {
    setState(prev => ({ ...prev, isLoading: true }));
    try {
      let filters = { ...state.filters };
      if (listingId) {
        filters.listing_id = listingId;
      }

      const response = await getMultiListing(state.page, state.limit, filters, staging);
      if (response && response.data && response.data.data && Array.isArray(response.data.data)) {
        let formattedListings = response.data.data.map(listing => ({
          id: listing._id,
          name: listing.name,
          calendarData: listing.calendarData || []
        }));


        if (listingId) {
          formattedListings = formattedListings.filter(listing => listing.id === listingId);
        }

        setState(prev => ({
          ...prev,
          listings: formattedListings,
          totalCount: formattedListings.length,
          isNextDisabled: true,
          error: null,
          isLoading: false,
        }));
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      setState(prev => ({
        ...prev,
        listings: [],
        error: err.message,
        isLoading: false,
      }));
    }
  }, [state.page, state.limit, state.filters, listingId, staging]);

  const debouncedFetchListings = useCallback(
    debounce(fetchListings, 300),
    [fetchListings]
  );

  useEffect(() => {
    const filtersChanged = JSON.stringify(state.filters) !== JSON.stringify(prevFiltersRef.current);
    if (filtersChanged || state.page !== prevFiltersRef.current.page || state.limit !== prevFiltersRef.current.limit) {
      debouncedFetchListings();
      prevFiltersRef.current = { ...state.filters, page: state.page, limit: state.limit };
    }
    return () => debouncedFetchListings.cancel();
  }, [state.filters, state.page, state.limit, debouncedFetchListings, staging]);

  const handleFilterChange = useCallback((newFilters) => {
    setState(prev => ({
      ...prev,
      filters: { ...prev.filters, ...newFilters },
      page: 0,
    }));
  }, []);

  const handlePageChange = useCallback((newPage) => {
    setState(prev => ({ ...prev, page: newPage }));
  }, []);

  const handleLimitChange = useCallback((newLimit) => {
    setState(prev => ({ ...prev, limit: newLimit, page: 0 }));
  }, []);

  const handleSearchQueryChange = useCallback((query) => {
    setState(prev => ({
      ...prev,
      filters: { ...prev.filters, match: query },
      page: 0,
    }));
  }, []);

  const handleSelectedItemsChange = useCallback((items) => {
    setState(prev => ({ ...prev, selectedItems: items }));
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <div className="h-full">
          <FilterCalendar
            onFilterChange={handleFilterChange}
            onSearchQueryChange={handleSearchQueryChange}
            onSelectedItemsChange={handleSelectedItemsChange}
            listingsCount={state.listings.length}
          />
          <MultiCalendar
            listings={state.listings}
            selectedItems={state.selectedItems}
            isLoading={state.isLoading}
            listingOnId={listingId}
            staging={staging}
            checkInDate={checkInDate}
            checkOutDate={checkOutDate}
          />
          <Pagination
            page={state.page}
            onPageChange={handlePageChange}
            isNextDisabled={state.isNextDisabled}
            limit={state.limit}
            onLimitChange={handleLimitChange}
            rowsPerPageOptions={[10, 25, 50, 100]}
          />
        </div>
      </MDBox>
    </DashboardLayout>
  );
}

export default MonthlyCalendarPage;