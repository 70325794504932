import React from 'react';
import { TextField, IconButton, Switch } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import WeekdaySelector from './WeekdaySelector';

const EventItem = ({ event, onChange, onRemove, onAdd }) => {
    const handleInputChange = (field, value) => {
        onChange({ ...event, [field]: value });
    };

    const handleActiveChange = (e) => {
        onChange({ ...event, active: e.target.checked });
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    return (
        <div className="mt-4 border p-4 rounded">
            <div className="flex flex-wrap items-center gap-2 mt-2">
                <TextField
                    label="Start"
                    type="date"
                    size="small"
                    value={formatDate(event.start_date)}
                    onChange={(e) => handleInputChange('start_date', e.target.value)}
                />
                <TextField
                    label="End"
                    type="date"
                    size="small"
                    value={formatDate(event.end_date)}
                    onChange={(e) => handleInputChange('end_date', e.target.value)}
                />
                <TextField
                    label="Scale"
                    value={event.rate_percentage ? event.rate_percentage / 100 : ''}
                    size="small"
                    onChange={(e) => handleInputChange('rate_percentage', Math.round(parseFloat(e.target.value) * 100))}
                    type="number"
                    inputProps={{ min: 0, step: 0.01 }}
                />
                <WeekdaySelector event={event} onChange={onChange} />
                <TextField
                    label="Title"
                    value={event.event_name || ''}
                    size="small"
                    onChange={(e) => handleInputChange('event_name', e.target.value)}
                />
                <div className="flex items-center gap-2">
                    <IconButton onClick={onRemove} color="error">
                        <RemoveCircleOutlineIcon />
                    </IconButton>
                    <IconButton onClick={onAdd} color="primary">
                        <AddCircleOutlineIcon className="!text-medium-aquamarine" />
                    </IconButton>
                    <Switch
                        checked={event.active}
                        onChange={handleActiveChange}
                        sx={{
                            '& .MuiSwitch-switchBase.Mui-checked': { color: '#00b4b4' },
                            '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#00b4b4' }
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default EventItem;