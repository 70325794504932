import React, { useState, useEffect } from 'react';
import { getCountries, getCities } from 'features/reservation/services/serverApi.reservation';

const ReservationDetails = ({ editableDetails, isEditMode, handleInputChange }) => {
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const countriesData = await getCountries();
            setCountries(countriesData.data);

            const citiesData = await getCities();
            setCities(citiesData.data);
        } catch (error) {
            console.error('Error fetching country and city data:', error);
        }
    };

    const renderInputField = (label, name, type = 'text') => {
        return (
            <div className="flex flex-col">
                <label className="font-bold text-[#676a6c] text-sm mb-2">{label}</label>
                <input
                    type={type}
                    name={name}
                    onChange={handleInputChange}
                    value={editableDetails[name]}
                    readOnly={!isEditMode}
                    className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded p-2"
                    style={{
                        backgroundColor: (!isEditMode) ? '#eee' : 'white',
                        border: (!isEditMode) ? '1px solid #eee' : '1px solid #ccc',
                        fontSize: 'small',
                    }}
                />
            </div>
        );
    };

    const renderSelectField = (label, name, options) => (
        <div className="flex flex-col">
            <label className="font-bold text-[#676a6c] text-sm mb-2">{label}</label>
            <select
                name={name}
                className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded !p-[0.60rem]"
                value={editableDetails[name]}
                readOnly={!isEditMode}
                disabled={!isEditMode}
                onChange={handleInputChange}
                style={{
                    backgroundColor: isEditMode ? 'white' : '#eee',
                    border: isEditMode ? '1px solid #ccc' : '1px solid #eee',
                    fontSize: 'small',
                }}
            >
                {options.map(option => (
                    <option
                        key={option.value}
                        value={option.value}
                        disabled={['CancelledByAdmin', 'CancelledByCustomer', 'CancelledByOta', 'CancelledAfterFailedPayment', 'OtherCancellation'].includes(option.value)}
                    >
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );

    const renderTimeSelectField = (label, name) => {
        const hours = Array.from({ length: 24 }, (_, i) => i);
        return (
            <div className="flex flex-col">
                <label className="font-bold text-[#676a6c] text-sm mb-2">{label}</label>
                <select
                    name={name}
                    className="mb-2 focus:outline-none text-[#676a6c] focus:border-gray-300 px-3 rounded !p-[0.60rem]"
                    value={editableDetails[name]}
                    readOnly={!isEditMode}
                    onChange={handleInputChange}
                    style={{
                        backgroundColor: isEditMode ? 'white' : '#eee',
                        border: isEditMode ? '1px solid #ccc' : '1px solid #eee',
                        fontSize: 'small',
                    }}
                >
                    {hours.map(hour => (
                        <option key={hour} value={hour}>
                            {`${hour}:00`}
                        </option>
                    ))}
                </select>
            </div>
        );
    };

    return (
        <>
            <div className="grid grid-cols-4 gap-4">
                {renderInputField('Check-in date (Listing time)', 'arrivalDate', 'date')}
                {renderInputField('Check-out date (Listing time)', 'departureDate', 'date')}
                {renderTimeSelectField('Check-in time (Listing time)', 'checkInTime')}
                {renderTimeSelectField('Check-out time (Listing time)', 'checkOutTime')}
                {renderSelectField('Currency', 'currency', [{ value: 'MAD', label: 'MAD' }, { value: 'USD', label: 'USD' }, { value: 'EUR', label: 'EUR' }, { value: 'GBP', label: 'GBP' }])}
                {renderSelectField('Status', 'status', [
                    { value: 'Pending', label: 'Pending' },
                    { value: 'Confirmed', label: 'Confirmed' },
                    { value: 'Completed', label: 'Completed' },
                    { value: 'CancelledByAdmin', label: 'Cancelled by Admin' },
                    { value: 'CancelledByCustomer', label: 'Cancelled by Customer' },
                    { value: 'CancelledByOta', label: 'Cancelled by OTA' },
                    { value: 'CancelledAfterFailedPayment', label: 'Cancelled After Failed Payment' },
                    { value: 'OtherCancellation', label: 'Other Cancellation' }
                ])}
                {renderSelectField('Payment Status', 'paymentStatus', [{ value: 'Paid', label: 'Paid' }, { value: 'UnPaid', label: 'UnPaid' }])}
                {renderSelectField('Time Line', 'timeLine', [
                    { value: 'Coming-Today', label: 'Coming Today' },
                    { value: 'Coming-Tomorrow', label: 'Coming Tomorrow' },
                    { value: 'Coming-in2days', label: 'Coming in 2 days' },
                    { value: 'Coming-in3days', label: 'Coming in 3 days' },
                    { value: 'Normal', label: 'Normal' },
                    { value: 'Departure-today', label: 'Departure Today' },
                    { value: 'Departure-tomorrow', label: 'Departure Tomorrow' },
                    { value: 'Inside', label: 'Inside' },
                    { value: 'Leave', label: 'Leave' },
                    { value: 'One-Night', label: 'One Night' }
                ])}
                {renderSelectField('Guest Country', 'guestCountry', countries.map(country => ({ value: country.id, label: country.name })))}
                {renderSelectField('Guest City', 'guestCity', cities.map(city => ({ value: city.id, label: city.name })))}
            </div>
        </>
    );
};

export default ReservationDetails;
