import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Typography, Select, MenuItem, IconButton, FormControl, InputLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import { createProject, updateProject } from '../services/serverApi.ProjectUnits';
import { toast } from 'react-toastify';
import ImageUpload from 'components/CustomUpload/UploadV2';
import defaultImage from 'assets/images/image_placeholder.jpg';
import ImageUploadSection from './imageUpload/ImageUploadSection';

const PROJECT_TYPES = ['Building', 'Residence', 'Villas'];
const PROJECT_STATUSES = ['Pre-Launch', 'Under-Construction', 'Recently-Delivered', 'Ready-To-Invest', 'Needs-Work'];
const UNIT_TYPES = ['Apartment', 'Villa', 'Studio'];

const validationSchema = Yup.object().shape({
    projectName: Yup.string().required('Project name is required'),
    projectSubName: Yup.string(),
    unitType: Yup.string()
        .required('Unit type is required')
        .oneOf(UNIT_TYPES, 'Invalid unit type'),
    projectType: Yup.string()
        .required('Project type is required')
        .oneOf(PROJECT_TYPES, 'Invalid project type'),
    status: Yup.string()
        .required('Status is required')
        .oneOf(PROJECT_STATUSES, 'Invalid status'),
    city: Yup.string().required('City is required'),
    district: Yup.string().required('District is required'),
    address: Yup.string().required('Address is required'),
    totalUnits: Yup.number().required('Total units is required').min(0),
    soldUnits: Yup.number().required('Sold units is required').min(0),
    remainingUnits: Yup.number().required('Remaining units is required').min(0),
    startingPrice: Yup.number().required('Starting price is required').min(0),
    deliveryDate: Yup.date(),
    description: Yup.string(),
    gps: Yup.object().shape({
        latitude: Yup.number()
            .required('Latitude is required')
            .min(-90, 'Latitude must be between -90 and 90')
            .max(90, 'Latitude must be between -90 and 90'),
        longitude: Yup.number()
            .required('Longitude is required')
            .min(-180, 'Longitude must be between -180 and 180')
            .max(180, 'Longitude must be between -180 and 180')
    }),
    images: Yup.array().of(
        Yup.object().shape({
            url: Yup.string().required('Image URL is required'),
            sortOrder: Yup.number().required('Sort order is required')
        })
    )
});

const ProjectModal = ({ open, onClose, setProjects, project }) => {

    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        setIsLoading(true);
        setErrorMessage('');

        try {
            let response;
            if (project) {
                response = await updateProject(project.id, values);
            } else {
                response = await createProject(values);
            }

            const responseData = response.data;

            if (responseData && responseData.success) {
                setProjects(prevProjects => {
                    if (!prevProjects) {
                        return [responseData.project];
                    }

                    if (project) {
                        return prevProjects.map(p =>
                            p.id === responseData.project.id ? responseData.project : p
                        );
                    } else {
                        return [responseData.project, ...prevProjects];
                    }
                });

                toast.success(responseData.message || (project ? 'Project updated successfully' : 'Project created successfully'));
                onClose();
            } else {
                throw new Error(responseData?.message || 'Failed to process project');
            }
        } catch (error) {
            console.error('Error caught:', error);
            // setErrorMessage(error.message || 'An error occurred while processing your request.');
            toast.error(error.response?.data?.error || error.message || (project ? 'Error updating project' : 'Error creating project'));
        } finally {
            setIsLoading(false);
            setSubmitting(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{project ? 'Update Project' : 'Create New Project'}</DialogTitle>
            <DialogContent>
                {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                <Formik
                    initialValues={{
                        projectName: project ? project.projectName : '',
                        projectSubName: project ? project.projectSubName : '',
                        unitType: project ? project.unitType : UNIT_TYPES[0],
                        projectType: project ? project.projectType : PROJECT_TYPES[0],
                        status: project ? project.status : PROJECT_STATUSES[0],
                        city: project ? project.city : '',
                        district: project ? project.district : '',
                        address: project ? project.address : '',
                        totalUnits: project ? project.totalUnits : 0,
                        soldUnits: project ? project.soldUnits : 0,
                        remainingUnits: project ? project.remainingUnits : 0,
                        startingPrice: project ? project.startingPrice : 0,
                        deliveryDate: project?.deliveryDate ? new Date(project.deliveryDate).toISOString().slice(0, 10) : '',
                        description: project ? project.description : '',
                        gps: project?.gps ? {
                            latitude: project.gps.latitude,
                            longitude: project.gps.longitude
                        } : { latitude: 0, longitude: 0 },
                        images: project ? project.images.map((img, index) => ({
                            url: img.url,
                            sortOrder: img.sortOrder || index + 1
                        })) : []
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}

                >
                    {({ values, errors, touched, setFieldValue }) => (
                        <Form className="mt-2">
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Box sx={{ display: 'flex', gap: 2 }}>

                                    <Field name="projectName">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Project Name"
                                                fullWidth
                                                error={touched.projectName && Boolean(errors.projectName)}
                                                helperText={touched.projectName && errors.projectName}
                                            />
                                        )}
                                    </Field>

                                    <Field name="projectSubName">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Project Sub Name"
                                                fullWidth
                                                error={touched.projectSubName && Boolean(errors.projectSubName)}
                                                helperText={touched.projectSubName && errors.projectSubName}
                                            />
                                        )}
                                    </Field>

                                    <Field name="unitType">
                                        {({ field }) => (
                                            <FormControl fullWidth error={touched.unitType && Boolean(errors.unitType)}>
                                                <InputLabel id="unit-type-label">Unit Type</InputLabel>
                                                <Select
                                                    {...field}
                                                    labelId="unit-type-label"
                                                    label="Unit Type"
                                                    fullWidth
                                                >
                                                    {UNIT_TYPES.map(type => (
                                                        <MenuItem key={type} value={type}>{type}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 2 }}>

                                    <Field name="projectType">
                                        {({ field }) => (
                                            <FormControl fullWidth error={touched.projectType && Boolean(errors.projectType)}>
                                                <InputLabel id="project-type-label">Project Type</InputLabel>
                                                <Select
                                                    {...field}
                                                    labelId="project-type-label"
                                                    label="Project Type"
                                                    fullWidth
                                                >
                                                    {PROJECT_TYPES.map(type => (
                                                        <MenuItem key={type} value={type}>{type}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    </Field>

                                    <Field name="status">
                                        {({ field }) => (
                                            <FormControl fullWidth error={touched.status && Boolean(errors.status)}>
                                                <InputLabel id="status-label">Status</InputLabel>
                                                <Select
                                                    {...field}
                                                    labelId="status-label"
                                                    label="Status"
                                                    fullWidth
                                                >
                                                    {PROJECT_STATUSES.map(status => (
                                                        <MenuItem key={status} value={status}>{status}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Box>
                                <Box sx={{ display: 'flex', gap: 2 }}>
                                <Field name="gps.latitude">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Latitude"
                                            type="number"
                                            fullWidth
                                            error={touched.gps?.latitude && Boolean(errors.gps?.latitude)}
                                            helperText={touched.gps?.latitude && errors.gps?.latitude}
                                        />
                                    )}
                                </Field>

                                <Field name="gps.longitude">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Longitude"
                                            type="number"
                                            fullWidth
                                            error={touched.gps?.longitude && Boolean(errors.gps?.longitude)}
                                            helperText={touched.gps?.longitude && errors.gps?.longitude}
                                        />
                                    )}
                                </Field>
                            </Box>


                                <Box sx={{ display: 'flex', gap: 2 }}>
                                    <Field name="city">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="City"
                                                fullWidth
                                                error={touched.city && Boolean(errors.city)}
                                                helperText={touched.city && errors.city}
                                            />
                                        )}
                                    </Field>

                                    <Field name="district">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="District"
                                                fullWidth
                                                error={touched.district && Boolean(errors.district)}
                                                helperText={touched.district && errors.district}
                                            />
                                        )}
                                    </Field>
                                </Box>

                                <Field name="address">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Full Address"
                                            multiline
                                            rows={1}
                                            fullWidth
                                            error={touched.address && Boolean(errors.address)}
                                            helperText={touched.address && errors.address}
                                        />
                                    )}
                                </Field>

                                <Box sx={{ display: 'flex', gap: 2 }}>


                                    <Field name="totalUnits">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Total Units"
                                                type="number"
                                                fullWidth
                                                error={touched.totalUnits && Boolean(errors.totalUnits)}
                                                helperText={touched.totalUnits && errors.totalUnits}
                                            />
                                        )}
                                    </Field>

                                    <Field name="soldUnits">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Sold Units"
                                                type="number"
                                                fullWidth
                                                error={touched.soldUnits && Boolean(errors.soldUnits)}
                                                helperText={touched.soldUnits && errors.soldUnits}
                                            />
                                        )}
                                    </Field>
                                </Box>

                                <Box sx={{ display: 'flex', gap: 2 }}>

                                    <Field name="remainingUnits">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Remaining Units"
                                                type="number"
                                                fullWidth
                                                error={touched.remainingUnits && Boolean(errors.remainingUnits)}
                                                helperText={touched.remainingUnits && errors.remainingUnits}
                                            />
                                        )}
                                    </Field>

                                    <Field name="startingPrice">
                                        {({ field }) => (
                                            <TextField
                                                {...field}
                                                label="Starting Price"
                                                type="number"
                                                fullWidth
                                                error={touched.startingPrice && Boolean(errors.startingPrice)}
                                                helperText={touched.startingPrice && errors.startingPrice}
                                            />
                                        )}
                                    </Field>

                                </Box>

                                <Field name="deliveryDate">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Delivery Date"
                                            type="date"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            error={touched.deliveryDate && Boolean(errors.deliveryDate)}
                                            helperText={touched.deliveryDate && errors.deliveryDate}
                                        />
                                    )}
                                </Field>

                                <Field name="description">
                                    {({ field }) => (
                                        <TextField
                                            {...field}
                                            label="Description"
                                            multiline
                                            rows={4}
                                            fullWidth
                                            error={touched.description && Boolean(errors.description)}
                                            helperText={touched.description && errors.description}
                                        />
                                    )}
                                </Field>

                                <FieldArray name="images">
                                    <Box sx={{ mt: 2 }}>
                                        <Typography variant="subtitle1" sx={{ mb: 2 }}>Project Images</Typography>
                                        <ImageUploadSection
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            imageHeight={150}
                                            containerHeight={150}
                                            previewBoxPadding="150px"
                                        />
                                    </Box>
                                </FieldArray>
                            </Box>
                            <DialogActions>
                                <Button onClick={onClose} className="!text-gray-500">
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="!bg-medium-aquamarine !text-white"
                                    disabled={isLoading}
                                >
                                    {project ? 'Update Project' : 'Create Project'}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default ProjectModal;