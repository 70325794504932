import React, { useState, useEffect } from 'react';
import { Reorder, AnimatePresence } from 'framer-motion';
import { Box, Grid, Card, CardContent, Typography, TableContainer, Paper, CircularProgress, IconButton, Button } from '@mui/material';
import { getAmenities, getAmenitiesMapping, updateAmenitiesMapping, addAmenityMapping } from '../services/serverApi.listing';
import AddIcon from '@mui/icons-material/Add';
import AddAmenityMapping from './AddAmenityMapping';
import { ToastContainer, toast } from 'react-toastify';
import Icon from "@mui/material/Icon";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import 'react-toastify/dist/ReactToastify.css';

function AmenitiesMap() {
  const [amenitiesMapping, setAmenitiesMapping] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAddDialog, setOpenAddDialog] = useState(false);

  useEffect(() => {
    fetchAmenities();
    fetchAmenitiesMapping();
  }, []);

  const handleCloseModifyDialog = () => {
    setOpenAddDialog(false);
  };

  const fetchAmenities = async () => {
    try {
      const response = await getAmenities();
      setAmenities(response.data.data);
    } catch (error) {
      console.error('Error fetching amenities:', error);
    }
  };

  const fetchAmenitiesMapping = async () => {
    try {
      const response = await getAmenitiesMapping();
      setAmenitiesMapping(response.data.amenitiesMapping);
    } catch (error) {
      console.error('Error fetching amenities mapping:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleReorder = async (newOrder) => {
    const newMappingIds = newOrder.map(item => item.amenitiesMapping);
    setAmenitiesMapping(newOrder);

    try {
      await updateAmenitiesMapping({ amenitiesMapping: newMappingIds });
    } catch (error) {
      console.error('Error updating amenities order:', error);
    }
  };


  const deleteItem = async (id) => {
    if (window.confirm("Are you sure you want to delete this amenity?")) {
      try {
        const filteredMapping = amenitiesMapping.filter(mapping => mapping.amenities._id !== id);
        const newMapping = filteredMapping.map(mapping => mapping.amenities._id);
        setAmenitiesMapping(filteredMapping);
        await updateAmenitiesMapping({ amenitiesMapping: newMapping });
        toast.success('Amenity deleted successfully');
      } catch (error) {
        console.error("Error deleting amenity:", error);
        toast.error('Error deleting amenity');
      }
    } else {
      toast.info('Deletion cancelled');
    }
  };

  const handleAddAmenity = () => {
    setOpenAddDialog(true);
  };

const handleAddConfirm = async () => {
  try {
    fetchAmenitiesMapping();
  } catch (error) {
    console.error('Error adding amenity mapping:', error);
  }
};

  return (
    <Grid container component={Paper}>
      <ToastContainer position="top-right" autoClose={3000} />
      <Box className="flex justify-between w-[100%] p-4">
        <Box>
          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
            <span className='text-3xl text-yellow-300'><Icon>room_service</Icon></span>

            <span style={{ fontSize: '25px', fontWeight: 'bold', marginLeft: '8px' }}>
              Amenities Mapping
            </span>
          </span>
        </Box>
        <Box>
          <Button
            startIcon={<AddIcon />}
            sx={{
              background: '#66cdaa',
              float: 'right',
              color: 'white !important',
              margin: '10px',
              '&:hover': {
                background: '#59bd9b',
              },
            }}
            variant="contained"
            onClick={handleAddAmenity}
          >
            Add Amenity
          </Button>
        </Box>
      </Box>

      {loading ? (
        <Grid item xs={12} style={{ margin: 'auto' }}>
          <CircularProgress style={{ color: '#66cdaa' }} size={30} />
        </Grid>
      ) : (
        <TableContainer className='px-[63px] py-4' style={{ margin: 'auto',padding: "7px"}} component={Paper}>
          <AnimatePresence>
            <Reorder.Group axis="y" values={amenitiesMapping} onReorder={handleReorder}>
              {amenitiesMapping.map((item) => (
                <Item key={item.amenitiesMapping} item={item} onDelete={deleteItem}/>
              ))}
            </Reorder.Group>
          </AnimatePresence>
        </TableContainer>
      )}

      <AddAmenityMapping
        open={openAddDialog}
        onClose={handleCloseModifyDialog}
        getAmenitiesMap={handleAddConfirm}
        amenities={amenities}
        amenitiesMapping={amenitiesMapping}
      />
    </Grid>
  );
}

const Item = ({ item, onDelete }) => {
  const [hover, setHover] = useState(false);
  return (
    <Reorder.Item
      id={item._id}
      value={item}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Card
        sx={{
          position: 'relative',
          marginBottom: '10px',
          cursor: 'grab',
          textAlign: 'center',
          '&:hover': {
            backgroundColor: 'black',
          },
        }}
        className="bg-gradient-to-r from-[#000000] via-[#242424] to-[#181818] text-white shadow-md shadow-violet-600 "
      >
        <CardContent>
          <Typography variant="body1">
            {Array.isArray(item.amenities)
              ? item.amenities.map(amenity => amenity.name).join(', ')
              : item.amenities.name}
          </Typography>
        </CardContent>
        <Box 
            sx={{
              position: 'absolute',
              background: 'transparent',
              color: 'black',
              top: 8,
              left: 8,
            }}>
        <AlignHorizontalLeftIcon style={{color:"yellow"}} />
        </Box>
        <IconButton
          sx={{
            position: 'absolute',
            background: 'white',
            color: 'black',
            top: 8,
            right: 8,
            '&:hover': {
              background: 'red',
              color: 'white'
            },
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => onDelete(item.amenities._id)}
        >
          {hover ? <DeleteSweepOutlinedIcon style={{ color: 'white' }} /> : <DeleteOutlineIcon />}
        </IconButton>
      </Card>
    </Reorder.Item>
  );
};

export default AmenitiesMap;