import React from 'react';
import { Typography, List, ListItem, ListItemText, Grid, TextField, Paper, Box } from '@mui/material';
import logo from 'assets/images/logo-ct.png';

function ReservationDataTab({ reservationMessage, reservationDetails }) {
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toISOString().split('T')[0];
    };

    const formatTime = (timeString) => {
        if (!timeString) return '';
        const hours = timeString.padStart(2, '0');
        return `${hours}:00`;
    };

    return (
        <List className="p-3">
            <ListItem>
                <ListItemText
                    primary={
                        <Paper elevation={0} sx={{ p: 2, mb: 3, backgroundColor: 'rgba(0, 0, 0, 0.03)' }}>
                            <Box display="flex" className="gap-2" alignItems="center">
                                <img src={logo} alt="Listing" style={{ width: 30, height: 40 }} />
                                <Box>
                                    <Typography variant="subtitle1" fontWeight="medium">Listing name</Typography>
                                    <Typography variant="body2">{reservationDetails?.listing?.name || 'N/A'}</Typography>
                                </Box>
                            </Box>
                        </Paper>
                    }
                />
            </ListItem>

            <Grid container spacing={2}>
                {[
                    { label: 'Check-in', type: 'date', value: formatDate(reservationDetails?.arrivalDate) },
                    { label: 'Check-out', type: 'date', value: formatDate(reservationDetails?.departureDate) },
                    { label: 'Check-in Time', type: 'time', value: formatTime(reservationDetails?.checkInTime) },
                    { label: 'Check-out Time', type: 'time', value: formatTime(reservationDetails?.checkOutTime) },
                    { label: 'Reservation Number', value: reservationDetails?.reservationNumber },
                    { label: 'Phone Number', value: reservationDetails?.phone },
                    { label: 'Date', value: new Date(reservationDetails?.reservationDate).toLocaleString() },
                    // { label: 'Category', value: reservationMessage.category },
                    { label: 'Nights', value: reservationDetails?.nights },
                    { label: 'Status', value: reservationDetails?.status },
                    { label: 'Guests', value: reservationDetails?.numberOfGuests },
                    { label: 'Channel', value: reservationDetails?.channelName },
                    { label: 'Total Price', value: `${reservationDetails?.totalPrice} ${reservationDetails?.currency}` },
                    { label: 'Has Paid', value: reservationDetails?.isInstantBooked ? 'Yes' : 'No' }
                ].map((item, index) => (
                    <Grid item xs={6} key={index}>
                        <ListItem>
                            <ListItemText
                                primary={<Typography variant="subtitle2" className="!font-medium">{item.label}</Typography>}
                                secondary={
                                    item.type ? (
                                        <TextField
                                            id={item.type}
                                            type={item.type}
                                            defaultValue={item.value}
                                            InputLabelProps={{ shrink: true }}
                                            className="w-[90%]"
                                            inputProps={{ readOnly: true }}
                                        />
                                    ) : (
                                        <Typography variant="body2" className="">
                                            {item.value}
                                        </Typography>
                                    )
                                }
                            />
                        </ListItem>
                    </Grid>
                ))}
            </Grid>
        </List>
    );
}

export default ReservationDataTab;