import React, { useState, useEffect } from 'react';
import { Button, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { Plus } from 'lucide-react';
import { toast } from 'react-toastify';
import { getUnits, updateUnitMapping } from 'features/projectUnits/services/serverApi.ProjectUnits';


const AddUnitButton = ({ unitMapping = [], onUnitAdded }) => {
    const [units, setUnits] = useState([]);
    const [selectedUnit, setSelectedUnit] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getExistingUnitIds = () => {
        const existingIds = new Set();
        unitMapping.forEach(item => {
            if (item.type === 'project') {
                item.units?.forEach(unit => existingIds.add(unit._id));
            } else if (item.type === 'independentUnit') {
                existingIds.add(item._id);
            }
        });
        return existingIds;
    };

    useEffect(() => {
        const fetchUnits = async () => {
            try {
                setError(null);
                const response = await getUnits({ page: 0, limit: 1000 });
                if (response?.data?.units) {
                    setUnits(response.data.units);
                } else {
                    // throw new Error('Invalid response format');
                    toast.error('Invalid response format');
                }
            } catch (error) {
                const errorMessage = error.response?.data?.message || 'Failed to fetch units';
                console.error('Error fetching units:', error);
                setError(errorMessage);
                toast.error(errorMessage);
            }
        };

        fetchUnits();
    }, []);

    const handleAddUnit = async () => {
        if (!selectedUnit) return;
    
        setLoading(true);
        try {
            const unitToAdd = units.find(unit => unit._id === selectedUnit);
            if (!unitToAdd) {
                // throw new Error('Selected unit not found');
                toast.error('Selected unit not found');
            }
    
            const newMapping = [
                ...unitMapping,
                {
                    ...unitToAdd,
                    type: 'independentUnit',
                    originalIndex: unitMapping.length
                }
            ];
    
            const mappingForDb = transformForDatabase(newMapping);
    
            await updateUnitMapping(null, { unitMapping: mappingForDb });
    
            onUnitAdded?.(newMapping);
            
            setSelectedUnit('');
            toast.success('Unit added successfully');
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Failed to add unit';
            console.error('Error adding unit:', error);
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };
    
    const transformForDatabase = (orderedItems) => {
        const unitIds = [];
      
        orderedItems.forEach(item => {
          if (item.type === 'project') {
            item.units?.forEach(unit => {
              unitIds.push(unit._id);
            });
          } else if (item.type === 'independentUnit') {
            unitIds.push(item._id);
          }
        });
      
        return unitIds;
    };

    const existingUnitIds = getExistingUnitIds();

    if (error) {
        return <Box sx={{ color: 'error.main' }}>Error: {error}</Box>;
    }

    return (
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 3 }}>
            <FormControl sx={{ minWidth: 280 }}>
                <InputLabel>Select a unit to add</InputLabel>
                <Select
                    value={selectedUnit}
                    onChange={(e) => setSelectedUnit(e.target.value)}
                    label="Select a unit to add"
                    size="small"
                >
                    {units.map(unit => (
                        <MenuItem
                            key={unit._id}
                            value={unit._id}
                            disabled={existingUnitIds.has(unit._id)}
                        >
                            {unit.unitName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Button
                variant="contained"
                onClick={handleAddUnit}
                disabled={!selectedUnit || loading}
                startIcon={<Plus size={20} />}
                size="small"
                className="!bg-medium-aquamarine !text-white"
            >
                Add Unit
            </Button>
        </Box>
    );
};

export default AddUnitButton;