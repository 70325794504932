import React, { useState, useEffect } from 'react';
import { Calendar } from './components/Calendar/Calendar';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { getListings } from './services/serverApi.task';
import './Task.css';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';

function Task() {
    const [groupedListings, setGroupedListings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const { listingId } = useParams();
    const staging = JSON.parse(localStorage.getItem('isStaging')) || false;


    const fetchListings = async () => {
        try {
            setIsLoading(true);
            const response = await getListings(staging);
            if (response && response.data && response.data.data && Array.isArray(response.data.data)) {
                const normalizedListings = response.data.data.map(group => ({
                    ...group,
                    _id: group._id.toLowerCase()
                }));

                let filteredListings = normalizedListings;
                if (listingId) {
                    filteredListings = normalizedListings.map(group => ({
                        ...group,
                        listings: group.listings.filter(listing => listing._id === listingId),
                        count: group.listings.filter(listing => listing._id === listingId).length
                    })).filter(group => group.count > 0);
                }

                const groupedByZone = filteredListings.reduce((acc, group) => {
                    const zone = group._id;
                    if (!acc[zone]) {
                        acc[zone] = { _id: zone, listings: [], count: 0 };
                    }
                    acc[zone].listings = [...acc[zone].listings, ...group.listings];
                    acc[zone].count += group.count;
                    return acc;
                }, {});

                const regroupedListings = Object.values(groupedByZone);

                setGroupedListings(regroupedListings);
            } else {
                throw new Error('Invalid response format');
            }
        } catch (err) {
            console.error('Error fetching listings:', err);
            setError('Failed to fetch listings. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchListings();
    }, [listingId, staging]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="task-container">

                <Calendar groupedListings={groupedListings} listingId={listingId} />

            </div>
        </DashboardLayout>
    );
}

export default Task;