import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Admin from "layouts/admin";
import AuthRoute from 'clerkAuth/AuthRoute';
import SignInPage from 'clerkAuth/SignInPage';
// import UnauthorizedAccess from 'clerkAuth/UnauthorizedAccess';

function App() {
  return (
    <Routes>
      <Route element={<AuthRoute />}>
        <Route path='*' element={<Admin />} />
      </Route>
      <Route path="/login" element={<SignInPage />} />
      {/* <Route path="/unauthorized" element={<UnauthorizedAccess />} /> */}
    </Routes>
  );
}

export default App;
