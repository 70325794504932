import React, { useState, useEffect } from 'react';
import {
  Modal, Box, Typography, IconButton, Grid, Paper,
  Checkbox, Badge, Chip
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Check } from 'lucide-react';

const AmenityPopup = ({ isOpen, onClose, amenities, selectedAmenities, onAddAmenity }) => {
  const [tempSelected, setTempSelected] = useState([]);

  useEffect(() => {
    if (isOpen) {
      setTempSelected(selectedAmenities);
    }
  }, [isOpen, selectedAmenities]);

  const handleClose = () => {
    const amenitiesToAdd = amenities.filter(amenity =>
      tempSelected.includes(amenity._id)
    );
    onAddAmenity(amenitiesToAdd);
    onClose();
  };

  const handleSelect = (amenityId) => {
    setTempSelected(prev => {
      if (prev.includes(amenityId)) {
        return prev.filter(id => id !== amenityId);
      } else {
        return [...prev, amenityId];
      }
    });
  };

  const getNewSelectionsCount = () => {
    return tempSelected.filter(id => !selectedAmenities.includes(id)).length;
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="amenity-modal-title"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 2,
        p: 4,
        maxHeight: '80vh',
        overflow: 'auto'
      }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3
        }}>
          <Typography variant="h6" component="h2">
            Select Amenities
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {getNewSelectionsCount() > 0 && (
              <Chip
                label={`${getNewSelectionsCount()} new selected`}
                color="primary"
                size="small"
                className="!bg-teal-600"
              />
            )}
            <IconButton
              onClick={handleClose}
              className="!text-gray-500 hover:!text-gray-700"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Selected: {tempSelected.length} amenities
          </Typography>
        </Box>

        {amenities.length > 0 ? (
          <Grid container spacing={2}>
            {amenities.map((amenity) => (
              <Grid item xs={12} sm={6} key={amenity._id}>
                <Paper
                  elevation={0}
                  variant="outlined"
                  sx={{
                    p: 2,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    borderColor: tempSelected.includes(amenity._id) ? 'primary.main' : 'divider',
                    bgcolor: tempSelected.includes(amenity._id) ? 'action.selected' : 'background.paper',
                    '&:hover': {
                      bgcolor: tempSelected.includes(amenity._id)
                        ? 'action.selected'
                        : 'action.hover',
                      transform: 'translateY(-1px)',
                      boxShadow: 1
                    },
                  }}
                  onClick={() => handleSelect(amenity._id)}
                  className={`
                    ${tempSelected.includes(amenity._id) ? '!border-teal-600 !bg-teal-50' : ''}
                  `}
                >
                  {/* <Checkbox
                    checked={tempSelected.includes(amenity._id)}
                    onChange={() => handleSelect(amenity._id)}
                    color="primary"
                    className={`
                      ${tempSelected.includes(amenity._id) ? '!text-teal-600' : ''}
                    `}
                  /> */}

                  <Box className="truncate" sx={{ display: 'flex', alignItems: 'center', gap: 1, flex: 1 }}>
                    <div>
                      {tempSelected.includes(amenity._id) ? (
                        <Check className="text-white w-4 h-4 p-1 bg-teal-400 rounded-full" />
                      ) : (
                        <div className="w-4 h-4 p-1 bg-white border-1 border-gray-400 rounded-full" />
                      )}
                    </div>
                    <img
                      src={amenity.iconUrl}
                      alt={amenity.name}
                      style={{
                        width: 24,
                        height: 24,
                        objectFit: 'contain'
                      }}
                    />
                    <Typography
                      variant="body2"
                      className={`
                        ${tempSelected.includes(amenity._id) ? 'text-teal-700' : 'text-gray-700'}
                      `}
                    >
                      {amenity.name}
                    </Typography>

                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'center', py: 4 }}>
            No amenities available
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default AmenityPopup;