import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
  Switch,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createOpenAiConfig } from '../services/serverApi.adminConfig';
import { toast } from 'react-toastify';

const validationSchema = Yup.object({
  type: Yup.string().required('Type is required'),
  description_openai: Yup.string().required('Description OpenAI is required'),
  enable: Yup.boolean().required('Enable is required'),
  configuration: Yup.array()
    .of(
      Yup.object().shape({
        field: Yup.string().required('Field is required'),
        value: Yup.string().required('Value is required'),
        agent: Yup.string().required('Agent is required'),
        enable: Yup.boolean().required('Enable is required'),
        RequireReservation: Yup.boolean().required(
          'RequireReservation is required',
        ),
      }),
    )
    .optional()
    .default([]),
});

const initialValues = {
  type: '',
  description_openai: '',
  enable: false,
  configuration: [
    // {
    //   field: '',
    //   value: '',
    //   agent: '',
    //   enable: false,
    //   RequireReservation: false,
    // },
  ],
};

const AddOpenAIConfigDialog = ({ open, onClose, addAiConfig }) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const response = await createOpenAiConfig(values);
        const data = response?.data?.openAiConfig;
        addAiConfig(data);
        toast.success('Addition Successful');
        onClose();
        resetForm();
      } catch (error) {
        console.error('Error adding config:', error);
        toast.error("Erreur lors de l'ajout");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleRemoveConfig = (index) => {
    const updatedConfiguration = [...formik.values.configuration];
    updatedConfiguration.splice(index, 1);
    formik.setFieldValue('configuration', updatedConfiguration);
  };

  return (
    <Dialog
      PaperProps={{ style: { width: 500 } }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle sx={{ padding: '30px', textAlign: 'center' }}>
        Add OpenAI Configuration
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3 text-center">
            <label htmlFor="type" className="form-label">
              Type
            </label>
            <TextField
              fullWidth
              id="type"
              name="type"
              label="Type"
              value={formik.values.type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.type && Boolean(formik.errors.type)}
              helperText={formik.touched.type && formik.errors.type}
            />
          </div>
          <div className="mb-3 text-center">
            <label htmlFor="description_openai" className="form-label">
              Description
            </label>
            <TextField
              fullWidth
              id="description_openai"
              name="description_openai"
              label="Description OpenAI"
              multiline
              rows={4}
              value={formik.values.description_openai}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.description_openai &&
                Boolean(formik.errors.description_openai)
              }
              helperText={
                formik.touched.description_openai &&
                formik.errors.description_openai
              }
            />
          </div>
          <div className="mb-3 text-center">
            <label htmlFor="enable" className="form-label">
              Enable
            </label>
            <div>
              <Switch
                id="enable"
                name="enable"
                checked={formik.values.enable}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <Box mt={2}>
            {/* {formik.values.configuration.map((config, index) => (
              <div key={index} className="mb-3 text-center">
                <div>
                  <label className="form-label">Config N° {index + 1}</label>
                </div>
                <div className="mb-3 text-center">
                  <label htmlFor={`configuration.${index}.field`} className="form-label">Field</label>
                  <TextField
                    fullWidth
                    id={`configuration.${index}.field`}
                    name={`configuration.${index}.field`}
                    label={`Configuration Field ${index + 1}`}
                    value={formik.values.configuration[index].field}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.configuration?.[index]?.field && Boolean(formik.errors.configuration?.[index]?.field)}
                    helperText={formik.touched.configuration?.[index]?.field && formik.errors.configuration?.[index]?.field}
                  />
                </div>
                <div className="mb-3 text-center">
                  <label htmlFor={`configuration.${index}.value`} className="form-label">Value</label>
                  <TextField
                    fullWidth
                    id={`configuration.${index}.value`}
                    name={`configuration.${index}.value`}
                    label={`Configuration Value ${index + 1}`}
                    value={formik.values.configuration[index].value}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.configuration?.[index]?.value && Boolean(formik.errors.configuration?.[index]?.value)}
                    helperText={formik.touched.configuration?.[index]?.value && formik.errors.configuration?.[index]?.value}
                  />
                </div>
                <div className="mb-3 text-center">
                  <label htmlFor={`configuration.${index}.agent`} className="form-label">Agent</label>
                  <TextField
                    fullWidth
                    id={`configuration.${index}.agent`}
                    name={`configuration.${index}.agent`}
                    label={`Configuration Agent ${index + 1}`}
                    value={formik.values.configuration[index].agent}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.configuration?.[index]?.agent && Boolean(formik.errors.configuration?.[index]?.agent)}
                    helperText={formik.touched.configuration?.[index]?.agent && formik.errors.configuration?.[index]?.agent}
                  />
                </div>
                <div className="mb-3 text-center">
                  <label htmlFor={`configuration.${index}.enable`} className="form-label">Enable</label>
                  <div>
                    <Switch
                      id={`configuration.${index}.enable`}
                      name={`configuration.${index}.enable`}
                      checked={formik.values.configuration[index].enable}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.configuration?.[index]?.enable && formik.errors.configuration?.[index]?.enable && (
                      <div>{formik.errors.configuration[index].enable}</div>
                    )}
                  </div>
                </div>
                <div className="mb-3 text-center">
                  <label htmlFor={`configuration.${index}.RequireReservation`} className="form-label">RequireReservation</label>
                  <div>
                    <Switch
                      id={`configuration.${index}.RequireReservation`}
                      name={`configuration.${index}.RequireReservation`}
                      checked={formik.values.configuration[index].RequireReservation}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.configuration?.[index]?.RequireReservation && formik.errors.configuration?.[index]?.RequireReservation && (
                      <div>{formik.errors.configuration[index].RequireReservation}</div>
                    )}
                  </div>
                </div>
                <button
                  type="button"
                  style={{ margin: '12px auto 4px auto', display: 'block' }}
                  className="btn btn-danger"
                  onClick={() => handleRemoveConfig(index)}
                >
                  Remove
                </button>
              </div>
            ))} */}
            <button
              type="button"
              style={{ margin: '12px auto 4px auto', display: 'block' }}
              className="btn btn-primary"
              onClick={() =>
                formik.setFieldValue('configuration', [
                  ...formik.values.configuration,
                  {
                    field: '',
                    value: '',
                    agent: '',
                    enable: false,
                    RequireReservation: false,
                  },
                ])
              }
            >
              Add
            </button>
          </Box>
          <DialogActions>
            <Button onClick={onClose} color="secondary">
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
            >
              {formik.isSubmitting ? 'Adding...' : 'Add'}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddOpenAIConfigDialog;
