import React from 'react';
import { Grid } from '@mui/material';


const LoadingListing = () => {
    const loadingStyles = {
        container: {
            background: 'white',
            padding: '25px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        imageContainer: {
            width: '25%',
            height: '250px',
            backgroundColor: '#f0f0f0',
            borderRadius: '4px',
        },
        contentContainer: {
            flex: 1,
            marginLeft: '20px',
        },
        line: {
            height: '16px',
            backgroundColor: '#e2e8f0',
            borderRadius: '4px',
            marginBottom: '8px',
        },
        line0: {
            width: '60%',
        },
        line1: {
            width: '60%',
        },
        line2: {
            width: '80%',
            height: '12px',
        },
        line3: {
            width: '30%',
            height: '8px',
        },
        line4: {
            width: '15%',
            height: '16px',
            marginBottom: '12px',
        },
    };

    return (
        <>

            <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Grid container style={loadingStyles.container}>
                            <div className="animate-pulse w-full flex">
                                <div style={loadingStyles.imageContainer} />
                                <div style={loadingStyles.contentContainer}>
                                    <div style={{ ...loadingStyles.line, ...loadingStyles.line0 }} />
                                    <div style={{ ...loadingStyles.line, ...loadingStyles.line1 }} />
                                    <div style={{ ...loadingStyles.line, ...loadingStyles.line2 }} />
                                    <div style={{ ...loadingStyles.line, ...loadingStyles.line3 }} />
                                    <div style={{ ...loadingStyles.line, ...loadingStyles.line4 }} />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
            </Grid>
    
        </>
    );
};

export default LoadingListing;