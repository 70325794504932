import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';


const StyledTextField = styled(TextField)({
    width: '100%',
    margin: '10px',
    marginLeft: '0', 
    '& .MuiOutlinedInput-root': {
      height: '40px', 
      '& fieldset': {
        borderRadius: '4px',
      },
      '&:hover fieldset': {
        borderColor: '#b3b3b3',
      },
    },
    '& .MuiInputBase-input': {
      padding: '9px 14px',
      height: '100px', 
      alignItems: 'flex-start', 
    },
});

const ReservationNumberFilter = ({ reservationNumber, setReservationNumber }) => {
  const handleChange = (event) => {
    setReservationNumber(event.target.value);
  };

  return (
    <StyledTextField
      placeholder="Reservation Number"
      value={reservationNumber}
      onChange={handleChange}
      variant="outlined"
      size="small"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default ReservationNumberFilter;