import React, { useState, useEffect } from 'react';
import {
  Modal, Box, Typography, Grid, Chip, IconButton, CircularProgress, Collapse
} from '@mui/material';
import { X, Calendar, Clock, DollarSign, User, Home, Briefcase, ChevronDown, ChevronUp } from 'lucide-react';
import moment from 'moment';

const DetailsTask = ({ event, open, onClose }) => {
  const [showReservationInfo, setShowReservationInfo] = useState(false);

  const toggleReservationInfo = () => {
    setShowReservationInfo(!showReservationInfo);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'PENDING':
        return 'warning';
      case 'IN_PROGRESS':
        return 'primary';
      case 'COMPLETED':
        return 'success';
      case 'CANCELED':
        return 'error';
      case 'CANCELED-BY-CUST':
        return 'error';
      default:
        return 'default';
    }
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 'PENDING':
        return 'Pending';
      case 'IN_PROGRESS':
        return 'In Progress';
      case 'COMPLETED':
        return 'Completed';
      case 'CANCELED':
        return 'Cancelled';
      case 'CANCELED-BY-CUST':
        return 'Cancelled by Customer';
      default:
        return status || 'Unknown';
    }
  };


  return (
    <Modal open={open} onClose={onClose} aria-labelledby="task-details-modal">
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        maxWidth: '90%',
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Box sx={{ p: 2, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" component="h2">
              Task Details
            </Typography>
            <IconButton onClick={onClose} size="small">
              <X size={20} />
            </IconButton>
          </Box>
          <Chip
              label={getStatusLabel(event.assignmentStatus)}
              color={getStatusColor(event.assignmentStatus)}
              size="small"
            />
        </Box>

        <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center" mb={1}>
                <Briefcase size={18} style={{ marginRight: 8 }} />
                <Typography variant="body2" color="text.secondary">Type</Typography>
              </Box>
              <Typography variant="body1">{event.type || 'Not specified'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center" mb={1}>
                <Briefcase size={18} style={{ marginRight: 8 }} />
                <Typography variant="body2" color="text.secondary">Sub-Type</Typography>
              </Box>
              <Typography variant="body1">{event.subType || ' '}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={1}>
                <Calendar size={18} style={{ marginRight: 8 }} />
                <Typography variant="body2" color="text.secondary">Date</Typography>
              </Box>
              <Typography variant="body1">{moment(event.date).format('YYYY-MM-DD')}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Box display="flex" alignItems="center" mb={1}>
                <Clock size={18} style={{ marginRight: 8 }} />
                <Typography variant="body2" color="text.secondary">Start Time</Typography>
              </Box>
              <Typography variant="body1">{moment(event.checkInTime, 'H').format('HH:mm')}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center" mb={1}>
                <Clock size={18} style={{ marginRight: 8 }} />
                <Typography variant="body2" color="text.secondary">End Time</Typography>
              </Box>
              <Typography variant="body1">{moment(event.checkOutTime, 'H').format('HH:mm')}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Box display="flex" alignItems="center" mb={1}>
                <User size={18} style={{ marginRight: 8 }} />
                <Typography variant="body2" color="text.secondary">Staff</Typography>
              </Box>
              <Typography variant="body1">{event.assignmentStatus || 'Not assigned'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center" mb={1}>
                <User size={18} style={{ marginRight: 8 }} />
                <Typography variant="body2" color="text.secondary">Assignee</Typography>
              </Box>
              <Typography variant="body1">{event.Staff?.username || 'Not assigned'}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Box display="flex" alignItems="center" mb={1}>
                <DollarSign size={18} style={{ marginRight: 8 }} />
                <Typography variant="body2" color="text.secondary">Total price</Typography>
              </Box>
              <Typography variant="body1">{`$${event.price || 0} USD`}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center" mb={1}>
                <DollarSign size={18} style={{ marginRight: 8 }} />
                <Typography variant="body2" color="text.secondary">Payment Status</Typography>
              </Box>
              <Chip
                label={event.paid ? 'Paid' : 'Unpaid'}
                color={event.paid ? 'success' : 'error'}
                size="small"
              />
            </Grid>
            {event.reservationCode && (
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  onClick={toggleReservationInfo}
                  sx={{ cursor: 'pointer', mb: 1 }}
                >
                  <Box display="flex" alignItems="center">
                    <Home size={18} style={{ marginRight: 8 }} />
                    <Typography variant="body2" color="text.secondary">Reservation Info</Typography>
                  </Box>
                  <IconButton size="small">
                    {showReservationInfo ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
                  </IconButton>
                </Box>
                <Collapse in={showReservationInfo}>
                  <Box sx={{ pl: 2, pt: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="text.secondary">Reservation Number</Typography>
                        <Typography variant="body1">{event.reservationCode}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" color="text.secondary">Guest</Typography>
                        <Typography variant="body1">{event.guestName || 'Not specified'}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">Check In</Typography>
                        <Typography variant="body1">{moment(event.startDate).format('ddd, MMM D, YYYY')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">Check Out</Typography>
                        <Typography variant="body1">{moment(event.endDate).format('ddd, MMM D, YYYY')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box display="flex" alignItems="center" mb={1}>
                          <Clock size={18} style={{ marginRight: 8 }} />
                          <Typography variant="body2" color="text.secondary">Check In Time</Typography>
                        </Box>
                        <Typography variant="body1">{moment(event.checkInTime, 'H').format('HH:mm')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box display="flex" alignItems="center" mb={1}>
                          <Clock size={18} style={{ marginRight: 8 }} />
                          <Typography variant="body2" color="text.secondary">Check Out Time</Typography>
                        </Box>
                        <Typography variant="body1">{moment(event.checkOutTime, 'H').format('HH:mm')}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">Guests</Typography>
                        <Typography variant="body1">{`Adults: ${event.adults || '0'}, Children: ${event.children || '0'}`}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">Channel</Typography>
                        <Chip
                          label={event.channel || 'Airbnb'}
                          size="small"
                          color="primary"
                          sx={{ mt: 1 }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">Total Price</Typography>
                        <Typography variant="body1" fontWeight="bold">{`$${event.price || 0} USD`}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2" color="text.secondary">Payment Status</Typography>
                        <Chip
                          label={event.paid ? 'Paid' : 'Unpaid'}
                          color={event.paid ? 'success' : 'error'}
                          size="small"
                          sx={{ mt: 1 }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default DetailsTask;