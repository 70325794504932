import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress } from '@mui/material';
import  EditIcon  from '@mui/icons-material/Edit';
import  CloseIcon  from '@mui/icons-material/Close';
import CheckIcon  from '@mui/icons-material/Check';
import defaultImage from 'assets/images/image_placeholder.jpg';
import defaultAvatar from 'assets/images/placeholder.jpg';
import { useSelector, useDispatch } from 'react-redux';
import { uploadImageResetAction,uploadImageToAPI  } from '../../redux/slices/UploadSlice';
import * as yup from 'yup';
import { useFormik } from 'formik';

const validationSchema = yup.object({
  file: yup.string().required(),
});

export default function ImageUpload(props) {
  const dispatch = useDispatch();
  const upload = useSelector((state) => state.uploadData);
  const { iconUrl, error, loading, newUpload } = upload;
  let { avatar, folder, inputProps, style, btnSize } = props;

  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');
  const fileInput = useRef(null);
  const formik = useFormik({
    initialValues: {
      file: '',
    },
    validationSchema,
  });

  const handleImageChange = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
      formik.setValues({ 'file': file });
    }
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
    dispatch(uploadImageResetAction());
  };

  useEffect(() => {
    dispatch(uploadImageResetAction());
    setImagePreviewUrl(props.defaultImage && !file ? props.defaultImage : props.avatar ? defaultAvatar : defaultImage);
  }, []);


  const onUploadMedia = (file) => {
    // var folder = ['city','blog','listing','slide','other']
    dispatch(uploadImageToAPI({ file, folder }));
  };

  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} style={{ display: 'none' }} />
      <div className={'thumbnail' + (avatar ? ' img-circle' : '')}>
        <img src={imagePreviewUrl} alt="..." style={style?.img ? style.img : {}} />
      </div>
      <div>
        {file === null ? (
          <Button  onClick={handleClick} >
            {props.label ? props.label : avatar ? 'Add Photo' : 'Select image'}
          </Button>
        ) : (
          <>
             <Box display="flex" justifyContent="center">
      <Button
        onClick={handleClick}
        variant="contained"
        sx={{ borderRadius: '12px', minWidth: 'auto', margin:'5px 0 0 1px',color:'white !important' }}
      >
        <EditIcon />
      </Button>

      <Box mx={0.5}>
        <Button
          onClick={handleRemove}
          variant="contained"
          sx={{ borderRadius: '12px', minWidth: 'auto',  margin:'5px 0 0 1px',color:'white !important'}}
        >
          <CloseIcon />
        </Button>
      </Box>

      <Button
        onClick={() => onUploadMedia(file)}
        variant="contained"
        style={{color:'red !imprtant'}}
        disabled={!formik.dirty || !formik.isValid || loading}
        sx={{ borderRadius: '12px', minWidth: 'auto',color:'white !important', margin:'5px 0 0 1px' }}
      >
        {loading ? <CircularProgress size={24} /> : <CheckIcon />}
      </Button>
    </Box>
          </>
        )}
        {error && <h5 style={{ color: '#ca0b00' }}>{error}</h5>}
        {iconUrl && newUpload && <h5 style={{ color: '#4BB543' }}>Image has been uploaded</h5>}
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  defaultImage: PropTypes.string,
  folder: PropTypes.string,
  onSubmit: PropTypes.func,
  onRemove: PropTypes.func,
};
