import React, { useState, useEffect } from 'react';
import { Typography, Box, Tab, Tabs, CircularProgress } from '@mui/material';
import PauseIcon from '@mui/icons-material/Pause';
import ReservationDataTab from './ReservationDataTab';
import ReservationTasksTab from './ReservationTasksTab';
import { getReservationDetails, getTasks } from './services/serverApi.chatConfig';

const ChatReservationDetails = ({ activeConversation }) => {
  const [tabValue, setTabValue] = useState(0);
  const [reservationDetails, setReservationDetails] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const reservationMessage = activeConversation?.find(msg => msg.num_reservation);

  useEffect(() => {
    const fetchReservationAndTasks = async () => {
      if (!reservationMessage?.num_reservation) return;

      setIsLoading(true);
      try {
        const reservationResponse = await getReservationDetails(reservationMessage.num_reservation);
        if (reservationResponse.data.success) {
          const reservation = reservationResponse.data.reservation;
          setReservationDetails(reservation);

          const tasksResponse = await getTasks(reservation.arrivalDate, reservation.departureDate, [reservation.sojoriId]);
          if (tasksResponse && tasksResponse.data) {
            const tasksData = tasksResponse.data[reservation.sojoriId] || [];
            setTasks(tasksData);
          }
        }
      } catch (error) {
        console.error('Error fetching reservation details or tasks:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchReservationAndTasks();
  }, [reservationMessage]);

  const handleTabChange = (event, newValue) => setTabValue(newValue);

  if (!reservationMessage) {
    return (
      <div>
        <Typography variant="h6" className="p-4" style={{ borderBottom: '1px solid #ccc' }}>Reservation Details</Typography>
        <Typography className="p-4">No reservation details available.</Typography>
      </div>
    );
  }

  return (
    <div>
      <Typography variant="h6" className="p-4" style={{ borderBottom: '1px solid #ccc' }}>Reservation Details</Typography>
      <div className="" style={{ height: 'calc(100vh - 160px)' }}>
        <div className="p-4">
          <Typography variant="body1" className="cursor-pointer text-black p-2 flex justify-between items-center rounded-md" style={{ border: '1px solid #ccc' }}>
            Pause AI Reply
            <PauseIcon className="cursor-pointer text-black !text-2xl rounded-sm" style={{ fontSize: '24px', border: '2px solid #ccc' }} />
          </Typography>
        </div>
        <div className="border-b border-gray-300">
          <div className="flex justify-between">
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="reservation tabs" className="flex-1" >
              <Tab label="Data" className="flex-1" />
              <Tab label="Tasks" className="flex-1" />
            </Tabs>
          </div>
        </div>
        <div className="overflow-y-auto scrollbar-thin scrollbar-thumb-medium-aquamarine scrollbar-track-gray-100" style={{ height: 'calc(100vh - 240px)' }}>
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <CircularProgress sx={{ color: '#00b4b4' }} />
            </div>) : tabValue === 0 ? (
              <ReservationDataTab
                reservationMessage={reservationMessage}
                reservationDetails={reservationDetails}
              />
            ) : (
            <ReservationTasksTab tasks={tasks} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatReservationDetails;