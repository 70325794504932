import React, { useState } from 'react';
import { Chip } from 'primereact/chip';
import { Button } from '@mui/material';

const ViewToggle = ({ view, setView, toggleFilters }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    return (
        <div className="flex mb-2.5 items-center justify-between gap-4">
            <button 
                className="px-2 !bg-[#dcf6f6] !rounded-sm flex items-center gap-1 !mr-4 h-8" 
                onClick={toggleFilters}
            >
                <Chip 
                    label="Filters" 
                    icon={<i className="pi pi-filter"></i>} 
                    className="bg-transparent text-[#00b4b4] border-none"
                />
            </button>

            <div className="px-2 flex items-center border border-gray-300 rounded gap-2">
                <i className="pi pi-search ml-2 text-gray-500"></i>
                <input 
                    type="text" 
                    placeholder="Concept" 
                    value={searchQuery} 
                    onChange={handleSearchChange} 
                    className=" outline-none rounded-r !text-sm !rounded-sm flex items-center gap-1 !mr-4 h-8"
                />
            </div>
        </div>
    );
};

export default ViewToggle;
