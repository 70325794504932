import React, { useState, useEffect } from 'react';
import { TextField, Switch, Typography, MenuItem, Alert, Button } from '@mui/material';
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { getRatePlan, getCities, updateRatePlan } from '../services/serverApi.dynamicPricing';
import MonthWiseRule from '../components/MonthWishRule';
import WeekdayWeekendRule from '../components/WeekdayWeekendRule';
import SeasonRule from '../components/SeasonRule';
import {toast, ToastContainer} from 'react-toastify';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

const DynamicPricingComponent = () => {
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [ratePlan, setRatePlan] = useState(null);
  const [error, setError] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [staging, setStaging] = useState(JSON.parse(localStorage.getItem('isStaging')) || false);


  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    const handleStagingToggle = (event) => {
      setStaging(event.detail);

    };

    window.addEventListener('stagingToggle', handleStagingToggle);

    if (selectedCity) {
      fetchRatePlan(selectedCity, staging);
    }

    return () => {
      window.removeEventListener('stagingToggle', handleStagingToggle);
    };
  }, [selectedCity, staging]);


  const fetchCities = async () => {
    try {
      const response = await getCities();
      setCities(response.data);
    } catch (error) {
      console.error('Error fetching cities:', error);
      setError('Failed to fetch cities. Please try again.');
    }
  };

 const fetchRatePlan = async (cityId, staging = false) => {
    setError(null);
    try {
      const response = await getRatePlan(cityId, staging);
      if (response.data.success && response.data.data.length > 0) {
        setRatePlan(response.data.data[0]);
        setHasUnsavedChanges(false);
      } else {
        throw new Error('No rate plan found');
      }
    } catch (error) {
      console.error('Error fetching rate plan:', error);
      setError('Failed to fetch rate plan. Please try again.');
      setRatePlan(null);
    }
  };


  const handleCityChange = (event) => {
    const cityId = event.target.value;
    setSelectedCity(cityId);
    // fetchRatePlan(cityId);
  };

  const handleRulePlanChange = (changes) => {
    setRatePlan(prevRatePlan => ({
      ...prevRatePlan,
      ...changes
    }));
    setHasUnsavedChanges(true);
  };

  const handleSaveChanges = async () => {
    try {
      if (!ratePlan || !ratePlan._id) {
        throw new Error('No rate plan ID available');
      }
      const response = await updateRatePlan(ratePlan._id, ratePlan);
      if (response.data.success) {
        setHasUnsavedChanges(false);
        toast.success('Rate plan updated successfully');
      } else {
        throw new Error(response.data.message || 'Failed to update rate plan');
      }
    } catch (error) {
      console.error('Error updating rate plan:', error);
      setError('Failed to save changes. Please try again.');
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer />
      <div className="card p-4">
        <Typography variant="h4" gutterBottom>
          Dynamic Pricing
        </Typography>

        <TextField
          select
          label="Select City"
          value={selectedCity}
          onChange={handleCityChange}
          fullWidth
          margin="normal"
        >
          {cities.map((city) => (
            <MenuItem key={city._id} value={city._id}>
              {city.name}
            </MenuItem>
          ))}
        </TextField>
        
        {error && <Alert severity="error">{error}</Alert>}

        {ratePlan && (
          <>
          <div className="flex justify-between items-center">
            <Typography variant="h5" gutterBottom>
              Apply Dynamic Pricing
              <Switch
                checked={ratePlan.Active}
                onChange={(e) => handleRulePlanChange({ Active: e.target.checked })}
                sx={{ '& .MuiSwitch-switchBase.Mui-checked': { color: '#00b4b4' }, '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#00b4b4' } }}
              />
            </Typography>

            <Button
              variant="contained"
              onClick={handleSaveChanges}
              disabled={!hasUnsavedChanges}
              className="!bg-medium-aquamarine flex gap-1 !h-0 !hover:bg-dark-aquamarine !text-white !capitalize"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <PublishedWithChangesIcon className="" />
              <span className={`overflow-hidden  ${isHovered ? 'w-12 opacity-100' : 'w-0 opacity-0'}`}>
                Save
              </span>
            </Button>
          </div>



            <MonthWiseRule
              ratePlan={ratePlan}
              onChange={handleRulePlanChange}
            />

            <WeekdayWeekendRule
              ratePlan={ratePlan}
              onChange={handleRulePlanChange}
            />

            <SeasonRule
              ratePlan={ratePlan}
              onChange={handleRulePlanChange}
            />

          </>
        )}
      </div>
    </DashboardLayout>
  );
};

export default DynamicPricingComponent;