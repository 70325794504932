import React from 'react';
import { Card, CardContent, Typography, Box, ListItem, ListItemText } from '@mui/material';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChatIcon from '@mui/icons-material/Chat';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Link } from 'react-router-dom';

const DashboardCard = ({ title, count, subtitle, isCurrent, next7Days, last30Days, percentage, listingList, className }) => {

  // const handleReservationClick = (listingId) => {
  //   console.log('Icon clicked:', listingId);
  //   window.open(`/admin/Reservation/${listingId}/details`, '_blank');
  // };

  return (
    <Card className={`!rounded-none flex flex-col justify-between m-2 !shadow-xs ${className || ''}`}> {/* Fixed height for uniformity */}
      <CardContent className="p-0 flex flex-col h-full">
        <Box className="!p-[10px] flex justify-between items-center border-b-[1px] border-gray-300">
          <Typography variant="h6" component="div" className="flex gap-1 items-center !text-base !font-normal !text-[#676A6C]">
            <EventAvailableIcon className="!text-base !font-normal" />
            {title}
          </Typography>
          <Box className={`text-sm font-semibold text-white ${isCurrent ? 'bg-teal-500' : next7Days ? 'bg-teal-500' : last30Days ? 'bg-teal-500' : ''} px-2 rounded-sm font-normal text-[0.70rem]`}>
            {isCurrent ? 'Current' : next7Days ? 'Next 7 days' : last30Days ? 'Last 30 days' : ''}
          </Box>
        </Box>
        <Box className="!px-4 !py-4 flex-grow">
          <div>
            <Typography variant="h3" component="div" className="mt-2 text-3xl !font-light !text-[#676A6C]">
              {count}
            </Typography>
            <div className="flex items-center justify-between">
              <Typography variant="body2" className="!text-xs !font-light !text-[#676A6C]">
                {subtitle}
              </Typography>
              {percentage && (
                <Typography className={`!text-sm ${percentage === '0' || percentage === '0.00' ? 'text-black' : percentage.startsWith('-') ? 'text-red-500' : 'text-green-500'}`}>
                  {parseFloat(percentage).toFixed(2).replace(/\.00$/, '')}
                  {percentage.startsWith('-') ? <TrendingDownIcon /> : percentage !== '0' && percentage !== '0.00' ? <TrendingUpIcon /> : null}
                </Typography>
              )}
            </div>
          </div>
        </Box>
        {listingList && (
          <Box className="mx-2 my-2 overflow-y-scroll" style={{ maxHeight: '100px' }}>
            {listingList.map((listing, index) => (
              <ListItem key={index} className={`${index % 2 === 0 ? 'bg-gray-100' : 'bg-white'} !p-[0.55rem]`}>
                <ListItemText className="flex  gap-2 items-center"
                  primary={
                    <Link to={`/admin/Reservation/${listing._id}/details`} target="_blank" rel="noopener noreferrer">
                    <Typography className="!text-xs text-teal-500 flex items-center gap-2 cursor-pointer">
                      {listing.image} {listing.Listingname} ({listing.CheckInDate} {listing.CheckinTime} - {listing.CheckOutDate} {listing.CheckOutTime})
                    </Typography>
                    </Link>
                  }
                  secondary={
                    <div className="flex items-center gap-2 mt-0">
                      <Link to={`/admin/Reservation/${listing._id}/details`} target="_blank" rel="noopener noreferrer" style={{ all: 'unset' }}>
                        <CalendarTodayIcon className="text-teal-500 cursor-pointer" style={{ fontSize: 16 }}/>
                      </Link>
                      <Link to={"#"} target="_blank" rel="noopener noreferrer">
                      <CalendarMonthIcon className="text-teal-500 cursor-pointer" style={{ fontSize: 16 }}/>
                      </Link>
                      <Link to={"#"} target="_blank" rel="noopener noreferrer">
                      <ChatIcon className="text-teal-500 cursor-pointer" style={{ fontSize: 16 }}/>
                      </Link>
                    </div>
                  }
                />
              </ListItem>
            ))}
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default DashboardCard;
