import React, { useCallback, useState } from 'react';
import { Box, Typography, TextField, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import Modal from '@mui/material/Modal';


const MediaContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 200,
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: 'white',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}));

function Attachment({formik}) {
  const [fileName, setFileName] = useState(null);
  const [filePath, setFilePath] = useState(null);
  const [isPublic, setIsPublic] = useState('yes');
  const [savedData, setSavedData] = useState({ fileName: null, isPublic: null });
  const [showForm, setShowForm] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const toggleForm = () => {
    setShowForm(prevShowForm => !prevShowForm);
  };

  const handleSave = () => {
    setSavedData({ fileName, isPublic });
    setShowForm(false);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFileName(acceptedFiles[0].name);
    setFilePath(URL.createObjectURL(acceptedFiles[0]));
  }, []);


  const confirmDelete = () => {
    setFileName(null);
    setFilePath(null);
    setOpen(false)
  };

  const handlePublicChange = (event) => {
    setIsPublic(event.target.value);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*, video/mp4',
    maxSize: 52428800, 
  });

  return (
    <Box className="w-full h-[30rem] relative">
      <Box className="absolute m-4">
        <Typography variant="h5" gutterBottom>
          Attachment
        </Typography>
      </Box>

      <Box className="w-full flex justify-center items-center h-[19rem]">
        <Box>
          <MediaContainer {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drag the media and drop it here...</Typography>
            ) : (
              <Typography>Drag the media and drop it here</Typography>
            )}
          </MediaContainer>
        </Box>
      </Box>

      <Box className="flex justify-center">
        {fileName && filePath && (
          <Box className="w-[94%] h-auto border-1 border-gray-300 rounded-md absolute">
            <Box className="flex gap-1 items-center m-2">
              <img className="w-16 h-16" src={filePath} alt="uploaded file" />
              <Typography className="!text-[14px] !text-[#6fd1bd] !font-light" variant="body1">
                {fileName}
              </Typography>
              <Box className="absolute right-3 top-2 text-black">
                <button className="px-2" onClick={toggleForm} aria-label="Edit">
                  <i className="pi pi-pencil text-gray-600"></i>
                </button>
                <button className="mx-1" onClick={handleOpen} aria-label="Delete">
                  <i className="pi pi-times !font-bold"></i>
                </button>
              </Box>
            </Box>
            {showForm && (
              <Box className="flex justify-center gap-3 p-3">
                <TextField fullWidth label="Name" value={fileName} onChange={(e) => setFileName(e.target.value)} />
                <Box className="w-1/2">
                  <TextField
                    select
                    label="Is public?"
                    value={isPublic}
                    onChange={handlePublicChange}
                    fullWidth
                  >
                    <MenuItem value="yes">Yes</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                  </TextField>
                </Box>

                <Box className="flex gap-2 justify-end">
                  <button
                    className="px-2 py-1 !rounded-md bg-[#6fd1bd] !text-[13px] text-white"
                    onClick={handleSave}
                    aria-label="Update"
                  >
                    update
                  </button>
                  <button
                    className="px-2 py-1 !text-[13px] text-gray-700"
                    onClick={toggleForm}
                    aria-label="Cancel"
                  >
                    Cancel
                  </button>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
      >
        <Box class="absolute top-36 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[550px] rounded-sm shadow-[24]  bg-[#f8fafb]">
          <Box className="w-full mb-11 p-4">
            <Typography className="!text-[14px] !text-center" id="modal-modal-description" >
              Are you sure you want to delete this file?
            </Typography>
          </Box>
          <Box className="flex justify-end p-3 bg-white border-t-1 border-gray-200">

            <Box className="flex gap-2">
              <button
                className="px-3 py-1 bg-[#d6fff7] text-[#70d4c0] !rounded-sm mr-2 !text-[16px]"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="px-3 py-1 bg-red-500 text-white !rounded-sm mr-2 !text-[16px]"
                onClick={confirmDelete}
              >
                Confirm
              </button>
            </Box>
          </Box>
        </Box>

      </Modal>

    </Box>
  );
}

export default Attachment;