import React from 'react';
import { Modal, Box, Typography, Grid, Chip, IconButton, Divider } from '@mui/material';
import { X, Home, User, Calendar, Clock, Users, DollarSign, CreditCard } from 'lucide-react';
import moment from 'moment';

const EventPopup = ({ event, open, onClose }) => {

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="event-details-modal"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        maxWidth: '90%',
        maxHeight: '90vh',
        overflow: 'auto',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        p: 3,
      }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h5" fontWeight="bold">
            Reservation Details
          </Typography>
          <IconButton onClick={onClose} size="small">
            <X />
          </IconButton>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Home size={20} style={{ marginRight: 8 }} />
              <Typography variant="h6">{event.propertyName || '6251 Cahuilla Avenue'}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <User size={20} style={{ marginRight: 8 }} />
              <Typography variant="body1"><strong>Guest:</strong> {event.guestName}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary">Reservation Number</Typography>
            <span className='text-[17px] font-bold'>{event.reservationNumber}</span>
          </Grid>

          <Grid item xs={6}>
            <Box display="flex" alignItems="center" mb={1}>
              <Calendar size={18} style={{ marginRight: 8 }} />
              <Typography variant="body2" color="text.secondary">Check In</Typography>
            </Box>
            <Typography variant="body1">{moment(event.startDate).format('ddd, MMM D, YYYY')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center" mb={1}>
              <Calendar size={18} style={{ marginRight: 8 }} />
              <Typography variant="body2" color="text.secondary">Check Out</Typography>
            </Box>
            <Typography variant="body1">{moment(event.endDate).format('ddd, MMM D, YYYY')}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Box display="flex" alignItems="center" mb={1}>
              <Clock size={18} style={{ marginRight: 8 }} />
              <Typography variant="body2" color="text.secondary">Check In Time</Typography>
            </Box>
            <Typography variant="body1">{moment(event.checkInTime, 'H').format('HH:mm')}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center" mb={1}>
              <Clock size={18} style={{ marginRight: 8 }} />
              <Typography variant="body2" color="text.secondary">Check Out Time</Typography>
            </Box>
            <Typography variant="body1">{moment(event.checkOutTime, 'H').format('HH:mm')}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Box display="flex" alignItems="center" mb={1}>
              <Users size={18} style={{ marginRight: 8 }} />
              <Typography variant="body2" color="text.secondary">Guests</Typography>
            </Box>
            <Typography variant="body1">{event.numberOfGuests}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" color="text.secondary">Channel</Typography>
            <Chip
              label={event.channel || 'Airbnb'}
              size="small"
              color="primary"
              sx={{ mt: 1 }}
            />
          </Grid>

          <Grid item xs={6}>
            <Box display="flex" alignItems="center" mb={1}>
              <DollarSign size={18} style={{ marginRight: 8 }} />
              <Typography variant="body2" color="text.secondary">Total Price</Typography>
            </Box>
            <Typography variant="body1" fontWeight="bold">{`$${event.price}`}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center" mb={1}>
              <CreditCard size={18} style={{ marginRight: 8 }} />
              <Typography variant="body2" color="text.secondary">Payment Status</Typography>
            </Box>
            <Chip
              label={event.paid ? 'Paid' : 'Unpaid'}
              className='text-white'
              color={event.paid ? 'success' : 'error'}
              size="small"
              sx={{ mt: 1 }}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default EventPopup;