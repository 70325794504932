import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createCountry } from '../services/serverApi.adminConfig';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Country name is required'),
  currency: Yup.string().required('Currency is required'),
  countryCode: Yup.string().required('country Code is required'),
});

const AddCountryDialog = ({ open, onClose, addCountry }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);
    const dataToSend = {
      country: {
        name: values.name,
        currency: values.currency,
        countryCode: values.countryCode
      }
    };
    createCountry(dataToSend)
      .then((data) => {
        addCountry(data)
        setSubmitting(false);
        onClose();
        toast.success("Country added successfully");
      })
      .catch(error => {
        setErrorMessage(error.message); // Assuming error.message contains error text
      })
      .finally(() => {
        setIsLoading(false); // End loading
      });
  };

  return (
    <Dialog PaperProps={{ style: { width: 800 } }} open={open} onClose={onClose}>
      <DialogTitle sx={{ padding: '30px', textAlign: 'center' }} className="!bg-medium-aquamarine !text-white mb-4"
      >Add Country</DialogTitle>
      <DialogContent>
        {errorMessage && <h5 className='text-center text-danger'>{errorMessage}</h5>}
        <Formik
          initialValues={{ name: '', currency: '', countryCode: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid, dirty }) => (
            <Form encType="multipart/form-data">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Country Name
                </label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  autoComplete="off"
                />
                <ErrorMessage name="name" component="div" className="text-danger" />
              </div>
              <div className="mb-3">
                <label htmlFor="currency" className="form-label">
                  Currency
                </label>
                <Field
                  type="text"
                  id="currency"
                  name="currency"
                  className="form-control"
                  autoComplete="off"
                />
                <ErrorMessage name="currency" component="div" className="text-danger" />
              </div>
              <div className="mb-3">
                <label htmlFor="country Code" className="form-label">
                  Country Code
                </label>
                <Field
                  type="text"
                  id="countryCode"
                  name="countryCode"
                  className="form-control"
                  autoComplete="off"
                />
                <ErrorMessage name="countryCode" component="div" className="text-danger" />
              </div>
              <DialogActions>
                <Button onClick={onClose} color="secondary">
                  Cancel
                </Button>
                <Button type="submit" className="!bg-medium-aquamarine text-white" disabled={isSubmitting || !isValid || !dirty}>
                  {isLoading ? 'Adding...' : 'Add'}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddCountryDialog;
