import React, { useEffect, useState, useRef } from "react";
import Search from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { makeStyles } from '@mui/styles';
import {
  FormControl,
  InputBase,
  MenuItem,
  Paper,
  List,
  Typography,
  Box,
} from "@mui/material";
import {getTags} from "../services/serverApi.calendar"


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        // fontFamily: "Roboto",
        // fontWeight: "medium",
    },
    formControl: {
      width: "100%",
    },
    select: {
      height: 45,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(0.5, 1),
      border: "1px solid",
      borderRadius: 6,
      borderColor: "#d2d6da",
      backgroundColor: "transparent !important",
      cursor: "pointer",
      // transition: "border-color 0.2s ease-in-out",
      boxShadow: "none !important",
    },
    selectOpen: {
      border: "2px solid",
      borderColor: "#1a73e8",
    },
    dropdown: {
      position: "absolute !important",
      zIndex: 1000,
      top: "calc(100% - -3px)",
      left: 0,
      width: "100% !important",
      borderRadius: theme.shape.borderRadius,
      marginTop: theme.spacing(1),
      boxShadow: theme.shadows[2],
      boxSizing: "border-box",
      backgroundColor: theme.palette.background.paper,
    },
    listItemBox: {
      maxHeight: 200,
      overflowY: "auto",
      overflowX: "hidden",
    },
    listItem: {
      padding: theme.spacing(1, 2),
      marginBottom: "2px !important",
      borderRadius: "0px !important",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "lightblue !important",
        // color: theme.palette.common.white,
      },
    },
    selectedItem:{
      backgroundColor: "#dcdcdc !important",
      color: "black !important"
    },

    inputBox: {
      flex: 1,
      borderBottom: "1px solid",
      padding: "0px 4px",
    },
    input: {
      fontSize: "15px !important",
      fontFamily: "inherit",
      lineHeight: "1.15",
      margin: 0,
    },
    searchIcon: {
      color: "grey",
      fontSize: 18
    },
    tag: {
      backgroundColor: "#dcdcdc",
        borderRadius: "5px",
        padding: "3px 7px",
        fontSize: "12px !important",
    },
    limitTag: {
      fontSize: "12px !important",
  },
    placeholderTag: {
      borderRadius: "5px",
      padding: "0px 5px",
      fontSize: "15px !important",
      color: "#7b809a"
    }
}));

const SelectMulti = ({valueTag,onchageTag}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [limit, setLimit] = useState(1); // Maximum number of selected tags
  const selectRef = useRef(null);

  const tagsItems  = async ()=>{
    try {
      const data = await getTags();
      setTags(data.data);
      setFilteredTags(data.data)
    //   setTotal(data.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } 

  }
  useEffect(()=>{
    tagsItems();
  },[])
  const handleInputChange = (event) => {
    const value = event.target.value.toLowerCase();
    setInputValue(value);
    const filtered = tags.filter((tag) =>
      tag.name.toLowerCase().includes(value)
    );
    setFilteredTags(filtered);
    
  };
  const handleDeleteTag = (event, item) => {
    event.stopPropagation();
    const updatedItems = selected.filter((tag) => tag.name !== item.name);
    setSelected(updatedItems);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box className={classes.root} ref={selectRef}>
      <FormControl className={classes.formControl}>
        <Paper
          className={`${classes.select} ${open ? classes.selectOpen : ""}`}
          onClick={() => setOpen(!open)}
        >
          <Box>
            {selected.length > 0
              ? 
              selected.slice(0, limit).map((item, key) => (
                <Typography key={key} className={classes.tag} onClick={(event) => handleDeleteTag(event, item)}>
                  {item.name} x
                </Typography>
              ))

              :
              <Typography className={classes.placeholderTag}>
                tags
              </Typography>
            }
                
          </Box>
          <Typography className={classes.limitTag} >
            {selected.length > 0 ?
                (selected.length > limit ? ` +${selected.length - limit}` : "")
              : ""}
          </Typography>
          {
            !open 
            ? <ArrowDropDownIcon sx={{color: "#6e7275"}}/>
            :<ArrowDropUpIcon sx={{color: "#6e7275"}}/>
          }
        </Paper>
        {
          open &&
          <List className={classes.dropdown}>
            <Box className={classes.inputBox}>
              <InputBase
                className={classes.input}
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Search..."
                startAdornment={<Search className={classes.searchIcon} />}
              />
            </Box>
            <Box className={classes.listItemBox}>
              {filteredTags.map((tag) => (
                <MenuItem
                  key={tag.name}
                  className={`${classes.listItem} ${
                    selected.find((selectedTag) => selectedTag.name === tag.name) 
                      ? classes.selectedItem
                      : ""
                  }`}
                  onClick={() => {
                    const isSelected = selected.find(
                      (selectedTag) => selectedTag.name === tag.name
                    );
                    const updatedSelected = isSelected
                      ? selected.filter((selectedTag) => selectedTag.name !== tag.name)
                      : [...selected, tag];
                    setSelected(updatedSelected);
                    onchageTag(tag?.name);
                  }}
                >
                  {tag.name}
                </MenuItem>
              ))}
            </Box>
          </List>
        }
      </FormControl>
    </Box>
  );
};

export default SelectMulti;