import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Pagination from './Pagination';
import TableStyle from './TableStyle';
import TotalRevenueTable from 'features/financial/components/rentalActivity/TotalRevenueTable';

const GlobalTable = ({
  data,
  columns,
  page,
  onPageChange,
  isNextDisabled,
  hasPagination,
  limit,
  onLimitChange,
  rowsPerPageOptions,
  totalRevenue,
  totals,
}) => {
  const rowClassName = (rowData) => {
    return {
      'bg-yellow-100': rowData.isUnmapped 
    };
  };

  return (
    <>
      <TableStyle>
        <DataTable 
          value={data} 
          className="p-datatable-sm"
          rowClassName={rowClassName} 
          sortField="isUnmapped" 
          sortOrder={-1} 
        >
          {columns.map((col, index) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              body={col.body}
              headerClassName={col.headerClassName}
              sortable 
              headerStyle={col.headerStyle} 
              className='w-99%'
            />
          ))}
        </DataTable>
        {totalRevenue !== undefined && (
          <TotalRevenueTable 
            columns={columns} 
            totalRevenue={totalRevenue} 
            totals={totals}
          />
        )}
        {hasPagination && (
          <Pagination
            page={page}
            onPageChange={onPageChange}
            isNextDisabled={isNextDisabled}
            limit={limit}
            onLimitChange={onLimitChange}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        )}
      </TableStyle>
    </>
  );
};

export default GlobalTable;
