import React, { useState, useEffect } from 'react';
import { getBedTypes, deleteBedType } from '../services/serverApi.listing';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import ModifyBedType from './ModifyBedType';
import AddBedType from './AddBedType';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress, Typography, Button, Box, Modal} from '@mui/material';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteIcon from '@mui/icons-material/Delete';

function BedType() {
    const [bedTypes, setBedTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openModifyDialog, setOpenModifyDialog] = useState(false);
    const [selectedBedType, setSelectedBedType] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [error, setError] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [bedTypeToDelete, setBedTypeToDelete] = useState(null);

    const fetchBedTypes = async () => {
        setIsLoading(true);
        try {
            const response = await getBedTypes();
            setBedTypes(response.data.data);
        } catch (error) {
            console.error('Error fetching bed types:', error);
            setError('Failed to fetch bed types. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchBedTypes();
    }, []);

    const handleOpenModifyDialog = (index, bedType) => {
        setSelectedBedType(bedType);
        setSelectedIndex(index);
        if (bedType) {
            setOpenModifyDialog(true);
        }
    };

    const handleCloseModifyDialog = () => {
        setOpenModifyDialog(false);
        setSelectedBedType(null);
        setSelectedIndex(null);
    };

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    const handleOpenDeleteDialog = (bedType) => {
        setBedTypeToDelete(bedType);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setBedTypeToDelete(null);
    };

    const handleDeleteBedType = async () => {
        if (bedTypeToDelete) {
            try {
                await deleteBedType(bedTypeToDelete._id);
                setBedTypes(bedTypes.filter(bt => bt._id !== bedTypeToDelete._id));
                toast.success('Bed type deleted successfully');
            } catch (error) {
                console.error('Error deleting bed type:', error);
                toast.error('Failed to delete bed type. Please try again.');
            } finally {
                handleCloseDeleteDialog();
            }
        }
    };

    const columns = [
        { header: "Name", body: (rowData) => <span>{rowData.name}</span> },
        {
            header: "Action",
            body: (rowData, rowIndex) => (
                <div className="flex gap-1">
                    {rowData && !rowData.hostawayId && (
                        <>
                            <button
                                className="px-2 py-1 bg-medium-aquamarine !rounded-md"
                                onClick={() => handleOpenModifyDialog(rowIndex, rowData)}
                            >
                                <EditOffIcon className="text-white" />
                            </button>
                            <button
                                className="px-2 py-1 bg-red-500 !rounded-md"
                                onClick={() => handleOpenDeleteDialog(rowData)}
                            >
                                <DeleteIcon className="text-white" />
                            </button>
                        </>
                    )}
                </div>
            ),
        },
    ];

    if (error) {
        return <div className="w-full h-64 flex justify-center items-center text-red-500">{error}</div>;
    }

    return (
        <div className="card p-4">
            <Typography variant="h4" component="h1" className="mb-4" gutterBottom>
                Bed Types Management
            </Typography>
            <div className="mb-4">
                <Button onClick={handleOpenAddDialog} className="float-right !bg-medium-aquamarine text-white">Add Bed Type</Button>
            </div>
            <div>
                <div className="w-full">
                    {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress style={{ color: '#00b4b4' }} />
                        </div>
                    ) : (
                        <div style={{ height: '500px', overflow: 'auto' }}>
                            <GlobalTable
                                data={bedTypes}
                                columns={columns}
                                hasPagination={false}
                                isLoading={isLoading}
                            />
                        </div>
                    )}
                </div>
            </div>
            <ToastContainer position="top-right" autoClose={3000} />
            {selectedBedType && (
                <ModifyBedType
                    open={openModifyDialog}
                    onClose={handleCloseModifyDialog}
                    setBedTypes={setBedTypes}
                    bedTypes={bedTypes}
                    index={selectedIndex}
                    dataBedType={selectedBedType}
                />
            )}
            <AddBedType
                open={openAddDialog}
                onClose={handleCloseAddDialog}
                setBedTypes={setBedTypes}
                bedTypes={bedTypes}
            />

            <Modal
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
            >
                <Box class="absolute top-36 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[550px] rounded-sm shadow-[24]  bg-[#f8fafb]">
                    <Box className="w-full mb-11 p-4">
                        <Typography className="!text-[14px] !text-center" id="modal-modal-description" >
                        Are you sure you want to delete this bed type? This action cannot be undone.
                        </Typography>
                    </Box>
                    <Box className="flex justify-end p-3 bg-white border-t-1 border-gray-200">

                        <Box className="flex gap-2">
                            <button
                                className="px-3 py-1 bg-[#d6fff7] text-[#70d4c0] !rounded-sm mr-2 !text-[16px]"
                                onClick={handleCloseDeleteDialog}
                            >
                                Cancel
                            </button>
                            <button
                                className="px-3 py-1 bg-red-500 text-white !rounded-sm mr-2 !text-[16px]"
                                onClick={handleDeleteBedType}
                            >
                                Confirm
                            </button>
                        </Box>
                    </Box>
                </Box>

            </Modal>
          
        </div>
    );
}

export default BedType;