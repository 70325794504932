import React, { useState, useEffect } from 'react';
import { getListings, getCalendarDetails } from 'features/reservation/services/serverApi.reservation';
import GuestDetails from './guestInfoDetails/GuestDetails';
import ListingDetails from './guestInfoDetails/ListingDetails';
import ReservationDetails from './guestInfoDetails/ReservationDetails';
import InvoicesAndCharges from './guestInfoDetails/InvoicesAndCharges';

const GuestInfo = ({ reservationDetails, isEditMode, onDetailsUpdate }) => {
    const [listings, setListings] = useState([]);
    const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
    const [editableDetails, setEditableDetails] = useState({
        reservationNumber: '',
        guestName: '',
        guestFirstName: '',
        guestLastName: '',
        guestEmail: '',
        phone: '',
        numberOfGuests: '',
        guestLanguage: '',
        listingName: '',
        sojoriId: '',
        cancellationPolicy: '',
        arrivalDate: '',
        departureDate: '',
        checkInTime: '',
        checkOutTime: '',
        currency: '',
        status: '',
        guestCountry: '',
        guestCity: '',
        reservationDate: '',
        timeLine: '',
        paymentStatus: '',
        roomTypeId: '',

    });

    useEffect(() => {
        const fetchListings = async () => {
            try {
                const fetchedListings = await getListings(staging);
                setListings(fetchedListings);
            } catch (error) {
                console.error("Error fetching listings:", error);
            }
        };
        fetchListings();
    }, [staging]);

    useEffect(() => {
        if (reservationDetails) {
            const selectedListing = listings.find(listing => listing.id === reservationDetails.sojoriId);

            setEditableDetails(prevDetails => {
                const details = {
                    ...prevDetails,
                    ...reservationDetails,
                    listingName: reservationDetails.listing ? reservationDetails.listing.name : '',
                    sojoriId: selectedListing ? selectedListing.id : '',
                    arrivalDate: formattedDate(reservationDetails.arrivalDate),
                    departureDate: formattedDate(reservationDetails.departureDate),
                    reservationDate: formattedDate(reservationDetails.reservationDate),
                    checkInTime: reservationDetails.checkInTime ? reservationDetails.checkInTime : selectedListing ? selectedListing.checkInTime : '',
                    checkOutTime: reservationDetails.checkOutTime ? reservationDetails.checkOutTime : selectedListing ? selectedListing.checkOutTime : '',
                    
                };

                if (reservationDetails.atSojori) {
                    details.createdAt = formattedDate(reservationDetails.createdAt);
                } else {
                    details.reservationDate = formattedDate(reservationDetails.reservationDate);
                }

                return details;
            });
        }
    }, [reservationDetails, listings]);

    useEffect(() => {
        if (isEditMode) {
            onDetailsUpdate(editableDetails);
        }
    }, [editableDetails, isEditMode, onDetailsUpdate]);

    const formattedDate = (date) => {
        if (!date) return '';
        const formattedDate = new Date(date);
        if (isNaN(formattedDate.getTime())) return '';
        return formattedDate.toISOString().split('T')[0];
    };

    const handleInputChange = async (event) => {
        const { name, value } = event.target;
    
        if (name === 'listing-select') {
            const selectedListing = listings.find(listing => listing.id.toString() === value);
    
            if (selectedListing) {
                setEditableDetails(prevDetails => ({
                    ...prevDetails,
                    sojoriId: selectedListing.id,
                    listingName: selectedListing.name,
                    checkInTime: selectedListing.checkInTime,
                    checkOutTime: selectedListing.checkOutTime,
                    // roomTypeId: '', 

                }));
    
                await fetchCalendarAndSetPrice(selectedListing);
            }
        } else {
            setEditableDetails(prevDetails => ({
                ...prevDetails,
                [name]: value,
            }));
        }
    };
    

    const fetchCalendarAndSetPrice = async (selectedListing) => {
        if (!selectedListing) return;
    
        try {
            const arrivalDate = formattedDate(editableDetails.arrivalDate);
            const departureDate = formattedDate(editableDetails.departureDate);
            const calendarData = await getCalendarDetails(selectedListing.id, arrivalDate, departureDate);
            const totalPrice = calendarData[0]?.price || 0;
    
            setEditableDetails(prevDetails => ({
                ...prevDetails,
                totalPrice,
            }));
        } catch (error) {
            console.error('Error fetching calendar details:', error);
        }
    };
    
    return (
        <div className="transition-all duration-100 ease-in-out !my-8">
            <GuestDetails
                editableDetails={editableDetails}
                isEditMode={isEditMode}
                handleInputChange={handleInputChange}
            />
            <InvoicesAndCharges reservationDetails={reservationDetails} />
            <ListingDetails
                listings={listings}
                editableDetails={editableDetails}
                isEditMode={isEditMode}
                handleInputChange={handleInputChange}
            />
            <ReservationDetails
                editableDetails={editableDetails}
                isEditMode={isEditMode}
                handleInputChange={handleInputChange}
                listings={listings}
            />
        </div>
    );
};

export default GuestInfo;
