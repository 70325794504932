import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, Typography, TextField, Box, Avatar } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { getChatInbox } from './services/serverApi.chatConfig';
import BedIcon from '@mui/icons-material/Bed';

function ConversationList({ onSelectConversation }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isAtEnd, setIsAtEnd] = useState(false);



  useEffect(() => {

    fetchConversations();
  }, []);

  const fetchConversations = async () => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const response = await getChatInbox(page, 1000);
      const newConversations = response.data.data;
      if (newConversations.length === 0) {
        setHasMore(false);
        setIsAtEnd(true);
      } else {
        const groupedConversations = newConversations.reduce((acc, msg) => {
          if (!acc[msg.session_id]) acc[msg.session_id] = [];
          acc[msg.session_id].push({ ...msg, avatarUrl: 'path/to/avatar.png' });
          return acc;
        }, {});
        const conversationsWithDate = Object.entries(groupedConversations).map(([id, msgs]) => {
          const latestDate = new Date(Math.max(...msgs.map(m => new Date(m.date))));
          return [id, msgs, latestDate];
        });
        setConversations(prevConversations => [...prevConversations, ...conversationsWithDate]);
        setPage(prevPage => prevPage + 1);
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e) => setSearchQuery(e.target.value);

  const handleSelectConversation = (sessionId, messages) => {
    setSelectedSessionId(sessionId);
    onSelectConversation(messages);
  };

  const filteredConversations = conversations.filter(([id]) =>
    id.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const formatDate = (date) => {
    return date && !isNaN(date.getTime())
      ? new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(date)
      : 'Invalid date';
  };


  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && !loading && hasMore) {
      fetchConversations();
    }
    if (scrollHeight - scrollTop === clientHeight) {
      setIsAtEnd(true);
    } else {
      setIsAtEnd(false);
    }
  };

  return (
    <Box className="">
      <Typography variant="h6" className="p-4" style={{ borderBottom: '1px solid #ccc' }}>Conversations</Typography>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search conversations..."
        value={searchQuery}
        onChange={handleSearchChange}
        className="p-4"
      />
      <List
        className="overflow-y-auto scrollbar-thin scrollbar-thumb-medium-aquamarine scrollbar-track-gray-100"
        style={{ height: 'calc(100vh - 240px)' }}
        onScroll={handleScroll}
      >
        {filteredConversations.map(([sessionId, messages, latestDate]) => (
          <ListItem button key={sessionId} onClick={() => handleSelectConversation(sessionId, messages)}
            className={`flex gap-2 px-4 py-2 hover:bg-gray-100 ${selectedSessionId === sessionId ? '!bg-light-aquamarine' : ''}`}>


            <ListItemText
              primary={
                <div className="flex items-center gap-2 justify-between">
                  <div className="flex items-center gap-2">
                    <Avatar src={messages[0].avatarUrl} alt="Avatar" className="mr-2" />
                    <Typography variant="h6" color="textPrimary">
                      {/* {`Conv ${sessionId.slice(0, 8)}...`} */}
                      {`${sessionId}`}

                    </Typography>
                  </div>
                  <CheckCircleOutlineIcon fontSize="medium" className="text-medium-aquamarine" />
                </div>
              }
              secondary={
                <Box >
                  {/* <Typography variant="body2" color="textSecondary">
                    {`${messages.length} messages`}
                  </Typography> */}
                  <div className="flex justify-between mt-2 items-center">
                    <Typography color="textSecondary" className="text-gray-300 !text-xs">
                      {formatDate(latestDate)}
                    </Typography>
                    <Typography color="textSecondary" className="bg-gray-100 px-1 py-1 rounded-sm !text-xs">
                      <BedIcon fontSize="small" className="mr-1 text-medium-aquamarine" />
                      Current
                    </Typography>
                  </div>

                </Box>
              }
            />

          </ListItem>
        ))}
        {loading && !isAtEnd && <ListItem><Typography>Loading...</Typography></ListItem>}
        {isAtEnd && !hasMore && <ListItem><Typography>No more conversations</Typography></ListItem>}
      </List>
    </Box>
  );
}

export default ConversationList;