import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';

const staging = JSON.parse(localStorage.getItem('isStaging')) || false;

export function getListings(params = {}) {
    const { page = 0, limit = 0, name = '', city = '', country = '', sortingBy = '', staging = false } = params;
    // const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
    const queryParams = new URLSearchParams({
        page,
        limit,
        name,
        city,
        country,
        sortingBy,
        staging
    }).toString();

    return axios.get(`${MICROSERVICE_BASE_URL.LISTING}/?${queryParams}`)
        .then(response => {
            if (!response.data.success) {
                throw new Error(response.data.message);
            }
            return response
        })
        .catch(error => {
            throw error;
        });
}


export function getTags() {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/tag/?page=0&limit=20&paged=false&search_text`);

}

export function getLanguage() {
    return axios.get(`${MICROSERVICE_BASE_URL.LANGUAGE}?page=0&limit=20&paged=false&search_text`);

}

export function getCountries() {
    return axios.get(`${MICROSERVICE_BASE_URL.COUNTRY}?page=0&limit=10&search_text&paged=false`);
}

export function getCities() {
    return axios.get(`${MICROSERVICE_BASE_URL.CITY}?page=0&limit=10&search_text&paged=false`);
}

export function getAmenities() {
    return axios.get(`${MICROSERVICE_BASE_URL.AMENITIES}`);
}

export function updateAmenity(id, data) {
    return axios.put(`${MICROSERVICE_BASE_URL.AMENITIES}/${id}`, data);
}
export function createAmenity(data) {
    return axios.post(`${MICROSERVICE_BASE_URL.SRV_LISTING}/amenities`, data);
}

export function deleteAmenity(id) {
    return axios.delete(`${MICROSERVICE_BASE_URL.SRV_LISTING}/amenities/${id}`);
}

export function getAmenitiesMapping() {
    return axios.get(`${MICROSERVICE_BASE_URL.AMENITIESMAPPING}`);
}

export function updateAmenitiesMapping(data) {
    return axios.put(`${MICROSERVICE_BASE_URL.AMENITIESMAPPING}`, data);
}

export const addAmenityMapping = (amenityIds) => {
    const data = { amenitiesMapping: amenityIds };
    return axios.put(`${MICROSERVICE_BASE_URL.AMENITIESMAPPING}`, data);
};
export const getBedTypes = () => {
    return axios.get(`${MICROSERVICE_BASE_URL.BEDTYPE}`);
}
export const addBedType = (data) => {
    return axios.post(`${MICROSERVICE_BASE_URL.BEDTYPE}/create`, data);
}
export const updateBedType = (id, data) => {
    return axios.put(`${MICROSERVICE_BASE_URL.BEDTYPE}/update/${id}`, data);
}
export const deleteBedType = (id) => {
    return axios.delete(`${MICROSERVICE_BASE_URL.BEDTYPE}/delete/${id}`);
}

export const getPropertyTypes = () => {
    return axios.get(`${MICROSERVICE_BASE_URL.PROPERTYTYPES}`);
}
export const addPropertyType = (data) => {
    return axios.post(`${MICROSERVICE_BASE_URL.PROPERTYTYPES}/create`, data);
}
export const updatePropertyType = (id, data) => {
    return axios.put(`${MICROSERVICE_BASE_URL.PROPERTYTYPES}/update/${id}`, data);
}

export const createListing = async (formData) => {
    try {
        const response = await axios.post(`${MICROSERVICE_BASE_URL.LISTING}/create-property`, formData);
        console.log(response);
        return response.data;
    } catch (error) {
        console.error('Error creating listing:', error);
        throw error;
    }
};

export const UpdateListing = async (listingId, listingData) => {
    try {
        const response = await axios.put(`${MICROSERVICE_BASE_URL.LISTING}/update-property/${listingId}`, listingData);
        console.log(response)
        return response.data;
    } catch (error) {
        console.error('Error updating listing:', error);
        throw error;
    }

}

export const UpdateNameListing = async (listingId, listingData) => {
    try {
        const response = await axios.put(`${MICROSERVICE_BASE_URL.SRV_LISTING}/listings/update-name/${listingId}`, listingData);
        console.log(response)
        return response.data;
    } catch (error) {
        console.error('Error updating listing:', error);
        throw error;
    }

}

export const getOneListing = async (listingId, staging = false) => {
    try {
        const response = await axios.get(`${MICROSERVICE_BASE_URL.LISTING}/by-id/${listingId}?staging=${staging}`);
        return response.data.listing;
    } catch (error) {
        console.error('Error getting listing:', error);
        throw error;
    }

}

export function getChannelManager() {
    return axios.get(`${MICROSERVICE_BASE_URL.CHANNELMANAGER}?page=0&limit=30&paged=false`);
}

export function getStandardSojori() {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/standard-sojori`);
}

export function createStandardSojori(data) {
    return axios.post(`${MICROSERVICE_BASE_URL.SRV_LISTING}/standard-sojori`, data);
}

export function updateStandardSojori(id, data) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_LISTING}/standard-sojori/${id}`, data);
}

export function deleteStandardSojori(id) {
    return axios.delete(`${MICROSERVICE_BASE_URL.SRV_LISTING}/standard-sojori/${id}`);
}

export function deleteListing(id) {
    return axios.delete(`${MICROSERVICE_BASE_URL.LISTING}/delete-property/${id}`);
}
// http://localhost:4002/api/v1/reservations/minut/get-minut-listings

export function getMinutHomes() {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/minut/get-minut-listings`);
}


//----------------------RoomTypeConfig-------------------------

export function getRoomTypes() {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/room-type-config/get`);
}

export function updateRoomTypes(id, data) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_LISTING}/room-type-config/update/${id}`, data);
}
export function createRoomTypes(data) {
    return axios.post(`${MICROSERVICE_BASE_URL.SRV_LISTING}/room-type-config/create`, data);
}

export function deleteRoomTypes(id) {
    return axios.delete(`${MICROSERVICE_BASE_URL.SRV_LISTING}/room-type-config/delete/${id}`);
}



// export function getProjects() {
//     return axios.get(`http://localhost:4000/api/v1/listing/project/get-project-and-units?page=0&limit=20&paged=true`);
// }

//---------------------------Channex Mapping------------------------
export function getChannexMapping(id) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/listings/channex-mapping/${id}`);
}


export function syncAvailabilityChannex(data) {
    return axios.post(`${MICROSERVICE_BASE_URL.SRV_CALENDAR}/channex/sync-availability`,data);
}



//---------------------------Listing Mapping------------------------
export function getListingMapping(staging) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_CALENDAR}/listing/get-listing-mapping?staging=${staging}`);
}

export function updateListingMapping(data) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_CALENDAR}/listing/update-listing-mapping`, data);
}