import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    unitName: Yup.string().required('Unit name is required'),
    propertyType: Yup.string().required('Property type is required'),
    condition: Yup.string().required('Condition is required'),
    totalArea: Yup.number().required('Total area is required').min(0),
    livingArea: Yup.number().required('Living area is required').min(0),
    city: Yup.string().required('City is required'),
    district: Yup.string().required('District is required'),
    address: Yup.string().required('Address is required'),
    // projectId: Yup.string(),
    gps: Yup.object().shape({
        latitude: Yup.number().required('Latitude is required'),
        longitude: Yup.number().required('Longitude is required')
    }),
    numberOfRooms: Yup.number().required('Number of rooms is required').min(0),
    numberOfBedrooms: Yup.number().required('Number of bedrooms is required').min(0),
    numberOfBathrooms: Yup.number().required('Number of bathrooms is required').min(0),
    salePrice: Yup.number().required('Sale price is required').min(0),
    pricePerSqm: Yup.number().required('Price per square meter is required').min(0),
    gatedCommunity: Yup.boolean(),
    security: Yup.boolean(),
    swimmingPool: Yup.string(),
    numberOfFloors: Yup.number().min(0),
    floor: Yup.number().min(0),
    elevator: Yup.boolean(),
    balcony: Yup.boolean(),
    balconyArea: Yup.number().min(0),
    landArea: Yup.number().min(0),
    garage: Yup.boolean(),
    parkingSpaces: Yup.number().min(0),
    garden: Yup.number().min(0),
    images: Yup.array().of(
        Yup.object().shape({
            url: Yup.string().required('Image URL is required'),
            sortOrder: Yup.number().required('Sort order is required')
        })
    )
});

export default validationSchema;