import React, { useEffect, useState, useRef } from "react";
import Search from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { makeStyles } from '@mui/styles';
import {
  FormControl,
  InputBase,
  MenuItem,
  Paper,
  List,
  Typography,
  Box,
} from "@mui/material";
import {geListingIds} from "../services/serverApi.calendar";
import {useDispatch} from 'react-redux';
import {setListingId,setListingIdBtn} from '../../../redux/slices/DateSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    formControl: {
      width: "100%",
    },
    select: {
      height: 45,
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(0.5, 1),
      border: "1px solid",
      borderRadius: 6,
      borderColor: "#d2d6da",
      backgroundColor: "transparent !important",
      cursor: "pointer",
      // transition: "border-color 0.2s ease-in-out",
      boxShadow: "none !important",
    },
    selectOpen: {
      border: "2px solid",
      borderColor: "#1a73e8",
    },
    dropdown: {
      position: "absolute !important",
      zIndex: 1000,
      top: "calc(100% - -3px)",
      left: 0,
      width: "100% !important",
      borderRadius: theme.shape.borderRadius,
      marginTop: theme.spacing(1),
      boxShadow: theme.shadows[2],
      boxSizing: "border-box",
      backgroundColor: theme.palette.background.paper,
    },
    listItemBox: {
      maxHeight: 200,
      overflowY: "auto",
      overflowX: "hidden",
    },
    listItem: {
      padding: theme.spacing(1, 2),
      fontSize: "12px !important",
      marginBottom: "2px !important",
      borderRadius: "0px !important",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "lightblue !important",
        // color: theme.palette.common.white,
      },
    },
    selectedItem:{
      backgroundColor: "#dcdcdc !important",
      color: "black !important"
    },

    inputBox: {
      flex: 1,
      borderBottom: "1px solid",
      padding: "0px 4px",
    },
    input: {
      fontSize: "17px !important",
      fontFamily: "inherit",
      lineHeight: "1.15",
      margin: 0,
    },
    searchIcon: {
      color: "grey",
      fontSize: 18
    },
    listing: {
        borderRadius: "5px",
        padding: "3px 7px",
        fontSize: "12px !important",
        width: 'max-content',
    },
}));

const SelectSearchBar = () => {

  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [selected, setSelected] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const [selectedlistingId, setSelectedlistingId] = useState(null);
  const [open, setOpen] = useState(false);
  const [filteredListing, setFilteredListing] = useState([]);
  const selectRef = useRef(null);
  const [loading, setLoading] = useState(false)
  const staging = JSON.parse(localStorage.getItem('isStaging')) || false;
  

  const dispatch = useDispatch();

  const ListingItems  = async ()=>{
    try {
      const resulat = await geListingIds(staging);
      setFilteredListing(resulat.data.data);
      setSelected(resulat.data.data[0])
      setSelectedId(resulat.data.data[0]?._id)
      setSelectedlistingId(resulat.data.data[0]?.hostawayId)

    } catch (error) {
      console.error("Error fetching data:", error);
    }

  }
  useEffect(()=>{
    ListingItems();
  },[staging])
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if(selectedId){
        dispatch(setListingId(selectedId));
        dispatch(setListingIdBtn(selectedlistingId));

    }
  }, [selectedId]);
  
  const handleInputChange = (event) => {
    const value = event.target.value.toLowerCase();
    setInputValue(value);
    const filtered = filteredListing.filter((listing) =>
      listing.name.toLowerCase().includes(value)
    );
    setFilteredListing(filtered);
  };

  return (
    <Box className={classes.root} ref={selectRef}>
      <FormControl className={classes.formControl}>
        <Paper
          className={`${classes.select} ${open ? classes.selectOpen : ""}`}
          onClick={() => setOpen(!open)}
        >
          <Box className="w-56 overflow-hidden">
            {
              <Typography className={classes.listing} >
                {selected?.name} ({selected?.hostawayId ? selected.hostawayId : selected?._id?.slice(0, 6)})

              </Typography>
            }
                
          </Box>
          {
            !open 
            ? <ArrowDropDownIcon sx={{color: "#6e7275"}}/>
            :<ArrowDropUpIcon sx={{color: "#6e7275"}}/>
          }
        </Paper>
        {
          open &&
          <List className={classes.dropdown}>
             <Box className={classes.inputBox}>
                <InputBase
                  className={classes.input}
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder="Search..."
                  startAdornment={<Search className={classes.searchIcon} />}
                />
              </Box>
            <Box className={classes.listItemBox}>
              {filteredListing?.map((listing, key) => (
                <MenuItem
                  key={key}
                  className={`${classes.listItem} ${
                    selected?.name === listing?.name
                      ? classes.selectedItem
                      : ""
                  }`}
                  onClick={() => {
                    setSelected(listing)
                    setSelectedId(listing?._id)
                    setSelectedlistingId(listing?.hostawayId)

                    
                }}
                >
                 <span>{listing.name}({listing.hostawayId})</span> 
                </MenuItem>
              ))}
            </Box>
          </List>
        }
      </FormControl>
    </Box>
  );
};

export default SelectSearchBar;