import React from 'react';
import { Field } from 'formik';
import { TextField, FormControl, InputLabel, Select, MenuItem, Box, Checkbox, FormControlLabel } from '@mui/material';

const PROPERTY_TYPES = ['Villa', 'Appartement', 'Studio'];
const CONDITIONS = ['Neuf', 'Bon état', 'À rénover'];
const STATUS = ['Pre-Launch', 'Under-Construction', 'Recently-Delivered', 'Ready-To-Invest', 'Needs-Work'];

export const BasicInformation = ({ touched, errors, projects, isLoading }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} className="!mb-4">
        <Field name="unitName">
            {({ field }) => (
                <TextField
                    {...field}
                    label="Unit Name"
                    fullWidth
                    error={touched.unitName && Boolean(errors.unitName)}
                    helperText={touched.unitName && errors.unitName}
                />
            )}
        </Field>

        <Box sx={{ display: 'flex', gap: 2 }}>
            <Field name="propertyType">
                {({ field }) => (
                    <FormControl fullWidth error={touched.propertyType && Boolean(errors.propertyType)}>
                        <InputLabel id="property-type-label">Property Type</InputLabel>
                        <Select {...field} labelId="property-type-label" label="Property Type">
                            {PROPERTY_TYPES.map(type => (
                                <MenuItem key={type} value={type}>{type}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Field>

            <Field name="condition">
                {({ field }) => (
                    <FormControl fullWidth error={touched.condition && Boolean(errors.condition)}>
                        <InputLabel id="condition-label">Condition</InputLabel>
                        <Select {...field} labelId="condition-label" label="Condition">
                            {CONDITIONS.map(condition => (
                                <MenuItem key={condition} value={condition}>{condition}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Field>
        </Box>

        <Field name="description">
            {({ field }) => (
                <TextField
                    {...field}
                    label="Description"
                    fullWidth
                    multiline
                    rows={2}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                />
            )}
        </Field>

        <Box sx={{ display: 'flex', gap: 2 }}>
            <Field name="projectId">
                {({ field, form }) => (
                    <FormControl fullWidth error={touched.projectId && Boolean(errors.projectId)}>
                        <InputLabel id="project-label">Project</InputLabel>
                        <Select
                            {...field}
                            labelId="project-label"
                            label="Project"
                            disabled={isLoading}
                            onChange={(e) => {
                                const value = e.target.value;
                                form.setFieldValue('projectId', value === '' ? null : value);
                                form.setFieldValue('displayAsUnit', false);
                                form.setFieldValue('displayAsProject', false);
                            }}
                        >
                            <MenuItem value="">None</MenuItem>
                            {projects.map(project => (
                                <MenuItem key={project._id} value={project._id}>
                                    {project.projectName} ({project.unitType})
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Field>

            <Field name="status">
                {({ field }) => (
                    <FormControl fullWidth error={touched.status && Boolean(errors.status)}>
                        <InputLabel id="status-label">Status</InputLabel>
                        <Select {...field} labelId="status-label" label="Status">
                            {STATUS.map(status => (
                                <MenuItem key={status} value={status}>{status}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Field>
        </Box>

        <Field name="projectId">
            {({ field, form }) =>
                field.value && (
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Field name="displayAsUnit">
                            {({ field: displayField }) => (
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...displayField}
                                                checked={displayField?.value}
                                                color="primary"
                                            />
                                        }
                                        label="Display as Unit"
                                    />
                                </FormControl>
                            )}
                        </Field>

                        <Field name="displayAsProject">
                            {({ field: displayField }) => (
                                <FormControl fullWidth>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                {...displayField}
                                                checked={displayField?.value}
                                                color="primary"
                                            />
                                        }
                                        label="Display as Project"
                                    />
                                </FormControl>
                            )}
                        </Field>
                    </Box>
                )
            }
        </Field>

        <Box sx={{ display: 'flex', gap: 2 }}>
            <Field name="totalArea">
                {({ field }) => (
                    <TextField
                        {...field}
                        label="Total Area (m²)"
                        type="number"
                        fullWidth
                        error={touched.totalArea && Boolean(errors.totalArea)}
                        helperText={touched.totalArea && errors.totalArea}
                    />
                )}
            </Field>

            <Field name="livingArea">
                {({ field }) => (
                    <TextField
                        {...field}
                        label="Living Area (m²)"
                        type="number"
                        fullWidth
                        error={touched.livingArea && Boolean(errors.livingArea)}
                        helperText={touched.livingArea && errors.livingArea}
                    />
                )}
            </Field>
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
            <Field name="numberOfRooms">
                {({ field }) => (
                    <TextField
                        {...field}
                        label="Number of Rooms"
                        type="number"
                        fullWidth
                        error={touched.numberOfRooms && Boolean(errors.numberOfRooms)}
                        helperText={touched.numberOfRooms && errors.numberOfRooms}
                    />
                )}
            </Field>

            <Field name="numberOfBedrooms">
                {({ field }) => (
                    <TextField
                        {...field}
                        label="Number of Bedrooms"
                        type="number"
                        fullWidth
                        error={touched.numberOfBedrooms && Boolean(errors.numberOfBedrooms)}
                        helperText={touched.numberOfBedrooms && errors.numberOfBedrooms}
                    />
                )}
            </Field>

            <Field name="numberOfBathrooms">
                {({ field }) => (
                    <TextField
                        {...field}
                        label="Number of Bathrooms"
                        type="number"
                        fullWidth
                        error={touched.numberOfBathrooms && Boolean(errors.numberOfBathrooms)}
                        helperText={touched.numberOfBathrooms && errors.numberOfBathrooms}
                    />
                )}
            </Field>
        </Box>
    </Box>
);

export default BasicInformation;