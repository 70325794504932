import React from 'react';
import { Button, IconButton, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Add, Remove, ExpandMore } from '@mui/icons-material';

const Message = ({ formik }) => {
  const { values, setFieldValue } = formik;

  const handleAddItem = (arrayName) => {
    setFieldValue(arrayName, [...values[arrayName], '']);
  };

  const handleRemoveItem = (arrayName, index) => {
    setFieldValue(arrayName, values[arrayName].filter((_, i) => i !== index));
  };

  const handleChangeItem = (arrayName, index, value) => {
    const newArray = [...values[arrayName]];
    newArray[index] = value;
    setFieldValue(arrayName, newArray);
  };

  const renderMessageArray = (arrayName, title) => (
    <Accordion className="mb-4">
      <AccordionSummary
        expandIcon={<ExpandMore className="!text-white" />}
        className="!bg-[#6fd1bd] text-white"
      >
        <div className="flex gap-2 items-center">
          <span className="text-sm ml-2 bg-white text-[#6fd1bd] px-2 py-1 rounded-full">
            {values[arrayName].length}
          </span>
          <span className="text-md font-semibold">{title}</span>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <Button
            variant="outlined"
            color="success"
            startIcon={<Add />}
            onClick={() => handleAddItem(arrayName)}
            className="!border-medium-aquamarine !text-medium-aquamarine mb-4 mt-2"
          >
          </Button>
          {values[arrayName].map((item, index) => (
            <div key={index} className="flex items-center mb-2">
              <textarea
                value={item}
                onChange={(e) => handleChangeItem(arrayName, index, e.target.value)}
                placeholder={`Message ${index + 1}`}
                className="w-full p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none overflow-hidden !text-sm"
                rows={1}
                onInput={(e) => {
                  e.target.style.height = 'auto';
                  e.target.style.height = `${e.target.scrollHeight}px`;
                }}
              />
              <IconButton onClick={() => handleRemoveItem(arrayName, index)} color="error">
                <Remove />
              </IconButton>
            </div>
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <div className="w-full p-4">
      <div className="flex justify-between mb-4">
        <span className="text-md font-semibold">Messages</span>
      </div>
      {renderMessageArray('messageCheckin', 'Check-in Messages')}
      {renderMessageArray('messageCheckout', 'Check-out Messages')}
    </div>
  );
};

export default Message;