import { Copy } from 'lucide-react';
import { IconButton, Tooltip } from '@mui/material';




export  const ReservationNumber = ({ reservationNumber, onCopy }) => {
    return (
        <div className="relative group">
            <span className="text-sm text-gray-700 cursor-pointer group-hover:text-blue-600 transition-colors duration-200">
                {reservationNumber}
            </span>
            <div className="absolute -top-4 left-1/2 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                <Tooltip title="Copy Reservation Number" arrow placement="right">
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            onCopy(reservationNumber);
                        }}
                        size="small"
                        className="bg-white shadow-md hover:bg-gray-100"
                    >
                        <Copy size={16} className="text-gray-600" />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
};
