import React, { useState, useEffect } from 'react';
import { getblogs, removeblog, updateblog, getLanguages } from '../services/serverApi.adminConfig';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import AddBlogDialog from './AddBlog.component';
import ModifyBlogDialog from './ModifyBlog.component';
import EditOffIcon from '@mui/icons-material/EditOff';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { Typography, CircularProgress, Button, Switch, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BlogsTable() {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openModifyDialog, setOpenModifyDialog] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [selectedBlogIndex, setSelectedBlogIndex] = useState(null);
  const [error, setError] = useState(null);
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    fetchBlogs();
    fetchLanguages();
  }, []);

  const fetchLanguages = async () => {
    try {
      const response = await getLanguages();
      setLanguages(response);
    } catch (error) {
      console.error('Error fetching languages:', error);
      toast.error('Failed to fetch languages');
    }
  };

  const fetchBlogs = async () => {
    setIsLoading(true);
    try {
      const response = await getblogs();
      setBlogs(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching blogs:', error);
      // setError('Failed to fetch blogs. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenAddDialog = () => setOpenAddDialog(true);
  const handleCloseAddDialog = () => setOpenAddDialog(false);

  const handleOpenModifyDialog = (blog, index) => {
    setSelectedBlog(blog);
    setSelectedBlogIndex(index);
    setOpenModifyDialog(true);
  };
  
  const handleCloseModifyDialog = () => {
    setSelectedBlog(null);
    setSelectedBlogIndex(null);
    setOpenModifyDialog(false);
  };

  const handleDeleteBlog = async (blogId) => {
    if (window.confirm('Are you sure you want to delete this blog?')) {
      try {
        await removeblog(blogId);
        setBlogs(blogs.filter(blog => blog._id !== blogId));
        toast.success('Blog deleted successfully');
      } catch (error) {
        console.error('Error deleting blog:', error);
        toast.error('Failed to delete blog');
      }
    }
  };

  const addBlog = (newBlog) => {
    setBlogs([...blogs, newBlog]);
    handleCloseAddDialog();
  };

  const handleUpdateBlog = (updatedBlog) => {
    const updatedBlogs = [...blogs];
    updatedBlogs[selectedBlogIndex] = updatedBlog;
    setBlogs(updatedBlogs);
  };

  const handleSwitchToggle = async (blog, index) => {
    const updatedBlog = { ...blog, displayed: !blog.displayed };
    try {
      await updateblog(blog._id, updatedBlog);
      const updatedBlogs = [...blogs];
      updatedBlogs[index] = updatedBlog;
      setBlogs(updatedBlogs);
      toast.success("Display status updated");
    } catch (error) {
      console.error('Error updating blog:', error);
      toast.error("Failed to update display status");
    }
  };

  const renderMultiLanguageContent = (content) => {
    if (typeof content !== 'object') {
      return <span>{content}</span>;
    }
    return (
      <div>
        {Object.entries(content).map(([langId, text]) => {
          const language = languages.find(lang => lang._id === langId);
          return (
            <Tooltip key={langId} title={text} arrow placement="top">
              <div>
                <strong>{language ? language.name : 'Unknown'}:</strong>{' '}
                {text.length > 50 ? `${text.substring(0, 50)}...` : text}
              </div>
            </Tooltip>
          );
        })}
      </div>
    );
  };

  const columns = [
    { 
      header: "Image", 
      body: (rowData) => <img src={rowData.imageUrl} alt="Blog" style={{ width: '100px', height: 'auto' }} />
    },
    { 
      header: "City", 
      body: (rowData) => <span>{rowData.cityName}</span> 
    },
    { 
      header: "Zone", 
      body: (rowData) => <span>{rowData.ZoneName}</span> 
    },
    { 
      header: "Title", 
      body: (rowData) => renderMultiLanguageContent(rowData.title)
    },
    { 
      header: "Description", 
      body: (rowData) => renderMultiLanguageContent(rowData.description)
    },
    {
      header: "Displayed",
      body: (rowData, { rowIndex }) => (
        <Switch 
          checked={rowData.displayed}
          onChange={() => handleSwitchToggle(rowData, rowIndex)}
        />
      )
    },
    {
      header: "Action",
      body: (rowData, { rowIndex }) => (
        <div className="flex gap-1">
          <button className="px-2 py-1 bg-medium-aquamarine !rounded-md" onClick={() => handleOpenModifyDialog(rowData, rowIndex)}>
            <EditOffIcon className="text-white" />
          </button>
          <button className="px-2 py-1 bg-[#df5454] !rounded-md" onClick={() => handleDeleteBlog(rowData._id)}>
            <DeleteSweepIcon className="text-white" />
          </button>
        </div>
      ),
    },
  ];

  if (error) {
    return <div className="w-full h-64 flex justify-center items-center text-red-500">{error}</div>;
  }

  return (
    <div className="card p-4">
      <Typography variant="h4" component="h1" className="mb-4" gutterBottom>
        Blogs Management
      </Typography>
      <div className="mb-4">
        <Button
          startIcon={<AddIcon />}
          onClick={handleOpenAddDialog}
          className="float-right !bg-medium-aquamarine text-white"
        >
          Add Blog
        </Button>
      </div>
      <div>
        <div className="w-full">
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <CircularProgress style={{ color: '#00b4b4' }} />
            </div>
          ) : (
            <GlobalTable data={blogs} columns={columns} hasPagination={false} />
          )}
        </div>
      </div>
      <AddBlogDialog open={openAddDialog} onClose={handleCloseAddDialog} addBlog={addBlog} />
      <ModifyBlogDialog
        open={openModifyDialog}
        onClose={handleCloseModifyDialog}
        onUpdateBlog={handleUpdateBlog}
        dataBlog={selectedBlog}
      />
      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
}

export default BlogsTable;