import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import moment from 'moment';
import { useCalendar } from '../hooks/useCalendar';
import MultiTableCalendar from './MultiTableCalendar';
import { CalendarHeader } from '../../multiCalendarV2/CalendarHeader';
import { getMultiCalendById } from '../../../services/serverApi.calendar';
import { ToastContainer } from 'react-toastify';
import { LRUCache } from 'lru-cache';
import { isRangeCached, mergeRanges } from '../Helper/range';
import debounce from 'lodash/debounce';

const cache = new LRUCache({
  max: 500,
  ttl: 1000 * 60 * 15
});

const FETCH_WINDOW = 30;

export const MultiCalendar = React.memo(({ listings, selectedItems, isLoading: initialIsLoading, listingOnId, staging, checkInDate, checkOutDate }) => {
  console.log("--------listings--------------------- : ", listings);
  const [allCalendarData, setAllCalendarData] = useState({});
  const [isLoading, setIsLoading] = useState(initialIsLoading);
  const [loadingDateRanges, setLoadingDateRanges] = useState([]);
  const [fetchedRanges, setFetchedRanges] = useState([]);
  const [currentlyFetchingRanges, setCurrentlyFetchingRanges] = useState([]);
  const scrollerRef = useRef(null);
  const {
    currentDate,
    goToNextPeriod,
    goToPreviousPeriod,
    daysInView,
    setViewType,
    viewType,
    goToToday,
    allDays,
    setCurrentDate,
  } = useCalendar(checkInDate ? moment(checkInDate).toDate() : new Date());

  const startDate = useMemo(() => moment(daysInView[0]).format('YYYY-MM-DD'), [daysInView]);
  const endDate = useMemo(() => moment(daysInView[daysInView.length - 1]).format('YYYY-MM-DD'), [daysInView]);
  const formattedDateRange = useMemo(() =>
    `${moment(startDate).format('ddd D MMM')} – ${moment(endDate).format('ddd D MMM, YYYY')}`,
    [startDate, endDate]
  );

  useEffect(() => {
    cache.clear();
    setFetchedRanges([]);
    setAllCalendarData({});
  }, [listings, staging]);

  const fetchCalendarData = useCallback(async (fetchStartDate, fetchEndDate) => {
    if (!listings || listings.length === 0) return;

    const fetchWindow = {
      start: fetchStartDate.format('YYYY-MM-DD'),
      end: fetchEndDate.format('YYYY-MM-DD')
    };

    if (currentlyFetchingRanges.some(range =>
      moment(fetchWindow.start).isSameOrAfter(range.start) &&
      moment(fetchWindow.end).isSameOrBefore(range.end)
    )) {
      console.log('This range is already being fetched:', fetchWindow);
      return;
    }

    const rangesToFetch = findNonOverlappingRanges(fetchWindow, [...fetchedRanges, ...currentlyFetchingRanges]);

    if (rangesToFetch.length === 0) {
      console.log('All data already cached or being fetched for range:', fetchWindow);
      return;
    }

    setIsLoading(true);
    setLoadingDateRanges(prev => [...prev, ...rangesToFetch]);
    setCurrentlyFetchingRanges(prev => [...prev, ...rangesToFetch]);

    try {
      const listingIds = listingOnId ? [listingOnId] : listings.map(listing => listing.id);

      const fetchResults = await Promise.all(
        rangesToFetch.map(async range => {
          const calendarResponses = await Promise.all(
            listingIds.map(listingId =>
              getMultiCalendById(listingId, range.start, range.end)
            )
          );

          const newData = calendarResponses.reduce((acc, response, index) => {
            const listingId = listingIds[index];
            acc[listingId] = response.data.data;
            return acc;
          }, {});

          const cacheKey = `${range.start}-${range.end}`;
          cache.set(cacheKey, newData);

          return { range, newData };
        })
      );

      setAllCalendarData(prevData => {
        const mergedData = { ...prevData };
        fetchResults.forEach(({ newData }) => {
          Object.keys(newData).forEach(listingId => {
            if (!mergedData[listingId]) {
              mergedData[listingId] = [];
            }
            mergedData[listingId] = [
              ...mergedData[listingId],
              ...newData[listingId].filter(newItem =>
                !mergedData[listingId].some(existingItem =>
                  existingItem.date === newItem.date
                )
              )
            ].sort((a, b) => moment(a.date).diff(moment(b.date)));
          });
        });
        return mergedData;
      });

      setFetchedRanges(prev => mergeRanges([...prev, ...rangesToFetch]));
    } catch (error) {
      console.error('Failed to fetch calendar data:', error);
    } finally {
      setIsLoading(false);
      setLoadingDateRanges(prev => prev.filter(range =>
        !rangesToFetch.some(r => r.start === range.start && r.end === range.end)
      ));
      setCurrentlyFetchingRanges(prev => prev.filter(range =>
        !rangesToFetch.some(r => r.start === range.start && r.end === range.end)
      ));
    }
  }, [listings, fetchedRanges, currentlyFetchingRanges, staging, listingOnId]);

  const findNonOverlappingRanges = useCallback((targetRange, existingRanges) => {
    let nonOverlappingRanges = [];
    let currentStart = moment(targetRange.start);

    while (currentStart.isSameOrBefore(targetRange.end)) {
      const overlappingRange = existingRanges.find(range =>
        moment(currentStart).isBetween(range.start, range.end, null, '[]')
      );

      if (overlappingRange) {
        currentStart = moment(overlappingRange.end).add(1, 'day');
      } else {
        const rangeEnd = moment.min(moment(targetRange.end), currentStart.clone().add(FETCH_WINDOW - 1, 'days'));
        nonOverlappingRanges.push({
          start: currentStart.format('YYYY-MM-DD'),
          end: rangeEnd.format('YYYY-MM-DD')
        });
        currentStart = rangeEnd.add(1, 'day');
      }
    }

    return nonOverlappingRanges;
  }, []);

  const handleScroll = useCallback(
    debounce((targetDate) => {
      const momentTargetDate = moment(targetDate);
      const currentMoment = moment(currentDate);
      const targetMonthStart = momentTargetDate.clone().startOf('month');
      const targetMonthEnd = momentTargetDate.clone().endOf('month');
      fetchCalendarData(targetMonthStart, targetMonthEnd);
      const isForward = momentTargetDate.isAfter(currentMoment);
      let iterationDate = isForward ? currentMoment.clone() : momentTargetDate.clone();
      const endDate = isForward ? momentTargetDate.clone() : currentMoment.clone();
      while (iterationDate.isBefore(endDate, 'month')) {
        const monthStart = iterationDate.clone().startOf('month');
        const monthEnd = iterationDate.clone().endOf('month');
        fetchCalendarData(monthStart, monthEnd);
        iterationDate.add(1, 'month');
      }
      setCurrentDate(targetDate);
    }, 200),
    [currentDate, fetchCalendarData, setCurrentDate]
  );

  const scrollToDate = useCallback((targetDate) => {
    const momentTargetDate = moment(targetDate);
    const currentMoment = moment(currentDate);

    const targetMonthStart = momentTargetDate.clone().startOf('month');
    const targetMonthEnd = momentTargetDate.clone().endOf('month');
    fetchCalendarData(targetMonthStart, targetMonthEnd);

    const isForward = momentTargetDate.isAfter(currentMoment);
    let iterationDate = isForward ? currentMoment.clone() : momentTargetDate.clone();
    const endDate = isForward ? momentTargetDate.clone() : currentMoment.clone();

    while (iterationDate.isBefore(endDate, 'month')) {
      const monthStart = iterationDate.clone().startOf('month');
      const monthEnd = iterationDate.clone().endOf('month');
      fetchCalendarData(monthStart, monthEnd);
      iterationDate.add(1, 'month');
    }

    setCurrentDate(targetDate);

    if (scrollerRef.current) {
      const scrollWidth = scrollerRef.current.scrollWidth;
      const clientWidth = scrollerRef.current.clientWidth;
      const daysFromStart = momentTargetDate.diff(moment(allDays[0]), 'days');
      const scrollPercentage = daysFromStart / allDays.length;
      const scrollLeft = scrollPercentage * (scrollWidth - clientWidth);
      scrollerRef.current.scrollLeft = scrollLeft;
    }
  }, [currentDate, fetchCalendarData, setCurrentDate, allDays]);

  const checkinScroll = useCallback((targetDate) => {
    const momentTargetDate = moment(targetDate);

    const preCheckInStart = moment(checkInDate).subtract(1, 'month').startOf('month');
    const preCheckInEnd = moment(checkInDate).subtract(1, 'day');

    const reservationStart = moment(checkInDate);
    const reservationEnd = moment(checkOutDate);

    const postCheckOutStart = moment(checkOutDate).add(1, 'day');
    const postCheckOutEnd = moment(checkOutDate).add(1, 'month').endOf('month');

    fetchCalendarData(preCheckInStart, preCheckInEnd);
    fetchCalendarData(reservationStart, reservationEnd);
    fetchCalendarData(postCheckOutStart, postCheckOutEnd);

    setCurrentDate(targetDate);

    if (scrollerRef.current) {
      const scrollWidth = scrollerRef.current.scrollWidth;
      const clientWidth = scrollerRef.current.clientWidth;
      const daysFromStart = momentTargetDate.diff(moment(allDays[0]), 'days');
      const scrollPercentage = daysFromStart / allDays.length;
      const scrollLeft = scrollPercentage * (scrollWidth - clientWidth);
      scrollerRef.current.scrollLeft = scrollLeft;
    }
  }, [checkInDate, checkOutDate, fetchCalendarData, setCurrentDate, allDays]);


  useEffect(() => {
    if (checkInDate && checkOutDate && checkOutDate.isAfter(checkInDate)) {
      if (moment(checkInDate).isSameOrAfter(moment(currentDate)) && moment(checkOutDate).isSameOrBefore(moment(currentDate).add(2, 'month'))) {
        checkinScroll(checkInDate);
      }  
    } else {
      const fetchStartDate = moment(currentDate).startOf('month');
      const fetchEndDate = moment(currentDate).add(2, 'month').endOf('month');
      fetchCalendarData(fetchStartDate, fetchEndDate);
    }
  }, [fetchCalendarData, currentDate, checkInDate, checkOutDate]);

  const processedListings = useMemo(() => {
    if (listingOnId) {
      console.log("--------listingOnId--------------------- : ", listingOnId);
      return [{
        id: listingOnId,
        name: listings.find(l => l.id === listingOnId)?.name || '',
        calendarData: allCalendarData[listingOnId] || []
      }];
    }
    return listings.map(listing => ({
      id: listing.id,
      name: listing.name,
      calendarData: allCalendarData[listing.id] || []
    }));
  }, [listings, allCalendarData]);

  return (
    <div className="calendar-wrapper">
      <ToastContainer />
      <CalendarHeader
        formattedDateRange={formattedDateRange}
        goToToday={goToToday}
        setViewType={setViewType}
        viewType={viewType}
        canScrollForward={true}
        scrollToDate={scrollToDate}
      />
      <MultiTableCalendar
        scrollerRef={scrollerRef}
        properties={processedListings}
        daysInView={daysInView}
        selectedItems={selectedItems}
        isLoading={isLoading}
        goToNextPeriod={goToNextPeriod}
        goToPreviousPeriod={goToPreviousPeriod}
        allDays={allDays}
        loadingDateRanges={loadingDateRanges}
        onScroll={handleScroll}
      />
    </div>
  );
});
