import { Switch, styled } from '@mui/material';

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#00b4b4',
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#00b4b4',
  },
  '& .MuiSwitch-switchBase': {
    '&.Mui-disabled': {
      color: '#e886a9',
    },
  },
  '& .MuiSwitch-track': {
  },
}));