import React, { useState } from 'react';
import { TextField, FormControlLabel, Switch, Button, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadingIcon from '@mui/icons-material/Downloading';
import defaultAvatar from 'assets/images/placeholder.jpg';
import ImageUpload from 'components/CustomUpload/UploadV2';
import { saveAs } from 'file-saver';
import PdfViewerModal from '../PdfViewerModal';

const AccessInfo = ({ formik }) => {
  const { values, setFieldValue } = formik;
  const accesses = values.access || [];
  const [expandedPanel, setExpandedPanel] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPdfUrl, setCurrentPdfUrl] = useState('');

  const handleAccessChange = (index, field, value) => {
    const newAccesses = [...accesses];
    if (field.includes('.')) {
      const [parentField, childField] = field.split('.');
      newAccesses[index] = {
        ...newAccesses[index],
        [parentField]: {
          ...newAccesses[index][parentField],
          [childField]: value,
        },
      };
    } else {
      newAccesses[index] = { ...newAccesses[index], [field]: value };
    }
    setFieldValue('access', newAccesses);
  };

  const addNewAccess = () => {
    const newAccess = {
      type: '',
      gpsPosition: { lat: '', lng: '' },
      code: '',
      detail: '',
      adresse: '',
      enable: false,
      planAccess: '',
      imageUrl: '',
    };
    const newAccesses = [...accesses, newAccess];
    setFieldValue('access', newAccesses);
    setExpandedPanel(newAccesses.length - 1);
  };

  const removeAccess = (index) => {
    const newAccesses = accesses.filter((_, i) => i !== index);
    setFieldValue('access', newAccesses);
    setExpandedPanel(null);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  const downloadPDF = (url) => {
    saveAs(url, 'plan_access.pdf');
  };

  const removeImage = (index, field) => {
    handleAccessChange(index, field, '');
  };

  const openPdfModal = (url) => {
    setCurrentPdfUrl(url);
    setModalOpen(true);
  };

  const closePdfModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="w-full p-4 h-auto">
      <div className="flex justify-between mb-4">
        <span className="text-md font-semibold">Access Information</span>
        <Button
          variant="contained"
          className="!bg-medium-aquamarine !text-white"
          onClick={addNewAccess}
        >
          <AddCircleIcon />
        </Button>
      </div>
      {accesses.map((access, index) => (
        <Accordion
          key={index}
          expanded={expandedPanel === index}
          onChange={handleAccordionChange(index)}
          className="!mb-4"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="!text-white" />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
            className="!bg-[#6fd1bd] text-white"
          >
            <Typography>Access {index + 1} - {access.type || 'New Access'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="flex flex-col gap-4">
              <div className="flex justify-end">
                <Button
                  variant="outlined"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => removeAccess(index)}
                  className="!border-red-500 !text-red-500"
                >
                </Button>
              </div>

              <TextField
                label="Type"
                value={access.type || ''}
                onChange={(e) => handleAccessChange(index, 'type', e.target.value)}
                fullWidth
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={access.enable || false}
                    onChange={(e) => handleAccessChange(index, 'enable', e.target.checked)}
                  />
                }
                label="Enable"
              />

              <TextField
                label="Address"
                value={access.adresse || ''}
                onChange={(e) => handleAccessChange(index, 'adresse', e.target.value)}
                fullWidth
              />

              <TextField
                label="Detail"
                value={access.detail || ''}
                onChange={(e) => handleAccessChange(index, 'detail', e.target.value)}
                fullWidth
                multiline
                rows={4}
              />

              <div className="flex gap-4">
                <TextField
                  label="GPS Latitude"
                  value={access.gpsPosition?.lat || ''}
                  onChange={(e) => handleAccessChange(index, 'gpsPosition.lat', e.target.value)}
                  type="number"
                  fullWidth
                />
                <TextField
                  label="GPS Longitude"
                  value={access.gpsPosition?.lng || ''}
                  onChange={(e) => handleAccessChange(index, 'gpsPosition.lng', e.target.value)}
                  type="number"
                  fullWidth
                />
              </div>

              <div className="flex gap-4">
                <div className="flex-1">
                  <label className="mb-2 text-gray-500">Image:</label>
                  {access.imageUrl ? (
                    <div>
                      <img
                        src={access.imageUrl}
                        alt="Access Image"
                        style={{ width: '300px', height: '150px' }}
                      />
                      <Button
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                        onClick={() => removeImage(index, 'imageUrl')}
                        className="!ml-28 mt-2 !text-red-500 !border !border-red-500"
                      >
                      </Button>
                    </div>
                  ) : (
                    <img
                      src={defaultAvatar}
                      alt="No image uploaded"
                      style={{ width: '300px', height: '150px' }}
                    />
                  )}
                  <div className="!ml-[73px]">
                    <ImageUpload
                      fieldName={`access[${index}].imageUrl`}
                      setFieldValue={setFieldValue}
                      folder="listing"
                      accept="image/*"
                      label="Upload Image"
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <label className="mb-2 text-gray-500">Plan Access:</label>
                  {access.planAccess ? (
                    access.planAccess.endsWith('.pdf') ? (
                      <div className="!my-[4.9rem] flex gap-4 items-center">
                        {/* <p className="text-gray-700 font-semibold !m-0">PDF Found: {access.planAccess.replace(/^.*(\/MS\/pdfs\/.*)$/, '$1')}</p> */}
                        <div className="flex gap-4">
                          <Button
                            variant="contained"
                            className="!bg-medium-aquamarine !text-white"
                            onClick={() => openPdfModal(access.planAccess)}
                          >
                            <RemoveRedEyeIcon />
                          </Button>
                          <Button
                            variant="contained"
                            className="!text-gray-500 !bg-white !border !border-gray-500"
                            onClick={() => downloadPDF(access.planAccess)}
                          >
                            <DownloadingIcon />
                          </Button>
                          <Button
                            variant="contained"
                            startIcon={<DeleteIcon />}
                            onClick={() => removeImage(index, 'planAccess')}
                            className="!text-red-500 !bg-white !border !border-red-500"
                          >
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <img
                          src={access.planAccess}
                          alt="Plan Access"
                          style={{ width: '300px', height: '150px' }}
                        />
                        <Button
                          variant="outlined"
                          color="error"
                          startIcon={<DeleteIcon />}
                          onClick={() => removeImage(index, 'planAccess')}
                          className="!ml-28 mt-2 !text-red-500 !border !border-red-500"
                        >
                        </Button>
                      </div>
                    )
                  ) : (
                    <img
                      src={defaultAvatar}
                      alt="No plan access uploaded"
                      style={{ width: '300px', height: '150px' }}
                    />
                  )}
                  <div className="!ml-[73px]">
                    <ImageUpload
                      fieldName={`access[${index}].planAccess`}
                      setFieldValue={setFieldValue}
                      folder="listing"
                      accept=".pdf,image/*"
                      label="Upload Plan Access"
                    />
                  </div>
                </div>
              </div>

              <TextField
                label="Access Code"
                value={access.code || ''}
                onChange={(e) => handleAccessChange(index, 'code', e.target.value)}
                fullWidth
              />
            </div>
          </AccordionDetails>
        </Accordion>
      ))}

      <TextField
        label="Apartment Access Code"
        name="appartementAccessCode"
        value={values.appartementAccessCode || ''}
        onChange={(e) => setFieldValue('appartementAccessCode', e.target.value)}
        fullWidth
        className="mt-4"
      />

      <TextField
        label="Description Access"
        name="descriptionAccess"
        value={values.descriptionAccess || ''}
        onChange={(e) => setFieldValue('descriptionAccess', e.target.value)}
        fullWidth
        multiline
        rows={4}
        className="mt-4"
      />

      <PdfViewerModal
        open={modalOpen}
        onClose={closePdfModal}
        pdfUrl={currentPdfUrl}
      />
    </div>
  );
};

export default AccessInfo;