import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { getCountries, getCities, getLanguage } from "../../services/serverApi.listing";
import { FormControl, Select, MenuItem, InputLabel, Autocomplete, TextField, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const homeIconSvgString = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 100">
  <defs>
    <filter id="shadow">
      <feDropShadow dx="0" dy="3" stdDeviation="2" flood-color="#000000" flood-opacity="0.3"/>
    </filter>
  </defs>
  <path d="M40 5 C20 5 5 25 5 50 C5 75 40 95 40 95 C40 95 75 75 75 50 C75 25 60 5 40 5 Z" fill="#4285F4" filter="url(#shadow)"/>
  <circle cx="40" cy="42" r="25" fill="white"/>
  <path d="M40 25 L40 60 M28 37 L52 37 M28 47 L52 47" stroke="#4285F4" stroke-width="5" stroke-linecap="round"/>
</svg>`;

const homeIcon = new L.DivIcon({
  html: `<div style="display: flex; justify-content: center; align-items: center; width: 38px; height: 38px;">${homeIconSvgString}</div>`,
  className: '',
  iconSize: [38, 38],
  iconAnchor: [12, 24],
  popupAnchor: [0, -24]
});

function Address({ formik }) {
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);

  const UpdateView = () => {
    if (mapRef.current && markerRef.current) {
      const { lat, lng } = formik.values;
      mapRef.current.setView([lat, lng], 10);
      markerRef.current.setLatLng([lat, lng]);
    }
  }

  useEffect(() => {
    UpdateView()
  }, [formik.values.lat, formik.values.lng]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [citiesResponse, countriesResponse, languagesResponse] = await Promise.all([
          getCities(),
          getCountries(),
          getLanguage()
        ]);
        setCities(citiesResponse.data);
        setCountries(countriesResponse.data);
        setLanguages(languagesResponse.data.filter(lang => lang.name.toLowerCase() !== 'english'));
        const englishLanguage = languagesResponse.data.find(lang => lang.name.toLowerCase() === 'english');
        if (englishLanguage) {
          setDefaultLanguage(englishLanguage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (defaultLanguage && !formik.values.zoneDescription) {
      formik.setFieldValue('zoneDescription', { [defaultLanguage._id]: '' });
    }
  }, [defaultLanguage]);

  const handleZoneDescriptionChange = (languageId, value) => {
    const currentDescriptions = { ...formik.values.zoneDescription };
    currentDescriptions[languageId] = value;
    formik.setFieldValue('zoneDescription', currentDescriptions);
  };

  const getAvailableLanguagesForZoneDescription = () => {
    const usedLanguageIds = Object.keys(formik.values.zoneDescription || {});
    return languages.filter(lang => !usedLanguageIds.includes(lang._id));
  };

  return (
    <div className="w-full p-4">
      <div className="flex justify-start">
        <span className="text-md font-semibold">Address</span>
      </div>

      <div className="grid grid-cols-2 gap-4 !mt-4">

        <FormControl fullWidth>
          <InputLabel id="country-label">Country</InputLabel>
          <Select
            labelId="country-label"
            id="country"
            name="country"
            className='!text-sm !rounded-none'
            value={formik.values.country}
            onChange={(e) => {
              const selectedCountry = countries?.find(country => country.name === e.target.value);
              formik.setFieldValue('country', e.target.value);
              formik.setFieldValue('countryCode', selectedCountry ? selectedCountry.countryCode : '');
            }}
            onBlur={formik.handleBlur}
            MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8 } } }}
          >
            {countries.map((country) => (
              <MenuItem key={country._id} value={country.name}>
                {country.name}
              </MenuItem>
            ))}
          </Select>
          {formik.touched.country && formik.errors.country && (
            <div className="text-red-500 text-sm mt-1">{formik.errors.country}</div>
          )}
        </FormControl>
        <FormControl fullWidth>
          <InputLabel id="city-label">City</InputLabel>
          <Select
            labelId="city-label"
            id="city"
            name="city"
            className='!text-sm !rounded-none'
            value={formik.values.city}
            onChange={(e) => {
              const selectedCity = cities?.find(city => city.name === e.target.value);
              formik.setFieldValue('city', e.target.value);
              formik.setFieldValue('cityId', selectedCity ? selectedCity._id : '');
            }}
            onBlur={formik.handleBlur}
            MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8 } } }}
          >
            {cities.map((city) => (
              <MenuItem key={city._id} value={city.name}>
                {city.name}
              </MenuItem>
            ))}
          </Select>
          {formik.touched.city && formik.errors.city && (
            <div className="text-red-500 text-sm mt-1">{formik.errors.city}</div>
          )}
        </FormControl>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div className="bg-white">
          <div className="mb-1">
            <label htmlFor="lng" className="block text-[13px] text-gray-700 font-semibold mb-2">
              Longitude
            </label>
            <input
              id="lng"
              name="lng"
              type="number"
              value={formik.values.lng}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full !text-sm px-2 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Longitude"
            />
            {formik.touched.lng && formik.errors.lng && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.lng}</div>
            )}
          </div>

        </div>

        <div className="bg-white">
          <div className="mb-1">
            <label htmlFor="lat" className="block text-[13px] text-gray-700 font-semibold mb-2">
              Latitude
            </label>
            <input
              id="lat"
              name="lat"
              type="number"
              value={formik.values.lat}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full !text-sm px-2 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Latitude"
            />
            {formik.touched.lat && formik.errors.lat && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.lat}</div>
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">

        <div className="bg-white">
          <div className="mb-1">
            <label htmlFor="address" className="block text-[13px] text-gray-700 font-semibold mb-2">
              Enter your address
            </label>
            <input
              id="address"
              name="address"
              type="text"
              value={formik.values.address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full !text-sm px-2 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter your address"
            />
            {formik.touched.address && formik.errors.address && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.address}</div>
            )}
          </div>
        </div>
        <div className="mb-1">
          <label htmlFor="zone" className="block text-[13px] text-gray-700 font-semibold mb-2">
            Zone
          </label>
          <input
            id="zone"
            name="zone"
            type="text"
            value={formik.values.zone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className="w-full !text-sm px-2 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter zone"
          />
          {formik.touched.zone && formik.errors.zone && (
            <div className="text-red-500 text-sm mt-1">{formik.errors.zone}</div>
          )}
        </div>
      </div>





      <div className="grid grid-cols-2 gap-4 mt-3">
        <div className="bg-white">
          <MapContainer
            ref={mapRef}
            center={[formik.values.lat, formik.values.lng]}
            zoom={14}
            style={{ height: '300px', marginTop: '1rem' }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker
              position={[formik.values.lat, formik.values.lng]}
              icon={homeIcon}
              ref={markerRef}
            >
              <Popup>
                Your location. <br /> Easily customizable.
              </Popup>
            </Marker>
          </MapContainer>

        </div>
        <div className="bg-white">


          <div className="mb-1">
            <label htmlFor="zipcode" className="block text-[13px] text-gray-700 font-semibold mb-2">
              Zip code
            </label>
            <input
              id="zipcode"
              name="zipcode"
              type="text"
              value={formik.values.zipcode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full !text-sm px-2 py-2 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="zipcode"
            />
            {formik.touched.zipcode && formik.errors.zipcode && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.zipcode}</div>
            )}
          </div>
          <div className="mb-1 ">
            <label htmlFor="timeZoneName" className="block text-[13px] text-gray-700 font-semibold mb-2">
              Timezone name
            </label>
            <input
              id="timeZoneName"
              name="timeZoneName"
              type="text"
              value={formik.values.timeZoneName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full !text-sm px-2 py-2 border border-gray-300 cursor-not-allowed"
              placeholder="Timezone name"
              disabled
            />
            {formik.touched.timeZoneName && formik.errors.timeZoneName && (
              <div className="text-red-500 text-sm mt-1">{formik.errors.timeZoneName}</div>
            )}
          </div>

          <div className="mb-1">
            <label className="block text-[13px] text-gray-700 font-semibold mb-2">
              Zone Description
            </label>
            {defaultLanguage && (
              <div className="grid grid-cols-1 gap-3 mb-2">
                <TextField
                  value="English"
                  disabled
                  label="Language"
                  variant="outlined"
                  size="small"
                  className="mr-2 mx-1 w-1/4"
                />
                <TextField
                  fullWidth
                  value={formik.values.zoneDescription[defaultLanguage._id] || ''}
                  onChange={(e) => handleZoneDescriptionChange(defaultLanguage._id, e.target.value)}
                  label="Description"
                  variant="outlined"
                  size="small"
                  multiline
                  rows={4}
                />
              </div>
            )}
            {Object.entries(formik.values.zoneDescription || {}).map(([languageId, description]) => {
              if (languageId === defaultLanguage?._id) return null;
              return (
                <div key={languageId} className="grid grid-cols-1 gap-2 mb-2">
                  <TextField
                    fullWidth
                    value={languages.find(lang => lang._id === languageId)?.name || ''}
                    disabled
                    label="Language"
                    variant="outlined"
                    size="small"
                    className="mr-2 my-1 !w-1/4 !rounded-none"
                  />
                  <div className='flex items-center'>
                    <TextField
                      fullWidth
                      value={description}
                      onChange={(e) => handleZoneDescriptionChange(languageId, e.target.value)}
                      label="Description"
                      variant="outlined"
                      size="small"
                      multiline
                      rows={4}
                    />
                    <IconButton onClick={() => {
                      const updatedDescriptions = { ...formik.values.zoneDescription };
                      delete updatedDescriptions[languageId];
                      formik.setFieldValue('zoneDescription', updatedDescriptions);
                    }} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              );
            })}
            <Autocomplete
              options={getAvailableLanguagesForZoneDescription()}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Add Language" variant="outlined" size="small" />}
              onChange={(_, newValue) => {
                if (newValue) {
                  handleZoneDescriptionChange(newValue._id, '');
                }
              }}
              style={{ marginTop: '15px' }}
            />
          </div>
        </div>
      </div>
    </div>

  );
}

export default Address;
