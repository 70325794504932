import React from 'react';
import Tooltip from '@mui/material/Tooltip';

const SidebarProperty = ({ properties }) => {
  const renderPropertyCell = (property) => {
    return (
      <Tooltip title={property.name} arrow placement="right">
        <div className="property-cell">
          <div className='truncate'>
            <span className='property-name'>{property.name}</span>
          </div>
        </div>
      </Tooltip>
    );
  };

  return (
    <div className="w-[250px] border-r-[3px] border-r-gray-200 h-full">
      <div className='border-y-[2px] bg-gray-50'>
        <span className='text-sm font-semibold px-2'>property</span>
      </div>
      {properties.map((property) => (
        <div key={property.id} className="!p-[8.5%]">
          {renderPropertyCell(property)}
        </div>
      ))}
    </div>
  );
};

export default SidebarProperty;