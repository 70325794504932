import React, { useState, useEffect } from 'react';
import { getTickets, getCities, getPropertyTypes } from '../services/serviceApi.ticketsConfig';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import TicketsModal from '../compoenets/TicketsModal';
import { toast, ToastContainer } from 'react-toastify';
import EditOffIcon from '@mui/icons-material/EditOff';
import { CircularProgress, Typography, Button } from '@mui/material';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import ColumnVisibilityManager from '../compoenets/ColumnVisibilityManager';
import TicketFilter from '../compoenets/TicketFilter';
import Tooltip from '@mui/material/Tooltip';


function Tickets() {
    const [isLoading, setIsLoading] = useState(true);
    const [tickets, setTickets] = useState([]);
    const [filteredTickets, setFilteredTickets] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [filters, setFilters] = useState({
        status: '',
        acceptance: '',
        cityId: '',
        code: '',
    });

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [error, setError] = useState(null);
    const [cities, setCities] = useState([]);
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState([]);

    useEffect(() => {
        console.log('Tickets state changed:', tickets);
    }, [tickets]);

    const columns = [
        {
            header: "Ticket Code",
            body: (rowData) => <span>{rowData?.code}</span>
        },
        {
            header: "Status",
            body: (rowData) => <span>{rowData?.status}</span>
        },
        {
            header: "Acceptance",
            body: (rowData) => <span>{rowData?.acceptance}</span>
        },
        {
            header: "Client Name",
            body: (rowData) => (
                <span>
                    {`${rowData?.firstName} ${rowData?.lastName}`}
                </span>
            )
        },
        {
            header: "Property Details",
            body: (rowData) => {
                const city = cities.find(city => city._id === rowData?.cityId);
                const propertyType = propertyTypes.find(type => type._id === rowData?.propertyTypeId);
                
                const cityName = city?.name || rowData?.city || '';
                const typeName = propertyType?.name || rowData?.propertyType || '';
                const condition = rowData?.propertyCondition || '';
                
                return (
                    <div>
                        <div>
                            <Tooltip title={`City: ${cityName}`} placement="right">
                                <span className="cursor-pointer">
                                    City: {cityName.slice(0, 6)}...
                                </span>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip title={`Type: ${typeName}`} placement="right">
                                <span className="cursor-pointer">
                                    Type: {typeName.slice(0, 6)}...
                                </span>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip title={`Condition: ${condition}`} placement="right">
                                <span className="cursor-pointer">
                                    Condition: {condition.slice(0, 6)}...
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                );
            }
        },
        {
            header: "Email",
            body: (rowData) => <div>{rowData?.email}</div>
        },
        {
            header: "WhatsApp",
            body: (rowData) => <div>{rowData?.whatsApp}</div>
        },
        {
            header: "Location",
            body: (rowData) => <div>{rowData?.location}</div>
        },
        {
            header: "Expected Monthly Revenue",
            body: (rowData) => (
                <div>
                    <div>{rowData?.compensationModel}</div>
                    <div>{rowData?.expectedMonthlyRevenue} MAD</div>
                </div>
            )
        },
        {
            header: "Action",
            body: (rowData) => (
                <div className="flex gap-1">
                    <button
                        className="px-2 py-1 bg-medium-aquamarine !rounded-md"
                        onClick={() => handleOpenModal(rowData)}
                    >
                        <EditOffIcon className="text-white" />
                    </button>
                </div>
            ),
        },
    ];

    useEffect(() => {
        fetchInitialData();
    }, [filters, page, limit]);

    useEffect(() => {
        fetchCitiesAndPropertyTypes();
    }, [filters]);

    useEffect(() => {
        applyFiltersAndPagination();
    }, [tickets, filters, page, limit]);


    const fetchInitialData = async () => {
        try {
            setIsLoading(true);
            const response = await getTickets({
                status: filters.status,
                acceptance: filters.acceptance,
                cityId: filters.cityId,
                code: filters.code,
                page: page,
                limit: limit
            });

            if (response && response.tickets) {
                setTickets(response.tickets);
                setTotal(response.total);
            }
        } catch (error) {
            toast.error('No tickets found');
            setTickets([]);
            setTotal(0);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCitiesAndPropertyTypes = async () => {
        try {
            setIsLoading(true);
            const [citiesResponse, propertyTypesResponse] = await Promise.all([
                getCities(),
                getPropertyTypes()
            ]);

            if (citiesResponse && citiesResponse.data) {
                setCities(Array.isArray(citiesResponse.data) ? citiesResponse.data : [citiesResponse.data]);
            }

            if (propertyTypesResponse && propertyTypesResponse.data) {
                if (propertyTypesResponse && propertyTypesResponse.data) {
                    setPropertyTypes(
                        Array.isArray(propertyTypesResponse.data)
                            ? propertyTypesResponse.data
                            : (propertyTypesResponse.data.data || [])
                    );
                }
            }
        } catch (error) {
            console.error('Error fetching initial data:', error);
            toast.error('Failed to fetch initial data');
            setCities([]);
            setPropertyTypes([]);
        } finally {
            setIsLoading(false);
        }
    };

    const applyFiltersAndPagination = () => {
        let filteredData = tickets;

        if (filters.status) {
            filteredData = filteredData?.filter(ticket => ticket.status === filters.status);
        }

        if (filters.acceptance) {
            filteredData = filteredData?.filter(ticket => ticket.acceptance === filters.acceptance);
        }

        if (filters.cityId) {
            filteredData = filteredData?.filter(ticket => ticket.cityId === filters.cityId);
        }

        if (filters.code) {
            filteredData = filteredData?.filter(ticket =>
                ticket.code.toLowerCase().includes(filters.code.toLowerCase())
            );
        }
        setTotal(filteredData?.length);
    };

    const handleFilterApply = (newFilters) => {
        setFilters(newFilters);
        setPage(0);
    };


    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (newLimit) => {
        setLimit(newLimit);
        setPage(0);
    };

    const handleOpenModal = (ticket = null) => {
        setSelectedTicket(ticket);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedTicket(null);
        setModalOpen(false);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ToastContainer />
            <div className="card p-4">
                <div className="flex justify-between items-center mb-4">
                    <Typography variant="h4" component="h1" gutterBottom>
                        Tickets Management
                    </Typography>
                    <Button
                        onClick={() => handleOpenModal()}
                        className="!bg-medium-aquamarine text-white"
                    >
                        Create New Ticket
                    </Button>
                </div>

                <TicketFilter
                    cities={cities}
                    onFilterApply={handleFilterApply}
                    initialFilters={filters}
                />

                <div className="flex justify-end mb-2">
                    <ColumnVisibilityManager
                        columns={columns}
                        setVisibleColumns={setVisibleColumns}
                        tableName="tickets"
                    />
                </div>

                <div>
                    {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress style={{ color: '#00b4b4' }} />
                        </div>
                    ) : (
                        <GlobalTable
                            data={tickets} 
                            columns={visibleColumns}
                            hasPagination={true}
                            page={page}
                            onPageChange={handlePageChange}
                            limit={limit}
                            onLimitChange={handleLimitChange}
                            total={total}
                            isNextDisabled={tickets.length < limit}
                            rowsPerPageOptions={[10, 20, 50]}
                        />
                    )}
                </div>

                <TicketsModal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    setTickets={setTickets}
                    ticket={selectedTicket}
                />
            </div>
        </DashboardLayout>
    );
}

export default Tickets;