import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Box, Grid, Paper, CircularProgress, IconButton, Button } from '@mui/material';
import { Reorder } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import { getUnitMapping, updateUnitMapping } from '../services/serverApi.ProjectUnits';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import { Plus, ChevronDown, ChevronUp, GripVertical } from 'lucide-react';
import AddUnitButton from '../components/unitMapping/AddUnitButton';

const UnitMapping = () => {
  const [unitMapping, setUnitMapping] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedProjects, setExpandedProjects] = useState([]);

  const normalizeResponse = (data) => {
    return data.map(item => {
      if (item.dataproject) {
        return {
          _id: item.dataproject.id,
          type: 'project',
          projectName: item.dataproject.projectName,
          originalIndex: item.originalIndex,
          units: item.units?.map(unit => ({
            ...unit,
            type: 'projectUnit',
            projectId: item.dataproject.id
          })) || []
        };
      } else if (item.unit) {
        return {
          ...item.unit,
          type: 'independentUnit',
          originalIndex: item.originalIndex
        };
      }
      return null;
    }).filter(Boolean);
  };

  const transformForDatabase = (orderedItems) => {
    const unitIds = [];

    orderedItems.forEach(item => {
      if (item.type === 'project') {
        item.units?.forEach(unit => {
          unitIds.push(unit._id);
        });
      } else if (item.type === 'independentUnit') {
        unitIds.push(item._id);
      }
    });

    return unitIds;
  };

  const fetchUnitMapping = async () => {
    try {
      const response = await getUnitMapping();
      const normalizedData = normalizeResponse(response.data);
      setUnitMapping(normalizedData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching unit mapping:', error);
      toast.error('Failed to fetch unit mapping');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUnitMapping();
  }, []);

  const handleMainReorder = async (newOrder) => {
    try {
      setUnitMapping(newOrder);
      const mappingForDb = transformForDatabase(newOrder);
      await updateUnitMapping(null, { unitMapping: mappingForDb });
      toast.success('Order updated successfully');
    } catch (error) {
      console.error('Error updating order:', error);
      toast.error('Failed to update order');
    }
  };

  const handleProjectUnitReorder = async (projectId, newUnitsOrder) => {
    try {
      setUnitMapping(prevMapping =>
        prevMapping.map(item =>
          item._id === projectId
            ? { ...item, units: newUnitsOrder }
            : item
        )
      );

      const updatedMapping = unitMapping.map(item =>
        item._id === projectId
          ? { ...item, units: newUnitsOrder }
          : item
      );

      const mappingForDb = transformForDatabase(updatedMapping);
      await updateUnitMapping(projectId, { unitMapping: mappingForDb });
      toast.success('Project units reordered successfully');
    } catch (error) {
      console.error('Error updating project units order:', error);
      toast.error('Failed to update project units order');
    }
  };

  const toggleProjectExpand = (projectId) => {
    setExpandedProjects(prev =>
      prev.includes(projectId)
        ? prev.filter(id => id !== projectId)
        : [...prev, projectId]
    );
  };

  const ProjectUnitsList = ({ project, isExpanded }) => (
    <div className={`${isExpanded ? 'block' : 'hidden'} space-y-2 pl-6 pr-4 pb-2 mt-4 px-2`}>
      {project.units && project.units.length > 0 && (
        <Reorder.Group
          axis="y"
          values={project.units}
          onReorder={(newOrder) => handleProjectUnitReorder(project._id, newOrder)}
        >
          {project.units.map(unit => (
            <Reorder.Item key={unit._id} value={unit} className="touch-none my-2">
              <div className="flex items-center group rounded-lg border border-gray-200 bg-blue-50 p-3 hover:bg-blue-100 transition-all">
                <GripVertical className="h-5 w-5 text-gray-400 opacity-0 group-hover:opacity-100 cursor-move mr-2" />
                <span className="text-sm font-medium text-gray-700">{unit.unitName}</span>
              </div>
            </Reorder.Item>
          ))}
        </Reorder.Group>
      )}
    </div>
  );

  const ProjectCard = ({ item }) => {
    const isExpanded = expandedProjects.includes(item._id);

    return (
      <Card className="!border-none !shadow-sm !hover:shadow-md !transition-all !duration-200">
        <CardContent className="p-0">
          <div className="flex items-center justify-between p-4 group !bg-green-50">
            <div className="flex items-center gap-3">
              <GripVertical className="h-5 w-5 text-gray-400 opacity-0 group-hover:opacity-100 cursor-move" />
              <span className="text-sm font-medium text-gray-700">{item.projectName}</span>
            </div>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => toggleProjectExpand(item._id)}
              className="hover:bg-green-100"
            >
              {isExpanded ?
                <ChevronUp className="h-4 w-4 text-green-600" /> :
                <ChevronDown className="h-4 w-4 text-green-600" />
              }
            </Button>
          </div>
          <ProjectUnitsList project={item} isExpanded={isExpanded} />
        </CardContent>
      </Card>
    );
  };

  const IndependentUnitCard = ({ item }) => (
    <Card className="!border-none !shadow-sm !hover:shadow-md !transition-all !duration-200 !bg-blue-50">
      {/* use this in this div: rounded-lg border border-gray-200 bg-blue-50 p-3 hover:bg-blue-100 transition-all */}
      <CardContent className="p-4">
        <div className="flex items-center gap-3 group">
          <GripVertical className="h-5 w-5 text-gray-400 opacity-0 group-hover:opacity-100 cursor-move" />
          <span className="text-sm font-medium text-gray-700">{item.unitName}</span>
        </div>
      </CardContent>
    </Card>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <div className="p-6 mx-auto card">
        <ToastContainer position="top-right" autoClose={3000} />

        <div className="flex justify-between items-center mb-8 p-4">
          <div>
            <h1 className="text-2xl font-semibold text-gray-900">Unit Mapping</h1>
            <p className="text-sm text-gray-500 mt-1">Drag and drop to reorder units and projects</p>
          </div>
          <AddUnitButton
        unitMapping={unitMapping}
        onUnitAdded={(newMapping) => setUnitMapping(newMapping)}
      />
        </div>

        {loading ? (
          <div className="flex justify-center py-12">
            <div className="w-8 h-8 border-4 border-primary border-t-transparent rounded-full animate-spin" />
          </div>
        ) : (
          <div className="overflow-y-auto p-4">
            <Reorder.Group
              axis="y"
              values={unitMapping}
              onReorder={handleMainReorder}
              className="space-y-3"
            >
              {unitMapping.map(item => (
                <Reorder.Item
                  key={item._id}
                  value={item}
                  className="touch-none my-2 cursor-move"
                >
                  {item.type === 'project'
                    ? <ProjectCard item={item} />
                    : <IndependentUnitCard item={item} />
                  }
                </Reorder.Item>
              ))}
            </Reorder.Group>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default UnitMapping;