import React, { useState, useEffect } from 'react';
import { getStayMore, updateStayMore } from '../services/serverApi.adminConfig';
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import {
    TextField,
    Button,
    Typography,
    Paper,
    Grid,
    Snackbar,
    CircularProgress,
    Box
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

const StayMore = () => {
    const [stayMoreDiscounts, setStayMoreDiscounts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '' });
    const [stayMoreId, setStayMoreId] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const data = await getStayMore();
            setStayMoreDiscounts(data.stayMore || []);
            setStayMoreId(data._id);
        } catch (error) {
            console.error('Error fetching stay more:', error.message);
            setStayMoreDiscounts([]);
            setSnackbar({ open: true, message: 'Error fetching data. Please try again.' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddStayMoreDiscount = () => {
        setStayMoreDiscounts([...stayMoreDiscounts, { night: 0, discount: 0 }]);
    };

    const handleDeleteStayMoreDiscount = (index) => {
        const updatedDiscounts = stayMoreDiscounts.filter((_, i) => i !== index);
        setStayMoreDiscounts(updatedDiscounts);
    };

    const handleStayMoreDiscountChange = (index, field, value) => {
        const updatedDiscounts = [...stayMoreDiscounts];
        updatedDiscounts[index][field] = Number(value);
        setStayMoreDiscounts(updatedDiscounts);
    };

    const handleSave = async () => {
        if (!stayMoreId) {
            setSnackbar({ open: true, message: 'Error: No ID available for update.' });
            return;
        }

        setIsSaving(true);
        try {
            await updateStayMore(stayMoreId, { stayMore: stayMoreDiscounts });
            setSnackbar({ open: true, message: 'Changes saved successfully!' });
        } catch (error) {
            console.error('Error saving stay more discounts:', error);
            setSnackbar({ open: true, message: 'Error saving changes. Please try again.' });
        } finally {
            setIsSaving(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div>
                <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
                <Box className="flex justify-between items-center !mb-[50px]">
                    <Typography variant="h5" className='!text-2xl pl-[90px] !w-[88%] text-start'>
                        Stay More
                    </Typography>
                    <Box className="flex gap-2 w-[88%] justify-end">
                        <Button
                            startIcon={<AddIcon className='text-[#00b4b4]' />}
                            onClick={handleAddStayMoreDiscount}
                            variant="outlined"
                            style={{ color: '#00b4b4', borderColor: '#00b4b4' }}
                            size="small"
                        >
                            Add Discount
                        </Button>
                        <Button
                            startIcon={isSaving ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                            onClick={handleSave}
                            variant="contained"
                            className="!bg-medium-aquamarine text-white"
                            disabled={isSaving}
                        >
                            {isSaving ? 'Saving...' : 'Save Changes'}
                        </Button>

                    </Box>
                </Box>
                    {stayMoreDiscounts.map((discount, index) => (
                        <Grid container spacing={2} className='flex justify-center w-full' key={index} sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    label="Nights"
                                    variant="outlined"
                                    value={discount.night}
                                    onChange={(e) => handleStayMoreDiscountChange(index, 'night', e.target.value)}
                                    inputProps={{ min: 0 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    fullWidth
                                    type="number"
                                    label="Discount (%)"
                                    variant="outlined"
                                    value={discount.discount}
                                    onChange={(e) => handleStayMoreDiscountChange(index, 'discount', e.target.value)}
                                    inputProps={{ min: 0, max: 100 }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Button
                                    onClick={() => handleDeleteStayMoreDiscount(index)}
                                    variant="contained"
                                    color="error"
                                    startIcon={<DeleteIcon className='text-red-500' />}
                                >
                                </Button>
                            </Grid>
                        </Grid>
                    ))}

                    

                </Paper>
            </div>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbar.message}
            />
        </DashboardLayout>
    );
};

export default StayMore;