import React, { useState, useEffect, useCallback } from 'react';
import { getMinutWebhook, getListings, SOCKET_URL, SOCKET_PATH } from '../services/serverApi.minut';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import { CircularProgress, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import io from 'socket.io-client';

const eventTypes = [
    'device_offline',
    'device_online',
    'tamper_mounted',
    'tamper_removed',
    'battery_low',
    'battery_empty',
    'pir_motion',
];

function Minut() {
    const [isLoading, setIsLoading] = useState(true);
    const [minutData, setMinutData] = useState([]);
    const [listings, setListings] = useState({});
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [eventType, setEventType] = useState('');
    const [socket, setSocket] = useState(null);

    const fetchListings = useCallback(async () => {
        try {
            const response = await getListings();
            if (response.data && Array.isArray(response.data.data)) {
                const listingsMap = {};
                response.data.data.forEach(listing => {
                    listingsMap[listing._id] = listing.name || 'Unnamed Listing';
                });
                setListings(listingsMap);
            }
        } catch (error) {
            console.error('Error fetching listings:', error);
        }
    }, []);

    const fetchMinutData = useCallback(async () => {
        try {
            setIsLoading(true);
            const response = await getMinutWebhook(page, limit, eventType);
            if (response.data && Array.isArray(response.data.data)) {
                setMinutData(response.data.data);
                setTotal(response.data.total);
            } else {
                setMinutData([]);
                setTotal(0);
            }
        } catch (error) {
            console.error('Error fetching Minut data:', error);
            setMinutData([]);
            setTotal(0);
        } finally {
            setIsLoading(false);
        }
    }, [page, limit, eventType]);

    useEffect(() => {
        fetchListings();
        fetchMinutData();
    }, [fetchListings, fetchMinutData]);

    useEffect(() => {
        const newSocket = io(SOCKET_URL, {
            path: SOCKET_PATH,
            transports: ['websocket'],
        });

        newSocket.on('connect', () => {
            console.log('WebSocket connected.');
            newSocket.emit('JOIN_ROOM', 'MINUT');
        });

        newSocket.on('disconnect', () => {
            console.log('WebSocket disconnected.');
        });

        newSocket.on('MINUT_NEW_EVENT', (data) => {
            setMinutData(prevData => {
                if (!eventType || eventType === data.event.type) {
                    return [data, ...prevData].slice(0, limit);
                }
                return prevData;
            });

            setTotal(prevTotal => prevTotal + 1);
        });

        setSocket(newSocket);

        return () => {
            if (newSocket) {
                newSocket.disconnect();
            }
        };
    }, [eventType, limit]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handleLimitChange = (newLimit) => {
        setLimit(newLimit);
        setPage(0);
    };

    const handleEventTypeChange = (event) => {
        setEventType(event.target.value);
        setPage(0);
    };

    const columns = [
        {
            header: "Event Type",
            body: (rowData) => <span>{rowData.event.type}</span>
        },
        {
            header: "Created At",
            body: (rowData) => <span>{new Date(rowData.createdAt).toLocaleString()}</span>
        },
        {
            header: "Listing Name",
            body: (rowData) => <span>{listings[rowData.listingId] || 'Unknown Listing'}</span>
        },
    ];

    return (
        <div className="card p-4">
            <Typography variant="h4" component="h1" className="mb-4" gutterBottom>
                Surveillance Webhook Data
            </Typography>
            <FormControl className="mb-4" style={{ minWidth: 200 }}>
                <InputLabel id="event-type-select-label">Filter by Event Type</InputLabel>
                <Select
                    labelId="event-type-select-label"
                    id="event-type-select"
                    value={eventType}
                    label="Filter by Event Type"
                    onChange={handleEventTypeChange}
                >
                    <MenuItem value="">All</MenuItem>
                    {eventTypes.map((type) => (
                        <MenuItem key={type} value={type}>{type}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <div>
                <div className="w-full">
                    {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress style={{ color: '#00b4b4' }} />
                        </div>
                    ) : (
                        <GlobalTable
                            data={minutData}
                            columns={columns}
                            hasPagination={true}
                            page={page}
                            onPageChange={handlePageChange}
                            limit={limit}
                            onLimitChange={handleLimitChange}
                            isNextDisabled={page >= Math.ceil(total / limit) - 1}
                            rowsPerPageOptions={[10, 20, 50]}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Minut;