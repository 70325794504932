export const allEvents = [
    'alarm_heard', 'avg_sound_high', 'battery_empty', 'battery_low', 'device_offline', 
    'device_online', 'device_power_off', 'disturbance_dismissed', 
    'disturbance_dispatched_automatically', 'disturbance_dispatched_manually', 
    'disturbance_ended', 'disturbance_first_notice', 'disturbance_second_notice', 
    'disturbance_snoozed', 'disturbance_third_notice', 'glassbreak', 
    'humidity_dropped_normal', 'humidity_high', 'humidity_low', 'humidity_risen_normal', 
    'pir_motion', 'response_service_dispatch_arrived', 
    'response_service_dispatch_cancelled_by_minut_while_in_progress', 
    'response_service_dispatch_cancelled_by_minut_while_requested', 
    'response_service_dispatch_cancelled_by_provider', 
    'response_service_dispatch_cancelled_by_user', 'risk_of_mould', 'short_button_press', 
    'smoking_detection_smoking_detected', 'sound_level_dropped_normal', 'tamper', 
    'tamper_mounted', 'tamper_removed', 'temperature_dropped_normal', 'temperature_high', 
    'temperature_low', 'temperature_risen_normal'
];
