import React, { useState } from 'react';
import {
  Modal, Box, Typography, Grid, Chip, IconButton, Collapse
} from '@mui/material';
import { X, Calendar, Clock, HandCoins, ToggleLeft, User, Home, Mail, Phone, ChevronDown, ChevronUp } from 'lucide-react';
import moment from 'moment';

const ModelEventDialog = ({ eventSelect, onClose }) => {
  const [showGuestInfo, setShowGuestInfo] = useState(false);

  const toggleGuestInfo = () => {
    setShowGuestInfo(!showGuestInfo);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'confirmed':
        return 'success';
      case 'pending':
        return 'warning';
      case 'canceled':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <Modal open={true} onClose={onClose} aria-labelledby="reservation-details-modal">
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        maxWidth: '90%',
        maxHeight: '90vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '16px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Box sx={{ p: 2, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" component="h2">
              Reservation Details
            </Typography>
            <IconButton onClick={onClose} size="small">
              <X size={20} />
            </IconButton>
          </Box>
          <Chip
            label={eventSelect.status}
            color={getStatusColor(eventSelect.status)}
            size="small"
          />
        </Box>

        <Box sx={{ flexGrow: 1, overflowY: 'auto', p: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mb={1}>
                <User size={18} style={{ marginRight: 8 }} />
                <Typography variant="body2" color="text.secondary">Guest</Typography>
              </Box>
              <Typography variant="body1">{eventSelect.guestName}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Box display="flex" alignItems="center" mb={1}>
                <Calendar size={18} style={{ marginRight: 8 }} />
                <Typography variant="body2" color="text.secondary">Check In</Typography>
              </Box>
              <Typography variant="body1">{moment(eventSelect.arrivalDate).format('ddd, MMM D, YYYY')}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center" mb={1}>
                <Calendar size={18} style={{ marginRight: 8 }} />
                <Typography variant="body2" color="text.secondary">Check Out</Typography>
              </Box>
              <Typography variant="body1">{moment(eventSelect.departureDate).format('ddd, MMM D, YYYY')}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Box display="flex" alignItems="center" mb={1}>
                <Clock size={18} style={{ marginRight: 8 }} />
                <Typography variant="body2" color="text.secondary">Nights</Typography>
              </Box>
              <Typography variant="body1">{eventSelect.nights}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center" mb={1}>
                <User size={18} style={{ marginRight: 8 }} />
                <Typography variant="body2" color="text.secondary">Guests</Typography>
              </Box>
              <Typography variant="body1">{`${eventSelect.adults || 0}A/${eventSelect.children || 0}C`}</Typography>
            </Grid>

            <Grid item xs={6}>
              <Box display="flex" alignItems="center" mb={1}>
                <HandCoins size={18} style={{ marginRight: 8 }} />
                <Typography variant="body2" color="text.secondary">Total Price</Typography>
              </Box>
              <Typography variant="body1">{`${eventSelect.totalPrice} ${eventSelect.currency}`}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" alignItems="center" mb={1}>
                <ToggleLeft size={18} style={{ marginRight: 8 }} />
                <Typography variant="body2" color="text.secondary">Payment Status</Typography>
              </Box>
              <Chip
                label={eventSelect.paymentStatus}
                color={eventSelect.paymentStatus === 'Paid' ? 'success' : 'error'}
                size="small"
              />
            </Grid>

            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                onClick={toggleGuestInfo}
                sx={{ cursor: 'pointer', mb: 1 }}
              >
                <Box display="flex" alignItems="center">
                  <Home size={18} style={{ marginRight: 8 }} />
                  <Typography variant="body2" color="text.secondary">Guest Info</Typography>
                </Box>
                <IconButton size="small">
                  {showGuestInfo ? <ChevronUp size={18} /> : <ChevronDown size={18} />}
                </IconButton>
              </Box>
              <Collapse in={showGuestInfo}>
                <Box sx={{ pl: 2, pt: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Mail size={18} style={{ marginRight: 8 }} />
                        <Typography variant="body2" color="text.secondary">Email</Typography>
                      </Box>
                      <Typography variant="body1">{eventSelect.guestEmail}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Phone size={18} style={{ marginRight: 8 }} />
                        <Typography variant="body2" color="text.secondary">Phone</Typography>
                      </Box>
                      <Typography variant="body1">{eventSelect.guestPhone || 'Not provided'}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" alignItems="center" mb={1}>
                        <Home size={18} style={{ marginRight: 8 }} />
                        <Typography variant="body2" color="text.secondary">Address</Typography>
                      </Box>
                      <Typography variant="body1">{`${eventSelect.guestCity}, ${eventSelect.guestCountry}`}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Collapse>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModelEventDialog;