import React from 'react';

const weekdayMap = [
    { short: 'S', full: 'sun' },
    { short: 'M', full: 'mon' },
    { short: 'T', full: 'tue' },
    { short: 'W', full: 'wed' },
    { short: 'T', full: 'thu' },
    { short: 'F', full: 'fri' },
    { short: 'S', full: 'sat' }
];

const WeekdaySelector = ({ event, onChange }) => {
    const handleDayChange = (day) => {
        onChange({ ...event, [day]: !event[day] });
    };

    return (
        <div className="mx-2">
            <div className="flex gap-1">
                {weekdayMap.map(({ short, full }, index) => (
                    <button
                        key={`${short}-${index}`}
                        onClick={() => handleDayChange(full)}
                        className={`!w-8 !h-8 !rounded-full !flex !items-center !justify-center !text-sm !font-medium !transition-colors !duration-200 ${
                            event[full] ? '!bg-green-200 !text-green-800' : '!bg-gray-200 !text-gray-600'
                        }`}
                    >
                        {short}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default WeekdaySelector;