import React from 'react';
import { FormControl, Select, MenuItem, Chip, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    padding: '9px 32px 8px 9px !important',
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
});

const QuickFilterDropdown = ({
  quickFilter,
  setQuickFilter,
  quickFilterOptions,
  onQuickFilterChange
}) => {
  const handleQuickFilterChange = (event) => {
    const newQuickFilter = event.target.value;
    setQuickFilter(newQuickFilter);
    onQuickFilterChange(newQuickFilter);
  };

  return (
    <FormControl sx={{ m: 1, width: '100%' }}>
      <StyledSelect
        value={quickFilter || ''}
        onChange={handleQuickFilterChange}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return <em>Quick Filters</em>;
          }
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              <Chip label={quickFilterOptions.find(option => option.value === selected)?.label} size="small" />
            </Box>
          );
        }}
      >
        {quickFilterOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default QuickFilterDropdown;