import React, { useEffect, useState } from 'react';
import { Select, MenuItem, TextField, Button, FormLabel, CircularProgress } from '@mui/material';
import Charts from './Charts';
import { getcities, getListings, getCheckinBychannel, getRentalRevenue, getBookedNights, getoccupancyRate, getAverageDailyRate, getAverageRevenuePerStay, getReservationStats, getChannelStats, getBookedNightsByChannel, getRevenueBychannel } from '../services/serverApi.financialConfig';
import moment from 'moment';
import Upcoming from './Upcoming';
import { getDateRange } from './dateUtils';
import { scrollbarStyle } from '../financialStyles/scrollBar';
import useFetchData from '../services/useFetchData';



const Analytics = () => {
    const [cities, setCities] = useState([]);
    const [listings, setListings] = useState([]);
    const formattedStartOfMonthDate = moment().startOf('month').format('YYYY-MM-DD');
    const formattedEndOfMonthDate = moment().endOf('month').format('YYYY-MM-DD');
    const [startDate, setStartDate] = useState(formattedStartOfMonthDate);
    const [endDate, setEndDate] = useState(formattedEndOfMonthDate);
    const [dateRangeOption, setDateRangeOption] = useState('Current month');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedListing, setSelectedListing] = useState('');
    const staging = JSON.parse(localStorage.getItem('isStaging')) || false;

    useEffect(() => {
        fetchData();
    }, [staging]);

    const fetchData = async () => {
        try {
            const [citiesData, listingsResponse] = await Promise.all([
                getcities(),
                getListings(staging)
            ]);
            setCities(citiesData.data);
            setListings(listingsResponse.data.data);
        } catch (error) {
            console.error("Error fetching data:", error);
            setListings([]);
        }
    };

    const handleDateRangeOptionChange = (event) => {
        const option = event.target.value;
        setDateRangeOption(option);
        const { start, end } = getDateRange(option);
        setStartDate(start);
        setEndDate(end);
    };

    const { data: rentalRevenueData, isLoading: isLoadingRentalRevenue } = useFetchData(() => getRentalRevenue(startDate, endDate, selectedCity, selectedListing), [startDate, endDate, selectedCity, selectedListing]);
    const { data: bookedNightsData, isLoading: isLoadingBookedNights } = useFetchData(() => getBookedNights(startDate, endDate, selectedCity, selectedListing), [startDate, endDate, selectedCity, selectedListing]);
    const { data: occupancyRateData, isLoading: isLoadingOccupancyRate } = useFetchData(() => getoccupancyRate(startDate, endDate, selectedCity, selectedListing), [startDate, endDate, selectedCity, selectedListing]);
    const { data: averageDailyRateData, isLoading: isLoadingAverageDailyRate } = useFetchData(() => getAverageDailyRate(startDate, endDate, selectedCity, selectedListing), [startDate, endDate, selectedCity, selectedListing]);
    const { data: averageRevenuePerStayData, isLoading: isLoadingAverageRevenuePerStay } = useFetchData(() => getAverageRevenuePerStay(startDate, endDate, selectedCity, selectedListing), [startDate, endDate, selectedCity, selectedListing]);
    const { data: reservationStatsData, isLoading: isLoadingReservationStats } = useFetchData(() => getReservationStats(startDate, endDate, selectedCity, selectedListing), [startDate, endDate, selectedCity, selectedListing]);
    const { data: channelStatsData, isLoading: isLoadingChannelStats } = useFetchData(() => getChannelStats(startDate, endDate, selectedCity, selectedListing), [startDate, endDate, selectedCity, selectedListing]);
    const { data: checkinByChannelData, isLoading: isLoadingCheckinByChannel } = useFetchData(() => getCheckinBychannel(startDate, endDate, selectedCity, selectedListing), [startDate, endDate, selectedCity, selectedListing]);
    const { data: revenueByChannelData, isLoading: isLoadingRevenueByChannel } = useFetchData(() => getRevenueBychannel(startDate, endDate, selectedCity, selectedListing), [startDate, endDate, selectedCity, selectedListing]);
    const { data: bookedNightsByChannelData, isLoading: isLoadingBookedNightsByChannel } = useFetchData(() => getBookedNightsByChannel(startDate, endDate, selectedCity, selectedListing), [startDate, endDate, selectedCity, selectedListing]);


    const handleDateChange = (event, dateType) => {
        if (dateType === 'start') {
            setStartDate(event.target.value);
        } else {
            setEndDate(event.target.value);
        }
    };

    return (
        <div className="p-6 max-w-7xl mx-auto">
            <div className="flex justify-between items-center mb-4">
                <h5 className="text-2xl font-bold">Financial Reporting</h5>
                <Button className="px-2 !bg-[#dcf6f6] !rounded-sm flex items-center gap-1">
                    <i className="pi pi-cog text-[#00b4b4] text-sm"></i>
                    <span className="ladda-label flex items-center text-[13px] text-[#00b4b4] p-[3px] capitalize">Settings</span>
                </Button>
            </div>
            <div className="flex gap-4 items-end mb-4">
                <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
                    <div className="w-full">
                        <FormLabel className="!text-[#788999] !text-xs !font-bold">Listings</FormLabel>
                        <Select
                            value={selectedListing}
                            onChange={(e) => setSelectedListing(e.target.value)}
                            displayEmpty
                            className="w-full h-9 !rounded-sm"
                        >
                            <MenuItem value="">Select Listing</MenuItem>
                            {listings.map((listing, index) => (
                                <MenuItem key={index} value={listing._id}>{listing.name}</MenuItem>
                            ))}
                        </Select>
                    </div>

                    <div className="w-full">
                        <FormLabel className="!text-[#788999] !text-xs !font-bold">City</FormLabel>
                        <Select
                            value={selectedCity}
                            onChange={(e) => setSelectedCity(e.target.value)}
                            displayEmpty
                            className="w-full h-9 !rounded-sm"
                        >
                            <MenuItem value="">Select City</MenuItem>
                            {cities.map((city, index) => (
                                <MenuItem key={index} value={city.name}>{city.name}</MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="w-full">
                        <FormLabel className="!text-[#788999] !text-xs !font-bold">Start Date</FormLabel>
                        <TextField
                            type="date"
                            value={startDate}
                            className="w-full"
                            InputProps={{ className: "h-9 !rounded-sm" }}
                            onChange={(e) => handleDateChange(e, 'start')}
                        />
                    </div>

                    <div className="w-full">
                        <FormLabel className="!text-[#788999] !text-xs !font-bold">End Date</FormLabel>
                        <TextField
                            type="date"
                            value={endDate}
                            className="w-full"
                            InputProps={{ className: "h-9 !rounded-sm" }}
                            onChange={(e) => handleDateChange(e, 'end')}
                        />
                    </div>

                    <div className="w-full">
                        <FormLabel className="!text-[#788999] !text-xs !font-bold">Date Range</FormLabel>
                        <Select
                            className="w-full h-9 !rounded-sm"
                            value={dateRangeOption}
                            onChange={handleDateRangeOptionChange}
                            MenuProps={{ PaperProps: { style: { maxHeight: 250, ...scrollbarStyle, }, }, }}
                        >
                            <MenuItem value="Current month">Current month</MenuItem>
                            <MenuItem value="Last month">Last month</MenuItem>
                            <MenuItem value="Last Year">Last Year</MenuItem>
                            <MenuItem value="Current Year">Current Year</MenuItem>
                            <MenuItem value="Last 12 months">Last 12 months</MenuItem>
                            <MenuItem value="Last 3 months">Last 3 months</MenuItem>
                            <MenuItem value="Last 30 days">Last 30 days</MenuItem>
                            <MenuItem value="Last 7 days">Last 7 days</MenuItem>
                            <MenuItem value="Today">Today</MenuItem>
                            <MenuItem value="YTD">YTD</MenuItem>
                            <MenuItem value="MTD">MTD</MenuItem>
                            <MenuItem value="QTD">QTD</MenuItem>
                            <MenuItem value="WTD">WTD</MenuItem>
                        </Select>
                    </div>

                    <div className="w-full">
                        <FormLabel className="!text-[#788999] !text-xs !font-bold">Channels</FormLabel>
                        <Select defaultValue="Channels" className="w-full h-9 !rounded-sm">
                            <MenuItem value="Channels">Channels</MenuItem>
                        </Select>
                    </div>
                </div>
                <div className="">
                    <FormLabel className="!text-[#788999] !text-xs !font-bold">&nbsp;</FormLabel>
                    <button
                        className="px-2 !bg-[#dcf6f6] !rounded-sm flex items-center justify-center gap-1 h-9 py-0 w-16"
                        onClick={() => {
                            setSelectedCity('');
                            setSelectedListing('');
                        }}
                    >
                        <span className="ladda-label flex items-center font-bold text-[13px] text-[#00b4b4] p-[3px] capitalize">Reset</span>
                    </button>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-5 gap-4 bg-[#F8F9FC] mb-4 py-4 px-3">
                {[
                    { label: 'Rental revenue', value: `${rentalRevenueData?.totalRevenue || 0} MAD`, isLoading: isLoadingRentalRevenue },
                    { label: 'Nights booked', value: bookedNightsData?.totalNights || 0, isLoading: isLoadingBookedNights },
                    { label: 'Occupancy rate', value: `${occupancyRateData?.occupancyRate || 0} %`, isLoading: isLoadingOccupancyRate },
                    { label: 'Average daily rate', value: `${averageDailyRateData?.averageDailyRate || 0} MAD`, isLoading: isLoadingAverageDailyRate },
                    { label: 'Average revenue per stay', value: `${averageRevenuePerStayData?.averageRevenuePerStay || 0} MAD`, isLoading: isLoadingAverageRevenuePerStay },
                ].map((item, index) => (
                    <div key={index} className="">
                        <div className="text-base font-extrabold text-black my-2">{item.label}</div>
                        {item.isLoading ? (
                            <CircularProgress sx={{ color: '#00b4b4' }} size={24} />
                        ) : (
                            <div className="text-3xl font-normal mb-2 text-[#788999]">{item.value}</div>
                        )}
                    </div>
                ))}
            </div>

            <Charts
                reservationStats={reservationStatsData?.data || []}
                channelStats={channelStatsData?.data || []}
                checkinByChannel={checkinByChannelData?.data || []}
                revenueByChannel={revenueByChannelData?.data || []}
                bookedNightsByChannel={bookedNightsByChannelData?.data || []}
                isLoadingReservationStats={isLoadingReservationStats}
                isLoadingChannelStats={isLoadingChannelStats}
                isLoadingCheckinByChannel={isLoadingCheckinByChannel}
                isLoadingRevenueByChannel={isLoadingRevenueByChannel}
                isLoadingBookedNightsByChannel={isLoadingBookedNightsByChannel}
            />
            <Upcoming
                selectedCity={selectedCity}
                selectedListing={selectedListing}
            />

        </div>
    );
};

export default Analytics;
