import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Box, Card, CardMedia, Grid, Typography } from "@mui/material";
import { Calendar, momentLocalizer,Views} from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CustomToolbar from "../components/CustomToolbar";
import MDBox from "components/MDBox";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { getCalnedarById } from '../services/serverApi.calendar';
import CheckIcon from '@mui/icons-material/Check';
import Year from "../components/yearly";
import SlotModal from '../components/ModelSlot.component';
import EventModal from '../components/ModelEvent.component';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import CalendarBarListing from '../components/CalendarbarListing';

const localizer = momentLocalizer(moment);

function MonthlyCalendarPage() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataSlot, setDataSlot] = useState(null);
  const [eventSelect, setEventSelect] = useState(null);
  const [selectedItems, setSelectedItems] = useState(['Reservation']);
  const [slotData, setSlotData] = useState([]);
  const [calenListingId, setCalenListingId] = useState();
  const [view, setView] =useState(Views.MONTH);
  const [defaultDay, setDefaultDay] = useState(moment().toDate());
  const yealyDate = useSelector((state) => state.yearDateDta?.YearDate);
  const listingId = useSelector((state) => state.yearDateDta?.ListingId);
  const slotSelectDate = useSelector((state) => state.yearDateDta?.slotDate);

  useEffect(() => {
    if (listingId) {
      setCalenListingId(listingId)
    }
  }, [listingId]);


  const getCalendarEvents = useCallback(async (calenListingId, startDate, endDate) => {

    try {
      const result = await getCalnedarById(calenListingId, startDate, endDate);
      const eventsData = result.data.data;
      setSlotData(eventsData);
      const seenReservationIds = new Set();
      const transformedEvents = eventsData.flatMap(event =>
        event.reservations
          .filter(reservation => {
            if (seenReservationIds.has(reservation.reservationId)) {
              return false;
            } else {
              seenReservationIds.add(reservation.reservationId);
              return true;
            }
          })
          .map(reservation => ({
            ...event,
            ...reservation,
          }))
      );
  
      setEvents(transformedEvents);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const givenDate = moment(new Date());
    const previousMonth = givenDate.clone().subtract(1, 'month').format('YYYY-MM-24');
    const nextCurrentMonth = givenDate.clone().add(1, 'month').format('YYYY-MM-08');
    if(calenListingId){
      getCalendarEvents(calenListingId, previousMonth, nextCurrentMonth);

    }
  }, [calenListingId]);
  useEffect(() => {
    if (yealyDate) {
      const givenDate = moment.isMoment(yealyDate) ? yealyDate : moment(yealyDate);
      const previousMonth = givenDate.clone().subtract(1, 'month').format('YYYY-MM-24');
      const nextCurrentMonth = givenDate.clone().add(1, 'month').format('YYYY-MM-08');
    ;
      if(calenListingId){
        getCalendarEvents(calenListingId, previousMonth, nextCurrentMonth);
        setDefaultDay(givenDate)  
      }
    }
  }, [yealyDate]); // delete from array calenListingId

  const filteredEvents = useMemo(() => {
    if (!selectedItems.includes('Reservation')) {
      return [];
    }
    return events;
  }, [events, selectedItems]);

  const handleSelectedItemsChange = useCallback((newSelectedItems) => {
    setSelectedItems([...newSelectedItems]);
  }, []);

  const { defaultDate, formats } = useMemo(() => ({
    defaultDate: defaultDay,
    formats: {
      monthHeaderFormat: (date, culture, localizer) =>
        localizer.format(date, `YYYY-MM-01`, culture),
      dateFormat: (date, culture, localizer) =>
        localizer.format(date, "D", culture),
    },
  }), [defaultDay]);

  const CustomCellWrapper = useCallback((props) => {
    
    const formattedDate = moment(props.value).format('YYYY-MM-DD');
    const slotItem = slotData?.find(slot => moment(slot.date).format('YYYY-MM-DD') === formattedDate);

    if (!slotItem) {
      return <div style={{ flex: '1 0' }} />;
    }
    const isSelected = slotSelectDate?.includes(moment(slotItem.date).format('YYYY-MM-DD'));

    return (
      <div style={{
        flex: '1 0',
        textAlign: 'right',
        border: isSelected ? '1px solid #00b4b4' : '1px solid #eaecf4',
        color: '#788999',
        fontStyle: 'italic',
        fontSize: '12px',
        borderRadius: '3px',
        marginTop: '2px',
        marginRight: '2px',
        lineHeight: '18px',
        padding: '0 6px 0 4px',
      }} >
        {selectedItems.includes('All tasks') && (
          <div style={{ width: '50px', color: '#03d903', float: 'left', margin: '12px 0px 0px 12px' }}>
            <CheckIcon />
          </div>
        )}
        <div style={{
          background: '#f8f9fc',
          width: '65px',
          float: 'right',
          border: '1px solid #eaecf4',
          marginTop: '13px',
          marginRight: '-3px',
          textAlign: 'center'
        }}>
          {slotItem.price} MAD
        </div>
        {selectedItems.includes('Minimum stay') && (
          <div style={{
            background: '#f8f9fc',
            width: '65px',
            float: 'right',
            border: '1px solid #eaecf4',
            marginTop: '36px',
            marginRight: '-64px',
            textAlign: 'center'
          }}>
            {slotItem.minimumStay} min days
          </div>
        )}
      </div>
    );
  }, [selectedItems, slotData,slotSelectDate]);

  const toolbarComponent = useCallback((props) => (
    <CustomToolbar {...props} onSelectedItemsChange={handleSelectedItemsChange} />
  ), [handleSelectedItemsChange]);

  const components = useMemo(() => ({
    toolbar: toolbarComponent,
    dateCellWrapper: CustomCellWrapper,
    month: {
      event: (data) => (
        <div className="parallelogram" style={{
          background: '#00b4b4',
          padding: '12px 11px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '13px',
          fontWeight: '600',
          lineheight: '1.2',
        }}>
          {data?.event?.guestName} * <span>{data?.event?.numberOfGuests} guest</span>
        </div>
      ),
    },
  }), [CustomCellWrapper, toolbarComponent]);

  const handleEventSelect = ((event) => {
    setEventSelect(event);
  });

  const handleEventClose = (() => {
    setEventSelect(null);
  });

  const handleSlotSelect = ((data) => {
    setDataSlot(data);

  });

  const handleDateClose = (() => {
    setDataSlot(null);
  });

  const onNavigate = ((newDate) => {
    setDefaultDay(moment(newDate).toDate());
  });
  const onRangeChange = useCallback((range) => {
    const start = moment(range.start).format('YYYY-MM-DD');
    const end = moment(range.end).format('YYYY-MM-DD');
    getCalendarEvents(calenListingId,start, end);
  }, [calenListingId]);
  const chanageview = useCallback((newView) => {
      setView(newView);
      if(newView ==='year'){
        const dateYear = moment(new Date()).format('YYYY')
      }
    },[setView]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <ToastContainer position="top-right" autoClose={3000} />
        <div className="calendar">
        <CalendarBarListing />

          <Calendar
            key={defaultDay}
            defaultDate={defaultDate}
            formats={formats}
            localizer={localizer}
            components={components}
            events={filteredEvents}
            views={{ year: Year, month: true }}
            view={view}
            onView={chanageview}
            startAccessor="arrivalDate"
            endAccessor="departureDate"
            onSelectEvent={handleEventSelect}
            onSelectSlot={(slotInfo) => {
              const { start } = slotInfo;
              const formattedDate = moment(start).format('YYYY-MM-DD');
              const slotIndex = slotData.findIndex(slot => moment(slot.date).format('YYYY-MM-DD') === formattedDate);
            
              if (slotIndex !== -1) {
                const slotItem = slotData[slotIndex];
                handleSlotSelect(slotItem);
              }
            }}
            selectable
            onNavigate={onNavigate}
            onRangeChange={onRangeChange}
            popup
          />
        </div>
        {dataSlot && (
          <SlotModal dataSlot={dataSlot} onClose={handleDateClose}  dataSlotSelect={slotData} setSlotData={setSlotData} />
        )}
        {eventSelect && (
          <EventModal eventSelect={eventSelect} onClose={handleEventClose} />
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default MonthlyCalendarPage;
