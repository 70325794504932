import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getUnitById } from '../services/serverApi.ProjectUnits';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import { Box, Container, Card, CardMedia, CardContent, Typography, IconButton, MobileStepper, Grid, useTheme, useMediaQuery, Button } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight, ArrowBack, Edit as EditIcon } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import FlagIcon from '@mui/icons-material/FlagCircle';
import StraightenIcon from '@mui/icons-material/Straighten';
import PinDropIcon from '@mui/icons-material/PinDrop';
import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PoolIcon from '@mui/icons-material/Pool';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import DeckIcon from '@mui/icons-material/Deck';

const UnitDetailsPage = () => {
    const { unitId } = useParams();
    const navigate = useNavigate();
    const [unit, setUnit] = useState(null);
    const [unitActiveStep, setUnitActiveStep] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        fetchUnitDetails();
    }, [unitId]);

    const fetchUnitDetails = async () => {
        try {
            const response = await getUnitById(unitId);
            setUnit(response.data.unit);
        } catch (error) {
            console.error('Error fetching unit details:', error);
        }
    };

    const handleNext = () => {
        setUnitActiveStep((prevActiveStep) => (prevActiveStep + 1) % unit.images.length);
    };

    const handleBack = () => {
        setUnitActiveStep((prevActiveStep) => (prevActiveStep - 1 + unit.images.length) % unit.images.length);
    };

    const handleNavigateBack = () => {
        navigate(-1);
    };

    const handleEditUnit = () => {
        navigate(`/admin/units/edit/${unitId}`);
    };

    if (!unit) {
        return <div>Loading...</div>;
    }

    const maxSteps = unit.images.length;

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box
                component="main"
                sx={{ py: { xs: 2, sm: 3, md: 4 }, backgroundColor: theme.palette.grey[100], minHeight: '100vh' }}>
                <Button
                    startIcon={<ArrowBack />}
                    onClick={handleNavigateBack}
                    className="!text-medium-aquamarine !hover:text-dark-aquamarine"
                >
                </Button>

                <Container maxWidth="lg">
                    <Card sx={{ mb: 6, borderRadius: 2, boxShadow: theme.shadows[5] }}>
                        <Box sx={{ position: 'relative' }}>
                            <CardMedia component="img"
                                sx={{ height: { xs: '300px', sm: '400px', md: '500px' }, objectFit: 'cover', width: '100%', margin: '0', padding: '16px', borderRadius: '20px' }}
                                image={unit.images[unitActiveStep].url}
                                alt={`Unit Image ${unitActiveStep + 1}`}
                            />
                            <Box sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', width: '100%', display: 'flex', justifyContent: 'space-between', px: 2 }}>
                                <IconButton onClick={handleBack} disabled={unitActiveStep === 0}
                                    sx={{ color: 'white', bgcolor: 'rgba(0,0,0,0.5)', '&:hover': { bgcolor: 'rgba(0,0,0,0.7)' }, size: isMobile ? 'small' : 'medium' }}>
                                    <KeyboardArrowLeft />
                                </IconButton>
                                <IconButton onClick={handleNext} disabled={unitActiveStep === maxSteps - 1}
                                    sx={{ color: 'white', bgcolor: 'rgba(0,0,0,0.5)', '&:hover': { bgcolor: 'rgba(0,0,0,0.7)' }, size: isMobile ? 'small' : 'medium' }}>
                                    <KeyboardArrowRight />
                                </IconButton>
                            </Box>
                            <MobileStepper steps={maxSteps} position="static" activeStep={unitActiveStep}
                                sx={{ position: 'absolute', bottom: 0, width: '100%', bgcolor: 'rgba(0,0,0,0.5)', '& .MuiMobileStepper-dot': { bgcolor: 'rgba(255,255,255,0.5)' }, '& .MuiMobileStepper-dotActive': { bgcolor: 'white' } }}
                                nextButton={<Box />} backButton={<Box />}
                            />
                        </Box>
                        <CardContent sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mb: 4 }}>
                                <Typography variant="h2" align="center" className="!text-medium-aquamarine"
                                    sx={{ fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' }, fontWeight: 600 }}>
                                    {unit.unitName}
                                </Typography>
                                <IconButton
                                    onClick={handleEditUnit}
                                    sx={{ color: theme.palette.primary.main }}
                                >
                                    <EditIcon className="text-medium-aquamarine" />
                                </IconButton>
                            </Box>
                            <Grid container spacing={4}>
                                {[
                                    { label: <HomeIcon />, value: unit.propertyType },
                                    { label: <FlagIcon />, value: unit.condition },
                                    { label: <StraightenIcon />, value: `${unit.totalArea} m²` },
                                    { label: <PinDropIcon />, value: `${unit.livingArea} m²` },
                                    { label: <BedIcon />, value: unit.numberOfBedrooms },
                                    { label: <BathtubIcon />, value: unit.numberOfBathrooms },
                                    { label: <AttachMoneyIcon />, value: `$${unit.salePrice.toLocaleString()}` },
                                    { label: <MonetizationOnIcon />, value: `$${unit.pricePerSqm.toLocaleString()}` },
                                    { label: <HomeWorkIcon />, value: unit.floor },
                                    { label: <DeckIcon />, value: `${unit.balconyArea} m²` },
                                    { label: <PoolIcon />, value: unit.swimmingPool },
                                ].map((item, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <Box
                                            sx={{ p: 2, bgcolor: theme.palette.background.default, borderRadius: 1, height: '100%' }}>
                                            <Typography variant="subtitle1" gutterBottom
                                                sx={{ color: theme.palette.text.secondary, fontWeight: 500 }}>
                                                {item.label}
                                            </Typography>
                                            <Typography variant="body1"
                                                sx={{ fontWeight: 600, color: theme.palette.text.primary }}>
                                                {item.value}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </DashboardLayout>
    );
};

export default UnitDetailsPage;