import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';



export function getReservationDetails(reservationNumber) {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/reservations/by-id/${reservationNumber}`);
}

export const getTasks = (startDate, endDate, listingIds) => {
  const params = new URLSearchParams({
    startDate,
    endDate,
    listingIds: listingIds.join(',')
  });

  return axios.get(`${MICROSERVICE_BASE_URL.SRV_TASK}/tasks/get-tasks`, { params });
};



export function getChatHistory(page, limit, conversationPage = 0, conversationLimit = 1000) {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/user-chat-history?page=${page}&limit=${limit}&paged=true&conversation_page=${conversationPage}&conversation_limit=${conversationLimit}`);

}


export const SOCKET_URL = 'https://dev.sojori.com/';
export const SOCKET_PATH = '/api/v1/sockets/connect';



export function getDocById(id) {
  return axios.get(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/user-chat-history/by-id/${id}`);
}


export function pushMessage(conversationId, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/user-chat-history/push-msg/${conversationId}`, data);
}


export function updateOpenAIMessage(messageId, content) {
  return axios.put(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/user-chat-history/update-msg/${messageId}`, { content });
}

export function updateUserChatHistoryReply(id, data) {
  return axios.put(`${MICROSERVICE_BASE_URL.SRV_ADMIN}/user-chat-history/update-reply/${id}`, data);
}
