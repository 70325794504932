import React, { useState, useEffect } from 'react';
import { MultiCalendar } from '../components/multiCalendarV2/MultiCalendar';
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import { getMultiListing } from '../services/serverApi.calendar';
import MDBox from "components/MDBox";
import Pagination from 'components/GlobalTable/Pagination';
import FilterCalendar from '../components/multiCalendarV2/FilterCalendar';
import 'react-toastify/dist/ReactToastify.css';

function MonthlyCalendarPage() {
  const [listings, setListings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItems, setSelectedItems] = useState(['Reservation']);
  const [filters, setFilters] = useState({
    city: '',
    country: '',
    tags: [],
    units: 'All units',
    match: '',
  });
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  useEffect(() => {
    fetchListings();
  }, [page, limit, filters]);

  const fetchListings = async () => {
    setIsLoading(true);
    try {
      const response = await getMultiListing(page, limit, filters);
      if (response && response.data && response.data.data && Array.isArray(response.data.data)) {
        const formattedListings = response.data.data.map(listing => ({
          id: listing._id,
          name: listing.name,
          calendarData: listing.calendarData || []
        }));
        console.log('Formatted Listings:', formattedListings);
        setListings(formattedListings);
        setTotalCount(response.data.total || 0);
        setIsNextDisabled((page + 1) * limit >= response.data.total);
        setError(null);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      setListings([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters, ...newFilters };
      if (newFilters.city) updatedFilters.city = newFilters.city;
      if (newFilters.country) updatedFilters.country = newFilters.country;
      return updatedFilters;
    });
    setPage(0);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setPage(0);
  };

  const handleSearchQueryChange = (query) => {
    setFilters(prevFilters => ({ ...prevFilters, match: query }));
    setPage(0);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <div className="monthly-calendar-container">
          <FilterCalendar
            onFilterChange={handleFilterChange}
            onSearchQueryChange={handleSearchQueryChange}
            onSelectedItemsChange={setSelectedItems}
            listingsCount={listings.length}
          />
          <MultiCalendar 
            listings={listings} 
            selectedItems={selectedItems} 
            isLoading={isLoading}
          />
          <Pagination
            page={page}
            onPageChange={handlePageChange}
            isNextDisabled={isNextDisabled}
            limit={limit}
            onLimitChange={handleLimitChange}
            rowsPerPageOptions={[10, 25, 50, 100]}
          />
        </div>
      </MDBox>
    </DashboardLayout>
  );
}

export default MonthlyCalendarPage;