export const checkInOptions = [
    { value: "Someone is available 24 hours a day to let guests in", label: "Doorman entry" },
    { value: "Lockbox", label: "Lockbox" },
    { value: "Smart lock", label: "Smart lock" },
    { value: "A keypad must be available 24 hours a day to entry to your listing for the guests", label: "Keypad" },
    { value: "Host greets you", label: "Host check-in" },
    { value: "Other", label: "Other check-in" },
];

export const instantBookableOptions = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" }
];
export const cleanlinessStatusOptions = [
    { value: "Not Set", label: "Not Set" },
    { value: "Clean", label: "Clean" },
    { value: "Not Clean", label: "Not Clean" }
];

export const RoomType = [
    { value: "private_room", label: "Private room" },
    { value: "shared_room", label: "Shared room" },
    { value: "entire_home", label: "Entire home" }
];

export const BathroomType = [
    { value: "private", label: "Private" },
    { value: "shared", label: "Shared" },
];

export const generateNumberOptions = (maxNumber) => {
    const options = [];
    for (let i = 1; i <= maxNumber; i++) {
        options.push({ value: i.toString(), label: i.toString() });
    }
    return options;
};

export const numberOptions = generateNumberOptions(40);


export const generateHoursOptions = (maxNumber) => {
    const optionsDays = [];
    for (let i = 0; i <= maxNumber; i++) {
        const label = i === 0 ? '0 days' :
            i === 1 ? '1 day' :
                `${i} days`;
        optionsDays.push({
            value: (i === 0 ? 0 : i * 24).toString(),
            label: label
        });
    }
    return optionsDays;
};


export const daysOptions = generateHoursOptions(31);
export const BookingEngine = generateHoursOptions(14);
export const BookiLeadTime = generateHoursOptions(3);



export const AirbnbAdvance = [
    { value: "0", label: "Same day (customizable cutoff hour)" },
    { value: "24", label: "At least 1 day's notice" },
    { value: "48", label: "At least 2 day's notice" },
    { value: "72", label: "At least 3 day's notice" },
    { value: "168", label: "At least 7 day's notice" },

];


export const preparationTimeOptions = [
    { value: "none", label: "none" },
    { value: "block1", label: "Block 1 night before and after each reservation" },
    { value: "block2", label: "Block 2 nights before and after each reservation" }
];

export const airbnbInstantBookingOptions = [
    { value: "Everyone", label: "Everyone" },
    { value: "Experienced", label: "Experienced" },
    { value: "Government ID", label: "Government ID" },
    { value: "Experienced guest with government ID", label: "Experienced guest with government ID" }
];

export const handlePaymentOptions = [
    { value: "No", label: "No" },
    { value: "Yes", label: "Yes" }
];

export const Cancellation = [
    { value: "Batter strict with grace period", label: "100% refund up to 30 days before arrival, 50% refund up to 7 days before arrivel"},
    { value: "Flexible", label: "100% refund up to to 1 days before arrival" },
    { value: "Moderate", label: "refund up to 5 days before arrival, 50% refund up to 1 days before arrival"},
    { value: "Strict 14 with grace period", label: "100% refund up to 14 days before arrival, 50% refund up to 7 days before arrival"},
    { value: "Super strict 30", label: "50% refund up to 30 days arrival"},
    { value: "Super strict 60", label: "50% refund up to 60 days arrival"},
];

export const Marriott = [
    { value: "Flexible", label: "100% refund up to to 14 days before arrival" },
    { value: "Moderate", label: "100% refund up to 30 days before arrival"},
    { value: "Firm", label: "100% refund up to 60 days before arrival"},
    { value: "Strict", label: "50% refund up to 90 days arrival"},
];

export const defaultValues = {
    TS_CLEAN: [
      { start: 10, end: 12 },
      { start: 11, end: 13 },
      { start: 12, end: 14 },
      { start: 13, end: 15 },
      { start: 14, end: 16 }
    ],
    TS_RECEPT: [
      { start: 10, end: 12 },
      { start: 11, end: 13 },
      { start: 12, end: 14 },
      { start: 13, end: 15 },
      { start: 14, end: 16 }
    ]
  };