import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    InputAdornment,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
} from '@mui/material';
import { Search, FilterList } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { getcities, getcountries } from "../../services/serverApi.calendar";
import FilterItems from './FilterItems';
import { Eraser } from 'lucide-react';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#ffffff',
        borderRadius: '8px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
        padding: theme.spacing(2),
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    filterToggle: {
        color: '#00b4b4',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    filterContent: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
        gap: theme.spacing(2),
        width: '60%',
    },
    formControl: {
        width: '100%',
    },
    clearButton: {
        marginLeft: theme.spacing(2),
        width: '50%',

    },
}));

const FilterCalendar = ({ onFilterChange, onSearchQueryChange, onSelectedItemsChange, listingsCount }) => {
    const classes = useStyles();
    const [showFilter, setShowFilter] = useState(false);
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const options = ['Reservation', 'Minimum stay'];
    const [selectedItems, setSelectedItems] = useState(['Reservation']);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const [countriesData, citiesData] = await Promise.all([
                getcountries(),
                getcities()
            ]);
            setCountries(countriesData.data);
            setCities(citiesData.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    const handleFilterChange = () => {
        onFilterChange({
            country: selectedCountry,
            city: selectedCity,
            selectedItems: selectedItems,
        });
    };

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        onSearchQueryChange(query);
    };

    const handleCountryChange = (e) => {
        const countryId = e.target.value;
        setSelectedCountry(countryId);
        setSelectedCity('');
        handleFilterChange();
    };

    const handleCityChange = (e) => {
        const cityId = e.target.value;
        setSelectedCity(cityId);
        handleFilterChange();
    };

    const handleClearFilter = () => {
        setSelectedCountry('');
        setSelectedCity('');
        setSelectedItems(['Reservation']);
        setSearchQuery('');
        onFilterChange({
            country: '',
            city: '',
            selectedItems: ['Reservation'],
        });
        onSearchQueryChange('');
        onSelectedItemsChange(['Reservation']);
    };

    useEffect(() => {
        handleFilterChange();
    }, [selectedCountry, selectedCity, selectedItems]);

    return (
        <Box className={classes.root}>
            <Box className={classes.header}>
                <div className="flex gap-1">
                    <div onClick={toggleFilter} style={{ cursor: 'pointer' }} className={`flex items-center p-2 rounded-md shadow-sm ${showFilter ? 'bg-[#020807] bg-opacity-50 text-white' : 'bg-white text-gray-700'}`}>
                        <FilterList fontSize="small" />
                        <span className='ml-2 text-sm font-medium'>Filters</span>
                    </div>
                    {showFilter && (
                        <div onClick={handleClearFilter} style={{ cursor: 'pointer' }} className='flex items-center p-2 rounded-md shadow-sm border-1 bg-[#66dac3] text-white'>
                            <Eraser size={20} />
                            <span className='ml-2 text-sm font-medium'>Clear</span>
                        </div>
                    )}
                </div>
                <Box display="flex" alignItems="center">
                    <Typography variant="body2" color="textSecondary" sx={{ mr: 2 }}>
                        Show ({listingsCount})
                    </Typography>
                    <TextField
                        placeholder="Search by listing"
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />

                </Box>
            </Box>
            {showFilter && (
                <Box className={classes.filterContent}>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="country-select-label">Country</InputLabel>
                        <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={selectedCountry}
                            label="Country"
                            onChange={handleCountryChange}
                        >
                            <MenuItem value="">
                                <em>Select country</em>
                            </MenuItem>
                            {countries.map((country) => (
                                <MenuItem key={country.id} value={country.name}>
                                    {country.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="city-select-label">City</InputLabel>
                        <Select
                            labelId="city-select-label"
                            id="city-select"
                            value={selectedCity}
                            label="City"
                            onChange={handleCityChange}
                        >
                            <MenuItem value="">
                                <em>Select city</em>
                            </MenuItem>
                            {cities.map((city) => (
                                <MenuItem key={city.id} value={city.name}>
                                    {city.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FilterItems
                        selectedItems={selectedItems}
                        options={options}
                        onItemsChange={(newItems) => {
                            setSelectedItems(newItems);
                            onSelectedItemsChange(newItems);
                        }}
                    />
                </Box>
            )}
        </Box>
    );
};

export default FilterCalendar;