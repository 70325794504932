import React, { useState, useEffect } from 'react';
import { getPropertyTypes, updatePropertyType } from '../services/serverApi.listing';
import GlobalTable from 'components/GlobalTable/GlobalTable';
import ModifyPropertyType from './ModifyPropertyType';
import AddPropertyType from './AddPropertyType';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@mui/icons-material/Edit';
import { CircularProgress, Typography, Button, Switch } from '@mui/material';

function PropertyType() {
    const [propertyTypes, setPropertyTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openModifyDialog, setOpenModifyDialog] = useState(false);
    const [selectedPropertyType, setSelectedPropertyType] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [error, setError] = useState(null);

    const fetchPropertyTypes = async () => {
        setIsLoading(true);
        try {
            const response = await getPropertyTypes();
            setPropertyTypes(response.data.data);
        } catch (error) {
            console.error('Error fetching property types:', error);
            setError('Failed to fetch property types. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchPropertyTypes();
    }, []);

    const handleOpenModifyDialog = (index, propertyType) => {
        setSelectedPropertyType(propertyType);
        setSelectedIndex(index);
        setOpenModifyDialog(true);
    };

    const handleCloseModifyDialog = () => {
        setOpenModifyDialog(false);
        setSelectedPropertyType(null);
        setSelectedIndex(null);
    };

    const handleOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    const handleManageRoomTypeChange = async (id, currentValue) => {
        try {
            const updatedValue = !currentValue;
            await updatePropertyType(id, { manageRoomType: updatedValue });
            setPropertyTypes(propertyTypes.map(pt =>
                pt._id === id ? { ...pt, manageRoomType: updatedValue } : pt
            ));
            toast.success('Property type updated successfully');
        } catch (error) {
            console.error('Error updating property type:', error);
            toast.error('Failed to update property type');
        }
    };

    const editSwitch = (key, value, id) => {
        const updatedPt = propertyTypes.map(pt => {
          if (pt._id === id) {
            return { ...pt, [key]: value };
          }
          return pt;
        });
      
        setPropertyTypes(updatedPt);
      
        const ptToUpdate = updatedPt.find(Pt => Pt._id === id);
        if (ptToUpdate) {
          const { _id, ...itemToUpdate } = ptToUpdate;
          updatePropertyType(_id, itemToUpdate)
            .then(({ data }) => {
              console.log(data);
              toast.success(`${key} has been updated`);
            })
            .catch((error) => {
              console.error(error.message);
              toast.error(`Failed to update ${key}`);
            });
        }
      };

      

    const columns = [
        { header: "Name", body: (rowData) => <span>{rowData.name}</span> },
        { 
            header: "Manage Room Type", 
            body: (rowData) => (
                <Switch 
                    checked={rowData.manageRoomType} 
                    onChange={(e) => editSwitch('manageRoomType', e.target.checked, rowData._id)}
                    color="primary" 
                />
            ) 
        },
        {
            header: "Action",
            body: (rowData, rowIndex) => (
                <div className="flex gap-1">
                    <button
                        className="px-2 py-1 bg-medium-aquamarine !rounded-md"
                        onClick={() => handleOpenModifyDialog(rowIndex, rowData)}
                    >
                        <EditIcon className="text-white" />
                    </button>
                </div>
            ),
        },
    ];

    return (
        <div className="card p-4">
            <Typography variant="h4" component="h1" className="mb-4" gutterBottom>
                Property Types Management
            </Typography>
            <div className="mb-4">
                <Button onClick={handleOpenAddDialog} className="float-right !bg-medium-aquamarine text-white">Add Property Type</Button>
            </div>
            <div>
                <div className="w-full">
                    {isLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <CircularProgress style={{ color: '#00b4b4' }} />
                        </div>
                    ) : (
                        <div style={{ height: '500px', overflow: 'auto' }}>
                            <GlobalTable
                                data={propertyTypes}
                                columns={columns}
                                hasPagination={false}
                                isLoading={isLoading}
                            />
                        </div>
                    )}
                </div>
            </div>
            <ToastContainer position="top-right" autoClose={3000} />
            {selectedPropertyType && (
                <ModifyPropertyType
                    open={openModifyDialog}
                    onClose={handleCloseModifyDialog}
                    setPropertyTypes={setPropertyTypes}
                    propertyTypes={propertyTypes}
                    index={selectedIndex}
                    dataPropertyType={selectedPropertyType}
                />
            )}
            <AddPropertyType
                open={openAddDialog}
                onClose={handleCloseAddDialog}
                setPropertyTypes={setPropertyTypes}
                propertyTypes={propertyTypes}
            />
        </div>
    );
}

export default PropertyType;