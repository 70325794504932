import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';


export function getMinutWebhook(page, limit, search_text) {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/minut/get-minut-webhook?page=${page}&limit=${limit}&paged=true&search_text=${search_text}`);
}

export function getListings() {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_LISTING}/listings`);
}

export function createUpdateWebhook(data) {
    return axios.post(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/minut/create-update-webhook`, data);
}

export function getWebhookConfigs() {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/minut/get-webhook-configs`);
}

export const SOCKET_URL = 'https://dev.sojori.com';
export const SOCKET_PATH = '/api/v1/sockets/connect';



export function updateMinutEventHandler(data) {
    return axios.put(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/minut/update-minut-event-handler`, data);
}

export function getMinutEventHandler() {
    return axios.get(`${MICROSERVICE_BASE_URL.SRV_RESERVATION}/minut/get-minut-event-handler`);
}