import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';
import { generateRandomString } from '../../helpers';

import axios from 'axios';

// Define your initial state
const initialState = {
  iconUrl: "",
  error: "",
  loading: false,
  newUpload: false
};

// Create an async thunk for uploading image
export const uploadImageToAPI = createAsyncThunk(
  'image/upload',
  async (payload, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const formData = new FormData();
      formData.append('media', payload.file);
      formData.append('type', payload.folder);
      formData.append('name', generateRandomString(15));

      const response = await axios.post(MICROSERVICE_BASE_URL.UPLOAD_IMAGE, formData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Create a slice to manage state and actions
const imageSlice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    uploadImageSuccessAction: (state, action) => {
      state.iconUrl = action.payload;
      state.loading = false;
      state.newUpload = true;
    },
    uploadImageFailAction: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    uploadImageResetAction: (state) => {
      state.iconUrl = "";
      state.error = "";
      state.loading = false;
      state.newUpload = false;
    },
    defaultImagesAction: (state, action) => {
      // Handle setting default images if needed
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(uploadImageToAPI.pending, (state) => {
        state.loading = true;
        state.error = "";
        state.iconUrl = "";
        state.newUpload = false;
      })
      .addCase(uploadImageToAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.iconUrl = action.payload;
        state.newUpload = true;
      })
      .addCase(uploadImageToAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

// Export actions and reducer
export const { uploadImageSuccessAction, uploadImageFailAction, uploadImageResetAction, defaultImagesAction } = imageSlice.actions;
export default imageSlice.reducer;

// Example usage in your component:
// dispatch(uploadImageToAPI(payload));
