import React, { useState } from 'react';
import { 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Typography, 
  Card, 
  CardContent, 
  Chip, 
  Box, 
  Avatar 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import InfoIcon from '@mui/icons-material/Info';
import { getEventIcon, getEventColor } from './eventConfig'; 

const MinutEvent = ({ minutEvent }) => {
  const [expanded, setExpanded] = useState(false);

  if (!minutEvent || minutEvent.length === 0) {
    return (
      <Card variant="outlined" className="my-4">
        <CardContent>
          <Box display="flex" alignItems="center" justifyContent="center" height={200}>
            <InfoIcon fontSize="large" color="info" sx={{ mr: 1 }} />
            <Typography variant="h6">No Minut events available at this time.</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  const eventTypes = [...new Set(minutEvent.map(event => event.type))];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={{ mt: 3 }}  className="!my-4">
      {eventTypes.map((eventType, index) => {
        const eventsOfType = minutEvent.filter(event => event.type === eventType);
        return (
          <Accordion 
            key={index} 
            expanded={expanded === eventType} 
            onChange={handleChange(eventType)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
            >
              <Box display="flex" alignItems="center" width="100%">
                <Avatar sx={{ bgcolor: `${getEventColor(eventType)}.main`, mr: 2 }}>
                  {getEventIcon(eventType)}
                </Avatar>
                <Typography sx={{ flexGrow: 1 }}>{eventType.replace(/_/g, ' ')}</Typography>
                <Chip label={eventsOfType.length} color={getEventColor(eventType)} size="small" />
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Timeline>
                {eventsOfType.map((event, eventIndex) => (
                  <TimelineItem key={eventIndex}>
                    <TimelineSeparator>
                      <TimelineDot color={getEventColor(event.type)}>
                        {getEventIcon(event.type)}
                      </TimelineDot>
                      {eventIndex !== eventsOfType.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Card variant="outlined" sx={{ mb: 2 }}>
                        <CardContent>
                          <Typography variant="subtitle1" component="div">
                            {new Date(event.created_at).toLocaleString()}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Device ID: {event.device_id}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Home ID: {event.home_id}
                          </Typography>
                        </CardContent>
                      </Card>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default MinutEvent;