import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

function LinkTab(props) {
  return <Tab component={NavLink} {...props} />;
}

LinkTab.propTypes = {
  selected: PropTypes.any,
};

export default function CalendarTabs({num}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(num);

  React.useEffect(() => {
    switch (location.pathname) {
      case '/admin/calendar/multiV2':
        setValue(0);
        break;
      case '/admin/calendar/monthly':
        setValue(1);
        break;
      // case '/admin/Calendar/Yearly':
      //   setValue(2);
      //   break;
      default:
        setValue(1);
        break;
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    switch (newValue) {
      case 0:
        navigate('/admin/calendar/multiV2');
        break;
      case 1:
        navigate('/admin/calendar/monthly');
        break;
      // case 2:
      //   navigate('/admin/Calendar/Yearly');
      //   break;
      default:
        break;
    }
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%'}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={value} 
          onChange={handleChange} 
          aria-label="nav tabs example"
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: '#00b4b4',
            },
            '& .MuiTab-root': {
              color: 'black',
              fontWeight: 600,
              textTransform: "none"
            },
            '& .active': {
              color: '#00b4b4 !important',
            },
          }}
        >
          <LinkTab label="Multi" to="/admin/calendar/multiV2" />
          <LinkTab label="Monthly" to="/admin/calendar/monthly" />
          {/* <LinkTab label="Yearly" to="/admin/Calendar/Yearly" /> */}
        </Tabs>
      </Box>
    </Box>
  );
}