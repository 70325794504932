import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Box, Typography, Button, CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { uploadImageResetAction, uploadImageToAPI } from '../../../../../redux/slices/UploadSlice';
import { CloudUpload, Edit, X, Check, TriangleAlert } from 'lucide-react';
import { toast } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';


const MediaContainer = styled(Box)(() => ({
  padding: '1rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 8,
  height: 120,
  width: 340,
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: 'white',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}));

const RoomTypeImages = ({ roomType, index, handleImageUpload, handleRemoveImage, formik }) => {
  const dispatch = useDispatch();
  const upload = useSelector((state) => state.uploadData);
  const { loading, iconUrl } = upload;
  const fileInput = useRef(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [uploadedImages, setUploadedImages] = useState({});

  const isBase64Image = (url) => {
    return url?.startsWith('data:image');
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (!file) return;

    const uploadIndex = editingIndex !== null ? editingIndex : 0;
    setLoadingIndex(uploadIndex);

    const reader = new FileReader();
    reader.onloadend = () => {
      const updatedImages = [...roomType.roomTypeImages];

      if (editingIndex !== null) {
        const currentSortOrder = updatedImages[editingIndex].sortOrder;
        updatedImages[editingIndex] = {
          ...updatedImages[editingIndex],
          url: reader.result,
          sortOrder: currentSortOrder,
        };
      } else {
        updatedImages.forEach(img => {
          img.sortOrder = img.sortOrder + 1;
        });

        updatedImages.unshift({
          url: reader.result,
          sortOrder: 1,
        });
      }

      handleImageUpload(index, 'roomTypeImages', updatedImages);
    };
    reader.readAsDataURL(file);
    dispatch(uploadImageToAPI({ file, folder: 'listing' }));
  }, [roomType, handleImageUpload, index, editingIndex, dispatch]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxSize: 52428800,
  });

  const moveImage = useCallback((dragIndex, hoverIndex) => {
    const updatedImages = [...roomType.roomTypeImages];
    const draggedImage = updatedImages[dragIndex];
    const targetImage = updatedImages[hoverIndex];

    const tempSortOrder = draggedImage.sortOrder;
    draggedImage.sortOrder = targetImage.sortOrder;
    targetImage.sortOrder = tempSortOrder;

    updatedImages.sort((a, b) => a.sortOrder - b.sortOrder);
    handleImageUpload(index, 'roomTypeImages', updatedImages);
  }, [roomType.roomTypeImages, handleImageUpload, index]);

  const handleClick = (imageIndex) => {
    setEditingIndex(imageIndex);
    fileInput.current.click();
  };

  const handleRemoveFile = (imageIndex) => {
    const updatedImages = [...roomType.roomTypeImages];
    updatedImages[imageIndex] = {
      ...updatedImages[imageIndex],
      url: '',
    };
    handleImageUpload(index, 'roomTypeImages', updatedImages);
    setUploadedImages(prev => ({ ...prev, [imageIndex]: false }));
  };

  const handleRetryUpload = (imageIndex, imageUrl) => {
    fetch(imageUrl)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], "image.jpg", { type: blob.type });
        setLoadingIndex(imageIndex);
        dispatch(uploadImageToAPI({ file, folder: 'listing' }));
      })
      .catch(() => {
        toast.error('Failed to retry upload');
      });
  };

  useEffect(() => {
    dispatch(uploadImageResetAction());
  }, [dispatch]);

  useEffect(() => {
    if (iconUrl.url && loadingIndex !== null) {
      const updatedImages = [...roomType.roomTypeImages];
      const currentSortOrder = updatedImages[loadingIndex].sortOrder;

      updatedImages[loadingIndex] = {
        ...updatedImages[loadingIndex],
        url: iconUrl.url,
        sortOrder: currentSortOrder,
      };

      handleImageUpload(index, 'roomTypeImages', updatedImages);
      setUploadedImages(prev => ({ ...prev, [loadingIndex]: true }));
      setLoadingIndex(null);
      setEditingIndex(null);
      dispatch(uploadImageResetAction());
      toast.success('Image uploaded');
    }
  }, [iconUrl.url, loadingIndex, handleImageUpload, index, roomType.roomTypeImages, dispatch]);

  const DraggableImage = ({ imageIndex, image }) => {
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
      accept: 'image',
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        }
      },
      hover(item, monitor) {
        if (!ref.current) return;
        const dragIndex = item.index;
        const hoverIndex = imageIndex;
        if (dragIndex === hoverIndex) return;
        moveImage(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: 'image',
      item: () => ({ id: image.id, index: imageIndex, sortOrder: image.sortOrder }),
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const opacity = isDragging ? 0.4 : 1;
    drag(drop(ref));

    const needsUpload = isBase64Image(image.url);

    return (
      <Grid item xs={12} sm={6} md={4} lg={3} ref={ref} style={{ opacity }} data-handler-id={handlerId}>
        <Box className="bg-gray-50 hover:shadow-lg relative rounded-lg" sx={{ height: '100%' }}>
          <Box className="flex flex-col gap-2 p-3">
            <button
              onClick={() => handleRemoveImage(index, imageIndex)}
              className="absolute top-2 right-2 z-30 bg-white !rounded-full p-1 border-1 border-gray-500 shadow-md hover:bg-red-50"
            >
              <X className="text-red-500" size={16} />
            </button>
            <Box position="relative">
              <img
                className="w-full h-52 rounded-lg object-cover"
                src={image.url}
                alt="uploaded file"
              />
              {needsUpload && !(loading && loadingIndex === imageIndex) && (
                <Box className="absolute inset-0 bg-black/50 flex items-center justify-center rounded-lg">
                  <Button
                    onClick={() => handleRetryUpload(imageIndex, image.url)}
                    className="!bg-teal-500 !hover:bg-teal-600 !text-white !px-4 !py-2"
                    startIcon={<CloudUpload className="w-5 h-5" />}
                  >
                    Upload to Server
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <Box className="absolute bottom-6 left-0 right-0 flex justify-center gap-3">
            <Button
              onClick={() => handleClick(imageIndex)}
              className="!bg-blue-500/80 !hover:bg-blue-600/80 !min-w-0 !p-2 !rounded-full"
            >
              <Edit className="w-6 h-4 text-white" />
            </Button>
            <Button
              onClick={() => handleRemoveFile(imageIndex)}
              className="!bg-red-500/80 !hover:bg-red-600/80 !min-w-0 !p-2 !rounded-full"
            >
              <X className="w-6 h-4 text-white" />
            </Button>
            <Button
              className={`!min-w-0 !p-2 !rounded-full ${needsUpload
                ? '!bg-yellow-500/80'
                : '!bg-green-500/80'
                }`}
              disabled={true}
            >
              {loading && loadingIndex === imageIndex ? (
                <CircularProgress size={16} className="text-white " />
              ) : needsUpload ? (
                <TriangleAlert className="w-6 h-4 text-white" />
              ) : (
                <Check className="w-6 h-4 text-white" />
              )}
            </Button>
          </Box>
        </Box>
      </Grid>
    );
  };

  return (
    <Box className="w-full flex justify-start relative">
      {formik.touched.roomTypes?.[index]?.roomTypeImages && formik.errors.roomTypes?.[index]?.roomTypeImages&& (
        <div className='absolute right-8 top-5'>
          <span className="text-red-500 text-sm">{formik.errors.roomTypes?.[index]?.roomTypeImages}</span>
        </div>
      )}
      <Box className="mt-3 w-full">
        <Box className="m-4">
          <Typography variant="h5" gutterBottom>
            Photos
          </Typography>
          <Typography variant="body2" className="pb-4 text-gray-600">
            The maximum number of photos allowed is 10
          </Typography>
          <DndProvider backend={HTML5Backend}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <MediaContainer {...getRootProps()}>
                <input {...getInputProps()} ref={fileInput} className="hidden" />
                <Typography className="!text-sm text-gray-500">
                  {isDragActive ?
                    "Drop the image here..." :
                    "Drag and drop an image here or click to select"
                  }
                </Typography>
              </MediaContainer>
            </Grid>
            <Box className="flex flex-wrap gap-4">
              {roomType.roomTypeImages
                .slice()
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((image, imageIndex) => (
                  <DraggableImage
                    key={image.id || imageIndex}
                    imageIndex={imageIndex}
                    image={image}
                  />
                ))}
            </Box>
          </DndProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default RoomTypeImages;