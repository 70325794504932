import React from 'react';
import { Modal, Box, Button } from '@mui/material';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PdfViewerModal = ({ open, onClose, pdfUrl }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="pdf-viewer-modal"
      aria-describedby="modal-to-view-pdf"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        height: '90%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflow: 'auto'
      }}>
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}>
          <div style={{ height: '80vh', width: '100%' }}>
            <Viewer fileUrl={pdfUrl} />
          </div>
        </Worker>
        <Button 
          onClick={onClose}
          sx={{ position: 'absolute', top: 10, right: 10 }}
          className="!bg-blue-500 !text-white"
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default PdfViewerModal;