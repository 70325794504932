import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getProjectById } from '../services/serverApi.ProjectUnits';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import HomeIcon from '@mui/icons-material/Home';
import FlagIcon from '@mui/icons-material/FlagCircle';
import StraightenIcon from '@mui/icons-material/Straighten';
import PinDropIcon from '@mui/icons-material/PinDrop';
import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ArrowBack from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Container, Grid, Card, CardMedia, CardContent, Typography, IconButton, MobileStepper, Paper, useTheme, useMediaQuery, Button } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const ProjectDetailsPage = () => {
    const { projectId } = useParams();
    const navigate = useNavigate();
    const [project, setProject] = useState(null);
    const [projectActiveStep, setProjectActiveStep] = useState(0);
    const [unitActiveSteps, setUnitActiveSteps] = useState({});
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        fetchProjectDetails();
    }, [projectId]);

    useEffect(() => {
        if (project) {
            const initialActiveSteps = project.units.reduce((steps, unit, index) => {
                steps[index] = 0;
                return steps;
            }, {});
            setUnitActiveSteps(initialActiveSteps);
        }
    }, [project]);

    const fetchProjectDetails = async () => {
        try {
            const response = await getProjectById(projectId);
            setProject(response.data.project);
        } catch (error) {
            console.error('Error fetching project details:', error);
        }
    };
    

    const handleProjectNext = () => {
        setProjectActiveStep((prevActiveStep) => (prevActiveStep + 1) % project.images.length);
    };

    const handleProjectBack = () => {
        setProjectActiveStep((prevActiveStep) => (prevActiveStep - 1 + project.images.length) % project.images.length);
    };

    const handleNext = (unitIndex) => {
        setUnitActiveSteps((prevSteps) => ({
            ...prevSteps,
            [unitIndex]: (prevSteps[unitIndex] + 1) % project.units[unitIndex].images.length,
        }));
    };

    const handleBack = (unitIndex) => {
        setUnitActiveSteps((prevSteps) => ({
            ...prevSteps,
            [unitIndex]: (prevSteps[unitIndex] - 1 + project.units[unitIndex].images.length) % project.units[unitIndex].images.length,
        }));
    };

    const handleNavigateBack = () => {
        navigate(-1);
    };

    const handleEditProject = (projectId) => {
        navigate(`/admin/projects/${projectId}`);
        
      };

    const handleEditUnit = (unitId) => {
        navigate(`/admin/units/edit/${unitId}`);
    };

    if (!project) {
        return <div>Loading...</div>;
    }

    const maxSteps = project.images.length;

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box component="main"
                sx={{ py: { xs: 2, sm: 3, md: 4 }, backgroundColor: theme.palette.grey[100], minHeight: '100vh' }}>
                <Button startIcon={<ArrowBack />} onClick={handleNavigateBack} s className="!text-medium-aquamarine !hover:text-dark-aquamarine">
                </Button>
                <Container maxWidth="lg">
                    <Card sx={{ mb: 6, borderRadius: 2, boxShadow: theme.shadows[5] }}>
                        <Box sx={{ position: 'relative' }}>
                            <CardMedia component="img"
                                sx={{ height: { xs: '300px', sm: '400px', md: '500px' }, objectFit: 'cover', width: '100%', margin: '0', padding: '16px', borderRadius: '20px' }}
                                image={project.images[projectActiveStep].url}
                                alt={`Project Image ${projectActiveStep + 1}`} />
                            <Box sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', width: '100%', display: 'flex', justifyContent: 'space-between', px: 2 }}>
                                <IconButton onClick={handleProjectBack} disabled={projectActiveStep === 0}
                                    sx={{ color: 'white', bgcolor: 'rgba(0,0,0,0.5)', '&:hover': { bgcolor: 'rgba(0,0,0,0.7)' }, size: isMobile ? 'small' : 'medium' }}>
                                    <KeyboardArrowLeft />
                                </IconButton>
                                <IconButton onClick={handleProjectNext} disabled={projectActiveStep === maxSteps - 1}
                                    sx={{ color: 'white', bgcolor: 'rgba(0,0,0,0.5)', '&:hover': { bgcolor: 'rgba(0,0,0,0.7)' }, size: isMobile ? 'small' : 'medium' }}>
                                    <KeyboardArrowRight />
                                </IconButton>
                            </Box>
                            <MobileStepper steps={maxSteps} position="static" activeStep={projectActiveStep}
                                sx={{ position: 'absolute', bottom: 0, width: '100%', bgcolor: 'rgba(0,0,0,0.5)', '& .MuiMobileStepper-dot': { bgcolor: 'rgba(255,255,255,0.5)' }, '& .MuiMobileStepper-dotActive': { bgcolor: 'white' } }}
                                nextButton={<Box />} backButton={<Box />} />
                        </Box>
                        <CardContent sx={{ p: { xs: 2, sm: 3, md: 4 } }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mb: 4 }}>
                                <Typography variant="h2" align="center" className="!text-medium-aquamarine"
                                    sx={{ fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' }, fontWeight: 600 }}>
                                    {project.projectName}
                                </Typography>
                                <IconButton  onClick={() => handleEditProject(project._id)}
                                    sx={{ color: theme.palette.primary.main }}>
                                    <EditIcon className="text-medium-aquamarine" />
                                </IconButton>
                            </Box>
                            <Typography variant="body1" align="center"
                                sx={{ fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' }, mb: 4, px: { xs: 2, sm: 4, md: 6 }, color: theme.palette.text.secondary, lineHeight: 1.6 }}>
                                {project?.description}
                            </Typography>
                            <Grid container spacing={4}>
                                {[
                                    { label: 'Project Type', value: project.projectType },
                                    { label: 'Unit Type', value: project.unitType },
                                    { label: 'Status', value: project.status },
                                    { label: 'Total Units', value: project.totalUnits.toLocaleString() },
                                    { label: 'Sold Units', value: project.soldUnits.toLocaleString() },
                                    { label: 'Remaining Units', value: project.remainingUnits.toLocaleString() },
                                    { label: 'Starting Price', value: `$${project.startingPrice.toLocaleString()}` }
                                ].map((item, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <Box sx={{ p: 2, bgcolor: theme.palette.background.default, borderRadius: 1, height: '100%' }}>
                                            <Typography variant="subtitle1" gutterBottom sx={{ color: theme.palette.text.secondary, fontWeight: 500 }}>
                                                {item.label}
                                            </Typography>
                                            <Typography variant="body1" sx={{ fontWeight: 600, color: theme.palette.text.primary }}>
                                                {item.value}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </CardContent>
                    </Card>

                    <Typography variant="h3" gutterBottom sx={{ fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' }, fontWeight: 600, mb: 3 }} className="!text-medium-aquamarine">
                        Unit Details
                    </Typography>
                    <Grid container spacing={3}>
                        {project.units.map((unit, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card sx={{ height: '100%', borderRadius: 2, transition: 'transform 0.2s', '&:hover': { transform: 'translateY(-4px)', boxShadow: theme.shadows[8] } }}>
                                    <CardContent sx={{ p: 3 }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mb: 2 }}>
                                            <Typography variant="h4" align="center" className="!text-medium-aquamarine"
                                                sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' }, fontWeight: 600 }}>
                                                {unit.unitName || `Unit ${index + 1}`}
                                            </Typography>
                                            <IconButton onClick={() => handleEditUnit(unit._id)}
                                                sx={{ color: theme.palette.primary.main }}>
                                                <EditIcon className="text-medium-aquamarine" />
                                            </IconButton>
                                        </Box>
                                        <Box sx={{ position: 'relative', height: 200, overflow: 'hidden', borderRadius: '20px' }}>
                                            <CardMedia component="img"
                                                sx={{ height: '100%', objectFit: 'cover', width: '100%', margin: '0', padding: '16px' }}
                                                image={unit.images[unitActiveSteps[index]]?.url}
                                                alt={`Unit Image ${unitActiveSteps[index] + 1}`}
                                            />
                                            <MobileStepper steps={unit.images.length} position="static" activeStep={unitActiveSteps[index]}
                                                sx={{ position: 'absolute', bottom: 0, width: '100%', bgcolor: 'rgba(0,0,0,0.5)', '& .MuiMobileStepper-dot': { bgcolor: 'rgba(255,255,255,0.5)' }, '& .MuiMobileStepper-dotActive': { bgcolor: 'white' } }}
                                                nextButton={
                                                    <IconButton onClick={() => handleNext(index)} disabled={unitActiveSteps[index] === unit.images.length - 1}
                                                        sx={{ color: 'white', bgcolor: 'rgba(0,0,0,0.5)', '&:hover': { bgcolor: 'rgba(0,0,0,0.7)' }, size: isMobile ? 'small' : 'medium' }}>
                                                        <KeyboardArrowRight />
                                                    </IconButton>
                                                }
                                                backButton={
                                                    <IconButton onClick={() => handleBack(index)} disabled={unitActiveSteps[index] === 0}
                                                        sx={{ color: 'white', bgcolor: 'rgba(0,0,0,0.5)', '&:hover': { bgcolor: 'rgba(0,0,0,0.7)' }, size: isMobile ? 'small' : 'medium' }}>
                                                        <KeyboardArrowLeft />
                                                    </IconButton>
                                                }
                                            />
                                        </Box>

                                        <Grid container className="!my-4 gap-4 justify-center">
                                            {[
                                                { label: <HomeIcon />, value: unit.propertyType },
                                                { label: <FlagIcon />, value: unit.condition },
                                                { label: <StraightenIcon />, value: `${unit.totalArea} m²` },
                                                { label: <PinDropIcon />, value: `${unit.livingArea} m²` },
                                                { label: <BedIcon />, value: unit.numberOfBedrooms },
                                                { label: <BathtubIcon />, value: unit.numberOfBathrooms },
                                                { label: <AttachMoneyIcon />, value: `$${unit.salePrice.toLocaleString()}` },
                                                { label: <MonetizationOnIcon />, value: `$${unit.pricePerSqm.toLocaleString()}` }
                                            ].map((item, idx) => (
                                                <Grid item xs={6} sm={5} key={idx} className="flex gap-2 !p-2 my-2"
                                                    sx={{ backgroundColor: theme.palette.background.default, borderRadius: 1 }}>
                                                    <Typography variant="subtitle2"
                                                        sx={{ color: theme.palette.text.secondary, fontWeight: 500 }}>
                                                        {item.label}
                                                    </Typography>
                                                    <Typography variant="body2"
                                                        sx={{ fontWeight: 600, color: theme.palette.text.primary }}>
                                                        {item.value}
                                                    </Typography>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>
        </DashboardLayout>
    );
};

export default ProjectDetailsPage;