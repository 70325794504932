import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, ListItemIcon, ListItemText, Box } from '@mui/material';

const RoomTypeSelect = ({ roomType, types, index, handleRoomTypeChange, formik }) => {
  const handleChange = (e) => {
    const selectedTypeId = e.target.value;
    const selectedType = types.find(type => type._id === selectedTypeId);

    formik.setFieldValue(`roomTypes.${index}.roomTypeConfigId`, selectedTypeId);
    formik.setFieldValue(`roomTypes.${index}.roomTypeName`, selectedType?.type);
};
  return (
    <FormControl fullWidth>
      <InputLabel>Type</InputLabel>
      <Select
        name={`roomTypes[${index}].roomTypeConfigId`}
        value={roomType.roomTypeConfigId || ''}
        error={
          formik.touched.roomTypes?.[index]?.roomTypeConfigId && 
          Boolean(formik.errors.roomTypes?.[index]?.roomTypeConfigId)
        }
        helperText={
          formik.touched.roomTypes?.[index]?.roomTypeConfigId && 
          formik.errors.roomTypes?.[index]?.roomTypeConfigId
        }
        onChange={handleChange}
        label="Type"
        renderValue={(selected) => {
          const selectedTypeObj = types.find(type => type._id === selected);
          return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={selectedTypeObj?.iconUrl}
                alt={selectedTypeObj?.type}
                style={{ width: 24, height: 24, marginRight: 8 }}
              />
              {selectedTypeObj?.type}
            </Box>
          );
        }}
      >
        {types.map((type) => (
          <MenuItem key={type._id} value={type._id}>
            <ListItemIcon>
              <img src={type.iconUrl} alt={type.type} style={{ width: 24, height: 24 }} />
            </ListItemIcon>
            <ListItemText primary={type.type} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RoomTypeSelect;