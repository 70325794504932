import React, { useState, useEffect } from 'react';
import { 
  Checkbox, 
  FormControlLabel, 
  Menu, 
  IconButton,
  Box 
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

const ColumnVisibilityManager = ({ columns, setVisibleColumns }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [columnStates, setColumnStates] = useState(() => {
    const savedStates = localStorage.getItem('tableColumnStates');
    if (savedStates) {
      return JSON.parse(savedStates);
    }
    return columns.reduce((acc, col) => ({
      ...acc,
      [col.field || col.uniqueId || col.header]: true
    }), {});
  });

  useEffect(() => {
    localStorage.setItem('tableColumnStates', JSON.stringify(columnStates));
    const visibleCols = columns.filter(col => {
      const columnKey = col.field || col.uniqueId || col.header;
      return columnStates[columnKey];
    });
    setVisibleColumns(visibleCols);
  }, [columnStates, columns, setVisibleColumns]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColumnToggle = (columnKey) => {
    setColumnStates(prev => ({
      ...prev,
      [columnKey]: !prev[columnKey]
    }));
  };

  const columnsWithLabels = columns.map(column => {
    let label = column.header;
    if (typeof column.header === 'function') {
      label = column.headerLabel || column.field || 'Column';
    }
    return {
      ...column,
      displayLabel: label,
      key: column.field || column.uniqueId || column.header
    };
  });

  return (
    <div>
      <IconButton
        onClick={handleClick}
        className="hover:bg-gray-100 focus:outline-none"
        size="small"
      >
        <SettingsIcon className="text-gray-600" />
      </IconButton>
      
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          className: "mt-2 shadow-lg",
          style: {
            maxHeight: 400,
            width: 300,
          }
        }}
      >
        <Box className="p-2 space-y-1 max-h-96">
          {columnsWithLabels.map((column) => (
            <FormControlLabel
              key={column.key}
              control={
                <Checkbox
                  checked={columnStates[column.key]}
                  onChange={() => handleColumnToggle(column.key)}
                  sx={{
                    color: '#64748b',
                    '&.Mui-checked': {
                      color: '#0ea5e9',
                    },
                    padding: '4px',
                  }}
                  size="small"
                />
              }
              label={
                <span className="text-sm font-medium text-gray-700">
                  {column.displayLabel}
                </span>
              }
              className="block m-0 py-1 px-2 hover:bg-gray-50 rounded-md w-full"
            />
          ))}
        </Box>
      </Menu>
    </div>
  );
};

export default ColumnVisibilityManager;