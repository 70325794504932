import React, { useState, useEffect } from 'react';
import { Typography, Box, Tab, Tabs, CircularProgress } from '@mui/material';
import ReservationDataTab from './ReservationDataTab';
import ReservationTasksTab from './ReservationTasksTab';
import { getReservationDetails, getTasks } from './services/serverApi.chatConfig';
import AIReplyModeSelector from './AIReplyModeSelector';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChatReservationDetails = ({ activeConversation }) => {
  const [tabValue, setTabValue] = useState(0);
  const [reservationDetails, setReservationDetails] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const reservationMessage = activeConversation?.reservations?.[0];

  useEffect(() => {
    const fetchReservationAndTasks = async () => {
      if (!reservationMessage?.reservation_id) return;

      setIsLoading(true);
      try {
        const reservationResponse = await getReservationDetails(reservationMessage.reservation_id);
        if (reservationResponse.data.success) {
          const reservation = reservationResponse.data.reservation;
          setReservationDetails(reservation);
          console.log('reservation', reservation);

          const tasksResponse = await getTasks(reservation.arrivalDate, reservation.departureDate, [reservation.sojoriId]);
          if (tasksResponse && tasksResponse.data) {
            const tasksData = tasksResponse.data[reservation.sojoriId] || [];
            setTasks(tasksData);
          }
        }
      } catch (error) {
        console.error('Error fetching reservation details or tasks:', error);
        toast.error('Failed to fetch reservation details or tasks. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchReservationAndTasks();
  }, [reservationMessage]);

  const handleTabChange = (event, newValue) => setTabValue(newValue);

  const handleAIReplyModeChange = async ({ mode, openAI, response_backend }) => {
    console.log('AI Reply Mode changed to:', mode);
    console.log('OpenAI:', openAI);
    console.log('Response Backend:', response_backend);
    // You might want to update the local state or trigger a refresh of the conversation data here
  };

  if (!reservationMessage) {
    return (
      <div>
        <Typography variant="h6" className="p-4" style={{ borderBottom: '1px solid #ccc' }}>Reservation Details</Typography>
        <Typography className="p-4">No reservation details available.</Typography>
      </div>
    );
  }

  return (
    <div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Typography variant="h6" className="p-4" style={{ borderBottom: '1px solid #ccc' }}>Reservation Details</Typography>
      <div className="" style={{ height: 'calc(100vh - 160px)' }}>
        <AIReplyModeSelector 
          initialSettings={{ OpenAI: activeConversation.OpenAI, response_backend: activeConversation.response_backend }}
          onModeChange={handleAIReplyModeChange} 
          conversationId={activeConversation._id}
        />
        <div className="border-b border-gray-300">
          <div className="flex justify-between">
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="Reservation Tabs" className="w-full">
              <Tab label="Reservation Data" className="w-1/2" />
              <Tab label="Tasks" className="w-1/2" />
            </Tabs>
          </div>
        </div>
        <div className="overflow-y-auto scrollbar-thin scrollbar-thumb-medium-aquamarine scrollbar-track-gray-100" style={{ height: 'calc(100vh - 240px)' }}>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
              <CircularProgress />
            </Box>
          ) : (
            <div>
              {tabValue === 0 && (
                <ReservationDataTab reservationDetails={reservationDetails} />
              )}
              {tabValue === 1 && (
                <ReservationTasksTab tasks={tasks} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatReservationDetails;