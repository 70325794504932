import React from 'react';
import { Button } from '@mui/material';
import RatePlanEvents from './RatePlanEvents';

const RoomTypeItem = ({ listingId, roomType, onCreateRatePlan, onUpdateRatePlan }) => (
    <div className="ml-4 mb-4">
        {roomType.ratePlans && roomType.ratePlans.length > 0 ? (
            roomType.ratePlans.map((ratePlan) => (
                <RatePlanEvents
                    key={ratePlan._id}
                    ratePlan={ratePlan}
                    onUpdateEvent={(updatedEvent) => onUpdateRatePlan(ratePlan._id, updatedEvent)}
                />
            ))
        ) : (
            <Button
                variant="contained"
                className="!bg-medium-aquamarine !hover:bg-dark-aquamarine !text-white mt-2"
                onClick={onCreateRatePlan}
            >
                Create Rate Plan
            </Button>
        )}
    </div>
);

export default RoomTypeItem;