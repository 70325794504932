import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { 
  CircularProgress, Typography, Button, Card, CardContent, 
  CardActions, Divider, Box, Chip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import TranslateIcon from '@mui/icons-material/Translate';
import { getCancellationPolicy, getLanguages } from '../services/serverApi.adminConfig';
import CancellationPolicyModal from './CancellationPolicyModal';

const CancellationPolicy = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [policy, setPolicy] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [languages, setLanguages] = useState([]);


    useEffect(() => {
        fetchCancellationPolicy();
        fetchLanguages();
    }, []);

    const fetchCancellationPolicy = async () => {
        setIsLoading(true);
        try {
            const response = await getCancellationPolicy();
            if (response.data.sucess) {
                setPolicy(response.data.data);
            } else {
                toast.error(response.data.message || 'Failed to fetch cancellation policy');
                setPolicy(null);
            }
        } catch (error) {
            console.error('Error fetching cancellation policy:', error);
            toast.error('Failed to fetch cancellation policy');
            setPolicy(null);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchLanguages = async () => {
        try {
            const response = await getLanguages();
            setLanguages(response);
        } catch (error) {
            console.error('Error fetching languages:', error);
            toast.error('Failed to fetch languages');
        }
    };

    const handleOpenModal = () => setModalOpen(true);
    const handleCloseModal = () => setModalOpen(false);



    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{  }}>
            {policy ? (
                <Card elevation={3} sx={{ p: 2 }}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Cancellation Policy
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="subtitle1" gutterBottom>
                            Days Before Check-in: {policy.daysBeforCheckin || 'N/A'}
                        </Typography>
                        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                            Description:
                        </Typography>
                        {Object.entries(policy.cancellationDescription).map(([langId, text]) => (
                            <Box key={langId} sx={{ mb: 1 }}>
                                <Chip
                                    icon={<TranslateIcon />}
                                    className= 'text-gray-600'
                                    label={languages.find(lang => lang._id === langId)?.name || 'Unknown'}
                                    color={"default"}
                                    sx={{ mr: 1 }}
                                />
                                <Typography
                                    variant="body2"
                                    sx={{
                                        mt: 1,
                                    }}
                                >
                                    {text}
                                </Typography>
                            </Box>
                        ))}
                    </CardContent>
                    <CardActions className="justify-center">
                        <Button
                            startIcon={<EditIcon className='text-white' />}
                            onClick={handleOpenModal}
                            variant="contained"
                            className="!bg-medium-aquamarine !text-white"
                        >
                        </Button>
                    </CardActions>
                </Card>
            ) : (
                <Card elevation={3}>
                    <CardContent>
                        <Typography variant="h6" align="center" gutterBottom>
                            No Cancellation Policy Set
                        </Typography>
                        <Box display="flex" justifyContent="center" mt={2}>
                            <Button
                                onClick={handleOpenModal}
                                variant="contained"
                                color="primary"
                            >
                               <span className='text-white'>Create Policy</span> 
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            )}
            <CancellationPolicyModal
                open={modalOpen}
                onClose={handleCloseModal}
                setPolicy={setPolicy}
                policy={policy}
                policyId={policy?._id}
                languages={languages}
            />
        </Box>
    );
};

export default CancellationPolicy;