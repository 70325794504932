import { useState, useEffect } from 'react';

const useFetchData = (fetchFunction, dependencies) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetchFunction();
        if (isMounted && response.data.success) {
          setData(response.data);
          setError(null);
        }
      } catch (error) {
        console.error(`Error fetching data: ${error}`);
        if (isMounted) {
          setError(error);
          setData(null);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, dependencies);

  return { data, isLoading, error };
};

export default useFetchData;