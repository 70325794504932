import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Typography, Box, Card, CardContent, Tooltip, Paper } from '@mui/material';
import { Home, Users, Bed, MapPin, Flag, Phone, Mail, Star, Wifi, DoorClosed, Clock, Calendar, Hash, Info } from 'lucide-react';
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import MapLocation from '../components/Listingmap'
import { useNavigate } from 'react-router-dom';


const IconText = ({ icon: Icon, text, value }) => (
  <Box display="flex" alignItems="center" mb={2}>
    <Icon size={20} style={{ marginRight: '10px', color: '#00b4b4' }} />
    <Typography variant="body2">
      <strong>{text}:</strong> {value || '-'}
    </Typography>
  </Box>
);

const SectionCard = ({ title, children }) => (
  <Card elevation={3} style={{ marginBottom: '20px' }}>
    <CardContent>
      <Typography variant="h6" gutterBottom style={{ color: '#00b4b4', borderBottom: '2px solid #00b4b4', paddingBottom: '10px' }}>
        {title}
      </Typography>
      {children}
    </CardContent>
  </Card>
);

const ListingDetails = () => {
  const [data, setData] = useState({});
  const location = useLocation();
  const result = location?.state?.data;
  const navigate = useNavigate();

  useEffect(() => {
    if (result) {
      setData(result);
    }
  }, [result]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box p={3} className="relative">
        <h5 className="absolute">
          <button type="button" onClick={() => navigate(-1)} className="flex items-center text-[#00b4b4] cursor-pointer">
            <span className="ml-2 text-[14px]"><i className="pi pi-angle-left !text-[15px] font-bold"></i>Back</span>
          </button>
        </h5>
        <Typography variant="h4" gutterBottom align="center">
          {data?.name}
        </Typography>
        <div className="flex justify-center"><MapPin size={20} style={{ marginRight: '10px', color: 'orange' }} /></div>
        <Box textAlign="center" mb={4}>
          <Typography variant="body2" display="inline">
            {data?.address}
          </Typography>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <SectionCard title="Property Overview">
              <IconText icon={Home} text="Room Type" value={data?.roomType} />
              <IconText icon={Users} text="Guests" value={data?.guestsIncluded} />
              <IconText icon={Bed} text="Bedrooms" value={data?.bedroomsNumber} />
              <IconText icon={Bed} text="Beds" value={data?.bedsNumber} />
            </SectionCard>

            <SectionCard title="About this listing">
              <Typography variant="body2">{data?.description}</Typography>
            </SectionCard>

            <SectionCard title="Prices">
              <IconText icon={Info} text="Price" value={`${data?.price} ${data?.currencyCode}`} />
              {/* <IconText icon={Info} text="Weekly Discount" value={`${data?.weeklyDiscount}%`} />
              <IconText icon={Info} text="Monthly Discount" value={`${data?.monthlyDiscount}%`} /> */}
            </SectionCard>
          </Grid>

          <Grid item xs={12} md={6}>
            <SectionCard title="Amenities">
              <Grid container spacing={2}>
                {data?.listingAmenities?.map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item._id}>
                    <Paper
                      elevation={1}
                      sx={{
                        p: 2,
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        '&:hover': { boxShadow: 3 }
                      }}
                    >
                      <Box component="img" src={item.iconUrl} alt={item.name} sx={{ width: 28, height: 22, mr: 2 }} />
                      <Tooltip title={item.amenityName}>
                        <span className="text-sm truncate">{item.amenityName}</span>
                      </Tooltip>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </SectionCard>

            <SectionCard title="Standard Sojori">
              <Grid container spacing={2}>
                {data?.listingStandardSojori?.map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item._id}>
                    <Paper
                      elevation={1}
                      sx={{
                        p: 2,
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        '&:hover': { boxShadow: 3 }
                      }}
                    >
                      <Box component="img" src={item.iconUrl} alt={item.name} sx={{ width: 28, height: 22, mr: 2 }} />
                      <Tooltip title={item.standardSojoriName}>
                        <span className="text-sm truncate">{item.standardSojoriName}</span>
                      </Tooltip>

                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </SectionCard>
          </Grid>
        </Grid>

        <SectionCard title="Additional Information">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <IconText icon={Star} text="Star Rating" value={data?.rating} />
              <IconText icon={DoorClosed} text="Door Code" value={data?.doorSecurityCode} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <IconText icon={Clock} text="Check-in" value={`${data?.checkInTimeStart}`} />
              <IconText icon={Clock} text="Check-out" value={data?.checkOutTime} />
              <IconText icon={Calendar} text="Minimum Stay" value={`${data?.minNights} nights`} />
            </Grid>
          </Grid>
        </SectionCard>

        <SectionCard title="Location">
          {data?.lat && data?.lng ? (
            <MapLocation data={data} />
          ) : (
            <Typography variant="body2">Map data not available</Typography>
          )}
        </SectionCard>

        <SectionCard title="Photos">
          <Grid container spacing={2}>
            {data?.listingImages?.map((image, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <img
                  src={image.url}
                  alt={`Listing image ${index + 1}`}
                  style={{ width: '100%', height: 'auto', borderRadius: '8px' }}
                />
              </Grid>
            ))}
          </Grid>
        </SectionCard>
      </Box>
    </DashboardLayout>
  );
};

export default ListingDetails;