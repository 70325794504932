export const RenderTimeSelectField = ({formik, label, name, onChange}) => {
    const handleChange = (e) => {
        if (onChange) {
            onChange(e);
        } else {
            formik.handleChange(e);
        }
    };
    const hours = Array.from({ length: 24 }, (_, i) => i);
    return (
        <div className="mb-1">
            <label htmlFor={name} className="block text-[13px] text-gray-700 font-semibold mb-2">
                {label}
            </label>
            <select
                id={name}
                name={name}
                className={`w-full !text-sm px-2 py-2 border border-gray-300 focus:outline-none focus:ring-1 focus:ring-green-300 ${formik.touched[name] && formik.errors[name] ? '!border-red-500' : ''}`}
                value={formik.values[name]}
                onChange={handleChange}
                onBlur={formik.handleBlur}
            >
                <option value="">Select time</option>
                {hours.map(hour => (
                    <option key={hour} value={hour}>
                        {`${hour.toString().padStart(2, '0')}:00`}
                    </option>
                ))}
            </select>
            {formik.touched[name] && formik.errors[name] && (
                <div className="text-red-500 text-sm mt-1">{formik.errors[name]}</div>
            )}
        </div>
    );
};
