// components/componentsFiltrage/StatusFilter.js
import React from 'react';
import { FormControl, Select, MenuItem, Chip, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const statusOptions = [
  'Pending',
  'Confirmed',
  'Completed',
  'CancelledByAdmin',
  'CancelledByCustomer',
  'CancelledByOta',
  'CancelledAfterFailedPayment',
  'OtherCancellation'
];

const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    padding: '9px 32px 8px 9px !important',
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
  },
});

const StatusFilter = ({ status, setStatus }) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setStatus(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <FormControl sx={{ m: 1, width: '100%' }}>
      <StyledSelect
        labelId="status-filter-label"
        id="status-filter"
        multiple
        value={status}
        onChange={handleChange}
        displayEmpty
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em className="!p-[1.5px]">Status</em>;
          }
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} size="small" />
              ))}
            </Box>
          );
        }}
      >
        {statusOptions.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
};

export default StatusFilter;