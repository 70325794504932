import axios from 'axios';
import { MICROSERVICE_BASE_URL } from 'config/backendServer.config';

//----------------------------------Channex Call--------------------------------------------

export function getChannexCall(params = {}) {
    const { page = 0, limit = 25, search_text = ''} = params;
    const queryParams = new URLSearchParams({
        page,
        limit,
        paged: true,
        search_text,
    }).toString();

    return axios.get(`${MICROSERVICE_BASE_URL.SRV_CALENDAR}/channex/get-call-api?${queryParams}`)
        .then(response => {
            if (!response.data) {
                throw new Error('No data received from server');
            }
            return response.data;
        })
        .catch(error => {
            console.error('Error in getChannexCall:', error);
            throw error;
        });
}