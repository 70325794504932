import React from 'react';
import { Chip } from 'primereact/chip';

const ViewToggle = ({ view, setView, toggleFilters, onViewChange }) => (
    <div className="flex mb-2.5">
        <button className="px-2 !bg-[#dcf6f6] !rounded-sm flex items-center gap-1 !mr-4" onClick={toggleFilters}>
            <Chip label="Filters" icon={<i className="pi pi-filter"></i>} className="bg-transparent text-[#00b4b4]  border-none"/>
        </button>
        <button
            className={`h-10 px-1 ${view === 'reservation' ? 'bg-gray-500 text-white' : 'bg-gray-200 text-[#957F8C]'} border-none cursor-pointer !text-sm px-4`}
            onClick={() => {
                setView('reservation');
                onViewChange('reservation');
            }}
        >
            Per reservation
        </button>
        <button
            className={`h-10 px-1 ${view === 'listing' ? 'bg-gray-500 text-white' : 'bg-gray-200 text-[#957F8C]'} border-none cursor-pointer !text-sm px-4`}
            onClick={() => {
                setView('listing');
                onViewChange('listing');
            }}
        >
            Per listing
        </button>
    </div>
);

export default ViewToggle;