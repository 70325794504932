import React from 'react';
import SellIcon from '@mui/icons-material/Sell';
import DiamondIcon from '@mui/icons-material/Diamond';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const ReservationActions = ({ onCreateReservation }) => (
  <div className="flex items-center gap-6">
    <button className="bg-[#00b4b4] !rounded w-[7rem] items-center flex gap-1 justify-center">
      <span className="ladda-label text-[15px] text-white !p-[3px] font-semibold">Coupons</span>
      <SellIcon className="text-white !text-[20px]" />
    </button>
    <button className="bg-[#00b4b4] !rounded w-[13rem] items-center flex gap-1 justify-center p-[2px]">
      <span className="ladda-label text-[15px] text-white !p-[3px] font-semibold">Manage custom fields</span>
      <DiamondIcon className="text-white !text-[20px]" />
    </button>
    <button className="bg-[#00b4b4] !rounded w-[5rem] flex gap-1 items-center justify-center p-[2px]" onClick={onCreateReservation}>
      <span className="ladda-label text-[15px] text-white !p-[3px] font-semibold">Add</span>
      <AddCircleIcon className="text-white !text-[20px]" />
    </button>
  </div>
);

export default ReservationActions;
