import React, { useState, useEffect } from 'react';
import {
    Button, TextField, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { getLanguages } from 'features/reservation/services/serverApi.reservation';
import AddEditTravellerModal from './teaveller/AddEditTravellerModal';
import DeleteConfirmationModal from './teaveller/DeleteConfirmationModal';
import TravellersTable from './teaveller/TravellersTable';

const Travellers = ({ reservationDetails, isEditMode, onDetailsUpdate }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState(null);
    const [currentTraveller, setCurrentTraveller] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const [localData, setLocalData] = useState({
        traveller_numbers: reservationDetails.traveller_numbers || [],
        owner_number: reservationDetails.owner_number || '',
        owner_valid: reservationDetails.owner_valid || false,
    });
    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        setLocalData({
            traveller_numbers: reservationDetails.traveller_numbers || [],
            owner_number: reservationDetails.owner_number || '',
            owner_valid: reservationDetails.owner_valid || false,
        });
        fetchLanguages();
    }, [reservationDetails]);

    const fetchLanguages = async () => {
        try {
            const languageData = await getLanguages();
            setLanguages(languageData);
        } catch (error) {
            console.error("Error fetching languages:", error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCurrentTraveller(prev => ({ ...prev, [name]: value }));
    };

    const handleOwnerChange = (event) => {
        const { name, value } = event.target;
        let updatedValue = name === 'owner_valid' ? value === 'true' : value;
        
        setLocalData(prev => ({
            ...prev,
            [name]: updatedValue
        }));
    };

    const openModal = (traveller = null, index = null) => {
        setCurrentTraveller(traveller || { whatsappNumber: '', language: '', role: 'Traveller' });
        setEditIndex(index);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setCurrentTraveller(null);
        setEditIndex(null);
    };

    const saveTraveller = () => {
        let updatedTravellers;
        if (editIndex !== null) {
            updatedTravellers = localData.traveller_numbers.map((t, i) =>
                i === editIndex ? currentTraveller : t
            );
        } else {
            updatedTravellers = [...localData.traveller_numbers, currentTraveller];
        }
        setLocalData(prev => ({
            ...prev,
            traveller_numbers: updatedTravellers
        }));
        closeModal();
    };

    const openDeleteConfirm = (index) => {
        setDeleteIndex(index);
        setIsDeleteConfirmOpen(true);
    };

    const closeDeleteConfirm = () => {
        setIsDeleteConfirmOpen(false);
        setDeleteIndex(null);
    };

    const confirmDelete = () => {
        const updatedTravellers = localData.traveller_numbers.filter((_, i) => i !== deleteIndex);
        setLocalData(prev => ({
            ...prev,
            traveller_numbers: updatedTravellers
        }));
        closeDeleteConfirm();
    };

    useEffect(() => {
        onDetailsUpdate({
            ...reservationDetails,
            ...localData,
            atSojori: true,
        });
    }, [localData]);

    return (
        <div className="mb-4">
            <div className="mt-4 p-4 border rounded">
                <div className="mb-4">
                    <h4 className="font-semibold mb-2">Owner Information</h4>
                    <div className="mt-4" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' }}>
                        <TextField
                            label="Owner Number"
                            name="owner_number"
                            value={localData.owner_number}
                            onChange={handleOwnerChange}
                            disabled={!isEditMode}
                            fullWidth
                        />
                        <FormControl fullWidth>
                            <InputLabel>Owner Valid</InputLabel>
                            <Select
                                name="owner_valid"
                                value={localData.owner_valid.toString()}
                                onChange={handleOwnerChange}
                                disabled={!isEditMode}
                            >
                                <MenuItem value="true">Yes</MenuItem>
                                <MenuItem value="false">No</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <h4 className="font-semibold mb-2">Traveller Numbers</h4>
                {isEditMode && (
                    <div className="text-right">
                        <Button
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() => openModal()}
                            style={{ marginTop: '1rem', marginBottom: '1rem' }}
                            className="!bg-medium-aquamarine !text-white"
                        >
                            Add Traveller
                        </Button>
                    </div>
                )}
                <TravellersTable
                    data={localData.traveller_numbers}
                    isEditMode={isEditMode}
                    onEdit={openModal}
                    onDelete={openDeleteConfirm}
                />
            </div>

            <AddEditTravellerModal
                isOpen={isModalOpen}
                onClose={closeModal}
                currentTraveller={currentTraveller}
                editIndex={editIndex}
                languages={languages}
                onSave={saveTraveller}
                onInputChange={handleInputChange}
            />

            <DeleteConfirmationModal
                isOpen={isDeleteConfirmOpen}
                onClose={closeDeleteConfirm}
                onConfirm={confirmDelete}
            />
        </div>
    );
};

export default Travellers;