import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Box } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    getSyncListings,
    getSyncAmenities,
    getSyncPropertyTypes,
    getSyncBedTypes,
    getSyncAccessToken,
    getSyncCalendar,
    getSyncReservation,
    getSyncMinutHomes
} from '../../services/serverApi.adminConfig';
import { SyncButton } from './SyncButton';
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";

const Synchroniser = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState(null);

    const handleSync = useCallback(async (syncFunction, successMessage) => {
        setIsLoading(true);
        try {
            await syncFunction();
            setToastMessage({ type: 'success', message: successMessage });
        } catch (error) {
            console.error('Sync error:', error);
            setToastMessage({ type: 'error', message: 'Synchroniser failed. Please try again.' });
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        if (toastMessage) {
            const { type, message } = toastMessage;
            if (type === 'success') {
                toast.success(message);
            } else if (type === 'error') {
                toast.error(message);
            }
            setToastMessage(null);
        }
    }, [toastMessage]);

    const syncActions = [
        // { label: 'Sync Listings', action: () => handleSync(getSyncListings, 'Listings synchronized successfully') },
        // { label: 'Sync Amenities', action: () => handleSync(getSyncAmenities, 'Amenities synchronized successfully') },
        // { label: 'Sync Bed Types', action: () => handleSync(getSyncBedTypes, 'Bed types synchronized successfully') },
        // { label: 'Sync Property Types', action: () => handleSync(getSyncPropertyTypes, 'Property types synchronized successfully') },
        // { label: 'Refresh Access Token', action: () => handleSync(getSyncAccessToken, 'Access token refreshed successfully') },
        // { label: 'Sync Calendar', action: () => handleSync(getSyncCalendar, 'Calendar synchronized successfully') },
        // { label: 'Sync Reservations', action: () => handleSync(getSyncReservation, 'Reservations synchronized successfully') },
        { label: 'Sync Minut Homes', action: () => handleSync(getSyncMinutHomes, 'Minut homes synchronized successfully') },
    ];

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ToastContainer position="top-right" autoClose={5000} />
            <Box sx={{ p: 4 }} className="card">
                <Typography variant="h4" gutterBottom>
                    Synchroniser
                </Typography>
                <div className="grid grid-cols-4 gap-4 my-2">
                    {syncActions.map((action, index) => (
                        <div key={index} className="w-full">
                            <SyncButton
                                label={action.label}
                                onClick={action.action}
                                disabled={isLoading}
                            />
                        </div>
                    ))}
                </div>
            </Box>
        </DashboardLayout>
    );
};

export default Synchroniser;