import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { Card, CardContent, Typography, Grid, Chip, Box, ImageList, ImageListItem, Button } from '@mui/material';
// import { Pool, Elevator, Security, LocalParking, Balcony } from '@mui/icons-material';
import { getProjectsAndUnits } from '../services/serverApi.ProjectUnits';
import defaultImage from 'assets/images/image_placeholder.jpg';
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Pagination from 'components/GlobalTable/Pagination';

import { Card, CardContent, Typography, Grid, Chip, Box, ImageList, ImageListItem, LinearProgress, Divider, Paper, IconButton, Button, CircularProgress } from '@mui/material';
import { Pool, Elevator, Security, LocalParking, Balcony, House, ExpandMore, ExpandLess } from '@mui/icons-material';



const ProjectUnits = () => {
  const navigate = useNavigate();
  const [projectsData, setProjectsData] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [loading, setLoading] = useState(false);
  const rowsPerPageOptions = [5, 10, 20, 30, 50].map(num => ({ label: num.toString(), value: num }));


  useEffect(() => {
    fetchProjects();
  }, [page, limit]);

  const fetchProjects = async () => {
    try {
      setLoading(true);
      const response = await getProjectsAndUnits({ page, limit });
      setProjectsData(response.data.data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
  };



  const handleUnitDetails = (unitId) => {
    navigate(`/admin/units/${unitId}`);
  };

  const handleProjectDetails = (projectId) => {
    navigate(`/admin/projectDetails/${projectId}`);
  };


  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  }



  const IndividualUnitCard = ({ unit }) => (
    <Card className="hover:shadow-lg transition-shadow !p-4 sm:!p-6 !my-2">
      <CardContent className="!p-2">
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box className="relative w-full h-48 sm:h-72">
              <img
                src={unit.images[0]?.url || defaultImage}
                alt="Property"
                className="w-full h-full object-cover rounded-lg"
              />
              <Chip
                label="For Sale"
                className="absolute top-4 left-2 sm:top-4 sm:left-4 bg-green-500 text-white"
              />
            </Box>

            <Box className="flex flex-wrap gap-2 !py-3">
              {unit.swimmingPool && <Pool className="text-gray-600" />}
              {unit.elevator && <Elevator className="text-gray-600" />}
              {unit.security && <Security className="text-gray-600" />}
              {unit.garage && <LocalParking className="text-gray-600" />}
              {unit.balcony && <Balcony className="text-gray-600" />}
            </Box>
          </Grid>

          <Grid item xs={12} md={8}>
            <Box className="px-2 py-4 sm:p-4">

              <div className="flex flex-col sm:flex-row gap-2 sm:gap-3 items-start sm:items-center mb-4">
                <Typography variant="h6" sm="h5">
                  {unit.propertyType}
                </Typography>
                <Typography variant="h6" sm="h5">
                  ${unit.salePrice.toLocaleString()}
                </Typography>
                <Button onClick={() => handleUnitDetails(unit.id)} className="!px-4 sm:!px-6 !py-1 sm:!py-2 !rounded-lg !bg-gray-200 !text-gray-800 !hover:!bg-gray-300">
                  <VisibilityIcon className="mr-2" />
                </Button>
              </div>

              <div className="mb-4">
                <Typography className="text-gray-600 text-sm sm:text-base">
                  {unit.address}, {unit.district}, {unit.city}
                </Typography>
              </div>

              <Grid container spacing={2} className="!px-2 sm:!px-4 my-4">
                <Box className="!flex !flex-wrap !gap-2 sm:!gap-4">
                  <Chip icon={<House />} label={`${unit.totalArea}  m²`} size="small" />
                  <Chip label={`${unit.numberOfBedrooms} Lits`} size="small" />
                  <Chip label={`${unit.numberOfBathrooms} Salles de bain`} size="small" />
                  <Chip label={unit.condition} size="small" />
                </Box>
              </Grid>

              <Grid container spacing={2} className="mb-4">
                <Grid item xs={6} sm={3} md={2}>
                  <Typography variant="body2" color="text.secondary">
                    Surface habitable
                  </Typography>
                  <Typography className="text-sm sm:text-base">{unit.livingArea}  m²</Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <Typography variant="body2" color="text.secondary">
                    Prix par m²
                  </Typography>
                  <Typography className="text-sm sm:text-base">${(unit.salePrice / unit.totalArea).toFixed(2)}</Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <Typography variant="body2" color="text.secondary">
                    Surface du balcon
                  </Typography>
                  <Typography className="text-sm sm:text-base">{unit.balconyArea}  m²</Typography>
                </Grid>
                <Grid item xs={6} sm={3} md={2}>
                  <Typography variant="body2" color="text.secondary">
                    etage
                  </Typography>
                  <Typography className="text-sm sm:text-base">{unit.floor}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );


  const ProjectCard = ({ project }) => {
    const firstFifteenUnits = project.units.slice(0, 6);

    return (
      <Card className="hover:shadow-lg transition-shadow !p-4 sm:!p-6 !my-2">
        <CardContent className="!p-2">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} className="flex items-center">
              <Box className="relative w-full h-48 sm:h-72">
                <img
                  src={project.dataproject.images[0]?.url || defaultImage}
                  alt="Project"
                  className="w-full h-full object-cover rounded-lg"
                />
                <Chip
                  label={project.dataproject.status}
                  className="absolute top-2 left-2 sm:top-4 sm:left-4"
                  color={project.dataproject.status === 'Under-Construction' ? 'warning' : 'success'}
                  size="small"
                />
              </Box>
            </Grid>


            <Grid item xs={12} md={8}>
              <Box className="p-2 sm:p-4">

                <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 items-start sm:items-center mb-4">
                  <Typography variant="h6" sm="h5" className="">
                    {project.dataproject.projectName}
                  </Typography>
                  <Button onClick={() => handleProjectDetails(project.dataproject.id)} className="!px-4 sm:!px-6 !py-1 sm:!py-2 !rounded-lg !bg-gray-200 !text-gray-800 !hover:!bg-gray-300">
                    <VisibilityIcon className="mr-2" />
                  </Button>
                </div>

                <Typography variant="body2" className="text-sm sm:text-base mb-4">
                  {project.dataproject?.description?.slice(0, 80)}...
                </Typography>

                <Grid container spacing={2} className="mb-4">
                  <Grid item xs={6} sm={3} md={2}>
                    <Typography variant="body2" color="text.secondary">
                      Type de projet
                    </Typography>
                    <Typography className="text-sm sm:text-base">{project.dataproject.projectType}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={3} md={2}>
                    <Typography variant="body2" color="text.secondary">
                      Type d unite
                    </Typography>
                    <Typography className="text-sm sm:text-base">{project.dataproject.unitType}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={3} md={2}>
                    <Typography variant="body2" color="text.secondary">
                      Total des unites
                    </Typography>
                    <Typography className="text-sm sm:text-base">{project.dataproject.totalUnits}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={3} md={2}>
                    <Typography variant="body2" color="text.secondary">
                      Prix de depart
                    </Typography>
                    <Typography className="text-sm sm:text-base">${project.dataproject.startingPrice.toLocaleString()}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={3} md={2}>
                    <Typography variant="body2" color="text.secondary">
                      Date de livraison
                    </Typography>
                    <Typography className="text-sm sm:text-base">{formatDate(project.dataproject.deliveryDate)}</Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  {firstFifteenUnits.map(unit => (
                    <Grid item xs={12} sm={6} md={4} key={unit.id}>
                      <Box className="flex flex-col sm:flex-row items-start sm:items-center gap-2 p-2 border rounded overflow-hidden">
                        <Box className="flex flex-col sm:flex-row sm:items-center gap-1 sm:gap-2 w-full flex-wrap">
                          <Typography variant="body2" className="text-sm sm:text-base whitespace-nowrap">
                            {unit.propertyType}
                          </Typography>
                          <Typography variant="body2" className="text-sm sm:text-base whitespace-nowrap">
                            ${unit.salePrice.toLocaleString()}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" className="text-sm sm:text-base whitespace-nowrap">
                            {unit.totalArea} m²
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box className="px-3 sm:px-6 py-4 sm:py-8">
        <Typography variant="h5" component="h1" className="!mb-4">
          Immobilier - Projets et Unites
        </Typography>

        {loading ? (
          <Box className="flex justify-center items-center min-h-[200px]">
            <CircularProgress 
              size={40}
              thickness={4}
              className="text-[#00b4b4]"
            />
          </Box>
        ) : (
          <>
            {projectsData.length === 0 ? (
              <Box className="text-center py-8">
                <Typography variant="h6" color="textSecondary">
                  No projects or units found
                </Typography>
              </Box>
            ) : (
              <>
                {projectsData.map((item, index) => (
                  item.dataproject ? (
                    <ProjectCard key={`project-${index}`} project={item} />
                  ) : (
                    <IndividualUnitCard key={`unit-${index}`} unit={item.unit} />
                  )
                ))}
              </>
            )}
            <Box className="card px-4 rounded-lg shadow-md">
            {projectsData.length > 0 && (
              <Pagination
                page={page}
                onPageChange={handlePageChange}
                isNextDisabled={projectsData.length < limit}
                limit={limit}
                onLimitChange={handleLimitChange}
                rowsPerPageOptions={rowsPerPageOptions}
              />
            )}
            </Box>
          </>
        )}
      </Box>
    </DashboardLayout>
  );
};

export default ProjectUnits;