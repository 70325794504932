import React from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Grid, Paper, Typography, Select, MenuItem, CircularProgress } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const Charts = ({ reservationStats = [], channelStats = [], checkinByChannel = [], revenueByChannel = [], bookedNightsByChannel = [], isLoadingReservationStats, isLoadingChannelStats, isLoadingCheckinByChannel, isLoadingRevenueByChannel, isLoadingBookedNightsByChannel
}) => {
    const formatDate = (date) => {
        if (!date) return '';
        return `${date.year}/${String(date.month).padStart(2, '0')}/${String(date.date).padStart(2, '0')}`;
    };

    const createChartData = (labels, datasets) => ({ labels, datasets });

    const createBarDataset = (label, data, color) => ({
        label,
        data,
        backgroundColor: color,
        borderColor: color,
        borderWidth: 1,
        barThickness: 30,
    });

    const createDoughnutDataset = (data, colors) => ({
        data,
        backgroundColor: colors,
        hoverBackgroundColor: colors,
    });

    const chartOptions = {
        scales: { y: { beginAtZero: true } },
        plugins: {
            title: { display: true },
            legend: { position: 'bottom' },
        },
    };

    const charts = [
        {
            title: 'Rental Revenue Over Time',
            type: 'bar',
            data: createChartData(
                reservationStats.map(stat => formatDate(stat.x)),
                [createBarDataset('Reservation', reservationStats.map(stat => stat.y), 'rgb(19,108,194)')]
            ),
            options: { ...chartOptions, plugins: { ...chartOptions.plugins, title: { ...chartOptions.plugins.title, text: 'Rental Revenue' } } },
            gridSize: 8,
            isLoading: isLoadingReservationStats
        },
        {
            title: 'Per Channel (Total)',
            type: 'doughnut',
            data: createChartData(
                channelStats.map(channel => channel.label),
                [createDoughnutDataset(channelStats.map(channel => channel.value), ['#FFCE56', '#F0794F'])]
            ),
            options: { ...chartOptions, plugins: { ...chartOptions.plugins, title: { ...chartOptions.plugins.title, text: 'Total Revenue per Channel' } } },
            gridSize: 4,
            isLoading: isLoadingChannelStats
        },
        {
            title: 'Rental Revenue per Channel',
            type: 'bar',
            data: createChartData(
                revenueByChannel.map(stat => stat.x.month),
                [createBarDataset('Revenue per Channel', revenueByChannel.map(stat => stat.y), revenueByChannel.map(stat => stat.color === 'bookingengine' ? '#FFCE56' : '#F0794F'))]
            ),
            options: chartOptions,
            gridSize: 4,
            isLoading: isLoadingRevenueByChannel
        },
        {
            title: 'Booked Nights per Channel',
            type: 'bar',
            data: createChartData(
                bookedNightsByChannel.map(stat => stat.x.month),
                [createBarDataset('Booked Nights per Channel', bookedNightsByChannel.map(stat => stat.y), bookedNightsByChannel.map(stat => stat.color === 'bookingengine' ? '#FFCE56' : '#F0794F'))]
            ),
            options: chartOptions,
            gridSize: 4,
            isLoading: isLoadingBookedNightsByChannel
        },
        {
            title: 'Rental Revenue per Reservation per Channel',
            type: 'bar',
            data: createChartData(
                ['Booking Engine', 'Hostaway Direct'],
                [createBarDataset('Revenue per Reservation', [5000, 32000], ['#FFCE56', '#F0794F'])]
            ),
            options: chartOptions,
            gridSize: 4,
            isLoading: false
        },
        {
            title: 'Check-ins by channel',
            type: 'bar',
            data: createChartData(
                checkinByChannel.map(stat => formatDate(stat.x)),
                [createBarDataset('Check-ins', checkinByChannel.map(stat => stat.y), '#FFCE56')]
            ),
            options: { ...chartOptions, plugins: { ...chartOptions.plugins, title: { ...chartOptions.plugins.title, text: 'Number of check-ins based on "check-in date"' } } },
            gridSize: 8,
            isLoading: isLoadingCheckinByChannel
        },
        {
            title: 'Reservations per channel',
            type: 'doughnut',
            data: createChartData(
                ['Booking Engine', 'Hostaway Direct'],
                [createDoughnutDataset([1, 1], ['#FFCE56', '#F0794F'])]
            ),
            options: { ...chartOptions, plugins: { ...chartOptions.plugins, title: { ...chartOptions.plugins.title, text: 'Percentage of reservations based on "check-in date"' } } },
            gridSize: 4,
            isLoading: false,

            additionalContent: (
                <>
                    <Typography variant="h4" align="center" style={{ position: 'relative', top: '-50%' }}>
                        2
                    </Typography>
                    <Typography variant="subtitle1" align="center" style={{ position: 'relative', top: '-50%', fontSize: '0.9rem' }}>
                        Total reservations
                    </Typography>
                </>
            ),
        },

    ];

    return (
        <>
            <div className="flex space-x-4 gap-4 mb-4">
                {['Rental Revenue', 'All channels', 'Check-in', 'Bar chart'].map((item) => (
                    <Select key={item} defaultValue={item} className="w-28 h-9 !rounded-sm">
                        <MenuItem value={item}>{item}</MenuItem>
                    </Select>
                ))}
            </div>
            <Grid container spacing={3}>
                {charts.map((chart, index) => (
                    <Grid item xs={chart.gridSize} key={index}>
                        <Paper style={{ height: '100%', backgroundColor: 'rgb(248,249,252)' }}>
                            <Typography variant="h6" align="center">{chart.title}</Typography>
                            {chart.isLoading ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                                    <CircularProgress sx={{ color: '#00b4b4' }} />
                                </div>
                            ) : (
                                <>
                                    {chart.type === 'bar' ? (
                                        <Bar data={chart.data} options={chart.options} />
                                    ) : (
                                        <Doughnut data={chart.data} options={chart.options} />
                                    )}
                                    {chart.additionalContent}
                                </>
                            )}
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default Charts;