import React from 'react';
import { Grid, TextField } from '@mui/material';
import { Field } from 'formik';

const FinancialMetrics = ({ values, touched, errors, setFieldValue }) => {
  const renderRangeFields = (name, label) => (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Field
          as={TextField}
          fullWidth
          name={`${name}.min`}
          label={`${label} Min`}
          type="number"
          variant="outlined"
          error={touched[name]?.min && Boolean(errors[name]?.min)}
          helperText={touched[name]?.min && errors[name]?.min}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          as={TextField}
          fullWidth
          name={`${name}.max`}
          label={`${label} Max`}
          type="number"
          variant="outlined"
          error={touched[name]?.max && Boolean(errors[name]?.max)}
          helperText={touched[name]?.max && errors[name]?.max}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {renderRangeFields('prixDeLocationJournalier', 'Daily Rental Price')}
      </Grid>
      <Grid item xs={12}>
        {renderRangeFields('tauxOccupation', 'Occupancy Rate')}
      </Grid>
      <Grid item xs={12}>
        {renderRangeFields('ota', 'OTA Commissions')}
      </Grid>
      <Grid item xs={12}>
        {renderRangeFields('fraisDeGestion', 'Management Fees')}
      </Grid>
      <Grid item xs={12}>
        {renderRangeFields('depensesOperationnelles', 'Operational Expenses')}
      </Grid>
    </Grid>
  );
};

export default FinancialMetrics;