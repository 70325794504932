import React from 'react';
import { Field } from 'formik';
import { TextField, FormControlLabel, Switch, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const SWIMMING_POOL_TYPES = ['Aucune', 'Privée', 'Commune'];

const CustomSwitch = styled(Switch)({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#00b4b4',
    '&:hover': {
      backgroundColor: 'rgba(0, 180, 180, 0.08)',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#00b4b4',
  },
});

export const PropertyFeatures = ({ values, touched, errors, setFieldValue }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }} className="!mb-4">
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            <FormControlLabel
                control={
                    <Field name="gatedCommunity">
                        {({ field }) => (
                            <CustomSwitch {...field} checked={field.value} />
                        )}
                    </Field>
                }
                label="Gated Community"
            />

            <FormControlLabel
                control={
                    <Field name="security">
                        {({ field }) => (
                            <CustomSwitch {...field} checked={field.value} />
                        )}
                    </Field>
                }
                label="Security"
            />

            <FormControlLabel
                control={
                    <Field name="elevator">
                        {({ field }) => (
                            <CustomSwitch
                                {...field}
                                checked={field.value}
                            />
                        )}
                    </Field>
                }
                label="Elevator"
            />
            <FormControlLabel
                control={
                    <Field name="balcony">
                        {({ field }) => (
                            <CustomSwitch
                                {...field}
                                checked={field.value}
                                onChange={(e) => {
                                    setFieldValue('balcony', e.target.checked);
                                    if (!e.target.checked) {
                                        setFieldValue('balconyArea', 0);
                                    }
                                }}
                            />
                        )}
                    </Field>
                }
                label="Balcony"
            />
            <FormControlLabel
                control={
                    <Field name="garage">
                        {({ field }) => (
                            <CustomSwitch
                                {...field}
                                checked={field.value}
                                onChange={(e) => {
                                    setFieldValue('garage', e.target.checked);
                                    if (!e.target.checked) {
                                        setFieldValue('parkingSpaces', 0);
                                    }
                                }}
                            />
                        )}
                    </Field>
                }
                label="Garage"
            />

            <Field name="swimmingPool">
                {({ field }) => (
                    <FormControl fullWidth>
                        <InputLabel id="swimming-pool-label">Swimming Pool</InputLabel>
                        <Select {...field} labelId="swimming-pool-label" label="Swimming Pool">
                            {SWIMMING_POOL_TYPES.map(type => (
                                <MenuItem key={type} value={type}>{type}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Field>
        </Box>

        <Box sx={{ display: 'flex', gap: 2 }}>
            <Field name="numberOfFloors">
                {({ field }) => (
                    <TextField
                        {...field}
                        label="Number of Floors"
                        type="number"
                        fullWidth
                        error={touched.numberOfFloors && Boolean(errors.numberOfFloors)}
                        helperText={touched.numberOfFloors && errors.numberOfFloors}
                    />
                )}
            </Field>

            <Field name="floor">
                {({ field }) => (
                    <TextField
                        {...field}
                        label="Floor"
                        type="number"
                        fullWidth
                        error={touched.floor && Boolean(errors.floor)}
                        helperText={touched.floor && errors.floor}
                    />
                )}
            </Field>
        </Box>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
            {values.balcony && (
                <Field name="balconyArea">
                    {({ field }) => (
                        <TextField
                            {...field}
                            label="Balcony Area (m²)"
                            type="number"
                            fullWidth
                            error={touched.balconyArea && Boolean(errors.balconyArea)}
                            helperText={touched.balconyArea && errors.balconyArea}
                        />
                    )}
                </Field>
            )}
        </Box>
        <Box sx={{ display: 'flex', gap: 2 }}>
            <Field name="landArea">
                {({ field }) => (
                    <TextField
                        {...field}
                        label="Land Area (m²)"
                        type="number"
                        fullWidth
                        error={touched.landArea && Boolean(errors.landArea)}
                        helperText={touched.landArea && errors.landArea}
                    />
                )}
            </Field>

            <Field name="garden">
                {({ field }) => (
                    <TextField
                        {...field}
                        label="Garden Area (m²)"
                        type="number"
                        fullWidth
                        error={touched.garden && Boolean(errors.garden)}
                        helperText={touched.garden && errors.garden}
                    />
                )}
            </Field>
        </Box>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            {values.garage && (
                <Field name="parkingSpaces">
                    {({ field }) => (
                        <TextField
                            {...field}
                            label="Parking Spaces"
                            type="number"
                            fullWidth
                            error={touched.parkingSpaces && Boolean(errors.parkingSpaces)}
                            helperText={touched.parkingSpaces && errors.parkingSpaces}
                        />
                    )}
                </Field>
            )}
        </Box>
    </Box>
);

export default PropertyFeatures;