import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    TextField,
    Box,
} from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { addBedType } from '../services/serverApi.listing';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Bed type name is required')
});

const AddBedType = ({ open, onClose, setBedTypes, bedTypes }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values, { setSubmitting }) => {
        setIsLoading(true);
        const formData = {
            name: values.name 
        };
        try {
            const response = await addBedType(formData);
            const newBedType = response.data.bedTypes;
            setBedTypes([...bedTypes, newBedType]);
            setSubmitting(false);
            onClose();
            toast.success('Bed type added successfully');
        } catch (error) {
            setErrorMessage(error.message);
            setSubmitting(false);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle className='bg-medium-aquamarine text-white'>
                <Typography variant="h6" style={{ color: 'white' }} align="center">
                    Add New Bed Type
                </Typography>
            </DialogTitle>
            <DialogContent className='pt-4'>
                <Formik
                    initialValues={{
                        name: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    <Form>
                        <Box margin={1}>
                            <Field
                                as={TextField}
                                name='name'
                                label='Bed Type Name'
                                fullWidth
                                error={Boolean(errorMessage)}
                                helperText={<ErrorMessage name='name' />}
                            />
                        </Box>
                        {errorMessage && (
                            <Typography color='error' variant='body2'>
                                {errorMessage}
                            </Typography>
                        )}
                        <DialogActions>
                            <Button onClick={onClose} color='secondary'>
                                Cancel
                            </Button>
                            <button className='text-white py-1 px-3 bg-medium-aquamarine ' type='submit' style={{ borderRadius: '5px' }}>
                                {isLoading ? 'Adding...' : 'Add'}
                            </button>
                        </DialogActions>
                    </Form>
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

export default AddBedType;
